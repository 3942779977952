import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

import deepEqual from 'utils/deepEqual';

const useDeepEffect = (
	effectFn: EffectCallback,
	deps: DependencyList
): void => {
	const isFirst = useRef<boolean>(true);
	const prevDeps = useRef<DependencyList>(deps);

	useEffect(() => {
		const isSame = prevDeps.current.every((obj, index) =>
			deepEqual(obj, deps[index])
		);
		if (isFirst.current || !isSame) {
			effectFn();
		}

		isFirst.current = false;
		prevDeps.current = deps;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};

export default useDeepEffect;
