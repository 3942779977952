import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { FormikHelpers, Formik, Form as FormikForm, FormikProps } from 'formik';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { createFilter } from 'react-select';
import { Config } from 'react-select/src/filters';

import * as Yup from 'yup';

import { Input, Radio, AsyncSelect, FormikEffect } from 'components/Formik';
import { useToast, useQueryParams } from 'hooks';
import accountService from 'services/account.service';
import financialService from 'services/financial.service';
import { selectors } from 'store/ducks/session';
import { HttpBaseResult, ApiCodes, Bank } from 'types';
import { cpfCnpjFormatter } from 'utils/formatters';
import history from 'utils/history';
import ValidateCNPJ from 'utils/Validator/CNPJ';
import ValidateCPF from 'utils/Validator/CPF';

import { FormSubmitButton, FormCancelButton } from 'packages/admin/components';
import PageLayoutFormWrapper from 'packages/admin/components/PageLayoutFormWrapper';

import SkeletonForm from './account/SkeletonForm';
import { AccountFormType } from './account/types';

const defaultFormValue: AccountFormType = {
	id: null,
	idAccount: null,
	idUser: '',
	identifier: '',
	holder: '',
	cpfCnpj: '',
	agency: '',
	number: '',
	isCC: '',
	bank: null,
};

// Validações
const formSchema = Yup.object<AccountFormType>().shape({
	identifier: Yup.string().required(
		'Digite algo que te ajude a lembrar que conta é esta'
	),
	holder: Yup.string().required('Campo obrigatório'),
	cpfCnpj: Yup.string()
		.required('Campo obrigatório')
		.test(
			'isValidCpfCnpj',
			'CPF/CNPJ Inválido',
			(value) => ValidateCPF(value || '') || ValidateCNPJ(value || '')
		),
	agency: Yup.string()
		.required('Campo obrigatório')
		.max(8, ({ max }) => `O campo precisa possuir no máximo ${max} caracteres`),
	number: Yup.string()
		.required('Campo obrigatório')
		.max(
			20,
			({ max }) => `O campo precisa possuir no máximo ${max} caracteres`
		),
	isCC: Yup.string().required('Campo obrigatório'),
	bank: Yup.object<Bank>()
		// .transform((value: Bank | null) =>
		// 	Number((value as Bank)?.idBank).toString() !== 'NaN' ? value : null
		// )
		.nullable()
		.required('Campo obrigatório'),
});

const bankFilterConfig: Config = {
	ignoreCase: true,
	ignoreAccents: true,
	trim: true,
	matchFrom: 'any',
};

const Account: React.FC = () => {
	const [toast] = useToast();
	const query = useQueryParams();
	const executive = useSelector(selectors.getSessionPerson);
	const redirectTo = query.get('redirect') || '';

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return { account: defaultFormValue };
			}

			const account = await accountService.getById(Number(id));
			return { account };
		},
		[]
	);

	// Handlers
	const handleSubmit = useCallback(
		async (
			id: string,
			{ bank, ...values }: AccountFormType,
			{ setSubmitting }: FormikHelpers<AccountFormType>
		) => {
			const isCreating = id === 'create';
			const submit = {
				...values,
				idUser: isCreating ? executive.idUser.toString() : values.idUser,
				idBank: bank?.idBank,
			};

			try {
				await accountService.createOrUpdate(submit.idAccount, submit);

				toast(
					`Sua Conta foi ${isCreating ? 'criada' : 'atualizada'} com sucesso!`,
					{ type: 'success' }
				);
				history.push('/app/financial/account');
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[executive.idUser, toast]
	);

	return (
		<PageLayoutFormWrapper
			title="Conta"
			breadcrumb={[
				{ label: 'Financeiro', path: '/app/financial/account' },
				{ label: 'Minhas Contas', path: '/app/financial/account' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.account}
					validationSchema={formSchema}
				>
					{(formProps: FormikProps<AccountFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Body>
									<Form.Row>
										<Form.Group as={Col} md={12}>
											<Form.Label>Identificação</Form.Label>
											<Input name="identifier" type="text" />
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col} sm={12} md={6}>
											<Form.Label>Titular</Form.Label>
											<Input name="holder" type="text" />
										</Form.Group>

										<Form.Group as={Col} sm={12} md={6}>
											<Form.Label>CPF/CNPJ</Form.Label>
											<Input
												name="cpfCnpj"
												formatter={cpfCnpjFormatter}
												type="text"
											/>
										</Form.Group>

										<Form.Group as={Col} md={12}>
											<Form.Label>Banco</Form.Label>
											<AsyncSelect
												name="bank"
												fetchMethod={financialService.getBankList}
												optionValue="idBank"
												filterOption={createFilter(bankFilterConfig)}
												cacheOptions
												isClearable
											/>
										</Form.Group>

										<Form.Group as={Col} sm={12}>
											<Radio
												name="isCC"
												value="1"
												label="Conta Corrente"
												defaultChecked={formProps.values.isCC === '1'}
											/>
											<Radio
												name="isCC"
												value="0"
												label="Conta Poupança"
												defaultChecked={formProps.values.isCC === '0'}
											/>
										</Form.Group>

										<Form.Group as={Col} md={6}>
											<Form.Label>Agência</Form.Label>
											<Input name="agency" type="text" />
										</Form.Group>

										<Form.Group as={Col} md={6}>
											<Form.Label>Conta</Form.Label>
											<Input name="number" type="text" />
										</Form.Group>
									</Form.Row>
								</Card.Body>

								<Card.Footer className="text-right">
									<FormCancelButton
										to={redirectTo || '/app/financial/account'}
									/>
									<FormSubmitButton isSubmitting={formProps.isSubmitting} />
								</Card.Footer>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default Account;
