import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';

type FollowUpConfirmationModalProps = {
	title?: string | React.ReactElement;
	message: string | React.ReactElement;
	style?: React.CSSProperties;
	className?: string;
};

const defaultProps = {
	title: 'Fechamento de Follow-up',
};

const FollowUpConfirmationModal: React.FC<
	ModalForwardProps<FollowUpConfirmationModalProps, string | boolean>
> = ({ title, message, modalRef, ...rest }) => {
	const [modalDescription, setModalDescription] = useState<string>('');

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="m-0">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>{message}</div>

				<Form.Control
					as="textarea"
					onChange={(event) => setModalDescription(event.target.value)}
				/>
			</Modal.Body>
			<Modal.Footer align="end">
				<Button
					variant="outline-primary"
					onClick={() => modalRef.dismiss(false)}
				>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={() => modalRef.dismiss(modalDescription)}
				>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

FollowUpConfirmationModal.defaultProps = defaultProps;

export default FollowUpConfirmationModal;
