import React, { FunctionComponent, useRef, useState } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';

type LoadWhenVisibleProps = React.HTMLProps<HTMLDivElement> &
	Partial<{
		as: React.ElementType;
		threshold: number;
	}>;

const LoadWhenVisible: FunctionComponent<LoadWhenVisibleProps> = ({
	as: Component = 'div',
	threshold,
	children,
	...rest
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	useIntersectionObserver(ref, threshold || 0.1, (elements, observer) => {
		if (elements.length > 0 && elements[0].isIntersecting) {
			setIsVisible(true);

			if (ref.current) {
				observer.unobserve(ref.current);
			}
		}
	});

	return (
		<Component ref={ref} {...rest}>
			{isVisible && children}
		</Component>
	);
};

export default LoadWhenVisible;
