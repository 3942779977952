import React from 'react';

import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import * as Yup from 'yup';

import { CareerForSelect } from 'types';

import {
	FilterContainer,
	FilterButton,
	MonthSelectFilter,
	YearFilter,
	CareerSelectFilter,
} from 'packages/admin/components/datatableView';
import {
	monthOptionsBR,
	MonthOption,
} from 'packages/admin/components/datatableView/MonthSelectFilter';

type GraduatedFiltersFormType = {
	id: CareerForSelect | null;
	month: MonthOption;
	year: number;
};

const defaultFilterValues: GraduatedFiltersFormType = {
	id: null,
	month: monthOptionsBR[new Date().getMonth()],
	year: new Date().getFullYear(),
};

const validationSchema = Yup.object<GraduatedFiltersFormType>({
	id: Yup.object<CareerForSelect>().nullable().required('Campo obrigatório'),
	month: Yup.object<MonthOption>().required('Campo obrigatório'),
	year: Yup.number().required('Campo obrigatório'),
});

const GraduatedFilters: React.FC = () => {
	return (
		<>
			<FilterContainer
				initialValues={defaultFilterValues}
				validationSchema={validationSchema}
			>
				<Form.Row>
					<Col sm={12} md={10} lg={7} xl={6}>
						<Row>
							<FormGroup as={Col} sm={12} md={5}>
								<Form.Label>Carreira</Form.Label>
								<CareerSelectFilter name="id" queryKey="idCareer" />
							</FormGroup>
							<FormGroup as={Col} sm={12} md={4}>
								<Form.Label>Mês</Form.Label>
								<MonthSelectFilter name="month" />
							</FormGroup>
							<FormGroup as={Col} sm={12} md={3}>
								<Form.Label>Ano</Form.Label>
								<YearFilter name="year" />
							</FormGroup>
						</Row>
					</Col>

					<FormGroup as={Col} md={2} lg={5} xl={6}>
						<br />
						<FilterButton className="m-1 mr-4" />
					</FormGroup>
				</Form.Row>
			</FilterContainer>
		</>
	);
};

export default GraduatedFilters;
