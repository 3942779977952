import React, { useState } from 'react';

import { InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'components';
import { ModalForwardProps } from 'components/ModalContainer';

type ElectronicSignModalProps = {
	title?: string | React.ReactElement;
	message: string | React.ReactElement;
	ButtonCancelText?: string | React.ReactElement;
	ButtonConfirmText?: string | React.ReactElement;
	style?: React.CSSProperties;
	className?: string;
};

const defaultProps = {
	title: 'Autorização',
	ButtonCancelText: 'Cancelar',
	ButtonConfirmText: 'Enviar',
};

const ElectronicSignModal: React.FC<
	ModalForwardProps<ElectronicSignModalProps, string>
> = ({
	title,
	message,
	ButtonCancelText,
	ButtonConfirmText,
	modalRef,
	...rest
}) => {
	const [signature, setSignature] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);

	if (!modalRef) return null;

	return (
		<Modal {...rest} size="sm">
			<Modal.Header closeButton>
				<Modal.Title className="m-0">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>{message}</div>

				<Form.Group>
					<Form.Label>Assinatura Eletrônica</Form.Label>
					<InputGroup>
						<Form.Control
							type={showPassword ? 'text' : 'password'}
							placeholder="Digite a sua Assinatura Eletrônica"
							onChange={(event) => setSignature(event.target.value)}
						/>
						<InputGroup.Append>
							<Button
								variant="outline-primary"
								onClick={() => setShowPassword(!showPassword)}
							>
								<FAIcon icon={showPassword ? faEye : faEyeSlash} />
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer align="end">
				<Button variant="outline-primary" onClick={() => modalRef.dismiss()}>
					{ButtonCancelText}
				</Button>
				<Button variant="primary" onClick={() => modalRef.dismiss(signature)}>
					{ButtonConfirmText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

ElectronicSignModal.defaultProps = defaultProps;

export default ElectronicSignModal;
