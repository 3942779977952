import {
	MachineConfig,
	MachineOptions,
	Machine,
	Actor,
	assign,
	spawn,
	send,
	actions,
} from 'xstate';

import { ToastFn } from 'hooks/useToast';
import { PersonAddress, OrderShipment, ID, OrderSubmit } from 'types';

import AddressMachine, {
	AddressParentEvent,
	AddressActor,
	AddressSchemaFlag,
} from './addressSelect/AddressMachine';
import NotificationsMachine, {
	NotificationsEvent,
} from './NotificationsMachine';
import ShipmentMachine, {
	ShipmentParentEvent,
	ShipmentActor,
} from './shipmentSelect/ShipmentMachine';
import { ToggleSchema } from './ToggleMachine';

export type CheckoutSchema = {
	states: {
		setup: {};
		billingAddress: ToggleSchema;
		acceptTerms: ToggleSchema;
	};
};

type CheckoutContext = {
	addressRef?: AddressActor;
	billingAddressRef?: AddressActor;
	shipmentRef?: ShipmentActor;
	notificationsRef?: Actor<{}, NotificationsEvent>;
	addressSelected?: PersonAddress;
	billingAddressSelected?: PersonAddress;
	shipmentSelected?: OrderShipment;
};

// Eventos internos do statecharts de seleção de endereço
export type CheckoutEvent =
	| AddressParentEvent
	| ShipmentParentEvent
	| NotificationsEvent
	| { type: 'TOGGLE_BILLING_ADDRESS' }
	| { type: 'TOGGLE_ACCEPT_TERMS' }
	| { type: 'SUBMIT_ORDER'; order: OrderSubmit };

// Eventos disparados para a máquina de estados pai
export type CheckoutParentEvent = { type: 'CHECKOUT_UPDATE' };

export type CheckoutEventWithItem = {
	type: string;
};

export const CheckoutMachineConfig = (
	idPerson: ID,
	toast: ToastFn
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): MachineConfig<any, CheckoutSchema, CheckoutEvent> => ({
	id: 'checkout',
	context: {},
	type: 'parallel',
	states: {
		setup: {
			entry: assign({
				notificationsRef: () => spawn(NotificationsMachine(toast)),
				addressRef: () =>
					spawn(AddressMachine('address', AddressSchemaFlag.All, idPerson)),
				billingAddressRef: () =>
					spawn(
						AddressMachine(
							'billingAddress',
							AddressSchemaFlag.Browsing,
							idPerson
						)
					),
				shipmentRef: () => spawn(ShipmentMachine('shipment')),
			}),
			on: {
				ADDRESSES_LOADED: {
					actions: send(
						(_, event) => ({
							type: 'SET_ADDRESS_LIST',
							addresses: event.addresses,
						}),
						{ to: (ctx) => ctx.billingAddressRef }
					),
				},
				ADDRESS_CHANGE: {
					actions: actions.choose<
						CheckoutContext,
						AddressParentEvent & { type: 'ADDRESS_CHANGE' }
					>([
						{
							cond: (_, event) => event.id === 'address',
							actions: [
								assign({
									addressSelected: (_, event) => event.address,
								}),
								send(
									(_, event) => ({
										type: 'LOAD_OPTIONS_FOR_ADDRESS',
										address: event.address,
									}),
									{ to: (ctx) => ctx.shipmentRef! }
								),
							],
						},
						{
							cond: (_, event) => event.id === 'billingAddress',
							actions: assign({
								billingAddressSelected: (_, event) => event.address,
							}),
						},
					]),
				},
				FETCHING_OPTIONS: {
					actions: assign({
						shipmentSelected: () => undefined,
					}),
				},
				SHIPMENT_SELECTED: {
					actions: assign({
						shipmentSelected: (_, event) => event.option,
					}),
				},
				NOTIFY: {
					actions: (_, event) =>
						send(
							{ type: 'NOTIFY', data: event.data },
							{ to: 'notificationsMachine' }
						),
				},
			},
		},
		billingAddress: {
			id: 'billingAddress',
			initial: 'inactive',
			states: {
				active: {
					on: {
						TOGGLE_BILLING_ADDRESS: 'inactive',
					},
				},
				inactive: {
					on: {
						TOGGLE_BILLING_ADDRESS: 'active',
					},
				},
			},
		},
		acceptTerms: {
			id: 'acceptTerms',
			initial: 'inactive',
			states: {
				active: {
					on: {
						TOGGLE_ACCEPT_TERMS: 'inactive',
					},
				},
				inactive: {
					on: {
						TOGGLE_ACCEPT_TERMS: 'active',
					},
				},
			},
		},
	},
});

export const CheckoutMachineOptions: MachineOptions<
	CheckoutContext,
	CheckoutEvent
> = {
	guards: {},
	actions: {},
	services: {},
	activities: {},
	delays: {},
};

export default (idPerson: ID, toast: ToastFn) =>
	Machine(CheckoutMachineConfig(idPerson, toast), CheckoutMachineOptions);
