import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

import { TransitionPromptHook } from 'history';
import { ConfirmationModal } from 'modals';

import { useModal } from 'hooks';

export type ExitPromptProps = {
	isEnable: boolean;
	preventReload?: boolean;
};

const ExitPrompt: React.FC<ExitPromptProps> = ({
	isEnable,
	preventReload = true,
}) => {
	const history = useHistory();
	const open = useModal<{ message: string }, boolean>();

	const preventExitFn: TransitionPromptHook<unknown> = useCallback(
		({ pathname }) => {
			if (isEnable && history.location.pathname !== pathname) {
				open(
					ConfirmationModal,
					{
						message: `As alterações não foram salvas. Tem certeza que deseja sair?`,
					},
					(confirmed) => {
						if (confirmed) {
							history.block(() => {});
							history.push(pathname);
						}
					}
				);

				return false;
			}
		},
		[isEnable, open, history]
	);

	const preventReloadFn = useCallback(
		(e: BeforeUnloadEvent) => {
			if (preventReload && isEnable) {
				e.returnValue = true;
			}
		},
		[preventReload, isEnable]
	);

	useEffect(() => {
		history.block(preventExitFn);
		window.onbeforeunload = preventReloadFn;

		return () => {
			history.block(() => {});
			window.onbeforeunload = () => {};
		};
	}, [history, preventExitFn, preventReloadFn]);

	return null;
};

export default ExitPrompt;
