import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';

import { useMachine } from '@xstate/react';

import { Checkbox } from 'components';
import { useToast } from 'hooks';
import { selectors } from 'store/ducks/session';

import { Paragraph, Title, NavbarMMN } from '../components';
import useShoppingCartView from '../hooks/useShoppingCartView';

import AddressSelect from './checkout/AddressSelect';
import CheckoutMachine from './checkout/CheckoutMachine';
import CheckoutNavbar from './checkout/Navbar';
import OrderSummary from './checkout/OrderSummary';
import ShipmentSelect from './checkout/ShipmentSelect';

const Checkout: React.FC = () => {
	const executive = useSelector(selectors.getSessionPerson);
	const [toast] = useToast();
	const cartView = useShoppingCartView();

	const machine = useMachine(CheckoutMachine(executive.idUser, toast), {
		devTools: true,
	});
	const [current, send] = machine;
	const [, setObservation] = useState<string>('');

	const handleObservationBlur = (
		event: React.FocusEvent<HTMLTextAreaElement>
	) => {
		setObservation(event.target.value);
	};
	const handleSubmitOrder = () => {};
	const handleToggleBilling = () => send('TOGGLE_BILLING_ADDRESS');
	const handleTermsCheckboxChange = () => send('TOGGLE_ACCEPT_TERMS');

	const enableSubmit =
		current.matches('acceptTerms.active') &&
		!!current.context.addressSelected &&
		(current.matches('billingAddress.active')
			? !!current.context.billingAddressSelected
			: true) &&
		!!current.context.shipmentSelected;

	return (
		<div className="bg-light page-min-height">
			<NavbarMMN />
			<CheckoutNavbar />
			<Container fluid className="py-3">
				<header className="pb-3">
					<Title>Fechar Pedido</Title>
				</header>

				<Row>
					<Col lg={7}>
						{current.context.addressRef && (
							<AddressSelect
								title="Entrega"
								machineRef={current.context.addressRef!}
							>
								<Checkbox
									checked={current.matches('billingAddress.active')}
									onChange={handleToggleBilling}
									label="Endereço de cobrança é diferente do endereço de entrega"
								/>
							</AddressSelect>
						)}

						{current.matches('billingAddress.active') &&
							current.context.billingAddressRef && (
								<AddressSelect
									title="Cobrança"
									machineRef={current.context.billingAddressRef!}
								/>
							)}

						<ShipmentSelect
							machineRef={current.context.shipmentRef!}
							orderWeight={cartView.weight}
						/>

						<Card>
							<Card.Body className="pt-0 border">
								<Paragraph className="py-2">Observações</Paragraph>
								<FormControl
									as="textarea"
									rows={5}
									onBlur={handleObservationBlur}
								/>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={5} className="d-flex flex-column">
						<OrderSummary
							onSubmitOrder={handleSubmitOrder}
							enableSubmit={enableSubmit}
							termsCheckboxValue={current.matches('acceptTerms.active')}
							onTermsCheckboxChange={handleTermsCheckboxChange}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Checkout;
