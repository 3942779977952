import styled, { css, keyframes } from 'styled-components';

import { SwitchProps } from './type';

const switchLoading = keyframes`
  100% { transform:rotate(360deg);
`;

type SwitchStylesProps = Pick<
	SwitchProps,
	'disabled' | 'isLoading' | 'checkedColor' | 'unCheckedColor' | 'size'
> & { isChecked?: boolean };

const sizeModifiers = {
	sm: (isChecked: boolean) => css`
		min-width: 40px;
		height: 20px;
		line-height: 20px;

		div {
			width: 16px;
			height: 16px;
			left: ${isChecked ? 'calc(100% - 16px - 2px)' : '2px'};
		}

		> span {
			margin: ${isChecked ? '0 16px 0 7px' : '0 7px 0 16px'};
		}
	`,
	default: (isChecked: boolean) => css`
		min-width: 44px;
		height: 22px;
		line-height: 22px;

		div {
			width: 18px;
			height: 18px;
			left: ${isChecked ? 'calc(100% - 18px - 2px)' : '2px'};
		}

		> span {
			margin: ${isChecked ? '0 18px 0 7px' : '0 7px 0 18px'};
		}
	`,
	lg: (isChecked: boolean) => css`
		min-width: 48px;
		height: 24px;
		line-height: 24px;

		div {
			width: 20px;
			height: 20px;
			left: ${isChecked ? 'calc(100% - 20px - 2px)' : '2px'};
		}

		> span {
			margin: ${isChecked ? '0 20px 0 7px' : '0 7px 0 20px'};
		}
	`,
};

export const Switch = styled.button<SwitchStylesProps>`
	${({
		isChecked,
		disabled,
		isLoading,
		checkedColor = 'var(--success)',
		unCheckedColor = 'var(--gray-30)',
		size = 'default',
	}) => css`
		--duration: 0.36s;

		position: relative;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: middle;
		border: 0;
		border-radius: 100px;
		cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'} !important;
		transition: all var(--duration) cubic-bezier(0.35, 0, 0.25, 1);
		user-select: none;
		opacity: ${disabled || isLoading ? 0.4 : 1};
		background-color: ${isChecked ? checkedColor : unCheckedColor};

		&:focus {
			outline: 0;
			box-shadow: 0 0 0 2px rgba(0, 0, 0, ${isChecked ? '0.2' : '0.1'});

			&:hover {
				box-shadow: none;
			}
		}

		div {
			position: absolute;
			top: 2px;
			transition: all var(--duration) cubic-bezier(0.78, 0.14, 0.15, 0.86);

			&::before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: var(--always-white);
				border-radius: 9px;
				box-shadow: ${disabled || isLoading
					? 'none'
					: '0 2px 4px 0 rgba(0, 35, 11, 0.2)'};
				transition: all var(--duration) cubic-bezier(0.78, 0.14, 0.15, 0.86);
				content: '';
			}

			svg {
				position: absolute;
				top: 2px;
				left: 2px;
				color: ${isChecked ? checkedColor : unCheckedColor};
				animation: ${switchLoading} var(--duration) linear infinite;
			}
		}

		> span {
			display: block;
			color: var(--always-white);
			font-size: 12px;
			line-height: 12px;
			font-weight: bold;
			transition: margin var(--duration);
		}

		${!!size && sizeModifiers[size](!!isChecked)}
	`}
`;
