import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Card from 'components/Card';
import Panel from 'components/Panel';
import Skeleton from 'components/Skeleton';

const PayoutSkeletonForm: React.FC = () => (
	<Card>
		<Card.Content className="offset-lg-4 col-lg-4">
			<Form.Row>
				<Panel width="full" align="left">
					<Panel.Title>
						<Skeleton width="25ch" height="1.7rem" />
					</Panel.Title>
					<Panel.Text>
						<Skeleton height="1.36rem" display="block" margin="0.2rem" />
						<Skeleton height="1.36rem" display="block" margin="0.2rem" />
					</Panel.Text>
				</Panel>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton width="15ch" height="1rem" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton width="15ch" height="1rem" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton width="20ch" height="1rem" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>

			<div className="text-right">
				<Skeleton width="10ch" height="2.5rem" margin="0 1rem 0 0" />
				<Skeleton width="10ch" height="2.5rem" />
			</div>
		</Card.Content>
	</Card>
);

export default PayoutSkeletonForm;
