import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useQueryParams from 'hooks/useQueryParams';
import { RootState } from 'store/ducks/rootReducer';
import { actions } from 'store/ducks/session';
import { SessionPerson, PersonType } from 'types';
import history from 'utils/history';

const LoginRedirectContainer: FunctionComponent<{}> = () => {
	const query = useQueryParams();
	const dispatch = useDispatch();
	const user = useSelector<RootState, SessionPerson | null>(
		(state) => state.session.user
	);

	const path = query.get('path') || '';

	useEffect(() => {
		let redirPath = path.startsWith('/login') ? '' : path;

		if (user !== null) {
			switch (user.idType) {
				case PersonType.Admin: {
					if (path.startsWith('/admin') || path.startsWith('/reports')) {
						redirPath = path;
					} else {
						redirPath = '/admin';
					}
					break;
				}
				case PersonType.Executive:
				case PersonType.Distributor:
				case PersonType.DistributorUser:
				case PersonType.ExclusiveDistributor: {
					if (path.startsWith('/app') || path.startsWith('/loja')) {
						redirPath = path;
					} else {
						redirPath = '/app';
					}
					break;
				}
				default: {
					dispatch(actions.logoutRequest(redirPath));
					return;
				}
			}
		}

		history.push(redirPath);
	}, [dispatch, path, user]);

	return <></>;
};

export default LoginRedirectContainer;
