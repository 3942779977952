import { ReactFragment } from 'react';

import FindObjectProperty from 'utils/FindObjectProperty';

import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from './IFormatter';

class DefaultFormatter implements FormatterType {
	format = (
		field: string,
		row?: FormatterParamRowType
	): string | ReactFragment => {
		const data = !row ? field : FindObjectProperty(row, field) || '';
		if (!data) {
			return '';
		}
		const type = typeof data;
		if (type !== 'string' && type !== 'number') {
			return '';
		}
		return String(data);
	};

	formatForInput(data: FormatterParamType): [string, string] {
		const val = this.format('data', { data }) as string;
		return [val, val];
	}
}

export default DefaultFormatter;
