import React from 'react';

import {
	faSortAlphaDown,
	faSortAlphaDownAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import useDatagridContext from '../useDatagridContext';

type ColumnOrderByProps = {
	colKey: string;
	title: string;
	sortable: boolean;
	className?: string;
};

const thStyle = { cursor: 'pointer' };

const ColumnOrderBy: React.FC<ColumnOrderByProps> = ({
	colKey,
	title,
	sortable,
	className,
}) => {
	const {
		onOrderByChange,
		tableParams: { sortByKey, sortByDesc },
	} = useDatagridContext();

	const isSelected = sortByKey === colKey;
	const desc = isSelected ? !sortByDesc : false;

	const onClick = () => {
		if (!sortable) {
			return;
		}
		onOrderByChange(colKey, desc);
	};

	return (
		<th style={thStyle} className={className} onClick={onClick}>
			{title}
			{isSelected && (
				<FAIcon
					icon={desc ? faSortAlphaDown : faSortAlphaDownAlt}
					className="ml-2"
				/>
			)}
		</th>
	);
};

ColumnOrderBy.displayName = 'ColumnOrderBy';
ColumnOrderBy.whyDidYouRender = true;

export default ColumnOrderBy;
