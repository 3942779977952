import styled from 'styled-components';

import { cssVar } from 'polished';

import {
	TimelineFooterProps,
	TimelineHeaderProps,
	TimelineItemProps,
} from './types';

export const timelineFontContrastMap = {
	light: cssVar('--gray-20'),
	dark: cssVar('--gray-70'),
};

export const timelineFooterAlignMap = {
	start: 'flex-start',
	center: 'center',
	end: 'flex-end',
};

export const TimelineClearfix = styled.li`
	clear: both;
`;

export const TimelineFooter = styled.div<TimelineFooterProps>`
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: ${({ align = 'start' }) => timelineFooterAlignMap[align]};
	background-color: var(--gray-20);
`;

export const TimelineBody = styled.div`
	padding: 1rem;
	margin-bottom: 0;
	white-space: pre-line;
`;

export const TimelineContent = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	> h3,
	h4,
	h5 {
		font-weight: 600;
		font-family: 'Roboto', sans-serif;
	}

	> span {
		min-width: max-content;
		line-height: 1rem;
		font-size: 0.9rem;
		font-style: italic;
		font-weight: 500;
		color: var(--gray-50);
		flex: 1;
		text-align: left;
	}

	> img {
		position: absolute;
		display: block;
		width: 100%;
		height: auto;
		object-fit: cover;
	}
`;

export const TimelineHeaderIcon = styled.div`
	margin: -0.8rem 1rem -0.8rem -0.3rem;

	> svg {
		height: 100% !important;
		width: 3.5rem !important;
	}
`;

export const TimelineHeader = styled.div<TimelineHeaderProps>`
	position: relative;
	width: 100%;
	padding: 1rem;
	font-size: calc(1.2rem + 0.06vw);
	display: flex;
	/* z-index: 1; */

	> ${TimelineHeaderIcon} {
		color: ${({ fontContrast = 'light' }) =>
			timelineFontContrastMap[fontContrast]};
	}

	> ${TimelineContent} {
		> * {
			color: ${({ fontContrast = 'light' }) =>
				timelineFontContrastMap[fontContrast]};
		}
	}
`;

export const TimelinePanel = styled.div`
	position: relative;
	float: right;
	width: calc(100% - var(--timeline-width));
	min-width: min-content;

	border: none;
	background-color: var(--always-white);
	box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.15);
`;

export const TimelineBadge = styled.div`
	position: absolute;
	top: var(--badge-top);
	left: calc((var(--timeline-width) / 2) - (var(--badge-size) / 2));
	width: var(--badge-size);
	height: var(--badge-size);
	margin-left: 0;
	color: var(--light);

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;

	> svg {
		width: 65% !important;
		height: 65% !important;
	}
`;

export const TimelineDate = styled.div`
	position: absolute;
	left: calc((var(--timeline-width) / 2) - (var(--date-width) / 2));
	width: var(--date-width);
	padding: 0.3rem;

	font-weight: 600;
	font-size: 1rem;
	text-align: center;

	background: var(--gray-40);
	color: var(--light);
	top: var(--date-top);
	border-radius: 4px;
`;

export const TimelineItem = styled.li<TimelineItemProps>`
	position: relative;
	width: 100%;
	float: left;
	clear: left;
	margin-top: 3rem;
	margin-bottom: 0.6rem;

	> ${TimelineBadge} {
		background-color: ${({ color = 'var(--gray-40)' }) => color};
	}

	> ${TimelinePanel} {
		> ${TimelineHeader} {
			border: solid 2px ${({ color = 'var(--gray-40)' }) => color};

			> ${TimelineHeaderIcon} {
				> svg {
					color: ${({ color = 'var(--gray-40)' }) => color};
				}
			}
		}
	}
`;

export const StyledTimeline = styled.ul`
	/* CSS vars para todo o componente */
	--timeline-width: 8rem;
	--badge-top: 1.2rem;
	--badge-size: 2.4rem;
	--date-top: -3rem;
	--date-width: 6rem;

	position: relative;
	list-style: none;
	padding: 0 0 2rem;
	min-width: min-content;

	/* Linha vertical */
	&::before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: ' ';
		width: 4px;
		margin-left: -2px;
		background-color: var(--gray-30);
		left: calc(var(--timeline-width) / 2);
	}
`;
