import React from 'react';

import { faSearch } from '@fortawesome/pro-light-svg-icons';

import distributorService from 'services/distributor.service';
import userService from 'services/system-user.service';
import { OrderForList, StockListType, OrderSale } from 'types';
import { history } from 'utils';
import {
	numberFormatter,
	editLinkFormatter,
	dateHourMinFormatter,
	cepFormatter,
	coloredStatusFormatter,
	currencyWithSymbolFormatter,
	buttonLinkFormatter,
	dateFormatter,
	dateTimeFormatter,
	currencyFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import paginateConfig from 'packages/admin/pages/list/routes/paginate';
import { ListSceneConfig } from 'packages/admin/pages/list/types';
import { personStatusByName } from 'packages/admin/utils/coloredStatus';
import UserDetailsModal, {
	UserDetailsModalProps,
} from 'packages/escritorio/pages/distributor/order/history/modal/ExecutiveDetailsModal';

import AttachCreditOrder from './distributor/AttachCreditOrder';
import DistributorCreditOrdersFilters from './distributor/DistributorCreditOrdersFilters';
import DistributorFilterDetail from './distributor/DistributorFilterDetail';
import PaymentButtonCreditOrder from './distributor/PaymentButtonCreditOrder';

const executiveBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Ponto de Apoio',
		path: '/app/distributor/order',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	DistributorStock: {
		title: 'Estoque',
		breadcrumbItems: [
			...executiveBreadcrumb,
			{ label: 'Estoque', active: true },
		],
		action: distributorService.getStockListById,
		datatableConfig: {
			paginate: paginateConfig,
			sortByDesc: true,
			sortByKey: 'quantity',
			columns: [
				{
					key: 'product',
					title: 'Produto',
					searchable: true,
					sortable: true,
				},
				{
					key: 'quantity',
					title: 'Quantidade',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
					searchFormatter: numberFormatter.formatForSearch,
				},
				{
					key: 'product',
					title: 'Detalhes',
					scope: 'row',
					className: 'text-center',
					formatter: editLinkFormatter<StockListType, number>({
						icon: faSearch,
						label: 'Detalhes',
						onClick: (_, row) =>
							history.push(`stock/details?product=${row.product}`),
					}),
					searchable: false,
				},
			],
		},
	},
	DistributorStockDetails: {
		title: 'Estoque > Detalhes',
		breadcrumbItems: [
			...executiveBreadcrumb,
			{ label: 'Estoque', path: '/app/distributor/stock' },
			{ label: 'Detalhes', active: true },
		],
		toBackLink: '/app/distributor/stock',
		action: distributorService.getStockDetailsList,
		datatableFilters: <DistributorFilterDetail />,
		datatableConfig: {
			paginate: paginateConfig,
			sortByDesc: true,
			sortByKey: 'date',
			updateOnFilterChange: false,
			columns: [
				{
					key: 'date',
					title: 'Data',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: dateHourMinFormatter,
					searchFormatter: dateFormatter.formatForSearch,
				},
				{
					key: 'number',
					title: 'Pedido',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: numberFormatter,
					searchFormatter: numberFormatter.formatForSearch,
				},
				{
					key: 'product',
					title: 'Produto',
					className: 'w-50',
					searchable: false,
				},
				{
					key: 'buy',
					title: 'Compra',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: numberFormatter,
					searchFormatter: numberFormatter.formatForSearch,
				},
				{
					key: 'sell',
					title: 'Venda',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: numberFormatter,
					searchFormatter: numberFormatter.formatForSearch,
				},
			],
		},
	},
	DistributorCreditOrders: {
		title: 'Pedidos de Crédito',
		breadcrumbItems: [
			...executiveBreadcrumb,
			{ label: 'Pedidos de Crédito', active: true },
		],
		action: distributorService.getCreditOrdersList,
		datatableFilters: <DistributorCreditOrdersFilters />,
		datatableConfig: {
			paginate: paginateConfig,
			sortByDesc: true,
			sortByKey: 'idOrder',
			updateOnFilterChange: false,
			columns: [
				{
					key: 'number',
					title: 'Pedido',
					className: 'text-center',
					searchable: true,
					formatter: numberFormatter,
				},
				{
					key: 'date',
					title: 'Data',
					className: 'text-center',
					searchable: true,
					formatter: dateHourMinFormatter,
				},
				{
					key: 'payment',
					title: 'Forma de Pagamento',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'cep',
					title: 'CEP',
					className: 'text-center',
					searchable: true,
					formatter: cepFormatter,
				},
				{
					key: 'shipment',
					title: 'Envio',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'total',
					title: 'Total',
					className: 'text-center',
					searchable: true,
					formatter: currencyWithSymbolFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					className: 'text-center',
					searchable: true,
					formatter: buttonLinkFormatter<OrderForList, void>({
						component: PaymentButtonCreditOrder,
					}),
				},
				{
					key: 'attach',
					title: '',
					className: 'text-center',
					searchable: false,
					formatter: buttonLinkFormatter<OrderForList, void>({
						component: AttachCreditOrder,
					}),
				},
			],
		},
	},
	DistributorUser: {
		title: 'Usuários',
		breadcrumbItems: [
			...executiveBreadcrumb,
			{ label: 'Usuários', active: true },
		],
		newItemLink: 'user/create',
		action: userService.getUserPaList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					scope: 'row',
					formatter: editLinkFormatter({
						link: '/app/distributor/user/{id}',
					}),
					searchable: false,
				},
				{
					key: 'name',
					title: 'Nome',
					searchable: true,
				},
				{
					key: 'login',
					title: 'Login',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: personStatusByName,
					}),
				},
			],
		},
	},
	DistributorsOrderHistory: {
		title: 'Vendas',
		breadcrumbItems: [
			...executiveBreadcrumb,
			{ label: 'Vendas', active: true },
		],
		action: distributorService.getOrderSalesList,
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'number',
			sortByDesc: true,
			columns: [
				{
					key: 'number',
					title: 'Pedido',
					// scope: 'row',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
					// formatter: buttonLinkFormatter<OrderSale, { row: OrderSale }>({
					// 	label: (row) => row.idOrder,
					// 	className: 'btn btn-sm text-left',
					// 	onClick: (_, row, openModal) =>
					// 		openModal(OrderDetailsModal, { row }),
					// }),
				},
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'idPerson',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: buttonLinkFormatter<OrderSale, UserDetailsModalProps>({
						label: (row) => row.id,
						className: 'btn btn-sm text-left',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row,
								config: { showContactOnly: true },
							}),
					}),
				},
				{
					key: 'name',
					title: 'Executivo',
					searchable: true,
					className: 'w-50',
					formatter: buttonLinkFormatter<OrderSale, UserDetailsModalProps>({
						label: (row) => row.name,
						className: 'btn btn-sm text-left',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row,
								config: { showContactOnly: true },
							}),
					}),
				},
				{
					key: 'total',
					title: 'Total',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: true,
					className: 'text-center',
				},
			],
		},
	},
};

export default ScenesConfig;
