import act from './actions';
import reducers from './reducers';
import thunks from './thunks';

export { default as selectors } from './selectors';
export * from './types';

export const actions = {
	...thunks,
	...act,
};

export default reducers;
