import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Featured, LazyImage } from 'components';
import Sort from 'utils/Sorters';

import { Paragraph, Title } from '../components/Typography';
import useEcommerceBasePath from '../hooks/useEcommerceBasePath';
import useEcommerceContext from '../hooks/useEcommerceContext';

const Home = (): ReactElement => {
	const url = useEcommerceBasePath();
	const {
		config: { feature: features, banner: banners },
	} = useEcommerceContext();

	return (
		<Container fluid>
			<Carousel>
				{banners.map((banner) => (
					<Carousel.Item key={banner.imageUrl} style={{ maxHeight: '400px' }}>
						{banner.linkUrl ? (
							<a href={banner.linkUrl}>
								<img src={banner.imageUrl} width="100%" alt="" />
							</a>
						) : (
							<img src={banner.imageUrl} width="100%" alt="" />
						)}
					</Carousel.Item>
				))}
			</Carousel>

			{features.map((feature) => (
				<div key={feature.idFeature} className="my-5">
					<Title>{feature.name}</Title>
					<Row>
						<Col lg={6} md={6} sm={6} xs={6}>
							<Paragraph>{feature.description}</Paragraph>
						</Col>
						<Col lg={6} md={6} sm={6} xs={6} className="text-right">
							<Link to={feature.link || '#'}>
								{feature.textLink}{' '}
								<FAIcon icon={faAngleRight} className="ml-1" size="lg" />
							</Link>
						</Col>
					</Row>
					<Featured>
						{feature.product.sort(Sort.Numerically('sort')).map((item) => (
							<Featured.Item key={item.sort} size={item.size}>
								<Link to={`${url}/produto/${item.idProduct}`}>
									<div className="item-image">
										<LazyImage
											src={item.image}
											alt="Imagem"
											loadingSrc="/assets/images/cartEmpty.png"
										/>
									</div>
								</Link>
							</Featured.Item>
						))}
					</Featured>
				</div>
			))}
		</Container>
	);
};

Home.displayName = 'HomeScene';
Home.whyDidYouRender = true;

export default Home;
