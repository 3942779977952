import axios from 'axios';

import { RegionState, ID, RegionCity } from 'types';

import Crud from './crud.service';

let statesCache: RegionState[] | null = null;

const getCachedStates = async (): Promise<RegionState[]> => {
	if (statesCache !== null) {
		return Promise.resolve(statesCache);
	}
	return axios
		.get<RegionState[]>('/assets/states.json')
		.then((resp) => resp.data)
		.then((resp: RegionState[]) => {
			statesCache = resp;
			return statesCache;
		});
};

const getStates = async () => {
	if (statesCache !== null) {
		return Promise.resolve(statesCache);
	}
	return Crud.getListData<RegionState>('zone/state', {}).then((resp) => {
		statesCache = resp;
		return statesCache;
	});
};

const getCitiesForState = (idState: ID) =>
	Crud.getListData<RegionCity>('zone/state/{id}/city', {
		params: { id: idState },
	}).then((resp) => {
		const state = statesCache?.find((s) => s.idState === idState);
		if (state) {
			state.city = resp;
		}
		return resp;
	});

export default {
	getStates,
	getCitiesForState,
	getCachedStates,
};
