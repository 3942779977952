import React, { FunctionComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

type PrivateRouteProps = RouteProps & {
	hasPermission?: boolean;
};

const PrivateRoute: FunctionComponent<PrivateRouteProps> = (props) => {
	const { hasPermission = true, component, ...rest } = props;

	return hasPermission ? (
		<Route component={component} {...rest} />
	) : (
		<Route {...rest} render={() => <Redirect to={{ pathname: '/' }} />} />
	);
};

PrivateRoute.displayName = 'PrivateRoute';
PrivateRoute.whyDidYouRender = true;

export default PrivateRoute;
