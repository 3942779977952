import { faFileAlt } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

const ReportsRoutes: RouteDef = {
	path: 'http://google.com.br',
	menu: {
		name: 'Relatórios',
		icon: faFileAlt,
		externalLink: true,
	},
};

export default ReportsRoutes;
