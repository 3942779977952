/* eslint-disable no-plusplus */
import React from 'react';

import { FeatureProduct } from 'types';
import Sort from 'utils/Sorters';

import {
	LayoutMode1,
	LayoutMode2,
	GridLayout,
	Converted,
	GridColumn,
} from './types';

export const convertStrToLayoutMode1 = (strMode1: string): LayoutMode1 =>
	strMode1.split('x').map((value) => Number(value));

const convertStrToLayoutMode2 = (strMode2: string): LayoutMode2 =>
	strMode2.split('x').map((value) => {
		const [items, column] = value.split('');
		return { items: Number(items), column };
	});

// TODO: Temporário: conversão de dados entre componentes FeatureGrid e Featured

// Configuração do relacionamento entre os modos
// mode2: (1 = size: "2"; > 1 = size: "255"; Letra = Coluna)
// Funcionamento da conversão:
// Converte de '1x1x4' para '1Ax1Bx4C' = 1 registro na coluna A + 1 na coluna B + 4 na coluna C.
const strLayoutMapping = [
	{ mode1: '1x4x4', mode2: '1Ax2Bx2Cx2Bx2C' },
	{ mode1: '4x1x4', mode2: '2Ax1Bx2Cx2Ax2C' },
	{ mode1: '4x4x1', mode2: '2Ax2Bx1Cx2Ax2B' },
	{ mode1: '4x4x4', mode2: '2Ax2Bx2Cx2Ax2Bx2C' },
	{ mode1: '4x1x1', mode2: '2Ax1Bx1Cx2A' },
	{ mode1: '1x4x1', mode2: '1Ax2Bx1Cx2B' },
	{ mode1: '1x1x4', mode2: '1Ax1Bx4C' },
	{ mode1: '1x1x1', mode2: '1Ax1Bx1C' },
];

// Converte lista de FeatureProduct de Featured para FeatureGrid
export const convertToMode1 = (
	products: FeatureProduct[],
	layout: GridLayout
): FeatureProduct[] => {
	const layoutMode2: LayoutMode2 = convertStrToLayoutMode2(
		strLayoutMapping.find((l) => l.mode1 === layout)!.mode2
	);
	const sortedProducts = [...products.sort(Sort.Numerically('sort'))];

	let idCounter = 0;
	const converted: Converted[] = layoutMode2.map(({ items, column }) => ({
		column,
		items: [...Array(items).keys()].map(
			(): FeatureProduct => ({
				idProduct: String(-++idCounter),
				idImage: '',
				sort: String(idCounter),
				...sortedProducts.find((p) => Number(p.sort) === idCounter),
				size: items === 1 ? '2' : '255',
			})
		),
	}));

	const combined: Converted[] = converted.reduce((prev, next) => {
		const foundedIdx = prev.findIndex((item) => item.column === next.column);
		if (foundedIdx !== -1) {
			return prev.map((value, idx) =>
				idx === foundedIdx
					? { ...value, items: [...value.items, ...next.items] }
					: value
			);
		}
		return [...prev, next];
	}, [] as Converted[]);

	const sortedConverted = combined.sort(Sort.Alphabetically('column'));

	idCounter = 0;
	return sortedConverted.flatMap((item) =>
		item.items.map((product) => ({
			...product,
			sort: String(++idCounter),
		}))
	);
};

// Converte lista de FeatureProduct de FeatureGrid para Featured
export const convertToMode2 = (
	products: FeatureProduct[],
	layout: GridLayout
): FeatureProduct[] => {
	const layoutMode2: LayoutMode2 = convertStrToLayoutMode2(
		strLayoutMapping.find((l) => l.mode1 === layout)!.mode2
	);
	const sortedProducts = [...products.sort(Sort.Numerically('sort'))];

	const layoutMode2Combined: LayoutMode2 = layoutMode2.reduce(
		(prev, next, _) => {
			const foundedIdx = prev.findIndex((item) => item.column === next.column);
			if (foundedIdx !== -1) {
				return prev.map((value, idx) =>
					idx === foundedIdx
						? { ...value, items: value.items + next.items }
						: value
				);
			}
			return [...prev, next];
		},
		[] as LayoutMode2
	);

	let idCounter = 0;
	const combined: Converted[] = layoutMode2Combined.map(
		({ items, column }) => ({
			column,
			items: [...Array(items).keys()].map(
				(): FeatureProduct => ({
					idProduct: String(-++idCounter),
					idImage: '',
					sort: String(idCounter),
					...sortedProducts.find((p) => Number(p.sort) === idCounter),
					size: items === 1 ? '2' : '255',
				})
			),
		})
	);

	idCounter = 0;
	const converted = layoutMode2.reduce(
		(prev, { items, column }) => [
			...prev,
			...[...Array(items).keys()].map(
				(): FeatureProduct => ({
					// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
					...combined.find((c) => c.column === column)?.items.shift()!,
					sort: String(++idCounter),
				})
			),
		],
		[] as FeatureProduct[]
	);

	return converted.filter((product) => product.idImage);
};

// Reorganiza os dados para montar o grid
export const gridDataObjProcessor = (
	products: FeatureProduct[],
	layout: number[]
): GridColumn[] => {
	const sortedProducts = [...products.sort(Sort.Numerically('sort'))];
	let idCounter = 0;

	return layout.map((gridItems, columnKey) => ({
		columnKey,
		gridItems: [...Array(gridItems).keys()].map(() => ({
			itemKey: idCounter++,
			...sortedProducts.shift(),
		})),
	}));
};

export const getInputEvents = (
	ref: React.MutableRefObject<HTMLInputElement | null>
) => {
	const element = ref.current;
	return {
		select: () => {
			if (!element) return false;
			setTimeout(() => {
				element.select();
			}, 100);
			return true;
		},
		focus: () => {
			if (!element) return false;

			element.focus();
			return true;
		},
		setValue: (value: string) => {
			if (!element) return false;

			const lastValue = element.value;
			element.value = value;
			const event = new Event('input', { bubbles: true });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const tracker = (element as any)._valueTracker;
			if (tracker) {
				tracker.setValue(lastValue);
			}
			element.dispatchEvent(event);
			return true;
		},
	};
};
