import React from 'react';

import Button, { ButtonProps } from 'react-bootstrap/Button';

type FilterButtonProps = Omit<ButtonProps, 'variant' | 'type'> & {
	className?: string;
};

const FilterButton: React.FC<FilterButtonProps> = (props) => (
	<Button variant="primary" type="submit" {...props}>
		Pesquisar
	</Button>
);

export default FilterButton;
