import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { cover, linearGradient } from 'polished';

export type BackdropGradientProps = {
	filter?: {
		iniColor: string;
		endColor?: string;
		iniOpacity: number;
		endOpacity?: number;
		degree?: number;
	};
	zIndex?: number;
};

const hexToRGB = (hex: string): string => {
	let r = '';
	let g = '';
	let b = '';
	// Converte para base decimal porque alguns navegadores não aceitam rgba() com números hexa dentro
	if (hex.length === 4) {
		r = Number(`0x${hex[1]}${hex[1]}`).toString(10);
		g = Number(`0x${hex[2]}${hex[2]}`).toString(10);
		b = Number(`0x${hex[3]}${hex[3]}`).toString(10);
	} else {
		r = Number(`0x${hex[1]}${hex[2]}`).toString(10);
		g = Number(`0x${hex[3]}${hex[4]}`).toString(10);
		b = Number(`0x${hex[5]}${hex[6]}`).toString(10);
	}
	return `${r},${g},${b}`;
};

const BackdropGradient = styled.div`
	${cover()}
	z-index: ${({ zIndex }) => zIndex || 0};
	${({ filter }: BackdropGradientProps): FlattenSimpleInterpolation => {
		const colors = ['0,0,0', '0,0,0'];
		const opacities = [0, 0];
		let degree = 0;

		if (filter) {
			colors[0] = hexToRGB(filter.iniColor);
			opacities[0] = filter.iniOpacity;
			colors[1] = filter.endColor ? hexToRGB(filter.endColor) : colors[0];
			opacities[1] = filter.endOpacity ?? opacities[0];
			degree = filter.degree || 0;
		}

		return css`
			${linearGradient({
				colorStops: [
					`rgba(${colors[0]}, ${opacities[0]})`,
					`rgba(${colors[1]}, ${opacities[1]})`,
				],
				toDirection: `${degree}deg`,
				fallback: `rgba(${colors[0]}, 0.0)`,
			})}
		`;
	}}
`;

export default BackdropGradient;
