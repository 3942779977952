import React, { useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { faClock, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { faBoxOpen, faStar } from '@fortawesome/pro-solid-svg-icons';

import { Skeleton } from 'components';
import { useToast } from 'hooks';
import orderService from 'services/order.service';
import { OrderQuantitative } from 'types';
import { numberFormatter } from 'utils/formatters';

import Widget from './orderWidgets/Widget';

type WidgetsData = {
	total: number;
	waitingPayment: number;
	expedition: number;
	revenues: number;
};

const dataMap: Record<OrderQuantitative['name'], keyof WidgetsData> = {
	TOTAL: 'total',
	AGUARDANDOPAG: 'waitingPayment',
	EXPEDICAO: 'expedition',
	FATURAMENTO: 'revenues',
};

const OrderWidgets: React.FC = () => {
	const [toast] = useToast();

	const { data, isLoading } = useQuery<OrderQuantitative[]>(
		['order_widgets'],
		() => orderService.getOrderQuantitative().then((r) => r.data),
		{
			onError: () => {
				toast('Não foi possível carregar os Widgets informativos', {
					type: 'error',
				});
			},
		}
	);

	const widgets = useMemo(() => {
		return data?.reduce((prev, next) => {
			return next.name
				? { ...prev, [dataMap[next.name]]: Number(next.value) }
				: prev;
		}, {} as WidgetsData);
	}, [data]);

	return (
		<Row>
			<Col md={6} lg={3}>
				{/* TODO: Fazer Widget aceitar somente variantColors para usar cores do tema */}
				<Widget color="#B688D8" icon={faStar}>
					<Widget.Column>
						{isLoading ? (
							<Skeleton height="2rem" width="80%" className="mb-2" />
						) : (
							<Widget.Title>
								{numberFormatter.format(widgets?.total ?? 0)}
							</Widget.Title>
						)}
						<Widget.Info>Total de Pedidos (Geral)</Widget.Info>
					</Widget.Column>
					{/* <Widget.TopRight /> */}
				</Widget>
			</Col>
			<Col md={6} lg={3}>
				<Widget color="#F2B772" icon={faClock}>
					<Widget.Column>
						{isLoading ? (
							<Skeleton height="2rem" width="80%" className="mb-2" />
						) : (
							<Widget.Title>
								{numberFormatter.format(widgets?.waitingPayment ?? 0)}
							</Widget.Title>
						)}
						<Widget.Info>Aguardando Pagamento</Widget.Info>
					</Widget.Column>
					{/* <Widget.TopRight /> */}
				</Widget>
			</Col>
			<Col md={6} lg={3}>
				<Widget color="#212529" icon={faBoxOpen}>
					<Widget.Column>
						{isLoading ? (
							<Skeleton height="2rem" width="80%" className="mb-2" />
						) : (
							<Widget.Title>
								{numberFormatter.format(widgets?.expedition ?? 0)}
							</Widget.Title>
						)}
						<Widget.Info>Na Expedição</Widget.Info>
					</Widget.Column>
					{/* <Widget.TopRight /> */}
				</Widget>
			</Col>
			<Col md={6} lg={3}>
				<Widget color="#ADDD87" icon={faDollarSign}>
					<Widget.Column>
						{isLoading ? (
							<Skeleton height="2rem" width="80%" className="mb-2" />
						) : (
							<Widget.Title>
								{numberFormatter.format(widgets?.revenues ?? 0)}
							</Widget.Title>
						)}
						<Widget.Info>Em Faturamento</Widget.Info>
					</Widget.Column>
					{/* <Widget.TopRight /> */}
				</Widget>
			</Col>
		</Row>
	);
};

export default OrderWidgets;
