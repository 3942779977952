import {
	MachineConfig,
	MachineOptions,
	Machine,
	assign,
	sendParent,
	Actor,
	Interpreter,
	State,
} from 'xstate';

import expeditionService from 'services/expedition.service';
import {
	OrderShipment,
	PersonAddress,
	HttpBaseResult,
	OrderShipmentConsts,
} from 'types';

export type ShipmentSchema = {
	states: {
		idle: {};
		fetching: {};
	};
};

export type ShipmentContext = {
	options: OrderShipment[];
	selected?: OrderShipment;
	address?: PersonAddress;
	error?: HttpBaseResult;
};

// Eventos internos do statecharts de seleção de endereço
export type ShipmentEvent =
	| { type: 'LOAD_OPTIONS_FOR_ADDRESS'; address: PersonAddress; weight: number }
	| { type: 'SELECT'; option: OrderShipment };

// Eventos disparados para a máquina de estados pai
export type ShipmentParentEvent =
	| { type: 'SHIPMENT_SELECTED'; option: OrderShipment }
	| { type: 'FETCHING_OPTIONS' };

export type ShipmentEventWithItem = {
	type: string;
	address?: PersonAddress;
	option?: OrderShipment;
};

export const ShipmentMachineConfig = (
	id: string
): MachineConfig<ShipmentContext, ShipmentSchema, ShipmentEvent> => ({
	id,
	initial: 'idle',
	context: {
		options: [],
	},
	states: {
		idle: {
			on: {
				LOAD_OPTIONS_FOR_ADDRESS: {
					target: `#${id}.fetching`,
					actions: [
						'resetOptions',
						assign({
							address: (_, event) => event.address,
						}),
					],
				},
				SELECT: {
					actions: [
						assign({
							selected: (_, event) => event.option,
						}),
						sendParent((ctx) => ({
							type: 'SHIPMENT_SELECTED',
							option: ctx.selected,
						})),
					],
				},
			},
		},
		fetching: {
			entry: sendParent('FETCHING_OPTIONS'),
			invoke: {
				src: 'loadOptionsForAddressEffect',
				onDone: {
					target: `#${id}.idle`,
					actions: assign({
						options: (_, event) => event.data,
					}),
				},
				onError: {
					target: `#${id}.idle`,
					actions: assign({
						error: (_, event) => event.data,
					}),
				},
			},
		},
	},
});

export const ShipmentMachineOptions: MachineOptions<
	ShipmentContext,
	ShipmentEvent
> = {
	guards: {},
	actions: {
		resetOptions: (ctx) => ({
			...ctx,
			options: [],
			error: undefined,
		}),
	},
	services: {
		loadOptionsForAddressEffect: () =>
			expeditionService
				.getExpeditions({
					pagination: { page: 1, paginate: 999 },
				})
				.then((result) =>
					result.data.map(
						(expedition, index) =>
							({
								idShipment: index + 1,
								idExpedition: expedition.idExpedition,
								name: expedition.name,
								shipmentType: OrderShipmentConsts.WithdrawOnStore,
								price: 0,
								deliveryTime: 0,
								observation: '',
							} as OrderShipment)
					)
				)
				.catch(() =>
					Array(3)
						.fill(undefined)
						.map((_, index) => ({
							idShipment: index + 1,
							idExpedition: index + 1,
							name: `Filial 0${index + 1}`,
							shipmentType: OrderShipmentConsts.WithdrawOnStore,
							price: 0,
							deliveryTime: 0,
							observation: '',
						}))
				),
	},
	activities: {},
	delays: {},
};

export type ShipmentActor = Actor<ShipmentContext>;

export type ShipmentState = State<
	ShipmentContext,
	ShipmentEvent,
	ShipmentSchema
>;

export type ShipmentInterpreter = Interpreter<
	ShipmentContext,
	ShipmentSchema,
	ShipmentEvent
>;

export default (id: string) =>
	Machine(ShipmentMachineConfig(id), ShipmentMachineOptions);
