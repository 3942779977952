import React, {
	useEffect,
	useState,
	useRef,
	ElementRef,
	useCallback,
} from 'react';

import Image from 'react-bootstrap/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { tuple } from 'utils/type';

import { AvatarStyled } from './avatar/styles';

const shapeTypes = tuple('square', 'circle');
type ShapeTypes = typeof shapeTypes[number];

const sizeTypes = tuple('large', 'default', 'small');
type SizeTypes = typeof sizeTypes[number];

export interface AvatarProps {
	shape?: ShapeTypes;
	size?: SizeTypes | number;
	text?: string;
	src?: string;
	alt?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon?: any;
	style?: React.CSSProperties;
	className?: string;
}

export interface UiSizeMap {
	[size: string]: string;
}

const defaultProps = {
	shape: 'circle',
	size: 'default',
};

const Avatar = (props: AvatarProps) => {
	const { shape, size, icon, src, text, alt, style, className } = props;

	const [hasText, setHasText] = useState<boolean>(false);
	const [hasSrc, setHasSrc] = useState<boolean>(true);
	const [hasIcon, setHasIcon] = useState<boolean>(false);
	const [textElWidth, setTextElWidth] = useState<number>(0);
	const [elWidth, setElWidth] = useState<number>(0);
	const [textStyles, setTextStyles] = useState<React.CSSProperties>({});
	const [sizeStyles, setSizeStyles] = useState<React.CSSProperties>({});

	const sizeMap: UiSizeMap = { large: 'large', small: 'small' };
	const sizeMapSet = size ? sizeMap[size] : false;
	const textEl = useRef<ElementRef<'span'>>(null);
	const el = useRef<ElementRef<'span'>>(null);

	const calcStringSize = useCallback(() => {
		if (!hasText) {
			return;
		}

		const scale =
			elWidth && textElWidth && elWidth - 8 < textElWidth
				? (elWidth - 8) / textElWidth
				: 1;
		setTextStyles({ transform: `scale(${scale}) translateX(-50%)` });
		if (typeof size === 'number') {
			setTextStyles({ ...textStyles, lineHeight: `${size}px` });
		}
	}, [elWidth, hasText, size, textElWidth, textStyles]);

	function setSizeStyle(): void {
		const sizeStyle = typeof size === 'string' ? size : `${size}px`;
		setSizeStyles({
			width: sizeStyle,
			height: sizeStyle,
			lineHeight: sizeStyle,
		});
		if (hasIcon) {
			setSizeStyles({ ...sizeStyles, fontSize: `calc(${sizeStyle} / 2)` });
		}
	}

	useEffect(() => {
		setHasText(!src && !!text);
		setHasIcon(!src && !!icon);
		setHasSrc(!!src);

		calcStringSize();
		setSizeStyle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTextElWidth(textEl.current ? textEl.current.offsetWidth : 0);
		calcStringSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textEl.current]);

	useEffect(() => {
		setElWidth(el.current ? el.current.offsetWidth : 0);
		calcStringSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [el.current]);

	return (
		<AvatarStyled
			className={classNames(className, {
				[`is-${sizeMapSet}`]: sizeMapSet,
				[`${shape}`]: shape,
				'has-icon': icon,
				'has-image': hasSrc,
			})}
			style={{ ...style, ...sizeStyles }}
			ref={el}
		>
			{icon && hasIcon && <FontAwesomeIcon icon={icon} />}
			{src && hasSrc && <Image src={src} alt={alt} />}
			{text && (
				<span className="string" ref={textEl} style={textStyles}>
					{text}
				</span>
			)}
		</AvatarStyled>
	);
};

Avatar.displayName = 'Avatar';
Avatar.defaultProps = defaultProps;
Avatar.whyDidYouRender = true;

export default Avatar;
