import React from 'react';

import classNames from 'classnames';
import omit from 'omit.js';

import {
	RcDrawerStyle,
	DrawerWrapperBodyStyle,
	DrawerBodyStyle,
	DrawerHeaderStyle,
	DrawerFooterStyle,
} from './drawer/styles';

type EventType =
	| React.KeyboardEvent<HTMLDivElement>
	| React.MouseEvent<HTMLDivElement | HTMLButtonElement>;

type GetContainerFunc = () => HTMLElement;

const placementTypes = ['top', 'right', 'bottom', 'left'];
type PlacementType = typeof placementTypes[number];

export interface DrawerProps {
	closable?: boolean;
	forceRender?: boolean;
	getContainer?: string | HTMLElement | GetContainerFunc | false;
	maskClosable?: boolean;
	mask?: boolean;
	maskStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	drawerStyle?: React.CSSProperties;
	visible?: boolean;
	width?: number | string;
	height?: number | string;
	zIndex?: number;
	placement?: PlacementType;
	onClose?: (e: EventType) => void;
	afterVisibleChange?: (visible: boolean) => void;
	className?: string;
	handler?: React.ReactNode;
	keyboard?: boolean;
	direction?: string;
}

const defaultProps = {
	closable: true,
	placement: 'right' as PlacementType,
	maskClosable: true,
	mask: true,
	keyboard: true,
};

type DrawerComponent = React.FC<DrawerProps> & {
	Header: typeof DrawerHeaderStyle;
	Body: typeof DrawerBodyStyle;
	Footer: typeof DrawerFooterStyle;
};

const Drawer: DrawerComponent = (props) => {
	const {
		className,
		mask,
		zIndex,
		visible,
		drawerStyle,
		children,
		onClose,
		...rest
	} = props;

	const drawerClassName = classNames(className, mask === false && 'no-mask');

	return (
		<RcDrawerStyle
			handler={false}
			{...omit(rest, [
				'closable',
				'drawerStyle',
				'locale',
				'push',
				'visible',
				'getPopupContainer',
				'renderEmpty',
				'csp',
				'pageHeader',
				'autoInsertSpaceInButton',
			])}
			zIndex={zIndex}
			open={visible}
			showMask={mask}
			className={drawerClassName}
			onClose={onClose}
			level={null}
		>
			<DrawerWrapperBodyStyle style={drawerStyle}>
				{children}
			</DrawerWrapperBodyStyle>
		</RcDrawerStyle>
	);
};

Drawer.Header = DrawerHeaderStyle;
Drawer.Body = DrawerBodyStyle;
Drawer.Footer = DrawerFooterStyle;

Drawer.displayName = 'Drawer';
Drawer.defaultProps = defaultProps;
Drawer.whyDidYouRender = true;

export default Drawer;
