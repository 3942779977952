import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
} from 'types';
import {
	OrderAttachType,
	OrderExpeditionType,
	OrderForList,
	OrderQuantitative,
	OrderMonthSummary,
	OrderPartialPayment,
	Order,
} from 'types/order.types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<OrderForList>> =>
	Crud.getList<OrderForList>('my/order', params);

const getOrdersForCompany = (
	params: HttpGetListParams
): Promise<HttpGetListResult<OrderForList>> => {
	return Crud.getList<OrderForList>('company/{id}/order', params);
};

const updateOrderStatus = (
	id: ID,
	idStatus: number
): Promise<HttpPostResultAny> => Crud.patch('order/{id}', id, { idStatus });

const getAttach = (params: HttpGetListParams): Promise<OrderAttachType[]> =>
	Crud.getListData<OrderAttachType>('order/{id}/attach', params);

const saveAttach = (data: OrderAttachType): Promise<HttpPostResultAny> =>
	Crud.create('order/attach', data);

const deleteAttach = (id: ID): Promise<HttpPostResultAny> =>
	Crud.delete('order/attach/{id}', id);

// Essa rota exige o idCompany como parâmetro
const getOrderHistoryByCompany = async (
	params: HttpGetListParams = {}
): Promise<HttpGetListResult<Order>> =>
	Crud.getList<Order>('order?idCompany={idCompany}', params);

const getOrderExpedition = (id: ID): Promise<{ data: OrderExpeditionType }> =>
	Crud.get<{ data: OrderExpeditionType }>(`order/${id}/expedition`);

const getOrderQuantitative = (): Promise<
	HttpGetListResult<OrderQuantitative>
> => Crud.getList('/order/quantitative', {});

const getOrderMonthSummary = (): Promise<
	HttpGetListResult<OrderMonthSummary>
> => Crud.getList('/order/summarymonth', {});

const getPartialPayments = (idOrder: ID): Promise<OrderPartialPayment[]> =>
	Crud.getListData<OrderPartialPayment>(`order/${idOrder}/payment`, {});

export default {
	getForList,
	getOrdersForCompany,
	updateOrderStatus,
	getAttach,
	saveAttach,
	deleteAttach,
	getOrderHistoryByCompany,
	getOrderExpedition,
	getOrderQuantitative,
	getOrderMonthSummary,
	getPartialPayments,
};
