import styled from 'styled-components';

export const StyledModalHeader = styled.div`
	padding: 1rem;
	min-width: min-content;
	font-weight: 500;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	> :not(:first-child) {
		margin-left: 1.5rem;
	}
`;

export const CloseButton = styled.div`
	width: 1.6rem;
	height: 1.8rem;
	font-size: 1.5rem;
	font-weight: 700;
	color: var(--gray-50);

	cursor: pointer;
	border-radius: var(--border-radius);

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: var(--gray-80);
		background-color: rgba(0, 0, 0, 0.1);
	}
`;
