import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import * as Yup from 'yup';

import { Input, Select } from 'components/Formik';
import correiosService from 'services/correios.service';
import countryStateService from 'services/country-state.service';
import { RegionState, PersonAddress, RegionCity } from 'types';
import { cepFormatter, numberFormatter } from 'utils/formatters';

export const defaultFormValue: PersonAddress = {
	idAddress: null,
	name: 'Endereço Padrão',
	number: '',
	active: true,
	cep: '',
	district: '',
	complement: '',
	streetName: '',
	isBilling: true,
	state: null,
	city: null,
};

export const formSchema = Yup.object<PersonAddress>().shape({
	idAddress: Yup.mixed()
		.transform((val) => (!val ? null : Number(val)))
		.nullable()
		.notRequired(),
	name: Yup.string().required('O nome é obrigatório'),
	number: Yup.string().required('O número é obrigatório'),
	active: Yup.boolean(),
	district: Yup.string().required('O bairro é obrigatório'),
	complement: Yup.string().nullable().notRequired(),
	streetName: Yup.string().required('Endereço é obrigatório'),
	cep: Yup.string()
		.required('CEP é obrigatório')
		.test('len', 'CEP inválido', (val) => {
			const cep = val ? val.replace('-', '') : '';
			return cep.length === 8;
		}),
	isBilling: Yup.boolean(),
	state: Yup.object().nullable().required('UF obrigatório'),
	city: Yup.object().nullable().required('Cidade é obrigatória'),
});

type AddressFormProps = {
	path: string;
	isLoading?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({
	path,
	isLoading = false,
}) => {
	const [states, setStates] = useState<RegionState[]>([]);
	const [cities, setCities] = useState<RegionCity[]>([]);
	const [isLoadingCep, setIsLoadingCep] = useState<boolean>(false);
	const formikContext = useFormikContext<PersonAddress>();
	const formikCtx = useMemo(() => formikContext, [formikContext]);

	const onStateChange = useCallback(
		async (item, isFirstLoad, cityCep?) => {
			if (!isFirstLoad) {
				const state = item;
				const { value: oldState } = formikCtx.getFieldMeta(`${path}.state`);
				if (oldState === state) {
					return;
				}

				formikCtx.setFieldValue(`${path}.city`, undefined);
			}

			if (!item) {
				return;
			}
			try {
				const citiesByState = await countryStateService.getCitiesForState(
					item.idState
				);
				if (cityCep) {
					const city = citiesByState.find((c) => c.name === cityCep);
					formikCtx.setFieldValue(`${path}.city`, city);
				}
				setCities(citiesByState);
			} catch (e) {
				setCities([]);
			}
		},
		[setCities, path, formikCtx]
	);

	const onCEPChange = useCallback(
		async (cep, event) => {
			if (!event) return;
			formikCtx.setFieldTouched(`${path}.cep`, true);
			if (cep && cep.length === 8) {
				setIsLoadingCep(true);
				try {
					const addressInfo = await correiosService.getAddressForCEP(cep);

					// TODO:
					// Api não deveria retornar array vazio quando não acha o CEP
					if (Array.isArray(addressInfo)) {
						formikCtx.setFieldError(`${path}.cep`, 'CEP não encontrado');
					} else {
						const { value: addrValues } = formikCtx.getFieldMeta<PersonAddress>(
							path
						);

						const state = states.find((s) => {
							return s.uf === addressInfo.uf.trim();
						});

						formikCtx.setFieldValue(path, {
							...addrValues,
							streetName: addressInfo.logradouro,
							district: addressInfo.bairro,
							state,
							cep,
						});

						onStateChange(state, false, addressInfo.cidade);
					}
				} catch (e) {
					formikCtx.setFieldError(`${path}.cep`, 'CEP não encontrado');
				} finally {
					setIsLoadingCep(false);
				}
			}
		},
		[formikCtx, onStateChange, path, states]
	);

	useEffect(() => {
		const { value } = formikCtx.getFieldMeta(`${path}.state`);
		if (value) {
			onStateChange(value, true);
		}
	}, [formikCtx, onStateChange, path, states]);

	useEffect(() => {
		countryStateService
			.getStates()
			.then((s) => setStates(s))
			.catch(() => setStates([]));
	}, []);

	return (
		<>
			<Input className="d-none" name={`${path}.idAddress`} />
			<Input className="d-none" name={`${path}.name`} />
			<Form.Row>
				<Form.Group as={Col} md={4}>
					<Form.Label>CEP</Form.Label>
					<Input
						name={`${path}.cep`}
						formatter={cepFormatter}
						onChange={onCEPChange}
						disabled={isLoading || isLoadingCep}
						append={
							isLoadingCep && (
								<InputGroup.Text>
									<Spinner animation="border" size="sm" />
								</InputGroup.Text>
							)
						}
					/>
				</Form.Group>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col} lg={6}>
					<Form.Label>Endereço</Form.Label>
					<Input
						name={`${path}.streetName`}
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>

				<Form.Group as={Col} md={6} lg={2}>
					<Form.Label>Número</Form.Label>
					<Input
						name={`${path}.number`}
						formatter={numberFormatter}
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>

				<Form.Group as={Col} md={6} lg={4}>
					<Form.Label>Complemento</Form.Label>
					<Input
						name={`${path}.complement`}
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col} md={4} lg={2}>
					<Form.Label>Estado</Form.Label>
					<Select
						name={`${path}.state`}
						options={states}
						optionLabel="uf"
						optionValue="idState"
						onChange={(value) => onStateChange(value, false)}
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>

				<Form.Group as={Col} md={8} lg={4}>
					<Form.Label>Cidade</Form.Label>
					<Select
						name={`${path}.city`}
						isLoading={!cities}
						options={cities}
						optionLabel="name"
						optionValue="idCity"
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>

				<Form.Group as={Col} lg={6}>
					<Form.Label>Bairro</Form.Label>
					<Input
						name={`${path}.district`}
						disabled={isLoading || isLoadingCep}
					/>
				</Form.Group>
			</Form.Row>
		</>
	);
};

export default AddressForm;
