import React from 'react';

import { faHeadset, faTag, faTags } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const SupportRoutes: RouteDef = {
	path: '/admin/support',
	menu: {
		name: 'Suporte',
		icon: faTags,
	},
	children: [
		{
			path: '/tickets/progress',
			menu: {
				name: 'Atend. de Chamados',
				icon: faHeadset,
			},
		},
		{
			path: '/tickets/all',
			render: getListSceneRenderFn(listConfigs.SupportTickets),
			exact: true,
			menu: {
				name: 'Todos os Chamados',
				icon: faTags,
				separator: true,
			},
		},
		{
			path: '/tickets/summary',
			menu: {
				name: 'Chamados de TI',
				icon: faTag,
			},
		},
	],
};

export default SupportRoutes;
