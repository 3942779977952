export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const tuple = <T extends string[]>(...args: T) => args;

export const tupleNum = <T extends number[]>(...args: T) => args;

/**
 * Extract the type of an element of an array/tuple without performing indexing
 */
export type ElementOf<T> = T extends (infer E)[]
	? E
	: T extends readonly (infer E)[]
	? E
	: never;

export type EventType =
	| React.KeyboardEvent<HTMLDivElement>
	| React.MouseEvent<HTMLDivElement | HTMLButtonElement>;

/**
 * Torna uma propriedade optional em required
 */
export type Require<T, P extends keyof T> = Omit<T, P> & Pick<Required<T>, P>;

/**
 * Retorna as keys do tipo cujo o subtipo equivale à condição
 *
 * Ex:
 * type One = { id: number, name: string, street: string }
 * KeysSubtypeFilter<One, string> equivale à 'name' | 'street' que são as
 * keys do tipo string em One
 *
 * Para obter um novo tipo com somente as propriedades do tipo string em One use:
 * Pick<One, KeysSubtypeFilter<One, string>>
 */
export type KeysOfFilter<Base, SubtypeCondition> = {
	[Key in keyof Base]: Base[Key] extends SubtypeCondition ? Key : never;
}[keyof Base];
