/* eslint-disable no-nested-ternary */
import React, {
	useCallback,
	ChangeEvent,
	useState,
	useRef,
	useEffect,
	useMemo,
} from 'react';

import { useField } from 'formik';
import { Button } from 'react-bootstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { AspectRatio, LoadingSpinnerOverlay } from 'components';
import { AspectRatioProps } from 'components/AspectRatio';
import uploadHelpers from 'components/upload/Helpers';
import {
	UploadFile,
	UploadListType,
	UploadType,
	PictureBoxProps,
	NewContainerProps,
	Options,
	Attach,
} from 'components/upload/types';

import {
	ActionPictureButtons,
	UploadItem,
	UploadItems,
	PictureBox,
	Container,
} from '../upload/styles';

export interface UploadProps {
	name: string;
	id?: string;
	items?: UploadFile[];
	type?: UploadType;
	listType?: UploadListType;
	disabled?: boolean;
	accept?: string;
	maxSize?: number;
	multiple?: boolean;
	showUploadList?: boolean;
	width?: string | number;
	height?: string | number;
	aspect?: AspectRatioProps['aspect'];
	minHeight?: AspectRatioProps['minHeight'];
	objectFit?: PictureBoxProps['objectFit'];
	displayInline?: NewContainerProps['displayInline'];
	onClick?: (event: React.MouseEvent) => void;
	style?: React.CSSProperties;
	className?: string;
	children?: React.ReactNode & { type?: { displayName?: string } };
	itemIdProp?: Options<Attach>['itemIdProp'];
	itemNameProp?: Options<Attach>['itemNameProp'];
	itemLastModifiedProp?: Options<Attach>['itemLastModifiedProp'];
	itemUrlProp?: Options<Attach>['itemUrlProp'];
}

const defaultProps: Partial<UploadProps> = {
	type: 'select',
	accept: '',
	listType: 'text',
	showUploadList: true,
	aspect: 'fill',
	minHeight: '10rem',
};

const createBase64 = (
	file: File,
	callback: (file: string | ArrayBuffer | null) => void
) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.addEventListener('load', () => callback(reader.result));
};

const Upload: React.FC<UploadProps> = ({
	name,
	multiple,
	type,
	children,
	onClick,
	disabled,
	accept,
	maxSize,
	listType,
	displayInline,
	width,
	height,
	aspect,
	minHeight,
	objectFit,
	showUploadList,
	style,
	className,
	itemIdProp,
	itemNameProp,
	itemLastModifiedProp,
	itemUrlProp,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [, { initialValue, error, touched }, { setValue }] = useField<
		string | Attach[] | UploadFile[]
	>(name);
	const [files, setFiles] = useState<UploadFile[]>([]);
	const [dragState, setDragState] = useState<string>('drop');
	const [loading, setLoading] = useState<boolean>(true);

	const filesToUploadFiles = useCallback(
		(fileItems: FileList) => {
			const filesArray = Array.prototype.slice.call(fileItems);

			const result = filesArray.map(
				(file: File, idx: number): UploadFile => {
					if (accept) {
						const fileAccept = uploadHelpers.attrAccept(file, accept);
						if (!fileAccept) {
							return {
								uid: uploadHelpers.uid(idx),
								name: '',
								size: file.size,
								preview: null,
								url: '',
								type: file.type,
								icon: uploadHelpers.getIconForFile(file),
								originFileObj: file,
								error: `Tipo de arquivo não aceito, adicione arquivos do tipo: ${accept}`,
							};
						}
					}

					if (maxSize) {
						const fileMaxSizeAccept = uploadHelpers.maxFileAccept(
							file,
							maxSize
						);
						if (!fileMaxSizeAccept) {
							return {
								uid: uploadHelpers.uid(idx),
								name: '',
								size: file.size,
								preview: null,
								url: '',
								type: file.type,
								icon: uploadHelpers.getIconForFile(file),
								originFileObj: file,
								error: `Tamanho do arquivo inválido, adicione arquivos de no máximo: ${maxSize}MB`,
							};
						}
					}

					return {
						uid: uploadHelpers.uid(idx),
						name: file.name,
						fileName: file.name,
						size: file.size,
						preview: uploadHelpers.attrAccept(file, 'image/*')
							? URL.createObjectURL(file)
							: null,
						url: null,
						icon: uploadHelpers.getIconForFile(file),
						type: file.type,
						lastModified: file.lastModified,
						lastModifiedDate: new Date(file.lastModified),
						originFileObj: file,
						error: null,
					};
				}
			);

			result.forEach((file) => {
				createBase64(file.originFileObj as File, (fileUrl) => {
					// eslint-disable-next-line no-param-reassign
					file.url = file.error ? null : (fileUrl as string);
				});
			});

			return result;
		},
		[accept, maxSize]
	);

	const mapFile = useMemo(
		() =>
			uploadHelpers.mapToUploadFile({
				itemIdProp,
				itemNameProp,
				itemLastModifiedProp,
				itemUrlProp,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Proteção contra cache no navegador
	const isoDate = useMemo(() => new Date().toISOString(), []);

	useEffect(() => {
		(async () => {
			if (initialValue) {
				if (!loading) {
					setLoading(true);
				}

				let newFiles: UploadFile[];
				if (Array.isArray(initialValue)) {
					if (!uploadHelpers.isUploadFileArray(initialValue)) {
						newFiles = initialValue.map(mapFile);
					} else {
						newFiles = initialValue;
					}
				} else {
					newFiles = [mapFile(initialValue)];
				}

				setFiles(newFiles);
			}

			if (loading) {
				setLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue]);

	const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
		const newFiles = e.target.files;
		if (!newFiles) {
			setFiles([]);
			setValue([]);
			return;
		}

		const filesChanged = filesToUploadFiles(newFiles);

		if (multiple === false) {
			setFiles(filesChanged);
			setValue(filesChanged);
			return;
		}

		const concatFiles = filesChanged.concat(files || []);
		setFiles(concatFiles);
		setValue(concatFiles);
	};

	const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragState(e.type);

		if (e.type === 'dragover' || e.type === 'dragleave') return;

		const filesChanged = filesToUploadFiles(e.dataTransfer.files);

		if (multiple === false) {
			setFiles(filesChanged);
			setDragState('drop');
			return;
		}

		const concatFiles = filesChanged.concat(files || []);
		setFiles(concatFiles);
		setValue(concatFiles);
		setDragState('drop');
	};

	const handleOnClick = (e: React.MouseEvent) => {
		const el = inputRef.current;

		if (!el) {
			return;
		}

		if (children && children?.type?.displayName === 'Button') {
			el.focus();
		}
		el.click();
		if (onClick) {
			onClick(e);
		}
	};

	const removeFile = useCallback(
		(index: number) => {
			const itemsRemoved = [...files];
			itemsRemoved.splice(index, 1);
			setFiles(itemsRemoved);
			setValue(itemsRemoved);

			if (inputRef && inputRef.current) {
				inputRef.current.value = '';
			}
		},
		[files, setValue]
	);

	return loading ? (
		<Container
			style={style}
			className={className}
			width={width}
			height={height}
		>
			<AspectRatio aspect={aspect} minHeight={minHeight}>
				<PictureBox>
					<LoadingSpinnerOverlay />
				</PictureBox>
			</AspectRatio>
		</Container>
	) : (
		<Container
			displayInline={displayInline}
			disabled={disabled}
			style={style}
			className={className}
			width={width}
			height={height}
		>
			<input
				type="file"
				ref={inputRef}
				onClick={(e: React.MouseEvent) => e.stopPropagation()}
				style={{ display: 'none' }}
				multiple={multiple}
				accept={accept}
				onChange={onChangeFile}
				disabled={disabled}
				{...rest}
			/>

			{type === 'drag' && (
				<AspectRatio
					aspect={aspect}
					minHeight={minHeight}
					onDrop={onFileDrop}
					onDragOver={onFileDrop}
					onDragLeave={onFileDrop}
				>
					<PictureBox
						hasError={!!(touched && error)}
						dragHover={dragState === 'dragover'}
						onClick={handleOnClick}
						objectFit={objectFit}
					>
						{children}
					</PictureBox>
				</AspectRatio>
			)}

			{type === 'select' && (listType === 'text' || listType === 'picture') && (
				<span
					role="button"
					tabIndex={0}
					aria-hidden="true"
					onClick={handleOnClick}
				>
					{children}
				</span>
			)}

			{type === 'select' &&
				listType === 'picture-card' &&
				(files && files[0] ? (
					<>
						<AspectRatio aspect={aspect} minHeight={minHeight}>
							<PictureBox hasError={!!(touched && error)} objectFit={objectFit}>
								{files[0].error || error ? (
									<p>{files[0].error || error}</p>
								) : files[0].preview ? (
									<img src={files[0].preview} alt={files[0].name} />
								) : (
									files[0].url && (
										<img
											src={`${files[0].url}?d=${isoDate}`}
											alt={files[0].name}
										/>
									)
								)}
							</PictureBox>
						</AspectRatio>

						<ActionPictureButtons>
							{(!files[0].error || !!error) && files[0].preview && (
								<Button
									href={files[0].preview}
									as="a"
									size="sm"
									variant="info"
									target="_blank"
									title="Visualizar"
									className="mr-2"
									disabled={disabled}
								>
									<FAIcon icon="eye" fixedWidth />
								</Button>
							)}

							<Button
								type="button"
								variant="danger"
								size="sm"
								onClick={() => removeFile(0)}
								title="Remover"
								disabled={disabled}
							>
								<FAIcon icon={faTrash} fixedWidth />
							</Button>
						</ActionPictureButtons>
					</>
				) : (
					<AspectRatio aspect={aspect} minHeight={minHeight}>
						<PictureBox
							hasError={!!(touched && error)}
							onClick={handleOnClick}
							objectFit={objectFit}
						>
							{children}
						</PictureBox>
					</AspectRatio>
				))}

			{showUploadList && files && (
				<UploadItems>
					{files.map((file, index) => (
						<UploadItem
							key={file.uid}
							hasError={!!file.error || !!error}
							picture={listType === 'picture'}
						>
							{file.preview && listType === 'picture' ? (
								<a
									href={file.preview}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={file.preview} alt={file.name} />
								</a>
							) : (
								<a
									href={file.url || ''}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FAIcon icon={file.icon as IconProp} />
								</a>
							)}

							<span>{file.error || error || file.name}</span>

							<Button
								type="button"
								variant="light"
								onClick={() => removeFile(index)}
								disabled={disabled}
							>
								<FAIcon icon={faTrash} />
							</Button>
						</UploadItem>
					))}
				</UploadItems>
			)}

			{!!(touched && error && typeof error === 'string') && (
				<span className="invalid-feedback d-block">{error}</span>
			)}
		</Container>
	);
};

Upload.displayName = 'Upload';
Upload.defaultProps = defaultProps;
Upload.whyDidYouRender = true;

export default Upload;
