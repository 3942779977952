import { RouteDef } from 'types';

import JoinScene from './pages/Join';
import JoinAddressScene from './pages/JoinAddress';
import LoginScene from './pages/Login';
import LogoutScene from './pages/Logout';
import RedirectScene from './pages/Redirect';

const SessionRoutes: RouteDef[] = [
	{ path: '/login', component: LoginScene },
	{ path: '/logout', component: LogoutScene },
	{ path: '/join', component: JoinScene },
	{ path: '/joinaddress', component: JoinAddressScene },
	{ path: '/redirect', component: RedirectScene },
];

export default SessionRoutes;
