import React from 'react';

import { Card, Skeleton } from 'components';

const RecognitionSkeleton: React.FC = () => (
	<div className="d-flex flex-wrap">
		{[...Array(3).keys()].map((key) => (
			<Card
				key={key}
				className="mr-3"
				style={{
					width: '100%',
					minWidth: '200px',
					maxWidth: '300px',
				}}
			>
				<Card.Header
					separator
					justifyContent="space-between"
					alignItems="center"
				>
					<Card.Title>
						<Skeleton width="100px" height="2rem" />
					</Card.Title>
					<div>
						<Skeleton width="30px" height="2rem" />
					</div>
				</Card.Header>
				<Card.Content className="d-flex flex-column align-items-center justify-content-center">
					<Skeleton width="60%" height="12rem" style={{ minWidth: '150px' }} />
				</Card.Content>
			</Card>
		))}
	</div>
);

export default RecognitionSkeleton;
