import React, { useEffect, useState } from 'react';

import {
	QueryCache,
	ReactQueryCacheProvider,
	ReactQueryConfig,
	ReactQueryConfigProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import echarts from 'echarts';
import { Action } from 'history';
import { cover } from 'polished';

import SessionContainer from 'components/SessionContainer';
import echartsTheme from 'config/echarts_theme.json';
import { createApi } from 'services/api';
import history from 'utils/history';

import RootRouter from 'packages/rootRoutes';

library.add(far, fal, fad, fas, fab);

const FullScreen = styled.div`
	${cover()}

	@media print {
		position: relative;
	}
`;

const reactQueryConfig: ReactQueryConfig = {
	queries: {
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchInterval: false,
	},
};

const queryCache = new QueryCache();

const showQueryDevtoolsConsole = process.env.NODE_ENV !== 'production';

const App: React.FC = () => {
	const [isLoaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		echarts.registerTheme('amakha', echartsTheme.theme);

		createApi().then(() => {
			setLoaded(true);
		});
	}, []);

	useEffect(() => {
		const unlisten = history.listen((_, action: Action) => {
			if (action !== 'REPLACE') {
				window.scrollTo(0, 0);
			}
		});

		return unlisten;
	}, []);

	if (!isLoaded) {
		return <></>;
	}

	return (
		<ReactQueryCacheProvider queryCache={queryCache}>
			<ReactQueryConfigProvider config={reactQueryConfig}>
				<SessionContainer>
					<ToastContainer
						position="top-right"
						pauseOnFocusLoss
						transition={Slide}
						draggable
						draggablePercent={60}
					/>
					<FullScreen>
						<RootRouter />
					</FullScreen>
					{showQueryDevtoolsConsole && (
						<ReactQueryDevtools initialIsOpen={false} />
					)}
				</SessionContainer>
			</ReactQueryConfigProvider>
		</ReactQueryCacheProvider>
	);
};

App.displayName = 'App';
App.whyDidYouRender = true;

export default App;
