import React from 'react';

import { Card, Skeleton } from 'components';

import { AddressItem } from './styles';

const AddressSkeleton: React.FC = () => (
	<div className="d-flex flex-wrap">
		{[...Array(3).keys()].map((key) => (
			<AddressItem key={key}>
				<Card.Header
					separator
					justifyContent="space-between"
					alignItems="center"
				>
					<Card.Title>
						<Skeleton width="200px" height="2rem" />
					</Card.Title>
					<div>
						<Skeleton width="30px" height="2rem" className="mr-2" />
						<Skeleton width="30px" height="2rem" />
					</div>
				</Card.Header>
				<Card.Content>
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton width="50%" />
				</Card.Content>
				<Card.Content>
					<Skeleton width="200px" height="2rem" />
				</Card.Content>
			</AddressItem>
		))}
	</div>
);

export default AddressSkeleton;
