import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updatePrefsAction } from 'store/ducks/prefs/actions';
import { RootState } from 'store/ducks/rootReducer';
import { ID, Company } from 'types';

import useAdminContext from './useAdminContext';

const useSelectCompany = (): [Company, (company: Company | ID) => void] => {
	const { companies } = useAdminContext();
	const idCompany = useSelector<RootState, ID>(
		(state) => state.prefs.admin.idCompany
	);
	const dispatch = useDispatch();

	const handleChange = useCallback(
		(company: Company | ID) => {
			let id: ID;
			if (typeof company === 'object') {
				id = company.idPerson || 0;
			} else {
				id = company as ID;
			}
			dispatch(updatePrefsAction({ admin: { idCompany: id } }));
		},
		[dispatch]
	);

	const selectedCompany =
		companies.find((c) => c.idPerson === idCompany) || companies[0];

	return [selectedCompany, handleChange];
};

export default useSelectCompany;
