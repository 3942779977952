import { ReactFragment } from 'react';

import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from './IFormatter';
import { REGEX_CLEAR_NUMERIC } from './Regex';

type NumberOptions = Intl.NumberFormatOptions[];

export const numberFormat = {
	default: [{}] as NumberOptions,

	integer: [
		{
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		},
	] as NumberOptions,

	currency: (symbol = 'BRL'): NumberOptions => {
		const f = Intl.NumberFormat('pt-br', {
			style: 'currency',
			currency: symbol,
		});
		const fractionDigits = f.resolvedOptions().maximumFractionDigits;
		return [
			{
				maximumFractionDigits: fractionDigits,
				minimumFractionDigits: fractionDigits,
			},
		] as NumberOptions;
	},

	currencyWithSymbol: (symbol = 'BRL'): NumberOptions => [
		{
			style: 'currency',
			currency: symbol,
			currencyDisplay: 'symbol',
		},
	],

	decimal: (digits: number) => [
		{
			maximumFractionDigits: digits,
			minimumFractionDigits: digits,
		},
	],

	percent: (fractionDigits = 0, saturateAt100 = false) => [
		saturateAt100
			? {
					useGrouping: false,
					maximumSignificantDigits: 3,
					minimumSignificantDigits: 3,
			  }
			: {},
		{
			style: 'unit',
			unit: 'percent',
			maximumFractionDigits: fractionDigits,
			minimumFractionDigits: fractionDigits,
		},
	],
};

const isValidType = (param: FormatterParamType): boolean => {
	const type = typeof param;
	return type === 'string' || type === 'number' || type === 'boolean';
};

class NumberFormatter implements FormatterType {
	constructor(
		private locale = 'pt-br',
		private options: NumberOptions = numberFormat.default
	) {}

	format = (
		field: FormatterParamType,
		row?: FormatterParamRowType
	): string | ReactFragment => {
		const data = !row ? field : row[field as string];
		if (!isValidType(data)) {
			return '';
		}
		if (data === '') {
			return '';
		}
		let result = data;

		this.options.forEach((option) => {
			result = new Intl.NumberFormat(this.locale, option).format(
				result as number
			);
		});
		return result;
	};

	formatForInput = (data: FormatterParamType): [string, string] => {
		if (!isValidType(data)) {
			return ['', ''];
		}

		let decimal = 0;
		for (const option of this.options) {
			if (option.maximumFractionDigits) {
				decimal = option.maximumFractionDigits;
				break;
			}
		}

		let value: string[];
		if (typeof data === 'number') {
			value = String(data).split('.');
			if (value.length > 1) {
				value[1] = `${value[1] || ''}00000000000000000000`.substr(0, decimal);
			} else {
				value.push(`00000000000000000000`.substr(0, decimal));
			}
		} else {
			value = String(data).replace(REGEX_CLEAR_NUMERIC, '').split(',');
		}

		let result: string = value[0];

		if (decimal > 0) {
			// eslint-disable-next-line no-restricted-properties
			result = (Number(value.join('')) / Math.pow(10, decimal))
				.toFixed(decimal)
				.toString();
		}
		return [
			result,
			!result ? '' : (this.format('result', { result }) as string),
		];
	};

	formatForSearch = (value: string) =>
		value.trim().replace(/\./g, '').replace(/,/g, '.');
}

export default NumberFormatter;
