import { persistReducer } from 'redux-persist';

import localforage from 'localforage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (reducers: any) => {
	const persistedReducer = persistReducer(
		{
			key: 'amakha-paris',
			storage: localforage,
			whitelist: ['session', 'prefs', 'shoppingCart'],
		},
		reducers
	);

	return persistedReducer;
};
