export enum OrderStatusConsts {
	Integrated = 0,
	Waiting = 1,
	Open = 1,
	// 2, 3 - inativo
	Paid = 4, // 4 - pagto confirmado
	Expedition = 5, // 5 - enviado para expedição
	Picking = 6, // 6 - em separação
	Sorted = 7, // 7 - separado
	NfeIssued = 8, // 8 - nfe emitida
	Sent = 9, // 9 - pedido enviado
	Delivered = 10, // 10- pedido entregue
	Returned = 15, // 15- pedido devolvido
	Canceled = 20, // 20- pedido cancelado
}

export enum OrderShipmentConsts {
	Withdraw = 1,
	Sedex = 2,
	PAC = 3,
	Carrier = 4,
	WithdrawOnStore = 5,
}

export enum OrderPaymentConsts {
	WireTransfer = 1,
	PagSeguro = 2,
	Balance = 3,
	Money = 4,
	PaidOnPA = 5,
	Card = 6,
}
