import React, { useEffect } from 'react';

import { useField } from 'formik';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { AsyncSelect } from 'components/Formik';
import vipService from 'services/vip.service';
import { Vip } from 'types';

export type VipSelectFilterProps = {
	name: string;
	queryKey: keyof Vip;
};

const VipSelectFilter: React.FC<VipSelectFilterProps> = ({
	name,
	queryKey,
}) => {
	const { onParamChange } = useDatagridContext();
	const [{ value: fieldValue }] = useField<Vip>(name);

	// Inicializa com o defaultValue
	useEffect(() => {
		if (fieldValue != null) {
			onParamChange(name, String(fieldValue.idVip));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (value: Vip) => {
		onParamChange(name, value[queryKey] || '');
	};

	return (
		<AsyncSelect
			name={name}
			fetchMethod={vipService.getForList}
			fetchSortByKey="name"
			onChange={onChange}
		/>
	);
};

export default VipSelectFilter;
