import React from 'react';

import Container from 'react-bootstrap/Container';

import Skeleton from 'components/Skeleton';

import { Card } from './cardNews/styles';
import { NewsContainer } from './styles';

const NewsSkeleton: React.FC = () => (
	<Container fluid>
		<NewsContainer>
			{[...Array(2).keys()].map((key) => (
				<Card key={key} style={{ maxWidth: '500px', width: '100%' }}>
					<Skeleton width="100%" height="250px" />

					<Card.Body>
						<div className="d-flex justify-content-end mb-4">
							<Skeleton width="200px" height="20px" />
						</div>
						<div className="mb-3">
							<Skeleton width="60%" height="40px" />
						</div>
						<div className="mb-3">
							<Skeleton height="15px" />
							<br />
							<Skeleton height="15px" />
							<br />
							<Skeleton width="40%" height="15px" />
						</div>
						<div className="d-flex justify-content-end">
							<Skeleton width="100px" height="40px" />
						</div>
					</Card.Body>
				</Card>
			))}
		</NewsContainer>
	</Container>
);

export default NewsSkeleton;
