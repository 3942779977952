import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useField } from 'formik';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import { ArrayQueryKey, useQuery } from 'react-query';

import { faListOl, faStream } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Button, Checkbox, List, Skeleton } from 'components';
import { useToast } from 'hooks';
import storeService from 'services/store.service';
import { FeatureForSort, FeatureStore, StoreForSelect, UrlParams } from 'types';

import { FormGroupTitle } from 'packages/admin/components';

import {
	StoreItemContainer,
	ListPopoverWrapper,
} from './featureStoreSelect/styles';

type FeatureStoreSelectProps = {
	name: string;
	newFeatureName?: string;
};

type Cache = FeatureStore & {
	fetched: boolean;
};

const delay = (time: number): Promise<void> =>
	new Promise((resolve) =>
		setTimeout(() => {
			resolve();
		}, time)
	);

const FeatureStoreSelect: React.FC<FeatureStoreSelectProps> = ({
	name,
	newFeatureName,
}) => {
	const [toast] = useToast();
	// const storeRefs = useRef(new Map()).current;
	const { id: UrlId } = useParams<UrlParams>();
	const idFeature = useMemo(() => (UrlId === 'create' ? -1 : Number(UrlId)), [
		UrlId,
	]);
	const [cache, setCache] = useState<Cache[]>([]);
	const [isLoadingCache, setIsLoadingCache] = useState<boolean>(true);
	const [{ value }, { error }, { setValue }] = useField<FeatureStore[]>(name);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const initialValue = useMemo(() => value, []);

	const { data: storesForSelect, isLoading: loadingStoresForSelect } = useQuery<
		StoreForSelect[],
		ArrayQueryKey
	>(
		['feature_store'],
		() => storeService.getForSelect({}).then((r) => r.data),
		{
			onError: () => {
				toast('Não foi possível carregar as lojas', {
					type: 'error',
				});
			},
		}
	);

	const loadToCache = useCallback(
		async (values: FeatureStore[]): Promise<Cache[]> => {
			if (!storesForSelect) return [];

			setIsLoadingCache(true);
			const cacheAux: Cache[] = [];
			try {
				for await (const store of storesForSelect) {
					const featStore = values.find((f) => f.idStore === store.idStore);
					const featureList =
						featStore?.feature ||
						(await storeService
							.getFeaturesByIdStore(store.idStore)
							.then((r) => r.data)) ||
						[];

					cacheAux.push({
						fetched: true,
						name: store.name,
						idStore: store.idStore,
						feature: featureList,
					});
				}
			} catch (e) {
				toast('Não foi possível carregar os destaques das lojas', {
					type: 'error',
				});
			}
			setCache(cacheAux);

			await delay(100);
			setIsLoadingCache(false);
			return cacheAux;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storesForSelect]
	);

	useEffect(() => {
		if (initialValue && storesForSelect) {
			loadToCache(initialValue);
		}
	}, [loadToCache, storesForSelect, initialValue]);

	useEffect(() => {
		if (newFeatureName) {
			const newValue = value.map((c) => ({
				...c,
				feature: c.feature.map((f) =>
					f.idFeature === idFeature || f.idFeature === -1
						? { ...f, name: newFeatureName }
						: f
				),
			}));
			setValue(newValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newFeatureName]);

	const handleChangeItems = useCallback(
		(store: StoreForSelect) => (options: FeatureForSort[]) => {
			const option = options.find((o) => o.idFeature === idFeature);

			if (option) {
				// Setando no state
				setCache([
					...(cache?.filter((c) => c.idStore !== store.idStore) ?? []),
					{
						fetched: true,
						idStore: store.idStore,
						name: store.name,
						feature: options || [],
					},
				]);

				// Setando no form
				const stores = value.map(
					(s): FeatureStore =>
						s?.idStore === store.idStore ? { ...s, feature: options } : s
				);
				setValue(stores);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[idFeature, cache, value]
	);

	const handleChangeCheckbox = useCallback(
		(store: StoreForSelect) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const storeInCache = cache.find((s) => s.idStore === store.idStore);
			const otherStores = value.filter((s) => s.idStore !== store.idStore);

			// Ao desmarcar é necessário passar um objeto com idStore preenchido e
			// feature como array sem a feature atual
			if (!event.target.checked) {
				const isInInitialValue = initialValue.some(
					(s) => s.idStore === store.idStore
				);
				const feature =
					storeInCache?.feature.filter((f) => f.idFeature !== idFeature) ?? [];

				setValue([
					...otherStores,
					...(isInInitialValue ? [{ ...store, feature }] : []),
				]);
				return;
			}

			const thisFeature: FeatureForSort = {
				idFeature,
				name: newFeatureName ?? 'Este Destaque', // TODO: verificar qual nome vai aparecer
				sort: 1,
			};

			// Se a store estiver no cache, significa que já existem features
			// associadas para a store.
			if (storeInCache) {
				const thisFeatureInStore = storeInCache.feature.find(
					(f) => f.idFeature === idFeature
				);

				// Se a feature atual estiver no store, somente utiliza store do cache
				if (thisFeatureInStore) {
					setValue([...otherStores, storeInCache]);
					return;
				}
				const otherFeaturesInStore = storeInCache.feature.filter(
					(f) => f.idFeature !== idFeature
				);
				let newStore: FeatureStore = {
					...store,
					feature: [thisFeature],
				};

				// Se existirem outras features no store, adiciona a feature atual na última posição
				if (otherFeaturesInStore) {
					const lastSort = otherFeaturesInStore.reduce(
						(prev, next) => (next.sort > prev ? next.sort : prev),
						0
					);
					thisFeature.sort = lastSort + 1;

					newStore = {
						...store,
						feature: [...storeInCache.feature, thisFeature],
					};
				}

				setValue([...otherStores, newStore]);
				return;
			}

			// Se a store não estiver no cache, significa que nenhuma feature existe
			// na store. Neste caso, insere uma newStore com a feature
			const newStore: FeatureStore = {
				...store,
				feature: [thisFeature],
			};

			setValue([...otherStores, newStore]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[idFeature, initialValue, cache, value, newFeatureName]
	);

	const popover = useCallback(
		(store: StoreForSelect) => (
			<ListPopoverWrapper>
				<FormGroupTitle>Reordenar Destaques na loja</FormGroupTitle>
				<List
					data={value.find((s) => s.idStore === store.idStore)?.feature ?? []}
					loading={isLoadingCache}
					emptyImage={faStream}
					emptyDescription="Não há nenhuma feature adicionada para a loja"
					className="mt-3"
					onChangeItems={handleChangeItems(store)}
					canBeDraggedFilter={(item: FeatureForSort) =>
						item.idFeature === idFeature
					}
					hasRemoveButton={false}
					dragInDrop
				>
					{(item: FeatureForSort) => (
						<Row className="w-100">
							<Col>
								<span>{item.name}</span>
							</Col>
						</Row>
					)}
				</List>
			</ListPopoverWrapper>
		),
		[idFeature, value, isLoadingCache, handleChangeItems]
	);

	return (
		<div>
			{loadingStoresForSelect || isLoadingCache ? (
				<>
					<Skeleton height="1.8rem" />
					<Skeleton height="1.8rem" />
					<Skeleton height="1.8rem" />
				</>
			) : (
				<>
					{storesForSelect?.map((store) => (
						<StoreItemContainer
							key={store.idStore}
							// ref={(node) => storeRefs.set(store.idStore, node)}
						>
							<Checkbox
								checked={value.some(
									(s) =>
										s.idStore === store.idStore &&
										s.feature.some((f) => f.idFeature === idFeature)
								)}
								label={store.name}
								onChange={handleChangeCheckbox(store)}
							/>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								// container={storeRefs.get(store.idStore)}
								overlay={popover(store)}
								rootClose
							>
								<Button
									variant="link"
									className="ml-auto text-info"
									disabled={
										!value.some(
											(s) =>
												s.idStore === store.idStore &&
												s.feature.some((f) => f.idFeature === idFeature)
										)
									}
								>
									<FAIcon icon={faListOl} />
								</Button>
							</OverlayTrigger>
						</StoreItemContainer>
					))}
					{error && <span className="invalid-feedback d-block">{error}</span>}
				</>
			)}
		</div>
	);
};

export default FeatureStoreSelect;
