import React, { useState, useEffect, useCallback } from 'react';

import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

export interface CheckboxProps {
	name: string;
	label: string;
	value?: unknown;
	defaultChecked?: boolean;
	isValueNumber?: boolean;
	id?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	style?: React.CSSProperties;
	className?: string;
}

let checkboxIdNum = 0;

const Checkbox: React.FC<CheckboxProps> = (props) => {
	const {
		name,
		id,
		value,
		isValueNumber,
		defaultChecked,
		onChange,
		...rest
	} = props;

	const [checkboxId, setCheckboxId] = useState<number>(0);

	const [
		{ value: fieldValue },
		{ initialValue, error, touched },
		{ setValue },
	] = useField(name);

	useEffect(() => {
		// eslint-disable-next-line no-plusplus
		setCheckboxId(++checkboxIdNum);
	}, []);

	const setCheckedValue = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const inputChecked = event.target.checked;

			if (inputChecked) {
				if (value) {
					setValue(isValueNumber ? Number(value) : value);
				} else {
					setValue(inputChecked);
				}
			} else {
				setValue(null);
			}

			if (onChange !== undefined) {
				onChange(event);
			}
		},
		[isValueNumber, onChange, setValue, value]
	);

	return (
		<Form.Check
			id={`checkbox-${checkboxId}-${id || name}`}
			isInvalid={!!error && touched}
			defaultChecked={initialValue || defaultChecked}
			checked={typeof fieldValue === 'boolean' ? fieldValue : undefined}
			onChange={setCheckedValue}
			name={name}
			custom
			{...rest}
		/>
	);
};

Checkbox.displayName = 'Checkbox';
Checkbox.whyDidYouRender = true;

export default React.memo(Checkbox);
