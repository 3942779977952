const extract2Digit = (
	str: string,
	min: number,
	limit: number
): [string, number] => {
	const m1 = str.substr(0, 1);
	const m2 = str.substr(1, 1);
	let result = '';

	if (!m1) {
		return ['', 0];
	}
	if (Number(`${m1}0`) <= limit) {
		result = m1;
	} else {
		if (Number(`${m1}`) <= limit) {
			return [`0${m1}`, 1];
		}
		return [String(min), 0];
	}
	if (!m2) {
		return [result, 1];
	}
	const num = `${m1}${m2}`;
	if (Number(num) <= limit) {
		result = `0${String(Math.max(min, Number(num)))}`;
		result = result.substr(result.length - 2, 2);
		return [result, 2];
	}
	return [`${m1}0`, 1];
};

const extract4Digit = (
	str: string,
	min: number,
	limit: number
): [string, number] => {
	const first = extract2Digit(str, min / 100, limit / 100);
	if (first[1] < 2) {
		return first;
	}
	const nstr = str.substring(first[1]);
	const y = first[0];
	const m1 = nstr.substr(0, 1);
	const m2 = nstr.substr(1, 1);
	let result = '';

	if (!m1) {
		return first;
	}
	if (Number(`${y}${m1}0`) <= limit) {
		result = m1;
	} else {
		if (Number(`${y}${m1}`) <= limit) {
			return [`${y}${m1}`, first[1] + 1];
		}
		return [String(min), first[1]];
	}
	if (!m2) {
		return [first[0] + result, first[1] + 1];
	}
	const num = `${y}${m1}${m2}`;
	if (Number(num) <= limit) {
		result = `${String(Math.max(min, Number(num)))}`;
		result = result.substr(result.length - 4, 4);
		return [result, 4];
	}
	return [`${y}${m1}`, first[1] + 1];
};

const extractDate = (date: string, order = 'dmy'): [string, number] => {
	let str = date;
	const params: { [k: string]: number[] } = {
		d: [1, 31],
		m: [1, 12],
		y: [1900, 2099],
	};

	const extract = [];
	for (const c of order) {
		const [min, max] = params[c];
		if (min > 99) {
			const r = extract4Digit(str, min, max);
			str = str.substring(r[1]);
			extract.push(r);
		} else {
			const r = extract2Digit(str, min, max);
			str = str.substring(r[1]);
			extract.push(r);
		}
	}

	let result = '';
	let count = 0;

	for (let i = 0; i < extract.length; ++i) {
		const [s, c] = extract[i];
		if (c === 0) {
			break;
		}
		result += s;
		count += c;
		if (i < extract.length - 1 && s.length === 2) {
			result += '/';
		}
	}

	return [result, count];
};

const extractTime = (param: string, addSec = false): [string, number] => {
	let time = param;
	const hour = extract2Digit(time, 0, 24);
	time = time.substring(hour[1]);
	const min = extract2Digit(time, 0, 59);
	time = time.substring(min[1]);
	const sec = extract2Digit(time, 0, 59);

	let result = '';
	let count = 0;

	if (hour[1] > 0) {
		const [s, c] = hour;
		result = s;
		count = c;

		if (min[1] > 0) {
			const [ms, mc] = min;
			result += `:${ms}`;
			count += mc;
		}
		if (addSec && sec[1] > 0) {
			const [ss, sc] = sec;
			result += `:${ss}`;
			count += sc;
		}
	}

	return [result, count];
};

export default {
	extract2Digit,
	extract4Digit,
	extractDate,
	extractTime,
};
