/* eslint-disable no-plusplus */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from 'formik';
import { Col, Form, Accordion } from 'react-bootstrap';

import * as Yup from 'yup';

import { Card } from 'components';
import { Checkbox, FormikEffect, Input } from 'components/Formik';
import { useToast } from 'hooks';
import systemUserService from 'services/system-user.service';
import { AuthACLPermission, AuthACLRole } from 'types';

import {
	FormCancelButton,
	FormGroupTitle,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './profile/SkeletonForm';
import { ProfileFormType } from './profile/types';

type GroupedPermissions = {
	module: string;
	permissions: AuthACLPermission[];
}[];

export const defaultFormValue: ProfileFormType = {
	idRole: undefined,
	name: '',
	description: '',
	blocked: false,
	permissions: [],
};

export const formSchema = Yup.object<ProfileFormType>({
	idRole: Yup.number().notRequired(),
	name: Yup.string().trim().required('Campo obrigatório'),
	description: Yup.string()
		.trim()
		.required('Campo obrigatório')
		.min(5, 'Campo deve ter 5 ou mais caracteres'),
	permissions: Yup.array()
		.of(
			Yup.object<AuthACLPermission>({
				idPermission: Yup.number(),
				module: Yup.string(),
				description: Yup.string(),
				slug: Yup.string(),
			}).nullable()
		)
		.transform((value: ProfileFormType['permissions']) =>
			value.filter((p) => p)
		)
		.min(1, 'O Perfil de Acesso deve ter ao menos uma permissão')
		.required('Campo obrigatório'),
});

const SubscriptionEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			const allPermissions = await systemUserService.getPermissions();

			let profile: ProfileFormType = defaultFormValue;

			if (!isCreating) {
				const {
					permissions,
					...response
				} = await systemUserService.getProfileById(Number(id));
				profile = {
					...response,
					permissions:
						allPermissions.map((p) =>
							permissions?.includes(p.idPermission) ? p : null
						) ?? [],
				};
			}

			return { allPermissions, profile };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends ProfileFormType>(
			id: string,
			{ permissions, ...values }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const isCreating = id === 'create';

			const submit: AuthACLRole = {
				...values,
				permissions: permissions.filter((p) => p).map((p) => p!.idPermission),
			};

			try {
				await systemUserService.createOrUpdatePermissions(
					isCreating ? null : Number(id),
					submit
				);
				toast(`Perfil alterado com sucesso`, {
					type: 'success',
				});
				history.push('/admin/access/profiles');
			} catch (e) {
				toast(`Não foi possível editar o perfil. Cód: ${e.code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[history, toast]
	);

	const groupedPermissions = useCallback(
		(permissions?: ProfileFormType['permissions']) => {
			const grouped: GroupedPermissions = [];
			if (!permissions?.length) return grouped;

			permissions.forEach((p) => {
				if (p) {
					let group = grouped.find((n) => n.module === p.module);
					if (!group) {
						group = { module: p.module || '', permissions: [] };
						grouped.push(group);
					}
					group.permissions.push(p);
				}
			});

			return grouped;
		},
		[]
	);

	return (
		<PageLayoutFormWrapper
			title="Perfil de Acesso"
			breadcrumb={[
				{ label: 'Acesso', path: '/admin/access/profiles' },
				{ label: 'Perfil de Acesso', path: '/admin/access/profiles' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.profile}
					validationSchema={formSchema}
				>
					{(form: FormikProps<ProfileFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content className="offset-lg-3 col-lg-6">
									<FormGroupTitle>Dados do Perfil</FormGroupTitle>
									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>Nome</Form.Label>
											<Input name="name" type="text" />
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>Descrição</Form.Label>
											<Input name="description" type="text" />
										</Form.Group>
									</Form.Row>
									<br />
									<Form.Row>
										<Form.Group as={Col} md={12}>
											<FormGroupTitle>Módulos</FormGroupTitle>
											<Accordion>
												<Card>
													{groupedPermissions(data?.allPermissions).map(
														({ module, permissions }, idx) => (
															<React.Fragment key={module}>
																<Accordion.Toggle
																	as={Card.Header}
																	role="button"
																	eventKey={`#${module}`}
																	className={idx > 0 ? 'border-top' : ''}
																>
																	{module}
																</Accordion.Toggle>
																<Accordion.Collapse
																	as={Card.Content}
																	eventKey={`#${module}`}
																>
																	<div className="p-3">
																		{permissions.map((permission) => (
																			<Checkbox
																				key={permission.idPermission}
																				name={`permissions[${data?.allPermissions.findIndex(
																					(p) =>
																						p.idPermission ===
																						permission.idPermission
																				)}]`}
																				value={permission}
																				label={permission.description}
																				className="mb-1"
																				disabled={form.isSubmitting}
																			/>
																		))}
																	</div>
																</Accordion.Collapse>
															</React.Fragment>
														)
													)}
												</Card>
											</Accordion>
											{!!form.errors.permissions &&
												form.touched.permissions && (
													<span className="invalid-feedback d-block">
														{form.errors.permissions}
													</span>
												)}
										</Form.Group>
									</Form.Row>
								</Card.Content>

								<Card.Content className="text-right border-top">
									<FormCancelButton
										isSubmitting={form.isSubmitting}
										to="/admin/access/profiles"
									/>
									<FormSubmitButton isSubmitting={form.isSubmitting} />
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default SubscriptionEdit;
