import { Popover, PopoverProps } from 'react-bootstrap';
import styled from 'styled-components';

export const ListPopoverWrapper = styled(Popover)<PopoverProps>`
	width: 400px;
	max-width: 90vw;
	padding: 1rem;

	background: var(--base-bg);
	box-shadow: var(--base-box-shadow);

	/* Tentativa de resolver problema com drag and drop saindo da página */
	/* transform: unset !important;
	top: 2.5rem !important;
	left: -4.8rem !important; */
`;

export const StoreItemContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
