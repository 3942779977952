import React from 'react';

import Button from 'react-bootstrap/Button';

import classnames from 'classnames';

import { DayProps, MonthType } from 'components/calendar/types';

const DaySquare: React.FC<DayProps> = ({
	dayOfMonth,
	today,
	selected,
	onClick,
	color,
	selectedColor,
}) => {
	const isActualMonth = dayOfMonth.monthType === MonthType.Actual;

	const variant = color || 'outline-light';
	const selVariant = selectedColor || 'primary';

	return (
		<>
			{isActualMonth ? (
				<Button
					size="sm"
					variant={selected ? selVariant : variant}
					className={classnames(
						'justify-content-center m-0 border-0 w-100',
						!selected && 'text-dark'
					)}
					onClick={() => onClick(dayOfMonth.day)}
				>
					{today ? <u>{dayOfMonth.day}</u> : dayOfMonth.day}
				</Button>
			) : (
				<span className={!isActualMonth ? 'text-muted' : ''}>
					{dayOfMonth.day}
				</span>
			)}
		</>
	);
};

export default DaySquare;
