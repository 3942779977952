import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';

import { PrintOrders } from './pages';
import MainRouter from './pages/MainRouter';

const AdminRoutes: RouteDef[] = [
	{
		path: '/admin/expedition/print-orders',
		component: PrintOrders,
		exact: true,
		validation: [personTypeValidation(PersonType.Admin)],
	},
	{
		path: '/admin',
		validation: [personTypeValidation(PersonType.Admin)],
		component: MainRouter,
	},
];

export default AdminRoutes;
