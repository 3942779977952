import Distributor from './routes/distributor.routes';
import Financial from './routes/financial.routes';
import Order from './routes/order.routes';
import Utils from './routes/utils.routes';
import { ListSceneConfig } from './types';

export default {
	...Distributor,
	...Financial,
	...Order,
	...Utils,
} as { [name: string]: ListSceneConfig };
