import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card } from 'components';
import {
	Input as FormikInput,
	DateTimeInput,
	Upload,
	FormikEffect,
} from 'components/Formik';
import uploadHelpers from 'components/upload/Helpers';
import { useToast } from 'hooks';
import storeService from 'services/store.service';
import { Banner, BannerForSort } from 'types';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import BannerStoreSelect from './banner/BannerStoreSelect';
import StoreBannerFormSkeleton from './banner/SkeletonForm';
import { BannerForm } from './banner/types';

const defaultFormValue: BannerForm = {
	idBanner: null,
	name: '',
	initialDate: new Date().toISOString(),
	endDate: null,
	image: [],
	store: [],
};

const formSchema = Yup.object<BannerForm>().shape({
	idBanner: Yup.number().nullable(),
	name: Yup.string().trim().required('Campo obrigatório'),
	initialDate: Yup.string()
		.transform((_, o) => (!o ? '' : o))
		.nullable(false)
		.required('Campo obrigatório'),
	endDate: Yup.string()
		.transform((_, o) => (!o ? '' : o))
		.nullable(false)
		.required('Campo obrigatório'),
	image: Yup.mixed().when(
		'idBanner',
		(idBanner: number | null, schema: Yup.StringSchema<string>) =>
			!idBanner ? schema.required('Campo obrigatório') : schema.nullable()
	),
	store: Yup.array().of(
		Yup.object()
			.required()
			.shape({
				idStore: Yup.number(),
				name: Yup.string().optional(),
				banner: Yup.array().of<BannerForSort>(
					Yup.object()
						.nullable()
						.required()
						.shape({
							idBanner: Yup.number(),
							name: Yup.string(),
							url: Yup.string().required('Campo obrigatório'),
							status: Yup.string().optional(),
							sort: Yup.number(),
						})
				),
			})
	),
});

const StoreBannerEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';
			let banner: BannerForm = defaultFormValue;

			if (!isCreating) {
				const { image, ...response } = await storeService.getBannerById(+id);
				banner = {
					...response,
					// Transformar prop image em UploadFile[]
					...(image && { image: uploadHelpers.mapImages(image) }),
				};
			}

			return { banner };
		},
		[]
	);

	const handleSubmit = useCallback(
		async (
			id: string,
			{ image, store, ...values }: BannerForm,
			{ setSubmitting }
		) => {
			const isCreating = id === 'create';

			let submit: Banner = {
				...values,
				store: !isCreating ? store : [],
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(image) &&
					(uploadHelpers.isNewFile(image[0]?.url)
						? { image: image[0]?.url }
						: image.length === 0 && { image: '' })),
			};

			try {
				const response = await storeService.createOrUpdateBanner(
					(isCreating && null) || Number(id),
					submit
				);

				// Atualiza o registro com as stores
				if (isCreating) {
					const idBanner = (response.data as { id: number }).id;
					submit = {
						...submit,
						store: store.map((s) => ({
							...s,
							banner: s.banner.map((b) =>
								b.idBanner !== -1
									? b
									: {
											...b,
											idBanner,
									  }
							),
						})),
					};
					await storeService.createOrUpdateBanner(idBanner, submit);
				}
				toast(`Banner ${isCreating ? 'criado' : 'alterado'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/stores/banners');
			} catch (e) {
				toast(`Não foi possível salvar os dados. Cód: ${e.code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[history, toast]
	);

	return (
		<PageLayoutFormWrapper
			title="Banner"
			breadcrumb={[
				{ label: 'Lojas', path: '/admin/stores/banners' },
				{ label: 'Banners', path: '/admin/stores/banners' },
			]}
			load={loadData}
			skeleton={StoreBannerFormSkeleton}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.banner}
					validationSchema={formSchema}
				>
					{(form: FormikProps<BannerForm>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator>
									<Card.Title>Dados Cadastrais</Card.Title>
								</Card.Header>
								<Card.Content>
									<Row>
										<Col md={12} lg={6}>
											<Form.Group>
												<Form.Label>Nome</Form.Label>
												<FormikInput
													name="name"
													type="text"
													disabled={form.isSubmitting}
												/>
											</Form.Group>

											<Form.Row>
												<Form.Group as={Col} md={6} lg={6}>
													<Form.Label>Data Início</Form.Label>
													<DateTimeInput
														name="initialDate"
														disabled={form.isSubmitting}
														selectDate
														selectTime
													/>
												</Form.Group>
												<Form.Group as={Col} md={6} lg={6}>
													<Form.Label>Data Fim</Form.Label>
													<DateTimeInput
														name="endDate"
														disabled={form.isSubmitting}
														selectDate
														selectTime
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Imagem</Form.Label>
													<Upload
														name="image"
														listType="picture-card"
														showUploadList={false}
														aspect={16 / 9}
														accept=".jpg, .png"
														maxSize={2}
														disabled={form.isSubmitting}
														displayInline
													>
														<FAIcon icon={faImage} />
														<div>Adicionar imagem</div>
													</Upload>
												</Form.Group>
											</Form.Row>
										</Col>
										<Col md={12} lg={6} className="border-left">
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<FormGroupTitle>Exibir nas lojas:</FormGroupTitle>
													<BannerStoreSelect
														name="store"
														newBannerName={form.values.name}
													/>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>

								<Card.Content className="text-right border-top">
									<Form.Row>
										<Col>
											<FormCancelButton to="/admin/stores/banners" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default StoreBannerEdit;
