import {
	HttpGetListParams,
	HttpGetListResult,
	StockListType,
	StockDetailsListType,
	OrderForList,
	OrderSale,
	ID,
} from 'types';

import { Distributor, DistributorList } from '../types/distributor.types';

import Crud from './crud.service';

const getById = (id: ID): Promise<Distributor> =>
	Crud.getOne('distributor/{id}', id);

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<DistributorList>> =>
	Crud.getList<DistributorList>('distributor', params);

const getStockListById = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StockListType>> =>
	Crud.getList<StockListType>('my/distributor/stock', params);

const getStockDetailsList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StockDetailsListType>> =>
	Crud.getList<StockDetailsListType>('my/distributor/stockdetail', params);

const getCreditOrdersList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<OrderForList>> =>
	Crud.getList<OrderForList>('my/order', params);

const getOrderSalesList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<OrderSale>> =>
	Crud.getList<OrderSale>('my/distributor/ordersale', params);

export default {
	getById,
	getForList,
	getStockListById,
	getStockDetailsList,
	getCreditOrdersList,
	getOrderSalesList,
};
