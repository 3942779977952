import { faCircle, faSmile } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

const ClubRoutes: RouteDef = {
	path: '/admin/club',
	menu: {
		name: 'Família Feliz',
		icon: faSmile,
	},
	children: [
		{
			path: '/summary',
			menu: {
				name: 'Resumo',
				icon: faCircle,
			},
		},
		{
			path: '/credit',
			menu: {
				name: 'Crédito do PA',
				icon: faCircle,
			},
		},
		{
			path: '/statement/by-person',
			menu: {
				name: 'Extrato x Pessoa (x Mês)',
				icon: faCircle,
			},
		},
		{
			path: '/statement/by-order',
			menu: {
				name: 'Extrato x Pedido',
				icon: faCircle,
			},
		},
		{
			path: '/advance/by-person',
			menu: {
				name: 'Antecipação x Pessoa',
				icon: faCircle,
			},
		},
		{
			path: '/advance/by-order',
			menu: {
				name: 'Antecipação x Pedido',
				icon: faCircle,
			},
		},
		{
			path: '/payment-queue',
			menu: {
				name: 'Fila de Pagamento',
				icon: faCircle,
			},
		},
		{
			path: '/allocations/open',
			menu: {
				name: 'Alocações Abertas',
				icon: faCircle,
			},
		},
		{
			path: '/allocations/paid',
			menu: {
				name: 'Alocações Pagas',
				icon: faCircle,
			},
		},
	],
};

export default ClubRoutes;
