import React from 'react';

import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { ArrayQueryKey, useQuery } from 'react-query';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import { useToast } from 'hooks';
import financialService from 'services/financial.service';
import { ID, StatementPayout } from 'types';
import {
	cpfCnpjFormatter,
	currencyWithSymbolFormatter,
} from 'utils/formatters';

type RequestPayoutModalProps = {
	id: ID;
};

const RequestPayoutModal: React.FC<
	ModalForwardProps<RequestPayoutModalProps, boolean>
> = ({ id, modalRef, ...rest }) => {
	const [toast] = useToast();

	const { data, isLoading } = useQuery<StatementPayout, ArrayQueryKey>(
		['payout_request', id],
		() => financialService.getPayoutRequest(id),
		{
			enabled: id !== null,
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados.', {
					type: 'error',
				});
				modalRef.dismiss();
			},
		}
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			{isLoading ? (
				<>
					<Modal.Header closeButton>
						<Skeleton width="150px" height="2rem" />
					</Modal.Header>
					<Modal.Body>
						<Skeleton width="50%" height="2rem" />

						<p className="mt-3" style={{ width: '70%' }}>
							<Skeleton count={5} />
						</p>

						<Skeleton width="50%" height="2.5rem" />
					</Modal.Body>
					<Modal.Footer align="end">
						<Skeleton width="100px" height="2rem" />
					</Modal.Footer>
				</>
			) : (
				data && (
					<>
						<Modal.Header closeButton>
							<Modal.Title>
								{`Solicitação de Saque #${data.idPayment}`}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<h4 className="font-weight-bold">{data.executiveName}</h4>
							<p>
								{data.account.bank?.name} (cód. {data.account.bank?.code})
								<br />
								<strong>Agência:</strong> {data.account.agency}
								<br />
								<strong>
									Conta {data.account.isCC === '1' ? 'Corrente' : 'Poupança'}:
								</strong>{' '}
								{data.account.number}
								<br />
								<strong>Titular:</strong> {data.account.holder}
								<br />
								<strong>
									{data.account.cpfCnpj.replace(/[^0-9]+/g, '').length === 11
										? 'CPF'
										: 'CNPJ'}
									:
								</strong>{' '}
								{cpfCnpjFormatter.format(data.account.cpfCnpj)}
							</p>
							<h3 className="font-weight-bold">
								Valor: {currencyWithSymbolFormatter.format(data.value)}
							</h3>
						</Modal.Body>
						<Modal.Footer align="end">
							<Button variant="primary" onClick={() => modalRef.dismiss(true)}>
								Ok
							</Button>
						</Modal.Footer>
					</>
				)
			)}
		</Modal>
	);
};

export default RequestPayoutModal;
