import { ActionPayload, ID } from 'types';

export enum PrefsActionEnum {
	Update = '@prefs/UPDATE',
}

export type PrefsDatatableState = {
	rowsPerPage: number;
};

export type PrefsAdminState = {
	// company selected no menu
	idCompany: ID;
};

export type PrefsState = {
	datatable: PrefsDatatableState;
	admin: PrefsAdminState;
};

export type UpdateAction = ActionPayload<Partial<PrefsState>>;

export type PrefsActionTypes = UpdateAction;
