import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const ScrollReplaceContainer = styled(Container)`
	overflow-y: scroll;
	max-height: calc(100vh - var(--navbar-height));
`;

export const NewsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
