import React from 'react';

import { Col, Form, Row } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

const VipEditSkeleton: React.FC = () => (
	<Card>
		<Card.Content className="offset-lg-4 col-lg-4 offset-md-3 col-md-6">
			<Row>
				<Col sm={12}>
					<Form.Row>
						<Form.Group as={Col} sm={12}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={12}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={12}>
							<Skeleton width="100%" padding={`0 0 ${(1.0 / 1.0) * 100}% 0`} />
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
		</Card.Content>
		<Card.Content className="text-right border-top">
			<Form.Row>
				<Col>
					<Skeleton width="10ch" height="2.5rem" margin="0 1rem 0 0" />
					<Skeleton width="10ch" height="2.5rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default VipEditSkeleton;
