import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { ImageSvg } from 'components';

import { BaseNav } from 'packages/escritorio/components/navBar/styles';

const NavBarSkeleeton: React.FC = () => (
	<BaseNav>
		<BaseNav.Brand href="#home">
			<ImageSvg height="30" image="amakha-admin" />
		</BaseNav.Brand>
		<div className="ml-auto">
			<Skeleton width="220px" height="30px" />
		</div>
	</BaseNav>
);

export default NavBarSkeleeton;
