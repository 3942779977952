import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import BsNavbar from 'react-bootstrap/Navbar';

import { ImageSvg } from 'components';

const Navbar: React.FC = () => {
	return (
		<BsNavbar bg="white" variant="light" className="py-3 border-bottom">
			<Container fluid className="justify-content-center">
				<Link to="/loja">
					<ImageSvg height={60} image="amakha" />
				</Link>
			</Container>
		</BsNavbar>
	);
};

Navbar.displayName = 'CheckoutNavbar';
Navbar.whyDidYouRender = true;

export default Navbar;
