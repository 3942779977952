import React, { FunctionComponent, useEffect, useState } from 'react';

import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';

import {
	faKey,
	faUser,
	faEye,
	faEyeSlash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import localforage from 'localforage';
import * as Yup from 'yup';

import { Input } from 'components/Formik';

/**
 * Dados retornada no onSubmit
 */
export interface LoginFormModel {
	email: string;
	password: string;
	remember: boolean;
}

type LoginFormProps = {
	isLoading?: boolean;
	initialData: LoginFormModel;
	onSubmit: (
		form: LoginFormModel,
		actions?: FormikHelpers<LoginFormModel>
	) => void;
};

const formSchema = Yup.object({
	email: Yup.string().trim().required('Digite seu ID ou e-mail'),
	password: Yup.string().required('Digite sua senha'),
});

/**
 * Formulário com dados de login.
 * O onSubmit somente é chamado quando os dados passam pela validação
 */
const LoginForm: FunctionComponent<LoginFormProps> = ({
	isLoading,
	initialData,
	onSubmit,
}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [localEmail, setLocalEmail] = useState<string>('');

	// Pega o email no localForage
	useEffect(() => {
		async function getLocalEmail() {
			try {
				const value = await localforage.getItem<string>('email');
				setLocalEmail(value || '');
			} catch (err) {
				// eslint-disable-next-line no-console
				console.warn('Erro email: ', err);
			}
		}
		getLocalEmail();
	}, []);

	return (
		<Formik
			onSubmit={onSubmit}
			validationSchema={formSchema}
			initialValues={{ ...initialData, email: localEmail }}
			enableReinitialize
		>
			<FormikForm>
				<Form.Group>
					<Input
						disabled={isLoading}
						name="email"
						type="text"
						placeholder="Usuário"
						prepend={
							<InputGroup.Text>
								<FAIcon fixedWidth icon={faUser} />
							</InputGroup.Text>
						}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						disabled={isLoading}
						name="password"
						type={showPassword ? 'text' : 'password'}
						placeholder="Senha"
						prepend={
							<InputGroup.Text>
								<FAIcon fixedWidth icon={faKey} />
							</InputGroup.Text>
						}
						append={
							<Button
								variant="outline-primary"
								onClick={() => setShowPassword(!showPassword)}
							>
								<FAIcon icon={showPassword ? faEye : faEyeSlash} />
							</Button>
						}
					/>
				</Form.Group>
				<div className="text-right">
					<Button
						variant="primary"
						className="mt-2"
						type="submit"
						disabled={isLoading}
					>
						{isLoading ? (
							<>
								<Spinner size="sm" animation="border" role="status" />
								<span className="ml-2">AGUARDE...</span>
							</>
						) : (
							'ENTRAR'
						)}
					</Button>
				</div>
			</FormikForm>
		</Formik>
	);
};

export default LoginForm;
