import React from 'react';

import BsBadge, { BadgeProps } from 'react-bootstrap/Badge';
import styled from 'styled-components';

const StyledBadge = styled(BsBadge)`
	position: relative;
	top: -8px !important;
	right: 8px !important;
	font-size: 0.7rem;
`;

const Badge: React.FC<BadgeProps> = (props) => (
	<span className="position-relative">
		<StyledBadge {...props} />
	</span>
);

Badge.displayName = 'Badge';
Badge.whyDidYouRender = true;

export default Badge;
