import React, { useCallback, useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';

import {
	faFile,
	faFileArchive,
	faFileImage,
	faFilePdf,
	faTrash,
	faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationModal, FeaturedImageModal } from 'modals';

import { Upload } from 'components';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import Skeleton from 'components/Skeleton';
import { UploadFile } from 'components/upload/types';
import * as envJson from 'config/env.json';
import { useModal, useToast } from 'hooks';
import orderService from 'services/order.service';
import { HttpBaseResult, ID, OrderAttachType } from 'types';
import { numberFormatter } from 'utils/formatters';

import { AttachList, AttachItem, AttachIcon } from './attachModal/styles';

interface OrderAttach extends OrderAttachType {
	isImage: boolean;
	isZip: boolean;
	isPdf: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon: any;
	fileName: string;
}

interface DataSave extends OrderAttachType {
	error?: string | null;
}

export type AttachModalProps = {
	idOrder: ID;
	number: ID;
	enableUpload: boolean;
	enableRemove: boolean;
};

const AttachModal: React.FC<ModalForwardProps<AttachModalProps, void>> = ({
	idOrder,
	number,
	enableUpload,
	enableRemove,
	modalRef,
	...rest
}) => {
	const [isLoadingAttach, setIsLoadingAttach] = useState<boolean>(false);
	const [isAddingAttach, setIsAddingAttach] = useState<boolean>(false);
	const modal = useModal();
	const [toast] = useToast();

	const { data = [], isLoading, refetch } = useQuery<OrderAttach[]>(
		['attach_modal', idOrder],
		() =>
			orderService.getAttach({ params: { id: idOrder } }).then((res) => {
				const newRes = res.map((att) => {
					let isImage = false;
					let isZip = false;
					let isPdf = false;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					let icon: any;
					const { file } = att;

					if (
						file.endsWith('jpg') ||
						file.endsWith('jpeg') ||
						file.endsWith('png')
					) {
						isImage = true;
						icon = faFileImage;
					} else if (file.endsWith('zip')) {
						isZip = true;
						icon = faFileArchive;
					} else if (file.endsWith('pdf')) {
						isPdf = true;
						icon = faFilePdf;
					} else {
						icon = faFile;
					}

					const fileName = file.replace(/^.*(\\|\/|:)/, '');
					return {
						...att,
						isPdf,
						isZip,
						isImage,
						icon,
						fileName,
						file: `${envJson.api_url}/${att.file}`,
					};
				});
				return newRes;
			}),
		{
			enabled: idOrder !== null,
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados.', {
					type: 'error',
				});
			},
		}
	);

	const onAttachClick = useCallback(
		({ file, fileName, isImage }: OrderAttach) => {
			if (isImage) {
				modal(FeaturedImageModal, { imageSrc: file, fileName }, () => {});
			} else {
				const a = document.createElement('a');
				a.href = file || '';
				a.target = '_blank';
				document.body.appendChild(a);
				a.click();
				a.remove();
			}
		},
		[modal]
	);

	const removeAttach = useCallback(
		({ idAttach, fileName }: OrderAttach) => {
			setIsLoadingAttach(true);
			modal(
				ConfirmationModal,
				{ message: `Remover anexo ${fileName}?` },
				async (res) => {
					if (res) {
						try {
							await orderService.deleteAttach(Number(idAttach));
							toast('Anexo removido com sucesso.', { type: 'success' });
							await refetch();
						} catch (e) {
							const { message } = e as HttpBaseResult;
							// eslint-disable-next-line no-console
							console.log('Error message: ', message);
							const errorMsg =
								'Erro interno: Ocorreu um erro ao remover o anexo.';
							toast(errorMsg, { type: 'error' });
						}
					}
					setIsLoadingAttach(false);
				}
			);
		},
		[modal, refetch, toast]
	);

	const changeAddAttach = useCallback(
		async (value: UploadFile[]) => {
			setIsAddingAttach(true);
			setIsLoadingAttach(true);

			const { url, error, name } = value[0];
			const dataSave: DataSave = {
				file: String(url),
				idOrder,
				idAttach: undefined,
				ext: name.substring(name.lastIndexOf('.') + 1),
			};

			if (error) {
				toast(error, { type: 'error' });
				setIsAddingAttach(false);
				setIsLoadingAttach(false);
				return;
			}

			try {
				await orderService.saveAttach(dataSave);
				toast('Anexo enviado com sucesso.', { type: 'success' });
				await refetch();
			} catch (e) {
				const { message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				const errorMsg =
					'Erro interno: Ocorreu um erro ao anexar o comprovante.';
				toast(errorMsg, { type: 'error' });
			}
			setIsAddingAttach(false);
			setIsLoadingAttach(false);
		},
		[idOrder, refetch, toast]
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			{isLoading ? (
				<>
					<Modal.Header closeButton>
						<Skeleton width="150px" height="2rem" />
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex">
							<span className="mr-2">
								<Skeleton height="13rem" width="180px" />
							</span>
						</div>
					</Modal.Body>
					<Modal.Footer className="justify-content-end">
						<Skeleton width="100px" height="2rem" />
					</Modal.Footer>
				</>
			) : (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							{`Anexos (Pedido #${numberFormatter.format(number)})`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{data.length > 0 && !isLoadingAttach && (
							<AttachList>
								{data.map((item) => (
									<AttachItem key={String(item.idAttach)}>
										<AttachIcon
											// eslint-disable-next-line no-nested-ternary
											bg={item.isPdf ? 'red' : item.isZip ? 'yellow' : 'blue'}
											onClick={() => onAttachClick(item)}
										>
											<FAIcon icon={item.icon} size="5x" />
										</AttachIcon>
										<div>
											{enableRemove && (
												<Button
													variant="link"
													size="sm"
													onClick={() => removeAttach(item)}
												>
													<FAIcon icon={faTrash} className="text-danger" />
												</Button>
											)}
											<span>{item.fileName}</span>
										</div>
									</AttachItem>
								))}
							</AttachList>
						)}
						{!data.length && !isLoadingAttach && (
							<p>
								Não há anexos para esse pedido.
								{enableUpload && (
									<>
										<br />
										Clique no botão abaixo para enviar anexos (máximo 3 por
										pedido).
									</>
								)}
							</p>
						)}
						{isLoadingAttach && (
							<div className="d-flex">
								<span className="mr-2">
									<Skeleton height="13rem" width="180px" />
								</span>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer align="end">
						{enableUpload && data.length < 3 && (
							<Upload
								name="attachs"
								accept="image/png,image/jpg,image/jpeg,application/zip,application/pdf"
								maxSize={5}
								showUploadList={false}
								onChange={changeAddAttach}
								className="mr-0"
							>
								<Button type="button" variant="info" disabled={isLoadingAttach}>
									{isAddingAttach ? (
										<>
											<Spinner size="sm" animation="border" role="status" />{' '}
											Anexando comprovante
										</>
									) : (
										<>
											<FAIcon icon={faUpload} className="mr-2" /> Anexar
											Comprovante
										</>
									)}
								</Button>
							</Upload>
						)}
						<Button
							variant="outline-primary"
							onClick={() => modalRef.dismiss()}
						>
							Voltar
						</Button>
					</Modal.Footer>
				</>
			)}
		</Modal>
	);
};

export default AttachModal;
