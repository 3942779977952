import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Link, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useQuery, ArrayQueryKey } from 'react-query';

import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { InputQty } from 'components';
import ecommerceService from 'services/ecommerce.service';
import {
	actions as cartActions,
	selectors as cartSelectors,
	MAX_PRODUCT_PER_CART,
} from 'store/ducks/shoppingCart';
import { EcProductDetails, UrlParams } from 'types';
import { UrlSlugToID, TextToUrlSlug, history } from 'utils';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import {
	Title,
	Small,
	Paragraph,
	Text,
	ProductPrice,
	Breadcrumb,
} from 'packages/ecommerce/components/Typography';

import {
	useSetShoppingCartVisible,
	useShoppingCartName,
	useEcommerceBasePath,
} from '../hooks';

import ProductDetailsSkeleton from './productDetails/Skeleton';

const ProductDetails: React.FC = () => {
	const [quantity, setQuantity] = useState<number>(1);
	const { url } = useRouteMatch();
	const { id } = useParams<UrlParams>();
	const baseUrl = useEcommerceBasePath();
	const idProduct = UrlSlugToID(id);
	const dispatch = useDispatch();
	const cartName = useShoppingCartName();
	const cartItem = useSelector(cartSelectors.getCartItem(cartName, idProduct));
	const setCartSidebarVisible = useSetShoppingCartVisible();

	const { isLoading, data: product } = useQuery<
		EcProductDetails,
		ArrayQueryKey
	>(
		['product_details', idProduct],
		() =>
			ecommerceService.getProductDetails(idProduct).then((result) => {
				const prod = result;
				prod.category = prod.category.reverse();
				return prod;
			}),
		{
			onError: () => {
				history.push(baseUrl);
			},
		}
	);

	const onAddToCart = () => {
		if (cartItem) {
			dispatch(cartActions.updateQuantity(cartName, idProduct, quantity));
		} else {
			dispatch(cartActions.addItem(cartName, idProduct, quantity));
		}
		setCartSidebarVisible(true);
	};

	useEffect(() => {
		if (!product) {
			return;
		}
		const productSlug = `${String(product.idProduct)}-${TextToUrlSlug(
			product.name
		)}`;
		const paramIndex = url.lastIndexOf('/');
		const newUrl = `${url.substring(0, paramIndex + 1)}${productSlug}`;
		if (url !== newUrl) {
			history.replace(newUrl);
		}
	}, [product, url]);

	if (isLoading || !product) {
		return <ProductDetailsSkeleton />;
	}

	return (
		<Container fluid className="mb-5 pb-5">
			<Breadcrumb>
				<Breadcrumb.Item as={Link} to={baseUrl} linkAs="span">
					<FAIcon icon={faHome} />
				</Breadcrumb.Item>
				{product.category.map((cat) => (
					<Breadcrumb.Item
						as={Link}
						key={cat.idCategory}
						to={`${baseUrl}/categoria/${cat.idCategory}`}
						linkAs="span"
					>
						{cat.shortName}
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
			<header className="pb-2">
				<Title>{product.name}</Title>
				<Small className="text-muted">Cód. {product.code}</Small>
			</header>
			<Row>
				<Col md={9} lg={9}>
					<Row>
						<Col md={5}>
							<Image
								src={product.image[0].idImage}
								width="100%"
								alt="Foto de produto"
							/>
						</Col>
						<Col md={7}>
							<Paragraph className="mx-0 mx-md-4 mx-lg-4 mx-xl-4">
								{product.description}
							</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col md={3} lg={3}>
					{product.priceBase !== product.price && (
						<Text className="text-muted">
							De:{' '}
							<del>{currencyWithSymbolFormatter.format(product.priceBase)}</del>
						</Text>
					)}
					<ProductPrice className="text-primary">
						{currencyWithSymbolFormatter.format(product.price)}
					</ProductPrice>

					{cartItem && (
						<small className="text-muted">
							Você tem <b>{cartItem.quantity}</b> em seu carrinho
						</small>
					)}
					<hr className="my-3" />

					<Small>Quantidade:</Small>
					<InputQty
						size="lg"
						quantity={quantity}
						onChange={(quant: number) => setQuantity(quant)}
						maxLength={MAX_PRODUCT_PER_CART}
					/>
					<Button
						variant="dark"
						size="lg"
						className="mt-2 text-center"
						block
						onClick={onAddToCart}
					>
						Adicionar ao Carrinho
					</Button>
					<Button
						variant="outline-primary"
						size="lg"
						className="mt-2 text-center"
						block
					>
						Comprar Agora
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default ProductDetails;
