import {
	ProductSubcategory,
	StoreProduct,
	Store,
	HttpGetListResult,
	HttpGetListParams,
	Type,
	MenuStore,
	EcStoreConfig,
	EcLang,
	EcBanner,
	EcMenuCategory,
	ID,
	EcProductDetails,
	EcCategoryConfig,
	CompanyForList,
} from 'types';

import Crud from './crud.service';

/**
 * Service de requisição de dados do ecommerce no backend
 */

const getDepartments = (
	route = 'product/category'
): Promise<EcMenuCategory[]> =>
	Crud.getListData<EcMenuCategory>(route, {}).then(async (departments) => {
		// Para cada departamento, carrega as categorias internas:
		const subcats = departments.map((dep) =>
			Crud.getListData<ProductSubcategory>(
				`product/category/${dep.idCategory}/subcategory`,
				{}
			)
		);
		return Promise.all(subcats).then((results) =>
			departments.map((dep, index: number) => ({
				...dep,
				categories: results[index],
			}))
		);
	});

const getStores = (
	params: HttpGetListParams,
	route = 'store'
): Promise<HttpGetListResult<Store>> => Crud.getList<Store>(route, params);

const getLangs = (
	params: HttpGetListParams,
	route = 'lang'
): Promise<HttpGetListResult<EcLang>> => Crud.getList<EcLang>(route, params);

const getCompanies = (
	params: HttpGetListParams,
	route = 'company'
): Promise<HttpGetListResult<CompanyForList>> =>
	Crud.getList<CompanyForList>(route, params);

const getTypes = (
	params: HttpGetListParams,
	route = 'store/type'
): Promise<HttpGetListResult<Type>> => Crud.getList<Type>(route, params);

const getMenus = (
	params: HttpGetListParams,
	route = 'product/category/select'
): Promise<HttpGetListResult<MenuStore>> =>
	Crud.getList<MenuStore>(route, params);

const getProducts = (route = 'store/790408'): Promise<StoreProduct[]> =>
	Crud.getListData<StoreProduct>(route, {
		pagination: { page: 1, paginate: 6 },
	}).then(async (products) => products);

const getBanners = (
	params: HttpGetListParams
): Promise<HttpGetListResult<EcBanner>> =>
	Crud.getList<EcBanner>('store/banner', params);

const getStoreMMN = (route = 'my/storeMMN'): Promise<EcStoreConfig> =>
	Crud.getOne<EcStoreConfig>(route, 0);

const getCategory = (
	id: ID,
	route = 'my/storeMMN/category/{id}'
): Promise<EcCategoryConfig> =>
	Crud.getOne<EcCategoryConfig>(route, id as number);

const getCategoryProducts = (
	idCategory: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<EcProductDetails>> =>
	Crud.getList<EcProductDetails>(
		`my/storeMMN/category/${idCategory}/product`,
		params
	);

const getProductDetails = (
	id: ID,
	route = 'my/storeMMN/product/{id}'
): Promise<EcProductDetails> =>
	Crud.getOne<EcProductDetails>(route, id as number);

export default {
	getDepartments,
	getProducts,
	getBanners,
	getStores,
	getLangs,
	getCompanies,
	getTypes,
	getMenus,
	getStoreMMN,
	getCategory,
	getCategoryProducts,
	getProductDetails,
};
