/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';

import { Formik, Form as FormikForm, FormikProps } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import Card from 'components/Card';
import {
	Input,
	Switch,
	AsyncSelect,
	DateTimeInput,
	FormikEffect,
} from 'components/Formik';
import { useToast } from 'hooks';
import campaignService from 'services/campaign.service';
import { dateFormatter, currencyFormatter } from 'utils/formatters';
import history from 'utils/history';

import {
	FormCancelButton,
	FormSubmitButton,
	DatatableView,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';
import listConfigs from 'packages/admin/pages/list/list.routes';

import PageLayoutFormWrapper from '../../components/PageLayoutFormWrapper';

import SkeletonForm from './campaign/SkeletonForm';
import { CampaignFormType } from './campaign/types';

export const defaultFormValue: CampaignFormType = {
	idCampaign: null,
	name: '',
	initialDate: dateFormatter.localeStringToIso(new Date().toLocaleString()),
	endDate: null,
	status: '',
	type: null,
	value: '',
	qtyWinners: null,
};

export const formSchema = Yup.object<CampaignFormType>().shape({
	name: Yup.string().required('Campo obrigatório'),
	type: Yup.object().nullable().required('Campo obrigatório'),
	initialDate: Yup.string().nullable().required('Campo obrigatório'),
	endDate: Yup.string()
		.nullable()
		.required('Campo obrigatório')
		.when('initialDate', (initialDate: string, schema: Yup.DateSchema) =>
			schema.test(
				'is_valid_date_range',
				'A data precisa ser posterior à data de início',
				(endDate) => {
					return (
						(endDate ? new Date(endDate).getTime() : 0) -
							new Date(initialDate).getTime() >
						0
					);
				}
			)
		),
	value: Yup.string().when('type.idType', {
		is: 1,
		then: Yup.string().required('Campo obrigatório'),
	}),
	qtyWinners: Yup.number()
		.nullable()
		.typeError('Precisa ser um número válido')
		.when('type.idType', {
			is: 1,
			then: Yup.number()
				.nullable()
				.typeError('Precisa ser um número válido')
				.required('Campo obrigatório'),
		}),
});

const CampaignEdit: React.FC = () => {
	const [toast] = useToast();
	const [grouped, setGrouped] = useState<boolean>(false);

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return {
					campaign: defaultFormValue,
				};
			}

			return {
				campaign: await campaignService.getById(Number(id)),
			};
		},
		[]
	);

	const handleSubmit = useCallback(
		async (id: string, values: CampaignFormType, { setSubmitting }) => {
			setSubmitting(true);
			const isCreating = id === 'create';

			const submit = {
				...values,
				idType: values.type?.idType,
			};

			campaignService
				.createOrUpdate(submit.idCampaign, submit)
				.then((_result) => {
					toast(`Campanha ${!isCreating ? 'alterada' : 'criada'} com sucesso`, {
						type: 'success',
					});
					history.push('/admin/communication/campaigns');
				})
				.catch((e) => {
					toast(`Não foi possível editar a campanha. Cód: ${e.code}`, {
						type: 'error',
					});
				});

			setSubmitting(false);
		},
		[toast]
	);

	const changeCheckGrouped = useCallback((isChecked) => {
		setGrouped(isChecked);
	}, []);

	return (
		<PageLayoutFormWrapper
			title="Campanha"
			breadcrumb={[
				{ label: 'Comunicação', path: '/admin/communication/campaigns' },
				{ label: 'Campanhas', path: '/admin/communication/campaigns' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.campaign}
					validationSchema={formSchema}
				>
					{(formProps: FormikProps<CampaignFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit={id === 'create'} />

							<Card>
								<Card.Content>
									<Row>
										<Col md={6}>
											<FormGroupTitle>Dados da Campanha</FormGroupTitle>
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" type="text" />
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Tipo</Form.Label>
													<AsyncSelect
														name="type"
														fetchMethod={campaignService.getTypes}
														optionValue="idType"
														isClearable
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={6} lg={6}>
													<Form.Label>Data Inicio</Form.Label>
													<DateTimeInput
														name="initialDate"
														selectDate
														selectTime
													/>
												</Form.Group>
												<Form.Group as={Col} md={6} lg={6}>
													<Form.Label>Data Fim</Form.Label>
													<DateTimeInput name="endDate" selectDate selectTime />
												</Form.Group>
											</Form.Row>

											{formProps.values.type?.idType === 1 && (
												<Form.Row>
													<Form.Group as={Col} md={6}>
														<Form.Label>Valor Mínimo</Form.Label>
														<Input
															name="value"
															type="text"
															formatter={currencyFormatter}
															prepend={
																<InputGroup.Text>
																	<FAIcon icon="dollar-sign" />
																</InputGroup.Text>
															}
														/>
													</Form.Group>

													<Form.Group as={Col} md={6}>
														<Form.Label>Quantidade de Premiados</Form.Label>
														<Input name="qtyWinners" type="text" />
													</Form.Group>
												</Form.Row>
											)}

											{id !== 'create' && (
												<>
													<FormGroupTitle className="mt-3">
														Premiados
													</FormGroupTitle>

													{formProps.values.status !== 'Encerrada' ? (
														<p className="text-center">
															Não há sorteados para essa campanha
															<br />
															<Button
																as="a"
																variant="secondary"
																href="#sorteio"
																target="_blank"
																size="sm"
															>
																Fazer Sorteio
															</Button>
														</p>
													) : (
														<DatatableView
															config={
																listConfigs.CommunicationCampaignWinners
																	.datatableConfig
															}
															serviceMethod={listConfigs.CommunicationCampaignWinners.action(
																id
															)}
														/>
													)}
												</>
											)}
										</Col>

										{id !== 'create' && (
											<Col md={6} className="border-left">
												<Form.Row>
													<Form.Group as={Col} md={12}>
														<FormGroupTitle>
															Participantes
															<Form.Group className="float-right d-inline mb-0">
																<Switch
																	name="grouped"
																	onChange={(isChecked) => {
																		changeCheckGrouped(isChecked);
																	}}
																/>
																<Form.Label className="text-muted pl-1">
																	Agrupar cupons
																</Form.Label>
															</Form.Group>
														</FormGroupTitle>

														<DatatableView
															config={
																grouped
																	? listConfigs
																			.CommunicationCampaignCouponsGrouped
																			.datatableConfig
																	: listConfigs.CommunicationCampaignCoupons
																			.datatableConfig
															}
															serviceMethod={
																grouped
																	? listConfigs.CommunicationCampaignCouponsGrouped.action(
																			id
																	  )
																	: listConfigs.CommunicationCampaignCoupons.action(
																			id
																	  )
															}
														/>
													</Form.Group>
												</Form.Row>
											</Col>
										)}
									</Row>
								</Card.Content>

								<Card.Content className="text-right">
									<FormCancelButton to="/admin/communication/campaigns" />
									{formProps.values.status !== 'Encerrada' && (
										<FormSubmitButton />
									)}
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default CampaignEdit;
