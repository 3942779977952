import React from 'react';

import {
	faBookmark,
	faCube,
	faCubes,
	faDesktop,
	faDolly,
	faEnvelope,
	faMoneyBill,
	faShoppingBag,
	faTruck,
} from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import StoreBannerEdit from './Banner';
import CarrierEditPage from './Carrier';
import StoreExpeditionEdit from './Expedition';
import StoreFeatureEdit from './Feature';
import StoreEdit from './Store';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const StoreRoutes: RouteDef = {
	path: '/admin/stores',
	menu: {
		name: 'Loja',
		icon: faShoppingBag,
	},
	children: [
		{
			path: '/stores',
			render: getListSceneRenderFn(listConfigs.Stores),
			exact: true,
			menu: {
				name: 'Lojas',
				icon: faDesktop,
			},
		},
		{
			path: '/stores/:id',
			component: StoreEdit,
			exact: true,
		},
		{
			path: '/banners',
			render: getListSceneRenderFn(listConfigs.StoreBanners),
			exact: true,
			menu: {
				name: 'Banners',
				icon: faDesktop,
			},
		},
		{
			path: '/banners/:id',
			component: StoreBannerEdit,
			exact: true,
		},
		{
			path: '/features',
			render: getListSceneRenderFn(listConfigs.StoreFeatures),
			exact: true,
			menu: {
				name: 'Destaques',
				icon: faDesktop,
				separator: true,
			},
		},
		{
			path: '/features/:id',
			component: StoreFeatureEdit,
			exact: true,
		},
		{
			path: '/categories',
			menu: {
				name: 'Categorias',
				icon: faBookmark,
			},
		},
		{
			path: '/products',
			menu: {
				name: 'Produtos',
				icon: faCube,
			},
		},
		{
			path: '/groups',
			menu: {
				name: 'Grupo de Produtos',
				icon: faCubes,
				separator: true,
			},
		},
		{
			path: '/expeditions',
			render: getListSceneRenderFn(listConfigs.StoreExpeditions),
			exact: true,
			menu: {
				name: 'Expedição',
				icon: faDolly,
			},
		},
		{
			path: '/expeditions/:id',
			component: StoreExpeditionEdit,
			exact: true,
		},
		{
			path: '/carriers',
			render: getListSceneRenderFn(listConfigs.StoreCarriers),
			exact: true,
			menu: {
				name: 'Transportadoras',
				icon: faTruck,
			},
		},
		{
			path: '/carriers/:id',
			component: CarrierEditPage,
			exact: true,
		},
		{
			path: '/shipping',
			menu: {
				name: 'Envio e Frete',
				icon: faEnvelope,
			},
		},
		{
			path: '/shipping-table',
			menu: {
				name: 'Tabela de Frete',
				icon: faDesktop,
			},
		},
		{
			path: '/payments',
			menu: {
				name: 'Formas de Pagamento',
				icon: faMoneyBill,
			},
		},
	],
};

export default StoreRoutes;
