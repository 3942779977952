import React from 'react';

import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components';
import { NewsNotification } from 'types';
import { history, TextToUrlSlug, ClearHtml } from 'utils';
import { dateHourMinFormatter } from 'utils/formatters';

import { Card, AuthorDate } from './cardNews/styles';

interface CardNewsProps {
	item: NewsNotification;
	className?: string;
	style?: React.CSSProperties;
}

const CardNews: React.FC<CardNewsProps> = ({ item }) => {
	const description = ClearHtml(item.description);

	return (
		<Card>
			<div style={{ maxHeight: '200px', overflow: 'hidden' }}>
				<Card.Img
					variant="top"
					src={String(item?.imgUrl) || '/assets/images/image_news_default.jpg'}
					alt={item.title}
				/>
			</div>
			<Card.Body>
				<Card.Title>{item.title}</Card.Title>
				<AuthorDate className="text-muted">
					{dateHourMinFormatter.format(item.releaseDate)}
				</AuthorDate>
				<Card.Text>
					{description.slice(0, 150)}
					{description.length > 150 ? '...' : ''}
				</Card.Text>
				<div className="d-flex justify-content-end">
					<Button
						size="sm"
						variant="secondary"
						onClick={() =>
							history.push({
								pathname: `/app/utils/news/${TextToUrlSlug(item.title)}`,
								state: item,
							})
						}
					>
						<FAIcon icon={faPlus} className="mr-1" /> Leia mais
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
};

export default CardNews;
