import React from 'react';

import {
	faCubes,
	faGlobe,
	faGrinAlt,
	faMoneyBill,
	faNewspaper,
	faPlus,
	faShoppingCart,
	faUsers,
} from '@fortawesome/pro-light-svg-icons';

import { PersonStatus, PersonType, RouteDef } from 'types';
import personStatusValidation from 'utils/RouteValidator/personStatusValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not, Some } from 'utils/RouteValidator/validationCombiners';

import List from 'packages/admin/pages/List';
import listConfigs from 'packages/escritorio/pages/list/list.routes';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';

import User from './User';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const NotDistribUserValidator = [
	Not(personTypeValidation(PersonType.DistributorUser)),
];

const DistributorRoutes: RouteDef = {
	path: '/app/distributor',
	validation: [
		personStatusValidation(PersonStatus.Active),
		Some(
			personTypeValidation(PersonType.Distributor),
			personTypeValidation(PersonType.DistributorUser)
		),
	],
	menu: {
		name: 'PA',
		icon: faGlobe,
	},
	children: [
		{
			path: '/order',
			render: getListSceneRenderFn(listConfigs.Distributors),
			exact: true,
			menu: {
				name: 'Nova Venda',
				icon: faPlus,
			},
		},
		{
			path: '/order/history',
			render: getListSceneRenderFn(listConfigs.DistributorsOrderHistory),
			exact: true,
			menu: {
				name: 'Vendas',
				icon: faShoppingCart,
			},
		},
		{
			path: '/stock',
			render: getListSceneRenderFn(listConfigs.DistributorStock),
			menu: {
				name: 'Estoque',
				icon: faCubes,
			},
		},
		{
			path: '/stock/details',
			render: getListSceneRenderFn(listConfigs.DistributorStockDetails),
		},
		{
			path: '/creditOrders',
			render: getListSceneRenderFn(listConfigs.DistributorCreditOrders),
			validation: NotDistribUserValidator,
			menu: {
				name: 'Pedidos de Marketplace',
				icon: faMoneyBill,
				separator: true,
			},
		},
		{
			path: '/club/credit',
			render: getListSceneRenderFn(listConfigs.Distributors),
			validation: NotDistribUserValidator,
			menu: {
				name: 'Família Feliz',
				icon: faGrinAlt,
			},
		},
		{
			path: '/club/order',
			render: getListSceneRenderFn(listConfigs.Distributors),
			exact: true,
			menu: {
				name: 'Nova Venda',
				icon: faPlus,
			},
		},
		{
			path: '/club/creditHistory',
			render: getListSceneRenderFn(listConfigs.Distributors),
			validation: NotDistribUserValidator,
			menu: {
				name: 'Histórico de Créditos',
				icon: faNewspaper,
			},
		},
		// (isUserPa) ? null :
		{
			path: '/club/creditOrders',
			render: getListSceneRenderFn(listConfigs.Distributors),
			validation: NotDistribUserValidator,
			menu: {
				name: 'Pedidos de Créditos',
				icon: faMoneyBill,
				separator: true,
			},
		},
		// (isUserPa) ? null :
		{
			path: '/user',
			render: getListSceneRenderFn(listConfigs.DistributorUser),
			exact: true,
			validation: NotDistribUserValidator,
			menu: {
				name: 'Usuários',
				icon: faUsers,
			},
		},
		{
			path: '/user/:id',
			component: User,
			exact: true,
			validation: NotDistribUserValidator,
		},
	],
};

export default DistributorRoutes;
