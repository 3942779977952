import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';

import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { FormatterType } from 'utils/Formatter/IFormatter';

/**
 *	Definição de props necessárias para o input
 */
export type InputProps = Omit<FormControlProps, 'onChange'> & {
	name?: string;
	placeholder?: string;
	formatter?: FormatterType;
	outputFormatted?: boolean;
	className?: string;
	prepend?: React.ReactNode | React.ReactNodeArray;
	append?: React.ReactNode | React.ReactNodeArray;
	error?: string;
	autocomplete?: string;
	onChange?: (value: string) => void;
	onBlur?: (event: React.ChangeEvent) => void;
	onFocus?: (event: React.FocusEvent) => void;
};

const Input: React.FC<InputProps> = (props) => {
	const {
		formatter,
		outputFormatted,
		onChange,
		prepend,
		append,
		error,
		autocomplete,
		value,
		...rest
	} = props;

	const [isControlled, setIsControlled] = useState<boolean>(
		value !== undefined
	);
	// Texto formatado exibido no input:
	const [formattedValue, setFormattedValue] = useState<string>(
		formatter
			? formatter.formatForInput(value as string)[1]
			: (value as string) || ''
	);

	const updateInputAndRef = useCallback(
		(newVal: string, updateRef = false) => {
			const val = newVal || '';
			const [rawValue, formatValue] = formatter
				? formatter.formatForInput(val)
				: [val, val];

			if (isControlled) {
				if (updateRef) {
					onChange?.(outputFormatted ? formatValue : rawValue);
				} else {
					setFormattedValue(formatValue);
				}
			} else {
				if (updateRef) {
					onChange?.(outputFormatted ? formatValue : rawValue);
				}
				setFormattedValue(formatValue);
			}
		},
		[formatter, onChange, outputFormatted, isControlled]
	);

	// Atualiza value do input apenas se o value externo mudar,
	// senão continua usando valor interno
	useEffect(() => {
		setIsControlled(value !== undefined);
		updateInputAndRef((value as string) || '', false);
	}, [updateInputAndRef, value]);

	const onInputChange = useCallback(
		(input: ChangeEvent<HTMLInputElement>) => {
			const val = input.target.value;
			updateInputAndRef(val, true);
		},
		[updateInputAndRef]
	);

	return (
		<InputGroup>
			{prepend && <InputGroup.Prepend>{prepend}</InputGroup.Prepend>}
			<FormControl
				as="input"
				value={formattedValue}
				onChange={onInputChange}
				isInvalid={!!error}
				{...rest}
				autoComplete={autocomplete}
			/>
			{append && <InputGroup.Append>{append}</InputGroup.Append>}
			{error && (
				<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
			)}
		</InputGroup>
	);
};

export default Input;
