import React, { FunctionComponent } from 'react';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';

import { faCheckCircle, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Input } from 'components/Formik';

/**
 * Dados retornada no onSubmit
 */
export interface ForgotPassFormModel {
	email: string;
}

type ForgotPassFormProps = {
	isLoading?: boolean;
	showMessage: boolean;
	initialData: ForgotPassFormModel;
	onSubmit: (
		form: ForgotPassFormModel,
		actions?: FormikHelpers<ForgotPassFormModel>
	) => void;
};

const formSchema = Yup.object({
	email: Yup.string()
		.email('E-mail inválido')
		.trim()
		.required('Campo obrigatório'),
});

/**
 * Formulário com o email para reenvio da nova senha.
 * O onSubmit somente é chamado quando os dados passam pela validação
 */
const ForgotPassForm: FunctionComponent<ForgotPassFormProps> = ({
	isLoading,
	showMessage,
	initialData,
	onSubmit,
}) => {
	return (
		<Formik
			onSubmit={onSubmit}
			validationSchema={formSchema}
			initialValues={initialData}
		>
			{(form: FormikProps<ForgotPassFormModel>) => (
				<FormikForm>
					{showMessage ? (
						<Form.Group className="text-center">
							<div className="mb-3">
								<FAIcon icon={faCheckCircle} size="4x" color="#03b304" />
							</div>
							<p>
								E-mail enviado para <strong>{form.values.email}</strong>
							</p>
						</Form.Group>
					) : (
						<>
							<Form.Group>
								<p>Digite seu e-mail para receber uma senha provisória.</p>
							</Form.Group>

							<Form.Group>
								<Input
									disabled={isLoading}
									name="email"
									type="text"
									placeholder="E-mail"
									prepend={
										<InputGroup.Text>
											<FAIcon fixedWidth icon={faEnvelope} />
										</InputGroup.Text>
									}
								/>
							</Form.Group>

							<div className="text-right">
								<Button
									variant="primary"
									className="mt-2"
									type="submit"
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<Spinner size="sm" animation="border" role="status" />
											<span className="ml-2">AGUARDE...</span>
										</>
									) : (
										'ENVIAR'
									)}
								</Button>
							</div>
						</>
					)}
				</FormikForm>
			)}
		</Formik>
	);
};

export default ForgotPassForm;
