import React, { useCallback, useEffect, useState } from 'react';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FormikProps,
	Field,
	FormikHelpers,
} from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import {
	faChevronDown,
	faCircle,
	faClock,
	faComment,
	faPencil,
	faPlus,
	faSearch,
	faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Button } from 'components';
import { AsyncSelect, Input, Checkbox } from 'components/Formik';
import { useModal, useThrottle, useToast } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import executiveService from 'services/executive.service';
import followupService from 'services/followup.service';
import {
	ID,
	FollowUp,
	ExecutiveLightForSelect,
	HttpGetListParams,
	HttpBaseResult,
	HttpGetListResult,
} from 'types';
import {
	numberFormatter,
	timeFormatter,
	dateFormatter,
} from 'utils/formatters';
import Sort from 'utils/Sorters';

import asyncSelectQueries from 'packages/admin/utils/AsyncSelectQueries';

import FollowupConfirmationModal from './followup/FollowUpConfirmationModal';
import {
	Container,
	Timeline,
	TimelineListItem,
	TimelineIcon,
	TimelineItem,
	TimelineBody,
	TimelineHeader,
	TimelineHeaderTime,
	TimeLabel,
} from './followup/styles';

type FollowupByUser = {
	idUser: ID;
	idOrder?: never;
};

type FollowupByOrder = {
	idUser?: never;
	idOrder: ID;
};

type FollowupProps = (FollowupByUser | FollowupByOrder) & {
	style?: React.CSSProperties;
	className?: string;
};

type FollowupHistory = {
	date: string;
	data: FollowUp[];
};

type FollowupService = (
	id: number,
	params: HttpGetListParams
) => Promise<HttpGetListResult<FollowUp>>;

type FollowupFormType = {
	closedCtrl: boolean;
	observationCtrl: string;
};

export const defaultFormValue: FollowupFormType = {
	closedCtrl: false,
	observationCtrl: '',
};

const dateToday = new Date();

export const formSchema = Yup.object<FollowupFormType>().shape({
	closedCtrl: Yup.bool().nullable(),
	observationCtrl: Yup.string().required('Campo obrigatório'),
});

const Followup: React.FC<FollowupProps> = (props) => {
	const { idUser, idOrder, ...rest } = props;

	const [idUserCtrl, setIdUserCtrl] = useState<ID | null>(idUser || null);
	const [idOrderCtrl, setIdOrderCtrl] = useState<ID | null>(idOrder || null);
	const [editing, setEditing] = useState<boolean>(false);
	const [isLoadingClose, setIsLoadingClose] = useState<boolean>(false);
	const [history, setHistory] = useState<FollowupHistory[]>([]);
	const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(false);
	const [hasMoreHistory, setHasMoreHistory] = useState<boolean>(false);
	const [paginate] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [toast] = useToast();
	const open = useModal();
	const {
		auth: { person },
	} = useSessionContext();

	const createMarkup = useCallback((description) => {
		return { __html: description };
	}, []);

	const loadHistory = useCallback(
		async (reloadOnly = false) => {
			let id: number;
			let service: FollowupService;
			const page = currentPage + 1;

			if (typeof idUser !== 'undefined') {
				id = idUser;
				service = followupService.getByPersonId;
			} else {
				id = idOrder!;
				service = followupService.getByOrderId;
			}

			setIsHistoryLoading(true);
			const params: HttpGetListParams = {
				pagination: {
					...(!reloadOnly
						? // Carrega a próxima página normalmente
						  {
								paginate,
								page,
						  }
						: // Carrega todos os registros visíveis
						  {
								paginate: currentPage * paginate,
								page: 1,
						  }),
					sort: '-openDate',
				},
			};

			try {
				const { data, meta } = await service(id, params);

				const totalPages = meta?.last_page ?? 1;

				// Dados atuais do history não agrupados por data
				let updatedData = history.flatMap((h) => h.data);

				// Atualiza updatedData
				data.forEach((followUp) => {
					const index = updatedData.findIndex(
						(f) => f.idFollowup === followUp.idFollowup
					);
					if (index !== -1) {
						updatedData[index] = followUp;
					} else {
						updatedData.push(followUp);
					}
				});

				// Reordena
				updatedData = updatedData.sort(Sort.Alphabetically('openDate', 'DESC'));

				const auxHistory: FollowupHistory[] = [];

				// Agrupa por date em auxHistory
				updatedData.forEach((followup) => {
					const openDate = followup.openDate?.substring(0, 10);
					let group = auxHistory.find((h) => h.date === openDate);
					if (!group) {
						group = { date: openDate || '', data: [] };
						auxHistory.push(group);
					}

					group.data.push(followup);
				});

				if (!reloadOnly) {
					setCurrentPage(page);
					setHasMoreHistory(page < totalPages);
				}
				setHistory(auxHistory);
				setIsHistoryLoading(false);
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(`Não foi possível obter os Follow-ups. Cód: ${code}`, {
					type: 'error',
				});
			}
		},
		[paginate, currentPage, history, idOrder, idUser, toast]
	);

	const handleSubmit = useCallback(
		async (values: FormikValues, helpers: FormikHelpers<FollowupFormType>) => {
			const submit = { ...values } as FollowupFormType;
			helpers.setSubmitting(true);

			const followUp: FollowUp = {
				idAttendant: String(person!.idUser),
				idPerson: idUser || idUserCtrl ? String(idUser || idUserCtrl) : null,
				idOrder: idOrder || idOrderCtrl ? String(idOrder || idOrderCtrl) : null,
				description: submit.observationCtrl,
				closed: !submit.closedCtrl,
			};

			try {
				const { message } = await followupService.createOrUpdate(
					null,
					followUp
				);
				// eslint-disable-next-line no-console
				console.log('Success: ', message);
				setEditing(!!(idOrder || idUser));
				setIdOrderCtrl(null);

				// Atualiza todos os registros já carregados
				loadHistory(true);

				helpers.resetForm();
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(`Não foi possível criar o Follow-up. Cód: ${code}`, {
					type: 'error',
				});
			}
			helpers.setSubmitting(false);
		},
		[idOrder, idOrderCtrl, idUser, idUserCtrl, loadHistory, person, toast]
	);

	// Primeiro carregamento
	useEffect(() => {
		loadHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idUserCtrl, idOrderCtrl]);

	useEffect(() => {
		if (idUser) {
			setEditing(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCloseFollowup = useCallback(
		(followup, description) => {
			setIsLoadingClose(true);

			followupService
				.patch(followup.idFollowup, { isActive: true, isClosed: true })
				.then(() => {
					// Atualiza o elemento editado no history
					const updatedHistory = history.map(({ date, data }) => ({
						date,
						data: data.map(
							(f): FollowUp =>
								f.idFollowup === followup.idFollowup
									? { ...f, closed: true }
									: f
						),
					}));
					setHistory(updatedHistory);

					// Gera um novo followup de fechamento
					const foll: FollowUp = {
						closed: true,
						idPerson: followup.idPerson,
						idAttendant: String(person!.idUser),
						idOrder: followup.idOrder,
						description,
					};

					followupService
						.createOrUpdate(null, foll)
						.then(() => {
							setIsLoadingClose(false);
							setEditing(!!(idOrder || idUser));
							setIdOrderCtrl(null);

							// Atualiza todos os registros já carregados
							loadHistory(true);
						})
						.catch((e) => {
							setIsLoadingClose(false);
							toast(
								`Não foi possível concluir o fechamento do Follow-up. Cód: ${e.code}`,
								{
									type: 'error',
								}
							);
						});
				})
				.catch((e) => {
					setIsLoadingClose(false);
					toast(
						`Não foi possível concluir o fechamento do Follow-up. Cód: ${e.code}`,
						{
							type: 'error',
						}
					);
				});
		},
		[history, idOrder, idUser, loadHistory, person, toast]
	);

	const onUserChange = useCallback((selected) => {
		setIdUserCtrl(selected ? Number(selected.idPerson) : null);
	}, []);

	const searchTerm = useThrottle((term) => {
		setIdOrderCtrl(Number(term));
	}, 500);

	return (
		<Container {...rest}>
			<Formik
				onSubmit={handleSubmit}
				initialValues={defaultFormValue}
				validationSchema={formSchema}
			>
				{(formProps: FormikProps<FollowupFormType>) => (
					<FormikForm>
						<Timeline>
							{!idUser && !idOrder && (
								<TimelineListItem style={{ marginTop: '15px' }}>
									<TimelineIcon className="bg-blue">
										<FAIcon icon={faSearch} />
									</TimelineIcon>

									<TimelineItem>
										<TimelineBody>
											<Row>
												<Form.Group as={Col} md={8}>
													<Form.Label>Executivo</Form.Label>
													<AsyncSelect
														name="userCtrl"
														fetchMethod={executiveService.getForSelect}
														fetchQuery={asyncSelectQueries.executiveQuery}
														formatOptionLabel={(
															value: ExecutiveLightForSelect
														) => {
															return (
																<>
																	<b>{value.id}</b> {value.name}
																</>
															);
														}}
														optionValue="idPerson"
														onChange={onUserChange}
														isClearable
													/>
												</Form.Group>

												<Form.Group as={Col} md={4}>
													<Form.Label>Pedido</Form.Label>
													<Input
														name="orderCtrl"
														type="text"
														formatter={numberFormatter}
														onChange={searchTerm}
													/>
												</Form.Group>
											</Row>

											{!editing && (
												<div className="text-right">
													<Button
														variant="outline-info"
														onClick={() => setEditing(true)}
													>
														Novo Follow-up
													</Button>
												</div>
											)}
										</TimelineBody>
									</TimelineItem>
								</TimelineListItem>
							)}
							{editing && (
								<TimelineListItem>
									<TimelineIcon className="bg-blue">
										<FAIcon icon={faPencil} />
									</TimelineIcon>

									<TimelineItem>
										<TimelineHeader>
											Novo Follow-up{' '}
											<TimelineHeaderTime>
												<FAIcon icon={faClock} className="mr-2" />
												{dateFormatter.format(dateToday)}
											</TimelineHeaderTime>
										</TimelineHeader>

										<TimelineBody>
											<Form.Group>
												<Form.Label>Observação</Form.Label>
												<Field
													as="textarea"
													name="observationCtrl"
													disabled={formProps.isSubmitting}
													className="resize-vertical form-control"
													rows={5}
												/>
											</Form.Group>

											<Checkbox
												name="closedCtrl"
												label="Verificação posterior necessária."
											/>

											<div className="text-right">
												{!idOrder && !idUser && (
													<Button
														variant="outline-info"
														className="mr-2"
														onClick={() => setEditing(false)}
													>
														Cancelar
													</Button>
												)}
												<Button
													type="submit"
													variant="info"
													disabled={
														!(
															(idUser || idUserCtrl) &&
															formProps.values.observationCtrl
														)
													}
												>
													{formProps.isSubmitting ? (
														<>
															<Spinner
																size="sm"
																animation="border"
																role="status"
															/>
															<span className="ml-2">Aguarde...</span>
														</>
													) : (
														<>
															<FAIcon icon={faPlus} className="mr-2" />{' '}
															Adicionar
														</>
													)}
												</Button>
											</div>
										</TimelineBody>
									</TimelineItem>
								</TimelineListItem>
							)}
							{history.length > 0 &&
								history.map((group, index) => (
									<React.Fragment
										// eslint-disable-next-line react/no-array-index-key
										key={index}
									>
										<TimeLabel>
											<span>{dateFormatter.format(group.date)}</span>
										</TimeLabel>

										{group.data &&
											group.data.map((followup) => (
												<TimelineListItem key={followup.personId}>
													<TimelineIcon className="bg-green">
														{!followup.idOrder && <FAIcon icon={faComment} />}
														{followup.idOrder && (
															<FAIcon icon={faShoppingCart} />
														)}
													</TimelineIcon>

													<TimelineItem
														style={{
															backgroundColor: !followup.closed
																? '#ffefef'
																: '',
															border: !followup.closed ? '1px solid red' : '',
														}}
													>
														<TimelineHeader
															style={{
																backgroundColor: !followup.closed
																	? '#fddede'
																	: '',
															}}
														>
															<TimelineHeaderTime>
																<strong>{followup.attendantName}</strong> às{' '}
																<strong>
																	{timeFormatter.format(
																		followup.openDate || ''
																	)}
																</strong>
															</TimelineHeaderTime>
														</TimelineHeader>
														<TimelineBody>
															<p>
																{(followup.idOrder || !followup.closed) && (
																	<span
																		style={{
																			display: 'block',
																			marginBottom: '10px',
																		}}
																	>
																		{followup.idOrder && (
																			<span
																				className="label text-white bg-warning mr-2"
																				style={{ cursor: 'pointer' }}
																				// onClick={() => onShowOrderInfo(followup)
																			>
																				Pedido {followup.orderNumber}
																			</span>
																		)}
																		{!followup.closed && (
																			<span className="label text-white bg-danger">
																				Pendente
																			</span>
																		)}
																	</span>
																)}
																<span
																	// eslint-disable-next-line react/no-danger
																	dangerouslySetInnerHTML={createMarkup(
																		followup.description
																	)}
																/>
															</p>
															{!followup.closed && (
																<div className="text-right">
																	<Button
																		variant="pink"
																		type="button"
																		disabled={isLoadingClose}
																		onClick={() => {
																			open(
																				FollowupConfirmationModal,
																				{
																					title: 'Fechamento de Follow-up',
																					message: (
																						<p>
																							Executivo: {followup.personName}
																							<br />
																							{followup.idOrder
																								? `Pedido: ${followup.idOrder}`
																								: ''}
																						</p>
																					),
																				},
																				(value?: unknown) =>
																					value
																						? onCloseFollowup(followup, value)
																						: null
																			);
																		}}
																	>
																		{isLoadingClose ? (
																			<>
																				<Spinner
																					size="sm"
																					animation="border"
																					role="status"
																					className="mr-2"
																				/>
																				Aguarde...
																			</>
																		) : (
																			'Concluir Follow-up'
																		)}
																	</Button>
																</div>
															)}
														</TimelineBody>
													</TimelineItem>
												</TimelineListItem>
											))}
									</React.Fragment>
								))}
							<TimelineListItem>
								{isHistoryLoading && (
									<TimelineIcon
										className="bg-green"
										style={{ lineHeight: '30px', top: '-16px' }}
									>
										<Spinner size="sm" animation="border" role="status" />
									</TimelineIcon>
								)}
								{!isHistoryLoading && (
									<>
										{hasMoreHistory && (
											<TimelineIcon
												className="bg-green"
												style={{ top: '-16px' }}
											>
												<FAIcon icon={faChevronDown} />
											</TimelineIcon>
										)}
										{!hasMoreHistory && (
											<TimelineIcon style={{ top: '-16px' }}>
												<FAIcon icon={faCircle} />
											</TimelineIcon>
										)}
									</>
								)}
							</TimelineListItem>
						</Timeline>

						{hasMoreHistory && !isHistoryLoading && (
							<div className="text-center">
								<Button variant="success" onClick={() => loadHistory()}>
									Carregar mais
								</Button>
							</div>
						)}
					</FormikForm>
				)}
			</Formik>
		</Container>
	);
};

export default Followup;
