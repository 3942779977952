import {
	HttpGetListParams,
	HttpGetListResult,
	Score,
	ScoreLineTree,
	ScoreTotal,
} from 'types';

import Crud from './crud.service';

const getScoreByUser = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Score>> =>
	Crud.getList<Score>(
		'mmn/score/byExecutive/{id}?month={month}&year={year}',
		params
	);

const getScoreTotalByUser = (
	params: HttpGetListParams
): Promise<ScoreTotal | ScoreTotal[]> =>
	Crud.getListData<ScoreTotal>(
		'mmn/score/byExecutive/{id}/total?month={month}&year={year}',
		params
	);

const getScoreLineByUser = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ScoreLineTree>> =>
	Crud.getList<ScoreLineTree>(
		'mmn/score/byExecutive/{id}/line?month={month}&year={year}',
		params
	);

const getScoreByOrder = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Score>> =>
	Crud.getList<Score>(
		'mmn/score/byOrder/{orderNumber}/company/{idCompany}',
		params
	);

export default {
	getScoreByUser,
	getScoreTotalByUser,
	getScoreLineByUser,
	getScoreByOrder,
};
