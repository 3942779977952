import React, {
	useState,
	FunctionComponent,
	useCallback,
	useEffect,
} from 'react';

import Button from 'react-bootstrap/Button';

import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Input from 'components/Input';
import { numberFormatter } from 'utils/formatters';

export interface InputQtyProps {
	quantity?: number;
	minLength?: number;
	maxLength?: number;
	size?: 'lg' | 'sm';
	className?: string;
	hideButtons?: boolean;
	onChange?: (qty: number) => void;
}

const defaultProps = {
	quantity: 1,
	minLength: 1,
	maxLength: 99999,
};

const InputQty: FunctionComponent<InputQtyProps> = ({
	quantity,
	minLength,
	maxLength,
	onChange,
	className,
	hideButtons,
	...rest
}) => {
	const [isControlled, setIsControlled] = useState<boolean>(
		quantity !== undefined
	);
	const [qty, setQtyValue] = useState<number>(quantity || 1);
	const [focused, setFocused] = useState<boolean>(false);

	const changeEvent = useCallback(
		(newVal: number, updateRef = true) => {
			const value = Math.max(
				Math.min(newVal, maxLength || 99999),
				minLength || 1
			);
			if (isControlled) {
				if (updateRef) {
					onChange?.(value);
				} else {
					setQtyValue(value);
				}
			} else {
				if (updateRef) {
					onChange?.(value);
				}
				setQtyValue(value);
			}
		},
		[maxLength, minLength, onChange, isControlled]
	);

	const inputChangeQty = useCallback(
		(val: string): void => {
			changeEvent(Number(val));
		},
		[changeEvent]
	);

	useEffect(() => {
		setIsControlled(quantity !== undefined);
		if (quantity !== undefined) {
			changeEvent(quantity, false);
		}
	}, [quantity, changeEvent]);

	const handleQtyDecrease = (): void => {
		changeEvent(Number(qty) - 1);
	};

	const handleQtyIncrease = (): void => {
		changeEvent(Number(qty) + 1);
	};

	const handleFocus = useCallback(() => {
		setFocused(true);
	}, []);

	const handleBlur = useCallback(() => {
		setFocused(false);
	}, []);

	return (
		<Input
			value={qty}
			onChange={inputChangeQty}
			formatter={numberFormatter}
			onFocus={handleFocus}
			onBlur={handleBlur}
			className={classNames(
				className,
				'text-center',
				!hideButtons && focused && 'border-left-0 border-right-0'
			)}
			prepend={
				!hideButtons && (
					<Button
						onClick={handleQtyDecrease}
						variant="outline-light"
						className={classNames(
							'bg-white text-dark border-right-0 border-left border-top border-bottom',
							focused && 'border-primary'
						)}
					>
						<FAIcon icon={faMinus} />
					</Button>
				)
			}
			append={
				!hideButtons && (
					<Button
						onClick={handleQtyIncrease}
						variant="outline-light"
						className={classNames(
							'bg-white text-dark border-left-0 border-right border-top border-bottom',
							focused && 'border-primary'
						)}
					>
						<FAIcon icon={faPlus} />
					</Button>
				)
			}
			{...rest}
		/>
	);
};

InputQty.displayName = 'InputQty';
InputQty.whyDidYouRender = true;
InputQty.defaultProps = defaultProps;

export default InputQty;
