import styled, { css } from 'styled-components';

type ContainerProps = {
	active: boolean;
};

export const Container = styled.div<ContainerProps>`
	${({ active }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 100%;
		min-width: 120px;
		max-width: 180px;
		padding: 0.5rem;
		border-top-left-radius: var(--border-radius);
		border-top-right-radius: var(--border-radius);
		background-color: #f4f5f6;
		border: 1px solid var(--gray-20);
		border-bottom: 1px solid var(--gray-20);
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			background-color: var(--gray-20);
		}

		${active &&
		css`
			background-color: var(--white);
			border-bottom-color: var(--white);
			cursor: inherit;

			&:hover {
				background-color: var(--white);
			}
		`}
	`}
`;

export const UserCode = styled.div`
	font-size: 12px;
	color: var(--primary);
	font-weight: 600;
	line-height: 1;
`;

export const UserName = styled.div`
	font-size: 11px;
	width: 100%;
	text-align: center;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const UserScore = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const UserScoreNumber = styled.div`
	font-size: 13px;
	font-weight: 600;
	padding: 2px 2px 0;
`;

export const UserScoreBar = styled.div`
	align-self: flex-end;
	width: 100%;
	height: 8px;
	border-radius: var(--border-radius);
	border: 1px solid var(--gray-20);
	background-color: var(--white);

	div {
		height: 100%;
		border-radius: var(--border-radius);
		background-color: var(--success);
	}
`;
