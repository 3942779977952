import { faEdit, faFileSearch } from '@fortawesome/pro-light-svg-icons';

import ButtonLink, { ButtonLinkFormatterConfig } from './ButtonLink';

export type EditLinkFormatterConfig<T, P, R> = ButtonLinkFormatterConfig<
	T,
	P,
	R
> & {
	editable?: boolean;
};

class EditLinkFormatter<T, P, R> extends ButtonLink<T, P, R> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(config: EditLinkFormatterConfig<T, P, R>) {
		super(config);
		this.setEditable(config.editable !== false);
	}

	// Métodos para alterar permissões em runtime
	setEditable = (editable = true) => {
		const config = this.config as EditLinkFormatterConfig<T, P, R>;
		if (!config.icon) {
			config.icon = editable ? faEdit : faFileSearch;
		}
	};
}

export default EditLinkFormatter;
