import {
	HttpGetListParams,
	HttpGetListResult,
	FollowUp,
	ID,
	HttpPostResultAny,
} from 'types';

import Crud from './crud.service';

const getAll = (
	params: HttpGetListParams
): Promise<HttpGetListResult<FollowUp>> =>
	Crud.getList<FollowUp>('followup', params);

const getByPersonId = (
	id: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<FollowUp>> =>
	Crud.getList<FollowUp>(`followup/byPerson/${id}`, params);

const getByOrderId = (
	id: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<FollowUp>> =>
	Crud.getList<FollowUp>(`followup/byOrder/${id}`, params);
const patch = (
	id: ID | null,
	data: { isActive: true; isClosed: true }
): Promise<HttpPostResultAny> => {
	return Crud.patch('followup/{id}', id as number, data);
};

const createOrUpdate = (
	id: ID | null,
	data: FollowUp
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('followup/{id}', id as number, data);
	}
	return Crud.create('followup', data);
};

export default {
	getAll,
	getByPersonId,
	getByOrderId,
	patch,
	createOrUpdate,
};
