import styled from 'styled-components';

import classNames from 'classnames';

const BackdropFade = styled.div.attrs(
	(props: { show: boolean; className?: string }) => ({
		show: props.show,
		className: classNames(props.className, props.show && 'show'),
	})
)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--backdrop-bg);
	z-index: calc(var(--dropdown-zindex) - 10);
	opacity: 0;
	visibility: hidden;
	transition: var(--base-transition-all);

	&.show {
		opacity: var(--backdrop-opacity);
		visibility: visible;
	}
`;

export default BackdropFade;
