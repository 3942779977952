import React from 'react';

import Container from 'react-bootstrap/Container';

import { PageHeader } from 'packages/admin/components';

import { LinkContainer, Link, ImageBeAPa } from './beAPa/styles';

const BeAPa: React.FC = () => {
	return (
		<Container fluid>
			<PageHeader alignItems="center">
				<PageHeader.Title>Ser PA</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils/distributors' },
						{ label: 'Ser PA', active: true },
					]}
				/>
			</PageHeader>

			<LinkContainer>
				<Link
					href="https://pontodeapoio.amakhaparis.com.br/"
					target="_blank"
					rel="noreferrer"
				>
					<ImageBeAPa
						src="/assets/images/marketing/seja_um_pa.jpg"
						className="img-fluid"
					/>
				</Link>
			</LinkContainer>
		</Container>
	);
};

export default BeAPa;
