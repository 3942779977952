import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #c5dbf1;
`;

export const StyledTitle = styled.h2`
	padding: 0.6rem;
	margin: 0;
	font-size: 1.3rem;
	font-weight: 600 !important;
`;

export const SidebarCollapse = styled(Col)`
	min-height: calc(100vh - 1rem - var(--navbar-height));
	display: flex;
	flex-direction: column;
`;

export const TextEllipsis = styled.span`
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&.capitalize {
		text-transform: capitalize;
	}
`;
