import { SessionState } from 'store/ducks/session';
import { PersonStatus } from 'types';

import { RouteValidator, ValidationFn } from './types';

const personStatusValidation: RouteValidator = (
	personStatus: PersonStatus
): ValidationFn => {
	return (session: SessionState) => session.user?.idStatus === personStatus;
};

export default personStatusValidation;
