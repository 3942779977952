import React, { ReactFragment } from 'react';

import DefaultFormatter from './Default';
import { FormatterType, FormatterParamRowType } from './IFormatter';

export type CustomComponentProps<T> = React.HTMLProps<HTMLButtonElement> & {
	row: T;
};

type ComponentConfig<T> = {
	className?: string;
	component: React.FC<CustomComponentProps<T>>;
	componentProps?: CustomComponentProps<T>;
};

type ValueConfig<T> = {
	className?: string;
	value: string | ((row: T) => string);
};

export type CustomFormatterConfig<T> = ComponentConfig<T> | ValueConfig<T>;

// Teste de tipo personalizado
const isComponentConfig = <T,>(
	options: CustomFormatterConfig<T>
): options is ComponentConfig<T> =>
	typeof (options as ComponentConfig<T>).component !== 'undefined';

const CustomComponent = <T,>(
	props: CustomFormatterConfig<T> & {
		row: T;
		field: string;
	}
): JSX.Element => {
	// Quando o tipo é ComponentConfig<T>
	if (isComponentConfig(props)) {
		const { row, component: Component, componentProps } = props;
		return (
			<Component
				{...((componentProps || {}) as CustomComponentProps<T>)}
				row={row}
			/>
		);
	}

	// Quando o tipo é ValueConfig<T>
	const { row, value } = props;
	if (typeof value === 'function') {
		return <>{value(row)}</>;
	}
	return <>{value}</>;
};

class CustomFormatter<T> extends DefaultFormatter implements FormatterType {
	protected isEnabled = true;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(protected config: CustomFormatterConfig<T>) {
		super();
	}

	setEnabled = (isEnabled = true) => {
		this.isEnabled = isEnabled;
	};

	format = (
		field: string,
		row?: FormatterParamRowType
	): string | ReactFragment => (
		<CustomComponent {...this.config} row={row} field={field} />
	);
}

export default CustomFormatter;
