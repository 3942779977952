import useShoppingCart from './useShoppingCart';

const useShoppingCartTotalItems = () => {
	const cart = useShoppingCart();
	let total = 0;

	for (const item of cart.items) {
		total += item.quantity;
	}
	return total;
};

export default useShoppingCartTotalItems;
