import React from 'react';

import accountService from 'services/account.service';
import payoutService from 'services/payout.service';
import {
	editLinkFormatter,
	coloredStatusFormatter,
	numberFormatter,
	currencyFormatter,
	dateHourMinFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import paginateConfig from 'packages/admin/pages/list/routes/paginate';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';
import { accountType } from 'packages/escritorio/utils/coloredStatus';

import PayoutFilters from './financial/PayoutFilters';

const appBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Financeiro',
		path: 'app/financial',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	FinancialAccounts: {
		title: 'Minhas Contas',
		breadcrumbItems: [
			...appBreadcrumb,
			{ label: 'Minhas Contas', active: true },
		],
		newItemLink: 'account/create',
		action: accountService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idAccount',
					title: '',
					scope: 'row',
					className: 'text-center',
					formatter: editLinkFormatter({
						link: 'account/{id}',
					}),
					searchable: false,
				},
				{
					key: 'identifier',
					title: 'Identificação',
					searchable: true,
				},
				{
					key: 'holder',
					title: 'Titular',
					searchable: true,
				},
				{
					key: 'agency',
					title: 'Agência',
					searchable: true,
				},
				{
					key: 'number',
					title: 'Conta',
					searchable: true,
				},
				{
					key: 'isCC',
					title: 'Tipo de conta',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: accountType,
					}),
				},
			],
		},
	},
	FinancialPayouts: {
		title: 'Saques',
		breadcrumbItems: [...appBreadcrumb, { label: 'Saques', active: true }],
		datatableFilters: <PayoutFilters />,
		action: payoutService.getForList,
		datatableConfig: {
			sortByKey: 'idPayment',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPayment',
					title: 'Código',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'value',
					title: 'Valor',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'accountIdentifier',
					title: 'Conta',
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'requestDate',
					title: 'Data do Pedido',
					searchable: true,
					className: 'text-center',
					formatter: dateHourMinFormatter,
				},
				{
					key: 'payDate',
					title: 'Data de Pagamento',
					searchable: true,
					className: 'text-center',
					formatter: dateHourMinFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
				},
			],
		},
	},
};

export default ScenesConfig;
