import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import { Card, Skeleton as Skel } from 'components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props}>
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const SubscriptionSkeletonForm: React.FC = () => (
	<Card>
		<Card.Header separator justifyContent="space-between">
			<Skeleton width="13rem" height="2rem" />
			<Skeleton width="7rem" height="1.5rem" />
		</Card.Header>
		<Card.Content>
			<Row>
				<Col lg={8}>
					<Form.Row>
						<SkelGroup as={Col} lg={12} />
						<Form.Group as={Col} lg={12}>
							<Form.Label>
								<Label />
							</Form.Label>
							<Skeleton height="9rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<SkelGroup as={Col} md={6} lg={3} />
						<SkelGroup as={Col} md={6} lg={3} />
						<SkelGroup as={Col} md={6} lg={3} />
						<SkelGroup as={Col} md={6} lg={3} />
					</Form.Row>
				</Col>

				<Col lg={4}>
					<Form.Row>
						<Form.Group as={Col}>
							<Skeleton height="270px" width="100%" />
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>

			<hr />

			<Row>
				<Col lg={6}>
					<Form.Row>
						<Form.Group as={Col} lg={3}>
							<Skeleton height="1.5rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col}>
							<Skeleton height="1.5rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={6}>
							<Skeleton />
						</Form.Group>
					</Form.Row>
				</Col>
				<Col lg={6} className="border-left">
					<Form.Row>
						<Form.Group as={Col} lg={3}>
							<Skeleton height="1.5rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} lg={10}>
							<Skeleton height="1.5rem" />
						</Form.Group>
						<Form.Group as={Col} lg={2}>
							<Skeleton height="1.5rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} lg={12}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={12}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={12}>
							<Skeleton />
						</Form.Group>
						<Form.Group as={Col} lg={12}>
							<Skeleton />
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
		</Card.Content>

		<Card.Content className="border-top">
			<div className="text-right">
				<span className="mr-2">
					<Skel width="100px" height="2rem" />
				</span>
				<Skel width="100px" height="2rem" />
			</div>
		</Card.Content>
	</Card>
);

export default SubscriptionSkeletonForm;
