import React from 'react';

import Container from 'react-bootstrap/Container';

import Skeleton from 'components/Skeleton';

import { SimpleList } from 'packages/escritorio/components';

const CampaignSkeleton: React.FC = () => (
	<Container fluid>
		{[...Array(10).keys()].map((key) => (
			<SimpleList key={key}>
				<SimpleList.Header withSeparator>
					<SimpleList.Column>
						<SimpleList.Title>
							<Skeleton width="10ch" height="1.2rem" />
						</SimpleList.Title>
						<SimpleList.Info>
							<Skeleton width="20ch" height="1rem" />
						</SimpleList.Info>
					</SimpleList.Column>
					<SimpleList.Column>
						<Skeleton width="10ch" height="1.2rem" />
					</SimpleList.Column>
				</SimpleList.Header>
				<SimpleList.Item>
					<p>
						<Skeleton width="20ch" height="1rem" />
					</p>
				</SimpleList.Item>
			</SimpleList>
		))}
	</Container>
);

export default CampaignSkeleton;
