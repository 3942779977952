/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from 'react-beautiful-dnd';

import { faListUl, faEdit } from '@fortawesome/pro-light-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import Sort from 'utils/Sorters';

import Button from './Button';
import Empty from './Empty';
import { Container, DroppableContent, AddListItem } from './list/styles';
import Skeleton from './Skeleton';

export interface ListProps {
	data: any[] | string;
	filterListProp?: string;
	dragInDrop?: boolean;
	isDragDisabled?: boolean;
	hasEditButton?: boolean;
	hasRemoveButton?: boolean;
	loading?: boolean;
	emptyDescription?: string;
	emptyImage?: any;
	sortField?: string;
	onChangeItems?: (items: any) => void;
	onEditItem?: (item: any, index: number) => void;
	onRemoveItem?: (item: any, index: number) => void;
	canBeDraggedFilter?: (item: any) => boolean;
	children: (item: any, index: number) => React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

const ListSkeleton: React.FC = () => {
	return (
		<>
			{[...Array(3).keys()].map((key) => (
				<Skeleton key={key} height="2rem" className="mb-1" />
			))}
		</>
	);
};

const reorder = (list: any, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const List: React.FC<ListProps> = ({
	data,
	isDragDisabled = false,
	filterListProp = 'nodata',
	dragInDrop = false,
	hasRemoveButton = true,
	hasEditButton = false,
	loading = false,
	emptyDescription = 'Sem dados',
	emptyImage = faListUl,
	sortField = 'sort',
	onChangeItems,
	onEditItem,
	onRemoveItem,
	canBeDraggedFilter,
	children,
	...rest
}) => {
	const formikCtx = useFormikContext();
	const { value: values } =
		typeof data === 'string'
			? formikCtx.getFieldMeta<any[]>(data)
			: { value: data };

	const [items, setItems] = useState<any[]>([]);

	const sortOptions = useCallback(
		(optionsItems: any) => {
			if (!optionsItems) {
				return [];
			}
			const result = optionsItems.map((item: any, idx: number) => ({
				...item,
				[sortField]: idx + 1,
			}));

			return result;
		},
		[sortField]
	);

	useEffect(() => {
		const sortedValues = values.sort(Sort.Numerically(sortField));
		const optionsOrdered = dragInDrop
			? sortOptions(sortedValues)
			: sortedValues;
		setItems(optionsOrdered);
	}, [dragInDrop, sortField, sortOptions, values]);

	function onDragEnd(result: DropResult) {
		if (!result.destination) return;

		const itemsReordered = reorder(
			items,
			result.source.index,
			result.destination.index
		);
		const optionsOrdened = sortOptions(itemsReordered);

		setItems(optionsOrdened);
		if (onChangeItems) onChangeItems(optionsOrdened);
	}

	const itemsThatCanBeDragged = useMemo(
		() => (canBeDraggedFilter ? items.filter(canBeDraggedFilter) : items),
		[canBeDraggedFilter, items]
	);

	const handleRemoveItem = useCallback(
		(item: any, index: number) => {
			if (onRemoveItem) onRemoveItem(item, index);
		},
		[onRemoveItem]
	);

	const handleEditItem = useCallback(
		(item: any, index: number) => {
			if (onEditItem) onEditItem(item, index);
		},
		[onEditItem]
	);

	return dragInDrop ? (
		<DragDropContext onDragEnd={onDragEnd}>
			<Container {...rest}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<DroppableContent
							{...provided.droppableProps}
							ref={provided.innerRef}
							isDraggingOver={snapshot.isDraggingOver}
						>
							{items && loading ? (
								<ListSkeleton />
							) : (
								items.map(
									(item, index) =>
										!item[filterListProp] && (
											<Draggable
												// eslint-disable-next-line react/no-array-index-key
												key={index}
												draggableId={String(item[sortField])}
												index={index}
												isDragDisabled={
													canBeDraggedFilter &&
													!itemsThatCanBeDragged.includes(item)
												}
											>
												{(providedDraggable: any, snapshotDraggable: any) => (
													<AddListItem
														ref={providedDraggable?.innerRef}
														{...providedDraggable.draggableProps}
														{...providedDraggable.dragHandleProps}
														isDragging={snapshotDraggable.isDragging}
													>
														{!isDragDisabled &&
															(canBeDraggedFilter
																? itemsThatCanBeDragged.includes(item)
																: true) && (
																<span>
																	<FAIcon icon="grip-vertical" />
																</span>
															)}

														{children(item, index)}

														<div className="ml-auto d-flex">
															{hasEditButton && (
																<Button
																	variant="link"
																	className="text-info"
																	onClick={() => handleEditItem(item, index)}
																>
																	<FAIcon icon={faEdit} />
																</Button>
															)}
															{hasRemoveButton && (
																<Button
																	variant="link"
																	className="ml-1 text-danger"
																	onClick={() => handleRemoveItem(item, index)}
																>
																	<FAIcon icon={faTrashAlt} />
																</Button>
															)}
														</div>
													</AddListItem>
												)}
											</Draggable>
										)
								)
							)}

							{!items?.length && !loading && (
								<Empty
									description={emptyDescription}
									image={<FAIcon icon={emptyImage} />}
								/>
							)}
							{provided.placeholder}
						</DroppableContent>
					)}
				</Droppable>
			</Container>
		</DragDropContext>
	) : (
		<Container {...rest}>
			<div>
				{items && loading ? (
					<ListSkeleton />
				) : (
					items.map(
						(item, index) =>
							!item[filterListProp] && (
								// eslint-disable-next-line react/no-array-index-key
								<AddListItem key={index}>
									{children(item, index)}

									<div className="ml-auto d-flex">
										{hasEditButton && (
											<Button
												variant="outline-green"
												onClick={() => handleEditItem(item, index)}
											>
												<FAIcon icon={faEdit} />
											</Button>
										)}
										{hasRemoveButton && (
											<Button
												variant="link"
												className="ml-auto text-danger"
												onClick={() => handleRemoveItem(item, index)}
											>
												<FAIcon icon={faTrashAlt} />
											</Button>
										)}
									</div>
								</AddListItem>
							)
					)
				)}

				{!items?.length && !loading && (
					<Empty
						description={emptyDescription}
						image={<FAIcon icon={emptyImage} />}
					/>
				)}
			</div>
		</Container>
	);
};

List.displayName = 'List';
List.whyDidYouRender = true;

export default List;
