import styled from 'styled-components';

type GridType = {
	minWidth?: number;
};

const FullWidthContainer = styled.div`
	grid-column: 1/-1;

	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(
		auto-fit,
		minmax(${(props: GridType) => props.minWidth ?? 240}px, 1fr)
	);
	gap: 1.5rem;
	margin-bottom: 1.5rem;
`;

export default Object.assign(Grid, {
	FullWidthContainer,
});
