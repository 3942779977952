import styled from 'styled-components';

import { lighten, rgba, math, cssVar } from 'polished';

interface SideBarStyledProps {
	isMobile: boolean;
	menuOpened: boolean;
	isSubmenuActive: boolean;
}

interface SideBarMobileMenuStyledProps {
	menuOpened: boolean;
}

export const SideBarStyled = styled.div.attrs((props: SideBarStyledProps) => ({
	isMobile: props.isMobile,
	menuOpened: props.menuOpened,
	isSubmenuActive: props.isSubmenuActive,
}))`
	position: fixed;
	top: var(--navbar-height);
	width: ${(props) =>
		props.isSubmenuActive
			? math(`190px + ${cssVar('--sidebar-width-collapse')}`)
			: cssVar('--sidebar-width-collapse')};
	z-index: 1;
	height: 100%;
	transition: width 0.2s;
	padding-bottom: 64px;
	background-color: var(--sidebar-bg);
	z-index: 999;
	display: ${(props) =>
		!props.isMobile || (props.isMobile && props.menuOpened) ? 'block' : 'none'};

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
`;

export const SideBarMenuStyled = styled.div`
	position: relative;
	width: var(--sidebar-width-collapse);
	background-color: var(--sidebar-bg);
	height: 100%;

	> ul {
		> li {
			&.active {
				> a {
					border-left: 3px solid var(--primary);
					color: var(--sidebar-menu-item-active);
					background-color: ${lighten(0.04, cssVar('--sidebar-bg'))};

					svg {
						color: var(--sidebar-menu-item-active);
						fill: ${rgba(255, 255, 255, 0.012)};
					}
				}
			}

			> a {
				display: block;
				font-size: 0.8rem;
				font-weight: 400;
				position: relative;
				transition: all 0.4s;
				border-left: 3px solid transparent;
				padding: 0.5rem 0;
				min-height: 54px;
				margin: 0px;
				text-align: center;
				color: var(--sidebar-menu-item);

				svg {
					display: inline-block;
					width: 22px !important;
					height: 22px !important;
					margin: 0;
					color: var(--sidebar-menu-item);
					fill: ${rgba(255, 255, 255, 0.012)};
				}

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					color: var(--sidebar-menu-item-hover);
					background-color: ${lighten(0.04, cssVar('--sidebar-bg'))};

					svg {
						color: var(--sidebar-menu-item-hover);
						fill: ${rgba(255, 255, 255, 0.012)};
					}
				}

				> span {
					display: block;
					padding: 0;
					font-size: 12px;
					letter-spacing: 0.2px;
				}
			}
		}

		hr {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			border-color: #484f5f;
		}
	}
`;

export const SideBarSubMenuStyled = styled.ul`
	left: var(--sidebar-width-collapse);
	position: absolute;
	width: 190px;
	height: 100%;
	box-shadow: var(--box-shadow-sm);
	top: 0;
	padding: 5px 0;
	background-color: #3b414e;
	display: none;
	z-index: 0;

	&.active-submenu {
		display: block;
	}

	li {
		&.active,
		&:active {
			> a {
				color: var(--sidebar-menu-item-active);
			}
		}

		> a {
			display: block;
			position: relative;
			transition: all 0.4s;
			box-shadow: none;
			padding: 8px 20px;
			width: 190px;
			z-index: 6;
			color: var(--sidebar-menu-item);

			span {
				padding-left: 10px;
			}

			&:hover,
			&:focus {
				background-color: transparent;
				color: var(--sidebar-menu-item-hover);
			}
		}
	}
`;

export const SideBarMobileMenuStyled = styled.div.attrs(
	(props: SideBarMobileMenuStyledProps) => ({
		menuOpened: props.menuOpened,
	})
)`
	bottom: 0;
	padding: 0;
	position: fixed;
	top: var(--navbar-height);
	transition-duration: 0.2s;
	z-index: 99;
	height: 100%;
	width: 100%;
	background-color: var(--sidebar-bg);
	overflow-x: auto;
	padding-bottom: 64px;
	display: ${(props) => (props.menuOpened ? 'block' : 'none')};

	> ul {
		> li {
			&.active {
				> a {
					border-left: 3px solid var(--primary);
					color: var(--sidebar-menu-item-active);
					background-color: ${lighten(0.04, cssVar('--sidebar-bg'))};

					svg {
						color: var(--sidebar-menu-item-active);
						fill: ${rgba(255, 255, 255, 0.012)};
					}

					> span.menu-arrow {
						transform: rotate(90deg);
						right: 30px;
					}
				}
			}

			> a {
				display: flex;
				align-items: center;
				padding: 12px 30px;
				font-size: 15px;
				font-weight: 400;
				position: relative;
				transition: all 0.4s;
				border-left: 3px solid transparent;
				color: var(--sidebar-menu-item);

				svg {
					display: inline-block;
					width: 16px;
					height: 16px;
					margin: 0 10px 0 3px;
					color: var(--sidebar-menu-item);
					fill: ${rgba(255, 255, 255, 0.012)};
				}

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					color: var(--sidebar-menu-item-hover);
					background-color: ${lighten(0.04, cssVar('--sidebar-bg'))};

					svg {
						color: var(--sidebar-menu-item-hover);
						fill: ${rgba(255, 255, 255, 0.012)};
					}
				}

				> span {
					vertical-align: middle;
				}
			}

			.menu-arrow {
				transition: transform 0.15s;
				position: absolute;
				right: 25px;
				display: inline-block;
				text-rendering: auto;
				line-height: 1.3rem;
				font-size: 0.9rem;
				transform: translate(0, 0);
				top: 15px;
			}
		}

		hr {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			border-color: #484f5f;
		}
	}
`;

export const SideBarMobileSubMenuStyled = styled.ul`
	padding-left: 43px !important;

	li {
		&.active {
			> a {
				color: var(--sidebar-menu-item-active);
			}
		}

		> a {
			padding: 8px 20px;
			display: block;
			position: relative;
			transition: all 0.4s;
			color: var(--sidebar-menu-item);

			span {
				padding-left: 10px;
				font-size: 14px;
			}

			&:hover,
			&:focus,
			&:active {
				background-color: transparent;
				color: var(--sidebar-menu-item-hover);
			}
		}
	}
`;

SideBarStyled.displayName = 'SideBarStyled';
(SideBarStyled.whyDidYouRender as boolean) = true;

SideBarMobileMenuStyled.displayName = 'SideBarMobileMenuStyled';
(SideBarMobileMenuStyled.whyDidYouRender as boolean) = true;

SideBarMobileSubMenuStyled.displayName = 'SideBarMobileSubMenuStyled';
(SideBarMobileSubMenuStyled.whyDidYouRender as boolean) = true;
