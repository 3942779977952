import { OrderSummary } from 'types';

export const latestOrdersMockData: OrderSummary[] = [
	{
		number: '5139349',
		date: '2021-05-03 14:30:00',
		id: '120183',
		name: 'JACQUELINE NUNES MARTINS PA SANTO ANDRÉ SP',
		type: 'Venda',
		shippingType: 'Adquirido na Loja',
		typePayment: 'Cartão',
		status: 'Entregue',
	},
	{
		number: '177506',
		date: '2021-05-03 14:30:00',
		id: '196999',
		name: 'Thaynan Lima PA Pinheiro MA',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Pagamento Confirmado',
	},
	{
		number: '177565',
		date: '2021-05-03 14:30:00',
		id: '810165',
		name: 'GABRIEL ROCHA RODRIGUES PA EXCLUSIVO BETIM MG',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'PagSeguro',
		status: 'Pagamento Confirmado',
	},
	{
		number: '177918',
		date: '2021-05-03 14:30:00',
		id: '810166',
		name: 'GABRIEL ROCHA RODRIGUES PA EXCLUSIVO BETIM MG',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Pagamento Confirmado',
	},
	{
		number: '4853803',
		date: '2021-05-03 14:30:00',
		id: '196998',
		name: 'Thaynan Lima PA Pinheiro MA',
		type: 'Venda',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Nota Fiscal Emitida',
	},
	{
		number: '178727',
		date: '2021-05-03 14:30:00',
		id: '196054',
		name: 'Thaynan Lima PA Pinheiro MA',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Pagamento Confirmado',
	},
	{
		number: '4872554',
		date: '2021-05-03 14:30:00',
		id: '903464',
		name: 'BEATRIZ OLIVEIRA CARVALHO PA EXCLUSIVO MOGI DAS CRUZES SP',
		type: 'Venda',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Nota Fiscal Emitida',
	},
	{
		number: '4881522',
		date: '2021-05-03 14:30:00',
		id: '903465',
		name: 'BEATRIZ OLIVEIRA CARVALHO PA EXCLUSIVO MOGI DAS CRUZES SP',
		type: 'Venda',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Nota Fiscal Emitida',
	},
	{
		number: '179468',
		date: '2021-05-03 14:30:00',
		id: '903466',
		name: 'BEATRIZ OLIVEIRA CARVALHO PA EXCLUSIVO MOGI DAS CRUZES SP',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Pagamento Confirmado',
	},
	{
		number: '179836',
		date: '2021-05-03 14:30:00',
		id: '903467',
		name: 'BEATRIZ OLIVEIRA CARVALHO PA EXCLUSIVO MOGI DAS CRUZES SP',
		type: 'Crédito de Marketplace',
		shippingType: 'Retirar no Local',
		typePayment: 'Saldo',
		status: 'Pagamento Confirmado',
	},
];
