const UrlSlugToID = (slug?: string): number => {
	if (slug) {
		const matches = slug.match(/^\/?([0-9]+)($|-)/);
		if (matches && matches.length > 1) {
			return Number(matches[1]);
		}
	}
	return 0;
};

export default UrlSlugToID;
