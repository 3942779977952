import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { ProductPrice } from '../../Typography';
import { Title, OldPrice } from '../styles';

const ImgContainer = styled.div`
	position: relative;
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	background: #eee;
`;

const ProductCardSkeleton: React.FC = () => (
	<Card className="border">
		<ImgContainer />
		<Card.Body>
			<Title>
				<Skeleton height="100%" width="100%" />
			</Title>
			<OldPrice>
				<Skeleton height="100%" width="5rem" />
			</OldPrice>
			<ProductPrice>
				<Skeleton height="100%" width="8rem" />
			</ProductPrice>

			<Form.Row className="mt-2">
				<Col>
					<Skeleton height="1.5rem" />
				</Col>
				<Col className="col-auto">
					<Skeleton height="1.5rem" />
				</Col>
			</Form.Row>
		</Card.Body>
	</Card>
);

export default ProductCardSkeleton;
