/* eslint-disable no-bitwise */
import {
	assign,
	MachineConfig,
	MachineOptions,
	Machine,
	Actor,
	sendParent,
	Interpreter,
	State,
} from 'xstate';

import myService from 'services/my.service';
import { ID, PersonAddress, HttpBaseResult } from 'types';

// Sem endereços
//  - Cadastro de endereço
//    - botoes visiveis: confirmar
//    - apos confirmar, endereço novo vem selecionado
// Com endereços
//  - Endereço padrão vem selecionado
//    - botões visiveis
//      - editar endereço
//        - substituir endereço selecionado por cadastro de endereço
//          - botoes visiveis: cancelar, confirmar
//          - apos confirmar, endereço novo vem selecionado
//      - remover endereço
//        - confirmação e exclusão
//      - novo endereço
//        - igual sem endereço
//      - selecionar outro endereço

export type AddressSchema = {
	states: {
		loading: {
			states: {
				fetching: {};
				failed: {};
			};
		};
		browsing: {
			states: {
				selecting: {};
				selected: {};
			};
		};
		editing: {
			states: {
				editing: {};
				saving: {};
				deleting: {};
			};
		};
	};
};

export enum AddressSchemaFlag {
	Loading = 0x01,
	Browsing = 0x02,
	Editing = 0x04,
	All = 0x07,
}

export type AddressContext = {
	addresses: PersonAddress[];
	selected?: PersonAddress;
	edit?: PersonAddress;
	error?: HttpBaseResult;
};

// Eventos internos do statecharts de seleção de endereço
export type AddressEvent =
	| { type: 'LOADING_RETRY' }
	| { type: 'SET_ADDRESS_LIST'; addresses: PersonAddress[] }
	| { type: 'NEW_ADDRESS' }
	| { type: 'SELECTED_EDIT' }
	| { type: 'SELECTED_DELETE' }
	| { type: 'BROWSE_ADDRESSES' }
	| { type: 'BROWSE_SELECT'; address: PersonAddress }
	| { type: 'BROWSE_CANCEL' }
	| { type: 'EDITING_SAVE'; address: PersonAddress }
	| { type: 'EDITING_CANCEL' };

// Eventos disparados para a máquina de estados pai
export type AddressParentEvent =
	| { type: 'ADDRESS_CHANGE'; address: PersonAddress; id: string }
	| { type: 'ADDRESSES_LOADED'; addresses: PersonAddress[]; id: string };

export type AddressEventWithItem = {
	type: string;
	address: PersonAddress;
};

export const AddressMachineConfig = (
	id: string,
	flags: number = AddressSchemaFlag.All
): MachineConfig<AddressContext, AddressSchema, AddressEvent> => ({
	id,
	initial: flags & AddressSchemaFlag.Loading ? 'loading' : 'browsing',
	context: {
		addresses: [],
		selected: undefined,
	},
	states: {
		loading: {
			initial: 'fetching',
			states: {
				fetching: {
					invoke: {
						src: 'getAddressesEffect',
						onDone: {
							target: `#${id}.browsing`,
							actions: [
								assign({ addresses: (_, event) => event.data }),
								'reloadSelectedAddress',
								sendParent((ctx) => ({
									type: 'ADDRESSES_LOADED',
									addresses: ctx.addresses,
									id,
								})),
							],
						},
						onError: {
							target: 'failed',
							actions: assign({ error: (_, event) => event.data }),
						},
					},
				},
				failed: {
					on: {
						LOADING_RETRY: 'fetching',
					},
				},
			},
		},
		browsing: {
			initial: flags & AddressSchemaFlag.Loading ? 'selected' : 'selecting',
			states: {
				selected: {
					always: [
						{
							cond: 'dontHaveAddress',
							target: `#${id}.editing`,
							actions: 'reset',
						},
					],
					onEntry: sendParent((ctx) => ({
						type: 'ADDRESS_CHANGE',
						address: ctx.selected,
						id,
					})),
					on: {
						BROWSE_ADDRESSES: `#${id}.browsing.selecting`,
						SELECTED_EDIT: {
							target: `#${id}.editing`,
							actions: 'copySelectedToEdit',
						},
						NEW_ADDRESS: { target: `#${id}.editing`, actions: 'clearEdit' },
						SET_ADDRESS_LIST: {
							target: `#${id}.browsing.selecting`,
							actions: assign((_, event) => ({
								addresses: event.addresses,
								selected: undefined,
							})),
						},
					},
				},
				selecting: {
					on: {
						SET_ADDRESS_LIST: {
							actions: assign((_, event) => ({
								addresses: event.addresses,
								selected: undefined,
							})),
						},
						BROWSE_SELECT: {
							target: 'selected',
							actions: 'selectAddress',
						},
						BROWSE_CANCEL: {
							target: 'selected',
						},
					},
				},
			},
		},
		editing: {
			initial: 'editing',
			states: {
				editing: {
					on: {
						EDITING_SAVE: {
							target: 'saving',
							actions: assign({
								edit: (_, event) => event.address,
							}),
						},
						EDITING_CANCEL: {
							cond: 'haveAddresses',
							target: `#${id}.browsing.selected`,
							actions: assign({
								edit: (_) => undefined,
							}),
						},
					},
				},
				saving: {
					invoke: {
						src: 'saveAddressEffect',
						onDone: {
							target: `#${id}.loading`,
							actions: assign({
								selected: (ctx, event) =>
									({
										...ctx.edit,
										idAddress:
											event.data.data?.idAddress ?? ctx.edit!.idAddress,
									} as PersonAddress),
							}),
						},
						onError: {
							target: `#${id}.editing`,
							actions: sendParent((_, event) => ({
								type: 'NOTIFY',
								data: {
									message: event.data.message,
									type: 'error',
								},
							})),
						},
					},
				},
				deleting: {
					invoke: {
						src: 'deleteAddressEffect',
						onDone: {
							target: `#${id}.loading`,
						},
						onError: {
							actions: [
								assign({
									error: (_, error) => error.data,
								}),
								'showEditErrorToast',
							],
						},
					},
				},
			},
		},
	},
});

export const AddressMachineOptions = (
	idPerson: ID
): MachineOptions<AddressContext, AddressEvent> => ({
	guards: {
		haveAddresses: (ctx) => !!ctx.addresses,
		dontHaveAddress: (ctx) => !ctx.addresses,
	},
	actions: {
		reset: (ctx) => ({
			...ctx,
			selected: undefined,
			error: undefined,
			edit: undefined,
		}),
		reloadSelectedAddress: assign({
			selected: (ctx) => {
				if (ctx.selected?.idAddress) {
					// idAddress muda a cada update, portanto a única forma de achar o mesmo endereço com id atualizado
					// é comparando por nome e cep:
					return (
						ctx.addresses.find(
							(address) =>
								address.cep === ctx.selected?.cep &&
								address.name === ctx.selected?.name
						) || ctx.addresses[0]
					);
				}
				return (
					ctx.addresses.find((address) => address.isBilling) || ctx.addresses[0]
				);
			},
		}),
		selectAddress: assign({
			selected: (_, event) => (event as AddressEventWithItem).address,
		}),
		copySelectedToEdit: assign({
			edit: (ctx) => ctx.selected,
		}),
		clearEdit: assign({
			edit: (_) => undefined,
		}),
		showEditErrorToast: () => {},
	},
	services: {
		getAddressesEffect: () => myService.getAddresses(idPerson),
		saveAddressEffect: (ctx) => {
			if (!ctx.edit) {
				return Promise.reject();
			}
			return myService.createOrUpdateAddress(ctx.edit.idAddress, ctx.edit);
		},
	},
	activities: {},
	delays: {},
});

export type AddressActor = Actor<AddressContext>;

export type AddressState = State<AddressContext, AddressEvent, AddressSchema>;

export type AddressInterpreter = Interpreter<
	AddressContext,
	AddressSchema,
	AddressEvent
>;

export default (id: string, flags: number, idPerson: ID) =>
	Machine(AddressMachineConfig(id, flags), AddressMachineOptions(idPerson));
