import React, { useCallback, ChangeEvent, useState } from 'react';

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useThrottle from 'hooks/useThrottle';

import { ColumnConfig } from '../types';
import useDatagridContext from '../useDatagridContext';

const COLUMN_SEARCH_DELAY = 1000;

type ColumnSearchProps = Pick<ColumnConfig, 'searchFormatter'> & {
	colKey: string;
	searchable?: boolean;
};

const SearchInputGroup = styled(InputGroup)`
	position: relative;
	left: 1px;
	width: calc(100% - 2px);
	top: 1px;
`;

const ColumnSearchable: React.FC<ColumnSearchProps> = ({
	colKey,
	searchFormatter,
}) => {
	const [inputValue, setInputValue] = useState<string>('');
	const { onSearchChange } = useDatagridContext();

	// Aguarda 1s antes de chamar o dispatch()
	const delayDispatch = useThrottle(
		useCallback(
			(searchKey: string, value: string) => {
				onSearchChange(
					searchKey,
					searchFormatter ? searchFormatter(value) : value
				);
			},
			[onSearchChange, searchFormatter]
		),
		COLUMN_SEARCH_DELAY
	);

	return (
		<td className="position-relative p-0">
			<SearchInputGroup>
				<InputGroup.Prepend>
					<InputGroup.Text className="bg-white">
						<FontAwesomeIcon icon={faSearch} />
					</InputGroup.Text>
				</InputGroup.Prepend>
				<FormControl
					type="text"
					value={inputValue}
					className="border-left-0 h-100"
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						const newValue = event.currentTarget.value;
						setInputValue(newValue);
						delayDispatch(colKey, newValue);
					}}
				/>
			</SearchInputGroup>
		</td>
	);
};

const ColumnSearch: React.FC<ColumnSearchProps> = ({
	colKey,
	searchable,
	searchFormatter,
}) => (
	<>
		{searchable !== false ? (
			<ColumnSearchable colKey={colKey} searchFormatter={searchFormatter} />
		) : (
			<td />
		)}
	</>
);

ColumnSearch.displayName = 'ColumnSearch';
ColumnSearch.whyDidYouRender = true;

export default ColumnSearch;
