import {
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	ID,
} from 'types';
import { Commission, CommissionList } from 'types/commission.types';

import Crud from './crud.service';

/**
 * Service de requisição de dados de Comissão do MMN
 */
const getList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CommissionList>> =>
	Crud.getList<CommissionList>('mmn/commission', params);

const getById = (id: ID): Promise<Commission> =>
	Crud.getOne<Commission>('mmn/commission/{id}', id as number);

const createOrUpdate = (
	id: ID | null,
	data: Commission
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('mmn/commission/{id}', id as number, data);
	}
	return Crud.create('mmn/commission', data);
};

export default {
	getList,
	getById,
	createOrUpdate,
};
