import React from 'react';

import Skeleton from 'components/Skeleton';

import { Grid } from './styles';
import { gridDataObjProcessor } from './utils';

type SkeletonGridProps = {
	gridLayout: number[];
};

const SkeletonFeatureGrid: React.FC<SkeletonGridProps> = ({ gridLayout }) => {
	const data = gridDataObjProcessor([], gridLayout);

	return (
		<Grid columns={data.length}>
			{data.map(({ columnKey, gridItems }) => (
				<Grid.Group
					key={columnKey}
					columns={Math.ceil(Number(gridItems.length) / 2)}
				>
					{[...Array(gridItems.length).keys()].map((key) => (
						<Skeleton key={key} width="100%" padding="0 0 100% 0" />
					))}
				</Grid.Group>
			))}
		</Grid>
	);
};

export default SkeletonFeatureGrid;
