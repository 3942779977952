import React from 'react';

import { Col, Row } from 'react-bootstrap';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill, faUser, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Card } from 'components';
import { dateFormatter, numberFormatter } from 'utils/formatters';

import { latestOrdersMockData } from './latestOrders/mockData';
import { StyledCard } from './latestOrders/styles';
import { StyledHeader, StyledTitle, TextEllipsis } from './styles';

type LatestOrdersType = {
	className: string;
};

const LatestOrders: React.FC<LatestOrdersType> = ({ className }) => {
	return (
		<StyledCard className={className}>
			<StyledHeader>
				<StyledTitle>Últimos Pedidos</StyledTitle>
			</StyledHeader>
			<Card.Content>
				{latestOrdersMockData?.map((order) => (
					<Card key={order.id}>
						<Card.Content>
							<Row className="px-1 justify-content-between">
								<div className="font-weight-bold">
									#{numberFormatter.format(order.number)}
								</div>
								<div>
									<FAIcon icon={faCalendar} />{' '}
									{order.date ? dateFormatter.format(order.date) : ' '}
								</div>
							</Row>
							<Row className="border-top">
								<Col xs={5}>
									<Row className="mt-1">
										<Col className="d-flex align-items-center">
											<FAIcon icon={faUser} />{' '}
											<TextEllipsis
												title={order.name}
												className="ml-1 capitalize"
											>
												{order.name.toLowerCase()}
											</TextEllipsis>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex align-items-center">
											<FAIcon icon={faMoneyBill} />{' '}
											<TextEllipsis title={order.typePayment} className="ml-1">
												{order.typePayment}
											</TextEllipsis>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex align-items-center">
											<FAIcon icon={faTruck} />{' '}
											<TextEllipsis title={order.shippingType} className="ml-1">
												{order.shippingType}
											</TextEllipsis>
										</Col>
									</Row>
								</Col>
								<Col
									xs={3}
									className="d-flex justify-content-center align-items-center text-center"
								>
									{order.type}
								</Col>
								<Col
									xs={4}
									className="d-flex justify-content-center align-items-center text-center"
								>
									{order.status}
								</Col>
							</Row>
						</Card.Content>
					</Card>
				))}
			</Card.Content>
		</StyledCard>
	);
};

export default LatestOrders;
