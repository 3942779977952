import React, { useState, useCallback } from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import useMediaQuery from '@restart/hooks/useMediaQuery';

import SideBar from 'components/SideBar';
import { useRouteValidator } from 'hooks';
import history from 'utils/history';

import { NavBar } from 'packages/escritorio/components';
import { ContentPage } from 'packages/escritorio/pages/mainRouter/styles';

import EscritorioRoutes from 'packages/escritorio/escritorio.routes';

const MainRouter: React.FC = () => {
	const [menuOpened, setMenuOpened] = useState<boolean>(false);
	const [isSubmenuActive, setIsSubmenuActive] = useState<boolean>(false);
	const isMobile = useMediaQuery('max-width: 769px');
	const [filteredRoutes, flatRoutes] = useRouteValidator(EscritorioRoutes);

	const openLeftMenu = useCallback(() => {
		setMenuOpened(!menuOpened);
	}, [menuOpened]);

	const handleActiveSubmenu = useCallback((active: boolean) => {
		setIsSubmenuActive(active);
	}, []);

	const handleMenuClose = useCallback(() => {
		setMenuOpened(false);
	}, []);

	return (
		<>
			<NavBar
				openLeftMenuCallBack={openLeftMenu}
				isMobile={isMobile}
				menuIsOpen={menuOpened}
			/>
			<SideBar
				items={filteredRoutes}
				isMobile={isMobile}
				menuOpened={menuOpened}
				isSubmenuActive={isSubmenuActive}
				onActiveSubmenu={handleActiveSubmenu}
				onMenuClose={handleMenuClose}
			/>

			<ContentPage
				isSubmenuActive={isSubmenuActive}
				isMobile={isMobile}
				className="content-page"
			>
				<Router history={history}>
					<Switch>
						{flatRoutes.map((params) => (
							<Route key={params.path} {...params} />
						))}
					</Switch>
				</Router>
			</ContentPage>
		</>
	);
};

MainRouter.displayName = 'MainRouter';
MainRouter.whyDidYouRender = true;

export default MainRouter;
