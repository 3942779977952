import React from 'react';

import {
	faCheckCircle,
	faCalculator,
	faCircle,
	faDollarSign,
	faFileInvoiceDollar,
	faList,
	faMoneyBill,
	faMoneyBillWave,
	faShare,
} from '@fortawesome/pro-light-svg-icons';

import { PersonPermissionSlug, RouteDef, PersonType } from 'types';
import permissionValidation from 'utils/RouteValidator/permissionValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import InsertCreditDebit from './InsertCreditDebit';
import StatementApproval from './StatementApproval';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const ExecutiveRoutes: RouteDef = {
	path: '/admin/financial',
	menu: {
		name: 'Financeiro',
		icon: faDollarSign,
	},
	children: [
		{
			path: '/statement/by-person',
			render: getListSceneRenderFn(listConfigs.StatementByExecutive),
			exact: true,
			menu: {
				name: 'Extrato x Executivo',
				icon: faList,
			},
		},
		{
			path: '/statement/by-order',
			render: getListSceneRenderFn(listConfigs.StatementByOrder),
			exact: true,
			menu: {
				name: 'Extrato x Pedido',
				icon: faList,
			},
		},
		{
			path: '/statement/club-credits',
			menu: {
				name: 'Extrato de Créditos FF',
				icon: faCircle,
			},
		},
		{
			path: '/statement/approval',
			validation: [personTypeValidation(PersonType.Admin)],
			component: StatementApproval,
			exact: true,
			menu: {
				name: 'Aprovação de Lançamentos',
				icon: faCheckCircle,
			},
		},
		{
			path: '/statement/manual',
			render: getListSceneRenderFn(listConfigs.StatementManual),
			exact: true,
			menu: {
				name: 'Lançamentos Manuais',
				icon: faShare,
			},
		},
		{
			path: '/transfer',
			validation: [permissionValidation(PersonPermissionSlug.InsertStatement)],
			component: InsertCreditDebit,
			exact: true,
			menu: {
				name: 'Inserir Crédito e Débito',
				icon: faMoneyBill,
			},
		},
		{
			path: '/payout-request',
			render: getListSceneRenderFn(listConfigs.PayoutRequest),
			exact: true,
			menu: {
				name: 'Solicitações de Saque',
				icon: faMoneyBillWave,
			},
		},
		{
			path: '/payout-alert',
			render: getListSceneRenderFn(listConfigs.PayoutAlert),
			exact: true,
			menu: {
				name: 'Alerta de Pagamentos',
				icon: faFileInvoiceDollar,
			},
		},
		{
			path: '/desk-list',
			render: getListSceneRenderFn(listConfigs.CashierCompany),
			exact: true,
			menu: {
				name: 'Fechamento de Caixa',
				icon: faCalculator,
			},
		},
		{
			path: '/pagseguro/transactions',
			menu: {
				name: 'Transações do PagSeguro',
				icon: faCircle,
			},
		},
	],
};

export default ExecutiveRoutes;
