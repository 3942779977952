/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useCallback } from 'react';

import { Table } from 'react-bootstrap';

import Decimal from 'decimal.js';

import { useQueryParams } from 'hooks';
import orderService from 'services/order.service';
import {
	HttpBaseResult,
	ApiCodes,
	OrderExpeditionType,
	OrderItemlistType,
} from 'types';
import { decimalFormatter, dateTimeFormatter } from 'utils/formatters';

import { Container, PageBreak } from './print-orders/styles';

type OrderGroupType = OrderExpeditionType & {
	weight: string;
	qtyCount: number;
	productCount: number;
	station: OrderItemlistType[][];
};

const PrintOrders: React.FC = () => {
	const [data, setData] = useState<OrderGroupType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const query = useQueryParams();
	const ids = query.get('orderIds') || '';

	const getOrderWeight = useCallback((list: OrderItemlistType[]) => {
		if (!list) {
			return 0;
		}
		let total = new Decimal(0);
		list.forEach((item) => {
			total = total.add(new Decimal(item.netWeight).mul(item.quantity));
		});
		return total.toDecimalPlaces(3).toNumber();
	}, []);

	const printOrderExpedition = useCallback(
		(dataExpedition: OrderExpeditionType) => {
			const picklist = dataExpedition.item as OrderItemlistType[];

			const productCount = picklist.length;
			const qtyCount = picklist.reduce(
				(acc: number, item: OrderItemlistType) => {
					return acc + item.quantity;
				},
				0
			);
			const weight = String(
				decimalFormatter(2).format(getOrderWeight(picklist))
			);

			const pickTmp = picklist.map((item) => Number(item.idStation));
			const stations: OrderItemlistType[][] = [];
			[...new Set(pickTmp)].forEach((item, index) => {
				const newStation = picklist.filter(
					(pick) => Number(pick.idStation) === item
				);
				if (newStation.length) {
					stations[index] = [...newStation];
				}
			});

			setData((old) => [
				...old,
				{
					...dataExpedition,
					productCount,
					qtyCount,
					weight,
					item: picklist,
					station: stations,
				},
			]);
		},
		[getOrderWeight]
	);

	const getOrderData = useCallback(async () => {
		const orderIds = ids ? ids.split(',') : [];

		if (Array.isArray(orderIds) && orderIds.length > 0) {
			try {
				const list = await Promise.all(
					orderIds.map((id) => orderService.getOrderExpedition(Number(id)))
				);

				list.forEach((item) => {
					const { data: exped } = item;
					printOrderExpedition({
						...exped,
						item: exped.item.sort((a, b) => Number(a.sort) - Number(b.sort)),
					});
				});

				parent.postMessage({ action: 'print-orders-loaded' }, '*');
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				parent.postMessage(
					{
						action: 'print-orders-error',
						message: ApiCodes.getCodeString(code),
					},
					'*'
				);
			} finally {
				setIsLoading(false);
			}
		}
	}, [ids, printOrderExpedition]);

	useEffect(() => {
		if (ids) {
			getOrderData();
		}
	}, [getOrderData, ids]);

	return (
		<Container>
			{isLoading ? (
				<section>
					<h3 className="p-3">Carregando dados...</h3>
				</section>
			) : (
				data.length > 0 &&
				data.map((order, idx) => (
					<React.Fragment key={order.idOrder}>
						{idx !== 0 && <PageBreak />}

						<section>
							<h3 className="border-bottom p-3 d-flex justify-content-between">
								<span>
									Pedido: <strong>#{order.number}</strong>
								</span>
								<span className="text-muted">
									{order.date === '0000-00-00 00:00:00'
										? 'Sem data'
										: dateTimeFormatter.format(order.date)}
								</span>
							</h3>

							<h5 className="p-3" style={{ lineHeight: '1.8rem' }}>
								ID: <strong>{order.person.number}</strong> - {order.person.name}
								<br />
								Data do Pagamento:{' '}
								<strong>{dateTimeFormatter.format(order.payDate)}</strong>
								<br />
								Tipo de Envio:{' '}
								<span className="text-muted">
									<strong>{order.shipment.name}</strong>
								</span>
								{order.observation && (
									<>
										<br />
										Observação:{' '}
										<span className="text-muted">{order.observation}</span>
									</>
								)}
								{order.sendObservation && (
									<>
										<br />
										Observação de envio:{' '}
										<span className="text-muted">{order.sendObservation}</span>
									</>
								)}
							</h5>

							<Table className="mb-0" borderless>
								<tbody>
									<tr>
										<td className="text-center" style={{ width: '20%' }}>
											Peso Líquido
										</td>
										<td colSpan={2}>{order.weight} Kg</td>
									</tr>
								</tbody>
							</Table>

							<Table borderless>
								<thead style={{ borderBottom: '2px solid #dddddd' }}>
									<tr>
										<th
											className="text-center border-top-0"
											style={{ width: '20%' }}
										>
											Qtde ({order.qtyCount})
										</th>
										<th colSpan={2} className="border-top-0">
											Produto ({order.productCount})
										</th>
									</tr>
								</thead>
								<tbody style={{ borderBottom: '2px solid #dddddd' }}>
									{order.station.length > 0 &&
										order.station.map((picklist: OrderItemlistType[]) =>
											picklist.map((pick: OrderItemlistType, index: number) => (
												<tr
													key={pick.sort}
													style={
														picklist.length === index + 1
															? { borderBottom: '2px solid #dddddd' }
															: { borderBottom: '1px dashed #dddddd' }
													}
												>
													<td className="text-center">
														{pick.isBonus ? (
															<strong>{pick.quantity}</strong>
														) : (
															<>{pick.quantity}</>
														)}
													</td>
													<td>
														{pick.isBonus ? (
															<>
																<strong>{pick.product}</strong>
															</>
														) : (
															<>{pick.product}</>
														)}
													</td>
													<td className="text-center">
														<strong>{pick.code}</strong>
													</td>
												</tr>
											))
										)}
								</tbody>
							</Table>
						</section>
					</React.Fragment>
				))
			)}
		</Container>
	);
};

export default PrintOrders;
