import React from 'react';

import { useField } from 'formik';
import { Form } from 'react-bootstrap';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { Container } from '../textAreaEditor/styles';

export interface TextAreaEditorProps {
	name: string;
	placeholder?: string;
	defaultValue?: React.ReactNode;
	disabled?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

const TextAreaEditor: React.FC<TextAreaEditorProps> = (props) => {
	const { name, defaultValue, ...rest } = props;

	const [
		,
		{ initialValue, error, touched },
		{ setValue, setTouched },
	] = useField(name);

	return (
		<Container hasError={!!error && touched}>
			<SunEditor
				name={name}
				height="350px"
				setContents={initialValue || defaultValue}
				setOptions={{
					imageFileInput: false,
					showPathLabel: false,
					resizingBar: false,
					formats: ['p', 'blockquote', 'h1', 'h2', 'h3'],
					buttonList: [
						[
							'undo',
							'redo',
							'formatBlock',
							'blockquote',
							'bold',
							'underline',
							'italic',
							'strike',
							'subscript',
							'superscript',
							'outdent',
							'indent',
							'align',
							'removeFormat',
							'horizontalRule',
							'list',
							'lineHeight',
							'table',
							'link',
							'image',
							'video',
							'codeView',
						],
					],
				}}
				lang="pt_br"
				onBlur={(_, newValue) => {
					setTouched(true);
					setValue(newValue === '<p><br></p>' ? '' : newValue);
				}}
				onChange={(newValue) => {
					setTouched(true);
					setValue(newValue === '<p><br></p>' ? '' : newValue);
				}}
				{...rest}
			/>
			{!!error && touched && (
				<Form.Control.Feedback type="invalid" className="d-block">
					{error}
				</Form.Control.Feedback>
			)}
		</Container>
	);
};

TextAreaEditor.displayName = 'TextAreaEditor';
TextAreaEditor.whyDidYouRender = true;

export default TextAreaEditor;
