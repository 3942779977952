import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import {
	faHome,
	faExclamationTriangle,
	faMoneyCheckAlt,
} from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faSync, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { useActor } from '@xstate/react';

import { Empty } from 'components';
import { PersonAddress } from 'types';

import { Paragraph } from 'packages/ecommerce/components';

import AddressBrowse from './addressSelect/AddressBrowse';
import AddressEdit from './addressSelect/AddressEdit';
import { AddressActor, AddressState } from './addressSelect/AddressMachine';
import AddressSummary from './addressSelect/AddressSummary';

type AddressSelectProps = {
	title: 'Entrega' | 'Cobrança';
	machineRef: AddressActor;
};

const AddressSelect: React.FC<AddressSelectProps> = ({
	children,
	title,
	machineRef,
}) => {
	const [context, send] = useActor(machineRef);
	const current = (context as unknown) as AddressState;

	const haveEditOptions = title === 'Entrega';
	const isShipmentAddress = title === 'Entrega';
	const isEditting = current.matches('editing');
	const isSelected = current.matches('browsing.selected');
	const isBrowsing = current.matches('browsing.selecting');
	const isLoading =
		current.matches('loading') ||
		current.matches('editing.saving') ||
		current.matches('editing.deleting');
	const isNetworkError = current.matches('loading.failed');
	const toolbarAddressChange = () =>
		send({ type: 'BROWSE_ADDRESSES' } as never);
	const toolbarAddressCreate = () => send({ type: 'NEW_ADDRESS' } as never);
	const browseSelect = (address: PersonAddress) =>
		send({ type: 'BROWSE_SELECT', address } as never);
	const browseCancel = () => send({ type: 'BROWSE_CANCEL' } as never);
	const selectedAddressEdit = () => send({ type: 'SELECTED_EDIT' } as never);
	const selectedAddressRemove = () =>
		send({ type: 'SELECTED_DELETE' } as never);
	const editSave = (address: PersonAddress) => {
		send({
			type: 'EDITING_SAVE',
			address: {
				...address,
				idCity: address?.city?.idCity,
			} as PersonAddress,
		} as never);
	};
	const editCancel = () => send({ type: 'EDITING_CANCEL' } as never);

	return (
		<Card className="mb-2">
			<Card.Body className="pt-0 border">
				<Paragraph className="py-2 border-bottom d-flex flex-row align-items-center">
					<span className="mr-auto">
						<FAIcon
							icon={isShipmentAddress ? faHome : faMoneyCheckAlt}
							className="mr-2"
						/>
						{isBrowsing && `Selecione um Endereço de ${title}`}
						{!isBrowsing && `Endereço de ${title}`}
					</span>
					{isSelected && current.context.addresses.length > 1 && (
						<Button
							size="sm"
							variant="outline-info"
							className={haveEditOptions && isSelected ? 'mr-2' : ''}
							onClick={toolbarAddressChange}
						>
							<FAIcon icon={faSync} className="mr-2" /> Alterar
						</Button>
					)}
					{haveEditOptions && isSelected && (
						<Button
							size="sm"
							variant="outline-success"
							onClick={toolbarAddressCreate}
						>
							<FAIcon icon={faPen} className="mr-2" /> Novo
						</Button>
					)}
					{haveEditOptions && isBrowsing && current.context.selected && (
						<Button size="sm" variant="outline-danger" onClick={browseCancel}>
							<FAIcon icon={faTimes} className="mr-2" /> Cancelar
						</Button>
					)}
				</Paragraph>
				{isBrowsing && (
					<AddressBrowse
						onSelect={browseSelect}
						addresses={current.context.addresses}
					/>
				)}
				{isEditting && (
					<AddressEdit
						onSubmit={editSave}
						onCancel={editCancel}
						address={current.context.edit}
						isLoading={isLoading}
					/>
				)}
				{!isEditting && !isBrowsing && (
					<>
						<AddressSummary
							address={current.context.selected}
							isLoading={isLoading}
							showDelete={
								isShipmentAddress && current.context.addresses.length > 1
							}
							showEdit={isShipmentAddress}
							onAddressDelete={selectedAddressRemove}
							onAddressEdit={selectedAddressEdit}
						/>
						{children && <div className="mb-3" />}
						{children}
					</>
				)}
				{isNetworkError && (
					<Button
						variant="link"
						block
						onClick={() => send({ type: 'LOADING_RETRY' } as never)}
					>
						<Empty
							description="Não foi possível carregar endereços, clique aqui para tentar novamente"
							image={
								<FAIcon icon={faExclamationTriangle} className="text-danger" />
							}
						/>
					</Button>
				)}
			</Card.Body>
		</Card>
	);
};

export default AddressSelect;
