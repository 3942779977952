import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Skeleton from 'react-loading-skeleton';

const NavbarCatalogSkeleton: React.FC = () => (
	<Navbar className="py-0 border-bottom" variant="light" bg="white">
		<Container fluid>
			<Col>
				<Nav>
					<Nav.Item className="m-1">
						<Skeleton width="300px" height="1rem" />
					</Nav.Item>
				</Nav>
			</Col>
		</Container>
	</Navbar>
);

export default NavbarCatalogSkeleton;
