import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { Card } from 'components';

export const StyledTable = styled(Table)`
	margin-bottom: 0;
	overflow-y: auto;

	th,
	td {
		white-space: nowrap;
		text-align: center;
		vertical-align: middle;
	}

	td {
		overflow-wrap: break-word;
	}
`;

export const StyledContent = styled(Card.Content)`
	height: 100%;
	min-height: 45rem;
	display: flex;
	flex-direction: column;
`;

export const ColExpand = styled.div`
	flex-grow: 1;
	overflow: auto;

	/* xl */
	@media (min-width: 1200px) {
		height: 0;
	}
`;
