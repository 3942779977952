import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons';

import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

const VcDigitalRoutes: RouteDef = {
	path: '/app/vc-digital',
	validation: [Not(personTypeValidation(PersonType.DistributorUser))],
	menu: {
		name: 'Você Digital',
		icon: faBullseyePointer,
	},
};

export default VcDigitalRoutes;
