import React from 'react';

import {
	faAward,
	faStar,
	faAddressCard,
	faCircle,
	faCogs,
	faDiamond,
	faGem,
	faGift,
	faHandHoldingUsd,
	faList,
	faListOl,
	faUserCrown,
	faUserTag,
} from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import CareerEdit from './Career';
import CommissionEdit from './Commission';
import ConfigEdit from './Config';
import RecognitionEdit from './Recognition';
import ScoreByNetwork from './ScoreByNetWork';
import SubscriptionEdit from './Subscription';
import VipEdit from './Vip';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const MnnRoutes: RouteDef = {
	path: '/admin/mmn',
	menu: {
		name: 'MMN',
		icon: faGem,
	},
	children: [
		{
			path: '/summary',
			menu: {
				name: 'Resumo',
				icon: faCircle,
			},
		},
		{
			path: '/network',
			menu: {
				name: 'Lista da Rede',
				icon: faListOl,
			},
		},
		{
			path: '/graduated',
			render: getListSceneRenderFn(listConfigs.graduated),
			exact: true,
			menu: {
				name: 'Graduados',
				icon: faDiamond,
			},
		},
		{
			path: '/score/by-network',
			component: ScoreByNetwork,
			exact: true,
			menu: {
				name: 'Pontuação da Rede',
				icon: faStar,
			},
		},
		{
			path: '/score/by-user',
			render: getListSceneRenderFn(listConfigs.scoreByUser),
			exact: true,
			menu: {
				name: 'Pontuação x Executivo',
				icon: faList,
			},
		},
		{
			path: '/score/by-order',
			render: getListSceneRenderFn(listConfigs.scoreByOrder),
			exact: true,
			menu: {
				name: 'Pontuação x Pedido',
				icon: faList,
			},
		},
		{
			path: '/person-by-affiliation',
			menu: {
				name: 'Pessoas x Filiação',
				icon: faCircle,
			},
		},
		{
			path: '/vip-view',
			render: getListSceneRenderFn(listConfigs.VipView),
			exact: true,
			menu: {
				name: 'Visão do VIP',
				icon: faUserTag,
				separator: true,
			},
		},
		{
			path: '/subscriptions',
			render: getListSceneRenderFn(listConfigs.Subscriptions),
			exact: true,
			menu: {
				name: 'Assinaturas',
				icon: faAddressCard,
			},
		},
		{
			path: '/subscriptions/:id',
			component: SubscriptionEdit,
			exact: true,
		},
		{
			path: '/vip',
			render: getListSceneRenderFn(listConfigs.Vip),
			exact: true,
			menu: {
				name: 'VIP',
				icon: faUserCrown,
			},
		},
		{
			path: '/vip/:id',
			component: VipEdit,
			exact: true,
		},
		{
			path: '/career',
			render: getListSceneRenderFn(listConfigs.Careers),
			exact: true,
			menu: {
				name: 'Carreiras',
				icon: faGift,
			},
		},
		{
			path: '/career/:id',
			component: CareerEdit,
			exact: true,
		},
		{
			path: '/commissions',
			render: getListSceneRenderFn(listConfigs.Commissions),
			exact: true,
			menu: {
				name: 'Comissões',
				icon: faHandHoldingUsd,
			},
		},
		{
			path: '/commissions/:id',
			component: CommissionEdit,
			exact: true,
		},
		{
			path: '/recognitions',
			component: RecognitionEdit,
			exact: true,
			menu: {
				name: 'Reconhecimentos',
				icon: faAward,
			},
		},
		{
			path: '/products-config',
			menu: {
				name: 'Configuração de Produtos',
				icon: faCircle,
			},
		},
		{
			path: '/config',
			render: getListSceneRenderFn(listConfigs.MMNConfig),
			exact: true,
			menu: {
				name: 'Configuração MMN',
				icon: faCogs,
			},
		},
		{
			path: '/config/:id',
			component: ConfigEdit,
			exact: true,
		},
	],
};

export default MnnRoutes;
