import styled from 'styled-components';

import { Card } from 'components';

export const StyledCard = styled(Card)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	> ${Card.Content} {
		overflow-y: scroll;
	}

	/* xl */
	@media (min-width: 1200px) {
		height: 0;
	}
`;
