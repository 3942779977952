import { useEffect, useState } from 'react';

import useThrottle from 'hooks/useThrottle';

function useThrottleChange<T>(value: T, timeout = 1000): T {
	const [searchProduct, setSearchProduct] = useState(value);
	const delayedSetSearchProduct = useThrottle(setSearchProduct, timeout);

	useEffect(() => {
		delayedSetSearchProduct(value);
	}, [value, delayedSetSearchProduct]);

	return searchProduct;
}

export default useThrottleChange;
