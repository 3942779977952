import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export type ListToBackButtonProps = LinkProps;

const ListToBackButton: React.FC<ListToBackButtonProps> = ({
	className,
	...rest
}) => (
	<Link
		className={classNames(
			'btn btn-sm btn-outline-primary align-self-start mb-3',
			className
		)}
		{...rest}
	>
		<FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Voltar
	</Link>
);

export default ListToBackButton;
