import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Skel from 'react-loading-skeleton';
import styled from 'styled-components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props} className="py-2">
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const AccessUsersFormSkeleton: React.FC = () => (
	<Card>
		<Card.Body>
			<Row>
				<Col lg={8}>
					<div className="text-right">
						<Skeleton width={70} />
					</div>

					<Row>
						<Col lg={12}>
							<Row>
								<SkelGroup as={Col} lg={12} />
							</Row>

							<Row className="justify-content-between">
								<SkelGroup as={Col} lg={4} />
								<SkelGroup as={Col} lg={2} />
							</Row>

							<Row>
								<Form.Group as={Col} lg={12}>
									<Form.Label>
										<Label />
									</Form.Label>
									<Skeleton height="2rem" count={10} />
								</Form.Group>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col lg={4}>
					<Row className="justify-content-between">
						<SkelGroup as={Col} lg={4} />
					</Row>

					<Form.Group as={Col} lg={12} style={{ height: '650px' }}>
						<Form.Label>
							<Label />
						</Form.Label>
						<Skeleton height="1.5rem" count={16} />
					</Form.Group>
				</Col>
			</Row>
		</Card.Body>

		<Card.Footer className="text-right">
			<span className="mr-2">
				<Skel width={100} height="2rem" />
			</span>
			<Skel width={100} height="2rem" />
		</Card.Footer>
	</Card>
);

export default AccessUsersFormSkeleton;
