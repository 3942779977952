import { useRef } from 'react';

function useFirstRender() {
	const firstRender = useRef(true);
	const render = firstRender.current;

	firstRender.current = false;

	return render;
}

export default useFirstRender;
