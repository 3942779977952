import {
	faAddressBook,
	faGem,
	faListUl,
	faNewspaper,
	faShoppingCart,
	faSitemap,
	faStar,
} from '@fortawesome/pro-light-svg-icons';

import { PersonStatus, PersonType, RouteDef } from 'types';
import personStatusValidation from 'utils/RouteValidator/personStatusValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

const NetworkRoutes: RouteDef = {
	path: '/app/network',
	validation: [
		personStatusValidation(PersonStatus.Active),
		Not(personTypeValidation(PersonType.DistributorUser)),
	],
	menu: {
		name: 'Rede',
		icon: faSitemap,
	},
	children: [
		/*
			// TODO: IMPLEMENTAR ROTA
			{
				path: '/summarybymonth',
				name: 'Resumo Mensal',
				icon: 'chart-line',
			},
			*/
		{
			path: '/score',
			menu: {
				name: 'Graduação',
				icon: faGem,
			},
		},
		{
			path: '/treeview',
			menu: {
				name: 'Minha Rede',
				icon: faListUl,
			},
		},
		{
			path: '/treeview/score',
			menu: {
				name: 'Pontuação da Rede',
				icon: faSitemap,
			},
		},
		{
			path: '/order/history',
			menu: {
				name: 'Pedidos da Rede',
				icon: faNewspaper,
			},
		},
		{
			path: '/summary',
			menu: {
				name: 'Vendas da Rede',
				icon: faShoppingCart,
			},
		},
		{
			path: '/scoreByMonthYear',
			menu: {
				name: 'Pontuação Pessoal',
				icon: faStar,
			},
		},
		{
			path: '/contact',
			menu: {
				name: 'Contatos',
				icon: faAddressBook,
			},
		},
	],
};

export default NetworkRoutes;
