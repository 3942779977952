/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { useToast } from 'hooks';
import carrierService from 'services/carrier.service';
import {
	Carrier,
	HttpBaseResult,
	PersonStatus,
	PersonStatusActiveInative,
} from 'types';

const ChangeStatusCarrier: React.FC<{
	row: Carrier;
}> = ({ row }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeStatus, setActiveStatus] = useState<boolean>(
		row.status === 'Ativo'
	);
	const [toast] = useToast();
	const { forceUpdate } = useDatagridContext();

	const handleActiveChange = useCallback(async () => {
		setIsLoading(true);
		const dataActive = {
			idStatus:
				row.status === 'Ativo' ? PersonStatus.Blocked : PersonStatus.Active,
		};

		try {
			await carrierService.patch(row.idPerson!, dataActive);
			setActiveStatus(!activeStatus);
			forceUpdate();
		} catch (e) {
			const { code, message } = e as HttpBaseResult;
			// eslint-disable-next-line no-console
			console.log('Error message: ', message);
			toast(`Não foi possível alterar o status. Cód: ${code}`, {
				type: 'error',
			});
		}

		setIsLoading(false);
	}, [activeStatus, forceUpdate, row, toast]);

	return (
		<Button
			variant={row.status === 'Ativo' ? 'success' : 'danger'}
			disabled={isLoading}
			onClick={handleActiveChange}
		>
			{isLoading ? (
				<Spinner size="sm" animation="border" role="status" />
			) : row.status === 'Ativo' ? (
				PersonStatusActiveInative.Active
			) : (
				PersonStatusActiveInative.Blocked
			)}
		</Button>
	);
};

export default ChangeStatusCarrier;
