import React from 'react';

import {
	faArrowsH,
	faMoneyBill,
	faUniversity,
} from '@fortawesome/pro-duotone-svg-icons';
import {
	faChartBar,
	faDollarSign,
	faKey,
} from '@fortawesome/pro-solid-svg-icons';

import { PersonStatus, PersonType } from 'types';
import { RouteDef } from 'types/router.types';
import personStatusValidation from 'utils/RouteValidator/personStatusValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

import List from 'packages/admin/pages/List';
import listConfigs from 'packages/escritorio/pages/list/list.routes';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';

import Account from './Account';
import ElectronicSign from './ElectronicSign';
import Payout from './Payout';
import Transfer from './Transfer';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const FinancialRoutes: RouteDef = {
	path: '/app/financial',
	validation: [Not(personTypeValidation(PersonType.DistributorUser))],
	menu: {
		name: 'Financeiro',
		icon: faDollarSign,
	},
	children: [
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/summary',
			validation: [personStatusValidation(PersonStatus.Active)],
			render: getListSceneRenderFn(listConfigs.Financial),
			menu: {
				name: 'Resumo Mensal',
				icon: faChartBar,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/transfer',
			validation: [Not(personTypeValidation(PersonType.DistributorUser))],
			component: Transfer,
			menu: {
				name: 'Transferir AMKs',
				icon: faArrowsH,
			},
		},
		// (isDefaulter) ? null :
		{
			path: '/account',
			validation: [Not(personTypeValidation(PersonType.DistributorUser))],
			render: getListSceneRenderFn(listConfigs.FinancialAccounts),
			exact: true,
			menu: {
				name: 'Minhas Contas',
				icon: faUniversity,
			},
		},
		{
			path: '/account/:id',
			component: Account,
			exact: true,
		},
		// (isDefaulter) ? null :
		{
			path: '/payout',
			validation: [Not(personTypeValidation(PersonType.DistributorUser))],
			render: getListSceneRenderFn(listConfigs.FinancialPayouts),
			exact: true,
			menu: {
				name: 'Saques',
				icon: faMoneyBill,
			},
		},
		{
			path: '/payout/:id',
			component: Payout,
			exact: true,
		},
		{
			path: '/electronicsign',
			validation: [Not(personTypeValidation(PersonType.DistributorUser))],
			component: ElectronicSign,
			menu: {
				name: 'Assinatura Eletrônica',
				icon: faKey,
			},
		},
	],
};

export default FinancialRoutes;
