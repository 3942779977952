import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link, useLocation } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { RouteDef } from 'types';

import SideBarSkeleton from './sideBar/Skeleton';
import {
	SideBarStyled,
	SideBarMenuStyled,
	SideBarSubMenuStyled,
	SideBarMobileMenuStyled,
	SideBarMobileSubMenuStyled,
} from './sideBar/styles';

interface ItemActiveIndex {
	itemIndex: number;
	childIndex: number | null;
}

export interface MenuItemWithChildrenProps {
	item: RouteDef;
	linkClassNames?: string;
	subMenuClassNames?: string;
	activeIndex?: ItemActiveIndex;
	index?: number;
}

export interface MenuItemProps {
	item: RouteDef;
	className?: string;
	linkClassName?: string;
}

export interface MenuItemLinkProps {
	item: RouteDef;
	className?: string;
}

export interface SideBarProps {
	items: RouteDef[];
	menuOpened?: boolean;
	isMobile?: boolean;
	isSubmenuActive?: boolean;
	onActiveSubmenu: (active: boolean) => void;
	onMenuClose: () => void;
}

export interface SideNavProps {
	items: RouteDef[];
	activeIndex?: ItemActiveIndex;
}

export interface SideNavMobileProps {
	items: RouteDef[];
	menuOpened?: boolean;
	activeIndex?: ItemActiveIndex;
	closeMenu?: () => void;
}

const MenuArrow = () => (
	<span className="menu-arrow">
		<FAIcon icon={faAngleRight} />
	</span>
);

const MenuItemLink: React.FC<MenuItemLinkProps> = React.memo(
	({ item, className }) => (
		<>
			{item.menu?.externalLink ? (
				<a href={item.path} target="blank" className={className}>
					{item.menu.icon && <FAIcon icon={item.menu.icon} />}
					<span>{item.menu.name}</span>
				</a>
			) : (
				<Link to={item.path} className={className}>
					{item.menu?.icon && <FAIcon icon={item.menu.icon} />}
					<span>{item.menu?.name}</span>
				</Link>
			)}
		</>
	)
);

MenuItemLink.displayName = 'MenuItemLink';
MenuItemLink.whyDidYouRender = true;

const MenuItem: React.FC<MenuItemProps> = React.memo((props) => {
	const { item, className, linkClassName } = props;

	return (
		<>
			<li className={className}>
				<MenuItemLink item={item} className={linkClassName} />
			</li>
			{item.menu?.separator && <hr />}
		</>
	);
});

MenuItem.displayName = 'MenuItem';
MenuItem.whyDidYouRender = true;

const MenuItemWithChildren: React.FC<MenuItemWithChildrenProps> = React.memo(
	(props) => {
		const {
			item,
			linkClassNames,
			subMenuClassNames,
			activeIndex,
			index,
		} = props;

		return (
			<>
				<li
					className={classNames({ active: activeIndex?.itemIndex === index })}
				>
					<Link
						to={item.children?.[0].path ?? item.path}
						className={linkClassNames}
					>
						{item.menu?.icon && <FAIcon icon={item.menu.icon} />}
						<span>{item.menu?.name}</span>
					</Link>

					<SideBarSubMenuStyled
						className={classNames(subMenuClassNames, {
							'active-submenu': activeIndex?.itemIndex === index,
						})}
					>
						{item.children &&
							item.children.map(
								(child, idx) =>
									child.menu && (
										<React.Fragment key={child.path}>
											<MenuItem
												item={child}
												className={classNames({
													active: activeIndex?.childIndex === idx,
												})}
											/>
										</React.Fragment>
									)
							)}
					</SideBarSubMenuStyled>
				</li>
				{item.menu?.separator && <hr />}
			</>
		);
	}
);

MenuItemWithChildren.displayName = 'MenuItemWithChildren';
MenuItemWithChildren.whyDidYouRender = true;

const SideNavMobile: React.FC<SideNavMobileProps> = (props) => {
	const { items, menuOpened, activeIndex, closeMenu } = props;

	return (
		<SideBarMobileMenuStyled
			menuOpened={menuOpened}
			className="sidebar-mobile-menu"
		>
			{items && (
				<Accordion as="ul">
					{items.map(
						(item, idx) =>
							item.menu && (
								<>
									<li
										key={item.path}
										className={classNames({
											active: activeIndex?.itemIndex === idx,
										})}
									>
										<Accordion.Toggle
											as={Link}
											to={item.path}
											eventKey={String(idx)}
										>
											{item.menu.icon && <FAIcon icon={item.menu.icon} />}
											<span>{item.menu.name}</span>
											{item.children && <MenuArrow />}
										</Accordion.Toggle>

										{item.children && (
											<Accordion.Collapse
												eventKey={String(idx)}
												className={classNames({
													'collapse show': activeIndex?.itemIndex === idx,
												})}
											>
												<SideBarMobileSubMenuStyled className="sidebar-mobile-submenu">
													{item.children.map(
														(child, idxChild) =>
															child.menu && (
																<>
																	<li
																		key={child.path}
																		className={classNames({
																			active:
																				activeIndex?.childIndex === idxChild,
																		})}
																	>
																		<Link to={child.path} onClick={closeMenu}>
																			{child.menu.icon && (
																				<FAIcon icon={child.menu.icon} />
																			)}
																			<span>{child.menu.name}</span>
																		</Link>
																	</li>
																	{child.menu.separator && <hr />}
																</>
															)
													)}
												</SideBarMobileSubMenuStyled>
											</Accordion.Collapse>
										)}
									</li>
									{item.menu.separator && <hr />}
								</>
							)
					)}
				</Accordion>
			)}
		</SideBarMobileMenuStyled>
	);
};

SideNavMobile.displayName = 'SideNavMobile';
SideNavMobile.whyDidYouRender = true;

const SideNav: React.FC<SideNavProps> = (props) => {
	const { items, activeIndex } = props;

	return (
		<SideBarMenuStyled className="sidebar-menu">
			{items && (
				<ul>
					{items.map(
						(item, idx) =>
							item.menu && (
								<React.Fragment key={item.path}>
									{item.children ? (
										<MenuItemWithChildren
											item={item}
											subMenuClassNames="sidebar-submenu"
											activeIndex={activeIndex}
											index={idx}
										/>
									) : (
										<MenuItem
											item={item}
											className={classNames('sidebar-menu-item', {
												active: activeIndex?.itemIndex === idx,
											})}
										/>
									)}
								</React.Fragment>
							)
					)}
				</ul>
			)}
		</SideBarMenuStyled>
	);
};

SideNav.displayName = 'SideNav';
SideNav.whyDidYouRender = true;

type SideBarComponent = React.FC<SideBarProps> & {
	Skeleton: typeof SideBarSkeleton;
};

const SideBar: SideBarComponent = (props) => {
	const {
		items,
		menuOpened,
		isMobile,
		isSubmenuActive,
		onActiveSubmenu,
		onMenuClose,
	} = props;

	const location = useLocation();
	const defaultActive: ItemActiveIndex = { itemIndex: 0, childIndex: null };
	const lastActiveIndexString = localStorage.getItem('lastItemActiveIndex');
	const lastActiveIndex = lastActiveIndexString
		? JSON.parse(lastActiveIndexString)
		: null;
	const [activeIndex, setActiveIndex] = useState(
		lastActiveIndex || defaultActive
	);

	useEffect(() => {
		function getActivatedMenuItem(): ItemActiveIndex {
			let bestMatchIdx: number | null = null;
			let bestMatchChildIdx: number | null = null;
			let bestMatchLength = 0;
			let bestMatchChildLength = 0;

			items.forEach((item, idx) => {
				if (
					location.pathname.startsWith(item.path) &&
					bestMatchLength < item.path.length
				) {
					bestMatchIdx = idx;
					bestMatchChildIdx = null;
					bestMatchLength = item.path.length;
				}
				if (item.children) {
					item.children.forEach((child, idxChild) => {
						if (
							location.pathname.startsWith(child.path) &&
							bestMatchChildLength < child.path.length
						) {
							bestMatchIdx = idx;
							bestMatchChildIdx = idxChild;
							bestMatchChildLength = child.path.length;
						}
					});
				}
			});

			if (bestMatchChildIdx !== null) {
				onActiveSubmenu(true);
			}

			return {
				itemIndex: Number(bestMatchIdx),
				childIndex: bestMatchChildIdx,
			};
		}

		function changeActiveIndex(newIndex: ItemActiveIndex) {
			localStorage.setItem('lastItemActiveIndex', JSON.stringify(newIndex));
			setActiveIndex(newIndex);
		}

		onActiveSubmenu(false);
		const activeItems = getActivatedMenuItem();
		changeActiveIndex(activeItems);
	}, [items, location, onActiveSubmenu]);

	return (
		<SideBarStyled
			isMobile={isMobile}
			menuOpened={menuOpened}
			isSubmenuActive={isSubmenuActive}
			className="sidebar d-print-none"
		>
			<PerfectScrollbar>
				{isMobile ? (
					<SideNavMobile
						items={items}
						menuOpened={menuOpened}
						activeIndex={activeIndex}
						closeMenu={onMenuClose}
					/>
				) : (
					<SideNav items={items} activeIndex={activeIndex} />
				)}
			</PerfectScrollbar>
		</SideBarStyled>
	);
};

SideBar.Skeleton = SideBarSkeleton;

SideBar.displayName = 'Sidebar';
SideBar.whyDidYouRender = true;

export default SideBar;
