import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.875rem;
	${(props) => (props.onClick ? 'cursor: pointer' : '')};

	p {
		color: var(--gray-50);
	}

	> div {
		svg {
			min-width: 64px;
			min-height: 64px;
			color: var(--gray-30);
		}
	}
`;
