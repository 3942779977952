import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@restart/hooks/useMediaQuery';
import { cssVar } from 'polished';

import {
	actions as cartActions,
	ShoppingCartItem,
} from 'store/ducks/shoppingCart';
import { EcProductDetails } from 'types';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import useShoppingCartName from 'packages/ecommerce/hooks/useShoppingCartName';

import { FixedWidthText, Small } from '../Typography';

import ListItemSkeleton from './listItem/Skeleton';
import { SmallImage, SmallInputQty } from './Shared';

export type ProductItemProps = {
	item: ShoppingCartItem;
	product: EcProductDetails | undefined;
	className?: string;
	showRemove?: boolean;
	showInput?: boolean;
};

type ProductItemComponent = React.FC<ProductItemProps> & {
	Skeleton: typeof ListItemSkeleton;
};

const ProductItem: ProductItemComponent = ({
	item,
	product,
	className,
	showRemove,
	showInput,
}) => {
	const dispatch = useDispatch();
	const cartName = useShoppingCartName();
	const isSmallScreen = useMediaQuery(
		`(max-width: ${cssVar('--breakpoint-sm')})`
	);

	const onQtyChange = useCallback(
		(qty: number) => {
			dispatch(cartActions.setQuantity(cartName, item.id, qty));
		},
		[dispatch, item.id, cartName]
	);

	const onRemove = useCallback(() => {
		dispatch(cartActions.removeItem(cartName, item.id));
	}, [dispatch, item.id, cartName]);

	if (!product) {
		return <></>;
	}

	return (
		<div className={className}>
			<Row noGutters className="align-items-center">
				<Col className="col-auto">
					<SmallImage
						className="mr-2"
						src={product?.image[0].idImage}
						alt={product?.name}
					/>
				</Col>
				<Col>
					<Row noGutters className="align-items-center">
						<Col xs={12} sm={6} md={6} lg={6} xl={6}>
							<Small className="d-block">{product?.name}</Small>
							<Small className="text-muted d-xs-none">{product?.code}</Small>
						</Col>
						<Col>
							<Row noGutters className="align-items-center">
								<Col className="col-auto mr-2 ml-auto">
									{showInput ? (
										<SmallInputQty
											size="sm"
											hideButtons={isSmallScreen}
											quantity={item.quantity}
											onChange={onQtyChange}
										/>
									) : (
										<>{item.quantity}</>
									)}
								</Col>
								<Col className="col-auto">
									<FixedWidthText width="7rem">
										<span className="text-muted">x</span>{' '}
										{currencyWithSymbolFormatter.format(product?.price || 0)}
									</FixedWidthText>
								</Col>
							</Row>
						</Col>
						<Col md="auto" lg="auto" xl="auto" className="text-right">
							<FixedWidthText width="9rem">
								<big>
									{currencyWithSymbolFormatter.format(
										product?.price * item.quantity
									)}
								</big>
							</FixedWidthText>
						</Col>
					</Row>
				</Col>
				{showRemove && (
					<Col className="col-auto">
						<Button
							size="sm"
							variant="link"
							className="text-danger"
							type="button"
							onClick={onRemove}
						>
							<FontAwesomeIcon icon={faTimesCircle} />
						</Button>
					</Col>
				)}
			</Row>
		</div>
	);
};

ProductItem.Skeleton = ListItemSkeleton;

ProductItem.displayName = 'ProductCard.ListItem';
ProductItem.whyDidYouRender = true;
ProductItem.defaultProps = {
	showInput: true,
	showRemove: true,
};

export default ProductItem;
