import { faHomeAlt } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import { Dashboard } from './pages';
import AccessRoutes from './pages/access/routes';
import ClubRoutes from './pages/club/routes';
import CommRoutes from './pages/communication/routes';
import ExecutiveRoutes from './pages/executive/routes';
import ExpeditionRoutes from './pages/expedition/routes';
import FinancialRoutes from './pages/financial/routes';
import MnnRoutes from './pages/mmn/routes';
import OrderRoutes from './pages/order/routes';
import ReportsRoutes from './pages/reports/routes';
import StoreRoutes from './pages/store/routes';
import SupportRoutes from './pages/support/routes';
import SystemRoutes from './pages/system/routes';
import VcDigitalRoutes from './pages/vcdigital/routes';

const DashboardRoutes: RouteDef = {
	path: '/admin',
	component: Dashboard,
	exact: true,
	menu: {
		name: 'Painel',
		icon: faHomeAlt,
	},
};

const AdminRoutes: RouteDef[] = [
	DashboardRoutes,
	ExecutiveRoutes,
	CommRoutes,
	MnnRoutes,
	VcDigitalRoutes,
	ClubRoutes,
	StoreRoutes,
	OrderRoutes,
	ExpeditionRoutes,
	FinancialRoutes,
	ReportsRoutes,
	SupportRoutes,
	AccessRoutes,
	SystemRoutes,
];

export default AdminRoutes;
