import { AsyncSelectFilterQuery } from 'components/Formik/AsyncSelect';

const executiveQuery: AsyncSelectFilterQuery = (input: string) => {
	const terms = input.trim().split(' ');
	const query = [];
	let id = null;
	let execName = null;
	// eslint-disable-next-line no-restricted-globals
	if (!isNaN(parseInt(terms[0], 10))) {
		id = terms.shift();
		query.push(`"~id": "${id}*"`);
	}
	execName = terms.join(' ');
	if (execName) {
		query.push(`"~name": "*${execName}*"`);
	}
	return query;
};

const careerQuery: AsyncSelectFilterQuery = (input: string) => {
	const terms = input.trim().split(' ');
	const query = [];
	let id = null;
	let execName = null;
	// eslint-disable-next-line no-restricted-globals
	if (!isNaN(parseInt(terms[0], 10))) {
		id = terms.shift();
		query.push(`"~idCareer": "${id}*"`);
	}
	execName = terms.join(' ');
	if (execName) {
		query.push(`"~name": "*${execName}*"`);
	}
	return query;
};

export default {
	executiveQuery,
	careerQuery,
};
