import orderService from 'services/order.service';
import { OrderForList } from 'types';
import {
	numberFormatter,
	cepFormatter,
	currencyFormatter,
	dateTimeFormatter,
	buttonLinkFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import paginateConfig from 'packages/admin/pages/list/routes/paginate';

import { ListSceneConfig } from '../types';

import PayoutAttachButton from './financial/PayoutAttachButton';
import PayoutPaymentButton from './financial/PayoutPaymentButton';

const appBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Pedidos',
		path: 'app/order',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	Orders: {
		title: 'Meus Pedidos',
		breadcrumbItems: [
			...appBreadcrumb,
			{ label: 'Meus Pedidos', active: true },
		],
		action: orderService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'number',
			sortByDesc: true,
			columns: [
				{
					key: 'number',
					title: 'Pedido',
					formatter: numberFormatter,
					searchFormatter: numberFormatter.formatForSearch,
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'date',
					title: 'Data',
					formatter: dateTimeFormatter,
					searchFormatter: dateTimeFormatter.formatForSearch,
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'type',
					title: 'Tipo',
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'payment',
					title: 'Forma de Pagamento',
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'cep',
					title: 'CEP',
					searchable: true,
					formatter: cepFormatter,
					searchFormatter: cepFormatter.formatForSearch,
					className: 'text-center',
				},
				{
					key: 'shipment',
					title: 'Envio',
					searchable: true,
					className: 'text-center',
				},
				{
					key: 'total',
					title: 'Total',
					searchable: true,
					formatter: currencyFormatter,
					searchFormatter: currencyFormatter.formatForSearch,
					className: 'text-center',
				},
				{
					key: 'idStatus',
					title: 'Status',
					searchable: true,
					className: 'text-center',
					formatter: buttonLinkFormatter<OrderForList, OrderForList>({
						component: PayoutPaymentButton,
					}),
				},
				{
					key: 'attach',
					title: '',
					className: 'text-center',
					searchable: false,
					formatter: buttonLinkFormatter<OrderForList, void>({
						component: PayoutAttachButton,
					}),
				},
			],
		},
	},
};

export default ScenesConfig;
