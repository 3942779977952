import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';

export const Card = styled(BootstrapCard)`
	display: flex;
	flex-direction: column;
	max-width: 500px;
	margin: 0 20px 20px 0;
`;

export const AuthorDate = styled.div`
	margin-bottom: 10px;
`;
