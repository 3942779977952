import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import styled from 'styled-components';

import { DatagridState } from 'components/datagrid/types';
import useDatagridContext from 'components/datagrid/useDatagridContext';
import financialService from 'services/financial.service';
import { FormatterType } from 'utils/Formatter/IFormatter';

export type ExecutiveBalanceProps = {
	idPersonField: string;
	formatter: FormatterType;
	prefix?: string | React.ReactNode;
	affix?: string | React.ReactNode;
	className?: string;
};

const StyledNumber = styled.span`
	font-size: 1.2rem;
`;

const ExecutiveBalance: React.FC<ExecutiveBalanceProps> = ({
	idPersonField,
	formatter,
	prefix,
	affix,
	className,
}) => {
	const { listenForUpdates } = useDatagridContext();
	const [idPerson, setIdPerson] = useState<number | null>(null);

	const { data: total, isLoading: isDataLoading } = useQuery(
		['executive_balance', idPerson],
		() => financialService.getBalanceByExecutiveId(idPerson as number),
		{
			enabled: idPerson,
			cacheTime: 0,
		}
	);

	// Registra listener para atualizar idPerson durante update de grid
	useEffect(() => {
		listenForUpdates((state: DatagridState) => {
			// eslint-disable-next-line no-console
			console.log(
				'CHAMANDO BALANCE',
				state.params,
				state.params[idPersonField]
			);
			setIdPerson(
				state.params[idPersonField] ? Number(state.params[idPersonField]) : null
			);
		});
	}, [listenForUpdates, idPersonField]);

	return (
		<StyledNumber className={className}>
			{prefix}
			<b>
				{!total || isDataLoading
					? '-,--'
					: formatter.format('total', { total })}
			</b>
			{affix}
		</StyledNumber>
	);
};

export default ExecutiveBalance;
