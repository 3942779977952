import React from 'react';

import careerService from 'services/career.service';
import commissionService from 'services/commission.service';
import graduatedService from 'services/graduated.service';
import mmnConfigService from 'services/mmnConfig.service';
import scoreService from 'services/score.service';
import subscriptionService from 'services/subscriptions.service';
import vipService from 'services/vip.service';
import { MMNConfig, Career, Vip } from 'types';
import {
	editLinkFormatter,
	numberFormatter,
	currencyFormatter,
	decimalFormatter,
	coloredStatusFormatter,
	buttonLinkFormatter,
	customFormatter,
	dateFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import ChangeActiveButton from 'packages/admin/pages/mmn/career/ChangeActiveButton';
import DeleteVipButton from 'packages/admin/pages/mmn/vip/DeleteVipButton';
import {
	activeInactiveStatusByBoolean,
	yesNoStatusByBoolean,
} from 'packages/admin/utils/coloredStatus';

import { ListSceneConfig } from '../types';

import GraduatedFilters from './financial/GraduatedFilters';
import ScoreByOrderFilters from './financial/ScoreByOrderFilters';
import ScoreByUserFilters from './financial/ScoreByUserFilters';
import VipViewFilters from './financial/VipViewFilters';
import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'MMN',
		path: '/admin/mmn',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	scoreByUser: {
		title: 'Pontuação x Executivo',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pontuação x Executivo',
				active: true,
			},
		],
		action: scoreService.getScoreByUser,
		datatableFilters: <ScoreByUserFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'idScore',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: dateFormatter,
				},
				{
					key: 'number',
					title: 'Pedido',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'friendId',
					title: 'ID do Amigo',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'friend',
					title: 'Amigo',
					searchable: true,
					sortable: true,
				},
				{
					key: 'level',
					title: 'Nível',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'value',
					title: 'Pontos',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	scoreByOrder: {
		title: 'Pontuação x Pedido',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pontuação x Pedido',
				active: true,
			},
		],
		action: scoreService.getScoreByOrder,
		datatableFilters: <ScoreByOrderFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'idScore',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: dateFormatter,
				},
				{
					key: 'id',
					title: 'ID',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'name',
					title: 'Executivo',
					searchable: true,
					sortable: true,
				},
				{
					key: 'level',
					title: 'Nível',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'friendId',
					title: 'ID do Amigo',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'friend',
					title: 'Amigo',
					searchable: true,
					sortable: true,
				},
				{
					key: 'value',
					title: 'Pontos',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	VipView: {
		title: 'Visão do VIP',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Visão do VIP',
				active: true,
			},
		],
		action: vipService.getByMonth,
		datatableFilters: <VipViewFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'idVipMonthly',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'id',
					title: 'ID',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'name',
					title: 'Nome',
					searchable: true,
					sortable: true,
				},
				{
					key: 'vip',
					title: 'VIP',
					searchable: true,
					sortable: true,
					className: 'text-center',
				},
				{
					key: 'value',
					title: 'Valor',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	graduated: {
		title: 'Graduados',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Graduados',
				active: true,
			},
		],
		action: graduatedService.getGraduatedByIdCareer,
		datatableFilters: <GraduatedFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'value',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'id',
					title: 'ID',
					searchable: false,
					sortable: false,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'name',
					title: 'Executivo',
					searchable: true,
					sortable: true,
				},
				{
					key: 'value',
					title: 'Pontuação',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	Subscriptions: {
		title: 'Assinaturas',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/subscriptions/create`,
		action: subscriptionService.getList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idSubscription',
					title: '',
					scope: 'row',
					searchable: false,
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/subscriptions/{id}`,
					}),
				},
				{
					key: 'idSubscription',
					title: 'ID',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'name.name', searchable: true, sortable: true, title: 'Nome' },
				{
					key: 'daysOfRenovation',
					title: 'Dias',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'valueOfRenovation',
					title: 'Valor',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'minValue',
					title: 'Valor Mínimo',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'maxValue',
					title: 'Valor Máximo',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'isActive',
					title: 'Status',
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: activeInactiveStatusByBoolean,
					}),
				},
			],
		},
	},
	Vip: {
		title: 'VIP',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/vip/create`,
		action: vipService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idVip',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/vip/{id}`,
					}),
				},
				{
					key: 'name',
					title: 'Nome',
					className: 'w-75',
					searchable: true,
				},
				{
					key: 'value',
					title: 'Valor',
					className: 'text-center w-25',
					searchable: true,
					formatter: decimalFormatter(0),
				},
				{
					key: 'idVip',
					title: '',
					className: 'text-center',
					searchable: false,
					formatter: buttonLinkFormatter<Vip, void>({
						component: DeleteVipButton,
					}),
				},
			],
		},
	},
	Careers: {
		title: 'Carreiras',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/career/create`,
		action: careerService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idCareer',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/career/{id}`,
					}),
				},
				{
					key: 'level',
					title: 'Nível',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'name',
					searchable: true,
					title: 'Nome (Carreira)',
				},
				{
					key: 'goalValue',
					title: 'Meta',
					className: 'text-center',
					searchable: true,
					formatter: currencyFormatter,
				},
				{
					key: 'maxInLine',
					title: 'Limite por Linha (%)',
					className: 'text-center',
					searchable: true,
					formatter: currencyFormatter,
				},
				{
					key: 'vip',
					title: 'VIP',
					className: 'text-center',
					searchable: false,
					formatter: customFormatter<Career>({
						value: ({ vip }) => vip?.name ?? '',
					}),
				},
				{
					key: 'active',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter<Career, void>({
						component: ChangeActiveButton,
					}),
				},
			],
		},
	},
	Commissions: {
		title: 'Comissões',
		breadcrumbItems: [
			{ ...adminBreadcrumb[0], path: '/admin/mmn/summary' },
			{ label: 'Comissões', active: true },
		],
		newItemLink: `${adminBreadcrumb[0].path}/commissions/create`,
		action: commissionService.getList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idComission',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center w-25',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/commissions/{id}`,
					}),
				},
				{ key: 'name', searchable: true, sortable: true, title: 'Nome' },
			],
		},
	},
	MMNConfig: {
		title: 'Configuração MMN',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/config/create`,
		action: mmnConfigService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idConfig',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/config/{id}`,
					}),
				},
				{
					key: 'name',
					searchable: true,
					title: 'Nome',
				},
				{
					key: 'comissionFactor',
					title: 'Fator de comissão',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'scoreFactor',
					title: 'Fator de pontuação',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'plFactor',
					title: 'Fator de PL',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'isVip',
					title: 'VIP',
					className: 'text-center',
					searchable: true,
					formatter: coloredStatusFormatter({
						statusList: yesNoStatusByBoolean,
					}),
				},
				{
					key: 'comission',
					title: 'Tabela de Comissão',
					className: 'text-center',
					searchable: true,
					formatter: customFormatter<MMNConfig>({
						value: ({ comission }) => comission?.name ?? '',
					}),
				},
				{
					key: 'activation',
					title: 'Programa de Ativação',
					className: 'text-center',
					searchable: true,
					formatter: customFormatter<MMNConfig>({
						value: ({ activation }) => activation?.name ?? '',
					}),
				},
			],
		},
	},
};

export default ScenesConfig;
