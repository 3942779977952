import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
	Formik,
	Form as FormikForm,
	FormikProps,
	FieldArray,
	ArrayHelpers,
	FormikHelpers,
} from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card, List, Input as InputNormal } from 'components';
import {
	Input,
	Upload,
	Radio,
	ToggleButtonGroup,
	ToggleButton,
	DateTimeInput,
	FormikEffect,
} from 'components/Formik';
import { AddActions } from 'components/list/styles';
import uploadHelpers from 'components/upload/Helpers';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import myService from 'services/my.service';
import { actions } from 'store/ducks/session';
import { ExecutiveRegister, ExecutiveUpdateRegister } from 'types';
import {
	cpfFormatter,
	cnpjFormatter,
	phoneFormatter,
	regexNumbersOnlyFormatter,
} from 'utils/formatters';

import {
	FormGroupTitle,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import DetailsSkeleton from './details/DetailsSkeleton';
import { ExecutiveRegisterFormType } from './details/types';

export const defaultFormValue: ExecutiveRegisterFormType = {
	idPerson: null,
	idType: null,
	type: '',
	name: '',
	email: '',
	dateRegister: '',
	id: null,
	socialName: '',
	imageUrl: [],
	personType: 'PF',
	cpfCnpj: '',
	nRegister: '',
	idSubscription: null,
	subscription: '',
	gender: 'F',
	birthdate: '',
	idSponsor: null,
	nameSponsor: '',
	phone: [],
};

const birthdateMin = new Date('1900-01-01');
const birthdateMax = new Date();
birthdateMax.setFullYear(birthdateMax.getFullYear() - 18);

export const formSchema = Yup.object<ExecutiveRegisterFormType>().shape({
	gender: Yup.string().when('personType', {
		is: 'PF',
		then: Yup.string().required('Campo obrigatório'),
	}),
	birthdate: Yup.mixed().when('personType', {
		is: 'PF',
		then: Yup.date()
			.required('Campo obrigatório')
			.min(birthdateMin, 'Data inválida')
			.max(birthdateMax, 'Você precisa ter mais de 18 anos')
			.nullable(),
	}),
});

const Details: React.FC = () => {
	const [toast] = useToast();
	const [phone, setPhone] = useState<string>('');
	const dispatch = useDispatch();

	const loadData = useCallback(
		() => async () => {
			const { imageUrl, ...response } = await executiveService.getRegister();
			const user = {
				...response,
				// Transformar prop image em UploadFile[]
				...(imageUrl && { imageUrl: uploadHelpers.mapImages(imageUrl) }),
			};

			user.phone = user.phone
				? user.phone.map((p) => ({ ...p, deleted: false }))
				: [];
			return { user };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends ExecutiveRegisterFormType>(
			{ imageUrl, ...values }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const submit: ExecutiveRegister = {
				...values,
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(imageUrl) &&
					(uploadHelpers.isNewFile(imageUrl[0]?.url)
						? { imageUrl: imageUrl[0]?.url }
						: imageUrl.length === 0 && { imageUrl: '' })),
			};

			const dataToSend: ExecutiveUpdateRegister = {
				birthdate: submit.birthdate || undefined,
				gender: submit.gender || undefined,
				imageUrl: submit.imageUrl,
				phone: submit.phone,
			};

			try {
				await myService.updateRegister(dataToSend);
				toast('Dados alterados com sucesso', { type: 'success' });
				dispatch(actions.loadLoggedRequest());
			} catch (e) {
				toast(`Não foi possível editar os dados. Cód: ${e.code}`, {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[dispatch, toast]
	);

	return (
		<PageLayoutFormWrapper
			title="Meu Cadastro"
			breadcrumb={[{ label: 'Cadastro', path: '/app/my/details' }]}
			skeleton={DetailsSkeleton}
			load={loadData}
			withPrependTitle={false}
		>
			{(_, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(value, helpers)}
					initialValues={data!.user}
					validationSchema={formSchema}
				>
					{(form: FormikProps<ExecutiveRegisterFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator>
									<Card.Title>Dados Cadastrais</Card.Title>
								</Card.Header>
								<Card.Content>
									<Form.Row className="w-100">
										<Form.Group as={Col} sm={8} md={4} lg={2}>
											<Form.Label className="d-block">
												Foto do Perfil
											</Form.Label>
											<Upload
												name="imageUrl"
												listType="picture-card"
												showUploadList={false}
												aspect={1}
												accept=".jpg, .png"
												maxSize={2}
												displayInline
											>
												<FAIcon icon={faUser} />
												<div>Adicionar Foto</div>
											</Upload>
										</Form.Group>

										<Col md={8} lg={{ span: 6, offset: 1 }}>
											<Form.Row>
												<Form.Group as={Col} md={6}>
													<Form.Label>Patrocinador</Form.Label>
													<Input name="nameSponsor" type="text" readOnly />
												</Form.Group>

												<Form.Group as={Col} md={6}>
													<Form.Label>Meu ID</Form.Label>
													<Input name="id" type="text" readOnly />
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={6}>
													<Form.Label>Assinatura</Form.Label>
													<Input name="subscription" type="text" readOnly />
												</Form.Group>

												<Form.Group as={Col} md={6}>
													<Form.Label>Tipo</Form.Label>
													<Input name="type" type="text" readOnly />
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={6}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" type="text" readOnly />
												</Form.Group>

												<Form.Group as={Col} md={6}>
													<Form.Label>Email</Form.Label>
													<Input name="email" type="email" readOnly />
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12} lg={8} className="d-flex">
													<Radio
														name="personType"
														label="Pessoa Física"
														value="PF"
														className="mr-3"
														defaultChecked
														disabled
													/>
													<Radio
														name="personType"
														label="Pessoa Jurídica"
														value="PJ"
														disabled
													/>
												</Form.Group>
											</Form.Row>

											{form.values.personType === 'PF' && (
												<Form.Row>
													<Form.Group as={Col} md={6}>
														<Form.Label>CPF</Form.Label>
														<Input
															name="cpfCnpj"
															type="text"
															formatter={cpfFormatter}
															readOnly
														/>
													</Form.Group>

													<Form.Group as={Col} md={6}>
														<Form.Label>RG</Form.Label>
														<Input
															name="nRegister"
															type="text"
															formatter={regexNumbersOnlyFormatter}
															readOnly
														/>
													</Form.Group>

													<Form.Group as={Col} md={6}>
														<Form.Label>Sexo</Form.Label>
														<br />
														<ToggleButtonGroup name="gender">
															<ToggleButton
																variant="primary"
																value="F"
																disabled={form.isSubmitting}
															>
																Feminino
															</ToggleButton>
															<ToggleButton
																variant="primary"
																value="M"
																disabled={form.isSubmitting}
															>
																Masculino
															</ToggleButton>
														</ToggleButtonGroup>
													</Form.Group>

													<Form.Group as={Col} md={6}>
														<Form.Label>Data de Nascimento</Form.Label>
														<DateTimeInput
															name="birthdate"
															selectDate
															readOnly={form.isSubmitting}
														/>
													</Form.Group>
												</Form.Row>
											)}

											{form.values.personType === 'PJ' && (
												<Form.Row>
													<Form.Group as={Col} md={6}>
														<Form.Label>CNPJ</Form.Label>
														<Input
															name="cpfCnpj"
															type="text"
															formatter={cnpjFormatter}
															readOnly
														/>
													</Form.Group>

													<Form.Group as={Col} md={6}>
														<Form.Label>Inscrição Estadual</Form.Label>
														<Input
															name="nRegister"
															type="text"
															formatter={regexNumbersOnlyFormatter}
															readOnly
														/>
													</Form.Group>
												</Form.Row>
											)}

											<Form.Row>
												<FormGroupTitle className="mt-3">
													Telefones
												</FormGroupTitle>
											</Form.Row>

											<Form.Row>
												<FieldArray name="phone">
													{(arrayHelper: ArrayHelpers) => (
														<Form.Group as={Col} md={12}>
															<AddActions>
																<Row noGutters className="w-100">
																	<Col>
																		<OverlayTrigger
																			placement="top"
																			overlay={
																				<Tooltip id="number_phone">
																					Novo número de Telefone.
																				</Tooltip>
																			}
																		>
																			<Form.Label className="no-wrap">
																				Número
																			</Form.Label>
																		</OverlayTrigger>
																		<InputNormal
																			name="phone_number"
																			type="text"
																			formatter={phoneFormatter}
																			value={phone}
																			onChange={setPhone}
																			readOnly={form.isSubmitting}
																		/>
																	</Col>
																	<Col
																		sm="auto"
																		className="d-flex flex-column-reverse"
																	>
																		<Button
																			type="button"
																			variant="outline-info"
																			onClick={() => {
																				arrayHelper.push({
																					number: phone,
																					deleted: false,
																				});
																				setPhone('');
																			}}
																			disabled={!phone}
																		>
																			<FAIcon icon="plus" className="mr-2" />{' '}
																			Adicionar
																		</Button>
																	</Col>
																</Row>
															</AddActions>

															<List
																data="phone"
																emptyDescription="Não há telefones adicionados"
																filterListProp="deleted"
																className="mb-3"
																onRemoveItem={(item) => {
																	form.setFieldValue(
																		'phone',
																		form.values.phone?.map((it) => {
																			return {
																				...it,
																				deleted: item.number === it.number,
																			};
																		})
																	);
																}}
															>
																{(item) => (
																	<Row className="w-100">
																		<Col>
																			<span>
																				{phoneFormatter.format(item.number)}
																			</span>
																		</Col>
																	</Row>
																)}
															</List>
														</Form.Group>
													)}
												</FieldArray>
											</Form.Row>
										</Col>
									</Form.Row>
								</Card.Content>

								<Card.Content className="border-top">
									<Col md={12} lg={9}>
										<div className="text-right">
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</div>
									</Col>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default Details;
