import {
	DatatableViewState,
	DatatableViewEnum,
	DatatableViewActionTypes,
} from './types';

export const defaultReducerState: DatatableViewState = {
	tableParams: undefined,
	fetchData: {
		isLoading: false,
		data: null,
		error: null,
		pagination: null,
	},
};

const reducer = (
	state: DatatableViewState = defaultReducerState,
	action: DatatableViewActionTypes
): DatatableViewState => {
	switch (action.type) {
		case DatatableViewEnum.SetParams:
			return {
				...state,
				tableParams: action.payload,
			};
		case DatatableViewEnum.SetLoading:
			return {
				...state,
				fetchData: {
					...state.fetchData,
					isLoading: action.payload,
				},
			};
		case DatatableViewEnum.SetFetchData:
			return {
				...state,
				fetchData: {
					isLoading: false,
					error: null,
					...action.payload,
				},
			};
		case DatatableViewEnum.SetFetchError:
			return {
				...state,
				fetchData: {
					data: null,
					pagination: null,
					isLoading: false,
					error: action.payload,
				},
			};
		default:
			return state;
	}
};

export default reducer;
