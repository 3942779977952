import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Formik, FormikProps, Form as FormikForm, FormikHelpers } from 'formik';
import {
	Col,
	Form,
	OverlayTrigger,
	Pagination,
	Row,
	Spinner,
	Tooltip,
} from 'react-bootstrap';
import { useQuery } from 'react-query';

import { faBoxUp, faEdit } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationModal } from 'modals';
import * as Yup from 'yup';

import { Button, Card, LoadingProgressBar } from 'components';
import { Input, Select } from 'components/Formik';
import { useModal, useToast } from 'hooks';
import orderService from 'services/order.service';
import { HttpGetListParams, HttpGetListResult, Order } from 'types';
import { FormatterType } from 'utils/Formatter/IFormatter';
import {
	cepFormatter,
	coloredStatusFormatter,
	currencyFormatter,
	dateFormatter,
	numberFormatter,
} from 'utils/formatters';

import { FormGroupTitle } from 'packages/admin/components';
import useSelectCompany from 'packages/admin/hooks/useSelectCompany';
import { IconButton } from 'packages/admin/pages/financial/statementApproval/styles';

import OrderPaymentModal from './OrderPaymentModal';
import {
	StyledTable,
	ColExpand,
	StyledContent,
} from './orderSearchList/styles';

type FilterByProp = { name: string; value: keyof Order };

type OrderSearchListType = {
	className?: string;
};

type OrderSearchListForm = {
	filterBy: FilterByProp;
	valueBy: string;
};

const itemsByFilter: FilterByProp[] = [
	{ name: 'ID', value: 'id' },
	{ name: 'Nome', value: 'name' },
	{ name: 'Nº do Pedido', value: 'number' },
	{ name: 'Data do Pedido', value: 'date' },
	{ name: 'Data do pagamento', value: 'paymentDate' },
];

const defaultFormValue: OrderSearchListForm = {
	filterBy: itemsByFilter[0],
	valueBy: '',
};

const formatterMap: { [x in keyof Partial<Order>]: FormatterType } = {
	idOrder: numberFormatter,
	number: numberFormatter,
	cep: cepFormatter,
	date: dateFormatter,
	paymentDate: dateFormatter,
};

const statusFormatter = coloredStatusFormatter({
	// TODO: Verificar se vai precisar desse coloredStatus.
	statusList: [
		{
			status: 1,
			badgeClassName: 'text-success',
			badgeText: 'Entregue',
		},
	],
});

const validationSchema = Yup.object({
	filterBy: Yup.object<FilterByProp>().required('Campo obrigatório'),
	valueBy: Yup.string()
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'idOrder',
			then: Yup.string().required('Campo obrigatório'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'number',
			then: Yup.string().required('Campo obrigatório'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'cep',
			then: Yup.string().required('Campo obrigatório'),
		}),
});

const initialPagination = { page: 1, paginate: 12, sort: '-number' };

const OrderSearchList: React.FC<OrderSearchListType> = ({ className }) => {
	const [toast] = useToast();
	const open = useModal();
	const [selectedCompany] = useSelectCompany();
	const [searchParameters, setSearchParameters] = useState<HttpGetListParams>({
		pagination: initialPagination,
		params: {
			idCompany: selectedCompany.idPerson,
		},
	});

	const { data, isLoading, isFetching, error } = useQuery<
		HttpGetListResult<Order>,
		{ message?: string }
	>(
		['order_search_list', searchParameters],
		(_: string, params: HttpGetListParams) =>
			orderService.getOrderHistoryByCompany(params),
		{
			enabled: !!searchParameters?.params?.idCompany,
			keepPreviousData: true,
			onError: () => {
				toast('Não foi possível carregar os pedidos', {
					type: 'error',
				});
			},
		}
	);

	// eslint-disable-next-line no-unused-vars
	const { orders, currPage, perPage: _, lastPage, pageRange } = useMemo(() => {
		const range: number[] = [];
		const pagination = {
			orders: data?.data ?? [],
			currPage: data?.meta?.current_page ?? initialPagination.page,
			perPage: data?.meta?.per_page ?? initialPagination.paginate,
			lastPage: data?.meta?.last_page ?? initialPagination.page,
			pageRange: range,
			total: data?.meta?.total ?? 0,
		};

		// Ajuste no pageRange
		if (pagination.currPage > initialPagination.page)
			range.push(pagination.currPage - 1);
		// TODO: Generate 5 numbers here
		range.push(pagination.currPage);
		if (pagination.currPage < pagination.lastPage)
			range.push(pagination.currPage + 1);

		return pagination;
	}, [data]);

	const handleSubmit = useCallback(
		async <T extends OrderSearchListForm>(
			{ filterBy, valueBy }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			setSearchParameters({
				...searchParameters,
				params: {
					idCompany: searchParameters?.params?.idCompany ?? null,
					[filterBy.value]: valueBy ?? '',
				},
			});
			setSubmitting(false);
		},
		[searchParameters]
	);

	const handleSetPage = useCallback(
		(page: number) => (event: React.SyntheticEvent) => {
			event.preventDefault();

			setSearchParameters({
				...searchParameters,
				pagination: {
					...searchParameters.pagination,
					page,
				},
			});
		},
		[searchParameters]
	);

	const handleCancelOrder = useCallback(
		(order: Order) => () => {
			open(
				ConfirmationModal,
				{
					message: `Tem certeza que deseja cancelar o pedido ${numberFormatter.format(
						order.number
					)}?`,
				},
				(_confirmed) => {
					// TODO: Cancelar pedido
				}
			);
		},
		[open]
	);

	const handleMakePayment = useCallback(
		(order: Order) => () => {
			open(OrderPaymentModal, { order, selectedCompany }, (_result) => {
				// TODO: Efetuar pagamento
			});
		},
		[open, selectedCompany]
	);

	const handleSendToExpedition = useCallback(
		(order: Order) => () => {
			open(
				ConfirmationModal,
				{
					message: `Confirmar envio de pedido ${numberFormatter.format(
						order.number
					)} para expedição?`,
				},
				(_confirmed) => {
					// TODO: Enviar pedido para expedição
				}
			);
		},
		[open]
	);

	return (
		<Card className={className} grow>
			<StyledContent>
				<FormGroupTitle>Consultar Pedidos</FormGroupTitle>

				<Formik
					onSubmit={(values, helpers) => handleSubmit(values, helpers)}
					initialValues={defaultFormValue}
					validationSchema={validationSchema}
				>
					{(form: FormikProps<OrderSearchListForm>) => (
						<FormikForm>
							<Form.Row>
								<Form.Group as={Col} md={3}>
									<Form.Label>Tipo</Form.Label>
									<Select
										name="filterBy"
										options={itemsByFilter}
										optionLabel="name"
										optionValue="value"
										isDisabled={isLoading}
										onChange={() => form.setFieldValue('valueBy', '')}
									/>
								</Form.Group>

								<Form.Group as={Col} md={6}>
									<Form.Label>{form.values.filterBy.name}</Form.Label>
									<Input
										name="valueBy"
										type="text"
										formatter={formatterMap[form.values.filterBy.value]}
										disabled={isLoading}
									/>
								</Form.Group>

								<Form.Group as={Col}>
									<div className="d-flex flex-column align-items-end">
										<Form.Label className="d-block pb-4"> </Form.Label>
										<Button
											type="submit"
											variant="primary"
											disabled={isLoading}
										>
											{isLoading ? (
												<>
													<Spinner size="sm" animation="border" role="status" />
													<span className="ml-2">Aguarde...</span>
												</>
											) : (
												'Pesquisar'
											)}
										</Button>
									</div>
								</Form.Group>
							</Form.Row>
						</FormikForm>
					)}
				</Formik>

				{isLoading || (isFetching && <LoadingProgressBar />)}
				<ColExpand>
					<StyledTable striped bordered hover>
						<thead>
							<tr>
								<th> </th>
								<th>Pedido</th>
								<th>Data</th>
								<th>ID</th>
								<th>Executivo</th>
								<th>Tipo</th>
								<th>Envio</th>
								<th>Forma de Pgto.</th>
								<th>Total</th>
								<th>Status</th>
								<th>Ações</th>
							</tr>
						</thead>
						<tbody>
							{!error &&
								orders.length &&
								orders.map((item) => (
									<tr key={item.idOrder}>
										<td>
											<Link to={`/admin/orders/${item.idOrder}`}>
												<FAIcon icon={faEdit} />
											</Link>
										</td>
										<td>{formatterMap?.number?.format(String(item.number))}</td>
										<td>{formatterMap?.date?.format(item.date)}</td>
										<td>
											{formatterMap?.idOrder?.format(String(item.idOrder))}
										</td>
										<td className="text-left">{item.name}</td>
										<td>{item.type}</td>
										<td>{item.sendObservation}</td>
										<td>{item.payment}</td>
										<td>{currencyFormatter.format(item.total)}</td>
										<td>{statusFormatter.format(item.status)}</td>
										<td>
											{/* TODO: Adicionar propriedade overlay no Button */}
											{!item.paymentDate && (
												<>
													<OverlayTrigger
														placement="top"
														overlay={
															<Tooltip id={`order_payment_${item.idOrder}`}>
																Lançar Pagamento
															</Tooltip>
														}
													>
														<IconButton
															variant="success"
															className="mx-1"
															onClick={handleMakePayment(item)}
														>
															<FAIcon icon={faCheck} />
														</IconButton>
													</OverlayTrigger>
													<OverlayTrigger
														placement="top"
														overlay={
															<Tooltip id={`order_cancel_${item.idOrder}`}>
																Cancelar Pedido
															</Tooltip>
														}
													>
														<IconButton
															variant="danger"
															className="mx-1"
															onClick={handleCancelOrder(item)}
														>
															<FAIcon icon={faTimes} />
														</IconButton>
													</OverlayTrigger>
												</>
											)}
											{/* TODO: Fazer funcionalidade de expedição */}
											{item.paymentDate && (
												<OverlayTrigger
													placement="top"
													overlay={
														<Tooltip id={`order_cancel_${item.idOrder}`}>
															Enviar para expedição
														</Tooltip>
													}
												>
													<IconButton
														variant="yellow"
														className="mx-1"
														onClick={handleSendToExpedition(item)}
													>
														<FAIcon icon={faBoxUp} />
													</IconButton>
												</OverlayTrigger>
											)}
										</td>
									</tr>
								))}
							{!error && orders.length === 0 && !isLoading && (
								<tr>
									<td colSpan={11} className="text-muted">
										Busque Pedidos nos filtros acima.
									</td>
								</tr>
							)}
							{!error && orders.length === 0 && isLoading && (
								<tr>
									<td colSpan={11} className="text-muted">
										Buscando pedidos...
									</td>
								</tr>
							)}
							{!!error && (
								<tr>
									<td colSpan={11} className="text-danger">
										{typeof error === 'string'
											? error
											: error?.message || 'Erro ao obter os dados.'}
									</td>
								</tr>
							)}
						</tbody>
					</StyledTable>
				</ColExpand>

				{/* TODO: Fixar no final (tentar expandir o StyledTable) */}
				<Row className="mt-3">
					<Col className="d-flex">
						<Pagination className="mb-0 ml-auto">
							{!orders.length ? (
								<>
									<Pagination.First disabled />
									<Pagination.Item disabled>1</Pagination.Item>
									<Pagination.Last disabled />
								</>
							) : (
								<>
									<Pagination.First
										onClick={handleSetPage(1)}
										disabled={isLoading || isFetching || currPage === 1}
									/>
									<Pagination.Prev
										onClick={handleSetPage(currPage - 1)}
										disabled={isLoading || isFetching || currPage <= 2}
									/>
									{pageRange.map((page) => (
										<Pagination.Item
											key={page}
											active={currPage === page}
											onClick={handleSetPage(page)}
											disabled={isLoading || isFetching}
										>
											{page}
										</Pagination.Item>
									))}
									<Pagination.Next
										onClick={handleSetPage(currPage + 1)}
										disabled={isLoading || isFetching || currPage === lastPage}
									/>
									<Pagination.Last
										onClick={handleSetPage(lastPage)}
										disabled={isLoading || isFetching || currPage === lastPage}
									/>
								</>
							)}
						</Pagination>
					</Col>
				</Row>
			</StyledContent>
		</Card>
	);
};

export default OrderSearchList;
