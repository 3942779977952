import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

const CartItemSkeleton: React.FC = () => (
	<Row noGutters className="p-2">
		<Col className="col-auto mr-2">
			<Skeleton height="40px" width="30px" />
		</Col>
		<Col>
			<div className="mb-2">
				<Skeleton height="1.5rem" />
			</div>
			<Skeleton height="0.8rem" />
		</Col>
	</Row>
);

export default CartItemSkeleton;
