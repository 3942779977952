/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FieldArray,
	FormikProps,
} from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { List } from 'components';
import { FormikEffect, Input, Switch } from 'components/Formik';
import { useToast } from 'hooks';
import expeditionService from 'services/expedition.service';
import productService from 'services/product.service';
import { Expedition, ExpeditionStation, ProductSelect, UrlParams } from 'types';
import history from 'utils/history';

import {
	PageHeader,
	FormCancelButton,
	FormSubmitButton,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import { sortOptions } from './expedition/ExpeditionUtils';
import ProductsListAddStation from './expedition/ProductsListAddStation';
import SkeletonForm from './expedition/SkeletonForm';
import StationAccordion from './expedition/StationAccordion';
import { ExpeditionFormType } from './expedition/types';

const newStation = {
	idStation: null,
	name: 'Nova Estação',
	sort: '1',
	delete: false,
	products: [],
};

export const defaultFormValue: ExpeditionFormType = {
	idExpedition: null,
	name: '',
	station: [newStation],
	station_select: null,
	products: [],
	isActive: true,
	status: '',
};

export const formSchema = Yup.object<ExpeditionFormType>().shape({
	name: Yup.string().required('Campo obrigatório'),
	station: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Campo obrigatório'),
			sort: Yup.string(),
			products: Yup.array(),
		})
	),
	isActive: Yup.boolean(),
	status: Yup.string(),
});

const ListItem: React.FC<{ prod: ProductSelect }> = React.memo(({ prod }) => (
	<>
		<strong>{prod.idProduct}</strong>
		<span className="mx-1">-</span>
		<span>{prod.name}</span>
	</>
));

const MemoList: React.FC<{
	item: ExpeditionStation;
	index: number;
	form: FormikProps<Expedition>;
}> = React.memo(({ index, form }) => (
	<FieldArray name={`station[${index}].products`}>
		{(arrayHelper) => (
			<>
				<List
					data={`station[${index}].products`}
					emptyDescription="Selecione produtos ao lado para adicionar"
					onRemoveItem={(item, idx) => {
						const { values } = form;
						arrayHelper.remove(idx);
						const sorted = [...(values.products ?? []), item].sort((a, b) => {
							return Number(a.idProduct) - Number(b.idProduct);
						});
						form.setFieldValue('products', sorted);
					}}
					onChangeItems={(items) => {
						form.setFieldValue(`station[${index}].products`, items);
					}}
					dragInDrop
				>
					{(prod) => <ListItem prod={prod} />}
				</List>
			</>
		)}
	</FieldArray>
));

const StoreExpeditionEdit: React.FC = () => {
	const { id } = useParams<UrlParams>();
	const [expedition, setExpedition] = useState<Expedition>(defaultFormValue);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [toast] = useToast();

	useEffect(() => {
		productService
			.getForSelect({})
			.then((prod) => {
				const products = prod.data.map((p) => ({ ...p, visible: true }));

				if (id !== 'create') {
					if (products && products.length > 0) {
						expeditionService
							.getById(Number(id))
							.then((result) => {
								// eslint-disable-next-line no-param-reassign
								result.station = result.station.map(
									(station: ExpeditionStation) => {
										return {
											...station,
											delete: false,
											products: (station.products as string[]).map(
												(idProd: string) => {
													const index = products.findIndex(
														(p) => p.idProduct === idProd
													);
													const prodReturn = {
														idProduct: products[index].idProduct,
														name: products[index].name,
													};
													products.splice(index, 1);
													return prodReturn;
												}
											),
										};
									}
								);
								// eslint-disable-next-line no-param-reassign
								result.station = result.station.sort(
									(
										s: { sort: string | number },
										s2: { sort: string | number }
									) => (+s.sort < +s2.sort ? -1 : 1)
								);
								// eslint-disable-next-line no-param-reassign
								result = {
									...result,
									products,
									station_select: null,
								};
								setExpedition(result);
								setIsLoading(false);
							})
							.catch(() => {
								setIsLoading(false);
								toast('Não foi possível carregar os dados da expedição', {
									type: 'error',
								});
							});
					}
				} else {
					setExpedition({ ...expedition, products });
					setIsLoading(false);
				}
			})
			.catch(() => {
				setIsLoading(false);
				toast('Não foi possível carregar os dados da expedição', {
					type: 'error',
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, toast]);

	const handleSubmit = useCallback(
		async (values: FormikValues, { setSubmitting }) => {
			const submit = { ...values } as ExpeditionFormType;

			delete submit.products;
			delete submit.station_select;

			submit.station = submit.station.map((sta) => {
				const productsSorted = sortOptions(sta.products as ProductSelect[]);
				return {
					...sta,
					sort: String(sta.sort),
					products: productsSorted.map((p: ProductSelect) =>
						String(p.idProduct)
					),
				};
			});

			submit.station = sortOptions(submit.station);

			setSubmitting(true);

			expeditionService
				.createOrUpdate(submit.idExpedition, submit)
				.then(() => {
					toast(`Expedição ${id ? 'alterada' : 'criada'} com sucesso`, {
						type: 'success',
					});
					history.push('/admin/stores/expeditions');
				})
				.catch((e) => {
					toast(`Não foi possível editar a expedição. Cód: ${e.code}`, {
						type: 'error',
					});
				})
				.finally(() => setSubmitting(false));
		},
		[id, toast]
	);

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>
					{`${id !== 'create' ? 'Editar' : 'Nova'} Expedição`}
				</PageHeader.Title>
				<PageHeader.Breadcrumb
					items={[
						{ label: 'Loja', path: '/admin/stores/expeditions' },
						{ label: 'Expedição', path: '/admin/stores/expeditions' },
						{
							label: `${id !== 'create' ? 'Editar' : 'Nova'} Expedição`,
							active: true,
						},
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<SkeletonForm />
			) : (
				<Formik
					onSubmit={handleSubmit}
					initialValues={expedition}
					validationSchema={formSchema}
				>
					{(form) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Body>
									<Row>
										<Col lg={8}>
											<FormGroupTitle>
												Dados Cadastrais
												<Form.Group className="float-right d-inline">
													<Switch name="isActive" defaultChecked />{' '}
													<Form.Label className="text-muted">Ativo</Form.Label>
												</Form.Group>
											</FormGroupTitle>

											<Form.Row className="w-100">
												<Form.Group as={Col} md={12}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" type="text" />
												</Form.Group>
											</Form.Row>

											<FieldArray name="station">
												{(arrayHelper) => (
													<>
														<FormGroupTitle className="mt-3 d-flex align-items-center justify-content-between">
															Estações
															<Form.Group className="float-right d-inline">
																<Button
																	type="button"
																	variant="outline-info"
																	onClick={() => {
																		arrayHelper.push({
																			...newStation,
																			name: 'Nova Estação',
																			sort: form.values.station.length + 1,
																		});
																	}}
																>
																	<FAIcon icon="plus" className="mr-2" /> Nova
																	estação
																</Button>
															</Form.Group>
														</FormGroupTitle>

														<StationAccordion
															data="station"
															onRemoveItem={(station, idx) => {
																if (!station.idStation) {
																	arrayHelper.remove(idx);
																} else {
																	form.setFieldValue(`station.${idx}`, {
																		...station,
																		delete: true,
																	});
																}
																if (station.products.length > 0) {
																	const sorted = [
																		...(form.values.products ?? []),
																		...station.products,
																	].sort((a, b) => {
																		return (
																			Number((a as ProductSelect).idProduct) -
																			Number((b as ProductSelect).idProduct)
																		);
																	});
																	form.setFieldValue('products', sorted);
																}
																if (form.values.station.length === 1) {
																	arrayHelper.push(newStation);
																}
															}}
															onChangeItems={(items) => {
																form.setFieldValue('station', items);
															}}
														>
															{(item, index) => (
																<MemoList
																	item={item}
																	index={index}
																	form={form}
																/>
															)}
														</StationAccordion>
													</>
												)}
											</FieldArray>
										</Col>

										<Col lg={4} className="border-left">
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<ProductsListAddStation data="products" />
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Body>

								<Card.Footer className="text-right">
									<FormCancelButton
										isSubmitting={form.isSubmitting}
										to="/admin/stores/expeditions"
									/>
									<FormSubmitButton isSubmitting={form.isSubmitting} />
								</Card.Footer>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</Container>
	);
};

export default StoreExpeditionEdit;
