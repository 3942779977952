import React, {
	useCallback,
	useState,
	useRef,
	ChangeEvent,
	useEffect,
} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask, { InputState } from 'react-input-mask';

import { faCalendar, faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import Calendar from 'components/Calendar';
import { useOnClickOutside } from 'hooks';
import { REGEX_CLEAR_NUMERIC } from 'utils/Formatter/Regex';
import {
	dateFormatter,
	hourMinFormatter,
	dateHourMinFormatter,
} from 'utils/formatters';

import StyledOverlay from './dateTimeInput/StyledOverlay';
import { InputProps } from './Input';

export type DateTimePickerProps = Omit<InputProps, 'outputFormatted'> & {
	calendarSide?: 'left' | 'right';
	selectDate?: boolean;
	selectTime?: boolean;
	showPicker?: boolean;
	className?: string;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const defaultProps: DateTimePickerProps = {
	calendarSide: 'left',
	selectDate: false,
	selectTime: false,
	showPicker: true,
};

const useBeforeMaskedStateChange = (
	inputMask: string,
	selectDate?: boolean,
	selectTime?: boolean
) => {
	// Intercepta o input e ajusta os dígitos para não ficarem fora dos limites
	// de data e hora
	return useCallback(
		(newState: InputState, _: InputState, userInput: string): InputState => {
			if (userInput === null) {
				return newState;
			}

			const { value } = newState;

			let formatter = dateHourMinFormatter;

			if (!selectDate) {
				formatter = hourMinFormatter;
			} else if (!selectTime) {
				formatter = dateFormatter;
			}

			const numericValue = value.replace(REGEX_CLEAR_NUMERIC, '');

			// TODO:
			// Ainda é possível por data inválida pois o limite de dias é 31 para qualquer mês
			let formattedDate = formatter.formatForInput(numericValue)[1];
			let dateOffset = formattedDate.length - 1;

			while (dateOffset < inputMask.length && inputMask[dateOffset] !== '9') {
				formattedDate += inputMask[dateOffset];
				// eslint-disable-next-line no-plusplus
				dateOffset++;
			}

			const newSelection = {
				start: formattedDate.length,
				end: formattedDate.length,
			};

			return {
				selection: newSelection,
				value: formattedDate,
			};
		},
		[inputMask, selectDate, selectTime]
	);
};

const convertInputToISODate = (
	maskedDate: string,
	hasDate: boolean,
	hasTime: boolean
): string | null => {
	let numDate = String(maskedDate).replace(REGEX_CLEAR_NUMERIC, '').split('');
	let neededLength = 0;
	if (hasDate) {
		neededLength += 8;
	}
	if (hasTime) {
		neededLength += 4;
	}
	// Campo vazio:
	if (numDate.length === 0) {
		return '';
	}
	// Campo inválido:
	if (numDate.length !== neededLength) {
		return null;
	}

	const date = new Date();

	if (hasDate) {
		const day = Number(numDate[0] + numDate[1]);
		const month = Number(numDate[2] + numDate[3]);
		const year = Number(numDate[4] + numDate[5] + numDate[6] + numDate[7]);
		numDate = numDate.slice(8);

		date.setFullYear(year);
		date.setMonth(month - 1);
		date.setDate(day);
	}
	if (hasTime) {
		const hh = Number(numDate[0] + numDate[1]);
		const mm = Number(numDate[2] + numDate[3]);

		date.setHours(hh, mm);
	}
	return date.toISOString();
};

const getFormattedDate = (
	date: string,
	selectDate: boolean,
	selectTime: boolean
): string => {
	let formattedDate = '';
	if (date.length > 0) {
		let formatter = dateHourMinFormatter;
		if (!selectDate) {
			formatter = hourMinFormatter;
		} else if (!selectTime) {
			formatter = dateFormatter;
		}
		formattedDate = formatter.format(date) as string;
	}
	return formattedDate;
};

const DateTimeInput: React.FC<DateTimePickerProps> = ({
	calendarSide,
	selectDate,
	selectTime,
	showPicker,
	className,
	onChange,
	onBlur,
	value,
	error,
	...rest
}) => {
	const inputGroupRef = useRef(null);
	const popupRef = useRef<HTMLDivElement>(null);

	// Exibe/esconde popup
	const [isPickerVisible, setPickerVisible] = useState<boolean>(false);
	const togglePicker = useCallback(() => {
		setPickerVisible(!isPickerVisible);
	}, [isPickerVisible]);

	useOnClickOutside(popupRef, () => setPickerVisible(false));

	// Cache da máscara do input
	const [inputMask, setInputMask] = useState<string>('');

	// Valor do input com máscara
	const [inputValue, setInputValue] = useState<string>(
		getFormattedDate((value as string) || '', !!selectDate, !!selectTime)
	);

	// Atualiza máscara do input baseado nos parâmetros passados:
	useEffect(() => {
		let mask = '';
		if (selectDate) {
			mask = '99/99/9999';
		}
		if (selectTime) {
			if (mask.length > 0) {
				mask += ' ';
			}
			mask += '99:99';
		}
		setInputMask(mask);
	}, [selectDate, selectTime]);

	// Formata data e atualiza o input
	const updateInputFromDate = useCallback(
		(date: string) => {
			const formattedDate = getFormattedDate(date, !!selectDate, !!selectTime);
			setInputValue(formattedDate);
		},
		[selectTime, selectDate]
	);

	// Intercepta o input e ajusta os dígitos para não ficarem fora dos limites
	// de data e hora
	const beforeMaskedStateChange = useBeforeMaskedStateChange(
		inputMask,
		selectDate,
		selectTime
	);

	const onInputChange = (input: ChangeEvent<HTMLInputElement>) => {
		setInputValue(input.currentTarget.value);
	};

	// Verifica se o campo de data é válido e atuaiza o dateValue
	const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (isPickerVisible) {
			return;
		}
		onBlur?.(event);
		const isoDate = convertInputToISODate(
			inputValue,
			!!selectDate,
			!!selectTime
		);
		onChange?.(isoDate || '');
	};

	const onDateChange = (date: string) => {
		updateInputFromDate(date);
		onChange?.(date || '');
	};

	const onTimeChange = (date: string) => {
		updateInputFromDate(date);
		onChange?.(date || '');
	};

	return (
		<>
			<InputGroup className={className} ref={inputGroupRef}>
				{showPicker && calendarSide === 'left' && (
					<InputGroup.Prepend>
						<Button variant="outline-primary" onClick={togglePicker}>
							<FAIcon icon={selectDate ? faCalendar : faClock} />
						</Button>
					</InputGroup.Prepend>
				)}
				<Form.Control
					as={InputMask}
					value={inputValue}
					onChange={onInputChange}
					onBlur={onInputBlur}
					beforeMaskedValueChange={beforeMaskedStateChange}
					mask={inputMask}
					maskChar="_"
					readOnly={isPickerVisible}
					isInvalid={!!error}
					className="rounded-right"
					{...rest}
				/>
				{error && (
					<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
				)}
				{showPicker && (
					<StyledOverlay
						overlayProps={{
							show: isPickerVisible,
							container: inputGroupRef,
							target: inputGroupRef,
							placement:
								calendarSide === 'left' ? 'bottom-start' : 'bottom-end',
						}}
					>
						<div ref={popupRef} className="pt-1 shadow-lg bg-white">
							<Calendar
								showDate={selectDate}
								showTime={selectTime}
								value={value as string}
								onDateChange={onDateChange}
								onTimeChange={onTimeChange}
							/>
						</div>
					</StyledOverlay>
				)}
			</InputGroup>
		</>
	);
};

DateTimeInput.displayName = 'DateTimeInput';
DateTimeInput.defaultProps = defaultProps;
DateTimeInput.whyDidYouRender = true;

export default DateTimeInput;
