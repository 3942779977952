import useSessionContext from 'hooks/useSessionContext';

import useEcommerceBasePath from './useEcommerceBasePath';

const useShoppingCartName = () => {
	const { auth } = useSessionContext();
	const storeName = useEcommerceBasePath();

	return `${storeName}_${auth.person?.idUser}`;
};

export default useShoppingCartName;
