import { Link } from 'react-router-dom';

import BsNavbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';

export const BaseNav = styled(BsNavbar)`
	--navbar-bg: var(--always-white);
	--navbar-zindex: 100;

	padding: 0 22px;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: var(--navbar-height);
	z-index: var(--navbar-zindex);
	box-shadow: var(--base-box-shadow);
	background-color: var(--navbar-bg);
`;

export const LinkStyled = styled(Link)`
	padding: 0 1rem;
	color: rgba(0, 0, 0, 0.6);

	svg {
		font-size: 1.6rem;
	}

	&:hover {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.8);
	}

	&:last-child {
		padding-right: 0;
	}
`;

export const DropdownStyled = styled.div`
	padding: 0 0.5rem;
	color: rgba(0, 0, 0, 0.6);
	border-left: 1px solid var(--gray-20);
	border-right: 1px solid var(--gray-20);

	&:hover {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.8);
	}

	&:first-child {
		border: none;
	}
`;
