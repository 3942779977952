import styled from 'styled-components';

export const Container = styled.div`
	.input-group-text {
		background-color: var(--always-white);
		color: var(--gray-50);
	}
`;

export const ProductItem = styled.div`
	border: 1px solid var(--gray-20);
	padding: 10px;
	border-radius: var(--base-border-radius);
	box-shadow: var(--base-box-shadow);

	strong {
		border-right: 1px solid var(--gray-20);
		margin-right: 10px;
		padding-right: 10px;
	}
`;
