import Access from './routes/access.routes';
import Communication from './routes/communication.routes';
import Executive from './routes/executive.routes';
import Financial from './routes/financial.routes';
import MMN from './routes/mmn.routes';
import Order from './routes/order.routes';
import Store from './routes/store.routes';
import System from './routes/system.routes';
import Ticket from './routes/ticket.routes';
import { ListSceneConfig } from './types';

export default {
	...Access,
	...Communication,
	...Executive,
	...Order,
	...Financial,
	...Store,
	...System,
	...Ticket,
	...MMN,
} as { [name: string]: ListSceneConfig };
