import React from 'react';

import CheckInput, { CheckInputProps } from './CheckInput';

export type RadioProps = CheckInputProps;

const Radio: React.FC<RadioProps> = (props) => (
	<CheckInput {...props} type="radio" />
);

Radio.displayName = 'Radio';
Radio.whyDidYouRender = true;

export default Radio;
