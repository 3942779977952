import React, { useCallback } from 'react';

import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from 'formik';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Button, Card } from 'components';
import { ColorVariant } from 'components/Background';
import { FormikEffect, Input } from 'components/Formik';
import { useModal, useToast } from 'hooks';
import financialService from 'services/financial.service';
import {
	HttpBaseResult,
	StatementApprovalEntry,
	StatementSetApprovalEntry,
} from 'types';
import {
	currencyFormatter,
	dateFormatter,
	numberFormatter,
} from 'utils/formatters';

import { PageHeader } from 'packages/admin/components';
import UserDetailsModal from 'packages/escritorio/pages/distributor/order/history/modal/ExecutiveDetailsModal';

import SkeletonForm from './statementApproval/SkeletonForm';
import {
	ContentBox,
	ExpandScrollBox,
	IconButton,
	Statement,
	StatusWrapper,
	Text,
} from './statementApproval/styles';
import { StatementApprovalFormType } from './statementApproval/types';

enum ApprovalStatus {
	Pending = 1,
	Approved = 2,
	Rejected = 3,
}

const ApprovalStatusMap: {
	[key: number]: { text: string; variant?: ColorVariant };
} = {
	1: { text: 'Pendente' },
	2: { text: 'Aprovado', variant: 'success' },
	3: { text: 'Reprovado', variant: 'danger' },
};

const formSchema = Yup.object({
	approvals: Yup.array()
		.of(
			Yup.object({
				idPayment: Yup.number(),
				idStatus: Yup.number(),
				observation: Yup.string().when('idStatus', {
					is: (idStatus?: number) => idStatus === ApprovalStatus.Rejected,
					then: Yup.string()
						.nullable()
						.required('Campo obrigatório')
						.max(150, 'Máximo 150 caracteres'),
					otherwise: Yup.string().nullable().notRequired(),
				}),
			})
		)
		.required(),
});

const StatementApproval: React.FC = () => {
	const [toast] = useToast();
	const open = useModal();

	const { data, isLoading, isFetching, refetch } = useQuery<
		StatementApprovalEntry[]
	>(
		'statement_approval',
		() =>
			financialService
				.getApprovalEntries({ pagination: { sort: '-date' } })
				.then((d) => d.data),
		{
			refetchOnMount: true,
			cacheTime: 0,
			onError: () => {
				toast('Ocorreu um erro ao obter os lançamentos', {
					type: 'error',
				});
			},
		}
	);

	const handleSubmit = useCallback(
		async <T extends StatementApprovalFormType>(
			{ approvals }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const submit: StatementSetApprovalEntry = {
				approvals:
					approvals
						?.filter((d) => d.idStatus !== ApprovalStatus.Pending)
						.map(({ idPayment, idStatus, observation }) => ({
							idPayment,
							idStatus,
							...(observation && { observation }),
						})) ?? [],
			};

			try {
				await financialService.setApprovalEntries(submit);
				toast(`Lançamentos concluídos com sucesso`, {
					type: 'success',
				});

				// Recarregar registros
				refetch();
			} catch (e) {
				const { code } = e as HttpBaseResult;
				toast(
					`Ocorreu um erro ao concluir os lançamentos.${
						code && ` Cód: ${code}`
					}`,
					{ type: 'error' }
				);
			}
			setSubmitting(false);
		},
		[toast, refetch]
	);

	const handleSubmitError = useCallback(() => {
		toast('Existem campos não preenchidos corretamente', {
			type: 'error',
		});
	}, [toast]);

	const handleApprove = useCallback(
		(idx: number, form: FormikProps<StatementApprovalFormType>) => () => {
			form.setFieldValue(`approvals[${idx}].idStatus`, ApprovalStatus.Approved);
			form.setFieldValue(`approvals[${idx}].observation`, null);
		},
		[]
	);

	const handleReject = useCallback(
		(idx: number, form: FormikProps<StatementApprovalFormType>) => () => {
			form.setFieldValue(`approvals[${idx}].idStatus`, ApprovalStatus.Rejected);

			// TODO: Fazer timeout com foco no campo Motivo?
		},
		[]
	);

	const handleCancel = useCallback(
		(idx: number, form: FormikProps<StatementApprovalFormType>) => () => {
			form.setFieldValue(`approvals[${idx}].idStatus`, ApprovalStatus.Pending);
			form.setFieldValue(`approvals[${idx}].observation`, null);
		},
		[]
	);

	const getPendingApprovals = useCallback(
		(form: FormikProps<StatementApprovalFormType>) =>
			form.values.approvals?.filter(
				(d) => d.idStatus === ApprovalStatus.Pending
			),
		[]
	);

	return (
		<ContentBox>
			<Container fluid className="d-flex flex-column h-100">
				<PageHeader className="d-flex justify-content-between">
					<PageHeader.Title>Aprovação de Lançamentos</PageHeader.Title>
					<PageHeader.Breadcrumb
						items={[
							{
								label: 'Financeiro',
								path: '/admin/financial/statement/approval',
							},
							{ label: 'Aprovação de Lançamentos', active: true },
						]}
					/>
				</PageHeader>
				{isLoading || isFetching ? (
					<SkeletonForm />
				) : (
					<Row className="h-100">
						<Formik
							onSubmit={(values, helpers) => handleSubmit(values, helpers)}
							initialValues={{ approvals: data! }}
							validationSchema={formSchema}
							validateOnChange={false}
							validateOnBlur={true}
						>
							{(form: FormikProps<StatementApprovalFormType>) => (
								<FormikForm className="w-100">
									<FormikEffect
										onSubmitError={handleSubmitError}
										focusOnError
										promptOnExit
									/>

									<Container fluid className="d-flex flex-column h-100">
										<ExpandScrollBox>
											<Container fluid className="d-flex flex-column">
												{form.values.approvals.map((statement, idx) => (
													<Statement key={statement.idPayment}>
														<Statement.Body>
															<Row>
																<Col>
																	<Row className="mb-0 pb-0">
																		<Col xs={6} lg={2} className="my-1">
																			<span className="text-nowrap">
																				<FAIcon icon={faCalendar} />{' '}
																				{dateFormatter.format(statement.date)}
																			</span>
																		</Col>
																		<Col xs={6} lg={5} className="my-1">
																			<span
																				role="button"
																				tabIndex={0}
																				aria-hidden="true"
																				onClick={() =>
																					open(UserDetailsModal, {
																						row: { id: statement.id },
																						config: {
																							showContactOnly: false,
																						},
																					})
																				}
																			>
																				<strong>Executivo: </strong>
																				<span className="text-nowrap">
																					{`${statement.id} - ${statement.user}`}
																				</span>
																			</span>
																		</Col>
																		<Col xs={6} lg={3} className="my-1">
																			<span>
																				<strong>Operador: </strong>
																				<span className="text-nowrap">
																					{statement.operator}
																				</span>
																			</span>
																		</Col>
																		{statement.number && (
																			<Col
																				xs={6}
																				lg={2}
																				className="my-1 text-right"
																			>
																				<span className="text-nowrap">
																					<strong>#</strong>
																					{numberFormatter.format(
																						statement.number
																					)}
																				</span>
																			</Col>
																		)}
																	</Row>
																	<Row className="border-top">
																		<Col className="mt-3 d-flex justify-content-between">
																			<span>{statement.description}</span>
																		</Col>
																	</Row>
																</Col>
																<Col xs="auto">
																	<Row className="h-100">
																		<Col className="px-xs-0 px-md-3 px-xl-5 d-flex align-items-center justify-content-center">
																			<Text
																				size={1.4}
																				weight={500}
																				className={`text-nowrap text-${
																					Number(statement.value) > 0
																						? 'success'
																						: 'danger'
																				}`}
																			>
																				R${' '}
																				{currencyFormatter.format(
																					statement.value
																				)}
																			</Text>
																		</Col>
																		<Col
																			sm="auto"
																			className="pr-3 pl-0 d-flex align-items-center justify-content-end"
																		>
																			{statement.idStatus ===
																			ApprovalStatus.Pending ? (
																				<>
																					<IconButton
																						variant="danger"
																						onClick={handleReject(idx, form)}
																						disabled={form.isSubmitting}
																					>
																						<FAIcon icon={faTimes} />
																					</IconButton>
																					<IconButton
																						variant="success"
																						className="ml-2"
																						onClick={handleApprove(idx, form)}
																						disabled={form.isSubmitting}
																					>
																						<FAIcon icon={faCheck} />
																					</IconButton>
																				</>
																			) : (
																				<StatusWrapper>
																					<FAIcon
																						icon={
																							statement.idStatus ===
																							ApprovalStatus.Approved
																								? faCheck
																								: faTimes
																						}
																						className={`text-${
																							ApprovalStatusMap[
																								statement.idStatus
																							].variant ?? ''
																						}`}
																						style={{
																							fontSize: 'xx-large',
																						}}
																					/>
																					<div className="w-100 position-relative">
																						<div className="w-100 position-absolute d-flex align-items-center justify-content-center">
																							<span
																								role="button"
																								tabIndex={0}
																								aria-hidden="true"
																								onClick={handleCancel(
																									idx,
																									form
																								)}
																							>
																								Desfazer
																							</span>
																						</div>
																					</div>
																				</StatusWrapper>
																			)}
																		</Col>
																	</Row>
																</Col>
															</Row>
														</Statement.Body>
														<Statement.Footer
															show={
																statement.idStatus === ApprovalStatus.Rejected
															}
															maxHeight={6}
														>
															<Statement.Footer.Pad>
																<span className="mt-2">Motivo: </span>
																<Input name={`approvals[${idx}].observation`} />
															</Statement.Footer.Pad>
														</Statement.Footer>
													</Statement>
												))}
											</Container>
										</ExpandScrollBox>

										<Card className="mt-1">
											<Card.Header
												separator
												justifyContent="space-between"
												alignItems="center"
											>
												<Text size={1.1} weight={500}>
													{getPendingApprovals(form)?.length
														? `Lançamentos pendentes: ${
																getPendingApprovals(form)?.length
														  }`
														: 'Nenhum lançamento pendente'}
												</Text>
												<Button
													type="submit"
													variant="secondary"
													className="border"
													disabled={
														form.isSubmitting ||
														getPendingApprovals(form)?.length === data?.length
													}
												>
													{form.isSubmitting ? (
														<>
															<Spinner
																size="sm"
																animation="border"
																role="status"
															/>
															<span className="ml-2">Aguarde...</span>
														</>
													) : (
														'Concluir Aprovados/Reprovados'
													)}
												</Button>
											</Card.Header>
										</Card>
									</Container>
								</FormikForm>
							)}
						</Formik>
					</Row>
				)}
			</Container>
		</ContentBox>
	);
};

export default StatementApproval;
