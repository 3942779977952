import ButtonLink from './ButtonLink';
import CEP from './CEP';
import CNAE from './CNAE';
import CNPJ from './CNPJ';
import Status from './ColoredStatus';
import CPF from './CPF';
import CPFCNPJ from './CPFCNPJ';
import Custom from './Custom';
import _Date from './Date';
import Default from './Default';
import EditLink from './EditLink';
import _Number from './Number';
import Phone from './Phone';
import Regex from './Regex';

/**
 * Melhor retornar a class do que um objeto pois podemos
 * passar parâmetros diferentes para o mesmo formatter de acordo com a necessidade,
 * por exemplo DateFormatter(location.locales[0])
 */
export default {
	Default,
	Number: _Number,
	Date: _Date,
	Regex,
	Phone,
	CEP,
	CPF,
	CNPJ,
	CPFCNPJ,
	CNAE,
	EditLink,
	ButtonLink,
	ColoredStatus: Status,
	Custom,
};
