import styled, { keyframes } from 'styled-components';

import { StyledModalHeader } from './modalHeader/styles';
import { BackdropProps, ModalDialogProps, ModalTitleProps } from './types';

// Valores das propriedades
export const modalSizes = {
	xm: '30vw',
	sm: '40vw',
	md: '50vw',
	lg: '70vw',
	xl: '90vw',
	fit: 'fit-content',
	max: 'max-content',
	min: 'min-content',
	auto: 'auto',
};

export const modalVariants = {
	info: 'teal',
	success: 'green',
	warning: 'orange',
	danger: 'red',
};

// Transitions (globalizar?)
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const slideDown = keyframes`
  from {
    transform: translate(0, -50px);
    opacity: 0;
  }

  to {
    transform: translate(0, 0px);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translate(0, 0px);
    opacity: 1;
  }

  to {
    transform: translate(0, -50px);
    opacity: 0;
  }
`;

// Styled Components
export const ModalTitle = styled.div`
	font-size: 1.2rem;
	width: 100%;

	white-space: pre-line;
	text-align: ${({ align }: ModalTitleProps) => align || 'start'};

	@media (max-width: 390px) {
		font-size: 1rem;
	}
`;

export const ModalBody = styled.div`
	padding: 1rem;
	overflow-x: auto;
`;

type ModalFooterProps = {
	align?: 'start' | 'center' | 'end';
};

const footerAlignMap = {
	start: 'flex-start',
	center: 'center',
	end: 'flex-end',
};

export const ModalFooter = styled.div`
	padding: 1rem;
	min-width: min-content;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: ${({ align = 'start' }: ModalFooterProps) =>
		footerAlignMap[align]};

	> :not(:first-child) {
		margin-left: 1rem;
	}
`;

export const Backdrop = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;

	left: 0;
	top: 0;
	z-index: var(--modal-backdrop-zindex);

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: rgba(0, 0, 0, 0.2);
	overflow-y: scroll;

	opacity: ${(props: BackdropProps) => (!props.show ? '0' : '1')};
	animation: ${(props: BackdropProps) => (!props.show ? fadeOut : fadeIn)} 0.3s
		alternate;

	::-webkit-scrollbar {
		display: none;
	}

	/* Desativar o funcionamento da barra de rolagem do body */
	overscroll-behavior: none;
	::before {
		content: '';
		height: calc(100% + 1px);
	}
`;

export const ModalDialog = styled.dialog`
	position: relative;
	margin: auto;
	padding: 0;

	z-index: var(--modal-zindex);
	display: initial;

	background: var(--base-bg);
	border: none;
	border-radius: var(--border-radius-lg);
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);

	/* Efeito abrir/fechar */
	animation: ${(props: ModalDialogProps) => (!props.show ? slideUp : slideDown)}
		0.3s alternate;

	/* Tamanhos */
	width: ${({ size }: ModalDialogProps) => modalSizes[size!]};
	min-width: min-content;

	@media (max-width: 575px) {
		min-width: calc(100vw - 2rem);
	}

	@media (min-width: 576px) {
		max-width: 540px;
	}

	@media (min-width: 768px) {
		max-width: 720px;
	}

	@media (min-width: 992px) {
		max-width: 960px;
	}

	@media (min-width: 1200px) {
		max-width: 1140px;
	}

	/* Bordas de cima */
	> div:first-child {
		border-radius: calc(var(--border-radius-lg) - 1px);
	}

	> div:nth-child(2) {
		border-radius: calc(var(--border-radius-lg) - 1px)
			calc(var(--border-radius-lg) - 1px) 0 0;
	}

	/* Bordas de baixo */
	> div:last-child {
		border-radius: 0 0 calc(var(--border-radius-lg) - 1px)
			calc(var(--border-radius-lg) - 1px);
	}

	/* Único filho */
	> div:only-child {
		border-radius: calc(var(--border-radius-lg) - 1px)
			calc(var(--border-radius-lg) - 1px);
	}

	/* Separador */
	> :not(:first-child) {
		border-top: 1px solid var(--border-color);
	}

	/* Children adjustments */
	> ${ModalBody}, ${StyledModalHeader} {
		/* Gradient filter */
		${({ variant }: ModalDialogProps) =>
			!!variant &&
			`
      * {
        color: var(--always-white);
      }
      color: var(--always-white);
      border: none;
    `}
	}
`;

ModalDialog.defaultProps = {
	size: 'sm',
};
