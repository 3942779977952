import { combineReducers } from 'redux';

import ecommerce from './ecommerce';
import { EcommerceState } from './ecommerce/types';
import prefs from './prefs';
import { PrefsState } from './prefs/types';
import session from './session';
import { SessionState } from './session/types';
import shoppingCart from './shoppingCart';
import { ShoppingCartState } from './shoppingCart/types';

export type RootState = {
	session: SessionState;
	prefs: PrefsState;
	shoppingCart: ShoppingCartState;
	ecommerce: EcommerceState;
};

const rootReducer = combineReducers({
	session,
	prefs,
	shoppingCart,
	ecommerce,
});

export default rootReducer;
