import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import { useQuery } from 'react-query';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import Select from 'components/Select';
import expeditionService from 'services/expedition.service';
import { ExpeditionList } from 'types';

import PageHeader from 'packages/admin/components/PageHeader';

import InSeparationDatagrid from './expedition/InSeparationDatagrid';
import WaitingSeparationDatagrid from './expedition/WaitingSeparationDatagrid';

const ForceRefreshGrid: React.FC<{ when: number }> = ({ when }) => {
	const { forceUpdate } = useDatagridContext();
	useEffect(() => {
		forceUpdate();
	}, [forceUpdate, when]);

	return <></>;
};

const ExpeditionPage: React.FC = () => {
	const [expedition, setExpedition] = useState<ExpeditionList | null>(null);
	const { isLoading, data } = useQuery('expedition_select', () =>
		expeditionService.getExpeditions({})
	);

	// FIXME:
	// Comunicação entre componentes filhos?
	// Quando os pedidos em espera tem status atualizado, é necessário recarregar
	// o grid de pedidos em separação tbm...
	const [reload, setReload] = useState<number>(0);

	// FIXME:
	// w-25 no select de expedição deve ser trocado para se ajustar a largura da tela

	return (
		<Container fluid className="d-flex flex-column flex-grow-1">
			<PageHeader>
				<PageHeader.Title>Expedição</PageHeader.Title>
				<FormGroup className="m-0 w-25">
					<Select
						placeholder="Selecione uma expedição"
						options={data?.data || []}
						isLoading={isLoading}
						onChange={setExpedition}
					/>
				</FormGroup>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[{ label: 'Expedição', path: '/admin/expedition' }]}
				/>
			</PageHeader>
			<Row className="flex-grow-1">
				<Col lg={6} className="d-flex">
					<Card className="shadow p-2 mb-2 flex-grow-1">
						{!!expedition && (
							<WaitingSeparationDatagrid
								idExpedition={expedition.idExpedition}
								onReload={() => setReload(reload + 1)}
							/>
						)}
					</Card>
				</Col>
				<Col lg={6} className="d-flex">
					<Card className="shadow p-2 mb-2 flex-grow-1">
						{!!expedition && (
							<InSeparationDatagrid idExpedition={expedition.idExpedition}>
								<ForceRefreshGrid when={reload} />
							</InSeparationDatagrid>
						)}
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ExpeditionPage;
