import React, { useCallback, useEffect, useState } from 'react';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FormikProps,
	FormikHelpers,
} from 'formik';
import { Button, Col, Form } from 'react-bootstrap';

import * as Yup from 'yup';

import AddressForm, {
	formSchema as addressFormSchema,
	defaultFormValue as defaultAddressFormValue,
} from 'components/AddressForm';
import { Input } from 'components/Formik';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import { useToast } from 'hooks';
import myService from 'services/my.service';
import { ApiCodes, HttpBaseResult, PersonAddress } from 'types';

import { FormSubmitButton } from 'packages/admin/components';

type AddressFormModalProps = {
	data: PersonAddress;
	style?: React.CSSProperties;
	className?: string;
};

export interface AddressFormType {
	name: string;
	address: PersonAddress;
}

export const defaultFormValue: AddressFormType = {
	name: '',
	address: defaultAddressFormValue,
};

export const formSchema = Yup.object<AddressFormType>().shape({
	name: Yup.string().required('Campo obrigatório'),
	address: addressFormSchema,
});

const AddressFormModal: React.FC<
	ModalForwardProps<AddressFormModalProps, boolean>
> = ({ data, modalRef, ...rest }) => {
	const [dataAddress, setDataAddress] = useState<AddressFormType>(
		defaultFormValue
	);
	const [toast] = useToast();

	useEffect(() => {
		if (data) {
			setDataAddress({ address: data, name: data.name });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = useCallback(
		async (values: FormikValues, helpers: FormikHelpers<AddressFormType>) => {
			const dataForm = { ...values } as AddressFormType;
			const submit = {
				...dataForm.address,
				name: dataForm.name,
				isBilling: false,
			};

			try {
				const { message } = await myService.createOrUpdateAddress(
					submit.idAddress,
					submit
				);
				// eslint-disable-next-line no-console
				console.log('Success: ', message);
				modalRef.dismiss(true);
				toast(
					`Endereço ${submit.idAddress ? 'alterado' : 'criado'} com sucesso`,
					{
						type: 'success',
					}
				);
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			}

			helpers.setSubmitting(false);
		},
		[modalRef, toast]
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="m-0">Editar Endereço</Modal.Title>
			</Modal.Header>

			<Formik
				onSubmit={(value, helpers) => handleSubmit(value, helpers)}
				initialValues={dataAddress}
				validationSchema={formSchema}
				enableReinitialize
			>
				{(form: FormikProps<AddressFormType>) => (
					<FormikForm>
						<Modal.Body>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label>Identificação</Form.Label>
									<Input type="text" name="name" disabled={form.isSubmitting} />
									<Form.Text className="text-muted">
										Por exemplo: Casa, Trabalho
									</Form.Text>
								</Form.Group>
							</Form.Row>

							<AddressForm path="address" isLoading={form.isSubmitting} />
						</Modal.Body>
						<Modal.Footer align="end">
							<Button
								variant="outline-primary"
								onClick={() => modalRef.dismiss(false)}
							>
								Cancelar
							</Button>
							<FormSubmitButton isSubmitting={form.isSubmitting} />
						</Modal.Footer>
					</FormikForm>
				)}
			</Formik>
		</Modal>
	);
};

export default AddressFormModal;
