import React, { useState, useCallback, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import useMediaQuery from '@restart/hooks/useMediaQuery';

import SideBar from 'components/SideBar';
import { useRouteValidator } from 'hooks';
import companyService from 'services/company.service';
import { Company } from 'types';
import history from 'utils/history';

import NavBar from 'packages/admin/components/NavBar';

import AdminRoutes from 'packages/admin/admin.routes';

import { AdminContextType } from '../types/admin.types';

import AdminContext from './mainRouter/AdminContext';
import MainRouterSkeleton from './mainRouter/Skeleton';
import { ContentPage } from './mainRouter/styles';

const MainRouter: React.FC = () => {
	const [menuOpened, setMenuOpened] = useState<boolean>(false);
	const [isSubmenuActive, setIsSubmenuActive] = useState<boolean>(false);
	const isMobile = useMediaQuery('max-width: 769px');
	const [contextValue, setContextValue] = useState<AdminContextType>({
		companies: [],
	});
	const [filteredRoutes, flatRoutes] = useRouteValidator(AdminRoutes);

	const openLeftMenu = useCallback(() => {
		setMenuOpened(!menuOpened);
	}, [menuOpened]);

	const handleActiveSubmenu = useCallback((active: boolean) => {
		setIsSubmenuActive(active);
	}, []);

	const handleMenuClose = useCallback(() => {
		setMenuOpened(false);
	}, []);

	const loadCompanies = useCallback(async () => {
		const companies = await companyService
			.getForList({})
			.then((result) => result.data.filter((c) => c.type === 'Matriz'))
			.catch(() => []);

		setContextValue({ companies: companies as Company[] });
	}, []);

	useEffect(() => {
		loadCompanies();
	}, [loadCompanies]);

	if (!contextValue.companies.length) {
		return <MainRouterSkeleton />;
	}

	return (
		<AdminContext.Provider value={contextValue}>
			<NavBar
				openLeftMenuCallBack={openLeftMenu}
				isMobile={isMobile}
				menuIsOpen={menuOpened}
			/>
			<SideBar
				items={filteredRoutes}
				isMobile={isMobile}
				menuOpened={menuOpened}
				isSubmenuActive={isSubmenuActive}
				onActiveSubmenu={handleActiveSubmenu}
				onMenuClose={handleMenuClose}
			/>

			<ContentPage
				isSubmenuActive={isSubmenuActive}
				isMobile={isMobile}
				className="content-page"
			>
				<Router history={history}>
					<Switch>
						{flatRoutes.map((params) => (
							<Route key={params.path} {...params} />
						))}
					</Switch>
				</Router>
			</ContentPage>
		</AdminContext.Provider>
	);
};

MainRouter.displayName = 'MainRouter';
MainRouter.whyDidYouRender = true;

export default MainRouter;
