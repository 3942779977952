import styled from 'styled-components';

export const StyledFrame = styled.iframe`
	width: calc(100% + 1px); // fix pixel rounding
	height: 45vw;
	min-width: 300px;
	min-height: 200px;
	max-height: 90vh;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: calc(var(--border-radius-lg) - 1px);
`;
