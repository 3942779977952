/* eslint-disable @typescript-eslint/no-explicit-any */

const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const sortOptions = (optionsItems: any[]) => {
	if (!optionsItems) {
		return [];
	}
	const result = optionsItems.map((item: any, idx: number) => ({
		...item,
		sort: idx + 1,
	}));

	return result;
};

export { reorder, sortOptions };
