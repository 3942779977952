import { GridWidgetsStyled, GridWidgetItemStyled } from './featured/styles';

type FeaturedComponent = typeof GridWidgetsStyled & {
	Item: typeof GridWidgetItemStyled;
};

const Featured: FeaturedComponent = GridWidgetsStyled as FeaturedComponent;

Featured.displayName = 'Featured';
(Featured.whyDidYouRender as boolean) = true;
Featured.Item = GridWidgetItemStyled;

export default Featured;
