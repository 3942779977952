import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import {
	faFacebookF,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const Links = [
	{
		href: 'https://web.facebook.com/AmakhaCosmeticosOficial/',
		icon: faFacebookF,
	},
	{ href: 'https://www.instagram.com/amakhaoficial/', icon: faInstagram },
	{
		href: 'https://www.youtube.com/channel/UCQ3KYXyvSwiJfWsX9_KDelQ',
		icon: faYoutube,
	},
];

const BtnLink = styled.a`
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
`;

type LoginHeaderProps = {
	rounded?: boolean;
};

const LoginHeader: FunctionComponent<LoginHeaderProps> = ({ rounded }) => {
	return (
		<>
			{Links.map((link, index: number) => (
				<BtnLink
					key={link.href}
					href={link.href}
					target="_blank"
					rel="external noreferrer noopener"
					className={classnames(
						'btn btn-primary justify-content-center',
						rounded && 'rounded-circle',
						index < Links.length - 1 && 'mr-2'
					)}
				>
					<FAIcon icon={link.icon} />
				</BtnLink>
			))}
		</>
	);
};

export default LoginHeader;
