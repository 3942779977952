import React, { useCallback, useMemo, useState } from 'react';

import { Formik, FormikHelpers, Form as FormikForm, FormikProps } from 'formik';
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import Decimal from 'decimal.js';
import * as Yup from 'yup';

import { Button, Modal } from 'components';
import { AsyncSelect, DateTimeInput, Input, Select } from 'components/Formik';
import { ModalForwardProps } from 'components/ModalContainer';
import { useToast } from 'hooks';
import companyService from 'services/company.service';
import financialService from 'services/financial.service';
import orderService from 'services/order.service';
import {
	Company,
	CompanyAccount,
	Order,
	OrderPartialPayment,
	OrderPaymentConsts,
} from 'types';
import {
	currencyFormatter,
	currencyWithSymbolFormatter,
	dateFormatter,
	numberFormatter,
} from 'utils/formatters';

import { FormGroupTitle } from 'packages/admin/components';

type OrderPaymentModalProps = {
	order: Order;
	selectedCompany: Company;
};

type PaymentOption = {
	name: string;
	value: OrderPaymentConsts;
};

type OrderPaymentFormType = {
	payType?: PaymentOption | null;
	account?: CompanyAccount | null;
	date?: string | null;
	value?: number | null;
};

const defaultFormValue: OrderPaymentFormType = {
	payType: null,
	account: null,
	date: null,
	value: null,
};

const paymentOptions = [
	{ name: 'Depósito', value: OrderPaymentConsts.WireTransfer },
];

const OrderPaymentModal: React.FC<
	ModalForwardProps<OrderPaymentModalProps, boolean>
> = ({ order, selectedCompany, modalRef, ...rest }) => {
	const [toast] = useToast();
	const [newPayments, setNewPayments] = useState<
		Partial<OrderPartialPayment>[]
	>([]);

	const { data: partialPayments, isLoading: isLoadingPayments } = useQuery<
		OrderPartialPayment[]
	>(
		['order_partial_payments'],
		() => orderService.getPartialPayments(order.idOrder),
		{
			onError: () => {
				toast('Ocorreu um erro ao obter os pagamentos do pedido', {
					type: 'error',
				});
			},
		}
	);

	const { data: userCredit, isLoading: isLoadingUserCredit } = useQuery(
		['order_user_credit'],
		() => financialService.getBalanceByExecutiveId(order.idPerson),
		{
			enabled: order.idPerson !== undefined,
			onError: () => {
				toast('Ocorreu um erro ao obter o crédito do usuário', {
					type: 'error',
				});
			},
		}
	);

	const pendingValue = useMemo(() => {
		let sum = new Decimal(0);
		partialPayments?.forEach((p) => {
			sum = sum.add(p.value);
		});
		newPayments?.forEach((p) => {
			sum = sum.add(p.value ?? 0);
		});

		return new Decimal(order.total).sub(sum).toDecimalPlaces(2).toNumber();
	}, [newPayments, order.total, partialPayments]);

	// A validação precisa ser atualizada de acordo com o valor pendente
	const validationSchema = useMemo(() => {
		const min = 0.01;
		const max = pendingValue;

		return Yup.object({
			payType: Yup.object().nullable(),
			account: Yup.object().when('payType', {
				is: (payType: PaymentOption) =>
					payType?.value === OrderPaymentConsts.WireTransfer,
				then: Yup.object().nullable().required('Campo obrigatório'),
				otherwise: Yup.object().nullable(),
			}),
			date: Yup.string().nullable(),
			value: Yup.number()
				.nullable()
				.min(
					min,
					`Valor deve ser maior que ${currencyFormatter.format(min - 0.01)}`
				)
				.max(
					max,
					`Valor não pode ser maior que ${currencyFormatter.format(max)}`
				)
				.required('Campo obrigatório'),
		});
	}, [pendingValue]);

	// Handlers

	const handleSubmit = useCallback(
		async <T extends OrderPaymentFormType>(
			data: T,
			{ setSubmitting, resetForm }: FormikHelpers<T>
		) => {
			setNewPayments([
				...newPayments,
				{
					value: String(data.value!),
					idPayment: String(data.payType!.value),
					name: data.payType!.name,
					dateAccount: data.date, // TODO: Verificar com o Backend se não é date no lugar de dateAccount
					idAccount: String(data.account!.idAccount),
					idApprover: null, // TODO: Verificar o que passar
				},
			]);

			resetForm();
			setSubmitting(false);
		},
		[newPayments]
	);

	const handleChangePayType = useCallback(
		(form?: FormikProps<OrderPaymentFormType>) => (
			paymentType: PaymentOption
		) => {
			// TODO: Verificar o que fazer aqui com o userCredit
			let value: number = pendingValue;
			if (paymentType?.value === OrderPaymentConsts.Balance) {
				value = Math.min(Number(userCredit), pendingValue);
			}
			// TODO: Finalizar
			console.log({ value, form });
		},
		[pendingValue, userCredit]
	);

	const handleRemoveItem = useCallback(
		(index: number) => {
			setNewPayments(newPayments.filter((_, idx) => idx !== index));
		},
		[newPayments]
	);

	const renderPaymentCols = useCallback(
		(payment: Partial<OrderPartialPayment>) => (
			<>
				<td className="text-left border-0">{payment.name}</td>
				<td className="text-center border-0">
					{Number(payment.idPayment) === OrderPaymentConsts.WireTransfer && (
						<>
							{/* TODO: Mostrar Agencia e Conta de depósito (fazer uma consulta obtendo as contas da empresa e associar os dados) */}
						</>
					)}
				</td>
				<td className="text-center border-0">
					{payment.date && dateFormatter.format(payment.date)}
				</td>
				<td className="text-right border-0">
					{payment.value && currencyWithSymbolFormatter.format(payment.value)}
				</td>
			</>
		),
		[]
	);

	return (
		<Modal size="md" {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Pagamento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoadingPayments || isLoadingUserCredit ? (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" />
					</div>
				) : (
					<>
						<Row className="mb-3">
							<Col>
								<FormGroupTitle>
									Confirme as formas de pagamento usadas para pagar o pedido{' '}
									<strong>#{numberFormatter.format(order.number)}</strong>
								</FormGroupTitle>
							</Col>
						</Row>
						<Row>
							<Col>
								<Formik
									onSubmit={(values, helpers) => handleSubmit(values, helpers)}
									initialValues={defaultFormValue}
									validationSchema={validationSchema}
								>
									{(formProps) => (
										<FormikForm>
											<Row>
												<Col md={10} lg={11}>
													<Form.Row>
														<Form.Group as={Col} sm={6} md={6} lg={3}>
															<Form.Label>Forma de Pagamento</Form.Label>
															<Select
																name="payType"
																options={paymentOptions}
																isClearable
																onChange={handleChangePayType(formProps)}
															/>
														</Form.Group>
														{formProps.values.payType?.value ===
															OrderPaymentConsts.WireTransfer && (
															<>
																<Form.Group as={Col} sm={6} md={6} lg={3}>
																	<Form.Label>Conta</Form.Label>
																	<AsyncSelect
																		name="account"
																		isClearable
																		fetchMethod={companyService.getAccounts}
																		fetchParams={{
																			id: selectedCompany.idPerson,
																		}}
																		getOptionLabel={(
																			option: CompanyAccount
																		) => {
																			return `${option.bank}\nAg: ${option.agency} Conta: ${option.number}\n${option.holder}`;
																		}}
																	/>
																</Form.Group>
																<Form.Group as={Col} sm={6} md={6} lg={3}>
																	<Form.Label>Data</Form.Label>
																	<DateTimeInput name="date" selectDate />
																</Form.Group>
															</>
														)}
														{formProps.values.payType?.value && (
															<Form.Group as={Col} sm={6} md={6} lg={3}>
																<Form.Label>Valor</Form.Label>
																<Input
																	name="value"
																	formatter={currencyFormatter}
																/>
															</Form.Group>
														)}
													</Form.Row>
												</Col>
												<Col xs={12} md={2} lg={1} className="p-0">
													{formProps.values.payType?.value && (
														<Form.Group
															as={Col}
															className="text-right text-md-center"
														>
															<Form.Label className="d-none d-md-block pb-4">
																{' '}
															</Form.Label>
															<Button type="submit" className="block">
																<FAIcon icon={faPlus} />
															</Button>
														</Form.Group>
													)}
												</Col>
											</Row>

											{!partialPayments?.length ? null : (
												<>
													<Form.Label>Pagamentos Parciais</Form.Label>
													<Table striped bordered hover className="mb-3">
														<thead className="border-bottom">
															<tr>
																<th className="text-left border-0">Tipo</th>
																<th className="text-center border-0"> </th>
																<th className="text-center border-0">Data</th>
																<th className="text-right border-0">Valor</th>
																<th className="text-right border-0"> </th>
															</tr>
														</thead>
														<tbody>
															{partialPayments.map((payment) => (
																<tr key={payment.idPayDetail}>
																	{renderPaymentCols(payment)}
																	<td className="text-center border-0">
																		<FAIcon
																			icon={faCheck}
																			className="text-success"
																		/>
																	</td>
																</tr>
															))}
															{newPayments.map((payment, idx) => (
																// eslint-disable-next-line react/no-array-index-key
																<tr key={idx}>
																	{renderPaymentCols(payment)}
																	<td className="text-center border-0">
																		<Button
																			variant="link"
																			className="text-danger p-0"
																			onClick={() => handleRemoveItem(idx)}
																		>
																			<FAIcon icon={faTrashAlt} />
																		</Button>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</>
											)}

											<Row className="mb-1">
												<Col xs={9} className="text-right px-1 h4">
													Total
												</Col>
												<Col xs={3} className="text-right px-1 h3">
													{currencyWithSymbolFormatter.format(order.total)}
												</Col>
											</Row>
											<Row className="mb-1">
												<Col xs={9} className="text-right px-1 h4">
													Total Pago
												</Col>
												<Col xs={3} className="text-right px-1 h4 text-danger">
													{currencyWithSymbolFormatter.format(
														order.total - pendingValue
													)}
												</Col>
											</Row>
											<Row className="mb-1">
												<Col xs={9} className="text-right px-1 h4">
													Pendente
												</Col>
												<Col xs={3} className="text-right px-1 h4 text-danger">
													{currencyWithSymbolFormatter.format(pendingValue)}
												</Col>
											</Row>
										</FormikForm>
									)}
								</Formik>
							</Col>
						</Row>
					</>
				)}
			</Modal.Body>
			<Modal.Footer align="end">
				<Button
					variant="outline-primary"
					onClick={() => modalRef.dismiss(false)}
				>
					Voltar
				</Button>
				<Button variant="primary" onClick={() => modalRef.dismiss(true)}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default OrderPaymentModal;
