import React, { useState, useEffect, ReactNode } from 'react';

import Button from 'react-bootstrap/Button';

import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import DaySquare from 'components/calendar/datepicker/DaySquare';
import Utils, {
	MonthLocalePt,
	WeeksLocalePt,
} from 'components/calendar/datepicker/Utils';
import { DayOfMonth, MonthType } from 'components/calendar/types';
import { dateFormatter } from 'utils/formatters';

export type DatePickerProps = {
	defaultDate?: string;
	value?: string;
	onDateChange?: (newDate: string) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({
	value,
	defaultDate,
	onDateChange,
}) => {
	const defDate = new Date(value || defaultDate || new Date());
	if (!value && !defaultDate) {
		defDate.setHours(0, 0);
		defDate.setSeconds(0, 0);
	}
	const [actualDate, setActualDate] = useState<Date>(defDate);

	const handlePrevMonth = () => {
		const day = actualDate.getDate();
		const lastDay = Utils.getDaysInMonth(
			actualDate.getFullYear(),
			actualDate.getMonth()
		);

		const date = new Date(actualDate);
		date.setDate(Math.min(day, lastDay));
		date.setMonth(date.getMonth() - 1);

		setActualDate(date);
	};

	const handleNextMonth = () => {
		const day = actualDate.getDate();
		const lastDay = Utils.getDaysInMonth(
			actualDate.getFullYear(),
			actualDate.getMonth() + 1
		);

		const date = new Date(actualDate);
		date.setMonth(date.getMonth() + 1);
		date.setDate(Math.min(day, lastDay));

		setActualDate(date);
	};

	const handleDayChange = (day: number) => {
		const date = new Date(actualDate);
		date.setDate(day);
		setActualDate(date);
	};

	useEffect(() => {
		if (actualDate && onDateChange) {
			const isoStr = actualDate.toISOString();
			const localeStr = dateFormatter.localeStringToIso(
				new Date(actualDate).toLocaleString()
			);
			if (value && (value === isoStr || value === localeStr)) {
				return;
			}
			onDateChange(isoStr);
		}
	}, [value, actualDate, onDateChange]);

	// Calcula quantos dias da semana teve do mês anterior antes do dia 1o do mês atual
	// e quantos dias teve depois para concluir 5 linhas (42 dias)
	const date = new Date(actualDate);
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	const todayDate = new Date();

	const prevMonth = new Date(date);
	const startMonth = new Date(date);
	const nextMonth = new Date(date);
	startMonth.setDate(1);
	const daysPrevMonth = startMonth.getDay() > 0 ? startMonth.getDay() : 0;
	prevMonth.setDate(-daysPrevMonth);
	nextMonth.setMonth(nextMonth.getMonth() + 1);
	nextMonth.setDate(0);

	const lastDayPrevMonth = Utils.getDaysInMonth(
		prevMonth.getFullYear(),
		prevMonth.getMonth()
	);
	const lastDayMonth = Utils.getDaysInMonth(
		date.getFullYear(),
		date.getMonth()
	);
	const end = 42 - lastDayMonth - daysPrevMonth;

	const daysForGrid: DayOfMonth[] = [];

	// Dias do mês anterior
	for (let i = prevMonth.getDate(); i < lastDayPrevMonth; ++i) {
		daysForGrid.push({ day: i, monthType: MonthType.Last });
	}
	// Dias do mês atual
	for (let i = 1; i <= lastDayMonth; ++i) {
		daysForGrid.push({ day: i, monthType: MonthType.Actual });
	}
	// Dias do mês posterior
	for (let i = 1; i <= end; ++i) {
		daysForGrid.push({ day: i, monthType: MonthType.Next });
	}

	// Monta o um array de semanas
	const rows = Utils.splitArray(daysForGrid, 7);

	const tableBody: ReactNode[] = [];

	for (const [rowNumber, row] of rows.entries()) {
		tableBody.push(
			<tr key={rowNumber}>
				{row.map((d) => {
					return (
						<td key={`${d.day}-${d.monthType}`}>
							<DaySquare
								dayOfMonth={d}
								today={
									d.day === todayDate.getDate() &&
									month === todayDate.getMonth()
								}
								selected={d.day === day}
								onClick={handleDayChange}
							/>
						</td>
					);
				})}
			</tr>
		);
	}

	return (
		<>
			<div className="d-flex justify-content-between p-3">
				<Button variant="primary" size="sm" onClick={handlePrevMonth}>
					<FAIcon icon={faChevronLeft} />
				</Button>
				<strong>{`${MonthLocalePt[month]} ${year}`}</strong>
				<Button variant="primary" size="sm" onClick={handleNextMonth}>
					<FAIcon icon={faChevronRight} />
				</Button>
			</div>
			<div className="p-2">
				<table className="w-100 text-center">
					<thead>
						<tr>
							{WeeksLocalePt.map((d) => (
								<td key={d}>{d}</td>
							))}
						</tr>
					</thead>
					<tbody>{tableBody}</tbody>
				</table>
			</div>
		</>
	);
};

DatePicker.displayName = 'DatePicker';
DatePicker.whyDidYouRender = true;

export default DatePicker;
