/* eslint-disable react/no-array-index-key */
import React from 'react';

import styled from 'styled-components';

import ColumnSearch from './filters/ColumnSearch';
import useDatagridContext from './useDatagridContext';
import useDatagridDataContext from './useDatagridDataContext';

const SearchRowStyle = styled.tr`
	height: 2rem;
`;

const DatagridBody: React.FC = () => {
	const {
		config: { columns },
	} = useDatagridContext();
	const { error, data, pagination } = useDatagridDataContext();

	const hasSearchRow = columns.some((col) => col.searchable !== false);
	const pageIndex = pagination?.current_page ?? 1;

	return (
		<>
			<tbody>
				{hasSearchRow && (
					<SearchRowStyle>
						{columns.map((col, index) => (
							<ColumnSearch
								key={`${col.key || ''}${index}`}
								colKey={col.key || ''}
								searchable={col.searchable}
								searchFormatter={col.searchFormatter!}
							/>
						))}
					</SearchRowStyle>
				)}
				{!!error && (
					<tr>
						<td colSpan={columns.length}>{error.message}</td>
					</tr>
				)}
				{!!data &&
					data.map((d, ind) => {
						return (
							<tr key={`${pageIndex}${ind}`}>
								{columns.map((col, index) =>
									col.scope !== undefined ? (
										<th
											key={`${pageIndex}${ind}${index}`}
											className={col.className}
											scope={col.scope}
										>
											{col.formatter?.format(col.key || '', d)}
										</th>
									) : (
										<td
											key={`${pageIndex}${ind}${index}`}
											className={col.className}
										>
											{col.formatter?.format(col.key || '', d)}
										</td>
									)
								)}
							</tr>
						);
					})}
			</tbody>
		</>
	);
};

DatagridBody.displayName = 'DatagridBody';
DatagridBody.whyDidYouRender = true;

export default DatagridBody;
