export type ColorVariant =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'danger'
	| 'warning'
	| 'info'
	| 'dark'
	| 'light'
	| 'link'
	| 'outline-primary'
	| 'outline-secondary'
	| 'outline-success'
	| 'outline-danger'
	| 'outline-warning'
	| 'outline-info'
	| 'outline-dark'
	| 'outline-light';

export type DayProps = {
	dayOfMonth: DayOfMonth;
	today: boolean;
	selected: boolean;
	onClick: (day: number) => void;
	color?: ColorVariant;
	selectedColor?: ColorVariant;
};

export enum MonthType {
	Last = 0,
	Actual = 1,
	Next = 2,
}

export type DayOfMonth = {
	day: number;
	monthType: MonthType;
};
