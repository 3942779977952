import React, { useEffect } from 'react';
import { useRouteMatch, Link, useParams } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useQuery, ArrayQueryKey } from 'react-query';

import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Backdrop } from 'components';
import ecommerceService from 'services/ecommerce.service';
import { EcCategoryConfig, EcProductDetails, UrlParams } from 'types';
import history from 'utils/history';
import TextToUrlSlug from 'utils/TextToUrlSlug';
import UrlSlugToID from 'utils/UrlSlugToID';

import {
	Breadcrumb,
	CategoryBanner,
	Title,
	Paragraph,
	ProductCard,
} from '../components';
import useEcommerceBasePath from '../hooks/useEcommerceBasePath';

import CategorySidebar from './productList/CategorySidebar';
import ProductListSkeleton from './productList/Skeleton';

const ProductList: React.FC = () => {
	const { url } = useRouteMatch();
	const { id } = useParams<UrlParams>();
	const baseUrl = useEcommerceBasePath();

	const idCategory = UrlSlugToID(id);

	const { isLoading: isCatLoading, data: category } = useQuery<
		EcCategoryConfig,
		ArrayQueryKey
	>(
		['category', idCategory],
		() =>
			ecommerceService.getCategory(idCategory).then((result) => {
				const cat = result;
				cat.parent = cat.parent.reverse();
				return cat;
			}),
		{
			onError: () => {
				history.push(baseUrl);
			},
			enabled: idCategory,
		}
	);

	const { isLoading: isProdLoading, data: products } = useQuery<
		EcProductDetails[],
		ArrayQueryKey
	>(
		['product_list', idCategory],
		() =>
			ecommerceService
				.getCategoryProducts(idCategory, {})
				.then((result) => result.data),
		{
			onError: () => {
				history.push(baseUrl);
			},
			enabled: idCategory,
		}
	);

	useEffect(() => {
		if (!category) {
			return;
		}
		const param = url.substring(url.lastIndexOf('/') + 1);
		const prevIdCategory = UrlSlugToID(param);

		// Se os parâmetros de categoria foram atualizados mas a categoria selecionada é
		// diferente do objeto armazenado previamente, espera nova categoria ser carregada
		// para atualizar a rota
		if (prevIdCategory !== category.idCategory) {
			return;
		}

		const productSlug = `${String(category.idCategory)}-${TextToUrlSlug(
			category.shortName
		)}`;
		const paramIndex = url.lastIndexOf('/');
		const newUrl = `${url.substring(0, paramIndex + 1)}${productSlug}`;
		if (url !== newUrl) {
			history.replace(newUrl);
		}
	}, [category, url]);

	if (isCatLoading || !category) {
		return <ProductListSkeleton />;
	}

	return (
		<>
			<CategoryBanner className="position-relative">
				<Backdrop>
					{category.banner && (
						<>
							<Backdrop.Image
								src={category.banner}
								align="right center"
								size="cover"
							/>
							<Backdrop.Gradient
								className="w-50"
								filter={{
									iniColor: '#111',
									iniOpacity: 1.0,
									degree: 90,
									endOpacity: 0,
								}}
							/>
						</>
					)}
				</Backdrop>
				<Container fluid>
					<Row>
						<Col lg={4}>
							<Breadcrumb color="var(--gray-30)">
								<Breadcrumb.Item as={Link} to={baseUrl} linkAs="span">
									<FAIcon icon={faHome} />
								</Breadcrumb.Item>
								{category.parent.map((cat) => (
									<Breadcrumb.Item
										as={Link}
										key={cat.idCategory}
										to={`${baseUrl}/categoria/${cat.idCategory}`}
										linkAs="span"
									>
										{cat.shortName}
									</Breadcrumb.Item>
								))}
							</Breadcrumb>
							<Title className="text-light">{category.longName}</Title>
						</Col>
					</Row>
				</Container>
			</CategoryBanner>
			<Container fluid className="py-4 bg-white">
				<Row>
					<Col lg={2}>
						<div className="mr-2">
							<CategorySidebar categories={category.child} />
						</div>
					</Col>
					<Col lg={10}>
						<Paragraph>{category.description}</Paragraph>
						<Row>
							{isProdLoading &&
								Array(12)
									.fill(0)
									.map((val, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<Col key={`${val}_${index}`} lg={3} md={4} sm={6} xs={12}>
											<ProductCard.Card.Skeleton />
										</Col>
									))}
							{products &&
								products.map((product) => (
									<Col key={product.idProduct} lg={3} md={4} sm={6} xs={12}>
										<ProductCard.Card
											showQuantity
											showAddToCartButton
											product={product}
										/>
									</Col>
								))}
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ProductList;
