import { TypeOptions } from 'react-toastify';

import { MachineConfig, MachineOptions, Machine } from 'xstate';

import { ToastFn } from 'hooks/useToast';

export type NotificationsSchema = {
	states: {
		listening: {};
	};
};

type NotificationsContext = {};

// Eventos internos do statecharts de seleção de endereço
export type NotificationsEvent = {
	type: 'NOTIFY';
	data: { message: string; type: TypeOptions };
};

// Eventos disparados para a máquina de estados pai
export type NotificationsParentEvent = { type: '' };

export type NotificationsEventWithItem = {
	type: string;
};

export const NotificationsMachineConfig: MachineConfig<
	NotificationsContext,
	NotificationsSchema,
	NotificationsEvent
> = {
	id: 'notificationsMachine',
	initial: 'listening',
	context: {},
	states: {
		listening: {
			on: {
				NOTIFY: {
					actions: ['showToast'],
				},
			},
		},
	},
};

export const NotificationsMachineOptions = (
	toast: ToastFn
): MachineOptions<NotificationsContext, NotificationsEvent> => ({
	guards: {},
	actions: {
		showToast: (_, event) => {
			toast(event.data.message, { type: event.data.type });
		},
	},
	services: {},
	activities: {},
	delays: {},
});

export default (toast: ToastFn) =>
	Machine(NotificationsMachineConfig, NotificationsMachineOptions(toast));
