import React, { useCallback, useState } from 'react';

import { DatagridConfig } from 'components/datagrid/types';
import expeditionService from 'services/expedition.service';
import { HttpGetListParams } from 'types';
import { dateFormatter, numberFormatter } from 'utils/formatters';

import { DatatableView } from 'packages/admin/components';

import CheckboxFilter from './CheckboxColumn';
import Filters from './inSeparationDatagrid/Filters';
import paginateConfig from './paginate';
import { CheckedContext } from './useCheckedContext';

const InSeparationConfig: DatagridConfig = {
	paginate: paginateConfig,
	sortByKey: 'dateStatus',
	columns: [
		{
			key: 'idOrder',
			title: '',
			searchable: false,
			className: 'text-center',
			formatter: new CheckboxFilter(),
		},
		{
			key: 'dateStatus',
			title: 'Data',
			className: 'text-center',
			formatter: dateFormatter,
		},
		{
			key: 'number',
			title: 'Pedido',
			className: 'text-center',
			formatter: numberFormatter,
		},
		{
			key: 'date',
			title: 'Data do Pedido',
			className: 'text-center',
			formatter: dateFormatter,
		},
		{ key: 'type', title: 'Tipo', className: 'text-center' },
		{ key: 'shipment', title: 'Envio', className: 'text-center' },
		{
			key: 'id',
			title: 'Executivo',
			className: 'text-center',
			formatter: numberFormatter,
		},
	],
};

export type InSeparationDatagridProps = {
	idExpedition?: number;
};

const InSeparationDatagrid: React.FC<InSeparationDatagridProps> = ({
	idExpedition,
}) => {
	const [checked, setChecked] = useState<{ [id: string]: boolean }>({});

	const emitChecked = useCallback(
		(ids: number[], state: boolean) => {
			setChecked((prevState) => {
				const newState = { ...prevState };
				ids.forEach((id: number) => {
					newState[id] = state;
				});
				return newState;
			});
		},
		[setChecked]
	);

	const clearChecked = useCallback(() => {
		setChecked({});
	}, [setChecked]);

	const serviceMethod = useCallback(
		(params: HttpGetListParams) =>
			expeditionService.getInSeparationOrders({
				...params,
				params: {
					...(params.params || {}),
					id: String(idExpedition ?? ''),
				},
			}),
		[idExpedition]
	);
	return (
		<CheckedContext.Provider value={{ checked, emitChecked, clearChecked }}>
			<DatatableView config={InSeparationConfig} serviceMethod={serviceMethod}>
				<Filters />
			</DatatableView>
		</CheckedContext.Provider>
	);
};

export default InSeparationDatagrid;
