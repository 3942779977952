import React, { useEffect } from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { useQueryParams } from 'hooks';

const DistributorFilterDetail: React.FC = () => {
	const { onQueryChange, forceUpdate } = useDatagridContext();
	const query = useQueryParams();

	useEffect(() => {
		const prodQuery = query.get('product');
		if (prodQuery) {
			onQueryChange('product', prodQuery);
		} else {
			forceUpdate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default DistributorFilterDetail;
