import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import { Card } from 'components';
import Skeleton from 'components/Skeleton';

const Label = styled.div`
	height: 1.2rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props} className="py-2">
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="2rem" />
	</Form.Group>
);

const DetailsSkeleton: React.FC = () => (
	<Card>
		<Card.Header separator>
			<Skeleton width="200px" height="2rem" />
		</Card.Header>
		<Card.Content>
			<Row>
				<Col lg={12}>
					<Row>
						<Col sm={8} md={4} lg={2}>
							<Form.Label>
								<Label />
							</Form.Label>
							<Skeleton height="15rem" />
						</Col>

						<Col md={8} lg={{ span: 6, offset: 1 }}>
							<Row>
								<SkelGroup as={Col} md={6} />
								<SkelGroup as={Col} md={6} />
							</Row>
							<Row>
								<SkelGroup as={Col} md={6} />
								<SkelGroup as={Col} md={6} />
							</Row>
							<Row>
								<SkelGroup as={Col} md={6} />
								<SkelGroup as={Col} md={6} />
							</Row>
							<Row>
								<SkelGroup as={Col} md={6} />
							</Row>
							<Row>
								<SkelGroup as={Col} md={6} />
								<SkelGroup as={Col} md={6} />
							</Row>
							<Row>
								<Form.Group as={Col} lg={12} className="mt-4">
									<Row>
										<Form.Group as={Col} lg={3}>
											<Skeleton height="2rem" />
										</Form.Group>
									</Row>
								</Form.Group>

								<Form.Group as={Col} lg={10}>
									<Skeleton height="2rem" />
								</Form.Group>
								<Form.Group as={Col} lg={2}>
									<Skeleton height="2rem" />
								</Form.Group>
								<Form.Group as={Col} lg={12}>
									<Skeleton height="2rem" />
								</Form.Group>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card.Content>

		<Card.Content className="border-top">
			<Col md={12} lg={9}>
				<div className="text-right">
					<span className="mr-2">
						<Skeleton width="100px" height="2rem" />
					</span>
					<Skeleton width="100px" height="2rem" />
				</div>
			</Col>
		</Card.Content>
	</Card>
);

export default DetailsSkeleton;
