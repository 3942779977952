import styled from 'styled-components';

import { math, cssVar } from 'polished';

export const ContentPage = styled.div.attrs(
	(props: { isMobile: boolean; isSubmenuActive: boolean }) => ({
		isMobile: props.isMobile,
		isSubmenuActive: props.isSubmenuActive,
	})
)`
	margin-left: ${(props) =>
		// eslint-disable-next-line no-nested-ternary
		!props.isMobile && props.isSubmenuActive
			? math(`190px + ${cssVar('--sidebar-width-collapse')}`)
			: props.isMobile
			? 0
			: cssVar('--sidebar-width-collapse')};
	overflow: hidden;
	min-height: calc(100vh - var(--navbar-height));
	margin-top: var(--navbar-height);
	display: flex;
	flex-direction: column;
`;

ContentPage.displayName = 'ContentPage';
(ContentPage.whyDidYouRender as boolean) = true;
