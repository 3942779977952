import React from 'react';

import styled from 'styled-components';

import Footer from './Footer';
import Navbar from './Navbar';
import NavbarCatalog from './NavbarCatalog';
import NavbarMMN from './NavbarMMN';

export const EcLayoutStyle = styled.div`
	font-size: 1rem;
	background: white;

	.container-fluid {
		max-width: 1600px;
	}

	.page-min-height {
		min-height: 100vh;
	}

	.navbar-sticky {
		position: sticky;
		top: 0;
		z-index: 10;
	}
`;

const EcLayout: React.FC = ({ children }) => {
	return (
		<>
			<div className="navbar-sticky border-bottom">
				<NavbarMMN />
				<Navbar />
			</div>
			<NavbarCatalog />
			<div className="page-min-height">{children}</div>
			<Footer />
		</>
	);
};

export default EcLayout;
