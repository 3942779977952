import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	Campaign,
	CampaignType,
	CampaignDraw,
	CampaignCoupon,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Campaign>> =>
	Crud.getList<Campaign>('campaign', params);

const getTypes = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CampaignType>> =>
	Crud.getList<CampaignType>('campaign/type', params);

const getById = (id: string | number): Promise<Campaign> =>
	Crud.getOne<Campaign>('campaign/{id}', id as number);

const getMyCampaigns = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Campaign>> =>
	Crud.getList<Campaign>('my/campaign', params);

const getWinnersForId = (
	idCampaign: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<CampaignDraw>> =>
	Crud.getList<CampaignDraw>(`campaign/${idCampaign}/winner`, params);

const getParticipantsForId = (
	idCampaign: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<CampaignCoupon>> =>
	Crud.getList<CampaignCoupon>(`campaign/${idCampaign}/coupon`, params);

const getParticipantsForIdGrouped = (
	idCampaign: ID,
	params: HttpGetListParams
): Promise<HttpGetListResult<CampaignCoupon>> =>
	Crud.getList<CampaignCoupon>(`campaign/${idCampaign}/coupon/grouped`, params);

const drawWinners = (idCampaign: ID) =>
	Crud.create(`campaign/${idCampaign}/draw`, {});

const createOrUpdate = (
	id: ID | null,
	data: Campaign
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('campaign/{id}', id as number, data);
	}
	return Crud.create('campaign', data);
};

export default {
	getForList,
	getTypes,
	getById,
	getMyCampaigns,
	getWinnersForId,
	getParticipantsForId,
	getParticipantsForIdGrouped,
	drawWinners,
	createOrUpdate,
};
