import styled from 'styled-components';

import { Card } from 'components';

export const AddressItem = styled(Card)`
	width: 100%;
	max-width: 350px;
	margin: 0 20px 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const AddressItemNew = styled(Card)`
	width: 100%;
	max-width: 350px;
	min-height: 200px;
	margin: 0 0 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px dashed var(--gray-30);
	box-shadow: none;

	svg {
		color: var(--success);
	}
`;
