import {
	faAddressCard,
	faUserCircle,
	faLock,
	faUser,
} from '@fortawesome/pro-light-svg-icons';

import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

import Address from './Address';
import Details from './Details';
import Password from './Password';

const MyRoutes: RouteDef = {
	path: '/app/my',
	validation: [Not(personTypeValidation(PersonType.DistributorUser))],
	menu: {
		name: 'Cadastro',
		icon: faUser,
	},
	children: [
		{
			path: '/details',
			component: Details,
			exact: true,
			menu: {
				name: 'Meu Cadastro',
				icon: faUserCircle,
			},
		},
		{
			path: '/contacts',
			component: Address,
			exact: true,
			menu: {
				name: 'Endereços',
				icon: faAddressCard,
			},
		},
		{
			path: '/password',
			component: Password,
			exact: true,
			menu: {
				name: 'Senha de Acesso',
				icon: faLock,
			},
		},
	],
};

export default MyRoutes;
