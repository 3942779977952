import React, { FunctionComponent, useState } from 'react';

import styled from 'styled-components';

import { stripUnit } from 'polished';

import LoadWhenVisible from 'components/LoadWhenVisible';

type LazyImageProps = React.PropsWithChildren<
	Partial<{
		alt: string;
		src: string;
		loadingSrc: string;
		className: string;
		width: string | number | undefined;
		height: string | number | undefined;
	}>
>;

const LazyImage = styled.img.attrs((props: { show: boolean }) => ({
	show: !!props.show,
}))`
	position: absolute;
	width: 100%;
	height: 100%;
	visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

const StyledLoadWhenVisible = styled(LoadWhenVisible)`
	padding-bottom: ${(props) =>
		(stripUnit(props.height || 1) / stripUnit(props.width || 1)) * 100}%;
	position: relative;
`;

const LazyImageContainer: FunctionComponent<LazyImageProps> = ({
	alt,
	src,
	loadingSrc,
	...props
}) => {
	const [loaded, setLoaded] = useState<boolean>(false);

	return (
		<StyledLoadWhenVisible>
			<LazyImage alt={alt} src={loadingSrc} {...props} show={!loaded} />
			<LazyImage
				alt={alt}
				src={src}
				{...props}
				show={loaded}
				onLoad={() => setLoaded(true)}
			/>
		</StyledLoadWhenVisible>
	);
};

LazyImageContainer.displayName = 'LazyImage';
LazyImageContainer.whyDidYouRender = true;

export default LazyImageContainer;
