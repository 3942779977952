import React, { useCallback, useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';

import { ConfirmationModal } from 'modals';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { useModal, useToast } from 'hooks';
import financialService from 'services/financial.service';
import { StatementPayoutRequest } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

const PayRequestButton: React.FC<
	ButtonLinkComponentProps<StatementPayoutRequest>
> = ({ row }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const open = useModal<{ message: string }, boolean>();
	const [toast] = useToast();
	const { forceUpdate } = useDatagridContext();
	const id = row.idPayment;

	const handlePayoutRequest = useCallback(() => {
		setIsLoading(true);
		const dataPaid = { paid: 1 };

		financialService
			.setPayoutRequest(id, dataPaid)
			.then(() => {
				forceUpdate();
			})
			.catch(() => {
				toast('Não foi possível realizar o pagamento.', {
					type: 'error',
				});
				setIsLoading(false);
			});
	}, [forceUpdate, id, toast]);

	if (row?.status === 'Pago') return null;

	return (
		<Button
			variant="success"
			size="sm"
			disabled={isLoading}
			onClick={() => {
				open(
					ConfirmationModal,
					{ message: `Confirmar o pagamento do pedido de saque ${id}?` },
					(value) => {
						// eslint-disable-next-line no-console
						console.log('Modal Fechado', value);
						if (value) {
							handlePayoutRequest();
						}
					}
				);
			}}
		>
			{isLoading ? (
				<Spinner size="sm" animation="border" role="status" />
			) : (
				'Pagar'
			)}
		</Button>
	);
};

export default PayRequestButton;
