import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { Panel, Skeleton } from 'components';

import { Separator, ButtonsContainer, FormTitle } from './styles';

const ElectronicSignSkeletonForm: React.FC = () => (
	<Card>
		<Card.Body className="offset-lg-3 col-lg-6">
			<Panel align="center">
				<Skeleton height="3rem" width="260px" />
				<Skeleton height="2.4rem" width="150px" />
			</Panel>
			<FormTitle>
				<Skeleton height="1.5rem" width="200px" />
			</FormTitle>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton height="1rem" width="180px" />
					<Skeleton height="2.2rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton height="1rem" width="180px" />
					<Skeleton height="2.2rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton height="1rem" width="180px" />
					<Skeleton height="10px" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton height="1rem" width="180px" />
					<Skeleton height="2.2rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton height="1rem" width="180px" />
					<Skeleton height="2.2rem" />
				</Form.Group>
			</Form.Row>
			<Separator />
			<ButtonsContainer>
				<Skeleton width="75px" height="2.4rem" />
			</ButtonsContainer>
		</Card.Body>
	</Card>
);

export default ElectronicSignSkeletonForm;
