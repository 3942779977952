/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { cssVar } from 'polished';

import { UploadItemProps, PictureBoxProps, NewContainerProps } from './types';

export const ActionPictureButtons = styled.div`
	margin: 5px 0;
	overflow: hidden;

	.btn {
		box-shadow: var(--base-box-shadow);

		svg {
			font-size: 1rem;
		}
	}
`;

export const UploadItems = styled.div`
	margin: 5px 0;

	div + div {
		margin-top: 5px;
	}
`;

export const UploadItem = styled.div<UploadItemProps>`
	background-color: var(--light);
	border-radius: var(--base-border-radius);
	display: flex;
	align-items: center;
	height: ${(props) => (props.picture ? '66px' : 'auto')};
	padding: ${(props) => (props.picture ? '8px' : '5px 8px')};
	color: ${(props) =>
		props.hasError ? cssVar('--danger') : cssVar('--base-color')};
	transition: background 0.3s;
	box-shadow: var(--base-box-shadow);

	> a {
		width: 48px;
		height: 48px;
		line-height: 54px;
		text-align: center;
		margin-right: 8px;

		img {
			display: block;
			width: 48px;
			height: 48px;
			overflow: hidden;
		}

		> svg {
			width: ${(props) => (props.picture ? '3.5rem' : '2rem')} !important;
			font-size: ${(props) => (props.picture ? '2rem' : '1rem')};
			padding-right: 8px;
			margin-right: ${(props) => (props.picture ? '0' : '8px')};
			border-right: ${(props) =>
				props.picture ? 'none' : `1px solid ${cssVar('--gray-40')}`};
		}
	}

	> svg {
		width: ${(props) => (props.picture ? '3.5rem' : '2rem')} !important;
		font-size: ${(props) => (props.picture ? '2rem' : '1rem')};
		padding-right: 8px;
		margin-right: ${(props) => (props.picture ? '0' : '8px')};
		border-right: ${(props) =>
			props.picture ? 'none' : `1px solid ${cssVar('--gray-40')}`};
	}

	span {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.btn {
		svg {
			color: var(--danger);
		}
	}

	&:hover {
		background-color: var(--gray-20);
	}
`;

export const PictureBox = styled.div<PictureBoxProps>`
	padding: 0.7rem;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--light);
	border-radius: var(--base-border-radius);
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
	transition: border-color 0.3s;

	border: 1px dashed
		${({ dragHover }) =>
			dragHover ? cssVar('--primary') : cssVar('--gray-40')};
	${({ hasError }) =>
		hasError &&
		css`
			border: 1px solid var(--danger);
		`}

	&:hover {
		border-color: var(--primary);
	}

	> img {
		width: 100%;
		height: 100%;
		object-fit: ${({ objectFit = 'scale-down' }) => objectFit};
		margin: 0;
	}

	svg {
		font-size: 1.5rem;
		margin-bottom: 0.3rem;
	}

	div {
		text-align: center;
		font-size: 0.875rem;
		flex-wrap: wrap;
	}
`;

export const Container = styled.div<NewContainerProps>`
	width: ${({ width }) =>
		width ? (typeof width === 'string' ? width : `${width}px`) : '100%'};
	height: ${({ height }) =>
		height ? (typeof height === 'string' ? height : `${height}px`) : 'unset'};
	display: ${({ displayInline }) => (displayInline ? 'inline-block' : 'block')};

	${PictureBox} {
		${({ disabled }) => disabled && 'cursor: not-allowed;'}
	}
`;
