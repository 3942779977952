import React from 'react';

import Container from 'react-bootstrap/Container';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { Featured } from 'components';

import {
	NavbarMMN,
	Navbar,
	NavbarCatalog,
} from 'packages/ecommerce/components';
import { EcLayoutStyle } from 'packages/ecommerce/components/EcLayout';

const FeatureItemContainer = styled.div`
	display: block;
	position: relative;
	width: 100%;
	padding-bottom: 56%;
	height: 0;
`;

const MainRouterSkeleton: React.FC = () => (
	<EcLayoutStyle className="h-100">
		<NavbarMMN />
		<Navbar.Skeleton />
		<NavbarCatalog.Skeleton />
		<Container fluid>
			<Skeleton height="300px" />
		</Container>
		<Container fluid className="pt-5">
			<Featured>
				<Featured.Item size={2}>
					<FeatureItemContainer>
						<div className="position-absolute w-100 h-100">
							<Skeleton height="100%" />
						</div>
					</FeatureItemContainer>
				</Featured.Item>
				<Featured.Item size={2}>
					<FeatureItemContainer>
						<div className="position-absolute w-100 h-100">
							<Skeleton height="100%" />
						</div>
					</FeatureItemContainer>
				</Featured.Item>
				<Featured.Item size={2}>
					<FeatureItemContainer>
						<div className="position-absolute w-100 h-100">
							<Skeleton height="100%" />
						</div>
					</FeatureItemContainer>
				</Featured.Item>
			</Featured>
		</Container>
	</EcLayoutStyle>
);

export default MainRouterSkeleton;
