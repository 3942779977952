import styled from 'styled-components';

import { cssVar } from 'polished';

export const Container = styled.div`
	height: 545px;
	overflow-x: auto;
`;

export const DroppableContent = styled.div.attrs(
	(props: { isDraggingOver: boolean }) => ({
		isDraggingOver: props.isDraggingOver,
	})
)`
	border: ${(props) =>
		props.isDraggingOver
			? `2px dotted ${cssVar('--gray-20')}`
			: '1px dotted transparent'};
	padding: ${(props) =>
		props.isDraggingOver ? '0.5rem 0.5rem 0 0.5rem' : 'none'};
	border-radius: var(--base-border-radius);
	transition: all 0.2s;
`;
