import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const TableStyled = styled(Table)`
	margin-bottom: 0;

	td {
		white-space: nowrap;
	}
`;
