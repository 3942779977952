import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const ScrollReplaceContainer = styled(Container)`
	overflow-y: scroll;
	max-height: calc(100vh - var(--navbar-height));
`;

export const AnexosList = styled.div`
	border-top: 1px solid var(--gray-20);
	padding: 15px 0 5px;

	> div {
		margin-bottom: 5px;
	}
`;

export const AnexosListItem = styled.div`
	span {
		margin-right: 5px;
	}
	a {
		padding: 5px 8px;
		border-radius: var(--base-border-radius);
		background-color: var(--gray-20);
	}
`;
