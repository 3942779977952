import {
	HttpGetListParams,
	SystemUserForList,
	HttpGetListResult,
	SystemUserTimetable,
	SystemUser,
	HttpPostResultAny,
	SystemUserDepartment,
	ID,
	HashID,
	UserPaType,
	UserPaListType,
} from 'types';
import { AuthACLRole, AuthACLPermission } from 'types/auth.types';

import Crud from './crud.service';

/**
 * Service de requisição de dados de usuários de sistema
 */

const getForSelect = (
	params: HttpGetListParams
): Promise<HttpGetListResult<SystemUserForList>> =>
	Crud.getList<SystemUserForList>('user', params);

const getProfiles = (
	params: HttpGetListParams
): Promise<HttpGetListResult<AuthACLRole>> =>
	Crud.getList<AuthACLRole>('acl/role', params);

const getProfileById = (id: ID): Promise<AuthACLRole> =>
	Crud.getOne<AuthACLRole>('acl/role/{id}', id as number);

const removeProfile = (id: ID | HashID): Promise<HttpPostResultAny> =>
	Crud.delete('acl/role/{id}', Number(id));

const getPermissions = (): Promise<AuthACLPermission[]> =>
	Crud.getListData('acl/permission', {});

const createOrUpdatePermissions = (
	id: ID | null,
	data: AuthACLRole
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('acl/role/{id}', id as number, data);
	}
	return Crud.create('acl/role', data);
};

const getSchedules = (
	params: HttpGetListParams
): Promise<HttpGetListResult<SystemUserTimetable>> =>
	Crud.getList<SystemUserTimetable>('timetable', params);

const getById = (id: ID): Promise<SystemUser> =>
	Crud.getOne('user/{id}', id as number);

const createOrUpdate = (
	id: ID | null,
	data: SystemUser
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('user/{id}', id as number, data);
	}
	return Crud.create('user', data);
};

const getDepartments = (onlyActives = true): Promise<SystemUserDepartment[]> =>
	Crud.getListData<SystemUserDepartment>('user/department', {
		query: onlyActives ? ['"active": 1'] : undefined,
	});

const getProfilesForUserId = (id: ID): Promise<AuthACLRole[]> =>
	Crud.getListData<AuthACLRole>(`user/${id}/role`, {});

const getUserPaList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<UserPaListType>> =>
	Crud.getList<UserPaListType>('my/distributor/user', params);

const getUserPaById = (id: ID): Promise<UserPaType> =>
	Crud.getOne<UserPaType>('my/distributor/user/{id}', id);

const createOrUpdateUserPa = (
	id: ID | null,
	data: UserPaType
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('my/distributor/user/{id}', id as number, data);
	}
	return Crud.create('my/distributor/user', data);
};

export default {
	getForSelect,
	getById,
	getProfiles,
	getProfileById,
	getProfilesForUserId,
	removeProfile,
	getPermissions,
	createOrUpdatePermissions,
	getSchedules,
	getDepartments,
	createOrUpdate,
	getUserPaList,
	getUserPaById,
	createOrUpdateUserPa,
};
