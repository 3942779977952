import { ColumnConfig } from 'components/datagrid/types';
import orderService from 'services/order.service';
import {
	numberFormatter,
	dateTimeFormatter,
	currencyFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Pedidos',
		path: '/admin/orders/summary',
		active: true,
	},
];

const ordersFilterColumns: Partial<ColumnConfig>[] = [
	{
		key: 'number',
		title: 'Pedido',
		searchable: true,
		className: 'text-center',
		formatter: numberFormatter,
	},
	{
		key: 'date',
		title: 'Data',
		searchable: true,
		className: 'text-center',
		formatter: dateTimeFormatter,
	},
	{
		key: 'id',
		title: 'ID',
		searchable: true,
		className: 'text-center',
		formatter: numberFormatter,
	},
	{ key: 'name', title: 'Executivo', searchable: true },
	{ key: 'type', title: 'Tipo', searchable: true },
	{ key: 'payment', title: 'Forma de Pagamento', searchable: true },
	{
		key: 'cep',
		title: 'CEP',
		searchable: true,
		className: 'text-center',
	},
	{ key: 'shipment', title: 'Envio', searchable: true },
	{
		key: 'total',
		title: 'Total',
		searchable: true,
		className: 'text-center',
		formatter: currencyFormatter,
	},
	{
		key: 'status',
		title: 'Status',
		searchable: true,
		className: 'text-center',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	OrdersSummary: {
		title: 'Resumo dos Pedidos',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Resumo dos Pedidos',
				path: '/admin/orders/summary',
			},
		],
		action: orderService.getOrdersForCompany,
		datatableConfig: {
			paginate: paginateConfig,
			columns: ordersFilterColumns,
		},
	},

	OrdersFilterByNumber: {
		title: 'Pedido x Número',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pedido x Número',
				path: '/admin/orders/filter/by-number',
			},
		],
		action: orderService.getOrdersForCompany,
		datatableConfig: {
			paginate: paginateConfig,
			columns: ordersFilterColumns,
		},
	},

	OrdersFilterByPerson: {
		title: 'Pedido x Executivo',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pedido x Executivo',
				path: '/admin/orders/filter/by-person',
			},
		],
		action: orderService.getOrdersForCompany,
		datatableConfig: {
			paginate: paginateConfig,
			columns: ordersFilterColumns,
		},
	},

	OrdersFilterByMonth: {
		title: 'Pedido x Mês',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pedido x Mês',
				path: '/admin/orders/filter/by-month',
			},
		],
		action: orderService.getOrdersForCompany,
		datatableConfig: {
			paginate: paginateConfig,
			columns: ordersFilterColumns,
		},
	},

	OrdersFilterByStatus: {
		title: 'Pedido x Status',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Pedido x Status',
				path: '/admin/orders/filter/by-status',
			},
		],
		action: orderService.getOrdersForCompany,
		datatableConfig: {
			paginate: paginateConfig,
			columns: ordersFilterColumns,
		},
	},
};

export default ScenesConfig;
