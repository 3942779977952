import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import { Card } from 'components';
import Skeleton from 'components/Skeleton';

import SkeletonFeatureGrid from './featureGrid/SkeletonFeatureGrid';

const FeaturesEditSkeleton: React.FC = () => (
	<Container>
		<Card>
			<Row>
				<Col md={8} className="pr-0">
					<Card.Content>
						<Form.Row>
							<Form.Group as={Col} sm={7} md={5} xl={3}>
								<Skeleton width="8ch" height="1.2rem" />
								<Skeleton height="2.6rem" />
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} md={6}>
								<Skeleton width="8ch" height="1.2rem" />
								<Skeleton height="2.4rem" />
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Skeleton width="8ch" height="1.2rem" />
								<Skeleton height="2.4rem" />
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} md={6}>
								<Skeleton width="8ch" height="1.2rem" />
								<Skeleton height="2.4rem" />
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Skeleton width="8ch" height="1.2rem" />
								<Skeleton height="2.4rem" />
							</Form.Group>
						</Form.Row>
						<Form.Row className="mt-3">
							<SkeletonFeatureGrid gridLayout={[4, 1, 4]} />
						</Form.Row>
					</Card.Content>
				</Col>

				<Col md={4} className="d-flex border-left w-100 pl-0">
					<Card.Content width={100}>
						<Form.Row>
							<Form.Group as={Col} md={12}>
								<Skeleton width="15ch" height="1.6rem" />
								<Skeleton height="1.8rem" />
								<Skeleton height="1.8rem" />
								<Skeleton height="1.8rem" />
							</Form.Group>
						</Form.Row>
					</Card.Content>
				</Col>
			</Row>
			<Form.Row />
			<Card.Content className="text-right border-top">
				<Form.Row>
					<Col>
						<Skeleton width="75px" height="2.4rem" />
						<Skeleton width="75px" height="2.4rem" margin="0 0 0 1rem" />
					</Col>
				</Form.Row>
			</Card.Content>
		</Card>
	</Container>
);

export default FeaturesEditSkeleton;
