import React, { useCallback } from 'react';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import { Button, Col, Form } from 'react-bootstrap';

import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { FormikEffect, Input, Upload } from 'components/Formik';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import uploadHelpers from 'components/upload/Helpers';
import { UploadFile } from 'components/upload/types';
import { useToast } from 'hooks';
import recognitionService from 'services/recognition.service';
import { ApiCodes, HttpBaseResult, Recognition } from 'types';

import { FormSubmitButton } from 'packages/admin/components';

type RecognitionFormModalProps = {
	data?: Recognition;
	style?: React.CSSProperties;
	className?: string;
};

export const defaultFormValue: Recognition = {
	idBadge: null,
	name: '',
	image: null,
};

export const formSchema = Yup.object<Recognition>().shape({
	name: Yup.string().required('Campo obrigatório'),
	image: Yup.array()
		.of(
			Yup.object()
				.shape<UploadFile>({
					icon: Yup.mixed(),
					name: Yup.string(),
					preview: Yup.mixed(),
					size: Yup.number(),
					type: Yup.string(),
					uid: Yup.string(),
					url: Yup.string().nullable(),
				})
				.nullable()
				.required()
		)
		.nullable()
		.required('Campo obrigatório'),
});

const RecognitionFormModal: React.FC<
	ModalForwardProps<RecognitionFormModalProps, boolean>
> = ({ data = defaultFormValue, modalRef, ...rest }) => {
	const [toast] = useToast();

	const handleSubmit = useCallback(
		async <T extends Recognition>(
			{ image, ...values }: T,
			helpers: FormikHelpers<T>
		) => {
			const imageUrl =
				(image && Array.isArray(image) ? image[0]?.url : image) ?? '';

			const submit: Recognition = {
				...values,
				...(uploadHelpers.isNewFile(imageUrl) && {
					image: imageUrl,
					idImage: null,
				}),
			};

			try {
				const { message } = await recognitionService.createOrUpdate(
					submit.idBadge,
					submit
				);
				// eslint-disable-next-line no-console
				console.log('Success: ', message);
				modalRef.dismiss(true);
				toast(
					`Reconhecimento ${
						submit.idBadge ? 'alterado' : 'criado'
					} com sucesso`,
					{
						type: 'success',
					}
				);
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			}

			helpers.setSubmitting(false);
		},
		[modalRef, toast]
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest} exitOnBackdropClick={false}>
			<Modal.Header closeButton>
				<Modal.Title className="m-0">
					{!data.idBadge ? 'Novo' : 'Editar'} Reconhecimento
				</Modal.Title>
			</Modal.Header>

			<Formik
				onSubmit={(value, helpers) => handleSubmit(value, helpers)}
				initialValues={data}
				validationSchema={formSchema}
				enableReinitialize
			>
				{(form: FormikProps<Recognition>) => (
					<FormikForm>
						<FormikEffect focusOnError promptOnExit />

						<Modal.Body>
							<Form.Row>
								<Form.Group style={{ width: '270px', margin: '0 auto' }}>
									<Upload
										name="image"
										listType="picture-card"
										showUploadList={false}
										aspect={1}
										accept=".jpg, .png"
										maxSize={2}
										disabled={form.isSubmitting}
									>
										<FAIcon icon={faImage} />
										<div>Adicionar imagem</div>
									</Upload>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col}>
									<Form.Label>Nome</Form.Label>
									<Input type="text" name="name" disabled={form.isSubmitting} />
								</Form.Group>
							</Form.Row>
						</Modal.Body>
						<Modal.Footer align="end">
							<Button
								variant="outline-primary"
								onClick={() => modalRef.dismiss(false)}
								disabled={form.isSubmitting}
							>
								Cancelar
							</Button>
							<FormSubmitButton isSubmitting={form.isSubmitting} />
						</Modal.Footer>
					</FormikForm>
				)}
			</Formik>
		</Modal>
	);
};

export default RecognitionFormModal;
