import React from 'react';

import Form from 'react-bootstrap/Form';

import { Checkbox } from 'components';
import { ID } from 'types';
import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from 'utils/Formatter/IFormatter';

import useCheckedContext from './useCheckedContext';

const CheckboxColumn: React.FC<{
	idColumnValue: ID;
}> = ({ idColumnValue }) => {
	const { checked, emitChecked } = useCheckedContext();
	const value: boolean = checked[idColumnValue] ?? false;

	return (
		<Form.Group>
			<Checkbox
				label=""
				aria-label="Selecionar"
				onChange={() => {
					emitChecked([idColumnValue], !value);
				}}
				checked={value}
				value={value ? 1 : 0}
			/>
		</Form.Group>
	);
};

class CheckboxFilter implements FormatterType {
	formatForInput = (_: FormatterParamType): [string, string] => ['', ''];

	format = (
		value: FormatterParamType,
		row?: FormatterParamRowType
	): React.ReactFragment => {
		const data = !row ? value : row[value as string];
		return <CheckboxColumn idColumnValue={data as number} />;
	};
}

export default CheckboxFilter;
