import Image from 'react-bootstrap/Image';
import styled from 'styled-components';

import { AsyncSelect } from 'components';

export const AddProductToCart = styled.div`
	display: flex;
	align-items: center;
	background-color: var(--light);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 20px;
`;

export const AddProductToCartItem = styled.div`
	display: flex;
	flex-direction: column;

	&:nth-child(2) {
		flex: 1;
		max-width: 40rem;
	}

	&:nth-child(3) {
		max-width: 8rem;
	}

	&:nth-child(4) {
		margin-left: 0.5rem !important;
	}

	&:not(:first-child) {
		margin-left: 2rem;
	}
`;

export const OptionItem = styled.div`
	display: flex;
	align-items: center;
`;

export const AsyncSelectStyled = styled(AsyncSelect)`
	.select__control {
		height: 4rem;
	}
`;

export const ListImage = styled(Image)`
	height: 40px;
`;
