import { useSelector } from 'react-redux';

import Image from 'react-bootstrap/Image';
import styled from 'styled-components';

import classNames from 'classnames';

import InputQty from 'components/InputQty';
import { selectors, ShoppingCartItem } from 'store/ducks/shoppingCart';
import { ID } from 'types';

import useShoppingCartName from 'packages/ecommerce/hooks/useShoppingCartName';

export const stubImageSrc =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89x8AAuEB74Y0o2cAAAAASUVORK5CYII=';

export const SmallImage = styled(Image)`
	max-height: 60px;
`;

export const SmallInputQty = styled(InputQty).attrs(
	(props: { className?: string }) => ({
		className: classNames(props.className, 'input-group-sm'),
	})
)`
	max-width: 60px;
`;

export const useCartNameAndItem = (
	id: ID
): [string, ShoppingCartItem | undefined] => {
	const cartName = useShoppingCartName();
	const cartItem = useSelector(selectors.getCartItem(cartName, Number(id)));

	return [cartName, cartItem];
};
