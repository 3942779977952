import React, {
	FunctionComponent,
	PropsWithRef,
	useState,
	useEffect,
	useContext,
	ReactNode,
} from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHover } from 'hooks';

import Backdrop from './Backdrop';
import Styles from './jumboMenu/styles';

/**
 * Definição de parâmetros de componentes
 */
export type JumboMenuItemType = {
	icon?: string;
	title: string | ReactNode;
	link: string;
};

type DefaultProps<T = {}> = PropsWithRef<{
	className?: string;
}> &
	T;

export type JumboMenuNavProps = DefaultProps;

export type JumboMenuNavItemProps = DefaultProps<JumboMenuItemType>;

export type JumboMenuProps = DefaultProps;

export type JumboMenuItemProps = DefaultProps<JumboMenuItemType>;

/**
 * Definições de state do jumbo menu
 */
type JumboContextState = {
	focused: boolean;
	setFocusCallback: ((focus: boolean) => void) | null;
};

const JumboContext = React.createContext<JumboContextState>({
	focused: false,
	setFocusCallback: null,
});

/**
 * Componentes
 */
const Nav: FunctionComponent<JumboMenuNavProps> = ({
	className,
	children,
	...props
}) => {
	const [focused, setFocusCallback] = useState<boolean>(false);

	return (
		<>
			<Backdrop.Fade show={focused} />
			<JumboContext.Provider value={{ focused, setFocusCallback }}>
				<Styles.JumboMenuNav className={className} {...props}>
					{children}
				</Styles.JumboMenuNav>
			</JumboContext.Provider>
		</>
	);
};

const NavItem: FunctionComponent<
	JumboMenuItemProps & {
		className?: string;
		showArrow?: boolean;
	}
> = (props) => {
	const { title, link, children, ...rest } = props;
	const [hoverRef, isHovered] = useHover<HTMLLIElement>(true);
	const { focused, setFocusCallback } = useContext(JumboContext);

	useEffect(() => {
		if (setFocusCallback !== null) {
			setFocusCallback(isHovered);
		}
	}, [setFocusCallback, isHovered]);

	return (
		<Styles.JumboMenuNavItem
			ref={hoverRef}
			{...rest}
			className={focused ? 'focused' : ''}
		>
			<Link to={link}>{title}</Link>
			{React.Children.toArray(children).length > 0 && (
				<Styles.JumboMenuNavDropdown show={focused}>
					{children}
				</Styles.JumboMenuNavDropdown>
			)}
		</Styles.JumboMenuNavItem>
	);
};

const DropdownItem: FunctionComponent<JumboMenuItemProps> = ({
	title,
	link,
	children,
	...rest
}) => {
	const [hoverRef, isHovered] = useHover<HTMLDivElement>();

	return (
		<>
			<Styles.JumboDropdownItem ref={hoverRef} {...rest}>
				<Link to={link} className={isHovered ? 'focused' : ''}>
					{title}
					{React.Children.toArray(children).length > 0 && (
						<span className="has-submenu">
							<FontAwesomeIcon icon="chevron-right" />
						</span>
					)}
				</Link>

				{children && (
					<Styles.JumboMenuNavDropdown show={isHovered}>
						{children}
					</Styles.JumboMenuNavDropdown>
				)}
			</Styles.JumboDropdownItem>
		</>
	);
};

const SubmenuItem: FunctionComponent<JumboMenuItemProps> = ({
	title,
	link,
	...rest
}) => {
	const [hoverRef, isHovered] = useHover<HTMLDivElement>();

	return (
		<Styles.JumboSubmenuItem ref={hoverRef} {...rest}>
			<Link to={link} className={isHovered ? 'focused' : ''}>
				{title}
			</Link>
		</Styles.JumboSubmenuItem>
	);
};
export default {
	Nav,
	NavItem,
	Dropdown: Styles.JumboDropdown,
	DropdownItem,
	Submenu: Styles.JumboSubmenu,
	SubmenuItem,
	SideImage: Styles.JumboSideImage,
};
