import React, { useEffect } from 'react';

import { useField } from 'formik';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { AsyncSelect } from 'components/Formik';
import careerService from 'services/career.service';
import { CareerForSelect } from 'types';

import asyncSelectQueries from 'packages/admin/utils/AsyncSelectQueries';

export type CareerSelectFilterProps = {
	name: string;
	queryKey: keyof CareerForSelect;
};

const CareerSelectFilter: React.FC<CareerSelectFilterProps> = ({
	name,
	queryKey,
}) => {
	const { onParamChange } = useDatagridContext();
	const [{ value: fieldValue }] = useField<CareerForSelect>(name || '');

	// Inicializa com o defaultValue
	useEffect(() => {
		if (fieldValue != null) {
			onParamChange(name, String(fieldValue.idCareer));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (value: CareerForSelect) => {
		onParamChange(name, value[queryKey] || '');
	};

	return (
		<AsyncSelect
			name={name}
			fetchMethod={careerService.getForSelect}
			fetchQuery={asyncSelectQueries.careerQuery}
			fetchSortByKey="name"
			onChange={onChange}
		/>
	);
};

export default CareerSelectFilter;
