import { HttpGetListParams, HttpGetListResult, ProductSelect } from 'types';

import Crud from './crud.service';

/**
 * Service de requisição de dados dos produtos
 */

const getForSelect = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ProductSelect>> =>
	Crud.getList<ProductSelect>('product/select', params);

export default {
	getForSelect,
};
