/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Skel from 'react-loading-skeleton';

import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card } from 'components';
import {
	Input,
	Switch,
	DateTimeInput,
	Upload,
	Radio,
	Checkbox,
	TextArea,
	TextAreaEditor,
	FormikEffect,
} from 'components/Formik';
import uploadHelpers from 'components/upload/Helpers';
import { useToast } from 'hooks';
import careerService from 'services/career.service';
import newsNotificationService from 'services/newsNotification.service';
import {
	CareerForSelect,
	NewsNotification,
	NewsNotificationAttach,
} from 'types';
import { history } from 'utils';
import { dateFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import SkeletonForm from './newsNotification/SkeletonForm';
import { NewsNotificationForm } from './newsNotification/types';

export const defaultFormValue: NewsNotificationForm = {
	idNews: null,
	createdBy: null,
	title: '',
	description: '',
	isActive: true,
	releaseDate: dateFormatter.localeStringToIso(new Date().toLocaleString())!,
	status: '',
	imgUrl: [],
	attach: [],
	careers: [],
	types: [],
	persons: [],
	level: { idLevel: 1 },
};

const personsType: Array<{ id: number; name: string }> = [
	{ id: 2, name: 'Executivo' },
	{ id: 3, name: 'Ponto de Apoio' },
	{ id: 7, name: 'Ponto de Apoio Exclusivo' },
];

type PageNNType = {
	api: string;
	title: string;
	url: string;
	titlePlural: string;
};

const pageUrl: { [key: string]: PageNNType } = {
	news: {
		api: 'news',
		title: 'Notícia',
		url: 'news',
		titlePlural: 'Notícias',
	},
	notifications: {
		api: 'notification',
		title: 'Notificação',
		url: 'notifications',
		titlePlural: 'Notificações',
	},
};

const NewsNotificationEdit: React.FC = () => {
	const [toast] = useToast();
	const [viewers, setViewers] = useState<number>(1);
	const [careers, setCareers] = useState<CareerForSelect[]>([]);
	const [attaches, setAttaches] = useState<NewsNotificationAttach[]>([]);
	const [level, setLevel] = useState<number | null>(
		defaultFormValue.level?.idLevel ?? null
	);
	const { page: pageParam } = useParams<{ page: string }>();
	const page = pageUrl[pageParam];

	const getCareers = useCallback(async () => {
		try {
			const response = await careerService.getForSelect({
				params: { sort: '-name' },
				pagination: { paginate: 25, page: 1 },
			});
			setCareers(response.data);
			return response;
		} catch (error) {
			toast('Não foi possível carregar as graduações', {
				type: 'error',
			});
		}
	}, [toast]);

	const validationSchema = useMemo(() => {
		return Yup.object<NewsNotificationForm>({
			title: Yup.string().required('Campo obrigatório'),
			releaseDate: Yup.string()
				.transform((_, o) => (!o ? '' : o))
				.nullable(false)
				.required('Campo obrigatório'),
			description: Yup.lazy((_) => {
				if (page.url === 'news') {
					return Yup.string().trim().required('Campo obrigatório');
				}
				return Yup.string()
					.trim()
					.required('Campo obrigatório')
					.max(600, 'Campo deve ter no máximo 600 caracteres');
			}),
			idNews: Yup.mixed(),
			createdBy: Yup.mixed(),
			persons: Yup.mixed(),
			status: Yup.string(),
			level: Yup.mixed(),
		});
	}, [page]);

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return { newsNotification: { ...defaultFormValue } };
			}

			const { imgUrl, ...response } = await newsNotificationService.getById(
				Number(id),
				page.api
			);

			const newsNotification = {
				...response,
				// Transformar prop image em UploadFile[]
				...(imgUrl && { imgUrl: uploadHelpers.mapImages(imgUrl) }),
			};

			if (Array.isArray(newsNotification?.attach) && attaches.length === 0) {
				setAttaches(newsNotification.attach);
			}

			if (newsNotification.types?.length) {
				newsNotification.types = personsType.map((person) => {
					return newsNotification.types?.includes(String(person.id))
						? String(person.id)
						: undefined;
				});
			}

			if (newsNotification.careers?.length) {
				const resp = await getCareers();

				newsNotification.careers =
					resp?.data.map((career) => {
						return newsNotification.careers?.includes(String(career.idCareer))
							? String(career.idCareer)
							: undefined;
					}) ?? [];
			}

			if (newsNotification.level) {
				setLevel(newsNotification.level.idLevel);
			}

			if (newsNotification.persons && Array.isArray(newsNotification.persons)) {
				newsNotification.persons = newsNotification.persons.join();
			}

			newsNotification.isActive = newsNotification.isActive === '1';

			setViewers(
				newsNotification.persons?.length
					? 4
					: newsNotification.careers?.length
					? 3
					: newsNotification.types?.length
					? 2
					: 1
			);

			return { newsNotification };
		},
		[attaches.length, getCareers, page.api]
	);

	const handleSubmit = useCallback(
		async <T extends NewsNotificationForm>(
			id: string,
			{ attach, imgUrl, types, ...values }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const submit: NewsNotification = {
				...values,
				idLevel: level,
				careers: values.careers?.filter((c) => c ?? false), // Remove campos nulos
				types: types?.filter((t) => t ?? false), // Remove campos nulos
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(imgUrl) &&
					(uploadHelpers.isNewFile(imgUrl[0]?.url)
						? { imgUrl: imgUrl[0]?.url }
						: imgUrl.length === 0 && { imgUrl: '' })),
			};

			// Limpando dados das opções não selecionadas
			switch (Number(viewers)) {
				case 2:
					submit.careers = [];
					submit.persons = [];
					break;
				case 3:
					submit.types = [];
					submit.persons = [];
					break;
				case 4:
					submit.types = [];
					submit.careers = [];
					break;
				default:
					submit.types = [];
					submit.careers = [];
					submit.persons = [];
					break;
			}

			if (!Array.isArray(submit.persons)) {
				submit.persons = String(submit.persons)
					.split(',')
					.map((person) => Number(person));
			}

			try {
				// Cria/Atualiza registro
				const { data } = await newsNotificationService.createOrUpdate(
					submit.idNews,
					page.api,
					submit
				);

				if (page.url === 'news') {
					if (Array.isArray(submit.imgUrl) && !submit.imgUrl[0]?.error) {
						submit.imgUrl = submit.imgUrl[0]?.url
							? String(submit.imgUrl[0]?.url)
							: '';
					} else {
						submit.imgUrl = '';
					}
				}

				const { added, deleted } = uploadHelpers.processUploadFiles(
					attach,
					attaches,
					'idAttach'
				);

				// Adiciona attaches
				if (added.length) {
					await newsNotificationService.createAttaches(
						Number(submit.idNews ?? data.id),
						page.api,
						{
							attachs: added.map(
								(attachToAdd): NewsNotificationAttach => ({
									file: attachToAdd.url ?? '',
									name: attachToAdd.fileName ?? '',
								})
							),
						}
					);
				}

				// Deleta attaches
				for (const attachToDelete of deleted) {
					// eslint-disable-next-line no-await-in-loop
					await newsNotificationService.deleteAttach(
						attachToDelete.idAttach!,
						page.api
					);
				}

				toast(`${page.title} ${id ? 'alterada' : 'criada'} com sucesso`, {
					type: 'success',
				});
				history.push(`/admin/communication/${page.url}`);
			} catch (e) {
				toast(
					`Não foi possível editar a ${page.title.toLowerCase()}. Cód: ${
						e.code
					}`,
					{
						type: 'error',
					}
				);
			}

			setSubmitting(false);
		},
		[level, viewers, page.api, page.url, page.title, attaches, toast]
	);

	return (
		<PageLayoutFormWrapper
			title={page.title}
			breadcrumb={[
				{ label: 'Comunicação', path: '/admin/communication/news' },
				{
					label: page.titlePlural,
					path: `/admin/communication/${page.url}`,
				},
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.newsNotification}
					validationSchema={validationSchema}
				>
					{(form: FormikProps<NewsNotificationForm>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content>
									<Row>
										<Col md={8}>
											<FormGroupTitle>
												{id !== 'create' && (
													<Form.Group className="float-right d-inline">
														<Form.Label className="text-muted">
															Criado Por
														</Form.Label>{' '}
														{data!.newsNotification.createdBy?.name}
													</Form.Group>
												)}
											</FormGroupTitle>

											<Form.Row className="w-100">
												<Form.Group as={Col} sm={12}>
													<Form.Label>Título</Form.Label>
													<Input name="title" type="text" />
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Col md={5} sm={6}>
													<Form.Row className="h-100">
														{page.url === 'notifications' && (
															<Form.Group
																as={Col}
																lg={5}
																md={12}
																className="border-right"
															>
																<Form.Label>Prioridade</Form.Label>
																<Radio
																	name="idLevel"
																	value="1"
																	label="Baixa"
																	onChange={() => setLevel(1)}
																	defaultChecked={level === 1}
																/>
																<Radio
																	name="idLevel"
																	value="2"
																	label="Média"
																	onChange={() => setLevel(2)}
																	defaultChecked={level === 2}
																/>
																<Radio
																	name="idLevel"
																	value="3"
																	label="Alta"
																	onChange={() => {
																		setLevel(3);
																		if (careers.length === 0) {
																			getCareers();
																		}
																	}}
																	defaultChecked={level === 3}
																/>
															</Form.Group>
														)}

														<Form.Group
															as={Col}
															lg={7}
															md={12}
															className="pl-3"
														>
															<Form.Label>Exibir para:</Form.Label>
															<Radio
																name="viewers"
																value="1"
																label="Todos"
																onChange={() => setViewers(1)}
																defaultChecked={viewers === 1}
															/>
															<Radio
																name="viewers"
																value="2"
																label="Por Tipo"
																onChange={() => setViewers(2)}
																defaultChecked={viewers === 2}
															/>
															<Radio
																name="viewers"
																value="3"
																label="Por Graduação"
																onChange={() => {
																	setViewers(3);
																	if (careers.length === 0) {
																		getCareers();
																	}
																}}
																defaultChecked={viewers === 3}
															/>
															<Radio
																name="viewers"
																value="4"
																label="Ids Específicos"
																onChange={() => setViewers(4)}
																defaultChecked={viewers === 4}
															/>
														</Form.Group>
													</Form.Row>
												</Col>

												{viewers === 2 && (
													<Form.Group
														as={Col}
														md={7}
														sm={12}
														className="border-left pl-3"
													>
														<Form.Label>Tipo</Form.Label>
														{personsType.map((person, idx) => (
															<Checkbox
																name={`types[${idx}]`}
																value={String(person.id)}
																label={person.name}
																key={person.id}
																isValueNumber
															/>
														))}
													</Form.Group>
												)}
												{viewers === 3 && (
													<Form.Group
														as={Col}
														md={7}
														sm={12}
														className="border-left pl-3"
													>
														<Form.Label>Graduação</Form.Label>
														{careers.length === 0 ? (
															<Skel count={5} height="1.5rem" />
														) : (
															careers.map((career, idx) => (
																<Checkbox
																	name={`careers[${idx}]`}
																	value={String(career.idCareer)}
																	label={career.name}
																	key={String(career.idCareer)}
																	isValueNumber
																/>
															))
														)}
													</Form.Group>
												)}
												{viewers === 4 && (
													<Form.Group
														as={Col}
														md={7}
														sm={12}
														className="border-left pl-3"
													>
														<Form.Label>Ids Específicos</Form.Label>
														<TextArea
															as="textarea"
															name="persons"
															rows={5}
															resize="vertical"
														/>
														<Form.Text className="text-muted">
															Adicione Ids separados por virgula. Por exemplo:
															1234,1235,1236
														</Form.Text>
													</Form.Group>
												)}
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Conteúdo</Form.Label>
													{page.url === 'news' ? (
														<TextAreaEditor name="description" />
													) : (
														<TextArea
															name="description"
															className="form-control"
															rows={20}
															resize="none"
														/>
													)}
												</Form.Group>
											</Form.Row>
										</Col>

										<Col md={4} className="border-left">
											<Form.Row>
												<Form.Group as={Col} md={12} className="mb-0">
													<FormGroupTitle>
														Programação
														<Form.Group className="float-right d-inline">
															<Switch name="isActive" defaultChecked />
															<Form.Label className="text-muted pl-1">
																Ativo
															</Form.Label>
														</Form.Group>
													</FormGroupTitle>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Data Inicio</Form.Label>
													<DateTimeInput
														name="releaseDate"
														selectDate
														selectTime
													/>
												</Form.Group>
											</Form.Row>

											{page.url === 'news' && (
												<Form.Row>
													<Form.Group as={Col} md={12}>
														<Form.Label>Imagem</Form.Label>
														<Upload
															name="imgUrl"
															listType="picture-card"
															showUploadList={false}
															width="100%"
															height={200}
															accept=".jpg, .png"
															maxSize={2}
															displayInline
														>
															<FAIcon icon={faImage} />
															<div>Adicionar imagem</div>
														</Upload>
													</Form.Group>
												</Form.Row>
											)}

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label className="d-block">Anexos</Form.Label>
													<Upload
														name="attach"
														itemIdProp="idAttach"
														itemLastModifiedProp="date"
														itemUrlProp="file"
														listType="picture"
														accept=".jpg, .png, .pdf, .docx, .doc"
														maxSize={2}
														displayInline
														multiple
													>
														<Button variant="primary">
															<FAIcon icon="upload" className="mr-2" />
															Anexar arquivos
														</Button>
													</Upload>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>

								<Card.Content className="text-right border-top">
									<FormCancelButton
										to={`/admin/communication/${page.url}`}
										isSubmitting={form.isSubmitting}
									/>
									<FormSubmitButton isSubmitting={form.isSubmitting} />
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default NewsNotificationEdit;
