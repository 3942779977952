/* eslint-disable no-param-reassign */
import RegexFormatter, { REGEX_CLEAR_NUMERIC } from './Regex';

class PhoneFormatter extends RegexFormatter {
	constructor(private locale = 'pt-BR') {
		super(
			/^(?:(\d{1,2})(\d{0,4})(\d{0,4})$|(\d{1,2})(\d{0,5})(\d{0,4})$)/g,
			(
				_: string,
				$1: string,
				$2: string,
				$3: string,
				$4: string,
				$5: string,
				$6: string
			) => {
				if ($4) {
					$1 = $4;
					$2 = $5;
					$3 = $6;
				}
				// eslint-disable-next-line no-nested-ternary
				let result = $1 ? ($2 ? `(${$1})` : $1) : '';
				result += $2 ? ` ${$2}` : '';
				result += $3 ? `-${$3}` : '';
				return result;
			},
			{
				clear: REGEX_CLEAR_NUMERIC,
				maxLength: 11,
			}
		);
	}

	formatForSearch = (value: string) => value.trim().replace(/[\s\-()]/g, '');
}

export default PhoneFormatter;
