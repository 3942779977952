import React, { useEffect } from 'react';

import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import faAmk from 'components/FontAwesomeIcons/faAmk';
import { useModal } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import { currencyFormatter } from 'utils/formatters';
import history from 'utils/history';

import { FilterContainer } from 'packages/admin/components/datatableView';
import PayoutInfoModal from 'packages/escritorio/pages/financial/payout/PayoutInfoModal';
import {
	Title,
	Credit,
} from 'packages/escritorio/pages/financial/payout/styles';

const defaultFilterValues = {};

const PayoutFilters: React.FC = () => {
	const open = useModal();

	const {
		executive: { balance: credit },
	} = useSessionContext();

	useEffect(() => {
		open(PayoutInfoModal, {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handlers
	const handleOnRequest = () => {
		history.push('/app/financial/payout/request');
	};

	return (
		<>
			<FilterContainer initialValues={defaultFilterValues}>
				<Form.Row className="m-2">
					<FormGroup
						as={Col}
						className="d-flex align-items-start justify-content-start"
					>
						<Title>
							{credit === null ? (
								<span>Carregando...</span>
							) : (
								<>
									{'Você tem '}
									<FAIcon icon={faAmk} />
									<Credit>{` ${currencyFormatter.format(credit)} `}</Credit>
									disponíveis
									<Button
										variant="outline-primary"
										className="ml-3"
										onClick={handleOnRequest}
									>
										Solicitar Saque
									</Button>
								</>
							)}
						</Title>
					</FormGroup>
				</Form.Row>
			</FilterContainer>
		</>
	);
};

export default PayoutFilters;
