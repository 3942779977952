import { ReactFragment } from 'react';

import FindObjectProperty from 'utils/FindObjectProperty';

import Utils from './date/Utils';
import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from './IFormatter';
import { REGEX_CLEAR_NUMERIC, REGEX_MASKED_DATE } from './Regex';

type DateOptions = Partial<{
	timeZone: string;
	weekday: 'long' | 'short' | 'narrow' | undefined;
	year: 'numeric' | '2-digit' | undefined;
	month: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit' | undefined;
	day: 'numeric' | '2-digit' | undefined;
	hour: 'numeric' | '2-digit' | undefined;
	hour12: boolean;
	minute: 'numeric' | '2-digit' | undefined;
	second: 'numeric' | '2-digit' | undefined;
}>;

export const dateOptions: DateOptions = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
};

export const timeOptions: DateOptions = {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};

export const hourMinOptions: DateOptions = {
	hour: '2-digit',
	minute: '2-digit',
};

export const dateTimeOptions: DateOptions = {
	...dateOptions,
	...timeOptions,
};

export const dateHourMinOptions: DateOptions = {
	...dateOptions,
	...hourMinOptions,
};

class DateFormatter implements FormatterType {
	constructor(
		private locale = 'pt-BR',
		private options: DateOptions = dateOptions
	) {}

	isInvalid = (date: FormatterParamType): boolean =>
		!date ||
		(typeof date !== 'string' && toString.call(date) !== '[object Date]');

	formatForInput = (date: FormatterParamType): [string, string] => {
		if (this.isInvalid(date)) {
			return ['', ''];
		}
		let num = ((date as string) || '').replace(REGEX_CLEAR_NUMERIC, '');
		let result = '';
		let isoDate = '';

		let dateOrder = '';
		if (this.options.day) {
			dateOrder += 'd';
		}
		if (this.options.month) {
			dateOrder += 'm';
		}
		if (this.options.year) {
			dateOrder += 'y';
		}
		if (dateOrder.length > 0) {
			const [d, count] = Utils.extractDate(num, dateOrder);
			result = d;
			num = num.substring(count);

			const iso = result.split('/');
			if (iso.length === 3) {
				isoDate = `${iso[2]}-${iso[1]}-${iso[0]}`;
			}
		}

		if (this.options.hour) {
			const [h] = Utils.extractTime(num, !!this.options.second);
			if (result.length > 0) {
				result += ' ';
			}
			result += h;

			if (isoDate.length > 0) {
				const isoHour = h.split(':');
				for (let i = 0; i < 3; ++i) {
					isoDate += `${i > 0 ? '-' : 'T'}${
						isoHour.length <= i ? '00' : isoHour[i]
					}`;
				}
			}
		}

		return [isoDate, result];
	};

	formatForSearch = (val: string) =>
		val
			.trim()
			.replace(REGEX_MASKED_DATE, '$4-$3-$2$5')
			.replace(/^-+(.*)/, '$1');

	format = (
		field: FormatterParamType,
		row?: FormatterParamRowType
	): string | ReactFragment => {
		const date = !row ? field : FindObjectProperty(row, field as string) || '';
		let result = '';
		if (this.isInvalid(date)) {
			return '';
		}

		try {
			// result = new Intl.DateTimeFormat(this.locale, this.options).format(new Date(data as string));

			// É necessário que exista o horário na string para o Date() não encarar como UTC
			const dateAdjusted =
				date.toString().length === 10 ? `${date} 00:00` : date;

			result = new Date(dateAdjusted as string).toLocaleString(
				this.locale,
				this.options
			);
		} catch (e) {
			//
		}
		return result;
	};

	/**
	 * Converte uma string no formato pt-BR "DD/MM/YYYY HH:mm:ss" para ISO "YYYY-MM-DD HH:mm:ss"
	 * @param localeStrDate String de data no formato pt-BR (DD/MM/YYYY HH:mm:ss)
	 */
	localeStringToIso = (
		localeStrDate: string,
		returnUTCDate = false
	): string | null => {
		// Data incompleta
		const numbers = localeStrDate
			.toString()
			.replace(REGEX_CLEAR_NUMERIC, '')
			.split('');
		if (![14, 12, 8, 4].includes(numbers.length)) {
			return '';
		}

		// Validação da data
		const match = REGEX_MASKED_DATE.exec(localeStrDate);
		if (match?.groups) {
			const formatted = localeStrDate.replace(REGEX_MASKED_DATE, '$4-$3-$2$5');

			// É necessário que exista o horário na string para o Date() não encarar como UTC
			const adjusted =
				match.groups.time || returnUTCDate
					? formatted
					: `${formatted} 00:00:00`;

			const date = new Date(adjusted);

			// Data válida (foi necessário comparar o dia porque o chrome encara 31 de fev como válido)
			if (
				// eslint-disable-next-line no-restricted-globals
				!isNaN(date.getTime()) &&
				(returnUTCDate || date.getDate() === Number(match.groups.day))
			) {
				return returnUTCDate ? date.toISOString() : adjusted;
			}
		}

		// Data inválida
		return null;
	};
}

export default DateFormatter;
