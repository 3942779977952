import { ID, HttpPostResultAny, Recognition, HttpGetListParams } from 'types';

import Crud from './crud.service';

const getList = (params: HttpGetListParams): Promise<Recognition[]> =>
	Crud.getListData('mmn/badge', params);

const createOrUpdate = (
	id: ID | null,
	data: Recognition
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('mmn/badge/{id}', id as number, data);
	}
	return Crud.create('mmn/badge', data);
};

export default {
	getList,
	createOrUpdate,
};
