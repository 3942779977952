import { Popover, PopoverProps, Col, ColProps, Row } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

// Types
type GridProps = {
	columns: number;
	isInvalid?: boolean;
};

type GridGroupProps = {
	columns: number;
};

type GridItemProps = {
	span?: boolean;
	selected?: boolean;
	disabled?: boolean;
};

type GridBackdropProps = {
	show: boolean;
};

type ListGroupProps = {
	align?: 'start' | 'center' | 'end' | 'normal';
	height?: string;
};

type ListGroupItemProps = {
	selected?: boolean;
};

type ListGroupImageWrapperProps = {
	selected?: boolean;
};

type ContainerProps = {
	selected?: boolean;
};

type StyledColProps = ColProps & {
	numRows: number;
};

// Transitions
// TODO: (globalizar?)
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

/**
 * Grid (FeatureGrid)
 */

const StyledGrid = styled.div<GridProps>`
	position: relative;

	width: 100%;
	display: grid;
	grid-template-columns: ${({ columns }) =>
		`repeat(${columns}, minmax(160px, 1fr))`};

	grid-gap: 10px;
	overflow: hidden;

	${({ isInvalid }) => (isInvalid ? 'border: var(--danger) 1px solid;' : '')}
	border-radius: calc(var(--border-radius-lg) + 1px);
	padding: 0.25rem;

	@media (max-width: 700px) {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
`;

const GridGroup = styled.div<GridGroupProps>`
	display: grid;
	grid-template-columns: ${({ columns }) =>
		`repeat(${columns}, minmax(75px, 1fr))`};

	${({ columns }) =>
		columns > 0
			? `grid-template-rows: repeat(${columns}, minmax(75px, 1fr));`
			: ''}

	grid-gap: 10px;
	overflow: hidden;
`;

const GridRemoveButton = styled.div`
	position: absolute;
	top: 0.4rem;
	right: 0.4rem;
	width: 1.8rem;
	height: 1.8rem;
	padding: 0.31rem;
	cursor: pointer;
	border-radius: var(--border-radius);

	&:hover {
		background-color: var(--gray-20);
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 1.2rem;
		top: 50%;
		height: 1px;
		background: var(--gray-50);
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
`;

const GridItem = styled.div<GridItemProps>`
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	font-weight: 100;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: var(--border-radius-lg);
	border: solid 1px
		${({ selected }) => (selected ? 'var(--gray-40)' : 'var(--border-color)')};

	transition: 0.3s;
	cursor: pointer;

	${({ span }) => (span ? 'grid-column: -3 / -1;' : '')}
	${({ disabled }) => (disabled ? 'filter: grayscale(100%) opacity(70%);' : '')}

	> img {
		position: absolute;
		top: 0;
		left: 0;
		transition: 0.3s;
		border-radius: calc(var(--border-radius-lg) + 1px);
		object-fit: scale-down;
		width: 100%;
		height: 100%;
	}

	> span {
		position: absolute;
		top: calc(50% - 0.75rem);
	}
`;

const GridBackdrop = styled.div<GridBackdropProps>`
	position: absolute;

	height: 100%;
	width: 100%;

	z-index: ${({ show }) => (show ? '1' : '-1')};
	opacity: ${({ show }) => (show ? '1' : '0')};
	animation: ${({ show }) => (show ? fadeIn : fadeOut)} 0.3s alternate;
`;

export const Grid = Object.assign(StyledGrid, {
	Group: GridGroup,
	Item: GridItem,
	Backdrop: GridBackdrop,
	RemoveButton: GridRemoveButton,
});

/**
 * ListGroup (FeatureGrid)
 */

const ListGroupItem = styled.div<ListGroupItemProps>`
	padding: 0.5rem 1rem;
	cursor: pointer;

	border-radius: var(--border-radius-lg) 0 0 var(--border-radius-lg);
	${({ selected }) => selected && 'background: var(--gray-20);'}

	:hover {
		background: var(--gray-20);
	}
`;

const ListGroupImageWrapper = styled.div<ListGroupImageWrapperProps>`
	position: relative;
	width: 80%;
	padding-bottom: 80%;
	margin: 0 auto;

	border-radius: var(--border-radius-lg);
	border: solid 1px
		${({ selected }) => (selected ? 'var(--gray-40)' : 'var(--border-color)')};
	${({ selected }) =>
		selected ? 'box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);' : ''}

	transition: 0.3s;
	cursor: pointer;

	${({ selected }) => (selected ? 'z-index: 1;' : '')}

	> img {
		position: absolute;
		border-radius: calc(var(--border-radius-lg) + 1px);
		object-fit: scale-down;
		width: 100%;
		height: 100%;
		background: var(--always-white);

		&:hover {
			filter: brightness(110%);
		}
	}
`;

const ListGroupAlignMap = {
	start: 'flex-start',
	center: 'center',
	end: 'flex-end',
	normal: 'normal',
};

const StyledListGroup = styled.div<ListGroupProps>`
	width: 100%;
	${({ height }) => (height ? `height: ${height};` : '')}
	padding: 1rem 0;

	display: flex;
	flex-direction: column;
	align-items: ${({ align = 'normal' }) => ListGroupAlignMap[align]};

	> ${ListGroupImageWrapper} {
		:not(:first-child) {
			margin-top: 1rem;
		}
	}
`;

export const ListGroup = Object.assign(StyledListGroup, {
	Item: ListGroupItem,
	ImageWrapper: ListGroupImageWrapper,
});

/**
 * Container (FeatureGrid)
 */

export const Container = styled.div<ContainerProps>`
	width: 100%;
	max-height: 100%;

	border-radius: var(--border-radius-lg);
	${({ selected }) => selected && 'background: var(--gray-20);'}

	/* Sem Scrollbar */
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
`;

/**
 * Popover
 */

export const StyledPopover = styled(Popover)<PopoverProps>`
	width: 500px;
	max-width: 90vw;
	max-height: 95vh;
	padding: 1rem;
	overflow: hidden;
	overflow-y: auto;

	background: var(--base-bg);
	box-shadow: var(--base-box-shadow);
`;

/**
 * StyledRow
 */

export const StyledRow = styled(Row)`
	height: 100%;
	margin: -1px 0;
`;

/**
 * StyledCol
 */

export const StyledCol = styled(Col)<StyledColProps>`
	/* height: 100%; */
	padding: 0;

	/**
	 * Override para que a altura da soma dos componentes filhos
	 * se mantenha fixada em 100%. A última linha se expandirá
	 * até o final. Cada componente filho ficará em uma linha e
	 * é necessário especificar a quantidade de linhas
	 */
	display: grid;
	grid-template-rows: ${({ numRows }) =>
			`repeat(${numRows - 1}, minmax(0, auto))`} 1fr;
`;

StyledCol.defaultProps = {
	numRows: 1,
};
