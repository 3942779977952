import React from 'react';

import { Formik, Form } from 'formik';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import AddressForm, { formSchema } from 'forms/AddressForm';
import * as Yup from 'yup';

import { PersonAddress } from 'types';

export type AddressEditForm = {
	address: PersonAddress;
};

export type AddressEditProps = {
	isLoading?: boolean;
	address?: PersonAddress;
	onSubmit: (values: PersonAddress) => void;
	onCancel: () => void;
};

const validationSchema = Yup.object({
	address: formSchema,
});

const AddressEdit: React.FC<AddressEditProps> = ({
	isLoading,
	address,
	onSubmit,
	onCancel,
}) => {
	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={{ address }}
			onSubmit={(values) => onSubmit(values.address as PersonAddress)}
		>
			<Form>
				<AddressForm path="address" editName />
				<div className="text-right">
					<Button
						className="mr-2"
						variant="outline-primary"
						type="button"
						disabled={isLoading}
						onClick={onCancel}
					>
						Cancelar
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading}>
						{isLoading ? (
							<>
								<Spinner animation="border" />
								<span className="ml-2">Enviando</span>
							</>
						) : (
							'Confirmar'
						)}
					</Button>
				</div>
			</Form>
		</Formik>
	);
};

export default AddressEdit;
