import React from 'react';

import { faBuilding, faCircle, faCogs } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import SystemCompanyEdit from './Company';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const SystemRoutes: RouteDef = {
	path: '/admin/system',
	menu: {
		name: 'Sistema',
		icon: faCogs,
	},
	children: [
		{
			path: '/companies',
			render: getListSceneRenderFn(listConfigs.SystemCompanies),
			exact: true,
			menu: {
				name: 'Empresas',
				icon: faBuilding,
			},
		},
		{
			path: '/companies/:id',
			component: SystemCompanyEdit,
			exact: true,
		},
		{
			path: '/modules',
			menu: {
				name: 'Módulos',
				icon: faCircle,
			},
		},
		{
			path: '/pagseguro',
			menu: {
				name: 'PagSeguro',
				icon: faCircle,
			},
		},
	],
};

export default SystemRoutes;
