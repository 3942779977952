import { DatagridState, DatagridData } from 'components/datagrid/types';
import { Pagination, HttpBaseResult } from 'types';

export enum DatatableViewEnum {
	SetParams = '@datatable/setParams',
	SetLoading = '@datatable/setLoading',
	SetFetchData = '@datatable/setFetchData',
	SetFetchError = '@datatable/setFetchError',
}

export type DatatableViewState = {
	tableParams: DatagridState | undefined;
	fetchData: DatagridData;
};

export type DatatableViewActionTypes =
	| {
			type: DatatableViewEnum.SetParams;
			payload?: DatagridState;
	  }
	| {
			type: DatatableViewEnum.SetLoading;
			payload: boolean;
	  }
	| {
			type: DatatableViewEnum.SetFetchData;
			payload: {
				data: unknown[];
				pagination: Pagination | null;
			};
	  }
	| {
			type: DatatableViewEnum.SetFetchError;
			payload: HttpBaseResult;
	  };
