import styled from 'styled-components';

type Default = {
	align?: 'center' | 'left' | 'right' | 'justify';
};

type PanelType = Default & {
	width?: 'full' | 'fit' | 'min' | 'max' | number;
	variant?: 'info' | 'success' | 'warning' | 'danger';
};

type TitleType = Default;

type TextType = Default;

const Panel = styled.div`
	padding: 1rem;
	margin: 1rem auto;
	width: 100%;
	min-width: min-content;
	max-width: ${(props: PanelType) =>
		(props.width === 'full' && '100%') ||
		(props.width === 'fit' && 'fit-content') ||
		(props.width === 'min' && 'min-content') ||
		(props.width === 'max' && 'max-content') ||
		(props.width && `${props.width}px`) ||
		'390px'};

	display: flex;
	flex-direction: column;
	align-items: ${(props: PanelType) =>
		(props.align === 'left' && 'flex-start') ||
		(props.align === 'right' && 'flex-end') ||
		(props.align === 'center' && 'center') ||
		'flex-start'};

	color: ${(props: PanelType) =>
		props.variant ? 'var(--light)' : 'var(--base-color)'};

	background-color: ${(props: PanelType) =>
		(props.variant && `var(--${props.variant})`) || 'var(--always-white)'};

	cursor: ${(props) => (props.onClick ? 'pointer' : 'unset')};
	border: 1px solid var(--border-color);
	border-radius: 4px;

	> :not(:first-child) {
		margin-top: 1rem;
	}

	> * {
		${(props: PanelType) => (props.align ? `text-align: ${props.align}` : '')};
	}
`;

const Title = styled.div`
	width: 100%;
	font-size: 1.2rem;
	font-weight: 500;
	white-space: pre-line;

	${(props: TitleType) => (props.align ? `text-align: ${props.align}` : '')};
`;

const Text = styled.div`
	width: 100%;
	font-size: 1.1rem;
	white-space: pre-line;

	${(props: TextType) => (props.align ? `text-align: ${props.align}` : '')};
`;

export default Object.assign(Panel, {
	Title,
	Text,
});
