import styled from 'styled-components';

import classNames from 'classnames';

const CategoryBanner = styled.div.attrs((props) => ({
	className: classNames(props.className, 'bg-dark'),
}))`
	height: 200px;
`;

export default CategoryBanner;
