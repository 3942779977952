import { EcStoreConfig, HttpBaseResult } from 'types';
import { action, Action } from 'typesafe-actions';

import { EcommerceActionEnum } from './types';

const storeSuccess = (config: EcStoreConfig): Action =>
	action(EcommerceActionEnum.StoreSuccess, { config });

const storeFailed = (error: HttpBaseResult): Action =>
	action(EcommerceActionEnum.StoreFailed, error);

const showShoppingCart = (): Action =>
	action(EcommerceActionEnum.ShowShoppingCart);

const hideShoppingCart = (): Action =>
	action(EcommerceActionEnum.HideShoppingCart);

export default {
	storeSuccess,
	storeFailed,
	showShoppingCart,
	hideShoppingCart,
};
