import React from 'react';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import DatatableView from 'packages/admin/components/DatatableView';
import PageHeader from 'packages/admin/components/PageHeader';

import { ListNewButton, ListToBackButton } from '../components';

import { ListSceneConfig } from './list/types';

export type ListSceneProps = {
	config: ListSceneConfig;
};

const ListScene: React.FC<ListSceneProps> = ({
	config,
}): React.ReactElement => (
	<Container fluid className="d-flex flex-column flex-grow-1">
		{config?.title && config?.breadcrumbItems && (
			<>
				<PageHeader>
					<PageHeader.Title>{config.title}</PageHeader.Title>
					{config.newItemLink && <ListNewButton to={config.newItemLink} />}
					<PageHeader.Breadcrumb
						className="ml-auto"
						items={config.breadcrumbItems}
						pathHome={config.pathHome}
					/>
				</PageHeader>

				{config.toBackLink && <ListToBackButton to={config.toBackLink} />}
			</>
		)}

		{config?.datatableConfig && config?.action && (
			<Card className="shadow p-2 mb-2 flex-grow-1">
				<DatatableView
					config={config.datatableConfig}
					serviceMethod={config.action}
				>
					{config.datatableFilters}
				</DatatableView>
			</Card>
		)}
	</Container>
);

ListScene.displayName = 'ListScene';
ListScene.whyDidYouRender = true;

export default ListScene;
