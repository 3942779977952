export type JWTBaseObject = {
	iss: string; // issuer
	iat: number; // issued at
	exp: number; // expiration time
	nbf: number; // not valid before
	jti: string; // JWT ID
	sub: string; // subject
};

export type JWTObject<T> = JWTBaseObject & {
	[extra: string]: T;
};

let sessionToken: string | null = null;

const getSessionToken = (): string | null => sessionToken;

const setSessionToken = (token: string | null): void => {
	sessionToken = token;
};

const getExpirationDate = (obj: JWTBaseObject): Date =>
	new Date(obj.exp * 1000);

const getIssuedAtDate = (obj: JWTBaseObject): Date => new Date(obj.iat * 1000);

const getNotBeforeDate = (obj: JWTBaseObject): Date => new Date(obj.nbf * 1000);

const extractData = (token: string): JWTBaseObject | null => {
	if (!token) {
		return null;
	}

	const parts = token.split('.');
	if (parts.length !== 3) {
		return null;
	}

	return JSON.parse(atob(parts[1]));
};

export default {
	getSessionToken,
	setSessionToken,
	getExpirationDate,
	getIssuedAtDate,
	getNotBeforeDate,
	extractData,
};
