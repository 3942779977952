import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	ExecutivePayout,
	Payout,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutivePayout>> =>
	Crud.getList<ExecutivePayout>('my/payout', params);

const getById = (id: ID): Promise<ExecutivePayout> =>
	Crud.getOne<ExecutivePayout>('my/payout/{id}', id);

const requestPayout = (data: Payout): Promise<HttpPostResultAny> =>
	Crud.create('my/payout', data);

export default {
	getForList,
	getById,
	requestPayout,
};
