import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';

import { ApiCodes, HttpPostResult } from 'types';
import history from 'utils/history';
import JWT from 'utils/jwt';

import * as envJson from '../config/env.json';

axios.defaults.withCredentials = true;

let api: AxiosInstance;

let refreshSessionPromise: Promise<string> | null = null;

const defaultHeaders = {
	Accept: 'application/json',
	// 'Access-Control-Allow-Origin': '*',
	// 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
};

const applySessionInterceptor = (instance: AxiosInstance): void => {
	instance.interceptors.request.use(
		(config) => {
			// Injetando default headers
			let headers = {
				...defaultHeaders,
				...config.headers,
			};

			// Access Token
			const token = JWT.getSessionToken();

			if (token) {
				headers = {
					...headers,
					Authorization: `Bearer ${token}`,
				};
			}

			return { ...config, headers };
		},
		async (error: AxiosError) => Promise.reject(error)
	);

	instance.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			if (error.response) {
				const { code } = error.response.data;
				if (
					code === ApiCodes.Error.InvalidToken ||
					code === ApiCodes.Error.TokenExpired
				) {
					if (error.config.url?.match(/.*logout.*/)) {
						return Promise.reject(error);
					}
					// Tentando renovar o Access Token usando o Refresh Token
					if (refreshSessionPromise === null) {
						refreshSessionPromise = axios
							.post<HttpPostResult<{ token: string }>>(
								`${error.config.baseURL}/auth/refresh`
							)
							.then((res) => {
								if (res.data?.data?.token) {
									const { token } = res.data.data;
									JWT.setSessionToken(token);
									return token;
								}
								return '';
							});
					}
					return Promise.all([refreshSessionPromise])
						.then(([token]) => {
							refreshSessionPromise = null;
							if (token !== '') {
								// Refazendo a solicitação anterior com o novo Access Token
								const config = {
									...error.config,
									headers: {
										...error.config.headers,
										Authorization: `bearer ${token}`,
									},
								};

								return instance.request(config);
							}
							return Promise.reject(error);
						})
						.catch((_) => {
							history.replace(`/logout?redirect=${history.location.pathname}`);
							refreshSessionPromise = null;
							return Promise.reject(error);
						});
				}
			}
			return Promise.reject(error);
		}
	);
};

export const createApi = async (): Promise<AxiosInstance> =>
	axios
		.get('/assets/env.json', { headers: defaultHeaders })
		.then((response: AxiosResponse<typeof envJson>) => response.data)
		.then((env) => {
			api = axios.create({ baseURL: env.api_url || envJson.api_url });
			applySessionInterceptor(api);
			return api;
		})
		.catch(() => {
			api = axios.create({ baseURL: envJson.api_url });
			applySessionInterceptor(api);
			return api;
		});

export const getApi = (): AxiosInstance => api;
