import React from 'react';

import styled from 'styled-components';

import classNames from 'classnames';

export type ScoreBarProps = {
	score: number;
	height?: string;
	className?: string;
};

const ScoreBarSegment = styled.div.attrs((props: { height: string }) => props)`
	width: 100%;
	height: ${({ height }) => height};
	background: rgb(246, 106, 65);
	background: linear-gradient(
		90deg,
		rgba(246, 106, 65, 1) 0%,
		rgba(249, 227, 65, 1) 25%,
		rgba(221, 247, 107, 1) 51%,
		rgba(123, 233, 63, 1) 75%,
		rgba(121, 249, 112, 1) 100%
	);
`;

const ScoreBarCover = styled.div.attrs((props: { percent: number }) => props)`
	position: absolute;
	background: var(--gray-10);
	right: 0;
	top: 0;
	bottom: 0;
	left: ${({ percent }) => percent}%;
`;

const ScoreBar: React.FC<ScoreBarProps> = ({ score, height, className }) => (
	<div className={classNames(className, 'position-relative border')}>
		<ScoreBarSegment height={height} />
		<ScoreBarCover percent={Math.min(100, Math.max(0, score))} />
	</div>
);

ScoreBar.displayName = 'ScoreBar';
ScoreBar.defaultProps = { height: '0.6rem' };
ScoreBar.whyDidYouRender = true;

export default ScoreBar;
