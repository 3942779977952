import systemUserService from 'services/system-user.service';
import { AuthACLRole } from 'types';
import {
	editLinkFormatter,
	coloredStatusFormatter,
	buttonLinkFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import DeleteProfileButton from 'packages/admin/pages/access/profile/DeleteProfileButton';
import { personStatusByName } from 'packages/admin/utils/coloredStatus';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Acesso',
		path: '/admin/access',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	AccessUsers: {
		title: 'Usuários',
		breadcrumbItems: [...adminBreadcrumb, { label: 'Usuários', active: true }],
		action: systemUserService.getForSelect,
		newItemLink: `${adminBreadcrumb[0].path}/users/create`,
		datatableConfig: {
			sortByKey: 'name',
			sortByDesc: false,
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/users/{id}`,
					}),
				},
				{ key: 'name', title: 'Nome', searchable: true },
				{
					key: 'email',
					title: 'E-mail',
					searchable: true,
				},
				{
					key: 'companyName',
					title: 'Empresa',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'departmentName',
					title: 'Departamento',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'timetableName',
					title: 'Horário',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: personStatusByName,
					}),
				},
			],
		},
	},

	AccessProfiles: {
		title: 'Perfis de Acesso',
		breadcrumbItems: [
			...adminBreadcrumb,
			{ label: 'Perfis de Acesso', active: true },
		],
		newItemLink: `${adminBreadcrumb[0].path}/profiles/create`,
		action: systemUserService.getProfiles,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idRole',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/profiles/{id}`,
					}),
				},
				{ key: 'name', title: 'Nome', searchable: true },
				{ key: 'description', title: 'Descrição', searchable: true },
				{
					key: 'blocked',
					title: 'Opções',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter<AuthACLRole, void>({
						component: DeleteProfileButton,
					}),
				},
			],
		},
	},

	AccessSchedule: {
		title: 'Horários',
		breadcrumbItems: [...adminBreadcrumb, { label: 'Horários', active: true }],
		newItemLink: `${adminBreadcrumb[0].path}/schedules/create`,
		action: systemUserService.getSchedules,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idTimetable',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/schedules/{id}`,
					}),
				},
				{ key: 'name', title: 'Nome', searchable: true },
			],
		},
	},
};

export default ScenesConfig;
