import RegexFormatter, { REGEX_CLEAR_NUMERIC } from './Regex';

class CNAEFormatter extends RegexFormatter {
	constructor() {
		super(
			/^(\d{1,4})(\d{0,1})(\d{0,2})/g,
			(_: string, $1: string, $2: string, $3: string) => {
				let result = $1;
				result += $2 ? `-${$2}` : '';
				result += $3 ? `/${$3}` : '';
				return result;
			},
			{
				clear: REGEX_CLEAR_NUMERIC,
				maxLength: 7,
			}
		);
	}

	formatForSearch = (value: string) => value.trim().replace(/[-/]/g, '');
}

export default CNAEFormatter;
