/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';

import { useFormikContext } from 'formik';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from 'react-beautiful-dnd';
import {
	Accordion,
	Button,
	Card,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { faChevronDown, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Input } from 'components/Formik';
import { ExpeditionStation } from 'types';

import { reorder, sortOptions } from './ExpeditionUtils';
import { Container, DroppableContent } from './stationAccordion/styles';

export interface StationAccordionProps {
	data: string;
	loading?: boolean;
	onChangeItems?: (items: ExpeditionStation[]) => void;
	onRemoveItem?: (item: ExpeditionStation, index: number) => void;
	children: (item: ExpeditionStation, index: number) => React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

const defaultProps = {
	loading: false,
};

const StationAccordion: React.FC<StationAccordionProps> = (props) => {
	const {
		data,
		loading,
		onChangeItems,
		onRemoveItem,
		children,
		...rest
	} = props;

	const formikCtx = useFormikContext();
	const { value: values } = formikCtx.getFieldMeta<any[]>(data);

	const [items, setItems] = useState<any[]>([]);

	useEffect(() => {
		const optionsOrdened = sortOptions(values);
		setItems(optionsOrdened);
	}, [values]);

	function onDragEnd(result: DropResult) {
		if (!result.destination) return;

		const itemsReordered = reorder(
			items,
			result.source.index,
			result.destination.index
		);
		const optionsOrdered = sortOptions(itemsReordered);

		setItems(optionsOrdered);
		if (onChangeItems) onChangeItems(optionsOrdered);
	}

	const handleRemoveItem = useCallback(
		(item: any, index: number) => {
			if (onRemoveItem) onRemoveItem(item, index);
		},
		[onRemoveItem]
	);

	return (
		<Accordion>
			<DragDropContext onDragEnd={onDragEnd}>
				<Container {...rest}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<DroppableContent
								{...provided.droppableProps}
								ref={provided.innerRef}
								isDraggingOver={snapshot.isDraggingOver}
							>
								{items && loading ? (
									<Skeleton count={3} />
								) : (
									<Card className="mb-0">
										{items.map(
											(item, index) =>
												!item.delete && (
													<Draggable
														key={item.sort}
														draggableId={String(item.sort)}
														index={index}
													>
														{(
															providedDraggable: any,
															snapshotDraggable: any
														) => (
															<>
																<Card.Header
																	className="d-flex align-items-center bg-light"
																	ref={providedDraggable?.innerRef}
																	{...providedDraggable.draggableProps}
																	{...providedDraggable.dragHandleProps}
																	isDragging={snapshotDraggable.isDragging}
																>
																	<span className="mr-3 text-muted">
																		<FAIcon icon="grip-vertical" />
																	</span>
																	<Input
																		name={`station[${index}].name`}
																		placeholder="Nome da estação"
																	/>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id={`remove-${index}`}>
																				Remover estação
																			</Tooltip>
																		}
																	>
																		<Button
																			variant="link"
																			className="ml-auto text-danger"
																			onClick={() => {
																				handleRemoveItem(item, index);
																			}}
																		>
																			<FAIcon icon={faTrashAlt} />
																		</Button>
																	</OverlayTrigger>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id={`accordion-${index}`}>
																				Ver produtos
																			</Tooltip>
																		}
																	>
																		<Accordion.Toggle
																			as={Button}
																			variant="link"
																			className="text-dark"
																			eventKey={String(index)}
																		>
																			<FAIcon icon={faChevronDown} />
																		</Accordion.Toggle>
																	</OverlayTrigger>
																</Card.Header>
																<Accordion.Collapse eventKey={String(index)}>
																	<Card.Body>{children(item, index)}</Card.Body>
																</Accordion.Collapse>
															</>
														)}
													</Draggable>
												)
										)}
									</Card>
								)}
								{provided.placeholder}
							</DroppableContent>
						)}
					</Droppable>
				</Container>
			</DragDropContext>
		</Accordion>
	);
};

StationAccordion.displayName = 'StationAccordion';
StationAccordion.defaultProps = defaultProps;
StationAccordion.whyDidYouRender = true;

export default StationAccordion;
