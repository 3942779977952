import React, { BaseSyntheticEvent, useEffect } from 'react';

const useOnClickOutside = (
	ref: React.RefObject<HTMLElement>,
	onClick: () => void
) => {
	useEffect(() => {
		const listener = (event: Event | BaseSyntheticEvent) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			event.stopPropagation();
			onClick();
		};

		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	});
};

export default useOnClickOutside;
