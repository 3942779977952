/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';

import {
	Formik,
	Form as FormikForm,
	FieldArray,
	ArrayHelpers,
	FormikProps,
	FormikHelpers,
} from 'formik';
import { Table } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { faImage, faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Button, Card, Skeleton, List } from 'components';
import {
	Input,
	Switch,
	Upload,
	AsyncSelect,
	TextArea,
	FormikEffect,
} from 'components/Formik';
import { AddActions } from 'components/list/styles';
import uploadHelpers from 'components/upload/Helpers';
import { UploadFile } from 'components/upload/types';
import { useToast } from 'hooks';
import commissionService from 'services/commission.service';
import ecommerceService from 'services/ecommerce.service';
import subscriptionService from 'services/subscriptions.service';
import {
	CommissionList,
	CommissionTable,
	MenuStore,
	Subscription,
	SubscriptionCategory,
} from 'types';
import { currencyFormatter } from 'utils/formatters';
import history from 'utils/history';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import SkeletonForm from './subscription/SkeletonForm';
import { SubscriptionFormType } from './subscription/types';

export const defaultFormValue: SubscriptionFormType = {
	idSubscription: null,
	name: '',
	minValue: '',
	maxValue: '',
	daysOfRenovation: '',
	valueOfRenovation: '',
	image: [],
	description: '',
	comission: null,
	activation: null,
	category: [],
	isActive: true,
	category_select: null,
};

export const formSchema = Yup.object<SubscriptionFormType>().shape({
	name: Yup.string().required('Campo obrigatório'),
	description: Yup.string().nullable().required('Campo obrigatório'),
	image: Yup.array()
		.of(
			Yup.object<UploadFile>({
				icon: Yup.mixed(),
				name: Yup.string(),
				preview: Yup.mixed(),
				size: Yup.number(),
				type: Yup.string(),
				uid: Yup.string(),
				url: Yup.string().nullable(),
			})
				.nullable()
				.required()
		)
		.notRequired(),
	minValue: Yup.string().required('Campo obrigatório'),
	maxValue: Yup.string().required('Campo obrigatório'),
	daysOfRenovation: Yup.string().required('Campo obrigatório'),
	valueOfRenovation: Yup.string().required('Campo obrigatório'),
	category: Yup.array(),
	comission: Yup.object().nullable().required('Campo obrigatório'),
	isActive: Yup.bool(),
});

const SubscriptionEdit: React.FC = () => {
	const [table, setTable] = useState<CommissionTable[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [toast] = useToast();
	const [categorySelected, setCategorySelected] = useState<MenuStore | null>(
		null
	);

	const getCommissionTables = useCallback(
		async (id: number) => {
			setIsTableLoading(true);
			try {
				const commission = await commissionService.getById(id);
				setTable(commission.commissionTable);
			} catch (e) {
				const { code } = e;
				setTable([]);
				toast(
					`Não foi possível buscar os dados da Comissão selecionada. Cód: ${code}`,
					{
						type: 'error',
					}
				);
			}

			setIsTableLoading(false);
		},
		[toast]
	);

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			let subscription: SubscriptionFormType = defaultFormValue;

			if (!isCreating) {
				const { image, ...rest } = await subscriptionService.getById(
					Number(id)
				);
				subscription = {
					...rest,
					// Transformar prop image em UploadFile[]
					...(image && { image: uploadHelpers.mapImages(image) }),
				};

				if (subscription.comission?.idComission) {
					getCommissionTables(subscription.comission?.idComission);
				}
			}

			return { subscription };
		},
		[getCommissionTables]
	);

	const handleSubmit = useCallback(
		async <T extends SubscriptionFormType>(
			id: string,
			{ image, ...values }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const submit: Subscription = {
				...values,
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(image) &&
					(uploadHelpers.isNewFile(image[0]?.url)
						? { image: image[0]?.url }
						: image.length === 0 && { image: '' })),
			};

			submit.idComission = Number(submit.comission?.idComission);
			submit.idActivation = Number(submit.activation?.idActivation);

			try {
				await subscriptionService.createOrUpdate(submit.idSubscription, submit);
				toast(`Assinatura ${id ? 'alterada' : 'criada'} com sucesso`, {
					type: 'success',
				});

				history.push('/admin/mmn/subscriptions');
			} catch (e) {
				toast(`Não foi possível editar a assinatura. Cód: ${e.code}`, {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[toast]
	);

	return (
		<PageLayoutFormWrapper
			title="Assinatura"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/summary' },
				{
					label: 'Assinaturas',
					path: '/admin/mmn/subscriptions',
				},
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.subscription}
					validationSchema={formSchema}
				>
					{(form: FormikProps<SubscriptionFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />
							{console.log({ errors: form.errors })}

							<Card>
								<Card.Header separator justifyContent="space-between">
									<Card.Title>Dados Cadastrais</Card.Title>

									<Form.Group className="mb-0">
										<Switch
											name="isActive"
											defaultChecked
											disabled={form.isSubmitting}
										/>
										<Form.Label className="text-muted pl-1">Ativo</Form.Label>
									</Form.Group>
								</Card.Header>
								<Card.Content>
									<Row>
										<Col md={6} lg={9}>
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Nome</Form.Label>
													<Input
														name="name"
														type="text"
														disabled={form.isSubmitting}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Descrição</Form.Label>
													<TextArea
														name="description"
														rows={5}
														resize="none"
														disabled={form.isSubmitting}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Programa de Ativação</Form.Label>
													<AsyncSelect
														name="activation"
														fetchMethod={subscriptionService.getActivationList}
														isDisabled={form.isSubmitting}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col} md={6} lg={3}>
													<Form.Label>Valor Mínimo</Form.Label>
													<Input
														name="minValue"
														type="text"
														formatter={currencyFormatter}
														disabled={form.isSubmitting}
														prepend={
															<InputGroup.Text>
																<FAIcon icon="dollar-sign" />
															</InputGroup.Text>
														}
													/>
												</Form.Group>
												<Form.Group as={Col} md={6} lg={3}>
													<Form.Label>Valor Máximo</Form.Label>
													<Input
														name="maxValue"
														type="text"
														formatter={currencyFormatter}
														disabled={form.isSubmitting}
														prepend={
															<InputGroup.Text>
																<FAIcon icon="dollar-sign" />
															</InputGroup.Text>
														}
													/>
												</Form.Group>
												<Form.Group as={Col} md={6} lg={3}>
													<Form.Label>Dias para Renovação</Form.Label>
													<Input
														name="daysOfRenovation"
														type="text"
														disabled={form.isSubmitting}
													/>
												</Form.Group>
												<Form.Group as={Col} md={6} lg={3}>
													<Form.Label>Valor Renovação</Form.Label>
													<Input
														name="valueOfRenovation"
														type="text"
														formatter={currencyFormatter}
														disabled={form.isSubmitting}
														prepend={
															<InputGroup.Text>
																<FAIcon icon="dollar-sign" />
															</InputGroup.Text>
														}
													/>
												</Form.Group>
											</Form.Row>
										</Col>

										<Col md={6} lg={3}>
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Upload
														name="image"
														listType="picture-card"
														showUploadList={false}
														aspect={1}
														accept=".jpg, .png"
														maxSize={2}
														disabled={form.isSubmitting}
														displayInline
													>
														<FAIcon icon={faImage} />
														<div>Adicionar imagem</div>
													</Upload>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col md={6}>
											<FormGroupTitle>Comissão</FormGroupTitle>

											<Form.Row>
												<Form.Group as={Col}>
													<AsyncSelect
														name="comission"
														fetchMethod={commissionService.getList}
														isDisabled={form.isSubmitting}
														onChange={(value: CommissionList) => {
															if (!value) {
																setTable([]);
																return;
															}
															getCommissionTables(Number(value.idComission));
														}}
														isClearable
													/>
												</Form.Group>
											</Form.Row>

											{isTableLoading && (
												<>
													<Skeleton height="1.5rem" />
													<Skeleton height="1.5rem" />
													<Skeleton height="1.5rem" />
													<Skeleton height="1.5rem" />
												</>
											)}
											{table.length > 0 && !isTableLoading && (
												<Table striped responsive>
													<thead>
														<tr>
															<th>Nível</th>
															<th>Valor</th>
														</tr>
													</thead>
													<tbody>
														{table.map((item) => (
															<tr key={item.level}>
																<td>{item.level}</td>
																<td>{currencyFormatter.format(item.value)}</td>
															</tr>
														))}
													</tbody>
												</Table>
											)}
										</Col>
										<Col md={6} className="border-left">
											<FormGroupTitle>Categorias</FormGroupTitle>

											<FieldArray name="category">
												{(arrayHelper: ArrayHelpers) => (
													<>
														<AddActions>
															<AsyncSelect
																name="category_select"
																fetchMethod={ecommerceService.getMenus}
																optionValue="idCategory"
																optionLabel="shortName"
																onChange={(option: MenuStore) => {
																	setCategorySelected(option);
																}}
																value={categorySelected}
																isDisabled={form.isSubmitting}
																isClearable
															/>
															<Button
																type="button"
																variant="outline-info"
																onClick={() => {
																	const alreadyAdd = form.values.category.some(
																		(cat) =>
																			cat.idCategory ===
																			categorySelected?.idCategory
																	);
																	if (!alreadyAdd) {
																		arrayHelper.push({
																			idCategory: categorySelected?.idCategory,
																			shortName: categorySelected?.shortName,
																			onPA: true,
																			onStore: true,
																		});
																	}
																	setCategorySelected(null);
																}}
																disabled={
																	!categorySelected || form.isSubmitting
																}
															>
																Adicionar
															</Button>
														</AddActions>

														{form.values.category.length > 0 && (
															<div
																className="d-flex justify-content-end mb-2"
																style={{ marginRight: '5rem' }}
															>
																<strong style={{ marginRight: '2rem' }}>
																	Loja
																</strong>
																<strong>PA</strong>
															</div>
														)}
														<List
															data="category"
															emptyDescription="Não há categorias adicionadas"
															emptyImage={faBars}
															onChangeItems={(items) => {
																form.setFieldValue('category', items);
															}}
															onRemoveItem={(_, index) =>
																arrayHelper.remove(index)
															}
														>
															{(item: SubscriptionCategory, index) => (
																<div className="d-flex justify-content-between w-100">
																	<span>{item.shortName}</span>

																	<Form.Group className="mb-0 pl-3 border-left">
																		<Switch
																			name={`category[${index}].onPA`}
																			size="sm"
																			disabled={form.isSubmitting}
																			defaultChecked
																			className="mr-2"
																		/>
																		<Switch
																			name={`category[${index}].onStore`}
																			size="sm"
																			disabled={form.isSubmitting}
																			defaultChecked
																			className="mr-2"
																		/>
																	</Form.Group>
																</div>
															)}
														</List>
													</>
												)}
											</FieldArray>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="border-top">
									<div className="text-right">
										<FormCancelButton
											isSubmitting={form.isSubmitting}
											to="/admin/mmn/subscriptions"
										/>
										<FormSubmitButton isSubmitting={form.isSubmitting} />
									</div>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default SubscriptionEdit;
