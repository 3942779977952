import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	Account,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Account>> =>
	Crud.getList<Account>('my/account', params);

const getById = (id: ID): Promise<Account> =>
	Crud.getOne<Account>('my/account/{id}', id);

const patch = (
	id: ID,
	data: { idStatus: number }
): Promise<HttpPostResultAny> => Crud.update('career/{id}', id, data);

const createOrUpdate = (
	id: ID | null,
	data: Account
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('my/account/{id}', id, data);
	}
	return Crud.create('my/account', data);
};

export default {
	getForList,
	getById,
	patch,
	createOrUpdate,
};
