import styled from 'styled-components';

type HeaderProps = {
	withSeparator?: boolean;
};

const SimpleList = styled.div`
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;

	background-color: var(--base-bg);
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 4px;
`;

const Header = styled.div`
	padding: 8px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${(props: HeaderProps) =>
		props.withSeparator &&
		`
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  `}

	> :not(:first-child) {
		margin-left: 10px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	font-size: 1.2rem;
	font-weight: 400;
`;

const Info = styled.div`
	color: var(--gray-50);
	font-size: 1rem;
`;

const Item = styled.div`
	color: var(--base-color);
	padding: 8px 10px;
	white-space: pre-line;
`;

export default Object.assign(SimpleList, {
	Header,
	Column,
	Title,
	Info,
	Item,
});
