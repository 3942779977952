import React from 'react';

import classNames from 'classnames';

import FindObjectProperty from 'utils/FindObjectProperty';

import DefaultFormatter from './Default';
import { FormatterType, FormatterParamRowType } from './IFormatter';

export type ColoredStatus = {
	status: string | number;
	badgeClassName?: string;
	badgeText?: string;
};

export type ColoredStatusConfig = {
	statusList: ColoredStatus[];
};

class ColoredStatusFormatter extends DefaultFormatter implements FormatterType {
	constructor(private config: ColoredStatusConfig) {
		super();
	}

	format = (
		field: string,
		row?: FormatterParamRowType
	): string | React.ReactFragment => {
		const data = !row ? field : FindObjectProperty(row, field) ?? undefined;
		if (data === undefined) {
			return '';
		}
		let actualStatus = data.toString();

		if (typeof data === 'string') {
			actualStatus = data.toLowerCase();
		}

		const status = this.config.statusList.find(
			(s) => String(s.status) === actualStatus
		);

		if (!status) {
			return data;
		}

		return (
			<span className={classNames('font-weight-bold', status.badgeClassName)}>
				{status.badgeText || data}
			</span>
		);
	};
}

export default ColoredStatusFormatter;
