import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	Career,
	CareerForSelect,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Career>> =>
	Crud.getList<Career>('mmn/career', params);

const getForSelect = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CareerForSelect>> =>
	Crud.getList<CareerForSelect>('mmn/career/select', params);

const getById = (id: string | number): Promise<Career> =>
	Crud.getOne<Career>('mmn/career/{id}', id as number);

const createOrUpdate = (
	id: ID | null,
	data: Career
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('mmn/career/{id}', id as number, data);
	}
	return Crud.create('mmn/career', data);
};

const patch = (
	id: ID,
	data: { isActive: boolean }
): Promise<HttpPostResultAny> => Crud.patch('mmn/career/{id}', id, data);

export default {
	getForList,
	getForSelect,
	getById,
	createOrUpdate,
	patch,
};
