import {
	TimetableSelect,
	HttpGetListParams,
	HttpGetListResult,
	ID,
	Timetable,
	HttpPostResultAny,
} from 'types';

import Crud from './crud.service';

const getSchedules = (
	params: HttpGetListParams,
	route = 'timetable'
): Promise<HttpGetListResult<TimetableSelect>> =>
	Crud.getList<TimetableSelect>(route, params);

const getSchedulesSelect = (
	params: HttpGetListParams,
	route = 'timetable/select'
): Promise<HttpGetListResult<TimetableSelect>> =>
	Crud.getList<TimetableSelect>(route, params);

const getById = (id: ID): Promise<Timetable> =>
	Crud.getOne('timetable/{id}', id as number);

const save = (
	id: ID | null | undefined,
	data: Timetable
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update<Timetable>('timetable/{id}', id, data);
	}
	return Crud.create('timetable', data);
};

export default {
	getSchedules,
	getSchedulesSelect,
	getById,
	save,
};
