import React from 'react';

import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';

import useDatagridContext from './useDatagridContext';
import useDatagridDataContext from './useDatagridDataContext';

const NUM_PAGES_TO_SHOW = 5;

const DatagridFooter: React.FC = () => {
	const {
		config,
		tableParams,
		onPageChange,
		onRowsPerPageChange,
	} = useDatagridContext();
	const { pagination: dataPagination, isLoading } = useDatagridDataContext();

	if (!config.paginate || !dataPagination) {
		return <></>;
	}

	const selectedPage = dataPagination.current_page || 1;
	const totalPages = dataPagination.last_page || 1;
	const totalRows = dataPagination.total || 1;
	const pageRowsStart = dataPagination.from || 1;
	const pageRowsEnd = dataPagination.to || 1;

	const { rowsPerPage } = tableParams;
	const { rowsOptions } = config.paginate;

	const numPages = Math.floor(NUM_PAGES_TO_SHOW / 2);
	const firstPage = Math.max(1, selectedPage - numPages);
	const lastPage = Math.max(
		selectedPage + NUM_PAGES_TO_SHOW,
		selectedPage + numPages
	);
	const text =
		totalRows === 0
			? 'Não há itens para exibir'
			: config.paginate.text
					.replace(/\{posIni\}/g, `${pageRowsStart}`)
					.replace(/\{posEnd\}/g, `${pageRowsEnd}`)
					.replace(/\{total\}/g, `${totalRows}`);
	const pagination = [];

	for (let i = firstPage; i < Math.min(lastPage, totalPages + 1); ++i) {
		pagination.push(
			<Pagination.Item
				key={i}
				disabled={isLoading}
				active={i === selectedPage}
				onClick={() => onPageChange(i)}
			>
				{i}
			</Pagination.Item>
		);
	}

	return (
		<>
			<div className="d-flex flex-row justify-content-start align-items-center">
				<div className="mr-2 ml-auto">
					<Form.Group className="form-inline m-0">
						<span>Itens por página</span>
						<Form.Control
							size="sm"
							as="select"
							className="ml-2 mr-4"
							onChange={(e) =>
								onRowsPerPageChange(Number(e.currentTarget.value))
							}
							value={rowsPerPage}
						>
							{rowsOptions.map((opt) => (
								<option key={`option-${opt}`} value={opt}>
									{opt}
								</option>
							))}
						</Form.Control>
						<span className="mr-3">{text}</span>
					</Form.Group>
				</div>
				<Pagination className="m-0">
					<Pagination.First
						disabled={selectedPage === 1 || isLoading}
						onClick={() => onPageChange(1)}
					/>
					<Pagination.Prev
						disabled={selectedPage === 1 || isLoading}
						onClick={() => onPageChange(selectedPage - 1)}
					/>
					{pagination}
					<Pagination.Next
						disabled={selectedPage === totalPages || isLoading}
						onClick={() => onPageChange(selectedPage + 1)}
					/>
					<Pagination.Last
						disabled={selectedPage === totalPages || isLoading}
						onClick={() => onPageChange(totalPages)}
					/>
				</Pagination>
			</div>
		</>
	);
};

DatagridFooter.displayName = 'DatagridFooter';
DatagridFooter.whyDidYouRender = true;

export default DatagridFooter;
