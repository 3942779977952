import {
	HttpGetListParams,
	HttpGetListResult,
	ID,
	HttpPostResultAny,
} from 'types';
import { CompanyForList, Company } from 'types/company.types';
import { CompanyAccount } from 'types/financial.types';

import Crud from './crud.service';

/**
 * Service de requisição de dados de empresas
 */
const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CompanyForList>> =>
	Crud.getList<CompanyForList>('company', params);

const getForId = (id: ID): Promise<Company> =>
	Crud.getOne<Company>('company/{id}', id);

const save = (
	id: ID | null | undefined,
	data: Company
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update<Company>('company/{id}', id, data);
	}
	return Crud.create('company', data);
};

// TODO: Remover
// const getAccounts = (idCompany: ID): Promise<CompanyAccount[]> =>
// 	Crud.getListData<CompanyAccount>(`company/${idCompany}/account`, {});

const getAccounts = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CompanyAccount>> =>
	Crud.getList<CompanyAccount>('company/{id}/account', params);

export default {
	getForList,
	getForId,
	save,
	getAccounts,
};
