import React from 'react';

import { StyledFrame } from './video/styles';

export type VideoProps = {
	title: string;
	src: string;
};

const Video: React.FC<VideoProps> = ({ title, src }) => (
	<StyledFrame
		title={title}
		src={src}
		frameBorder={0}
		allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
	/>
);

export default Video;
