/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Skeleton from 'react-loading-skeleton';
import { Props } from 'react-select';

import { StyledSelect } from '../select/styles';

export type SelectProps = Props<any> & {
	optionLabel?: string;
	optionValue?: string;
};

const LoadingIndicator: React.FC = () => <Skeleton height="2rem" count={5} />;

const NoOptionsMessage: React.FC = () => <>Sem dados</>;

const SelectField: React.FC<SelectProps> = ({
	name,
	onChange,
	optionLabel,
	optionValue,
	placeholder = 'Selecione',
	loadMessage = <LoadingIndicator />,
	noOptionMessage = <NoOptionsMessage />,
	menuPlacement = 'auto',
	...rest
}) => {
	const [field, { error, touched }, helpers] = useField(name || '');

	return (
		<InputGroup>
			<StyledSelect
				{...field}
				classNamePrefix="select"
				className={error && touched && 'is-invalid'}
				placeholder={placeholder}
				loadMessage={loadMessage}
				noOptionMessage={noOptionMessage}
				menuPlacement={menuPlacement}
				onChange={(option: any, action: any): void => {
					onChange?.(option, action);
					helpers.setValue(option);
				}}
				getOptionLabel={(option: any) => {
					return optionLabel ? option[optionLabel] : option.name;
				}}
				getOptionValue={(option: any) => {
					return optionValue ? option[optionValue] : option;
				}}
				{...rest}
			/>
			{error && touched && (
				<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
			)}
		</InputGroup>
	);
};

SelectField.displayName = 'Formik/Select';
SelectField.whyDidYouRender = true;

export default SelectField;
