import localforage from 'localforage';

import { getApi } from 'services/api';
import authService from 'services/session.service';
import { SessionPerson, SessionPersonPermission, AppAction } from 'types';
import history from 'utils/history';
import JWT from 'utils/jwt';

import sessionActions from './actions';
import { SessionActionEnum } from './types';

const loadLogged = (redirectTo = ''): AppAction<Promise<SessionPerson>> => {
	return async (dispatch) => {
		try {
			const result = await authService.getLogged();
			const data = result as SessionPerson;
			const permissions = (data.permission as SessionPersonPermission[]).map(
				(p) => p.slug
			);

			dispatch(sessionActions.loginSuccess(data, permissions));

			history.push(`/redirect?path=${redirectTo}`);
			return Promise.resolve(data);
		} catch (e) {
			dispatch(sessionActions.loginFailed(e));
			return Promise.reject(e);
		}
	};
};

const loginRequest = (
	email: string,
	password: string
): AppAction<Promise<SessionPerson>> => async (dispatch) => {
	dispatch({ type: SessionActionEnum.LoginRequest });
	try {
		const result = await authService.login({ email, password, remember: true });
		const { token } = result.data;
		JWT.setSessionToken(token);
		const localEmail = await localforage.getItem('email');
		if (!localEmail || email !== localEmail) {
			localforage.setItem('email', email);
		}
		getApi().defaults.headers.Authorization = `Bearer ${token}`;
	} catch (e) {
		dispatch(sessionActions.loginFailed(e));
		return Promise.reject(e);
	}
	return dispatch(loadLogged());
};

const forgotRequest = (
	email: string
): AppAction<Promise<{ message: string }>> => async (dispatch) => {
	dispatch({ type: SessionActionEnum.ForgotRequest });
	try {
		const result = await authService.forgotPassword(email);
		const { message } = result;
		dispatch(sessionActions.forgotSuccess(message));
		return await Promise.resolve({ message });
	} catch (e) {
		dispatch(sessionActions.forgotFailed(e));
		return Promise.reject(e);
	}
};

const redefineRequest = (
	email: string,
	password: string,
	newPassword: string
): AppAction<Promise<SessionPerson>> => async (dispatch) => {
	dispatch({ type: SessionActionEnum.RedefineRequest });
	try {
		await authService.resetPassword({ email, password, newPassword });
		return await dispatch(loginRequest(email, newPassword));
	} catch (e) {
		dispatch(sessionActions.loginFailed(e));
		return Promise.reject(e);
	}
};

const logoutRequest = (redirectTo = ''): AppAction => {
	return async (dispatch) => {
		dispatch({ type: SessionActionEnum.LogoutRequest });
		try {
			await authService.logout();
		} catch (e) {
			// Independente da resposta, continuamos o fluxo de redirect
		} finally {
			history.push(`/login?redirect=${redirectTo}`);
		}
	};
};

const loadLoggedRequest = (): AppAction<Promise<SessionPerson>> => async (
	dispatch
) => {
	dispatch({ type: SessionActionEnum.LoadLoggedRequest });
	try {
		const result = await authService.getLogged();
		const data = result as SessionPerson;
		dispatch(sessionActions.loadLoggedSuccess(data));
		return await Promise.resolve(data);
	} catch (e) {
		dispatch(sessionActions.loadLoggedFailed(e));
		return Promise.reject(e);
	}
};

export default {
	loginRequest,
	forgotRequest,
	redefineRequest,
	logoutRequest,
	loadLogged,
	loadLoggedRequest,
	...sessionActions,
};
