/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';

import { Pagination } from 'react-bootstrap';

const PREVIOUS_PAGE = 'PREV';
const NEXT_PAGE = 'NEXT';

const range = (from: number, to: number, step = 1) => {
	let i = from;
	const rangeArr = [];

	while (i <= to) {
		rangeArr.push(i);
		i += step;
	}

	return rangeArr;
};

interface PaginationExecutiveListProps {
	totalRecords: number;
	pageLimit?: number;
	pageRangeDisplayed?: number;
	onChangePage: (page: number) => void;
	isLoading: boolean;
	style?: React.CSSProperties;
	className?: string;
}

const PaginationExecutiveList: React.FC<PaginationExecutiveListProps> = ({
	totalRecords,
	pageLimit = 5,
	pageRangeDisplayed = 0,
	onChangePage,
	isLoading,
	...rest
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = Math.ceil(totalRecords / pageLimit);

	const gotoPage = useCallback(
		(page) => {
			setCurrentPage(page);
			onChangePage(page);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentPage]
	);

	useEffect(() => {
		gotoPage(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setPage = (page: number) => (event: React.SyntheticEvent) => {
		event.preventDefault();
		gotoPage(page);
	};

	const getPager = () => {
		const totalItems = pageRangeDisplayed * 2 + 3;
		const totalBlocks = totalItems + 2;

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageRangeDisplayed);
			const endPage = Math.min(
				totalPages - 1,
				currentPage + pageRangeDisplayed
			);

			let pages: Array<string | number> = range(startPage, endPage);

			const hasLeftSpill = startPage > 2;
			const hasRightSpill = totalPages - endPage > 1;
			const spillOffset = totalItems - (pages.length + 1);

			switch (true) {
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(startPage - spillOffset, startPage - 1);
					pages = [PREVIOUS_PAGE, ...extraPages, ...pages];
					break;
				}

				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(endPage + 1, endPage + spillOffset);
					pages = [...pages, ...extraPages, NEXT_PAGE];
					break;
				}

				case hasLeftSpill && hasRightSpill:
				default: {
					pages = [PREVIOUS_PAGE, ...pages, NEXT_PAGE];
					break;
				}
			}

			return [1, ...pages, totalPages];
		}

		return range(1, totalPages);
	};

	const pages = getPager();

	useEffect(() => {
		if (isLoading) gotoPage(1);
	}, [gotoPage, isLoading]);

	return (
		<Pagination {...rest}>
			{pages.length > 0 ? (
				pages.map((page, index) => {
					if (page === PREVIOUS_PAGE) {
						return <Pagination.First key={index} onClick={setPage(1)} />;
					}

					if (page === NEXT_PAGE) {
						return (
							<Pagination.Last key={index} onClick={setPage(totalPages)} />
						);
					}

					return (
						<Pagination.Item
							key={index}
							active={currentPage === page}
							onClick={setPage(Number(page))}
						>
							{page}
						</Pagination.Item>
					);
				})
			) : (
				<>
					<Pagination.First disabled />
					<Pagination.Item disabled>1</Pagination.Item>
					<Pagination.Last disabled />
				</>
			)}
		</Pagination>
	);
};

export default PaginationExecutiveList;
