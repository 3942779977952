import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Skeleton from 'components/Skeleton';

const AccountSkeletonForm: React.FC = () => (
	<Card>
		<Card.Body>
			<Form.Row>
				<Form.Group as={Col} md={12}>
					<Skeleton height="1rem" width="13ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col} sm={12} md={6}>
					<Skeleton height="1rem" width="7ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>

				<Form.Group as={Col} sm={12} md={6}>
					<Skeleton height="1rem" width="8ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>

				<Form.Group as={Col} md={12}>
					<Skeleton height="1rem" width="5ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>

				<Form.Group as={Col} sm={12}>
					<Skeleton height="1rem" width="14ch" />
					<Skeleton height="1rem" width="14ch" display="block" />
				</Form.Group>

				<Form.Group as={Col} md={6}>
					<Skeleton height="1rem" width="7ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>

				<Form.Group as={Col} md={6}>
					<Skeleton height="1rem" width="5ch" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>
		</Card.Body>

		<Card.Footer className="text-right">
			<Skeleton height="2.6rem" width="75px" margin="0 1rem 0 0" />
			<Skeleton height="2.6rem" width="75px" />
		</Card.Footer>
	</Card>
);

export default AccountSkeletonForm;
