import React, { useEffect, useState } from 'react';

import { Avatar } from 'components';
import { ScoreLineTree } from 'types';
import { currencyFormatter } from 'utils/formatters';

import * as S from './panelTab/styles';

export type PanelTabProps = {
	user: ScoreLineTree;
	scoreLimit: number;
	active?: boolean;
	width?: number;
	onClickTab: (user: ScoreLineTree) => void;
};

const PanelTab: React.FC<PanelTabProps> = ({
	user,
	scoreLimit,
	active = false,
	width,
	onClickTab,
}) => {
	const [score, setScore] = useState<number>(0);
	const [scoreBarPercent, setScoreBarPercent] = useState<string>('');

	useEffect(() => {
		setScore(Math.min(scoreLimit, Number(user.score)));
		setScoreBarPercent(
			`${Math.min(100, (100 / scoreLimit) * Number(user.score))}%`
		);
	}, [scoreLimit, user.score]);

	return (
		<S.Container
			style={{ width }}
			active={active}
			onClick={() => onClickTab(user)}
		>
			<Avatar
				text={user.name[0].toUpperCase()}
				src={user.imageUrl || ''}
				className="mb-2"
			/>
			<S.UserCode>{user.id}</S.UserCode>
			<S.UserName title={`${user.id} - ${user.name}`}>{user.name}</S.UserName>
			<S.UserScore>
				<S.UserScoreNumber>{currencyFormatter.format(score)}</S.UserScoreNumber>
				<S.UserScoreBar>
					<div style={{ width: scoreBarPercent }} />
				</S.UserScoreBar>
			</S.UserScore>
		</S.Container>
	);
};

export default PanelTab;
