import React from 'react';

import ProductCard from '../ProductCard';

export type ShoppingCartSidebarSkelProps = {
	count: number;
};

const ShoppingCartSidebarSkel: React.FC<ShoppingCartSidebarSkelProps> = ({
	count,
}) => {
	const items: React.ReactNodeArray = [];

	for (let i = 0; i < count; ++i) {
		items.push(<ProductCard.Small.Skeleton />);
	}
	return <>{items}</>;
};

export default ShoppingCartSidebarSkel;
