import React from 'react';

import {
	faClock,
	faUserCircle,
	faLock,
	faUser,
} from '@fortawesome/pro-light-svg-icons';

import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import ProfileEdit from './Profile';
import ScheduleEdit from './Schedule';
import UserEdit from './User';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const AccessRoutes: RouteDef = {
	path: '/admin/access',
	validation: [personTypeValidation(PersonType.Admin)],
	menu: {
		name: 'Acesso',
		icon: faUser,
	},
	children: [
		{
			path: '/users',
			render: getListSceneRenderFn(listConfigs.AccessUsers),
			exact: true,
			menu: {
				name: 'Usuários',
				icon: faUserCircle,
			},
		},
		{
			path: '/users/:id',
			component: UserEdit,
			exact: true,
		},
		{
			path: '/profiles',
			render: getListSceneRenderFn(listConfigs.AccessProfiles),
			exact: true,
			menu: {
				name: 'Perfis de Acesso',
				icon: faLock,
			},
		},
		{
			path: '/profiles/:id',
			component: ProfileEdit,
			exact: true,
		},
		{
			path: '/schedules',
			render: getListSceneRenderFn(listConfigs.AccessSchedule),
			exact: true,
			menu: {
				name: 'Horários',
				icon: faClock,
			},
		},
		{
			path: '/schedules/:id',
			component: ScheduleEdit,
			exact: true,
		},
	],
};

export default AccessRoutes;
