import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import EventManager, {
	ModalEvent,
} from 'components/modalContainer/EventManager';
import {
	ModalRef,
	ModalAfterCloseCallback,
} from 'components/modalContainer/types';

export type ModalOpenType<P, R> = (
	component: ReactNode,
	componentProps: P,
	afterClose?: ModalAfterCloseCallback<R>
) => ModalRef<P, R>;

// FIXME:
// Criar arquivo dedicado em src/utils?
const getNewID = (): string => {
	return `${Math.random()}${new Date().toISOString().substring(12, 8)}`;
};

/**
 * Hook de exibição de caixas de diálogo.
 * Retorna a função open() que pode ser chamada para instanciar um modal e aguardar o resultado gerado ao fechar o modal.
 * O componente recebe as suas propriedades além das definidas em ModalForwardProps.
 * As props ModalForwardProps devem ser mescladas com as customizadas pelo componente e passadas ao <Modal>, ex:
 *
 * const ModalTeste: React.FC<ModalForwardProps<{ title: string }>> = ({title, modalRef, ...rest}) => (
 *   <Modal {...rest}>
 *     <Modal.Header><Modal.Title>{title}</Modal.Title></Modal.Header>
 *     <Modal.Body>Exemplo</Modal.Body>
 *     <Modal.Footer align="end">
 *       <Button onClick={() => modalRef.dismiss()}>Fechar</Button>
 *     </Modal.Footer>
 *   </Modal>
 * );
 *
 * Esse exemplo de componente pode ser então instanciado com o open()
 *
 * const open = useModal<{ title: string }>();
 * const modalRef = open(ModalTeste, { title: 'Titulo' }, (result) => console.log(result));
 *
 * É possível fechar o popup usando a função dismiss() dentro do modalRef, retornado pelo open() e passado ao modal
 * pelo ModalForwardProps.
 */
const useModal = <T, R>(): ModalOpenType<T, R> => {
	const location = useLocation();
	const open = useMemo(
		() => (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			component: any,
			componentProps: T,
			afterClose?: ModalAfterCloseCallback<R>
		): ModalRef<T, R> => {
			const wrapper = {
				modalRef: {
					id: getNewID(),
					location,
					component,
					componentProps,
					afterClose,
					dismiss: (result: R) => {
						wrapper.modalRef.result = result;
						EventManager.emit(ModalEvent.Close, wrapper);
					},
				} as ModalRef<T, R>,
				show: true,
				onHide: () => {
					wrapper.modalRef.result = undefined;
					EventManager.emit(ModalEvent.Close, wrapper);
				},
				onExited: () => {
					EventManager.emit(ModalEvent.Exited, wrapper);
				},
			};
			EventManager.emit(ModalEvent.Show, wrapper);

			return wrapper.modalRef as ModalRef<T, R>;
		},
		[location]
	);

	return open;
};

export default useModal;
