import { Container as ContainerBootstrap } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(ContainerBootstrap)``;

export const ItemBody = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 900px;
`;

export const Title = styled.h3`
	max-width: 900px;
`;

export const ImageItem = styled.img`
	padding: 0;
`;

export const AuthorDate = styled.div`
	margin: 5px 0;
	align-self: flex-end;
`;

export const ContentText = styled.div`
	margin: 20px 0 0;
`;

export const AttachList = styled.div`
	border-top: 1px solid var(--gray-20);
	padding: 15px 0 5px;

	> div {
		margin-bottom: 5px;
	}
`;

export const AttachListItem = styled.div`
	span {
		margin-right: 5px;
	}
	a {
		padding: 5px 8px;
		border-radius: var(--base-border-radius);
		background-color: var(--gray-20);
	}
`;
