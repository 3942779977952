import React, { useEffect, useState, useCallback } from 'react';

import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

export interface RadioProps {
	name: string;
	label: string;
	value: string;
	defaultChecked?: boolean;
	isValueNumber?: boolean;
	id?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent) => void;
	style?: React.CSSProperties;
	className?: string;
}

let radioIdNum = 0;

const Radio: React.FC<RadioProps> = (props) => {
	const {
		name,
		id,
		value,
		defaultChecked,
		isValueNumber,
		onChange,
		...rest
	} = props;
	const [radioId, setRadioId] = useState<number>(0);

	const [, { initialValue, error, touched }, { setValue }] = useField(name);

	useEffect(() => {
		// eslint-disable-next-line no-plusplus
		setRadioId(++radioIdNum);
	}, []);

	const setCheckedValue = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const inputChecked = event.target.checked;

			if (inputChecked) {
				if (value) {
					setValue(isValueNumber ? Number(value) : value);
				} else {
					setValue(inputChecked);
				}
			} else {
				setValue(null);
			}

			if (onChange !== undefined) {
				onChange(event);
			}
		},
		[isValueNumber, onChange, setValue, value]
	);

	return (
		<Form.Check
			type="radio"
			id={`radio-${radioId}-${id || name}`}
			isInvalid={!!error && touched}
			feedback={error}
			defaultChecked={defaultChecked || initialValue === value}
			onChange={setCheckedValue}
			name={name}
			{...rest}
			custom
		/>
	);
};

Radio.displayName = 'Radio';
Radio.whyDidYouRender = true;

export default Radio;
