import { ID } from 'types';
import { action, Action } from 'typesafe-actions';

import { ShoppingCartActionEnum } from './types';

const clear = (cartNames: string[]): Action =>
	action(ShoppingCartActionEnum.Clear, { cartNames });

const clearAll = (skipCartNames: string[] = []): Action =>
	action(ShoppingCartActionEnum.ClearAll, { skipCartNames });

const updateQuantity = (cartName: string, id: ID, quantity: number): Action =>
	action(ShoppingCartActionEnum.UpdateQuantity, { cartName, id, quantity });

const setQuantity = (cartName: string, id: ID, quantity: number): Action =>
	action(ShoppingCartActionEnum.SetQuantity, { cartName, id, quantity });

const addItem = (cartName: string, id: ID, quantity: number): Action =>
	action(ShoppingCartActionEnum.AddItem, { cartName, id, quantity });

const removeItem = (cartName: string, id: ID): Action =>
	action(ShoppingCartActionEnum.RemoveItem, { cartName, id });

export default {
	clear,
	clearAll,
	updateQuantity,
	setQuantity,
	addItem,
	removeItem,
};
