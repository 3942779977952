import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card } from 'components';
import { FormikEffect, Input, Upload } from 'components/Formik';
import uploadHelpers from 'components/upload/Helpers';
import { UploadFile } from 'components/upload/types';
import { useToast } from 'hooks';
import vipService from 'services/vip.service';
import { HttpBaseResult, Vip } from 'types';
import { decimalFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './vip/SkeletonForm';
import { VipFormType } from './vip/types';

export const defaultFormValue: VipFormType = {
	idVip: null,
	name: '',
	value: '',
	image: [],
};

export const formSchema = Yup.object<VipFormType>().shape({
	idVip: Yup.mixed(),
	name: Yup.string().required('Campo obrigatório'),
	value: Yup.string().required('Campo obrigatório'),
	image: Yup.array()
		.of(
			Yup.object<UploadFile>({
				icon: Yup.mixed(),
				name: Yup.string(),
				preview: Yup.mixed(),
				size: Yup.number(),
				type: Yup.string(),
				uid: Yup.string(),
				url: Yup.string().nullable(),
			})
				.nullable()
				.required()
		)
		.nullable()
		.required('Campo obrigatório'),
});

const VipEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			let vip: VipFormType = defaultFormValue;

			if (!isCreating) {
				const { image, ...rest } = await vipService.getById(Number(id));
				vip = {
					...rest,
					// Transformar prop image em UploadFile[]
					...(image && { image: uploadHelpers.mapImages(image) }),
				};
			}

			return { vip };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends VipFormType>(
			id: string,
			{ image, ...values }: T,
			{ setSubmitting, setFieldError }: FormikHelpers<T>
		) => {
			const isCreating = id === 'create';

			const submit: Vip = {
				...values,
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(image) &&
					(uploadHelpers.isNewFile(image[0]?.url)
						? { image: image[0]?.url }
						: image.length === 0 && { image: '' })),
			};

			try {
				await vipService.createOrUpdate(isCreating ? null : Number(id), submit);
				toast(`Vip ${id ? 'alterado' : 'criado'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/mmn/vip');
			} catch (e) {
				const { code } = e as HttpBaseResult;
				if (code === 100) {
					toast('Já existe um VIP cadastrado com o mesmo valor', {
						type: 'info',
					});
					// Seta erro no formik
					setFieldError(
						'value',
						'Já existe um VIP cadastrado com o mesmo valor'
					);
					// TODO: Foco no campo com erro
				} else {
					toast(`Não foi possível editar o Vip. Cód: ${code}`, {
						type: 'error',
					});
				}
			}
			setSubmitting(false);
		},
		[toast, history]
	);

	return (
		<PageLayoutFormWrapper
			title="VIP"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/vip' },
				{ label: 'VIP', path: '/admin/mmn/vip' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.vip}
					validationSchema={formSchema}
				>
					{(form: FormikProps<VipFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content className="offset-lg-4 col-lg-4 offset-md-3 col-md-6">
									<Row>
										<Col sm={12}>
											<Form.Row>
												<Form.Group as={Col} sm={12}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" />
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} sm={12}>
													<Form.Label>Valor</Form.Label>
													<Input name="value" formatter={decimalFormatter(0)} />
												</Form.Group>
											</Form.Row>
											<Form.Row className="mt-1">
												<Form.Group as={Col} sm={12}>
													<Form.Label>Imagem</Form.Label>
													<Upload
														name="image"
														listType="picture-card"
														showUploadList={false}
														aspect={1}
														accept=".jpg, .png"
														maxSize={2}
														disabled={form.isSubmitting}
														displayInline
													>
														<FAIcon icon={faImage} />
														<div>Adicionar imagem</div>
													</Upload>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="text-right border-top">
									<Form.Row>
										<Col>
											<FormCancelButton to="/admin/mmn/vip" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default VipEdit;
