import React from 'react';

import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Card } from 'components';
import Skeleton from 'components/Skeleton';

const Label = styled.div`
	height: 1.8rem;
`;

const StoreFormSkeleton: React.FC = () => (
	<Card>
		<Card.Header separator justifyContent="space-between">
			<Skeleton width="50px" />
			<Skeleton width="50px" />
		</Card.Header>

		<Card.Content>
			<Label />
			<Row noGutters>
				<Col md={7} className="pr-1">
					<Skeleton height="1.8rem" />
				</Col>
				<Col md={5} className="pl-1">
					<Skeleton height="1.8rem" />
				</Col>
			</Row>

			<Label />
			<Row noGutters>
				<Col md={7} className="pr-1">
					<Skeleton height="1.8rem" />
				</Col>
				<Col md={5} className="pl-1">
					<Skeleton height="1.8rem" />
				</Col>
			</Row>
		</Card.Content>

		<Row>
			<Col md={6} lg={8}>
				<Card.Content>
					<Skeleton width="100px" />
					<Row>
						<Col md={10} lg={10} className="pr-1">
							<Skeleton height="1.8rem" />
						</Col>
						<Col md={2} lg={2} className="pl-1">
							<Skeleton height="1.8rem" />
						</Col>
					</Row>

					<Label />
					<Skeleton />
					<Skeleton />
					<Skeleton />

					<hr />

					<Skeleton width="100px" />
					<Row>
						<Col md={10} lg={10} className="pr-1">
							<Skeleton height="1.8rem" />
						</Col>
						<Col md={2} lg={2} className="pl-1">
							<Skeleton height="1.8rem" />
						</Col>
					</Row>

					<Label />
					<Skeleton />
					<Skeleton />
					<Skeleton />

					<hr />

					<Skeleton width="100px" />
					<Row>
						<Col md={10} lg={10} className="pr-1">
							<Skeleton height="1.8rem" />
						</Col>
						<Col md={2} lg={2} className="pl-1">
							<Skeleton height="1.8rem" />
						</Col>
					</Row>

					<Label />
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</Card.Content>
			</Col>

			<Col md={6} lg={4} className="d-flex border-left w-100">
				<Card.Content>
					<Skeleton height="1.8rem" width="500px" />

					<Label />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</Card.Content>
			</Col>
		</Row>

		<br />

		<Card.Content className="text-right border-top">
			<span className="mr-2">
				<Skeleton width="100px" height="2rem" />
			</span>
			<Skeleton width="100px" height="2rem" />
		</Card.Content>
	</Card>
);

export default StoreFormSkeleton;
