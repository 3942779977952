/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import Skeleton from 'react-loading-skeleton';
import { Props, OptionsType, OptionTypeBase } from 'react-select';

import { HttpGetListParams, HttpGetListResult } from 'types';

import useFirstRender from '../hooks/useFirstRender';

import { StyledAsyncSelect } from './select/styles';

export type AsyncSelectFetchMethod<T> = (
	prams: HttpGetListParams
) => Promise<HttpGetListResult<T>>;

export type AsyncSelectFilterQuery = (input: string) => string[];

export interface SelectProps extends Props<any> {
	fetchMethod: AsyncSelectFetchMethod<unknown>;
	name?: string;
	fetchQuery?: AsyncSelectFilterQuery;
	fetchSortByKey?: string;
	fetchSortByDesc?: boolean;
	onChange?: (value: any) => void;
	optionLabel?: string;
	optionValue?: string;
	defaultValue?: OptionTypeBase;
	disabled?: boolean;
	loadMessage?: React.ReactNode;
	noOptionMessage?: React.ReactNode;
	placeholder?: string | React.ReactNode;
}

const LoadingIndicator: React.FC = () => <Skeleton height="2rem" count={5} />;

const NoOptionsMessage: React.FC = () => <>Sem dados</>;

const defaultProps = {
	placeholder: 'Selecione',
	loadMessage: <LoadingIndicator />,
	noOptionMessage: <NoOptionsMessage />,
};

const defaultFetchQuery: AsyncSelectFilterQuery = (input: string) => {
	if (input.trim()) {
		return [`"~name": "*${input.trim()}*"`];
	}
	return [];
};

const SelectField: React.FC<SelectProps> = (props) => {
	const isFirstRender = useFirstRender();
	const {
		optionLabel,
		optionValue,
		defaultValue,
		fetchMethod,
		value,
		fetchQuery = defaultFetchQuery,
		fetchSortByKey = undefined,
		fetchSortByDesc = false,
		onChange,
		...rest
	} = props;

	const loadOptions = async (
		inputValue: string,
		_: OptionsType<any>,
		{ page }: { page: number }
	) => {
		const query = fetchQuery(inputValue);

		const newParams: HttpGetListParams = {
			query: !query.length ? undefined : query,
			pagination: {
				page,
				paginate: 100,
			},
		};

		if (fetchSortByKey) {
			newParams.pagination!.sort = !fetchSortByDesc
				? fetchSortByKey
				: `-${fetchSortByKey}`;
		}

		const result = await fetchMethod(newParams);
		return {
			options: result.data,
			hasMore: result.meta?.current_page !== result.meta?.last_page,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<>
			<InputGroup>
				<StyledAsyncSelect
					value={(isFirstRender && defaultValue) || value || ''}
					loadOptionsOnMenuOpen
					cacheUniqIds={false}
					defaultOptions
					loadOptions={loadOptions}
					classNamePrefix="select"
					getOptionLabel={(option: any) => {
						return optionLabel ? option[optionLabel] : option.name;
					}}
					getOptionValue={(option: any) => {
						return optionValue ? option[optionValue] : option;
					}}
					onChange={(v: any) => {
						onChange?.(v);
					}}
					additional={{ page: 1 }}
					debounceTimeout={1000}
					{...rest}
				/>
			</InputGroup>
		</>
	);
};

SelectField.displayName = 'AsyncSelect';
SelectField.defaultProps = defaultProps;
SelectField.whyDidYouRender = true;

export default SelectField;
