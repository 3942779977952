import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { OrderForList } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

const PaymentButtonCreditOrder: React.FC<
	ButtonLinkComponentProps<OrderForList>
> = ({ row }) => {
	if (row.idStatus !== 1) return <>{row.status}</>;

	return (
		<Button
			as={Link}
			to={`/app/distributor/creditOrders/checkout/${row.idOrder}`}
			variant="success"
			size="sm"
		>
			Pagar
		</Button>
	);
};

export default PaymentButtonCreditOrder;
