import React, { useState, useCallback } from 'react';

import DatePicker from './calendar/DatePicker';
import DateTimeToggle from './calendar/DateTimeToggle';
import TimePicker from './calendar/TimePicker';

export type CalendarProps = {
	showDate?: boolean;
	showTime?: boolean;
	value?: string;
	defaultDateTime?: string;
	onDateChange?: (newDate: string) => void;
	onTimeChange?: (newDate: string) => void;
};

const defaultProps: CalendarProps = {
	showDate: true,
};

const styles = {
	minWidth: 300,
	minHeight: 300,
};

const Calendar: React.FC<CalendarProps> = ({ showDate, showTime, ...rest }) => {
	const [isTimeVisible, setTimeVisible] = useState<boolean>(!showDate);

	const handleDateTimeToggle = useCallback(
		(dateVisible: boolean) => setTimeVisible(!dateVisible),
		[]
	);

	return (
		<div style={styles}>
			{showDate && showTime && (
				<DateTimeToggle
					isDateSelected={!isTimeVisible}
					onToggle={handleDateTimeToggle}
				/>
			)}
			{!isTimeVisible && <DatePicker {...rest} />}
			{isTimeVisible && <TimePicker {...rest} />}
		</div>
	);
};

Calendar.displayName = 'Calendar';
Calendar.defaultProps = defaultProps;
Calendar.whyDidYouRender = true;

export default Calendar;
