import styled, { css } from 'styled-components';

export const List = styled.ul`
	list-style: none;
	padding: 0;
	margin-bottom: 0;
`;

type ListItemProps = {
	level: number;
	isLast: boolean;
};

export const ListItem = styled.li<ListItemProps>`
	${({ level, isLast }) => css`
		margin-left: ${level === 0 ? 0 : level * 2}.0rem;
		padding: 0.3rem;
		border-radius: var(--border-radius);
		background-color: var(--gray-10);
		border: 1px solid var(--gray-20);
		position: relative;

		> button {
			margin-right: 0.5rem;
		}

		${level > 0 &&
		css`
			.line {
				position: absolute;
				width: 1px;
				background-color: var(--gray-30);
				top: 0;
				height: 100%;
			}

			&:before {
				content: '';
				display: block;
				width: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: -12px;
				border-left: 1px solid var(--gray-30);
				${isLast &&
				css`
					height: 50%;
				`}
			}
			&:after {
				content: '';
				display: block;
				width: 10px;
				height: 0;
				border-top: 1px solid var(--gray-30);
				margin-top: 2px;
				position: absolute;
				top: 1em;
				left: -12px;
			}
		`}
	`}
`;

export const TextWrapper = styled.span`
	span,
	> svg {
		margin-right: 0.5rem;
	}

	> svg {
		color: var(--yellow);
	}
`;

export const Codigo = styled.span`
	font-weight: bold;
	color: var(--primary);
`;
