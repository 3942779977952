import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const ScrollReplaceContainer = styled(Col)`
	overflow-y: scroll;
	height: 1050px;
`;
export const Status = styled.span`
	font-size: var(--base-font-size);
`;
