import React from 'react';

import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import financialService from 'services/financial.service';
import { dateFormatter } from 'utils/formatters';

import {
	FilterContainer,
	DateFilter,
	FilterButton,
	ExportButton,
} from 'packages/admin/components/datatableView';

const validationSchema = Yup.object({
	requestDate: Yup.date().nullable(),
});

const defaultFilterValues = {
	requestDate: '',
};

const columnsExport: string[] = [
	'Nº da Solicitação',
	'ID',
	'Executivo',
	'Valor',
	'Data da Solicitação',
	'Data do Pagamento',
	'Status',
];

const PayoutRequestFilters: React.FC = () => {
	const { tableParams } = useDatagridContext();
	const { data } = useDatagridDataContext();
	const requestDate =
		tableParams.filters.length > 0 ? tableParams.filters[0].query : '';
	const requestDate2 =
		tableParams.filters.length > 1 ? tableParams.filters[1].query : '';

	return (
		<>
			<FilterContainer
				initialValues={defaultFilterValues}
				validationSchema={validationSchema}
			>
				<Form.Row>
					<Col xl={4} lg={5} md={6} xs={7}>
						<Row>
							<FormGroup as={Col} sm={6} xs={12}>
								<Form.Label>Data inicial</Form.Label>
								<DateFilter
									selectDate
									name="requestDate"
									isQuery
									queryOperator=">>"
								/>
							</FormGroup>
							<FormGroup as={Col} sm={6} xs={12}>
								<Form.Label>Data final</Form.Label>
								<DateFilter
									selectDate
									name="requestDate2"
									isQuery
									queryParam="requestDate"
									queryOperator="<<"
								/>
							</FormGroup>
						</Row>
					</Col>

					<FormGroup as={Col} xl={8} lg={7} md={6} xs={5}>
						<br />
						<FilterButton className="m-1 mr-4" />
						<ExportButton
							title="Solicitações de Saque"
							filterData={`${dateFormatter.format(requestDate)}${
								requestDate2 ? ` - ${dateFormatter.format(requestDate2)}` : ''
							}`}
							to="csv"
							action={financialService.getPayoutRequests}
							columns={columnsExport}
							disabled={!data}
						>
							<FontAwesomeIcon icon={faFileCsv} className="mr-2" /> Exportar
						</ExportButton>
					</FormGroup>
				</Form.Row>
			</FilterContainer>
		</>
	);
};

export default PayoutRequestFilters;
