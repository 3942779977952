import React from 'react';

import styled from 'styled-components';

import { PersonAddress } from 'types';

import AddressSummary from './AddressSummary';

export type AddressBrowseProps = {
	addresses: PersonAddress[];
	onSelect: (address: PersonAddress) => void;
};

const AddressBrowseItemWrapper = styled.a.attrs((props) => ({
	...props,
	className: 'd-block p-2 mb-1 shadow rounded-sm',
}))`
	cursor: pointer;
	border: 1px solid var(--border-color);
	&:hover {
		background: #f0f0f0;
	}
`;

const AddressBrowse: React.FC<AddressBrowseProps> = ({
	addresses,
	onSelect,
}) => (
	<>
		{addresses?.map((address) => (
			<AddressBrowseItemWrapper
				key={String(address.idAddress)}
				onClick={() => onSelect(address)}
			>
				<AddressSummary
					isLoading={false}
					address={address}
					showEdit={false}
					showDelete={false}
				/>
			</AddressBrowseItemWrapper>
		))}
	</>
);

AddressBrowse.displayName = 'AddressBrowse';
AddressBrowse.whyDidYouRender = true;

export default AddressBrowse;
