import styled, { css } from 'styled-components';

const JumboMenuNav = styled.ul`
	--jumbo-nav-bg: var(--base-bg);
	--jumbo-nav-hover-bg: var(--base-hover-bg);
	--jumbo-nav-color: var(--base-color);
	--jumbo-nav-hover-color: var(--base-hover-color);
	--jumbo-nav-font-size: var(--base-font-size);
	--jumbo-dropdown-width: 280px;
	--jumbo-dropdown-image-width: 260px;

	display: inline-block;
	list-style: none;
	margin: 0;
	padding-left: 0;
`;

const JumboMenuNavItem = styled.li`
	position: relative;
	display: inline-block;

	> a {
		cursor: pointer;
		display: block;
		padding: 0.5rem 1rem;
		font-size: var(--jumbo-nav-font-size);
		color: var(--jumbo-nav-color);
	}

	&.focused {
		color: var(--jumbo-nav-hover-color);
		background-color: var(--jumbo-nav-bg);
		z-index: var(--dropdown-zindex);
	}
`;

const JumboMenuNavDropdown = styled.div.attrs((props: { show: boolean }) => ({
	show: props.show,
}))`
	--jumbo-dropdown-padding-y: 0.25rem;
	--jumbo-dropdown-padding-x: 1rem;
	overflow: visible;
	position: absolute;
	min-width: 10rem;
	padding-top: var(--jumbo-dropdown-padding-y);
	padding-bottom: var(--jumbo-dropdown-padding-y);
	background-color: var(--jumbo-nav-bg);
	z-index: var(--dropdown-zindex);

	display: block;
	transition: var(--base-transition-fade);
	${(props) =>
		props.show
			? css`
					opacity: 1;
					visibility: visible;
			  `
			: css`
					opacity: 0;
					visibility: hidden;
			  `}
`;

const JumboDropdown = styled.div`
	display: block;
	width: var(--jumbo-dropdown-width);
	font-size: var(--jumbo-nav-font-size);
`;

const JumboSubmenu = styled(JumboDropdown)`
	display: inline-flex;
	flex-flow: row;
	width: auto;
	min-width: 16rem;
	height: 100%;
	white-space: nowrap;
`;

const JumboSubmenuItem = styled.div`
	> a {
		display: block;
		position: relative;
		min-width: 8rem;
		padding: var(--jumbo-dropdown-padding-y) var(--jumbo-dropdown-padding-x);
		color: var(--jumbo-nav-color);
		&:hover {
			text-decoration: underline !important;
		}

		&.focused {
			color: var(--jumbo-nav-hover-color);
		}
	}
`;

const JumboDropdownItem = styled.div`
	> ${JumboMenuNavDropdown} {
		left: 100%;
		top: 0;
		height: 100%;
		width: auto;
	}

	> a {
		display: block;
		position: relative;
		padding: var(--jumbo-dropdown-padding-y) var(--jumbo-dropdown-padding-x);
		color: var(--jumbo-nav-color);

		&.focused {
			color: var(--jumbo-nav-hover-color);
			background-color: var(--jumbo-nav-hover-bg);
		}

		.has-submenu {
			position: absolute;
			right: calc(var(--jumbo-dropdown-padding-x) / 2);
			top: 50%;
			transform: translateY(-50%);
			font-size: calc(var(--jumbo-nav-font-size) * 0.75);
			color: var(--jumbo-nav-color);
			opacity: 0.5;
		}
	}
`;

const JumboSideImage = styled.div`
	position: absolute;
	left: 100%;
	top: 0;
	bottom: 0;
	width: var(--jumbo-dropdown-image-width);
	height: 100%;
	background-color: var(--jumbo-nav-bg);

	> img {
		width: 100%;
		height: auto;
	}
`;

export default {
	JumboMenuNav,
	JumboMenuNavItem,
	JumboMenuNavDropdown,
	JumboDropdown,
	JumboDropdownItem,
	JumboSideImage,
	JumboSubmenu,
	JumboSubmenuItem,
};
