import React from 'react';
import { Link } from 'react-router-dom';

import {
	Nav,
	Button,
	Tooltip,
	OverlayTrigger,
	NavDropdown,
} from 'react-bootstrap';

import { faSignOut, faTimes, faUser } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Avatar } from 'components';
import ImageSvg from 'components/ImageSvg';
import useSessionContext from 'hooks/useSessionContext';
import { SessionPerson } from 'types';

import NavBarSkeleton from 'packages/escritorio/components/navBar/Skeleton';
import {
	BaseNav,
	LinkStyled,
	DropdownStyled,
} from 'packages/escritorio/components/navBar/styles';

export interface NavBarProps {
	isMobile?: boolean;
	menuIsOpen?: boolean;
	openLeftMenuCallBack: () => void;
}

type NavBarComponent = React.FC<NavBarProps> & {
	Skeleton: typeof NavBarSkeleton;
};

const AvatarWithName: React.FC<Pick<SessionPerson, 'name' | 'imageUrl'>> = ({
	name,
	imageUrl,
}) => (
	<>
		<Avatar text={name[0]} src={imageUrl || ''} className="mr-2" />
		{name.split(' ')[0]} {name.split(' ')[1] || ''}
	</>
);

const NavBar: NavBarComponent = (props) => {
	const { isMobile, openLeftMenuCallBack, menuIsOpen } = props;
	const {
		auth: { person: user },
	} = useSessionContext();

	return (
		<BaseNav className="justify-content-between">
			<div>
				{isMobile && (
					<Button
						variant="link"
						className="text-muted pl-1 mr-3"
						size="lg"
						onClick={openLeftMenuCallBack}
					>
						<FAIcon icon={menuIsOpen ? faTimes : faBars} />
					</Button>
				)}

				<BaseNav.Brand href="#home">
					<ImageSvg height="30" image="amakha-admin" />
				</BaseNav.Brand>
			</div>

			<Nav className="align-items-center">
				{user && (
					<DropdownStyled>
						<NavDropdown
							title={
								<AvatarWithName name={user.name} imageUrl={user.imageUrl} />
							}
							id="avatar-dropdown"
							alignRight
						>
							<NavDropdown.Item as={Link} to="/app/my/details">
								<FAIcon icon={faUser} className="mr-2" /> Meu Cadastro
							</NavDropdown.Item>
						</NavDropdown>
					</DropdownStyled>
				)}

				<OverlayTrigger
					placement="bottom-end"
					overlay={<Tooltip id="tooltip-logout">Sair</Tooltip>}
				>
					<LinkStyled to="/logout">
						<FAIcon icon={faSignOut} />
					</LinkStyled>
				</OverlayTrigger>
			</Nav>
		</BaseNav>
	);
};

NavBar.Skeleton = NavBarSkeleton;

NavBar.displayName = 'NavBar';
NavBar.whyDidYouRender = true;

export default NavBar;
