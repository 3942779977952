/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { useMounted } from '@restart/hooks';

import Backdrop from 'components/Backdrop';
import ImageSvg from 'components/ImageSvg';
import useQueryParams from 'hooks/useQueryParams';
import { actions, selectors } from 'store/ducks/session';
import { actions as cartActions } from 'store/ducks/shoppingCart';
import { ApiCodes, AppDispatch } from 'types';
import history from 'utils/history';

import ExpiredPassForm, { ExpiredPassFormModel } from './login/ExpiredPassForm';
import ForgotPassForm, { ForgotPassFormModel } from './login/ForgotPassForm';
import LoginForm, { LoginFormModel } from './login/Form';
import LoginHeader from './login/Header';

const AbsoluteDiv = styled.div`
	position: absolute;
	right: 1rem;
	top: -1rem;
`;
const FooterP = styled.p`
	font-weight: 400;
`;

type LoginSceneProps = RouteProps;

const loginInitialData: LoginFormModel = {
	email: '',
	password: '',
	remember: false,
};

const LoginScene: React.FC<LoginSceneProps> = (props) => {
	const query = useQueryParams();
	const dispatch: AppDispatch = useDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loginState, setLoginState] = useState<number>(1);
	const [showMessage, setShowMessage] = useState<boolean>(false);
	const [emailPassState, setEmailPassState] = useState<{
		email: string;
		password: string;
	}>({ email: '', password: '' });
	const error = useSelector(selectors.getErrorMessage);
	const isLogged = useSelector(selectors.isLogged);
	const isMounted = useMounted();
	const redirectTo = query.get('redirect') || '';

	const onSubmit = ({ email, password }: LoginFormModel): void => {
		setIsLoading(true);
		dispatch(actions.loginRequest(email, password))
			.then((person) => {
				if (isMounted()) {
					setIsLoading(false);
				}
				dispatch(
					cartActions.clearAll([
						`mmn_${person.idUser}`,
						`club_${person.idUser}`,
						`pa_${person.idUser}`,
					])
				);
			})
			.catch((e) => {
				setIsLoading(false);
				if (e && e.code === ApiCodes.Error.PasswordExpired) {
					setEmailPassState({ email, password });
					setLoginState(3);
				}
			});
	};

	const onSubmitForgot = ({ email }: ForgotPassFormModel): void => {
		setIsLoading(true);
		dispatch(actions.forgotRequest(email))
			.then(({ message }) => {
				if (isMounted()) {
					setIsLoading(false);
				}
				setShowMessage(!!message);
			})
			.catch(() => setIsLoading(false));
	};

	const onSubmitExpired = ({
		email,
		password,
		newPassword,
	}: ExpiredPassFormModel): void => {
		setIsLoading(true);
		dispatch(actions.redefineRequest(email, password, newPassword))
			.then(() => {
				if (isMounted()) {
					setIsLoading(false);
				}
			})
			.catch(() => setIsLoading(false));
	};

	// Se estamos logado, não fica na tela de login:
	useEffect(() => {
		if (isLogged) {
			// eslint-disable-next-line no-console
			console.log('LOGIN logado, redirecionando para ', redirectTo);
			history.push(`/redirect?path=${redirectTo}`);
		}
	}, [isLogged, redirectTo]);

	// Atualiza redirect se veio de rota não permitida
	useEffect(() => {
		// eslint-disable-next-line no-console
		console.log(
			'LOGIN rota não permitida, redirecionando para ',
			props.location
		);
		const state =
			props.location && props.location.state
				? (props.location.state as { from: { pathname: string } })
				: { from: { pathname: '' } };

		if (state.from.pathname) {
			history.replace(`/login?redirect=${state.from.pathname}`);
		}
	}, [props.location]);

	return (
		<>
			<Backdrop>
				<Backdrop.Image src="https://cdn.pixabay.com/photo/2015/05/31/13/29/lipstick-791761_960_720.jpg" />
				<Backdrop.Gradient
					filter={{ iniOpacity: 0.1, endOpacity: 0.4, iniColor: '#c6aea2' }}
				/>
			</Backdrop>
			<Container
				fluid
				className="h-100 d-flex justify-content-center align-items-center"
			>
				<Card
					className="bg-white shadow-lg"
					style={{ width: '100%', minWidth: 320, maxWidth: 420 }}
				>
					<Card.Body>
						<AbsoluteDiv>
							<LoginHeader rounded />
						</AbsoluteDiv>
						<div className="text-center mb-4 mt-4 pb-4">
							<ImageSvg image="amakha" width="150px" fill="#333" />
						</div>
						{error && <Alert variant="danger">{error}</Alert>}
						{loginState === 1 && (
							<LoginForm
								initialData={loginInitialData}
								onSubmit={onSubmit}
								isLoading={isLoading}
							/>
						)}
						{loginState === 2 && (
							<ForgotPassForm
								initialData={{ email: '' }}
								showMessage={showMessage}
								isLoading={isLoading}
								onSubmit={onSubmitForgot}
							/>
						)}
						{loginState === 3 && (
							<ExpiredPassForm
								initialData={{
									email: emailPassState.email,
									password: emailPassState.password,
									newPassword: '',
									newPasswordConfirmation: '',
								}}
								isLoading={isLoading}
								onSubmit={onSubmitExpired}
							/>
						)}
					</Card.Body>
					{loginState !== 3 && (
						<Card.Footer className="bg-light">
							<FooterP className="text-center text-muted mt-2">
								{loginState === 1 && (
									<>
										Não tem uma conta?
										<span className="mr-2" />
										<Link to="/join">Cadastre-se aqui</Link>
										<br />
										<Link to="/login" onClick={() => setLoginState(2)}>
											Esqueceu sua senha?
										</Link>
									</>
								)}
								{loginState === 2 && (
									<Link
										to="/login"
										onClick={() => {
											setShowMessage(false);
											setLoginState(1);
										}}
									>
										<FAIcon icon={faArrowLeft} /> Voltar ao login
									</Link>
								)}
							</FooterP>
						</Card.Footer>
					)}
				</Card>
			</Container>
		</>
	);
};

export default LoginScene;
