import React from 'react';

import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Col, Container, Form, Row } from 'react-bootstrap';

import * as Yup from 'yup';

import { Card } from 'components';
import {
	Select,
	Input,
	AsyncSelect,
	TextArea,
	FormikEffect,
} from 'components/Formik';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import financialService from 'services/financial.service';
import { ExecutiveLightForSelect, InsertCreditDebitEntry } from 'types';
import { currencyFormatter, numberFormatter } from 'utils/formatters';

import { PageHeader, FormSubmitButton } from 'packages/admin/components';
import useAdminContext from 'packages/admin/hooks/useAdminContext';
import asyncSelectQueries from 'packages/admin/utils/AsyncSelectQueries';

import {
	InsertCreditDebitFormType,
	TransferType,
	CompanyForOrderSelect,
} from './insertCreditDebit/types';

const transferTypes: TransferType[] = [
	{ label: 'Crédito', value: 0 },
	{ label: 'Débito', value: 1 },
];

// Lista das empresas para escolher ao informar o pedido
const companyList: CompanyForOrderSelect[] = [
	{ name: 'Pedido de Produto', cpfCnpj: '28707475000131' }, // Amakha Paris Cosméticos
	{ name: 'Marketplace', cpfCnpj: '33167204000170' }, // Amakha Market Place
];

const defaultFormValue: InsertCreditDebitFormType = {
	idUser: 0,
	company: companyList.find((c) => c.cpfCnpj === '28707475000131'),
	number: null,
	executive: undefined,
	type: undefined,
	value: 0,
	description: '',
};

const validationSchema = Yup.object<InsertCreditDebitFormType>({
	executive: Yup.object<ExecutiveLightForSelect>().required(
		'Campo obrigatório'
	),
	type: Yup.object<TransferType>().required('Campo obrigatório'),
	value: Yup.number()
		.required('Campo obrigatório')
		.min(0.01, 'Valor deve ser maior que 0,00'),
	company: Yup.object<CompanyForOrderSelect>().nullable().notRequired(),
	number: Yup.string().nullable().notRequired(),
	description: Yup.string()
		.trim()
		.required('Campo obrigatório')
		.min(5, 'Descrição deve ter pelo menos 5 caracteres'),
	idUser: Yup.ref('executive.idPerson'),
});

const InsertCreditDebit: React.FC = () => {
	const [toast] = useToast();
	const { companies } = useAdminContext();

	const handleSubmit = async <T extends InsertCreditDebitFormType>(
		// eslint-disable-next-line no-unused-vars
		{ idUser, number, executive, type, company, ...values }: T,
		{ setSubmitting, resetForm, setFieldError }: FormikHelpers<T>
	) => {
		const submit: InsertCreditDebitEntry = {
			...values,
			id: Number(executive?.id) || 0,
			value: type === transferTypes[1] ? -values.value : values.value,
			idCompany: company
				? Number(companies.find((c) => c.cpfCnpj === company.cpfCnpj)?.idPerson)
				: null,
			number: number ? numberFormatter.formatForSearch(number) : null,
		};

		try {
			await financialService.createCreditDebitRequest(submit);
			resetForm();
			toast('Lançamento inserido com sucesso', {
				type: 'success',
			});
		} catch (e) {
			toast('Não foi possível fazer a inserção do crédito/débito', {
				type: 'error',
			});

			// Seta erro no campo Pedido
			if (number) {
				setFieldError('number', 'Número de pedido inválido');
				// TODO: Foco no campo com erro
			}
		}
		setSubmitting(false);
	};

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>Inserir Crédito e Débito</PageHeader.Title>
				<PageHeader.Breadcrumb
					items={[
						{ label: 'Financeiro', path: '/admin/financial/transfer' },
						{ label: 'Inserir Crédito e Débito', active: true },
					]}
				/>
			</PageHeader>
			<Formik
				onSubmit={handleSubmit}
				initialValues={defaultFormValue}
				validationSchema={validationSchema}
			>
				{(form) => (
					<FormikForm>
						<FormikEffect focusOnError promptOnExit />

						<Card>
							<Card.Content className="offset-lg-3 col-lg-6">
								<div className="d-none">
									<Input name="idUser" />
								</div>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Executivo</Form.Label>
										<AsyncSelect
											placeholder="Selecione um executivo"
											name="executive"
											optionValue="idPerson"
											fetchMethod={executiveService.getForSelect}
											fetchQuery={asyncSelectQueries.executiveQuery}
											formatOptionLabel={(value: ExecutiveLightForSelect) => {
												return (
													<>
														<b>{value.id}</b> {value.name}
													</>
												);
											}}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Tipo</Form.Label>
										<Select
											name="type"
											options={transferTypes}
											optionLabel="label"
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Valor</Form.Label>
										<Input name="value" formatter={currencyFormatter} />
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} sm={6} xl={4}>
										<Form.Label>Tipo do Pedido</Form.Label>
										<Select
											name="company"
											options={companyList}
											optionLabel="name"
											optionValue="cpfCnpj"
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Núm. do Pedido</Form.Label>
										<Input name="number" formatter={numberFormatter} />
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Descrição</Form.Label>
										<TextArea name="description" resize="vertical" rows={5} />
									</Form.Group>
								</Form.Row>
							</Card.Content>
							<Card.Content className="pt-0">
								<Row>
									<Col
										sm={12}
										md={{ span: 8, offset: 2 }}
										xl={{ span: 6, offset: 3 }}
									>
										<div className="text-right">
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</div>
									</Col>
								</Row>
							</Card.Content>
						</Card>
					</FormikForm>
				)}
			</Formik>
		</Container>
	);
};

export default InsertCreditDebit;
