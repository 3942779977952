import ecommerceService from 'services/ecommerce.service';
import { AppAction, EcStoreConfig } from 'types';

import actions from './actions';
import { EcommerceActionEnum } from './types';

const storeRequest = (
	_store: 'mmn' | 'club' | 'stock'
): AppAction<Promise<EcStoreConfig>> => async (dispatch) => {
	dispatch({ type: EcommerceActionEnum.StoreRequest });
	try {
		const result = await ecommerceService.getStoreMMN();

		const sortFn = (a: { sort: number }, b: { sort: number }) =>
			a.sort < b.sort ? -1 : 1;

		const newStore = {
			...result,
			banner: result.banner.sort(sortFn),
			feature: result.feature
				.map((feature) => ({
					...feature,
					product: feature.product.sort(sortFn),
				}))
				.sort(sortFn),
			menu: result.menu.sort(sortFn),
		};
		dispatch(actions.storeSuccess(newStore));
		return Promise.resolve(newStore);
	} catch (e) {
		dispatch(actions.storeFailed(e));
		return Promise.reject(e);
	}
};

export default {
	storeRequest,
};
