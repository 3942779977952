/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card } from 'components';
import {
	AsyncSelect,
	FormikEffect,
	Input,
	Switch,
	Upload,
} from 'components/Formik';
import uploadHelpers from 'components/upload/Helpers';
import { UploadFile } from 'components/upload/types';
import { useToast } from 'hooks';
import careerService from 'services/career.service';
import vipService from 'services/vip.service';
import { Career, VipForSelect } from 'types';
import { currencyFormatter, decimalFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './career/SkeletonForm';
import { CareerFormType } from './career/types';

export const defaultFormValue: CareerFormType = {
	idCareer: null,
	name: '',
	goalValue: '',
	maxInLine: '',
	plFactor: '',
	idVip: null,
	vip: null,
	level: '',
	image: [],
	isActive: true,
};

export const formSchema = Yup.object().shape<CareerFormType>({
	idCareer: Yup.mixed(),
	name: Yup.string().required('Campo obrigatório'),
	goalValue: Yup.number()
		.required('Campo obrigatório')
		.min(0.01, 'Valor deve ser maior que 0,00'),
	maxInLine: Yup.string().required('Campo obrigatório'),
	image: Yup.array()
		.of(
			Yup.object()
				.shape<UploadFile>({
					icon: Yup.mixed(),
					name: Yup.string(),
					preview: Yup.mixed(),
					size: Yup.number(),
					type: Yup.string(),
					uid: Yup.string(),
					url: Yup.string().nullable(),
				})
				.nullable()
				.required()
		)
		.nullable()
		.required('Campo obrigatório'),
	idVip: Yup.string().nullable(),
	vip: Yup.object()
		.shape<VipForSelect>({
			idVip: Yup.string(),
			name: Yup.string(),
		})
		.nullable(),
	level: Yup.number()
		.required('Campo obrigatório')
		.max(150, 'O valor máximo é 150'),
	isActive: Yup.boolean().required(),
});

const CareerEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			let career: CareerFormType = defaultFormValue;

			if (isCreating) {
				// Preenche o level com o próximo disponível
				const { data: careers } = await careerService.getForList({});
				const lastLevel: number = careers.reduce(
					(prev, next) =>
						Number(next.level) > prev ? Number(next.level) : prev,
					0
				);
				career.level = String(lastLevel + 1);
			} else {
				const { image, ...response } = await careerService.getById(Number(id));
				career = {
					...response,
					// Transformar prop image em UploadFile[]
					...(image && { image: uploadHelpers.mapImages(image) }),
				};
			}

			return { career };
		},
		[]
	);

	const handleSubmit = useCallback(
		async (
			id: string,
			{ image, vip, goalValue, level, ...values }: CareerFormType,
			{ setSubmitting }
		) => {
			const submit: Career = {
				...values,
				goalValue: String(goalValue),
				level: String(level),
				idVip: vip?.idVip ?? null,
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(image) &&
					(uploadHelpers.isNewFile(image[0]?.url)
						? { image: image[0]?.url }
						: image.length === 0 && { image: '' })),
			};

			try {
				await careerService.createOrUpdate(
					(id === 'create' && null) || Number(id),
					submit
				);
				toast(`Dados ${id ? 'alterados' : 'criados'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/mmn/career');
			} catch (e) {
				toast(`Não foi possível editar a carreira. Cód: ${e.code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[toast, history]
	);

	return (
		<PageLayoutFormWrapper
			title="Carreira"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/career' },
				{ label: 'Carreira', path: '/admin/mmn/career' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.career}
					validationSchema={formSchema}
				>
					{(form: FormikProps<CareerFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator justifyContent="space-between">
									<div />
									<Form.Group className="mb-0">
										<Switch name="isActive" defaultChecked />
										<Form.Label className="text-muted pl-1">Ativo</Form.Label>
									</Form.Group>
								</Card.Header>
								<Card.Content>
									<Row>
										<Col md={8}>
											<Form.Row>
												<Form.Group as={Col} md={6} lg={3}>
													<Form.Label>Nível da carreira</Form.Label>
													<Input name="level" formatter={decimalFormatter(0)} />
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} sm={6}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" />
												</Form.Group>
												<Form.Group as={Col} sm={6}>
													<Form.Label>Meta</Form.Label>
													<Input
														name="goalValue"
														formatter={currencyFormatter}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} sm={6}>
													<Form.Label>Limite por linha (%)</Form.Label>
													<Input
														name="maxInLine"
														formatter={decimalFormatter(2)}
													/>
												</Form.Group>
												<Form.Group as={Col} sm={6}>
													<Form.Label>VIP</Form.Label>
													<AsyncSelect
														name="vip"
														fetchMethod={vipService.getForList}
														optionValue="idVip"
														disabled={form.isSubmitting}
														isClearable
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Col sm="auto" className="flex-grow-1" />
											</Form.Row>
										</Col>
										<Col md={4}>
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<Form.Label>Imagem</Form.Label>
													<Upload
														name="image"
														listType="picture-card"
														showUploadList={false}
														aspect={4 / 3}
														accept=".jpg, .png"
														maxSize={2}
														disabled={form.isSubmitting}
													>
														<FAIcon icon={faImage} />
														<div>Adicionar imagem</div>
													</Upload>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="text-right border-top">
									<Form.Row>
										<Col>
											<FormCancelButton to="/admin/mmn/career" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default CareerEdit;
