import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import * as Yup from 'yup';

import { decimalFormatter } from 'utils/formatters';

import {
	FilterContainer,
	MonthSelectFilter,
	YearFilter,
	ExecutiveSelectFilter,
	ExecutiveBalance,
	FilterButton,
} from 'packages/admin/components/datatableView';
import { monthOptionsBR } from 'packages/admin/components/datatableView/MonthSelectFilter';

const validationSchema = Yup.object({
	id: Yup.object().nullable().required('Campo obrigatório'),
	month: Yup.object().nullable().required('Campo obrigatório'),
	year: Yup.number().required('Campo obrigatório'),
});

const decFormatter = decimalFormatter(2);

const defaultFilterValues = {
	id: null,
	month: monthOptionsBR[new Date().getMonth()],
	year: new Date().getFullYear(),
};

const StatementByPersonFilter: React.FC = () => (
	<FilterContainer
		initialValues={defaultFilterValues}
		validationSchema={validationSchema}
	>
		<Form.Row>
			<FormGroup as={Col} lg={3} md={4} sm={4} xs={4}>
				<Form.Label>Executivo</Form.Label>
				<ExecutiveSelectFilter name="id" queryKey="idPerson" />
			</FormGroup>
			<FormGroup as={Col} lg={2} md={3} sm={4} xs={4}>
				<Form.Label>Mês</Form.Label>
				<MonthSelectFilter name="month" />
			</FormGroup>
			<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
				<Form.Label>Ano</Form.Label>
				<YearFilter name="year" />
			</FormGroup>
			<FormGroup as={Col} className="col-auto d-flex align-items-end">
				<FilterButton />
			</FormGroup>
			<FormGroup as={Col} className="text-right">
				<Form.Label>Saldo Total</Form.Label>
				<ExecutiveBalance
					className="d-block"
					idPersonField="id"
					prefix="AMK "
					formatter={decFormatter}
				/>
			</FormGroup>
		</Form.Row>
	</FilterContainer>
);

export default StatementByPersonFilter;
