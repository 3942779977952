import { useSelector } from 'react-redux';

import cartSelectors from 'store/ducks/shoppingCart/selectors';

import useShoppingCartName from './useShoppingCartName';

const useShoppingCart = () => {
	const cartName = useShoppingCartName();
	const cartItems = useSelector(cartSelectors.getCart(cartName));

	return cartItems;
};

export default useShoppingCart;
