import { SessionPerson } from 'types';

import { SessionState } from './types';

type Store = { session: SessionState };

const getSessionPerson = (store: Store): SessionPerson =>
	store.session.user as SessionPerson;

const isLogged = (store: Store): boolean =>
	!!(store.session.user && store.session.permissions);

const getLastError = (store: Store) => store.session.lastError;

const getErrorMessage = (store: Store) => getLastError(store)?.message;

export default {
	getSessionPerson,
	isLogged,
	getLastError,
	getErrorMessage,
};
