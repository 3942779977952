import React from 'react';

import { Button } from 'react-bootstrap';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/modalContainer/types';

const PayoutInfoModal: React.FC<ModalForwardProps<{}, void>> = ({
	modalRef,
	...rest
}) => {
	if (!modalRef) return null;

	return (
		<Modal {...rest} size="lg" variant="teal">
			<Modal.Header closeButton>
				<Modal.Title className="m-0">Atenção</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					A AMAKHA PARIS a partir de 01/10/2019 utilizará as bases de retenções
					governamentais para cálculo de RPA - Recibo de Pagamento a Autônomo e
					resolve extinguir a base mínima de retenção na fonte no valor de R$
					1.600,00 (um mil e seiscentos reais), eliminar a taxa de transferência
					no valor de R$ 11,90 (onze reais e noventa centavos) e reduzir a taxa
					administrativa de 27,00% para 16,67%.
				</p>
				<p>
					Para realizar saques de bônus é necessário o envio de um documento que
					justifique tal movimentação como NFS – Nota Fiscal de Serviços ou RPA
					– Recibo de Pagamento a Autônomo.
				</p>
				<ul>
					<li>
						<strong>SAQUE por Nota Fiscal</strong>: O EXECUTIVO ou PA deverão
						enviar a NFS no momento da solicitação de saque para o e-mail{' '}
						<a href="mailto:nfe@amakhacosmeticos.com.br">
							nfe@amakhacosmeticos.com.br
						</a>
						. O pagamento ocorrerá após 10 dias úteis do envio da NFS. Para este
						caso, não há haverá retenção na fonte (para empresas Optante do
						Simples Nacional).
						<br />
						<strong>
							OBS. As NFS a partir de 01/10/2019 deverão ser emitidas para a
							empresa: AMAKHA INTERMEDIAÇÃO DE NEGÓCIOS – EIRELI CNPJ:
							33.167.204/0001-70 ENDEREÇO: ALAMEDA TERRACOTA, Nº 215, SALA 220,
							SÃO CAETANO DO SUL, CEP: 09531- 190
						</strong>
					</li>
					<li>
						<strong>SAQUE por RPA</strong> (Recibo de Pagamento a Autônomo):
						haverá a retenção na fonte de acordo com as taxas governamentais uma
						taxa administrativa de 16,67% do valor solicitado.
					</li>
				</ul>
				<p>
					Para a emissão do RPA pela Amakha Paris, no momento da solicitação do
					saque será necessário enviar um e-mail para{' '}
					<a href="mailto:nfe@amakhacosmeticos.com.br">
						nfe@amakhacosmeticos.com.br
					</a>{' '}
					com alguns dados da pessoa física.
				</p>
				<p>
					<a target="_blank" href="/assets/docs/Amakha_Saque.pdf">
						Saiba mais clicando aqui.
					</a>
				</p>
				<p>
					Atenciosamente,
					<br />A direção
				</p>
			</Modal.Body>
			<Modal.Footer align="end">
				<Button variant="primary" onClick={() => modalRef.dismiss()}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PayoutInfoModal;
