import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { faFile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export type ListNewButtonProps = LinkProps;

const ListNewButton: React.FC<ListNewButtonProps> = ({
	className,
	...rest
}) => (
	<Link className={classNames('btn btn-sm btn-primary', className)} {...rest}>
		<FontAwesomeIcon icon={faFile} className="mr-2" /> Novo
	</Link>
);

export default ListNewButton;
