import React, { useCallback } from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { DateTimeInput } from 'components/Formik';
import { QueryOperator } from 'types';
import { dateFormatter } from 'utils/formatters';

export type DateFilterProps = {
	name: string;
	isQuery?: boolean;
	className?: string;
	selectDate?: boolean;
	selectTime?: boolean;
	queryOperator?: QueryOperator;
	queryParam?: string;
};

const DateFilter: React.FC<DateFilterProps> = ({
	name,
	isQuery = true,
	selectDate = true,
	selectTime = false,
	className = '',
	queryOperator,
	queryParam,
}) => {
	const { onQueryChange, onParamChange } = useDatagridContext();

	const onChange = useCallback(
		(isoDate: string) => {
			let date = selectTime
				? isoDate
				: dateFormatter.formatForInput(dateFormatter.format(isoDate))[0];
			const queryKey = queryParam ?? name;

			// Caso seja o segundo input de um período de datas, precisa ser o último segundo do dia
			if (
				!selectTime &&
				date.length === 10 &&
				(queryOperator === '<' || queryOperator === '<<')
			) {
				date = `${date} 23:59:59`;
			}

			if (isQuery) {
				onQueryChange(queryKey, date, queryOperator);
			} else {
				onParamChange(queryKey, date);
			}
		},
		[
			name,
			selectTime,
			isQuery,
			onQueryChange,
			onParamChange,
			queryParam,
			queryOperator,
		]
	);

	return (
		<DateTimeInput
			name={name}
			selectDate={selectDate}
			selectTime={selectTime}
			className={className}
			onChange={onChange}
		/>
	);
};

export default DateFilter;
