/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useInfiniteQuery } from 'react-query';

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Empty } from 'components';
import { useToast } from 'hooks';
import newsNotificationService from 'services/newsNotification.service';
import { HttpGetListResult, NewsNotification } from 'types';

import PageHeader from 'packages/admin/components/PageHeader';

import CardNews from './news/CardNews';
import NewsSkeleton from './news/NewsSkeleton';
import { ScrollReplaceContainer, NewsContainer } from './news/styles';

type ElementScrollProps = {
	scrollHeight: number;
	scrollLeft: number;
	scrollTop: number;
	scrollWidth: number;
	clientHeight: number;
	clientWidth: number;
	clientLeft: number;
	clientTop: number;
	offsetHeight: number;
	offsetLeft: number;
	offsetTop: number;
	offsetWidth: number;
};

const fetchNews = async (
	_: string,
	paginate: number,
	page = 1
): Promise<HttpGetListResult<NewsNotification>> =>
	newsNotificationService.getForPerson('news', {
		pagination: { paginate, page },
	});

const News: React.FC = () => {
	const [toast] = useToast();
	const [perPage] = useState<number>(9);

	const {
		data: searchData,
		isFetchingMore,
		fetchMore,
		canFetchMore,
		isLoading,
		isError,
	} = useInfiniteQuery<HttpGetListResult<NewsNotification>>(
		['utils_news', perPage],
		fetchNews,
		{
			getFetchMore: ({ meta }) =>
				meta?.last_page === meta?.current_page
					? false
					: (meta?.current_page || 0) + 1,
			onError: (error) => {
				// eslint-disable-next-line no-console
				console.log({ error });
				const errorMsg = 'Erro interno: Ocorreu um erro ao obter as Notícias.';
				toast(errorMsg, { type: 'error' });
			},
		}
	);
	const allItemsLength: number =
		searchData?.map((r) => r.data).flat().length || 0;

	const handleScrollBottom = (e: React.ChangeEvent<ElementScrollProps>) => {
		if (!canFetchMore || isFetchingMore) return;

		const bottomPosition = Math.round(
			e.target.scrollHeight - e.target.scrollTop
		);

		const bottom =
			bottomPosition === e.target.clientHeight ||
			bottomPosition === e.target.clientHeight + 1;

		if (bottom) {
			fetchMore();
		}
	};

	return (
		<ScrollReplaceContainer fluid onScroll={handleScrollBottom}>
			<PageHeader alignItems="center">
				<PageHeader.Title>Últimas Notícias</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils' },
						{
							label: 'Notícias',
							path: '/app/utils/news',
							active: true,
						},
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<NewsSkeleton />
			) : (
				<>
					{isError ? (
						<Empty
							description="Não foi possível carregar a lista de Notícias"
							image={<FAIcon icon={faTimesCircle} className="text-danger" />}
						/>
					) : allItemsLength === 0 ? (
						<Empty
							description="Nenhuma notícia"
							image={<FAIcon icon="check-circle" className="text-info" />}
						/>
					) : (
						<>
							<NewsContainer>
								{searchData?.map(({ data, meta }) => (
									<React.Fragment key={meta?.current_page ?? 0}>
										{data.map((n) => (
											<CardNews item={n} key={n.idNews ?? 0} />
										))}
									</React.Fragment>
								))}
							</NewsContainer>

							{isFetchingMore && (
								<Row className="justify-content-center mb-3">
									<Col xs="auto">
										<Spinner animation="border" role="status" />
									</Col>
								</Row>
							)}
						</>
					)}
				</>
			)}
		</ScrollReplaceContainer>
	);
};

export default News;
