import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PageHeader from 'packages/admin/components/PageHeader';

import ExecutiveCards from './executives/ExecutiveCards';
import ExecutiveLastRegister from './executives/ExecutiveLastRegister';
import ExecutiveRegion from './executives/ExecutiveRegion';
import ExecutiveSearchList from './executives/ExecutiveSearchList';

const ExecutivesPage = (): React.ReactElement => {
	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>Executivos</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[{ label: 'Executivos', active: true }]}
				/>
			</PageHeader>

			<Row>
				<Col md={8}>
					<ExecutiveCards />

					<ExecutiveSearchList className="mt-3" />

					<ExecutiveRegion className="mt-3" />
				</Col>

				<Col md={4}>
					<ExecutiveLastRegister />
				</Col>
			</Row>
		</Container>
	);
};

export default ExecutivesPage;
