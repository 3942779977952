import { DatagridState } from 'components/datagrid/types';
import { Pagination, HttpBaseResult } from 'types';

import { DatatableViewEnum, DatatableViewActionTypes } from './types';

const setParams = (params: DatagridState): DatatableViewActionTypes => ({
	type: DatatableViewEnum.SetParams,
	payload: params,
});

const setLoading = (loading: boolean): DatatableViewActionTypes => ({
	type: DatatableViewEnum.SetLoading,
	payload: loading,
});

const setFetchData = (
	data: unknown[],
	pagination: Pagination
): DatatableViewActionTypes => ({
	type: DatatableViewEnum.SetFetchData,
	payload: { data, pagination },
});

const setFetchError = (error: HttpBaseResult): DatatableViewActionTypes => ({
	type: DatatableViewEnum.SetFetchError,
	payload: error,
});

export default {
	setParams,
	setLoading,
	setFetchData,
	setFetchError,
};
