import React from 'react';

import { faEye } from '@fortawesome/pro-light-svg-icons';

import financialService from 'services/financial.service';
import { ExecutiveStatement, StatementPayoutRequest } from 'types';
import {
	dateFormatter,
	dateHourMinFormatter,
	numberFormatter,
	editLinkFormatter,
	currencyFormatter,
	currencyWithSymbolFormatter,
	coloredStatusFormatter,
	buttonLinkFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import {
	financialStatusPaid,
	payoutAlertStatus,
} from 'packages/admin/utils/coloredStatus';
import UserDetailsModal, {
	UserDetailsModalProps,
} from 'packages/escritorio/pages/distributor/order/history/modal/ExecutiveDetailsModal';

import { ListSceneConfig } from '../types';

import CashierCompanyFilters from './financial/CashierCompanyFilters';
import PayoutRequestModal from './financial/modal/PayoutRequestModal';
import MonthYearFilter from './financial/MonthYearFilter';
import PayoutAlertFilters from './financial/PayoutAlertFilters';
import PayoutRequestFilters from './financial/PayoutRequestFilters';
import PayRequestButton from './financial/PayRequestButton';
import StatementByOrderFilters from './financial/StatementByOrderFilter';
import StatementByExecutiveFilters from './financial/StatementByPersonFilter';
import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Financeiro',
		path: '/admin/financial/statement/by-person',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	StatementByExecutive: {
		title: 'Extrato x Executivo',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Extrato x Executivo',
				active: true,
			},
		],
		action: financialService.getStatementByExecutiveId,
		datatableFilters: <StatementByExecutiveFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateFormatter,
				},
				{ key: 'description', title: 'Descrição', searchable: true },
				{
					key: 'friendId',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'friendName',
					title: 'Amigo',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'number',
					title: 'Pedido',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'value',
					title: 'AMK',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	StatementByOrder: {
		title: 'Extrato x Pedido',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Extrato x Pedido',
				active: true,
			},
		],
		action: financialService.getStatementByOrderId,
		datatableFilters: <StatementByOrderFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateFormatter,
				},
				{
					key: 'personId',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: buttonLinkFormatter<
						ExecutiveStatement,
						UserDetailsModalProps
					>({
						label: (row) => numberFormatter.format(row.personId).toString(),
						className: 'btn p-0 text-center',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row: { id: row.personId },
								config: { showContactOnly: false },
							}),
					}),
				},
				{
					key: 'personName',
					title: 'Executivo',
					searchable: true,
					formatter: buttonLinkFormatter<
						ExecutiveStatement,
						UserDetailsModalProps
					>({
						label: (row) => row.personName,
						className: 'btn p-0 text-left',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row: { id: row.personId },
								config: { showContactOnly: false },
							}),
					}),
				},
				{ key: 'description', title: 'Descrição', searchable: true },
				{
					key: 'friendId',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: buttonLinkFormatter<
						ExecutiveStatement,
						UserDetailsModalProps
					>({
						label: (row) =>
							numberFormatter.format(Number(row.friendId)).toString(),
						className: 'btn p-0 text-center',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row: { id: Number(row.friendId) },
								config: { showContactOnly: false },
							}),
					}),
				},
				{
					key: 'friendName',
					title: 'Amigo',
					searchable: true,
					formatter: buttonLinkFormatter<
						ExecutiveStatement,
						UserDetailsModalProps
					>({
						label: (row) => row.friendName,
						className: 'btn p-0 text-left',
						onClick: (_, row, openModal) =>
							openModal(UserDetailsModal, {
								row: { id: row.personId },
								config: { showContactOnly: false },
							}),
					}),
				},
				{
					key: 'value',
					title: 'AMK',
					searchable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
			],
		},
	},
	StatementManual: {
		title: 'Lançamentos Manuais',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Lançamentos Manuais',
				active: true,
			},
		],
		action: financialService.getManualEntries,
		datatableFilters: <MonthYearFilter />,
		datatableConfig: {
			updateOnFilterChange: false,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateFormatter,
				},
				{ key: 'operator', title: 'Operador', searchable: true },
				{
					key: 'personId',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'personName', title: 'Executivo', searchable: true },
				{ key: 'description', title: 'Descrição', searchable: true },
				{
					key: 'number',
					title: 'Pedido',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'value',
					title: 'Valor',
					searchable: true,
					className: 'text-center',
					formatter: currencyWithSymbolFormatter,
				},
			],
		},
	},
	PayoutRequest: {
		title: 'Solicitações de Saque',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Solicitações de Saque',
				active: true,
			},
		],
		action: financialService.getPayoutRequests,
		datatableFilters: <PayoutRequestFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'requestDate',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPayment',
					title: '',
					searchable: false,
					formatter: editLinkFormatter({
						icon: faEye,
						onClick: (id, _, openModal) =>
							openModal(PayoutRequestModal, { id }),
					}),
				},
				{
					key: 'idPayment',
					title: 'Nº da Solicitação',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'executiveId',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'executiveName', title: 'Executivo', searchable: true },
				{
					key: 'value',
					title: 'Valor',
					searchable: true,
					className: 'text-center',
					formatter: currencyWithSymbolFormatter,
				},
				{
					key: 'requestDate',
					title: 'Data da Solicitação',
					searchable: true,
					className: 'text-center',
					formatter: dateHourMinFormatter,
				},

				{
					key: 'payDate',
					title: 'Data do Pagamento',
					searchable: true,
					className: 'text-center',
					formatter: dateHourMinFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: financialStatusPaid,
					}),
				},
				{
					key: 'idPayment',
					title: 'Pagar',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter<
						StatementPayoutRequest,
						StatementPayoutRequest
					>({
						component: PayRequestButton,
					}),
				},
			],
		},
	},
	PayoutAlert: {
		title: 'Alerta de Pagamentos',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Alerta de Pagamentos',
				active: true,
			},
		],
		action: financialService.getPayoutAlerts,
		datatableFilters: <PayoutAlertFilters />,
		datatableConfig: {
			updateOnFilterChange: false,
			sortByKey: 'date',
			sortByDesc: true,
			paginate: paginateConfig,
			columns: [
				{
					key: 'date',
					title: 'Data do Pedido',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: dateHourMinFormatter,
				},
				{
					key: 'idPayment',
					title: 'ID',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'user',
					title: 'Executivo',
					searchable: true,
					sortable: true,
				},
				{
					key: 'number',
					title: 'Pedido',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'value',
					title: 'Valor do Pedido',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyWithSymbolFormatter,
				},
				{
					key: 'operator',
					title: 'Responsável',
					searchable: true,
					sortable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: payoutAlertStatus,
					}),
				},
				{
					key: 'observation',
					title: 'Motivo',
					searchable: false,
					sortable: true,
				},
			],
		},
	},
	CashierCompany: {
		title: 'Fechamento de Caixa',
		breadcrumbItems: [
			...adminBreadcrumb,
			{ label: 'Fechamento de Caixa', active: true },
		],
		action: financialService.getCashierByCompany,
		datatableFilters: <CashierCompanyFilters />,
		datatableConfig: {
			paginate: paginateConfig,
			updateOnFilterChange: false,
			columns: [
				{ key: 'name', title: 'Atendente', searchable: true, sortable: true },
				{
					key: 'orderNumber',
					title: 'Pedido',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'deposito',
					title: 'Depósito',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'pagSeguro',
					title: 'PagSeguro',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'amk',
					title: 'Saldo',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'dinheiro',
					title: 'Dinheiro',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'cartao',
					title: 'Cartão',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'total',
					title: 'Total',
					searchable: true,
					sortable: true,
					className: 'text-center',
					formatter: currencyFormatter,
				},
				{
					key: 'type',
					title: 'Tipo',
					searchable: true,
					sortable: true,
					className: 'text-center',
				},
			],
		},
	},
};

export default ScenesConfig;
