import React from 'react';

import {
	StyledTimeline,
	TimelineItem,
	TimelineBadge,
	TimelineDate,
	TimelinePanel,
	TimelineHeader,
	TimelineHeaderIcon,
	TimelineContent,
	TimelineBody,
	TimelineFooter,
	TimelineClearfix,
} from './timeline/styles';

const Timeline: React.FC = ({ children, ...rest }) => (
	<StyledTimeline {...rest}>
		{children}
		<TimelineClearfix />
	</StyledTimeline>
);

Timeline.displayName = 'Timeline';
Timeline.whyDidYouRender = true;

export default Object.assign(Timeline, {
	Item: TimelineItem,
	Badge: TimelineBadge,
	Date: TimelineDate,
	Panel: TimelinePanel,
	Header: TimelineHeader,
	HeaderIcon: TimelineHeaderIcon,
	Content: TimelineContent,
	Body: TimelineBody,
	Footer: TimelineFooter,
});
