import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Drawer from 'components/Drawer';
import { selectors } from 'store/ducks/ecommerce';
import { history } from 'utils';
import { currencyFormatter } from 'utils/formatters';

import {
	useSetShoppingCartVisible,
	useShoppingCartView,
	useEcommerceBasePath,
} from '../hooks';

import ProductCard from './ProductCard';
import Skeleton from './shoppingCartSidebar/Skeleton';
import { CartSubtotal } from './Typography';

const DrawerWrapper = styled(Drawer)`
	.drawer-content-wrapper {
		min-width: 320px;
		max-width: 320px;
		width: 100%;
	}
`;

const ShoppingCartSidebar: React.FC = () => {
	const baseUrl = useEcommerceBasePath();
	const isCartVisible = useSelector(selectors.isCartVisible);
	const setCartSidebarVisible = useSetShoppingCartVisible();
	const cartView = useShoppingCartView();

	const gotoCart = () => {
		setCartSidebarVisible(false);
		history.push(`${baseUrl}/meu-carrinho`);
	};

	const gotoCheckout = () => {
		setCartSidebarVisible(false);
		history.push(`${baseUrl}/checkout`);
	};

	useEffect(() => {
		if (isCartVisible) {
			document.body.style.height = '100%';
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.height = '';
			document.body.style.overflow = '';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCartVisible]);

	return (
		<DrawerWrapper
			visible={isCartVisible}
			placement="right"
			onClose={() => setCartSidebarVisible(false)}
		>
			<Drawer.Header className="px-3 py-2 d-flex align-items-center">
				Meu Carrinho
				<Button
					size="sm"
					type="button"
					variant="link"
					className="ml-auto"
					onClick={() => setCartSidebarVisible(false)}
				>
					<FontAwesomeIcon icon={faTimes} className="text-dark" />
				</Button>
			</Drawer.Header>
			<Drawer.Body>
				{cartView.isLoading ? (
					<Skeleton count={cartView.items.length} />
				) : (
					cartView.items.map((item) => (
						<ProductCard.Small
							key={item.id}
							className="px-1 py-2 border-bottom"
							item={item}
							product={cartView.productMap[item.id]}
						/>
					))
				)}
			</Drawer.Body>
			<Drawer.Footer className="px-3 py-2">
				<CartSubtotal className="d-flex">
					Subtotal:
					<span className="ml-auto">
						{currencyFormatter.format(cartView.subtotal)}
					</span>
				</CartSubtotal>
				<Button variant="outline-primary" block onClick={gotoCart}>
					Ver Carrinho
				</Button>
				<Button
					variant="dark"
					block
					disabled={!cartView.items.length}
					onClick={gotoCheckout}
				>
					Comprar Agora
				</Button>
			</Drawer.Footer>
		</DrawerWrapper>
	);
};

export default ShoppingCartSidebar;
