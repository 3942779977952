import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/ducks/rootReducer';
import { SessionState } from 'store/ducks/session';
import { RouteDef } from 'types';

// Concatena path das rotas filhas e filtra os itens que não passarem pela validação
function remapAndValidateRoutes(
	routes: RouteDef[],
	basePath: string,
	session: SessionState
) {
	const filteredRoutes: RouteDef[] = [];
	for (let i = 0; i < routes.length; ++i) {
		const r = routes[i];
		if (r.validation && !r.validation.every((v) => v(session))) {
			// eslint-disable-next-line no-continue
			continue;
		}
		const newBasePath = basePath + r.path;
		const newRoute = {
			...r,
			path: basePath + r.path,
			children:
				r.children && remapAndValidateRoutes(r.children, newBasePath, session),
		};
		if (!r.children || (newRoute.children && newRoute.children.length > 0)) {
			filteredRoutes.push(newRoute);
		}
	}
	return filteredRoutes;
}

const collectRoutes = (routes: RouteDef[]): RouteDef[] =>
	routes.flatMap(
		(r) => (r.children ? [...collectRoutes(r.children)] : [r]),
		[]
	);

const useRouteValidator = (routes: RouteDef[]): [RouteDef[], RouteDef[]] => {
	const session = useSelector<RootState, SessionState>(
		(store) => store.session
	);

	const filteredRoutes = useMemo(
		(): RouteDef[] => remapAndValidateRoutes(routes, '', session),
		[routes, session]
	);

	const flatRoutes = useMemo(() => collectRoutes(filteredRoutes), [
		filteredRoutes,
	]);

	return [filteredRoutes, flatRoutes];
};

export default useRouteValidator;
