import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Router,
	Switch,
	Route,
	useRouteMatch,
	Redirect,
} from 'react-router-dom';

import { actions, selectors } from 'store/ducks/ecommerce';
import { AppDispatch } from 'types';
import history from 'utils/history';

import EcLayout, { EcLayoutStyle } from '../components/EcLayout';
import ShoppingCartSidebar from '../components/ShoppingCartSidebar';

import Checkout from './Checkout';
import HomeScene from './Home';
import MainRouterSkeleton from './mainRouter/Skeleton';
import MyShoppingCart from './MyShoppingCart';
import ProductDetails from './ProductDetails';
import ProductList from './ProductList';

const EcommerceRoutes: React.FC = () => {
	const { url } = useRouteMatch();
	return (
		<EcLayout>
			<Router history={history}>
				<Switch>
					<Route exact path={`${url}`} component={HomeScene} />
					<Route
						exact
						path={`${url}/meu-carrinho`}
						component={MyShoppingCart}
					/>
					<Route exact path={`${url}/categoria/:id`} component={ProductList} />
					<Route exact path={`${url}/produto/:id`} component={ProductDetails} />
					<Redirect to={url} />
				</Switch>
			</Router>
		</EcLayout>
	);
};

const NavbarWithRoutes = (): ReactElement => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch<AppDispatch>();
	const isLoading = useSelector(selectors.isLoading);

	useEffect(() => {
		dispatch(actions.storeRequest('mmn')).catch(() => {
			history.push('/app');
		});
	}, [dispatch]);

	if (isLoading) {
		return <MainRouterSkeleton />;
	}

	return (
		<EcLayoutStyle>
			<ShoppingCartSidebar />
			<Router history={history}>
				<Switch>
					<Route path={`${url}/checkout`} component={Checkout} />
					<Route path={`${url}`} component={EcommerceRoutes} />
				</Switch>
			</Router>
		</EcLayoutStyle>
	);
};

export default NavbarWithRoutes;
