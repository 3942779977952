import React, { SVGProps } from 'react';

import { AmakhaLogo, AmakhaLogoAdmin, AmakhaCrown, Amk } from './imagesvg';

interface ImageSvgProps extends SVGProps<SVGSVGElement> {
	image: string;
}

type ImageSvgMap = {
	[name: string]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const svgMap: ImageSvgMap = {
	amakha: AmakhaLogo,
	'amakha-crown': AmakhaCrown,
	'amakha-admin': AmakhaLogoAdmin,
	amk: Amk,
};

const ImageSvg: React.FC<ImageSvgProps> = React.memo(
	({ width, height, image, ...rest }) => {
		const Svg = svgMap[image];

		if (!Svg) {
			return <></>;
		}

		return (
			<Svg
				aria-hidden="true"
				focusable="false"
				width={width}
				role="img"
				height={height}
				{...rest}
			/>
		);
	}
);

ImageSvg.displayName = 'ImageSvg';
ImageSvg.defaultProps = {
	fill: '#000',
};
ImageSvg.whyDidYouRender = true;

export default ImageSvg;
