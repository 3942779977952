import React from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { AsyncSelect } from 'components/Formik';
import executiveService from 'services/executive.service';
import { ExecutiveForSelect, ExecutiveLightForSelect } from 'types';

import asyncSelectQueries from 'packages/admin/utils/AsyncSelectQueries';

export type ExecutiveSelectFilterProps = {
	name: string;
	queryKey: keyof ExecutiveForSelect;
};

const ExecutiveSelectFilter: React.FC<ExecutiveSelectFilterProps> = ({
	name,
	queryKey,
}) => {
	const { onParamChange } = useDatagridContext();
	const onChange = (value: unknown) => {
		onParamChange(name, (value as ExecutiveForSelect)[queryKey] || '');
	};
	return (
		<AsyncSelect
			name={name}
			fetchMethod={executiveService.getForSelect}
			fetchQuery={asyncSelectQueries.executiveQuery}
			fetchSortByKey="idPerson"
			formatOptionLabel={(value: ExecutiveLightForSelect) => {
				return (
					<>
						<b>{value.id}</b> {value.name}
					</>
				);
			}}
			onChange={onChange}
		/>
	);
};

export default ExecutiveSelectFilter;
