import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';
import { ArrayQueryKey, useQuery } from 'react-query';

import { faUsers, faStar, faBookmark } from '@fortawesome/pro-light-svg-icons';

import { InfoCard } from 'components';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { ExecutiveNumbers } from 'types';
import { numberFormatter } from 'utils/formatters';

const ExecutiveCards: React.FC = () => {
	const [toast] = useToast();

	const { data, isLoading } = useQuery<ExecutiveNumbers, ArrayQueryKey>(
		['executive_numbers'],
		() => executiveService.getExecutiveNumbers(),
		{
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	return (
		<Row>
			<Col md={4}>
				<InfoCard color="blue" icon={faUsers}>
					<InfoCard.Title>
						{isLoading ? (
							<Skeleton width="50%" />
						) : (
							numberFormatter.format(data?.totalRegister || '0')
						)}
					</InfoCard.Title>
					<InfoCard.Subtitle>Total Cadastrados</InfoCard.Subtitle>
				</InfoCard>
			</Col>
			<Col md={4}>
				<InfoCard color="green" icon={faStar}>
					<InfoCard.Title>
						{isLoading ? (
							<Skeleton width="50%" />
						) : (
							numberFormatter.format(data?.totalActive || '0')
						)}
					</InfoCard.Title>
					<InfoCard.Subtitle>Total de Ativos</InfoCard.Subtitle>
				</InfoCard>
			</Col>
			<Col md={4}>
				<InfoCard color="yellow" icon={faBookmark}>
					<InfoCard.Title>
						{isLoading ? (
							<Skeleton width="50%" />
						) : (
							numberFormatter.format(
								Number(data?.totalRegister) - Number(data?.totalActive) || '0'
							)
						)}
					</InfoCard.Title>
					<InfoCard.Subtitle>Total em Aprovação</InfoCard.Subtitle>
				</InfoCard>
			</Col>
		</Row>
	);
};

export default ExecutiveCards;
