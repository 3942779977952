import React from 'react';

import Container from 'react-bootstrap/Container';

import Card from 'components/Card';
import Grid from 'components/Grid';
import Skeleton from 'components/Skeleton';

const SkeletonVideosGrid: React.FC = () => (
	<Container fluid>
		<Grid>
			{[...Array(10).keys()].map((key) => (
				<Card key={key}>
					<Card.ImageWrapper>
						<Skeleton height="140px" margin="0" inverted />
					</Card.ImageWrapper>
					<Card.Title>
						<Skeleton height="2rem" inverted />
					</Card.Title>
					<Card.Content>
						<Skeleton height="6rem" inverted />
					</Card.Content>
				</Card>
			))}
		</Grid>
	</Container>
);

export default SkeletonVideosGrid;
