import React, { useEffect } from 'react';

import { useQuery } from 'react-query';

import useSessionContext from 'hooks/useSessionContext';
import myService from 'services/my.service';

const BALANCE_RELOAD_MS = 1000 * 60 * 15;

const ExecutiveBalanceUpdater: React.FC = ({ children }) => {
	const { update } = useSessionContext();

	const { isLoading, data: balance } = useQuery(
		'executive_balance',
		() => myService.getBalance(),
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchInterval: BALANCE_RELOAD_MS,
			staleTime: BALANCE_RELOAD_MS,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onError: () => {},
		}
	);

	useEffect(() => {
		if (!isLoading && balance !== undefined) {
			update({ executive: { balance } });
		}
	}, [update, isLoading, balance]);

	return <>{children}</>;
};

export default ExecutiveBalanceUpdater;
