/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import BsNavbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { faShoppingCart, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { ImageSvg, Badge } from 'components';
import faAmk from 'components/FontAwesomeIcons/faAmk';
import useSessionContext from 'hooks/useSessionContext';
import { decimalFormatter } from 'utils/formatters';

import { useShoppingCartTotalItems, useSetShoppingCartVisible } from '../hooks';

import SearchInput from './navbar/SearchInput';
import NavbarSkeleton from './navbar/Skeleton';

const amkFormatter = decimalFormatter(2);

type NavbarComponent = React.FC & {
	Skeleton: typeof NavbarSkeleton;
};

const Navbar: NavbarComponent = () => {
	const {
		auth: { person },
		executive: { balance },
	} = useSessionContext();

	const totalCartItems = useShoppingCartTotalItems();
	const setCartSidebarVisible = useSetShoppingCartVisible();

	const firstName = person?.name.split(' ')[0];
	return (
		<BsNavbar bg="white" variant="light" className="py-3">
			<Container fluid>
				<Col lg={4} md={4}>
					<SearchInput />
				</Col>
				<Col lg={2} md={2} className="text-center">
					<Link to="/loja">
						<ImageSvg height={60} image="amakha" />
					</Link>
				</Col>
				<Col lg={4} md={4}>
					<Nav className="justify-content-end">
						<Nav.Item>
							<Nav.Link
								as={Button}
								variant="link"
								className="pr-0"
								onClick={setCartSidebarVisible}
							>
								<big>
									<FAIcon
										icon={faShoppingCart}
										size="lg"
										className="text-primary"
									/>
								</big>
								<Badge variant="danger" pill>
									{totalCartItems}
								</Badge>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link>
								<big>
									<FAIcon icon={faAmk} size="lg" />{' '}
								</big>
								{balance ? amkFormatter.format(balance) : '-,--'}
							</Nav.Link>
						</Nav.Item>
						<NavDropdown
							id="nav-user-dropdown"
							title={
								<>
									<big>
										<FAIcon icon={faUserCircle} size="lg" />{' '}
									</big>
									{firstName}
								</>
							}
						>
							<NavDropdown.Item as={Link} to="/logout">
								Sair
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Col>
			</Container>
		</BsNavbar>
	);
};

Navbar.Skeleton = NavbarSkeleton;

Navbar.displayName = 'Navbar';
Navbar.whyDidYouRender = true;

export default Navbar;
