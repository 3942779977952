import React from 'react';

import { Container, Button } from 'react-bootstrap';
import Carousel, { consts } from 'react-elastic-carousel';

import { faArrowRight, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { EcProductDetails } from 'types';

import ProductCard from 'packages/ecommerce/components/ProductCard';

import { CarouselStyle } from './productsCarousel/styles';

export interface ProductsCarouselProps {
	products: EcProductDetails[];
	fluid?: boolean;
	title: string;
	itemPadding?: number[];
	breakPoints?: { width: number; itemsToShow: number }[];
}

const defaultProps = {
	fluid: false,
	itemPadding: [0, 10, 0, 10],
	breakPoints: [
		{ width: 1, itemsToShow: 1 },
		{ width: 517, itemsToShow: 2 },
		{ width: 678, itemsToShow: 3 },
	],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Arrows = ({ type, onClick }: any) => (
	<Button variant="secondary" onClick={onClick} className="btn-arrow">
		<FAIcon icon={type === consts.PREV ? faArrowLeft : faArrowRight} />
	</Button>
);

const ProductsCarousel: React.FC<ProductsCarouselProps> = (props) => {
	const { products, fluid, title, itemPadding, breakPoints } = props;

	return (
		<Container fluid={fluid}>
			<CarouselStyle>
				<h3>{title}</h3>

				<Carousel
					itemsToShow={3}
					itemPadding={itemPadding}
					pagination={false}
					renderArrow={Arrows}
					breakPoints={breakPoints}
				>
					{products.map((product) => (
						<ProductCard.Card key={product.idProduct} product={product} />
					))}
				</Carousel>
			</CarouselStyle>
		</Container>
	);
};

ProductsCarousel.displayName = 'ProductsCarousel';
ProductsCarousel.defaultProps = defaultProps;
ProductsCarousel.whyDidYouRender = true;

export default ProductsCarousel;
