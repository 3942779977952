/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Spinner from 'react-bootstrap/Spinner';

import { faGem } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { AsyncSelect, Button, Card, Input, Select } from 'components';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import scoreService from 'services/score.service';
import {
	ExecutiveLightForSelect,
	HttpGetListParams,
	ScoreLineTree,
	ScoreTotal,
} from 'types';
import { numberFormatter, currencyFormatter } from 'utils/formatters';

import {
	PageLayout,
	PanelTabGroup,
	ListTreeScore,
} from 'packages/admin/components';

const monthOptionsBR = [
	{ name: 'Janeiro', value: 1 },
	{ name: 'Fevereiro', value: 2 },
	{ name: 'Março', value: 3 },
	{ name: 'Abril', value: 4 },
	{ name: 'Maio', value: 5 },
	{ name: 'Junho', value: 6 },
	{ name: 'Julho', value: 7 },
	{ name: 'Agosto', value: 8 },
	{ name: 'Setembro', value: 9 },
	{ name: 'Outubro', value: 10 },
	{ name: 'Novembro', value: 11 },
	{ name: 'Dezembro', value: 12 },
];

const levels = [
	{ scoreLimit: 0, scoreMax: 0, title: 'Executivo' },
	{ scoreLimit: 700, scoreMax: 2000, title: 'Sênior' },
	{ scoreLimit: 2500, scoreMax: 7500, title: 'Ouro' },
	{ scoreLimit: 4500, scoreMax: 12500, title: 'Safira' },
	{ scoreLimit: 7000, scoreMax: 20000, title: 'Esmeralda' },
	{ scoreLimit: 10000, scoreMax: 30000, title: 'Diamante' },
	{ scoreLimit: 25000, scoreMax: 100000, title: 'Diamante Elite' },
	{ scoreLimit: 75000, scoreMax: 300000, title: 'Royal' },
	{ scoreLimit: 200000, scoreMax: 1000000, title: 'Imperial Black' },
];

const ScoreByNetwork: React.FC = () => {
	const [toast] = useToast();
	const [executive, setExecutive] = useState<ExecutiveLightForSelect | null>(
		null
	);
	const [month, setMonth] = useState<typeof monthOptionsBR[0]>(
		monthOptionsBR[new Date().getMonth()]
	);
	const [year, setYear] = useState<number>(new Date().getFullYear());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [scoreTotal, setScoreTotal] = useState<ScoreTotal | null>(null);
	const [scoreCache, setScoreCache] = useState<ScoreLineTree[]>([]);
	const [scoreLimit, setScoreLimit] = useState<number>(0);
	const [tabSelected, setTabSelected] = useState<ScoreLineTree | null>(null);

	const getLines = useCallback(
		async (params: HttpGetListParams) => {
			try {
				const { data } = await scoreService.getScoreLineByUser(params);
				if (data.length) {
					const i = data.findIndex((u) => u.idPerson === params.params?.id);
					const self = data.splice(i, 1)[0];
				}
				return data.map((node, index) => {
					return {
						...node,
						haveChildren: true,
						expanded: false,
						isLoading: false,
						level: 0,
						__cache: [],
						__cacheParent: [],
						isLast: data.length === index + 1,
					};
				});
			} catch (e) {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
				return [];
			}
		},
		[toast]
	);

	const removeAfter = useCallback(
		(
			node: ScoreLineTree,
			nodeParent: ScoreLineTree,
			lines: ScoreLineTree[]
		) => {
			const firstNode = lines.indexOf(node) + 1;
			let lastNode =
				firstNode +
				lines.slice(firstNode).findIndex((n) => {
					return n.level <= node.level;
				});

			if (lastNode < firstNode) {
				lastNode = lines.length;
			}

			const items = [...lines];
			const spliced = lines.splice(firstNode, lastNode - firstNode);
			items.splice(firstNode, lastNode - firstNode);

			const tree = items.map((u) => {
				if (u.idPerson === node.idPerson) {
					return { ...u, expanded: false, __cache: spliced };
				}
				return u;
			});

			setScoreCache((old) =>
				old.map((n) => {
					if (n.idPerson === nodeParent.idPerson) {
						const expanded =
							nodeParent.idPerson === node.idPerson ? false : n.expanded;
						const cacheParent =
							nodeParent.idPerson === node.idPerson ? spliced : [];
						return {
							...n,
							expanded,
							__cache: tree,
							__cacheParent: cacheParent,
						};
					}
					return n;
				})
			);
		},
		[]
	);

	const appendAfter = useCallback(
		(
			node: ScoreLineTree,
			nodeParent: ScoreLineTree,
			lines: ScoreLineTree[],
			newLines: ScoreLineTree[]
		) => {
			const firstNode = lines.indexOf(node) + 1;

			const tree = [
				...lines.slice(0, firstNode).map((u) => {
					if (u.idPerson === node.idPerson) {
						return {
							...u,
							expanded: true,
							isLoading: false,
							__cache: [...newLines],
						};
					}
					return u;
				}),
				...newLines,
				...lines.slice(firstNode),
			];

			setScoreCache((old) =>
				old.map((n) => {
					if (n.idPerson === nodeParent.idPerson) {
						return {
							...n,
							expanded: true,
							isLoading: false,
							__cache: [...tree],
						};
					}
					return n;
				})
			);
		},
		[]
	);

	const setParams = useCallback(
		(id: number) => {
			const params: HttpGetListParams = {
				params: {
					id,
					month: month.value,
					year,
				},
			};
			return params;
		},
		[month.value, year]
	);

	const handleTab = useCallback((node) => {
		setTabSelected(node);
	}, []);

	const handleSearch = useCallback(async () => {
		setScoreTotal(null);
		setIsLoading(true);
		setScoreCache([]);
		setTabSelected(null);

		const params = setParams(executive!.idPerson);

		try {
			const total = await scoreService.getScoreTotalByUser(params);
			const data = await getLines(params);

			setScoreTotal(total as ScoreTotal);
			setScoreCache(data);
			setTabSelected(data[0]);

			setScoreLimit(levels[0].scoreMax);
			const totalScore = Number((total as ScoreTotal).value);

			levels.forEach((l, index) => {
				if (totalScore >= l.scoreMax) {
					setScoreLimit(
						levels[Math.min(index + 1, levels.length - 1)].scoreLimit
					);
				}
			});
		} catch (e) {
			toast('Não foi possível carregar dados', {
				type: 'error',
			});
			setScoreCache([]);
			setScoreTotal(null);
		}
		setIsLoading(false);
	}, [executive, getLines, setParams, toast]);

	const handleExpand = useCallback(
		async (node: ScoreLineTree, index: number) => {
			const nodeParent =
				scoreCache.find((n) => n.idPerson === tabSelected?.idPerson) ||
				tabSelected!;
			const nodeParentCache = nodeParent
				? [...nodeParent.__cache]
				: [tabSelected!];

			if (node.expanded) {
				removeAfter(node, nodeParent, nodeParentCache);
			} else {
				const cacheParent =
					node.idPerson === nodeParent.idPerson
						? nodeParent.__cacheParent
						: node.__cache;
				if (cacheParent.length === 0) {
					setScoreCache((old) =>
						old.map((n) => {
							if (n.idPerson === nodeParent.idPerson) {
								return {
									...n,
									isLoading: n.isLoading || n.idPerson === node.idPerson,
									__cache: n.__cache.map((u) => {
										return {
											...u,
											isLoading: u.isLoading || u.idPerson === node.idPerson,
										};
									}),
								};
							}
							return n;
						})
					);

					const params = setParams(node.idPerson);
					const data = await getLines(params);

					const lines: ScoreLineTree[] = data.map((item) => ({
						...item,
						level: node.level + 1,
						expanded: false,
						haveChildren: true,
					}));

					if (lines.length === 0) {
						setScoreCache((old) =>
							old.map((n) => {
								if (n.idPerson === nodeParent.idPerson) {
									return {
										...n,
										isLoading: false,
										haveChildren:
											n.haveChildren && n.idPerson !== node.idPerson,
										__cache: n.__cache.map((u) => {
											const haveChildren =
												u.haveChildren && u.idPerson !== node.idPerson;
											return { ...u, isLoading: false, haveChildren };
										}),
									};
								}
								return n;
							})
						);
						return;
					}

					appendAfter(node, nodeParent, nodeParentCache, lines);
					return;
				}
				appendAfter(node, nodeParent, nodeParentCache, cacheParent);
			}
		},
		[appendAfter, getLines, removeAfter, scoreCache, setParams, tabSelected]
	);

	const getTabSelectedCache = useCallback(() => {
		if (tabSelected && scoreCache) {
			const nodeParent = scoreCache.find(
				(n) => n.idPerson === tabSelected?.idPerson
			);
			return nodeParent ? [nodeParent, ...nodeParent.__cache] : [];
		}
		return [];
	}, [scoreCache, tabSelected]);

	return (
		<PageLayout
			title="Pontuação da Rede"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/summary' },
				{ label: 'Pontuação da Rede', active: true },
			]}
		>
			<Card>
				<Card.Content>
					<Form.Row>
						<FormGroup as={Col} lg={3} md={4} sm={4} xs={4}>
							<Form.Label>Executivo</Form.Label>
							<AsyncSelect
								fetchMethod={executiveService.getForSelect}
								fetchSortByKey="idPerson"
								formatOptionLabel={(value: ExecutiveLightForSelect) => {
									return (
										<>
											<b>{value.id}</b> {value.name}
										</>
									);
								}}
								value={executive}
								onChange={(value) => setExecutive(value)}
								isDisabled={isLoading}
							/>
						</FormGroup>
						<FormGroup as={Col} lg={2} md={3} sm={4} xs={4}>
							<Form.Label>Mês</Form.Label>
							<Select
								options={monthOptionsBR}
								value={month}
								onChange={(value) => setMonth(value)}
								isDisabled={isLoading}
							/>
						</FormGroup>
						<FormGroup as={Col} lg={1} md={2} sm={3} xs={3}>
							<Form.Label>Ano</Form.Label>
							<Input
								formatter={numberFormatter}
								value={year}
								onChange={(value) => setYear(Number(value))}
								disabled={isLoading}
							/>
						</FormGroup>
						<FormGroup as={Col}>
							<br />
							<Button
								variant="primary"
								className="m-1 mr-3"
								disabled={!executive || !month || !year || isLoading}
								onClick={handleSearch}
							>
								{isLoading ? (
									<>
										<Spinner size="sm" animation="border" role="status" />
										<span className="ml-2">Pesquisando...</span>
									</>
								) : (
									<span>Pesquisar</span>
								)}
							</Button>
						</FormGroup>

						{scoreTotal && (
							<>
								<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
									<br />
									<div className="mt-2 d-flex align-items-center">
										<FAIcon icon={faGem} className="mr-2 text-primary" />
										{scoreTotal.career}
									</div>
								</FormGroup>
								<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
									<br />
									<div className="mt-2 d-flex align-items-center">
										<FAIcon icon="star" className="mr-2 text-yellow" />
										<b>{currencyFormatter.format(scoreTotal.value)}</b>
									</div>
								</FormGroup>
							</>
						)}
					</Form.Row>
				</Card.Content>
			</Card>

			{scoreCache.length > 0 && (
				<>
					<Card style={{ marginBottom: 0 }}>
						<PanelTabGroup
							lines={scoreCache}
							scoreLimit={scoreLimit}
							tabSelected={tabSelected}
							onTabSelected={(u) => handleTab(u)}
						/>
					</Card>

					<Card>
						<Card.Content>
							<ListTreeScore
								lines={getTabSelectedCache()}
								onExpand={handleExpand}
							/>
						</Card.Content>
					</Card>
				</>
			)}
		</PageLayout>
	);
};

export default ScoreByNetwork;
