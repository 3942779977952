import React, { useEffect } from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';

const DistributorCreditOrdersFilters: React.FC = () => {
	const { onQueryChange } = useDatagridContext();

	useEffect(() => {
		onQueryChange('idType', '7');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default DistributorCreditOrdersFilters;
