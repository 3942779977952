import React from 'react';

import { Spinner } from 'react-bootstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components';
import { ScoreLineTree } from 'types';
import { currencyFormatter } from 'utils/formatters';

import * as S from './listTreeScore/styles';

type ListTreeScoreProps = {
	lines: ScoreLineTree[];
	isSuperBonus?: boolean;
	iconFolderClose?: IconProp;
	iconFolderOpen?: IconProp;
	onExpand: (user: ScoreLineTree, index: number) => void;
};

const ListTreeScore: React.FC<ListTreeScoreProps> = ({
	lines,
	isSuperBonus = false,
	iconFolderClose = 'angle-right',
	iconFolderOpen = 'angle-down',
	onExpand,
}) => {
	return (
		<S.List>
			{lines.map((user, index) => (
				<S.ListItem key={user.idPerson} level={user.level} isLast={user.isLast}>
					{user.level > 1 &&
						[...Array(user.level - 1).keys()].map((lv) => (
							<span
								key={String(lv)}
								className="line"
								style={{ left: `-${(lv + 1) * 2}.9rem` }}
							/>
						))}
					{user.haveChildren && (
						<Button
							variant="secondary"
							size="sm"
							onClick={() => onExpand(user, index)}
							disabled={user.isLoading}
							style={{
								padding: user.isLoading
									? '0.25rem 0.28rem 0.3rem'
									: '0.25rem 0.5rem',
							}}
						>
							{user.isLoading ? (
								<Spinner size="sm" animation="border" role="status" />
							) : (
								<>
									{user.expanded ? (
										<FAIcon icon={iconFolderOpen} size="lg" />
									) : (
										<FAIcon icon={iconFolderClose} size="lg" />
									)}
								</>
							)}
						</Button>
					)}

					{isSuperBonus ? (
						<S.TextWrapper>
							<S.Codigo>{user.id}</S.Codigo>
							<span>{user.name}</span>
							<FAIcon icon="star" />
							<span style={{ padding: '1px 4px' }}>
								{user.status} {user.statusDate}
							</span>
						</S.TextWrapper>
					) : (
						<S.TextWrapper>
							<S.Codigo>{user.id}</S.Codigo>
							<span>{user.name}</span>
							<FAIcon icon="star" />
							{Number(user.score) < 0.001 ? (
								<span>{currencyFormatter.format(user.score)}</span>
							) : (
								<strong>{currencyFormatter.format(user.score)}</strong>
							)}
						</S.TextWrapper>
					)}
				</S.ListItem>
			))}
		</S.List>
	);
};

export default ListTreeScore;
