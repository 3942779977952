import React from 'react';

import { Video } from 'components';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';

type VideoModalProps = {
	link: string;
	title: string;
};

const VideoModal: React.FC<ModalForwardProps<VideoModalProps, void>> = ({
	link,
	title,
	modalRef,
	...rest
}) => {
	if (!modalRef) return null;

	return (
		<Modal {...rest} size="lg">
			<Video src={link} title={title} />
		</Modal>
	);
};

export default VideoModal;
