import React from 'react';

import { faBoxOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Container } from './empty/styles';

export interface EmptyProps {
	image?: React.ReactNode;
	imageStyle?: React.CSSProperties;
	description?: React.ReactNode;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const defaultProps = {
	image: <FAIcon icon={faBoxOpen} />,
	description: 'Sem dados',
};

const Empty: React.FC<EmptyProps> = (props) => {
	const { image, imageStyle, description, children, ...rest } = props;

	return (
		<Container {...rest}>
			<div style={imageStyle}>{image}</div>
			{description && <p>{description}</p>}
			{children && <span>{children}</span>}
		</Container>
	);
};

Empty.displayName = 'Empty';
Empty.defaultProps = defaultProps;
Empty.whyDidYouRender = true;

export default Empty;
