import React from 'react';

import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

import { faTrashAlt, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { PersonAddress } from 'types';
import { cepFormatter } from 'utils/formatters';

import { AddressTitle, AddressText } from 'packages/ecommerce/components';

export type AddressSummaryProps = {
	className?: string;
	isLoading: boolean;
	address?: PersonAddress;
	showEdit?: boolean;
	showDelete?: boolean;
	onAddressDelete?: () => void;
	onAddressEdit?: () => void;
};

const AddressSummary: React.FC<AddressSummaryProps> = ({
	className,
	isLoading,
	address,
	showEdit,
	showDelete,
	onAddressDelete,
	onAddressEdit,
}) => {
	const showOptions = showEdit || showDelete;
	return (
		<>
			{isLoading && (
				<div>
					<Skeleton height="1rem" />
					<Skeleton height="1rem" />
					<Skeleton height="1rem" />
				</div>
			)}
			{!isLoading && address && (
				<Row className={classNames(className, 'align-items-center')}>
					<Col>
						<AddressTitle className="mr-2">{address.name}</AddressTitle>
						<AddressText className="d-block">
							{address.streetName}
							{address.number && `, ${address.number}`}
							{address.complement && ` - ${address.complement}`}
						</AddressText>
						<AddressText className="d-block">
							CEP {cepFormatter.format(address.cep)} - {address.city!.name}/
							{address.state!.uf}
						</AddressText>
					</Col>
					<Col className="col-auto">
						{showOptions && (
							<>
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										size="sm"
										id="dropdown-options"
									>
										Opções
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{showEdit && (
											<Dropdown.Item onClick={onAddressEdit}>
												<FAIcon icon={faEdit} className="text-muted mr-2" />{' '}
												Editar
											</Dropdown.Item>
										)}
										{showDelete && (
											<Dropdown.Item onClick={onAddressDelete}>
												<FAIcon icon={faTrashAlt} className="text-muted mr-2" />{' '}
												Remover
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</>
						)}
					</Col>
				</Row>
			)}
		</>
	);
};

AddressSummary.displayName = 'AddressSummary';
AddressSummary.whyDidYouRender = true;
AddressSummary.defaultProps = {
	showEdit: true,
	showDelete: true,
	onAddressEdit: () => {},
	onAddressDelete: () => {},
};

export default AddressSummary;
