import { Reducer } from 'react';

import { HttpBaseResult } from 'types';

import {
	EcommerceState,
	EcommerceActionTypes,
	EcommerceActionEnum,
} from './types';

export const initialState: EcommerceState = {
	config: {
		idStore: 0,
		idCompany: 0,
		idType: 0,
		lang: { idLang: 1, slug: 'pt-BR' },
		menu: [],
		banner: [],
		dropdown: [],
		feature: [],
		expedition: [],
		personType: [],
	},
	isCartVisible: false,
	isLoading: true,
	error: null,
};

const EcommerceReducer: Reducer<EcommerceState, EcommerceActionTypes> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case EcommerceActionEnum.StoreRequest:
			return {
				...initialState,
				isLoading: true,
			};
		case EcommerceActionEnum.StoreSuccess:
			return {
				config: { ...action.payload.config },
				error: null,
				isLoading: false,
				isCartVisible: false,
			};
		case EcommerceActionEnum.StoreFailed:
			return {
				...initialState,
				error: action.payload as HttpBaseResult,
			};
		case EcommerceActionEnum.HideShoppingCart:
			return {
				...state,
				isCartVisible: false,
			};
		case EcommerceActionEnum.ShowShoppingCart:
			return {
				...state,
				isCartVisible: true,
			};
		default:
			return state;
	}
};

export default EcommerceReducer;
