import React from 'react';

import classNames from 'classnames';

import Breadcrumb from './pageHeader/Breadcrumb';
import Title from './pageHeader/Title';

type PageHeaderComponent = React.FC<{
	className?: string;
	alignItems?: 'baseline' | 'center' | 'start' | 'end';
}> & {
	Title: typeof Title;
	Breadcrumb: typeof Breadcrumb;
};

const PageHeader: PageHeaderComponent = ({
	className,
	alignItems,
	...props
}) => (
	<div
		className={classNames(
			'mx-1 my-3 d-flex flex-row',
			alignItems && `align-items-${alignItems}`,
			className
		)}
		{...props}
	/>
);

PageHeader.defaultProps = {
	alignItems: 'baseline',
};

PageHeader.Title = Title;
PageHeader.Breadcrumb = Breadcrumb;

PageHeader.displayName = 'PageHeader';
PageHeader.whyDidYouRender = true;

export default PageHeader;
