import React from 'react';

import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { EcCategory } from 'types';
import { FlatArrayToTree } from 'utils';

import {
	CategorySidebarTitle,
	CategorySidebarLink,
	CategorySidebarLinkIcon,
	CategorySidebarTitleLink,
} from 'packages/ecommerce/components';
import useEcommerceBasePath from 'packages/ecommerce/hooks/useEcommerceBasePath';

export type CategorySidebaProps = {
	categories: EcCategory[];
};

const CategorySidebar: React.FC<CategorySidebaProps> = ({
	categories: categoryList,
}) => {
	const baseUrl = useEcommerceBasePath();

	if (categoryList.length < 2) {
		return <></>;
	}
	const levels = Math.max(...categoryList.map((cat) => Number(cat.level)));
	let categories: EcCategory[] = [];

	if (levels === 1) {
		categories = categoryList.filter((cat) => Number(cat.level) === 1);
	} else if (levels > 1) {
		categories =
			FlatArrayToTree<EcCategory>(
				categoryList,
				'idCategory',
				'idParent',
				'children'
			)?.[0]?.children || [];
	}

	if (levels === 0) {
		return <></>;
	}

	return (
		<>
			{levels === 1 ? (
				<>
					<div className="border-bottom pb-3 mb-4">
						<CategorySidebarTitle>Categorias</CategorySidebarTitle>
						{categories &&
							categories.map((sub) => (
								<CategorySidebarLink
									key={sub.idCategory}
									to={`${baseUrl}/categoria/${sub.idCategory}`}
								>
									{sub.shortName}
									<CategorySidebarLinkIcon
										icon={faChevronRight}
										size="sm"
										className="ml-auto"
									/>
								</CategorySidebarLink>
							))}
					</div>
				</>
			) : (
				categories.map((cat) => (
					<div className="border-bottom pb-3 mb-4" key={cat.idCategory}>
						<CategorySidebarTitleLink
							to={`${baseUrl}/categoria/${cat.idCategory}`}
						>
							{cat.shortName}
						</CategorySidebarTitleLink>
						{cat.children &&
							cat.children.map((sub) => (
								<CategorySidebarLink
									key={sub.idCategory}
									to={`${baseUrl}/categoria/${sub.idCategory}`}
								>
									{sub.shortName}
									<CategorySidebarLinkIcon
										icon={faChevronRight}
										size="sm"
										className="ml-auto"
									/>
								</CategorySidebarLink>
							))}
					</div>
				))
			)}
		</>
	);
};

export default CategorySidebar;
