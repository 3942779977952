import React, { useCallback, useState } from 'react';

import { Spinner } from 'react-bootstrap';
import Button, { ButtonProps } from 'react-bootstrap/Button';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import { useToast } from 'hooks';
import { HttpBaseResult, HttpGetListParams, QueryOperator } from 'types';
import { history } from 'utils';
import { dateFormatter } from 'utils/formatters';

export interface ExportButtonProps extends ButtonProps {
	to: 'csv';
	title: string;
	columns: string[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action?: any;
	filterData?: string;
	exportOnlyScreenData?: boolean;
}

type ExportProps = Omit<ExportButtonProps, 'variant' | 'type'>;

const ExportButton: React.FC<ExportProps> = ({
	title,
	to,
	action,
	columns,
	filterData,
	exportOnlyScreenData,
	children,
	...rest
}) => {
	const [toast] = useToast();
	const { config, tableParams } = useDatagridContext();
	const { data } = useDatagridDataContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const downloadFileCsv = useCallback((fileName, exportData) => {
		const utf8BOM = '\uFEFF';
		// eslint-disable-next-line no-param-reassign
		exportData = utf8BOM + exportData;

		const blob = new Blob([exportData], { type: 'text/csv;charset=utf-8;' });
		const a = document.createElement('a');

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, fileName);
		} else if (URL && 'download' in a) {
			a.href = URL.createObjectURL(blob);
			a.setAttribute('download', fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			history.push(
				`data:application/octet-stream,${encodeURIComponent(exportData)}`
			);
		}
		setIsLoading(false);
	}, []);

	const onExport = useCallback(async () => {
		setIsLoading(true);

		try {
			let dataRows;

			if (action) {
				const prependQuery = (op?: QueryOperator) => (op !== '~' ? '' : '*');
				const appendQuery = prependQuery;

				const params: HttpGetListParams = {
					pagination: {
						...(exportOnlyScreenData && {
							paginate: tableParams.rowsPerPage,
							page: tableParams.page,
						}),
						...(tableParams.sortByKey && {
							sort: tableParams.sortByDesc
								? `-${tableParams.sortByKey}`
								: tableParams.sortByKey,
						}),
					},
					params: tableParams.params,
					...(tableParams.filters.length && {
						query: tableParams.filters.map((f) => {
							return `"${f.operator ?? '~'}${f.key}": "${prependQuery(
								f.operator
							)}${f.query}${appendQuery(f.operator)}"`;
						}),
					}),
				};

				const result = await action(params);
				dataRows = result.data || [];
			} else {
				dataRows = data;
			}

			if (!dataRows) {
				setIsLoading(false);
				return;
			}

			let exportData = `${title}${filterData ? ` - ${filterData}` : ''}\n`;

			columns.forEach((col) => {
				exportData += `${col};`;
			});
			exportData += '\n';

			dataRows.forEach((row: Record<string, string>) => {
				columns.forEach((col) => {
					const colConfig = config.columns.find((c) => c.title === col);

					if (colConfig && colConfig.key) {
						if (colConfig.formatter) {
							let colValue = '';

							const formatted = colConfig.formatter.format(row[colConfig.key]);
							if (typeof formatted === 'string') {
								colValue = formatted;
							} else {
								colValue = row[colConfig.key];
							}

							exportData += `${colValue};`;
						} else {
							exportData += `${row[colConfig.key]};`;
						}
					}
				});
				exportData += '\n';
			});

			const fileName = `${title} ${dateFormatter
				.format(new Date())
				.toString()
				.replace(/(\/|:|\s)/g, '')}.${to}`;
			downloadFileCsv(fileName, exportData);
		} catch (e) {
			const { code } = e as HttpBaseResult;
			toast(`Ocorreu um erro desconhecido ao exportar os dados. Cód: ${code}`, {
				type: 'error',
			});
		}

		setIsLoading(false);
	}, [
		action,
		columns,
		config.columns,
		data,
		downloadFileCsv,
		filterData,
		tableParams,
		title,
		to,
		toast,
	]);

	return (
		<Button
			{...rest}
			variant="outline-primary"
			type="button"
			disabled={!data || isLoading}
			onClick={() => onExport()}
		>
			{isLoading ? (
				<>
					<Spinner size="sm" animation="border" role="status" />
					<span className="ml-2">Exportando...</span>
				</>
			) : (
				<>{children}</>
			)}
		</Button>
	);
};

export default ExportButton;
