/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationModal } from 'modals';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { useModal, useToast } from 'hooks';
import systemUserService from 'services/system-user.service';
import { AuthACLRole, HttpBaseResult } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

const DeleteProfileButton: React.FC<ButtonLinkComponentProps<AuthACLRole>> = ({
	row,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [toast] = useToast();
	const { forceUpdate } = useDatagridContext();
	const open = useModal();

	const handleDelete = useCallback(
		async () =>
			new Promise<void>((resolve) => {
				setIsLoading(true);
				open(
					ConfirmationModal,
					{
						ButtonConfirmText: 'Remover',
						message: (
							<p className="mb-0">
								Tem certeza que deseja remover o registro{' '}
								<strong>{row.name}</strong>?
							</p>
						),
					},
					async (value?: unknown) => {
						if (!value) {
							setIsLoading(false);
							return resolve();
						}

						try {
							await systemUserService.removeProfile(row.idRole!);
							forceUpdate();
						} catch (e) {
							const { message } = e as HttpBaseResult;
							console.log('Error message: ', message);
							toast(
								'O Perfil está sendo utilizado, não é possível excluí-lo.',
								{
									type: 'error',
								}
							);
						}

						setIsLoading(false);
						return resolve();
					}
				);
			}),
		[forceUpdate, open, row.idRole, row.name, toast]
	);

	return (
		<>
			{!row.blocked && (
				<Button
					variant="link"
					className="m-0 text-danger"
					onClick={handleDelete}
					disabled={isLoading}
				>
					{isLoading ? (
						<Spinner size="sm" animation="border" role="status" />
					) : (
						<FAIcon icon={faTrashAlt} />
					)}
				</Button>
			)}
		</>
	);
};

export default DeleteProfileButton;
