import React from 'react';

import styled, { keyframes } from 'styled-components';

export type LoadingProgressBarProps = {
	height?: string | number;
	width?: string | number;
	variant?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'info'
		| 'dark'
		| 'light';
	className?: string;
};

const KeyframesLong = keyframes`
0% {
	left: -35%;
	right: 100%;
}
100%,
60% {
	left: 100%;
	right: -90%;
}
`;

const KeyframesShort = keyframes`
0% {
	left: -200%;
	right: 100%;
}
100%,
60% {
	left: 107%;
	right: -8%;
}
`;

const ProgressBarContainer = styled.div.attrs(
	(props: LoadingProgressBarProps) => props
)`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	background-color: var(--gray-20);
	overflow: hidden;
	display: flex;
	align-items: center;
	position: relative;
`;

const ProgressBarAnimation = styled.div.attrs(
	(props: { variant: string }) => props
)`
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: transparent;
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: var(${(props) => `--${props.variant}`});
		will-change: left, right;
	}
	&::before {
		animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite
			${KeyframesLong};
	}
	&::after {
		animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite
			${KeyframesShort};
		animation-delay: 1.2s;
	}
`;

const LoadingProgressBar: React.FC<LoadingProgressBarProps> = ({
	width,
	height,
	variant,
	className,
}) => (
	<ProgressBarContainer width={width} height={height} className={className}>
		<ProgressBarAnimation variant={variant} />
	</ProgressBarContainer>
);

LoadingProgressBar.displayName = 'LoadingProgressBar';
LoadingProgressBar.whyDidYouRender = true;
LoadingProgressBar.defaultProps = {
	width: '100%',
	height: '5px',
	variant: 'info',
};

export default LoadingProgressBar;
