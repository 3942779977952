import React from 'react';

import classNames from 'classnames';

type FormGroupTitleProps = React.PropsWithChildren<{
	className?: string | string[];
	marginTop?: boolean;
}>;

const FormGroupTitle: React.FC<FormGroupTitleProps> = ({
	className,
	marginTop,
	children,
}) => (
	<h5 className={classNames(marginTop && 'mt-3', className)}>{children}</h5>
);

export default FormGroupTitle;
