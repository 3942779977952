import { PersonStatus } from 'types';
import { ColoredStatus } from 'utils/Formatter/ColoredStatus';

export const personStatusById: ColoredStatus[] = [
	{
		status: PersonStatus.Active,
		badgeClassName: 'text-success',
		badgeText: 'Ativo',
	},
	{
		status: PersonStatus.Blocked,
		badgeClassName: 'text-danger',
		badgeText: 'Bloqueado',
	},
	{
		status: PersonStatus.Canceled,
		badgeClassName: 'text-dark',
		badgeText: 'Cancelado',
	},
	{
		status: PersonStatus.Validated,
		badgeClassName: 'text-warning',
		badgeText: 'Validado',
	},
	{
		status: PersonStatus.Registered,
		badgeClassName: 'text-primary',
		badgeText: 'Registrado',
	},
];

export const personStatusByName: ColoredStatus[] = [
	{
		status: 'ativo',
		badgeClassName: 'text-success',
	},
	{
		status: 'bloqueado',
		badgeClassName: 'text-danger',
	},
	{
		status: 'cancelado',
		badgeClassName: 'text-dark',
	},
	{
		status: 'validado',
		badgeClassName: 'text-warning',
	},
	{
		status: 'registrado',
		badgeClassName: 'text-primary',
	},
];

export const newsStatusByName: ColoredStatus[] = [
	{
		status: 'ativo',
		badgeClassName: 'text-success',
	},
	{
		status: 'desativado',
		badgeClassName: 'text-danger',
	},
];

export const activeInactiveStatusByName: ColoredStatus[] = [
	{
		status: 'ativo',
		badgeClassName: 'text-success',
	},
	{
		status: 'inativo',
		badgeClassName: 'text-danger',
	},
];

export const activeInactiveStatusByBoolean: ColoredStatus[] = [
	{
		status: 'true',
		badgeClassName: 'text-success',
		badgeText: 'Ativo',
	},
	{
		status: 'false',
		badgeClassName: 'text-danger',
		badgeText: 'Inativo',
	},
];

export const yesNoStatusByBoolean: ColoredStatus[] = [
	{
		status: 'true',
		badgeClassName: 'text-success',
		badgeText: 'Sim',
	},
	{
		status: 'false',
		badgeClassName: 'text-danger',
		badgeText: 'Não',
	},
];

export const campaignStatusByName: ColoredStatus[] = [
	{
		status: 'agendada',
		badgeClassName: 'text-info',
	},
	{
		status: 'ativa',
		badgeClassName: 'text-success',
	},
	{
		status: 'encerrada',
		badgeClassName: 'text-danger',
	},
];

export const financialStatusPaid: ColoredStatus[] = [
	{
		status: 'Em aberto',
		badgeClassName: 'text-info',
	},
	{
		status: 'Pago',
		badgeClassName: 'text-success',
	},
];

export const payoutAlertStatus: ColoredStatus[] = [
	{
		status: 'aprovado',
		badgeClassName: 'text-success',
	},
	{
		status: 'recusado',
		badgeClassName: 'text-danger',
	},
	{
		status: 'aguardando',
		badgeClassName: 'text-muted',
	},
];
