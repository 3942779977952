/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { Card } from 'components';

import { PageHeader } from 'packages/admin/components';

const neoAssist = (): void => {
	//---------------------------------------------------------
	// Integração NeoAssist

	// Remove todos os scripts carregados previamente para forçar
	// o reload do construtor deles e recarregar a div de atendimento
	const scripts: HTMLCollectionOf<HTMLScriptElement> = document.head.getElementsByTagName(
		'script'
	);
	// Remove scripts se existirem no projeto
	for (let i = 0; i < scripts.length; ++i) {
		const script = scripts.item(i);
		if (
			script &&
			(script.src.indexOf('neoassist') >= 0 ||
				script.src.indexOf('cdn.atendimen.to') >= 0)
		) {
			document.head.removeChild(script);
		}
	}

	// Adiciona o script de carga da NeoAssist
	const w = window as any;
	w.NeoAssistTag = {};
	w.NeoAssistTag.querystring = true;
	w.NeoAssistTag.pageid = '';
	w.NeoAssistTag.clientdomain = 'amakhaparis.neoassist.com';
	const na = document.createElement('script');
	na.type = 'text/javascript';
	na.async = true;
	na.src = 'https://cdn.atendimen.to/n.js';
	const s = document.getElementsByTagName('script')[0];
	s.parentNode?.insertBefore(na, s);

	//---------------------------------------------------------
};

const Contact: React.FC = () => {
	useEffect(() => {
		neoAssist();
	}, []);

	return (
		<Container fluid>
			<PageHeader alignItems="center">
				<PageHeader.Title>Canais de Atendimento</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils/distributors' },
						{ label: 'Ser PA', active: true },
					]}
				/>
			</PageHeader>
			<Row>
				<Col sm={12} md={12} lg={12}>
					<Card>
						<Card.Content id="NeoassistCentral">
							<div id="neoassist" />
						</Card.Content>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Contact;
