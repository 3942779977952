import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import * as Yup from 'yup';

import {
	FilterContainer,
	OrderFilter,
	FilterButton,
	CompanyIDParam,
} from 'packages/admin/components/datatableView';

const validationSchema = Yup.object({
	id: Yup.number().nullable().required('Campo obrigatório'),
});

const defaultFilterValues = {
	id: null,
};

const StatementByOrderFilter: React.FC = () => (
	<FilterContainer
		initialValues={defaultFilterValues}
		validationSchema={validationSchema}
	>
		<CompanyIDParam name="company" />
		<Form.Row>
			<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
				<Form.Label>Pedido</Form.Label>
				<OrderFilter name="id" />
			</FormGroup>
			<FormGroup as={Col} className="d-flex align-items-end">
				<FilterButton />
			</FormGroup>
		</Form.Row>
	</FilterContainer>
);

export default StatementByOrderFilter;
