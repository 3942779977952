import React from 'react';

import {
	faAddressCard,
	faBriefcase,
	faBuilding,
} from '@fortawesome/pro-light-svg-icons';
import { faExchange, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faSuitcase } from '@fortawesome/pro-solid-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import ExecutiveEdit from './Executive';
import ExecutivesPage from './Executives';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const ExecutiveRoutes: RouteDef = {
	path: '/admin/executive',
	menu: {
		name: 'Cadastros',
		icon: faUserPlus,
	},
	children: [
		{
			path: '/executives',
			component: ExecutivesPage,
			exact: true,
			menu: {
				name: 'Executivos',
				icon: faSuitcase,
			},
		},
		{
			path: '/executives/:id',
			component: ExecutiveEdit,
			exact: true,
		},
		{
			path: '/distributors',
			render: getListSceneRenderFn(listConfigs.Distributors),
			exact: true,
			menu: {
				name: 'PA Básico',
				icon: faBriefcase,
			},
		},
		{
			path: '/exclusives',
			render: getListSceneRenderFn(listConfigs.Exclusives),
			exact: true,
			menu: {
				name: 'PA Exclusivo',
				icon: faBuilding,
				separator: true,
			},
		},
		{
			path: '/migrations',
			menu: {
				name: 'Migração de PA',
				icon: faCircle,
			},
		},
		{
			path: '/change-sponsor',
			menu: {
				name: 'Alterar Patrocinador',
				icon: faExchange,
			},
		},
		{
			path: '/approval',
			menu: {
				name: 'Aprovação de Cadastro',
				icon: faAddressCard,
			},
		},
	],
};

export default ExecutiveRoutes;
