import { useCallback, useRef, ReactText } from 'react';

import { toast, ToastOptions, ToastContent } from 'react-toastify';

type ContentType = ToastContent;
type Options = ToastOptions;
export type ToastFn = (msg: ContentType, options?: Options) => void;

const useToast = (): [ToastFn, ToastFn] => {
	const toastRef = useRef<ReactText>('');
	const notify = useCallback((msg: ContentType, options?: Options) => {
		toastRef.current = toast(msg, options as Options);
	}, []);
	const update = useCallback((msg: ContentType, options?: Options) => {
		toast.update(toastRef.current, {
			render: msg,
			...(options || {}),
		});
	}, []);

	return [notify, update];
};

export default useToast;
