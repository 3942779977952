import { Link } from 'react-router-dom';

import styled from 'styled-components';

import classNames from 'classnames';

const NavLinkFeedback = styled(Link).attrs((props: { className?: string }) => ({
	className: classNames(props.className, 'nav-link'),
}))`
	background: transparent;

	&:hover {
		box-shadow: inset 0 2px 5px -2px rgba(0, 0, 0, 0.1);
		background: var(--gray-20);
		transition: var(--base-transition-all);
	}
`;

export default NavLinkFeedback;
