import { useDispatch } from 'react-redux';

import { useQueryCache, ArrayQueryKey } from 'react-query';

import useForceUpdate from '@restart/hooks/useForceUpdate';
import Decimal from 'decimal.js';

import ecommerceService from 'services/ecommerce.service';
import { actions, ShoppingCartItem } from 'store/ducks/shoppingCart';
import {
	EcProductDetails,
	ObjectIKeyMap,
	HttpErrorResponse,
	ApiCodes,
} from 'types';

import useShoppingCart from './useShoppingCart';
import useShoppingCartName from './useShoppingCartName';

type ShoppingCartView = {
	isLoading: boolean;
	items: ShoppingCartItem[];
	productMap: ObjectIKeyMap<EcProductDetails | undefined>;
	subtotal: number;
	weight: number;
};

const useShoppingCartView = (): ShoppingCartView => {
	const update = useForceUpdate();
	const cartName = useShoppingCartName();
	const cart = useShoppingCart();
	const queryCache = useQueryCache();
	const dispatch = useDispatch();

	const cartView: ShoppingCartView = {
		isLoading: false,
		items: cart.items,
		productMap: {} as ObjectIKeyMap<EcProductDetails | undefined>,
		subtotal: 0,
		weight: 0,
	};

	const promises = [];
	for (const item of cart.items) {
		const prod = queryCache.getQueryData<EcProductDetails>([
			'product_details',
			item.id,
		]);

		if (!prod) {
			cartView.isLoading = true;

			const query = queryCache.prefetchQuery<EcProductDetails, ArrayQueryKey>(
				['product_details', item.id],
				() =>
					ecommerceService.getProductDetails(item.id).catch((e) => {
						const error = e as HttpErrorResponse;
						if (error.code === ApiCodes.Error.IDNotFound) {
							dispatch(actions.removeItem(cartName, item.id));
						}
						return e;
					}),
				{
					retry: false,
					staleTime: 1000 * 60,
				}
			);

			promises.push(query);
		}
		cartView.productMap[item.id] = prod;
	}

	if (promises.length > 0 && cartView.isLoading) {
		Promise.all(promises).then(() => {
			update();
		});
	}
	if (!cartView.isLoading) {
		let subtotal = new Decimal(0);
		let weight = new Decimal(0);
		for (const item of cartView.items) {
			const productPrice = cartView.productMap[item.id]?.price || 0;
			const productWeight = cartView.productMap[item.id]?.weight ?? 0.01;

			subtotal = subtotal.add(new Decimal(productPrice).mul(item.quantity));
			weight = weight.add(new Decimal(productWeight).mul(item.quantity));
		}
		cartView.subtotal = subtotal.toDecimalPlaces(2).toNumber();
		cartView.weight = weight.toDecimalPlaces(2).toNumber();
	}

	return cartView;
};

export default useShoppingCartView;
