import React from 'react';

import ColumnOrderBy from './filters/ColumnOrderBy';
import useDatagridContext from './useDatagridContext';
import useDatagridDataContext from './useDatagridDataContext';

const DatagridHeader: React.FC = () => {
	const { config } = useDatagridContext();
	const { isLoading } = useDatagridDataContext();

	return (
		<thead>
			<tr>
				{config.columns.map((col) => (
					<ColumnOrderBy
						key={col.title}
						colKey={col.key || ''}
						title={col.title || ''}
						sortable={!!col.sortable && !!col.title && !isLoading}
						className={col.className}
					>
						{col.title}
					</ColumnOrderBy>
				))}
			</tr>
		</thead>
	);
};

DatagridHeader.displayName = 'DatagridHeader';
DatagridHeader.whyDidYouRender = true;

export default DatagridHeader;
