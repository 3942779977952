import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

import MainRouter from 'packages/escritorio/pages/MainRouter';

const EscritorioRoutes: RouteDef[] = [
	{
		path: '/app',
		validation: [Not(personTypeValidation(PersonType.Admin))],
		component: MainRouter,
	},
];

export default EscritorioRoutes;
