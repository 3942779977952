import React from 'react';

import campaignService from 'services/campaign.service';
import newsNotificationService from 'services/newsNotification.service';
import { HttpGetListParams, NewsNotification } from 'types';
import { ClearHtml } from 'utils';
import {
	editLinkFormatter,
	dateTimeFormatter,
	coloredStatusFormatter,
	customFormatter,
	numberFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import {
	newsStatusByName,
	campaignStatusByName,
} from 'packages/admin/utils/coloredStatus';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Comunicação',
		path: '/admin/communication',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	CommunicationNews: {
		title: 'Notícias',
		breadcrumbItems: [...adminBreadcrumb, { label: 'Notícias', active: true }],
		newItemLink: `${adminBreadcrumb[0].path}/news/create`,
		action: (params: HttpGetListParams) =>
			newsNotificationService.getForList('news', params),
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'idNews',
			sortByDesc: true,
			columns: [
				{
					key: 'idNews',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/news/{id}`,
					}),
				},
				{ key: 'title', title: 'Título', searchable: true },
				{
					key: 'description',
					title: 'Descrição',
					searchable: true,
					className: 'w-50',
					formatter: customFormatter<NewsNotification>({
						component: ({ row }) => {
							const description = ClearHtml(row.description);
							return (
								<>
									{description.slice(0, 150)}
									{description.length > 150 ? '...' : ''}
								</>
							);
						},
					}),
				},
				{
					key: 'releaseDate',
					title: 'Programado',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'createdByName',
					title: 'Criado Por',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: newsStatusByName,
					}),
				},
			],
		},
	},

	CommunicationNotif: {
		title: 'Notificações',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'Notificações',
				active: true,
			},
		],
		newItemLink: `${adminBreadcrumb[0].path}/notifications/create`,
		action: (params: HttpGetListParams) =>
			newsNotificationService.getForList('notification', params),
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'idNews',
			sortByDesc: true,
			columns: [
				{
					key: 'idNews',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/notifications/{id}`,
					}),
				},
				{ key: 'title', title: 'Título', searchable: true },
				{
					key: 'description',
					title: 'Descrição',
					searchable: true,
					className: 'w-50',
					formatter: customFormatter<NewsNotification>({
						component: ({ row }) => {
							const description = ClearHtml(row.description);
							return (
								<>
									{description.slice(0, 150)}
									{description.length > 150 ? '...' : ''}
								</>
							);
						},
					}),
				},
				{
					key: 'releaseDate',
					title: 'Programado',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'createdBy',
					title: 'Criado Por',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: newsStatusByName,
					}),
				},
			],
		},
	},

	CommunicationCampaign: {
		title: 'Campanhas',
		breadcrumbItems: [...adminBreadcrumb, { label: 'Campanhas', active: true }],
		newItemLink: '/admin/communication/campaigns/create',
		action: campaignService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'initialDate',
			sortByDesc: true,
			columns: [
				{
					key: 'idCampaign',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/campaigns/{id}`,
					}),
				},
				{
					key: 'name',
					title: 'Nome',
					searchable: true,
				},
				{
					key: 'type',
					title: 'Tipo',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'initialDate',
					title: 'Início',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'endDate',
					title: 'Fim',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: campaignStatusByName,
					}),
				},
			],
		},
	},

	CommunicationCampaignWinners: {
		action: (id: number) => (params: HttpGetListParams) =>
			campaignService.getWinnersForId(id, params),
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'personId',
					title: 'ID',
					searchable: false,
				},
				{ key: 'personName', title: 'Nome', searchable: true },
				{ key: 'idCoupon', title: 'Cupom', searchable: true },
			],
		},
	},

	CommunicationCampaignCoupons: {
		action: (id: number) => (params: HttpGetListParams) =>
			campaignService.getParticipantsForId(id, params),
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'personId',
					title: 'ID',
					searchable: false,
					formatter: numberFormatter,
					className: 'text-center',
				},
				{ key: 'personName', title: 'Nome', searchable: false },
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{ key: 'idCoupon', title: 'Cupom', searchable: true },
			],
		},
	},

	CommunicationCampaignCouponsGrouped: {
		action: (id: number) => (params: HttpGetListParams) =>
			campaignService.getParticipantsForIdGrouped(id, params),
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'personId',
					title: 'ID',
					searchable: false,
					formatter: numberFormatter,
					className: 'text-center',
				},
				{ key: 'personName', title: 'Nome', searchable: true },
				{ key: 'qty', title: 'Qtde. de Cupons', searchable: true },
			],
		},
	},
};

export default ScenesConfig;
