import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';

import { ExitPrompt } from 'components';
import { useThrottle } from 'hooks';
import { deepEqual } from 'utils';

export type ValidationErrors = {
	[key: string]: string | ValidationErrors[];
};

type FormikEffectProps = {
	onSubmitError?: (errors: ValidationErrors) => void;
	focusOnError?: boolean;
	promptOnExit?: boolean;
};

// Faz engenharia reversa no objeto de erro para gerar o nome do elemento
const genElementName = (
	err: ValidationErrors | ValidationErrors[],
	keyName = ''
): string => {
	if (Array.isArray(err)) {
		const firstDefinedKey = err.findIndex((e) => typeof e !== 'undefined')!;
		return genElementName(
			err[firstDefinedKey],
			`${keyName}[${firstDefinedKey}]`
		);
	}

	const errorKeys = Object.keys(err);
	const firstKeyName = errorKeys[0];

	if (firstKeyName === undefined) return keyName;

	const firstElement = err[firstKeyName];
	if (Array.isArray(firstElement)) {
		return genElementName(firstElement, `${keyName}${firstKeyName}`);
	}

	return keyName ? `${keyName}.${firstKeyName}` : firstKeyName;
};

// Permite designar handlers que acessam propriedades e funcionalidades do Formik form
const FormikEffect: React.FC<FormikEffectProps> = ({
	onSubmitError,
	focusOnError = false,
	promptOnExit = false,
}) => {
	const {
		submitCount,
		isValid,
		isSubmitting,
		errors,
		initialValues,
		values,
	} = useFormikContext();
	const [lastHandled, setLastHandled] = useState(0);
	const [enableExitPrompt, setEnableExitPrompt] = useState(false);

	useEffect(() => {
		if (submitCount > lastHandled && !isSubmitting && !isValid) {
			// Aciona callback recebido em onSubmitError
			onSubmitError?.(errors);

			// Foco no primeiro campo com erro
			if (focusOnError) {
				const elName: string = genElementName(errors);
				document.getElementsByName(elName)?.[0]?.focus();
			}

			setLastHandled(submitCount);
		}
	}, [
		submitCount,
		isValid,
		onSubmitError,
		lastHandled,
		errors,
		isSubmitting,
		focusOnError,
	]);

	const isSubmitted = useMemo(() => submitCount > lastHandled && isValid, [
		isValid,
		lastHandled,
		submitCount,
	]);

	const enablePromptOnExit = useThrottle(
		useCallback(() => setEnableExitPrompt(!deepEqual(initialValues, values)), [
			initialValues,
			values,
		]),
		600
	);

	useEffect(() => {
		if (promptOnExit) enablePromptOnExit();
	}, [enablePromptOnExit, promptOnExit]);

	return (
		<>
			{promptOnExit && (
				<ExitPrompt isEnable={!isSubmitted && enableExitPrompt} />
			)}
		</>
	);
};

export default FormikEffect;
