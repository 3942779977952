import React from 'react';

import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

export type FormSubmitButtonProps = Omit<
	ButtonProps,
	'as' | 'type' | 'variant'
> & {
	hasEditPermission?: boolean;
	isSubmitting?: boolean;
	label?: string;
	noIcon?: boolean;
};

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
	hasEditPermission = true,
	isSubmitting,
	label = 'Salvar',
	noIcon = false,
	...rest
}) => (
	<>
		{!!hasEditPermission && (
			<Button type="submit" variant="primary" disabled={isSubmitting} {...rest}>
				{isSubmitting ? (
					<>
						<Spinner size="sm" animation="border" role="status" />
						<span className="ml-2">Aguarde...</span>
					</>
				) : (
					<>
						{!noIcon && <FAIcon icon={faCheck} className="mr-2" />} {label}
					</>
				)}
			</Button>
		)}
	</>
);

export default FormSubmitButton;
