import React from 'react';

import { Col, Form } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

const AccessScheduleFormSkeleton: React.FC = () => (
	<Card>
		<Card.Content className="offset-lg-3 col-lg-6">
			<Skeleton width="15ch" height="1.3rem" />
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton width="10ch" height="1rem" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Skeleton width="10ch" height="1rem" />
					<Skeleton height="2.5rem" />
				</Form.Group>
			</Form.Row>
			<br />
			<Form.Row>
				<Form.Group as={Col} md={12}>
					<Skeleton width="15ch" height="1.3rem" />
					<Card>
						{[...Array(10).keys()].map((key) => (
							<Skeleton
								key={key}
								height="3rem"
								display="block"
								margin="1px 0 0 0"
							/>
						))}
					</Card>
				</Form.Group>
			</Form.Row>
		</Card.Content>
		<Card.Content className="text-right border-top">
			<Form.Row>
				<Col>
					<Skeleton width="10ch" height="2.5rem" margin="0 1rem 0 0" />
					<Skeleton width="10ch" height="2.5rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default AccessScheduleFormSkeleton;
