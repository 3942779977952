import React, { useCallback, useEffect, useState } from 'react';

import { Formik, Form as FormikForm, FormikValues } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import localforage from 'localforage';
import * as Yup from 'yup';

import AddressForm, {
	formSchema as addressFormSchema,
	defaultFormValue as defaultAddressFormValue,
} from 'components/AddressForm';
import { useToast } from 'hooks';
import personService from 'services/person.service';
import { ID, PersonAddress } from 'types';
import history from 'utils/history';

import { FormGroupTitle } from 'packages/admin/components';

// } from 'packages/admin/pages/system/company/AddressForm';
// import { YupHelper } from 'utils';

export interface JoinAddressSceneProps {
	address: PersonAddress;
	idPerson: ID | null;
}

export const defaultFormValue: JoinAddressSceneProps = {
	idPerson: null,
	address: defaultAddressFormValue,
};

export const formSchema = Yup.object<JoinAddressSceneProps>().shape({
	idPerson: Yup.mixed()
		.transform((val) => (!val ? null : Number(val)))
		.nullable()
		.notRequired(),
	address: addressFormSchema,
});

const JoinAddressScene: React.FC = () => {
	const [registerAddress, setRegisterAddress] = useState<JoinAddressSceneProps>(
		defaultFormValue
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [localEmail, setLocalEmail] = useState<string>('');
	const [toast] = useToast();

	useEffect(() => {
		async function getData() {
			setIsLoading(true);
			const data: {
				idPerson: ID;
				email: string;
			} | null = await localforage.getItem('joinaddress');

			if (!data) {
				history.push('/login');
				return;
			}

			localforage.removeItem('joinaddress');
			setLocalEmail(data.email);
			setRegisterAddress({ ...defaultFormValue, idPerson: data.idPerson });
			setIsLoading(false);
		}
		getData();
	}, []);

	const handleSubmit = useCallback(
		async (values: FormikValues) => {
			const submit = { ...values } as JoinAddressSceneProps;

			setIsLoading(true);

			const submitAddr: PersonAddress & { idCity?: ID } = submit.address;
			submitAddr.idPerson = submit.idPerson;
			submitAddr.idCity = submitAddr.city?.idCity;

			personService
				.createAddress(submitAddr)
				.then(async () => {
					localforage.setItem('email', localEmail);
					history.push('/login');
				})
				.catch(() => {
					setIsLoading(false);
					toast('Não foi possível efetuar o cadastro', {
						type: 'error',
					});
				});
		},
		[localEmail, toast]
	);

	return (
		<Container
			fluid
			className="h-100 d-flex justify-content-center align-items-start"
		>
			<Card
				className="bg-white mb-0 h-100"
				style={{ width: '100%', minWidth: 320, maxWidth: 1140 }}
			>
				<Card.Header className="p-0">
					<img
						src="assets/images/portal_registro_baner.jpg"
						className="img-fluid"
						alt="Banner de registro"
					/>
				</Card.Header>
				<Card.Body>
					<Formik
						onSubmit={handleSubmit}
						initialValues={registerAddress}
						validationSchema={formSchema}
						enableReinitialize
					>
						<FormikForm>
							<div className="w-100 bg-light p-4 mb-2">
								<Col
									sm={12}
									md={{ span: 10, offset: 1 }}
									lg={{ span: 8, offset: 2 }}
								>
									<h1 className="text-success">
										Parabéns, seu cadastro foi realizado com sucesso!
									</h1>
									<FormGroupTitle>
										Preencha abaixo seu endereço para que possamos completar seu
										cadastro.
									</FormGroupTitle>
								</Col>
							</div>

							<Col
								sm={12}
								md={{ span: 10, offset: 1 }}
								lg={{ span: 8, offset: 2 }}
								className="pt-5"
							>
								<AddressForm path="address" />

								<Form.Group className="text-right">
									<Button
										variant="primary"
										className="mt-2"
										type="submit"
										disabled={isLoading}
									>
										{isLoading ? (
											<>
												<Spinner size="sm" animation="border" role="status" />
												<span className="ml-2">AGUARDE...</span>
											</>
										) : (
											'CADASTRAR'
										)}
									</Button>
								</Form.Group>
							</Col>
						</FormikForm>
					</Formik>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default JoinAddressScene;
