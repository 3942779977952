import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Skel from 'react-loading-skeleton';
import styled from 'styled-components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props}>
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const ExecutiveEditFormSkeleton: React.FC = () => (
	<Card>
		<Card.Body>
			<Form.Row>
				<Form.Group as={Col} md={8}>
					<Form.Row className="justify-content-between">
						<SkelGroup as={Col} sm={4} md={5} lg={3} />
						<SkelGroup as={Col} sm={4} md={5} lg={3} />
					</Form.Row>

					<Form.Row>
						<SkelGroup as={Col} md={12} />
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md={4}>
							<Skeleton count={5} />
						</Form.Group>
						<Form.Group as={Col} md={8}>
							<Skeleton count={5} />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Skeleton height="20rem" />
						</Form.Group>
					</Form.Row>
				</Form.Group>

				<Form.Group as={Col} md={4}>
					<Form.Row className="justify-content-between">
						<SkelGroup as={Col} sm={4} md={6} lg={3} />
						<SkelGroup as={Col} sm={3} md={4} lg={2} />
					</Form.Row>

					<Form.Row>
						<SkelGroup as={Col} md={12} />
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Skeleton height="10rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<SkelGroup as={Col} md={6} />
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Skeleton count={5} />
						</Form.Group>
					</Form.Row>
				</Form.Group>
			</Form.Row>
		</Card.Body>

		<Card.Footer className="text-right">
			<span className="mr-2">
				<Skel width={100} height="2rem" />
			</span>
			<Skel width={100} height="2rem" />
		</Card.Footer>
	</Card>
);

export default ExecutiveEditFormSkeleton;
