import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar, { NavbarProps } from 'react-bootstrap/Navbar';

import { faBars, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import JumboMenu from 'components/JumboMenu';
import { EcDropdown } from 'types';

import { useEcommerceBasePath } from '../hooks';
import useEcommerceContext from '../hooks/useEcommerceContext';

import NavLinkFeedback from './navbarCatalog/NavLinkFeedback';
import NavbarCatalogSkeleton from './navbarCatalog/Skeleton';

type NavbarCatalogProps = NavbarProps & {
	className?: string;
};

type NavbarCatalogComponent = React.FC<NavbarCatalogProps> & {
	Skeleton: typeof NavbarCatalogSkeleton;
};

const NavbarCatalog: NavbarCatalogComponent = ({ className, ...rest }) => {
	const baseUrl = useEcommerceBasePath();
	const {
		config: { menu, dropdown },
	} = useEcommerceContext();

	const mappedDrop = new Map<string, EcDropdown>();
	const dropTree: EcDropdown[] = [];

	for (const drop of dropdown) {
		mappedDrop.set(String(drop.idCategory), { ...drop, children: [] });
	}

	for (const id of mappedDrop.keys()) {
		const drop = mappedDrop.get(id)!;

		if (drop.idParent) {
			mappedDrop.get(String(drop.idParent))?.children?.push({ ...drop });
		} else {
			dropTree.push(drop);
		}
	}

	return (
		<Navbar
			className={classNames(className, 'py-0 border-bottom')}
			variant="light"
			bg="white"
			{...rest}
		>
			<Container fluid>
				<Col>
					<Nav>
						<JumboMenu.Nav className="border-right">
							<JumboMenu.NavItem
								title={
									<>
										<FAIcon icon={faBars} />
										<span className="mx-3 text-uppercase text-weight-bold">
											Todos os Departamentos
										</span>
										<FAIcon icon={faChevronDown} />
									</>
								}
								link={baseUrl}
							>
								<JumboMenu.Dropdown>
									{dropTree.map((item) => (
										<JumboMenu.DropdownItem
											key={item.idCategory}
											title={<b>{item.name}</b>}
											link={`${baseUrl}/categoria/${item.idCategory}`}
										>
											{item.children && (
												<JumboMenu.Submenu>
													{item.children.map((child) => (
														<div key={child.idCategory} className="mb-2">
															<JumboMenu.SubmenuItem
																title={<b>{child.name}</b>}
																link={`${baseUrl}/categoria/${child.idCategory}`}
															/>
															{child.children &&
																child.children.map((child2) => (
																	<JumboMenu.SubmenuItem
																		key={child2.idCategory}
																		title={child2.name}
																		link={`${baseUrl}/categoria/${child2.idCategory}`}
																	/>
																))}
														</div>
													))}
												</JumboMenu.Submenu>
											)}
										</JumboMenu.DropdownItem>
									))}
								</JumboMenu.Dropdown>
							</JumboMenu.NavItem>
						</JumboMenu.Nav>
						{menu.map((item) => (
							<Nav.Item key={item.idCategory}>
								<NavLinkFeedback
									className="px-4"
									to={`${baseUrl}/categoria/${item.idCategory}`}
								>
									{item.shortName}
								</NavLinkFeedback>
							</Nav.Item>
						))}
					</Nav>
				</Col>
			</Container>
		</Navbar>
	);
};

NavbarCatalog.Skeleton = NavbarCatalogSkeleton;

NavbarCatalog.displayName = 'NavbarCatalog';
NavbarCatalog.whyDidYouRender = true;

export default NavbarCatalog;
