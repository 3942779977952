/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import { useField } from 'formik';
import BsToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup, {
	ToggleButtonGroupProps as ButtonGroupProps,
	ToggleButtonRadioProps,
} from 'react-bootstrap/ToggleButtonGroup';
import styled from 'styled-components';

export type ToggleButtonGroupProps = Omit<
	ButtonGroupProps<ToggleButtonRadioProps<any>>,
	'type'
>;

// Customiza o ToggleButton para apenas o selecionado ficar com a cor da variante
// Os outros ficam apenas com contorno e sem destaque
export const ToggleButton = styled(BsToggleButton)`
	&:not(active) {
		border: 1px solid var(--border-color);
		background: var(--gray-10);
		color: var(--base-color);
	}
`;

const FormikToggleGroup: React.FC<ToggleButtonGroupProps> = ({
	name,
	onChange,
	...props
}) => {
	const [{ value }, { initialValue }, { setValue }] = useField(name || '');

	const handleChange = useCallback(
		(newValue: any) => {
			setValue(newValue);
			onChange?.(newValue, () => {});
		},
		[setValue, onChange]
	);

	return (
		<ToggleButtonGroup
			{...props}
			name={name || ''}
			type="radio"
			defaultValue={initialValue}
			value={value}
			onChange={handleChange}
		/>
	);
};

FormikToggleGroup.displayName = 'FormikToggleGroup';
FormikToggleGroup.whyDidYouRender = true;

export default FormikToggleGroup;
