import React from 'react';

import { Button, Spinner } from 'react-bootstrap';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';

type FeaturedImageModalProps = {
	imageSrc: string;
	fileName: string;
	ButtonClose?: string | React.ReactElement;
	style?: React.CSSProperties;
	className?: string;
};

const defaultProps = {
	ButtonClose: 'Fechar',
};

const FeaturedImageModal: React.FC<
	ModalForwardProps<FeaturedImageModalProps, void>
> = ({ imageSrc, fileName, ButtonClose, modalRef, ...rest }) => {
	if (!modalRef) return null;

	return (
		<Modal size="auto" {...rest}>
			{typeof ButtonClose === 'string' && (
				<Button
					style={{
						position: 'absolute',
						display: 'flex',
						right: 0,
						top: '-32px',
					}}
					variant="light"
					onClick={() => modalRef.dismiss()}
				>
					{ButtonClose}
				</Button>
			)}
			{typeof ButtonClose === 'function' && <>{ButtonClose}</>}
			<Modal.Body
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					minHeight: '20rem',
					padding: 0,
					borderRadius: '0.5rem',
				}}
			>
				<img
					src={imageSrc}
					alt={fileName}
					width="100%"
					style={{
						zIndex: 1,
						width: 'auto',
						height: '100%',
						maxWidth: '1024px',
						maxHeight: '768px',
					}}
				/>
				<Spinner
					animation="border"
					style={{ position: 'absolute', zIndex: 0 }}
				/>
			</Modal.Body>
		</Modal>
	);
};

FeaturedImageModal.defaultProps = defaultProps;

export default FeaturedImageModal;
