import React from 'react';

import Overlay, { OverlayProps } from 'react-overlays/Overlay';
import styled, { css } from 'styled-components';

import { cssVar } from 'polished';

export type StyledOverlayProps = {
	overlayProps: Omit<OverlayProps, 'children'>;
	children: React.ReactNode;
	className?: string;
};

const Portal = styled.div.attrs((attrs: { placement: string }) => ({
	placement: attrs.placement || '',
}))`
	position: absolute;
	padding: 0 5px;
	z-index: var(--dropdown-zindex);

	${(p) => {
		const plac = p.placement;
		if (plac.startsWith('left') || plac.startsWith('right')) {
			return css`
				padding: 0 5px;
			`;
		}
		return css`
			padding: 5px 0;
		`;
	}}
`;

const Arrow = styled.div.attrs((attrs: { placement: string }) => ({
	placement: attrs.placement || '',
}))`
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;

	${(p) => {
		const baseBg = cssVar('--base-bg');
		const plac = p.placement;

		if (plac.startsWith('left')) {
			return css`
				right: 0;
				border-width: 5px 0 5px 5px;
				border-color: transparent transparent transparent ${baseBg};
			`;
		}
		if (plac.startsWith('right')) {
			return css`
				left: 0;
				border-width: 5px 5px 5px 0;
				border-color: transparent ${baseBg} transparent transparent;
			`;
		}
		if (plac.startsWith('top')) {
			return css`
				bottom: 0;
				border-width: 5px 5px 0;
				border-color: ${baseBg} transparent transparent transparent;
			`;
		}
		if (plac.startsWith('bottom')) {
			return css`
				top: 0;
				border-width: 0 5px 5px;
				border-color: transparent transparent ${baseBg} transparent;
			`;
		}
		return '';
	}}
`;

const Body = styled.div``;

const StyledOverlay: React.FC<StyledOverlayProps> = ({
	children,
	overlayProps,
	...rest
}) => (
	<Overlay {...overlayProps}>
		{({ props, arrowProps, placement }) => {
			return (
				<Portal {...props} {...rest} placement={placement}>
					<Arrow
						{...arrowProps}
						placement={placement}
						style={arrowProps.style}
					/>
					<Body>{children}</Body>
				</Portal>
			);
		}}
	</Overlay>
);

export default StyledOverlay;
