import distributorService from 'services/distributor.service';
import { DistributorList } from 'types';
import { cepFormatter, buttonLinkFormatter } from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import paginateConfig from 'packages/admin/pages/list/routes/paginate';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';

import DistributorInfoModal from './utils/modal/DistributorInfoModal';

const appBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Pedidos',
		path: 'app/utils',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	UtilsDistributors: {
		title: 'Encontre um PA',
		breadcrumbItems: [
			...appBreadcrumb,
			{ label: 'Meus Pedidos', active: true },
		],
		action: distributorService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			sortByKey: 'name',
			sortByDesc: false,
			columns: [
				{
					key: 'name',
					title: 'Usuário',
					searchable: true,
					sortable: true,
					formatter: buttonLinkFormatter<
						DistributorList,
						{ id: number; row: DistributorList }
					>({
						label: (row) => row.name,
						className: 'btn btn-sm text-left',
						onClick: (id, row, openModal) =>
							openModal(DistributorInfoModal, { id, row }),
					}),
				},
				{
					key: 'district',
					title: 'Bairro',
					searchable: true,
					sortable: true,
				},
				{
					key: 'city',
					title: 'Cidade',
					searchable: true,
					sortable: true,
				},
				{
					key: 'uf',
					title: 'Estado',
					searchable: true,
					searchFormatter: cepFormatter.formatForSearch,
					className: 'text-center',
					sortable: true,
				},
			],
		},
	},
};

export default ScenesConfig;
