import React, { useState, useEffect, useCallback } from 'react';

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Skeleton from 'react-loading-skeleton';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Empty } from 'components';
import { StoreProduct } from 'types';

import { Container, ProductItem } from './productSearchList/styles';

export interface ProductSearchListProps {
	products?: StoreProduct[];
	loading?: boolean;
	onSearchProducts?: (search: string) => void;
	style?: React.CSSProperties;
	className?: string;
}

const ProductSearchList: React.FC<ProductSearchListProps> = (props) => {
	const { products, loading, onSearchProducts, ...rest } = props;

	const [productsItens, setProductsItens] = useState<StoreProduct[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setProductsItens(products || []);
	}, [products]);

	useEffect(() => {
		setIsLoading(loading || false);
	}, [loading]);

	const searchProducts = useCallback(
		(event) => {
			setIsLoading(true);
			// eslint-disable-next-line no-console
			console.log('SEARCH ==> ', event.target.value);
			if (onSearchProducts) {
				onSearchProducts(event.target.value);
			}
		},
		[onSearchProducts]
	);

	return (
		<Container {...rest}>
			<h5>Produtos</h5>

			<InputGroup className="mb-3">
				<FormControl
					placeholder="Pesquisar por nome ou código do produto"
					onChange={searchProducts}
					disabled={!productsItens?.length}
				/>
				<InputGroup.Append>
					<InputGroup.Text>
						<FAIcon icon="search" />
					</InputGroup.Text>
				</InputGroup.Append>
			</InputGroup>

			<div>
				{productsItens && isLoading ? (
					<Skeleton count={5} />
				) : (
					productsItens.map((item: StoreProduct) => (
						<ProductItem key={item.idProduct}>
							<strong>{item.idProduct}</strong> {item.name}
						</ProductItem>
					))
				)}

				{!productsItens?.length && !isLoading && (
					<Empty description="Não há produtos cadastrados nessa loja" />
				)}
			</div>
		</Container>
	);
};

ProductSearchList.displayName = 'ProductSearchList';
ProductSearchList.whyDidYouRender = true;

export default ProductSearchList;
