import React, { useCallback } from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { Input } from 'components/Formik';
import { numberFormatter } from 'utils/formatters';

export type YearProps = {
	name: string;
	isQuery?: boolean;
};

const YearFilter: React.FC<YearProps> = ({ name, isQuery }) => {
	const { onParamChange, onQueryChange } = useDatagridContext();
	const onChange = useCallback(
		(value: string) => {
			if (isQuery) {
				onQueryChange(name, value);
			} else {
				onParamChange(name, value);
			}
		},
		[onParamChange, onQueryChange, name, isQuery]
	);

	return <Input name={name} onChange={onChange} formatter={numberFormatter} />;
};

export default YearFilter;
