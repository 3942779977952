import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	Vip,
	HashID,
	VipMonthly,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Vip>> => Crud.getList<Vip>('mmn/vip', params);

const getById = (id: ID | HashID): Promise<Vip> =>
	Crud.getOne<Vip>('mmn/vip/{id}', Number(id));

const getByMonth = (
	params: HttpGetListParams
): Promise<HttpGetListResult<VipMonthly>> =>
	Crud.getList<VipMonthly>(
		'mmn/vip/{id}/byMonth?month={month}&year={year}',
		params
	);

const remove = (id: ID | HashID): Promise<HttpPostResultAny> =>
	Crud.delete('mmn/vip/{id}', Number(id));

const createOrUpdate = (
	id: ID | null,
	data: Vip
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('mmn/vip/{id}', id as number, data);
	}
	return Crud.create('mmn/vip', data);
};

export default {
	getForList,
	getById,
	getByMonth,
	createOrUpdate,
	remove,
};
