import React from 'react';

import H from 'history';

import history from 'utils/history';

import EventManager, { ModalEvent } from './modalContainer/EventManager';
import { ModalForwardProps } from './modalContainer/types';

export * from './modalContainer/types';

type ModalForwardPropsAny = ModalForwardProps<unknown, unknown>;

let modalList: ModalForwardPropsAny[] = [];

const removeModal = (modal: ModalForwardPropsAny) =>
	modalList.splice(modalList.indexOf(modal), 1);

const locationUpdate = (location: H.Location) => {
	modalList = modalList.filter(
		(wrapper) => wrapper.modalRef.location.pathname === location.pathname
	);
};

export type ModalContainerProps = {};

/**
 * Controla a exibição ordenada das caixas de diálogo e ouve os eventos
 * de troca de rota para fechar automaticamente os popups obsoletos.
 * ATENÇÃO:
 * Container deve ser adicionado dentro do Router principal para receber os eventos de troca de rotas.
 */
const ModalContainer: React.FC<ModalContainerProps> = () => {
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

	React.useEffect(() => {
		EventManager.on(ModalEvent.Show, (wrapper?: ModalForwardPropsAny) => {
			if (!wrapper) {
				return;
			}
			modalList.push(wrapper);
			forceUpdate();
		});

		EventManager.on(ModalEvent.Close, (wrapper?: ModalForwardPropsAny) => {
			// eslint-disable-next-line no-param-reassign
			if (!wrapper) {
				return;
			}
			// eslint-disable-next-line no-param-reassign
			wrapper.show = false;
			forceUpdate();
		});

		EventManager.on(ModalEvent.Exited, (wrapper?: ModalForwardPropsAny) => {
			if (!wrapper) {
				return;
			}
			removeModal(wrapper);
			wrapper.modalRef.afterClose?.(wrapper.modalRef.result);
			forceUpdate();
		});

		EventManager.on(ModalEvent.ClearAll, () => {
			modalList = [];
			forceUpdate();
		});
	}, []);

	React.useEffect(() => {
		const unlisten = history.listen((location) => {
			locationUpdate(location);
			forceUpdate();
		});

		return unlisten;
	}, []);

	if (!modalList.length) {
		return null;
	}

	return (
		<div>
			{modalList?.map((modal) => {
				const { modalRef, ...rest } = modal;
				return (
					<modalRef.component
						key={modalRef.id}
						modalRef={modalRef}
						{...(modalRef.componentProps || {})}
						{...rest}
					/>
				);
			})}
		</div>
	);
};

export default ModalContainer;
