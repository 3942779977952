import React, { useCallback, useState } from 'react';

import { useEventCallback, useMergeState } from '@restart/hooks';
import classNames from 'classnames';

import Backdrop from 'components/Backdrop';
import LoadingProgressBar from 'components/LoadingProgressBar';
import useDeepEffect from 'hooks/useDeepEffect';
import { QueryOperator } from 'types';
import { defaultFormatter } from 'utils/formatters';

import DatagridBody from './datagrid/DatagridBody';
import DatagridContext from './datagrid/DatagridContext';
import DatagridDataContext from './datagrid/DatagridDataContext';
import DatagridFooter from './datagrid/DatagridFooter';
import DatagridHeader from './datagrid/DatagridHeader';
import DatagridStyle from './datagrid/DatagridStyle';
import {
	DatagridConfig,
	DatagridData,
	DatagridState,
	DatagridUpdateListenerFn,
	DatagridUpdateAction,
} from './datagrid/types';

export type DatagridProps = React.PropsWithChildren<DatagridConfig> & {
	className?: string | string[];
	tableClassName?: string | string[];
	data: DatagridData;
	onChange?: (state: DatagridState, force: boolean) => void;
};

const defaultProps: Partial<DatagridProps> = {
	updateOnFilterChange: true,
	sortByKey: '',
	sortByDesc: true,
	tableClassName: 'table-hover table-striped',
};

const defaultSearchFormatter = (val: unknown) => val as string;

const Datagrid: React.FC<DatagridProps> = ({
	className,
	tableClassName,
	columns,
	data,
	paginate,
	sortByKey,
	sortByDesc,
	updateOnFilterChange,
	children,
	onChange,
}) => {
	const config: DatagridConfig = {
		columns,
		paginate,
		sortByKey,
		sortByDesc,
		updateOnFilterChange,
	};

	const handleChange = useEventCallback(onChange);
	const [listeners, setListeners] = useState<DatagridUpdateListenerFn[]>([]);
	const [cachedConfig, setCachedConfig] = useState<DatagridConfig>(config);
	const [cachedState, setCachedState] = useMergeState<DatagridState>({
		page: 1,
		filters: [],
		rowsPerPage: paginate?.rowsOptions[0] ?? 50,
		searchColumns: [],
		sortByKey,
		sortByDesc,
		params: {},
		disabled: false,
		lastAction: DatagridUpdateAction.Setup,
	});

	// Avisa ao componente pai sobre alterações pelo onChange
	useDeepEffect(() => {
		if (
			cachedState.lastAction === DatagridUpdateAction.ParamChange ||
			cachedState.lastAction === DatagridUpdateAction.QueryChange ||
			cachedState.lastAction === DatagridUpdateAction.FilterChange ||
			cachedState.lastAction === DatagridUpdateAction.Setup
		) {
			if (!updateOnFilterChange) {
				return;
			}
		}
		handleChange(cachedState, false);
	}, [cachedState, handleChange, updateOnFilterChange]);

	// Cria cache da disposição de colunas e formatação do datatable:
	useDeepEffect(() => {
		const newConfig = { ...config };

		newConfig.columns = newConfig.columns.map((col) => {
			const newCol = { ...col };

			// Configura todos os formatters para simplificar lógica:
			if (newCol.formatter === undefined) {
				newCol.formatter = defaultFormatter;
			}
			if (newCol.searchable && newCol.searchFormatter === undefined) {
				newCol.searchFormatter =
					newCol.formatter.formatForSearch ?? defaultSearchFormatter;
			}
			return newCol;
		});
		setCachedConfig(newConfig);
	}, [config]);

	const notifyListeners = useCallback(
		(state: DatagridState, action: DatagridUpdateAction) => {
			// TODO:
			// Dispara um update no state somente para pegar a lista atual de listeners, mas não
			// atualiza a lista realmente.
			// Única forma que pensei de não adicionar dependências que mudam muito no callback.
			setListeners((list) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const listener of list) {
					listener(state, action);
				}
				return list;
			});
		},
		[setListeners]
	);

	useDeepEffect(() => {
		setCachedState((state) => {
			notifyListeners(state, DatagridUpdateAction.NewData);
			return state;
		});
	}, [data, setCachedState, notifyListeners]);

	// Callbacks do Datagrid:
	//
	const onPageChange = useCallback(
		(page: number) => {
			setCachedState((state) => {
				notifyListeners({ ...state, page }, DatagridUpdateAction.PageChange);
				return { page, lastAction: DatagridUpdateAction.PageChange };
			});
		},
		[setCachedState, notifyListeners]
	);

	const onRowsPerPageChange = useCallback(
		(rowsPerPage: number) => {
			setCachedState((state) => {
				notifyListeners(
					{ ...state, rowsPerPage },
					DatagridUpdateAction.RowsPerPage
				);
				return { rowsPerPage, lastAction: DatagridUpdateAction.RowsPerPage };
			});
		},
		[setCachedState, notifyListeners]
	);

	const onSearchChange = useCallback(
		(key: string, text: string) => {
			setCachedState((prevState: DatagridState) => {
				const searchColumns = prevState.searchColumns.filter(
					(val) => val.key !== key
				);
				if (text) {
					searchColumns.push({ key, text });
				}
				notifyListeners(
					{ ...prevState, searchColumns },
					DatagridUpdateAction.ColumnSearch
				);
				return { searchColumns };
			});
		},
		[setCachedState, notifyListeners]
	);

	const onQueryChange = useCallback(
		(key: string, query: string, operator?: QueryOperator) => {
			setCachedState((prevState: DatagridState) => {
				const filters = prevState.filters.filter(
					(val) => !(val.key === key && val.operator === operator)
				);
				if (query) {
					filters.push({ key, query, operator });
				}
				notifyListeners(
					{ ...prevState, filters },
					DatagridUpdateAction.FilterChange
				);
				return { filters, lastAction: DatagridUpdateAction.FilterChange };
			});
		},
		[setCachedState, notifyListeners]
	);

	const onParamChange = useCallback(
		(key: string, value: string | number | null) => {
			setCachedState((prevState: DatagridState) => {
				const params = {
					params: { ...prevState.params, [key]: value },
					lastAction: DatagridUpdateAction.ParamChange,
				};
				notifyListeners(
					{ ...prevState, ...params },
					DatagridUpdateAction.ParamChange
				);
				return params;
			});
		},
		[setCachedState, notifyListeners]
	);

	const onOrderByChange = useCallback(
		(key: string, desc: boolean) => {
			setCachedState((prevState: DatagridState) => {
				const sort = {
					sortByKey: key,
					sortByDesc: desc,
					lastAction: DatagridUpdateAction.ColumnSort,
				};
				notifyListeners(
					{ ...prevState, ...sort },
					DatagridUpdateAction.ColumnSort
				);
				return sort;
			});
		},
		[setCachedState, notifyListeners]
	);

	const setDisabled = useCallback(
		(disable?: boolean) => {
			setCachedState({ disabled: disable ?? true });
		},
		[setCachedState]
	);

	const forceUpdate = () => {
		// eslint-disable-next-line no-console
		console.log('Force update');
		// eslint-disable-next-line no-restricted-syntax
		for (const listener of listeners) {
			listener(cachedState, DatagridUpdateAction.ForceUpdate);
		}
		handleChange(cachedState, true);
	};

	const listenForUpdates = useCallback((listener: DatagridUpdateListenerFn) => {
		setListeners((list) => {
			if (list.indexOf(listener) >= 0) {
				return list;
			}
			const newListeners = [...list, listener];
			return newListeners;
		});
	}, []);

	const context = {
		config: cachedConfig as Required<DatagridConfig>,
		tableParams: cachedState,
		onPageChange,
		onParamChange,
		onOrderByChange,
		onRowsPerPageChange,
		onSearchChange,
		onQueryChange,
		forceUpdate,
		listenForUpdates,
		setDisabled,
	};

	return (
		<>
			<DatagridContext.Provider value={context}>
				<DatagridDataContext.Provider value={data}>
					{children}
					<DatagridStyle
						className={classNames(
							'table-responsive table-centered position-relative flex-grow-1 d-flex flex-column',
							className
						)}
					>
						{(cachedState.disabled || data.isLoading) && (
							<Backdrop>
								<Backdrop.Gradient
									filter={{
										iniColor: '#000000',
										iniOpacity: 0.05,
										endOpacity: 0.05,
									}}
								/>
								{!cachedState.disabled && <LoadingProgressBar />}
							</Backdrop>
						)}
						<table className={classNames('table mb-auto', tableClassName)}>
							<DatagridHeader />
							<DatagridBody />
						</table>
						<DatagridFooter />
					</DatagridStyle>
				</DatagridDataContext.Provider>
			</DatagridContext.Provider>
		</>
	);
};

Datagrid.displayName = 'Datagrid';
Datagrid.defaultProps = defaultProps;
Datagrid.whyDidYouRender = true;

export default Datagrid;
