import {
	faGrinAlt,
	faChartBar,
	faChartLine,
	faHandHoldingUsd,
	faNewspaper,
	faPlus,
	faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';

import { PersonType, RouteDef } from 'types';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

const ClubRoutes: RouteDef = {
	path: '/app/club',
	menu: {
		name: 'Família Feliz',
		icon: faGrinAlt,
	},
	validation: [Not(personTypeValidation(PersonType.DistributorUser))],
	children: [
		{
			path: '/summary',
			menu: {
				name: 'Resumo',
				icon: faChartBar,
			},
		},
		{
			path: '/store',
			menu: {
				name: 'Loja',
				icon: faShoppingCart,
			},
		},
		{
			path: '/order',
			menu: {
				name: 'Novo Pedido',
				icon: faPlus,
			},
		},
		{
			path: '/order/history',
			menu: {
				name: 'Pedidos',
				icon: faNewspaper,
			},
		},
		{
			path: '/statement',
			menu: {
				name: 'Financeiro',
				icon: faChartLine,
			},
		},
		{
			path: '/advance',
			menu: {
				name: 'Adiantamento',
				icon: faHandHoldingUsd,
			},
		},
	],
};

export default ClubRoutes;
