import { faHome, faShoppingBag } from '@fortawesome/pro-light-svg-icons';

import { PersonStatus, PersonType, RouteDef } from 'types';
import personStatusValidation from 'utils/RouteValidator/personStatusValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

import ClubRoutes from './pages/club/routes';
import DistributorRoutes from './pages/distributor/routes';
import FinancialRoutes from './pages/financial/routes';
import MyRoutes from './pages/my/routes';
import NetworkRoutes from './pages/network/routes';
import OrderRoutes from './pages/order/routes';
import UtilsRoutes from './pages/utils/routes';
import VcDigitalRoutes from './pages/vcdigital/routes';

const DashboardsRoutes: RouteDef = {
	path: '/app/home',
	validation: [Not(personTypeValidation(PersonType.DistributorUser))],
	menu: {
		name: 'Início',
		icon: faHome,
	},
};

// TODO: Implementar FirstOrderRoutes
/*
const FirstOrderRoutes: RouteDef[] = [
	{
		path: '/app/firstorder',
		isPrivate: true,
		// component: StoreEdit,
		exact: true,
	},
];
*/

const StoreRoutes: RouteDef = {
	path: '/loja',
	validation: [
		personStatusValidation(PersonStatus.Active),
		Not(personTypeValidation(PersonType.DistributorUser)),
	],
	menu: {
		name: 'Loja',
		icon: faShoppingBag,
	},
};

const EscritorioRoutes: RouteDef[] = [
	DashboardsRoutes,
	DistributorRoutes,
	StoreRoutes,
	ClubRoutes,
	OrderRoutes,
	NetworkRoutes,
	VcDigitalRoutes,
	FinancialRoutes,
	UtilsRoutes,
	MyRoutes,
];

export default EscritorioRoutes;
