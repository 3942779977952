import React from 'react';

import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';
import styled from 'styled-components';

import { cover } from 'polished';

type LoadingSpinnerOverlayProps = Partial<SpinnerProps>;

const Overlay = styled.div`
	${cover}
	background-color: rgba(0, 0, 0, cssVar(--backdrop-opacity));
	z-index: 5;
	text-align: center;
	vertical-align: center;
`;

const LoadingSpinnerOverlay: React.FC<LoadingSpinnerOverlayProps> = ({
	animation,
	...props
}) => (
	<Overlay>
		<Spinner animation={animation || 'border'} {...props} />
	</Overlay>
);

LoadingSpinnerOverlay.displayName = 'LoadingSpinnerOverlay';
LoadingSpinnerOverlay.whyDidYouRender = true;

export default LoadingSpinnerOverlay;
