import React, { useState } from 'react';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import KeyCode from 'rc-util/lib/KeyCode';

import * as S from './switch/styles';
import { SwitchProps } from './switch/type';

const Switch: React.FC<
	Omit<SwitchProps, 'name'> & { name?: string; checked?: boolean }
> = React.memo(
	({
		className,
		disabled,
		checkedChildren,
		unCheckedChildren,
		checkedColor,
		unCheckedColor,
		isLoading,
		checked,
		onChange,
		defaultChecked,
		...restProps
	}) => {
		const [isChecked, setChecked] = useState<boolean>(
			defaultChecked ?? !!checked
		);

		const triggerChange = (newChecked: boolean) => {
			if (!disabled && !isLoading && newChecked !== isChecked) {
				setChecked(newChecked);
				onChange?.(newChecked);
			}
		};

		function onInternalKeyDown(e: React.KeyboardEvent<HTMLButtonElement>) {
			if (e.which === KeyCode.LEFT) {
				triggerChange(false);
			} else if (e.which === KeyCode.RIGHT) {
				triggerChange(true);
			}
		}

		function onInternalClick() {
			triggerChange(!isChecked);
		}

		return (
			<S.Switch
				isChecked={isChecked}
				type="button"
				role="switch"
				aria-checked={isChecked}
				disabled={disabled}
				className={className}
				isLoading={isLoading}
				checkedColor={checkedColor}
				unCheckedColor={unCheckedColor}
				onKeyDown={onInternalKeyDown}
				onClick={onInternalClick}
				{...restProps}
			>
				<div>{isLoading && <FAIcon icon={faSpinnerThird} />}</div>
				<span>{isChecked ? checkedChildren : unCheckedChildren}</span>
			</S.Switch>
		);
	}
);

Switch.displayName = 'Switch';
Switch.whyDidYouRender = true;

export default Switch;
