import {
	Store,
	ID,
	HttpPostResultAny,
	Banner,
	HttpGetListParams,
	HttpGetListResult,
	Feature,
	FeatureSelect,
	StoreForSelect,
	BannerSelect,
	BannerForSort,
	FeatureForSort,
} from 'types';

import Crud from './crud.service';

const getForSelect = (
	params: HttpGetListParams,
	route = 'store/select'
): Promise<HttpGetListResult<StoreForSelect>> =>
	Crud.getList<StoreForSelect>(route, params);

const getStores = (
	params: HttpGetListParams,
	route = 'store'
): Promise<HttpGetListResult<Store>> => Crud.getList<Store>(route, params);

const getStore = (id: ID): Promise<Store> =>
	Crud.getOne<Store>('store/{id}', id);

const saveStore = (data: Store, id?: ID): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('store/{id}', id, data);
	}

	return Crud.create('store', data);
};

const getBanners = (
	params: HttpGetListParams,
	route = 'store/banner'
): Promise<HttpGetListResult<BannerSelect>> =>
	Crud.getList<BannerSelect>(route, params);

const getBannerById = (id: ID): Promise<Banner> =>
	Crud.getOne<Banner>('store/banner/{id}', id as number);

const getBannersByIdStore = (
	id: ID
): Promise<HttpGetListResult<BannerForSort>> =>
	Crud.getList<BannerForSort>(`store/${id}/banner`, {});

const createOrUpdateBanner = (
	id: ID | null,
	data: Banner
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('store/banner/{id}', id, data);
	}

	return Crud.create('store/banner', data);
};

const getFeatures = (
	params: HttpGetListParams,
	route = 'store/feature'
): Promise<HttpGetListResult<FeatureSelect>> =>
	Crud.getList<FeatureSelect>(route, params);

const getFeatureById = (id: ID): Promise<Feature> =>
	Crud.getOne<Feature>('store/feature/{id}', id as number);

const getFeaturesByIdStore = (
	id: ID
): Promise<HttpGetListResult<FeatureForSort>> =>
	Crud.getList<FeatureForSort>(`store/${id}/feature`, {});

// const getFeaturesByIdStore = (id: ID, config?: AxiosRequestConfig): Promise<HttpGetListResult<FeatureForSort>> =>
// 	Crud.get<HttpGetListResult<FeatureForSort>>(`store/${id}/feature`, undefined, undefined, config);

const createOrUpdateFeatures = (
	id: ID | null,
	data: Feature
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('store/feature/{id}', id as number, data);
	}
	return Crud.create('store/feature', data);
};

export default {
	getBanners,
	getBannerById,
	getBannersByIdStore,
	createOrUpdateBanner,
	getForSelect,
	getStore,
	getStores,
	saveStore,
	getFeatures,
	getFeatureById,
	getFeaturesByIdStore,
	createOrUpdateFeatures,
};
