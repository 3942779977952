import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

import * as Yup from 'yup';

import { Card, Select } from 'components';
import { FormikEffect, Input as FormikInput } from 'components/Formik';
import { useToast } from 'hooks';
import storeService from 'services/store.service';
import { Feature, FeatureProduct } from 'types';

import {
	FormCancelButton,
	FormGroupTitle,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import FeatureGrid from './feature/FeatureGrid';
import { GridLayout } from './feature/featureGrid/types';
import FeatureStoreSelect from './feature/FeatureStoreSelect';
import SkeletonForm from './feature/SkeletonForm';
import { FeaturesFormType } from './feature/types';

const types = [
	{ label: '1x4x4', value: '1x4x4' },
	{ label: '4x1x4', value: '4x1x4' },
	{ label: '4x4x1', value: '4x4x1' },
	{ label: '4x4x4', value: '4x4x4' },
	{ label: '4x1x1', value: '4x1x1' },
	{ label: '1x4x1', value: '1x4x1' },
	{ label: '1x1x4', value: '1x1x4' },
	{ label: '1x1x1', value: '1x1x1' },
];

export const defaultFormValue: FeaturesFormType = {
	idFeature: null,
	name: '',
	description: '',
	textLink: '',
	link: '',
	type: '4x1x4',
	store: [],
	product: [],
	status: '',
};

export const formSchema = Yup.object<FeaturesFormType>().shape({
	name: Yup.string().trim().required('Campo obrigatório'),
	description: Yup.string().trim().required('Campo obrigatório'),
	textLink: Yup.string().trim().required('Campo obrigatório'),
	link: Yup.string().trim().required('Campo obrigatório'),
	type: Yup.string().required('Campo obrigatório'),
	store: Yup.array(),
	product: Yup.array()
		.of<FeatureProduct>(
			Yup.object().required().shape({
				idImage: Yup.string(),
				idProduct: Yup.string(),
				size: Yup.string(),
				sort: Yup.string(),
			})
		)
		.required('É necessário escolher pelo menos um produto no grid'),
});

const StoreFeatureEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();
	const [type, setType] = useState<GridLayout>(
		defaultFormValue.type! as GridLayout
	);

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';
			const feature: FeaturesFormType = isCreating
				? defaultFormValue
				: await storeService.getFeatureById(Number(id));

			setType(feature.type! as GridLayout);

			return { feature };
		},
		[]
	);

	const handleSubmit = useCallback(
		async (id: string, values: FeaturesFormType, { setSubmitting }) => {
			const isCreating = id === 'create';
			const { store } = values;

			let submit = {
				...values,
				product: values.product.map((p) => ({
					...p,
					idImage:
						/image\/(?<idImage>[0-9]+)/.exec(p.idImage.toString())?.groups
							?.idImage ?? null,
				})),
				type,
			} as Feature;

			if (isCreating) {
				submit = {
					...submit,
					store: [],
				};
			}

			try {
				const response = await storeService.createOrUpdateFeatures(
					(isCreating && null) || Number(id),
					submit
				);

				// Atualiza o registro com as stores
				if (isCreating) {
					const idFeature = (response.data as { id: number }).id;
					submit = {
						...submit,
						store: store.map((s) => ({
							...s,
							feature: s.feature.map((f) =>
								f.idFeature !== -1
									? f
									: {
											...f,
											idFeature,
									  }
							),
						})),
					};
					await storeService.createOrUpdateFeatures(idFeature, submit);
				}
				toast(`Destaques ${isCreating ? 'criados' : 'alterados'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/stores/features');
			} catch (e) {
				toast(`Não foi possível salvar os dados. Cód: ${e.code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[toast, history, type]
	);

	return (
		<PageLayoutFormWrapper
			title="Destaques"
			breadcrumb={[
				{ label: 'Lojas', path: '/admin/stores/features' },
				{ label: 'Destaques', path: '/admin/stores/features' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.feature}
					validationSchema={formSchema}
				>
					{(form: FormikProps<FeaturesFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Row>
									<Col md={8} className="pr-0">
										<Card.Content>
											<Form.Row>
												<Form.Group as={Col} sm={7} md={5} xl={3}>
													<Form.Label>Tipo</Form.Label>
													<Select
														name="type"
														options={types}
														optionLabel="label"
														onChange={(opt) => setType(opt.value)}
														defaultValue={types.filter((t) => t.value === type)}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} md={6}>
													<Form.Label>Título</Form.Label>
													<FormikInput name="name" />
												</Form.Group>
												<Form.Group as={Col} md={6}>
													<Form.Label>Nome Link</Form.Label>
													<FormikInput name="textLink" />
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} md={6}>
													<Form.Label>Descrição</Form.Label>
													<FormikInput name="description" />
												</Form.Group>
												<Form.Group as={Col} md={6}>
													<Form.Label>URL</Form.Label>
													<FormikInput name="link" />
												</Form.Group>
											</Form.Row>
											<Form.Row className="mt-3">
												<FeatureGrid name="product" gridLayout={type} />
											</Form.Row>
										</Card.Content>
									</Col>

									<Col md={4} className="d-flex border-left w-100 pl-0">
										<Card.Content width={100}>
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<FormGroupTitle>Exibir nas lojas:</FormGroupTitle>
													<FeatureStoreSelect
														name="store"
														newFeatureName={form.values.name}
													/>
												</Form.Group>
											</Form.Row>
										</Card.Content>
									</Col>
								</Row>
								<Form.Row />
								<Card.Content className="text-right border-top">
									<Form.Row>
										<Col>
											<FormCancelButton to="/admin/stores/features" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default StoreFeatureEdit;
