import { EcommerceState } from './types';

type Store = { ecommerce: EcommerceState };

const getStore = ({ ecommerce }: Store) => ecommerce;

const getStoreConfig = ({ ecommerce }: Store) => ecommerce.config;

const isLoading = ({ ecommerce }: Store) => ecommerce.isLoading;

const getError = ({ ecommerce }: Store) => ecommerce.error;

const isCartVisible = ({ ecommerce }: Store) => ecommerce.isCartVisible;

export default {
	getStore,
	getStoreConfig,
	isLoading,
	getError,
	isCartVisible,
};
