export class TicketStatusConsts {
	public static Open = 1;

	public static InAnalisys = 2;

	public static InAttending = 3;

	public static Waiting = 4;

	public static Reopend = 5;

	public static WaitingForAssistance = 6;

	public static Done = 10;

	public static Canceled = 15;
}
