import React from 'react';

import {
	faBullseyePointer,
	faNewspaper,
	faSignalStream,
	faBell,
} from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from '../List';
import listConfigs from '../list/list.routes';
import { ListSceneConfig } from '../list/types';

import CampaignEdit from './Campaign';
import NewsNotificationEdit from './NewsNotificationEdit';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const CommRoutes: RouteDef = {
	path: '/admin/communication',
	menu: {
		name: 'Comunicação',
		icon: faBullseyePointer,
	},
	children: [
		{
			path: '/news',
			menu: {
				name: 'Notícias',
				icon: faNewspaper,
			},
			render: getListSceneRenderFn(listConfigs.CommunicationNews),
			exact: true,
		},
		{
			path: '/notifications',
			menu: {
				name: 'Notificações',
				icon: faBell,
			},
			render: getListSceneRenderFn(listConfigs.CommunicationNotif),
			exact: true,
		},
		{
			path: '/campaigns',
			menu: {
				name: 'Campanhas',
				icon: faSignalStream,
			},
			render: getListSceneRenderFn(listConfigs.CommunicationCampaign),
			exact: true,
		},
		{
			path: '/campaigns/:id',
			component: CampaignEdit,
			exact: true,
		},
		{
			path: '/:page/:id',
			component: NewsNotificationEdit,
			exact: true,
		},
	],
};

export default CommRoutes;
