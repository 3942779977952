import React from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styled from 'styled-components';

import { faCalendarAlt, faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ColorVariant } from 'components/calendar/types';

export type DateTimeToggleProps = {
	isDateSelected: boolean;
	onToggle: (dateVisible: boolean) => void;
	color?: ColorVariant;
};

const StyledButtonGroup = styled(ButtonGroup)`
	width: 70%;
	> .btn.active {
		background-color: var(--primary) !important;
	}
`;

const DateTimeToggle: React.FC<DateTimeToggleProps> = ({
	isDateSelected,
	onToggle,
	color,
}) => {
	const variant = color || 'outline-primary';

	return (
		<div className="w-100 text-center">
			<StyledButtonGroup>
				<Button
					className={classnames(
						'justify-content-center',
						isDateSelected && 'active'
					)}
					variant={variant}
					onClick={() => onToggle(true)}
				>
					<FAIcon icon={faCalendarAlt} className="mr-2" /> Data
				</Button>
				<Button
					className={classnames(
						'justify-content-center',
						!isDateSelected && 'active'
					)}
					variant={variant}
					onClick={() => onToggle(false)}
				>
					<FAIcon icon={faClock} className="mr-2" /> Hora
				</Button>
			</StyledButtonGroup>
		</div>
	);
};

export default React.memo(DateTimeToggle);
