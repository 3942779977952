/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';
import { ArrayQueryKey, useQuery } from 'react-query';

import Axios from 'axios';
import echarts, { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';

import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { NumbersByState } from 'types';
import { numberFormatter } from 'utils/formatters';

import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import { TableStyled } from './executiveRegion/styles';

type RegionsDataType = {
	name: string;
	uf: string;
	active: number;
	pending: number;
};

type RegionsType = {
	[name: string]: {
		active: number;
		pending: number;
	};
};

const regionsDataDefault: RegionsDataType[] = [
	{ name: 'Norte', uf: 'RO', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'AC', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'AM', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'RR', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'PA', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'TO', active: 0, pending: 0 },
	{ name: 'Norte', uf: 'AP', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'MA', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'PI', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'BA', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'CE', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'RN', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'PB', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'PE', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'AL', active: 0, pending: 0 },
	{ name: 'Nordeste', uf: 'SE', active: 0, pending: 0 },
	{ name: 'Sudeste', uf: 'MG', active: 0, pending: 0 },
	{ name: 'Sudeste', uf: 'ES', active: 0, pending: 0 },
	{ name: 'Sudeste', uf: 'SP', active: 0, pending: 0 },
	{ name: 'Sudeste', uf: 'RJ', active: 0, pending: 0 },
	{ name: 'Sul', uf: 'PR', active: 0, pending: 0 },
	{ name: 'Sul', uf: 'SC', active: 0, pending: 0 },
	{ name: 'Sul', uf: 'RS', active: 0, pending: 0 },
	{ name: 'Centroeste', uf: 'MT', active: 0, pending: 0 },
	{ name: 'Centroeste', uf: 'MS', active: 0, pending: 0 },
	{ name: 'Centroeste', uf: 'GO', active: 0, pending: 0 },
	{ name: 'Centroeste', uf: 'DF', active: 0, pending: 0 },
];

const regionsTypeDefault: RegionsType = {
	Norte: { active: 0, pending: 0 },
	Nordeste: { active: 0, pending: 0 },
	Sudeste: { active: 0, pending: 0 },
	Sul: { active: 0, pending: 0 },
	Centroeste: { active: 0, pending: 0 },
};

const getChartMapOption = (dataMap: NumbersByState[] | null) => {
	const states = [
		'AC',
		'AM',
		'AL',
		'AP',
		'BA',
		'CE',
		'DF',
		'ES',
		'GO',
		'MA',
		'MS',
		'MG',
		'MT',
		'PA',
		'PB',
		'PI',
		'PE',
		'PR',
		'RJ',
		'RN',
		'RO',
		'RR',
		'RS',
		'SC',
		'SE',
		'SP',
		'TO',
	];

	let maxScale = 0;
	let minScale = 999999;

	dataMap?.forEach((d) => {
		maxScale = Math.max(maxScale, +d.totalRegister + +d.totalActive);
		minScale = Math.min(minScale, +d.totalRegister + +d.totalActive);
	});

	const option: EChartOption = {
		tooltip: {
			formatter: (params: any) => {
				let ret = '';
				const stat = dataMap && dataMap.find((c) => c.uf === params.data.name);
				if (!stat) {
					return `${params.marker} ${params.data.name}: ${params.data.value}`;
				}

				ret = `<strong>${stat.uf}</strong>`;
				ret += `<br />Total de Cadastros: ${numberFormatter.format(
					stat.totalRegister
				)}`;
				ret += `<br />Total de Ativos: ${numberFormatter.format(
					stat.totalActive
				)}`;
				ret += `<br />Cadastros no Dia: ${numberFormatter.format(
					stat.todayRegister
				)}`;
				ret += `<br />Ativações no Dia: ${numberFormatter.format(
					stat.todayActivation
				)}`;
				return ret;
			},
		},
		legend: {
			orient: 'vertical',
			left: 'left',
			data: [
				'Total de Cadastros',
				'Total de Ativos',
				'Cadastros no Dia',
				'Ativações no Dia',
			],
		},
		visualMap: [
			{
				min: minScale,
				max: maxScale,
				text: ['Máx', 'Min'],
				realtime: false,
				calculable: false,
			},
		],
		color: ['#c23531', '#2f4554', '#1ce1ac'],
		series: [
			{
				name: 'Total de Cadastros',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.totalRegister,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
			{
				name: 'Total de Ativos',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.totalActive,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
			{
				name: 'Cadastros no Dia',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.todayRegister,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
			{
				name: 'Ativações no Dia',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.todayActivation,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
		],
	};

	return option;
};

const ExecutiveRegion: React.FC<{ className: string }> = ({ ...rest }) => {
	const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
	const [regionsData, setRegionsData] = React.useState<RegionsDataType[]>(
		regionsDataDefault
	);
	const [regions, setRegions] = React.useState<RegionsType>(regionsTypeDefault);
	const [toast] = useToast();

	const onSetRegions = useCallback(
		(item: NumbersByState) => {
			const index = regionsData.findIndex((region) => region.uf === item.uf);

			const newRegions = [...regionsData];
			newRegions[index].active = Number(item.totalActive);
			newRegions[index].pending = Number(item.totalRegister);

			setRegionsData(newRegions);
		},
		[regionsData]
	);

	const dataByRegions = useCallback(() => {
		const newRegions = regions;

		regionsData.forEach((region) => {
			const byRegions = regionsData.filter((reg) => reg.name === region.name);
			const dataType = byRegions.map((reg) => ({
				active: reg.active,
				pending: reg.pending,
			}));
			const summed = dataType.reduce((total, current) => ({
				active: total.active + current.active,
				pending: total.pending + current.pending,
			}));

			newRegions[region.name] = {
				active: summed.active,
				pending: summed.pending,
			};
		});

		setRegions(newRegions);
	}, [regions, regionsData]);

	const { data: numbersByState, isLoading: isNumbersByStateLoading } = useQuery<
		NumbersByState[],
		ArrayQueryKey
	>(
		['userStatusByState_list'],
		() =>
			executiveService.getNumbersByState({}).then((result) => {
				result.data.map((item) => {
					onSetRegions(item);
					return item;
				});
				dataByRegions();
				return result.data;
			}),
		{
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	useEffect(() => {
		Axios.get('/assets/geo/brazil.json').then((json) => {
			setMapLoaded(true);

			const br = { ...json.data };
			br.features.forEach((feat: { geometry: { coordinates: any[] } }) => {
				feat.geometry.coordinates.forEach((poly: any[]) => {
					poly.forEach((coord) => {
						// eslint-disable-next-line no-param-reassign
						coord[1] *= 0.8;
					});
				});
			});

			echarts.registerMap('brazil', br);
		});
	}, []);

	return (
		<Card {...rest}>
			<Card.Body>
				<FormGroupTitle>Executivos x Região</FormGroupTitle>

				<Row>
					<Col md={6}>
						<TableStyled>
							<thead>
								<tr>
									<th> </th>
									<th className="text-center">Cadastrados</th>
									<th className="text-center">Ativos</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="border-right">Norte</td>
									<td className="text-center">
										{numberFormatter.format(regions.Norte.pending)}
									</td>
									<td className="text-center">
										{numberFormatter.format(regions.Norte.active)}
									</td>
								</tr>
								<tr>
									<td className="border-right border-bottom">Sul</td>
									<td className="text-center border-bottom">
										{numberFormatter.format(regions.Sul.pending)}
									</td>
									<td className="text-center border-bottom">
										{numberFormatter.format(regions.Sul.active)}
									</td>
								</tr>
								<tr>
									<td className="border-right">Leste</td>
									<td className="text-center">
										{numberFormatter.format(regions.Sudeste.pending)}
									</td>
									<td className="text-center">
										{numberFormatter.format(regions.Sudeste.active)}
									</td>
								</tr>
								<tr>
									<td className="border-right border-bottom">Oeste</td>
									<td className="text-center border-bottom">
										{numberFormatter.format(regions.Nordeste.pending)}
									</td>
									<td className="text-center border-bottom">
										{numberFormatter.format(regions.Nordeste.active)}
									</td>
								</tr>
								<tr>
									<td className="border-right">Centroeste</td>
									<td className="text-center">
										{numberFormatter.format(regions.Centroeste.pending)}
									</td>
									<td className="text-center">
										{numberFormatter.format(regions.Centroeste.active)}
									</td>
								</tr>
							</tbody>
						</TableStyled>
					</Col>

					<Col md={6}>
						{isNumbersByStateLoading && mapLoaded ? (
							<div style={{ minHeight: '355px' }}>
								<Skeleton width="98%" height="27rem" />
							</div>
						) : (
							<ReactEcharts
								option={getChartMapOption(numbersByState || null)}
								style={{ minHeight: '355px' }}
							/>
						)}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ExecutiveRegion;
