/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export type AspectRatioProps = {
	aspect?: number | 'fill';
	minHeight?: number | string;
};

const AspectRatio = styled.div<AspectRatioProps>`
	position: relative;

	min-height: ${({ minHeight }) =>
		minHeight
			? typeof minHeight === 'string'
				? minHeight
				: `${minHeight}px`
			: 'unset'};

	${({ aspect }) =>
		aspect === 'fill' &&
		css`
			width: 100%;
			height: 100%;
		`}

	&::before {
		content: '';
		display: block;
		height: 0;
		padding-bottom: ${({ aspect = 4.0 / 3.0 }) =>
			typeof aspect === 'number' ? `${(1.0 / aspect) * 100}%` : 'unset'};
	}

	& > * {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
`;

AspectRatio.displayName = 'AspectRatio';

export default AspectRatio;
