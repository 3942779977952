import { faPallet } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import ExpeditionPage from './Expedition';

const ExpeditionRoutes: RouteDef = {
	path: '/admin/expedition',
	component: ExpeditionPage,
	exact: true,
	menu: {
		name: 'Expedição',
		icon: faPallet,
	},
};

export default ExpeditionRoutes;
