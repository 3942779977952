import { Button, Container as ContainerBootstrap } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { ColorVariant } from 'components/Background';

type ContainerProps = {
	expand: boolean;
	scroll: boolean;
};

type TextProps = {
	size?: number;
	weight?: number | 'bold' | 'bolder' | 'lighter' | 'normal' | 'inherit';
};

type StatementProps = {
	variant?: ColorVariant;
};

type StatementFooterProps = {
	show: boolean;
	maxHeight: number;
};

export const Container = styled(ContainerBootstrap)<ContainerProps>`
	${({ expand }) => expand && 'flex-grow: 1;'}
	${({ scroll }) => scroll && 'overflow: scroll;'};
`;

export const ContentBox = styled.div`
	height: calc(100vh - var(--navbar-height));
`;

export const ExpandScrollBox = styled.div`
	height: 0;
	flex-grow: 1;
	overflow: auto;
`;

export const Text = styled.span<TextProps>`
	${({ size, weight }) => css`
		font-size: ${size ?? 1}rem;
		font-weight: ${weight ?? 'inherit'};
	`}
`;

export const IconButton = styled(Button)`
	width: 1.9rem;
	height: 1.9rem;
	padding: 0.2rem;

	> svg {
		width: 100% !important;
		height: 100% !important;
		color: var(--always-white);
	}
`;

export const StatusWrapper = styled.div`
	width: 4.7rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StatementBody = styled.div`
	width: 100%;
	padding: 1rem;

	span {
		white-space: pre-line;
	}
`;

const StatementFooterPad = styled.div`
	padding: 1rem;
`;

const StatementFooter = styled.div<StatementFooterProps>`
	box-sizing: border-box;
	max-height: 0;

	background: var(--gray-10);
	border-top: solid 1px var(--gray-30);
	overflow: hidden;
	transition: all 0.35s;

	${({ show, maxHeight }) =>
		show &&
		css`
			max-height: ${maxHeight}rem;
		`}

	> ${StatementFooterPad} {
		display: flex;
		justify-content: flex-end;

		> :not(:first-child) {
			margin-left: 1rem;
		}
	}
`;

const StyledStatement = styled.div<StatementProps>`
	width: 100%;
	margin-bottom: 1rem;

	background: var(--always-white);
	box-shadow: var(--base-box-shadow);

	${({ variant }) =>
		variant &&
		css`
			border-top: 2px solid var(--${variant});
		`}
`;

export const Statement = Object.assign(StyledStatement, {
	Body: StatementBody,
	Footer: Object.assign(StatementFooter, { Pad: StatementFooterPad }),
});
