import Crud from 'services/crud.service';
import {
	Executive,
	HttpGetListParams,
	ExecutiveLightForSelect,
	HttpGetListResult,
	ID,
	DistributorUser,
	HttpPostResultAny,
	ExecutiveCredit,
	ExecutiveTotalSpentInStore,
	ExecutiveSubscription,
	Product,
	StoreDistribProduct,
	DistributorStock,
	DistributorCreditInfo,
	ExecutiveClubOrderUpdate,
	ExecutiveNumbers,
	ExecutiveList,
	ExecutiveSignatureStatus,
	ElectronicSignEntry,
	NumbersByState,
	ExecutiveDetails,
	HttpBaseResult,
	HttpPostResult,
	ExecutiveOrder,
	ExecutiveRegister,
} from 'types';

/**
 * Service de requisição de dados dos executivos
 */

const getRegister = (): Promise<ExecutiveRegister> =>
	Crud.getOne<ExecutiveRegister>('auth/register', null);

const getExecutives = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveLightForSelect>> =>
	Crud.getList<ExecutiveLightForSelect>('reports/executiveView', params);

const getForSelect = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveLightForSelect>> =>
	Crud.getList<ExecutiveLightForSelect>('allExecutive', params);

const getAllExecutiveById = (id: ID): Promise<ExecutiveDetails> =>
	Crud.getOne('allExecutive/details/{id}', id);

const getNetworkForSelect = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveLightForSelect>> =>
	Crud.getList<ExecutiveLightForSelect>('executive/{id}/netlight', params);

const getLatestRegister = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveList>> =>
	Crud.getList<ExecutiveList>(
		'executive/latest?paginate={paginate}&page={page}&days={days}',
		params
	);

const getExecutiveNumbers = (): Promise<ExecutiveNumbers> =>
	Crud.getOne<ExecutiveNumbers>('executive/numbers', 0);

// TODO: Rota executive/{id} não funciona. Poderia ser executive/findById/{id},
// porém o tipo é diferente e pode quebrar o funcionamento de outras telas
const getById = (id: ID): Promise<Executive> =>
	Crud.getOne('executive/{id}', id);

// TODO: Rotas POST executive e UPDATE executive/{id} não implementadas
const createOrUpdate = (
	id: ID | null,
	data: Executive
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('executive/{id}', id as number, data);
	}
	return Crud.create('executive', data);
};

const getFindBy = (
	by: 'Id' | 'Cpf' | 'Name' | 'Email' | 'Phone',
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveList>> => {
	let route = '';

	if (by === 'Name') {
		route = 'executive/findByName?name={name}';
	} else if (by === 'Email') {
		route = 'executive/findByEmail?email={email}';
	} else {
		route = `executive/findBy${by}/{param}`;
	}

	return Crud.getList<ExecutiveList>(route, params).then(
		(result): HttpGetListResult<ExecutiveList> => {
			if (Array.isArray(result.data)) {
				return result;
			}
			return { data: [result.data], message: '', code: 0 };
		}
	);
};

const findById = (id: ID): Promise<ExecutiveLightForSelect> =>
	Crud.getOne('allExecutive/findById/{id}', id as number);

const getDistributorUserById = (id: ID): Promise<DistributorUser> =>
	Crud.getOne('distributor/user/{id}', id);

const getCredit = (id: ID): Promise<number> =>
	Crud.getOne<ExecutiveCredit>('executive/{id}/balance', id).then(
		(result) => result.ammount
	);

const getSignatureStatus = (): Promise<ExecutiveSignatureStatus> =>
	Crud.getOne<ExecutiveSignatureStatus>('my/signature', 0);

const updateSignature = (data: ElectronicSignEntry): Promise<HttpBaseResult> =>
	Crud.update<ElectronicSignEntry>('my/signature', 0, data);

const sendNewSignature = (): Promise<
	HttpPostResult<ExecutiveSignatureStatus>
> => Crud.create('my/signature', null);

const updateClubOrder = (
	idOrder: ID,
	data: ExecutiveClubOrderUpdate
): Promise<HttpPostResultAny> =>
	Crud.update<ExecutiveClubOrderUpdate>(
		'club/order/{id}',
		idOrder as number,
		data
	);

const getTotalSpentInStore = (id: ID): Promise<number> =>
	Crud.getOne<ExecutiveTotalSpentInStore>(
		'executive/{id}/totalOnStore',
		id
	).then((result) => result.total);

const getScoreByMonthYear = (params: HttpGetListParams): Promise<unknown> =>
	Crud.getList('reports/scoreView/{id}?month={month}&year={year}', params);

const getSubscriptions = (actives = true): Promise<ExecutiveSubscription[]> =>
	Crud.getListData<ExecutiveSubscription>('subscription', {
		query: actives ? ['"active": 1'] : undefined,
	});

const getSubscriptionProducs = (id: ID): Promise<Product[]> =>
	Crud.getListData<Product>(`subscription/${id}/product`, {});

const getDistributorUserStore = (
	params: HttpGetListParams
): Promise<StoreDistribProduct[]> =>
	Crud.getListData<StoreDistribProduct>('distributor/{id}/store', params);

const getDistributorStock = (
	params: HttpGetListParams
): Promise<DistributorStock[]> =>
	Crud.getListData<DistributorStock>('distributor/{id}/stock', params);

const getDistributorCredit = (id: ID): Promise<DistributorCreditInfo> =>
	Crud.getOne<DistributorCreditInfo>('distributor/{id}/creditinfo', id);

const getDistributorClubCredit = (id: ID): Promise<DistributorCreditInfo> =>
	Crud.getOne<DistributorCreditInfo>('distributor/{id}/clubinfo', id);

const changeUserSubscription = (
	id: ID,
	idSubscription: number
): Promise<HttpPostResultAny> =>
	Crud.patch<{ idSubscription: number }>('executive/{id}', id, {
		idSubscription,
	});

const getNumbersByState = (
	params: HttpGetListParams
): Promise<HttpGetListResult<NumbersByState>> =>
	Crud.getList<NumbersByState>('executive/numbersByState', params);

const getOrderForPerson = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveOrder>> =>
	Crud.getList<ExecutiveOrder>('executive/{id}/order', params);

export default {
	getRegister,
	getExecutives,
	getForSelect,
	getNetworkForSelect,
	getLatestRegister,
	getExecutiveNumbers,
	getFindBy,
	getAllExecutiveById,
	findById,
	getById,
	createOrUpdate,
	getDistributorUserById,
	getCredit,
	getSignatureStatus,
	updateSignature,
	sendNewSignature,
	updateClubOrder,
	getTotalSpentInStore,
	getScoreByMonthYear,
	getSubscriptions,
	getSubscriptionProducs,
	getDistributorUserStore,
	getDistributorStock,
	getDistributorCredit,
	getDistributorClubCredit,
	changeUserSubscription,
	getNumbersByState,
	getOrderForPerson,
};
