import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useField } from 'formik';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ArrayQueryKey, useQuery } from 'react-query';

import { faListOl, faStream } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Button, Checkbox, Input, List, Skeleton } from 'components';
import { useToast } from 'hooks';
import storeService from 'services/store.service';
import { BannerForSort, BannerStore, StoreForSelect, UrlParams } from 'types';

import { FormGroupTitle } from 'packages/admin/components';

import {
	StoreItemContainer,
	ListPopoverWrapper,
	InputWrapper,
	StoreContainer,
} from './bannerStoreSelect/styles';

type BannerStoreSelectProps = {
	name: string;
	newBannerName?: string;
};

type Cache = BannerStore;

type Error = {
	banner: { url: string }[];
}[];

const delay = (time: number): Promise<void> =>
	new Promise((resolve) =>
		setTimeout(() => {
			resolve();
		}, time)
	);

const BannerStoreSelect: React.FC<BannerStoreSelectProps> = ({
	name,
	newBannerName,
}) => {
	const [toast] = useToast();
	// const storeRefs = useRef(new Map()).current;
	const { id: UrlId } = useParams<UrlParams>();
	const idBanner = useMemo(() => (UrlId === 'create' ? -1 : Number(UrlId)), [
		UrlId,
	]);

	const [cache, setCache] = useState<Cache[]>([]);
	const [isLoadingCache, setIsLoadingCache] = useState<boolean>(true);
	const [{ value }, { error, touched }, { setValue }] = useField<BannerStore[]>(
		name
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const initialValue = useMemo(() => value, []);

	const { data: storesForSelect, isLoading: loadingStoresForSelect } = useQuery<
		StoreForSelect[],
		ArrayQueryKey
	>(['banner_store'], () => storeService.getForSelect({}).then((r) => r.data), {
		onError: () => {
			toast('Não foi possível carregar as lojas', {
				type: 'error',
			});
		},
	});

	// Carrega e atualiza os banners para o cache
	const loadToCache = useCallback(
		async (values: BannerStore[]): Promise<Cache[]> => {
			if (!storesForSelect) return [];

			setIsLoadingCache(true);
			const cacheAux: Cache[] = [];
			try {
				for await (const store of storesForSelect) {
					const bannerStore = values.find((b) => b.idStore === store.idStore);
					const bannerList =
						bannerStore?.banner ||
						(await storeService
							.getBannersByIdStore(store.idStore)
							.then((r) => r.data)) ||
						[];

					cacheAux.push({
						name: store.name,
						idStore: store.idStore,
						banner: bannerList.filter((b) => b.status === 'Ativo'),
					});
				}
			} catch (e) {
				toast('Não foi possível carregar os banners das lojas', {
					type: 'error',
				});
			}
			setCache(cacheAux);

			await delay(100);
			setIsLoadingCache(false);
			return cacheAux;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storesForSelect]
	);

	useEffect(() => {
		if (initialValue && storesForSelect) {
			loadToCache(initialValue);
		}
	}, [loadToCache, storesForSelect, initialValue]);

	useEffect(() => {
		if (newBannerName) {
			const newValue = value.map((c) => ({
				...c,
				banner: c.banner.map((f) =>
					f.idBanner === idBanner || f.idBanner === -1
						? { ...f, name: newBannerName }
						: f
				),
			}));
			setValue(newValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newBannerName]);

	// Handlers

	const handleChangeItems = useCallback(
		(store: StoreForSelect) => (options: BannerForSort[]) => {
			const option = options.find((o) => o.idBanner === idBanner);

			if (option) {
				// Setando no state
				setCache([
					...(cache?.filter((c) => c.idStore !== store.idStore) ?? []),
					{
						idStore: store.idStore,
						name: store.name,
						banner: options || [],
					},
				]);

				// Setando no form
				const stores = value.map(
					(s): BannerStore =>
						s?.idStore === store.idStore ? { ...s, banner: options } : s
				);
				setValue(stores);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[idBanner, cache, value]
	);

	const handleChangeCheckbox = useCallback(
		(store: StoreForSelect) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const storeInCache = cache.find((s) => s.idStore === store.idStore);
			const otherStores = value.filter((s) => s.idStore !== store.idStore);

			// Ao desmarcar é necessário passar um objeto com idStore preenchido e
			// banner como array sem o banner atual
			if (!event.target.checked) {
				const isInInitialValue = initialValue.some(
					(s) => s.idStore === store.idStore
				);
				const banner =
					storeInCache?.banner.filter((b) => b.idBanner !== idBanner) ?? [];

				setValue([
					...otherStores,
					...(isInInitialValue ? [{ ...store, banner }] : []),
				]);
				return;
			}

			const thisBanner: BannerForSort = {
				idBanner,
				name: newBannerName ?? 'Este Banner', // TODO: verificar qual nome vai aparecer
				sort: 1,
			};

			// Se a store estiver no cache, significa que já existem banners
			// associadas para a store.
			if (storeInCache) {
				const thisBannerInStore = storeInCache.banner.find(
					(b) => b.idBanner === idBanner
				);

				// Se a banner atual estiver no store, somente utiliza store do cache
				if (thisBannerInStore) {
					setValue([...otherStores, storeInCache]);
					return;
				}
				const otherBannersInStore = storeInCache.banner.filter(
					(b) => b.idBanner !== idBanner
				);
				let newStore: BannerStore = {
					...store,
					banner: [thisBanner],
				};

				// Se existirem outras banners no store, adiciona a banner atual na última posição
				if (otherBannersInStore) {
					const lastSort = otherBannersInStore.reduce(
						(prev, next) => (next.sort > prev ? next.sort : prev),
						0
					);
					thisBanner.sort = lastSort + 1;

					newStore = {
						...store,
						banner: [...storeInCache.banner, thisBanner],
					};
				}

				setValue([...otherStores, newStore]);
				return;
			}

			// Se a store não estiver no cache, significa que nenhuma banner existe
			// na store. Neste caso, insere uma newStore com a banner
			const newStore: BannerStore = {
				...store,
				banner: [thisBanner],
			};

			setValue([...otherStores, newStore]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[idBanner, initialValue, cache, value, newBannerName]
	);

	const handleChangeURL = useCallback(
		(storeSelect: StoreForSelect): ((value: string) => void) | undefined => {
			return (url) => {
				const otherStores = value.filter(
					(c) => c.idStore !== storeSelect.idStore
				);
				const thisStore = value.find((c) => c.idStore === storeSelect.idStore);
				const otherBannersInStore = thisStore?.banner.filter(
					(b) => b.idBanner !== idBanner
				);
				const thisBannerInStore = thisStore?.banner.find(
					(b) => b.idBanner === idBanner
				);

				const newValue = [
					...otherStores,
					...(thisStore
						? ([
								{
									...thisStore,
									banner: [
										...(otherBannersInStore ?? []),
										{
											...thisBannerInStore,
											url,
										},
									] as BannerForSort[],
								},
						  ] as Cache[])
						: []),
				];

				setValue(newValue, true);
			};
		},
		[idBanner, setValue, value]
	);

	const handleOnBlurURL = useCallback(
		(
			storeSelect: StoreForSelect,
			storeIndex: number
		): ((event: React.ChangeEvent<Element>) => void) | undefined => {
			return () => {
				setCache([
					...(cache?.filter((c) => c.idStore !== storeSelect.idStore) ?? []),
					{
						idStore: storeSelect.idStore,
						name: storeSelect.name,
						banner: value[storeIndex]?.banner || [],
					},
				]);
			};
		},
		[cache, value]
	);

	const popover = useCallback(
		(store: StoreForSelect) => (
			<ListPopoverWrapper>
				<FormGroupTitle>Reordenar Banners na loja</FormGroupTitle>
				<List
					data={value.find((s) => s.idStore === store.idStore)?.banner ?? []}
					loading={isLoadingCache}
					emptyImage={faStream}
					emptyDescription="Não há nenhum banner adicionado para a loja"
					className="mt-3"
					onChangeItems={handleChangeItems(store)}
					canBeDraggedFilter={(item: BannerForSort) =>
						item.idBanner === idBanner
					}
					hasRemoveButton={false}
					dragInDrop
				>
					{(item: BannerForSort) => (
						<Row className="w-100">
							<Col>
								<span>{item.name}</span>
							</Col>
						</Row>
					)}
				</List>
			</ListPopoverWrapper>
		),
		[idBanner, value, isLoadingCache, handleChangeItems]
	);

	return (
		<>
			<StoreContainer>
				{loadingStoresForSelect || isLoadingCache ? (
					<>
						<Skeleton height="1.8rem" />
						<Skeleton height="1.8rem" />
						<Skeleton height="1.8rem" />
					</>
				) : (
					<>
						{storesForSelect?.map((storeSelect) => {
							const storeIndex = value.findIndex(
								(s) => s.idStore === storeSelect.idStore
							);
							const bannerInStoreIndex = value[storeIndex]?.banner.findIndex(
								(b) => b.idBanner === idBanner
							);
							const bannerInStore =
								value[storeIndex]?.banner[bannerInStoreIndex];

							const inputError =
								error &&
								touched &&
								// error[storeIndex] &&
								// ((error as unknown) as Error)[storeIndex]?.banner &&
								((error as unknown) as Error)[storeIndex]?.banner[
									bannerInStoreIndex
								]?.url;
							return (
								<>
									<StoreItemContainer
										key={storeSelect.idStore}
										// ref={(node) => storeRefs.set(storeSelect.idStore, node)}
										className={inputError ? 'mb-4' : ''}
									>
										<Checkbox
											checked={!!bannerInStore}
											label={storeSelect.name}
											onChange={handleChangeCheckbox(storeSelect)}
										/>
										<InputWrapper>
											<OverlayTrigger
												placement="top"
												overlay={
													<Tooltip id="tooltip-logout">
														Informe a URL para o link deste banner na loja
													</Tooltip>
												}
											>
												<Input
													placeholder="Url"
													value={bannerInStore?.url ?? ''}
													onChange={handleChangeURL(storeSelect)}
													isInvalid={!!inputError}
													disabled={!bannerInStore}
													onBlur={handleOnBlurURL(storeSelect, storeIndex)}
												/>
											</OverlayTrigger>
											{!!inputError && (
												<span className="invalid-feedback d-block">
													{inputError}
												</span>
											)}
										</InputWrapper>
										<OverlayTrigger
											trigger="click"
											placement="bottom"
											// container={storeRefs.get(storeSelect.idStore)}
											overlay={popover(storeSelect)}
											rootClose
										>
											<Button
												variant="link"
												className="ml-3 text-info"
												disabled={!bannerInStore}
											>
												<FAIcon icon={faListOl} />
											</Button>
										</OverlayTrigger>
									</StoreItemContainer>
								</>
							);
						})}
					</>
				)}
			</StoreContainer>
		</>
	);
};

export default BannerStoreSelect;
