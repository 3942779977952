import { useRef, useEffect, useCallback } from 'react';

const useThrottle = <T extends (...args: any[]) => void>(
	fnc: T,
	timeout = 1000
): T => {
	const timer = useRef<NodeJS.Timeout | null>(null);

	const cancel = (): void => {
		if (timer.current !== null) {
			clearTimeout(timer.current);
			timer.current = null;
		}
	};

	useEffect(() => cancel, []);

	return useCallback(
		(...args: any[]) => {
			cancel();

			timer.current = setTimeout(() => {
				timer.current = null;
				fnc(...args);
			}, timeout);
		},
		[timeout, fnc, timer]
	) as T;
};

export default useThrottle;
