import { EcStoreConfig, HttpBaseResult } from 'types';

export enum EcommerceActionEnum {
	StoreRequest = '@ecommerce/STORE_REQUEST',
	StoreFailed = '@ecommerce/STORE_FAILED',
	StoreSuccess = '@ecommerce/STORE_SUCCESS',
	ShowShoppingCart = '@ecommerce/SHOW_SHOPPING_CART',
	HideShoppingCart = '@ecommerce/HIDE_SHOPPING_CART',
}

export type EcommerceState = {
	config: EcStoreConfig;
	isCartVisible: boolean;
	isLoading: boolean;
	error: HttpBaseResult | null;
};

export type EcommerceActionTypes =
	| {
			type: EcommerceActionEnum.StoreRequest;
			payload: {
				store: 'mmn' | 'club' | 'stock';
			};
	  }
	| {
			type: EcommerceActionEnum.StoreSuccess;
			payload: {
				config: EcStoreConfig;
			};
	  }
	| {
			type: EcommerceActionEnum.StoreFailed;
			payload: HttpBaseResult;
	  }
	| {
			type: EcommerceActionEnum.ShowShoppingCart;
	  }
	| {
			type: EcommerceActionEnum.HideShoppingCart;
	  };
