import React from 'react';

import { useField } from 'formik';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import KeyCode from 'rc-util/lib/KeyCode';

import { SwitchProps } from 'components/switch/type';

import * as S from '../switch/styles';

const Switch: React.FC<SwitchProps> = React.memo(
	({
		className,
		disabled = false,
		checkedChildren,
		unCheckedChildren,
		checkedColor,
		unCheckedColor,
		size = 'default',
		name,
		isLoading,
		onChange,
		...restProps
	}) => {
		const [, { initialValue, value }, { setValue }] = useField(name);

		const triggerChange = (newChecked: boolean) => {
			if (!disabled && !isLoading && newChecked !== value) {
				setValue(newChecked);
				if (onChange) {
					onChange(newChecked);
				}
			}
		};

		function onInternalKeyDown(e: React.KeyboardEvent<HTMLButtonElement>) {
			if (e.which === KeyCode.LEFT) {
				triggerChange(false);
			} else if (e.which === KeyCode.RIGHT) {
				triggerChange(true);
			}
		}

		function onInternalClick() {
			triggerChange(!value);
		}

		return (
			<S.Switch
				isChecked={!!value}
				type="button"
				role="switch"
				aria-checked={value}
				disabled={disabled}
				className={className}
				isLoading={isLoading}
				checkedColor={checkedColor}
				unCheckedColor={unCheckedColor}
				onKeyDown={onInternalKeyDown}
				onClick={onInternalClick}
				defaultValue={initialValue}
				size={size}
				{...restProps}
			>
				<div>{isLoading && <FAIcon icon={faSpinnerThird} />}</div>
				<span>{value ? checkedChildren : unCheckedChildren}</span>
			</S.Switch>
		);
	}
);

Switch.displayName = 'Switch';
Switch.whyDidYouRender = true;

export default Switch;
