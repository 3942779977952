import React, { useEffect } from 'react';

import { useField } from 'formik';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { Select } from 'components/Formik';

export type MonthFilterProps = {
	name: string;
	isQuery?: boolean;
};

export const monthOptionsBR = [
	{ name: 'Janeiro', value: 1 },
	{ name: 'Fevereiro', value: 2 },
	{ name: 'Março', value: 3 },
	{ name: 'Abril', value: 4 },
	{ name: 'Maio', value: 5 },
	{ name: 'Junho', value: 6 },
	{ name: 'Julho', value: 7 },
	{ name: 'Agosto', value: 8 },
	{ name: 'Setembro', value: 9 },
	{ name: 'Outubro', value: 10 },
	{ name: 'Novembro', value: 11 },
	{ name: 'Dezembro', value: 12 },
];

export type MonthOption = typeof monthOptionsBR[0];

const MonthSelectFilter: React.FC<MonthFilterProps> = ({ name, isQuery }) => {
	const { onParamChange, onQueryChange } = useDatagridContext();
	const [{ value: fieldValue }] = useField<MonthOption>(name || '');

	// Inicializa com o defaultValue
	useEffect(() => {
		if (fieldValue != null) {
			if (isQuery) {
				onQueryChange(name, String(fieldValue.value));
			} else {
				onParamChange(name, String(fieldValue.value));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (option: MonthOption) => {
		if (isQuery) {
			onQueryChange(name, String(option.value));
		} else {
			onParamChange(name, String(option.value));
		}
	};
	return <Select name={name} options={monthOptionsBR} onChange={onChange} />;
};

export default MonthSelectFilter;
