import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type WidgetIconType = {
	colored?: boolean;
	gradient?: boolean;
	color: string;
};

export const WidgetIcon = styled(FontAwesomeIcon)<WidgetIconType>`
	width: 5rem !important;
	height: 5rem;
	margin: 0 0.8rem;
`;

export const WidgetTitle = styled.div`
	font-size: 1.9rem;
	font-weight: 400;
`;

export const WidgetInfo = styled.div`
	font-size: 1.1.rem;
	font-weight: 500;
	color: var(--gray-60);
`;

export const WidgetColumn = styled.div`
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	* {
		overflow: hidden;
		min-width: min-content;
	}
`;

export const StyledWidget = styled.div`
	position: relative;
	height: calc(100% - 0.8rem);
	margin-bottom: var(--margin-bottom);
	padding: 1rem;

	min-height: 8rem;
	display: flex;
	align-items: center;

	color: var(--input-color);
	background: var(--base-bg);
	border-radius: var(--border-radius);
	box-shadow: var(--base-box-shadow);

	overflow-x: auto;
	::-webkit-scrollbar {
		display: none;
		height: 0;
	}
`;
