import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Skeleton from 'react-loading-skeleton';

import {
	faExclamationTriangle,
	faUsdCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import ElectronicSignModal from 'modals/ElectronicSignModal';
import * as Yup from 'yup';

import { AsyncSelect, Input } from 'components/Formik';
import { useModal, useToast } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import executiveService from 'services/executive.service';
import financialService from 'services/financial.service';
import myService from 'services/my.service';
import {
	ApiCodes,
	TransferAmk,
	HttpBaseResult,
	ExecutiveLightForSelect,
} from 'types';
import { currencyFormatter } from 'utils/formatters';

import { PageHeader, FormSubmitButton } from 'packages/admin/components';

import SkeletonForm from './transfer/SkeletonForm';
import {
	Panel,
	Separator,
	ButtonsContainer,
	AlertMessage,
	ButtonLink,
	AlertIcon,
	NumberAmmount,
	MessageAmmount,
	SkeletonAmmount,
} from './transfer/styles';

const defaultFormValue: TransferAmk = {
	idUser: undefined,
	idOperation: '3',
	idFriend: null,
	ammount: 0,
	signature: '',
	date: new Date(),
	description: '',
};

const Transfer: React.FC = () => {
	const [toast] = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [netError, setNetError] = useState<boolean>(false);
	const [userCredit, setUserCredit] = useState<number | null>(null);
	const [maxValue, setMaxValue] = useState<number>(0.0);
	const [disableTransfer, setDisableTransfer] = useState<boolean>(true);
	const open = useModal();
	const {
		auth: { person },
	} = useSessionContext();

	const validationSchema = useMemo(() => {
		return Yup.object<TransferAmk>({
			idUser: Yup.object<ExecutiveLightForSelect>()
				.required('Campo obrigatório')
				.test(
					'idUser',
					'Executivo não pode receber transferências',
					(value) => (value?.idPerson ?? 0) !== 4
				),
			ammount: Yup.number()
				.required('Campo obrigatório')
				.min(0.01, 'Valor deve ser maior que 0,00')
				.max(
					maxValue,
					`Valor deve ser menor que ${currencyFormatter.format(maxValue)}`
				),
			idFriend: Yup.mixed(),
			date: Yup.mixed(),
			idOperation: Yup.mixed(),
			signature: Yup.mixed(),
			description: Yup.mixed(),
		});
	}, [maxValue]);

	const updateUserCredit = useCallback(async () => {
		try {
			const result = await myService.getBalance();
			setIsLoading(false);
			setUserCredit(result);
			setMaxValue(result);
			setDisableTransfer(result <= 0);
			setNetError(false);
		} catch (e) {
			const { message } = e as HttpBaseResult;
			setIsLoading(false);
			// eslint-disable-next-line no-console
			console.log('Error message: ', message);
			toast('Erro interno: Ocorreu um erro ao obter o saldo AMK.', {
				type: 'error',
			});
			setUserCredit(null);
			setMaxValue(0);
			setDisableTransfer(true);
			// FIX ME: arrumar um jeito de pegar erro de internet
			setNetError(message === 'Sem resposta do servidor');
		}
	}, [toast]);

	const handleSubmit = async (
		form: TransferAmk,
		helpers: FormikHelpers<TransferAmk>
	) => {
		helpers.setSubmitting(true);
		const submit = form;

		open(
			ElectronicSignModal,
			{
				message: (
					<p>
						Digite sua Assinatura Eletrônica para confirmar a transferência de{' '}
						<strong>{currencyFormatter.format(submit.ammount)}</strong> para{' '}
						<strong>
							{typeof submit.idUser === 'object'
								? `${submit.idUser.idPerson} - ${submit.idUser.name}`
								: ''}
						</strong>
					</p>
				),
			},
			async (signature?: unknown) => {
				if (!signature) {
					helpers.setSubmitting(false);
					return;
				}

				submit.signature = String(signature);
				if (typeof submit.idUser === 'object' && submit.idUser) {
					submit.idUser = submit.idUser.idPerson;
				}
				submit.idFriend = Number(person!.idUser);

				try {
					const { message } = await financialService.createTransfer(submit);
					// eslint-disable-next-line no-console
					console.log('Success: ', message);
					helpers.resetForm();
					updateUserCredit();
					toast('Transferência realizada com sucesso!', {
						type: 'success',
					});
				} catch (e) {
					const { code, message } = e as HttpBaseResult;
					// eslint-disable-next-line no-console
					console.log('Error message: ', message);
					toast(ApiCodes.getCodeString(code), {
						type: 'error',
					});
				}

				helpers.setSubmitting(false);
			}
		);
	};

	const onClickUpdate = useCallback(() => {
		setIsLoading(true);
		updateUserCredit();
	}, [updateUserCredit]);

	useEffect(() => {
		updateUserCredit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>Transferir AMKs</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Financeiro', path: '/app/financial/summary' },
						{
							label: 'Transferir AMKs',
							path: '/app/financial/transfer',
							active: true,
						},
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<SkeletonForm />
			) : (
				<Card>
					<Card.Body className="offset-lg-3 col-lg-6">
						{netError ? (
							<Panel warning>
								<AlertMessage>
									<AlertIcon icon={faExclamationTriangle} size="3x" />
									<br />
									Falha na conexão com a internet.
									<br />
									<ButtonLink
										type="button"
										variant="link"
										onClick={onClickUpdate}
									>
										<strong>Clique aqui</strong>
									</ButtonLink>{' '}
									para recarregar a página.
								</AlertMessage>
							</Panel>
						) : (
							<>
								<Panel>
									<MessageAmmount textColor>
										Você tem
										{userCredit === null && !netError && (
											<SkeletonAmmount>
												<Skeleton width="100px" height="2rem" />
											</SkeletonAmmount>
										)}
										{userCredit !== null && !netError && (
											<>
												<FAIcon icon={faUsdCircle} />
												<NumberAmmount>
													{currencyFormatter.format(userCredit || 0)}
												</NumberAmmount>{' '}
												disponíveis
											</>
										)}
									</MessageAmmount>
								</Panel>

								{(!userCredit || userCredit <= 0) && disableTransfer && (
									<Panel danger>
										<MessageAmmount>
											Você não tem AMKs disponíveis para transferir
										</MessageAmmount>
									</Panel>
								)}

								<Separator />

								{userCredit !== null && userCredit > 0 && !disableTransfer && (
									<Formik<TransferAmk>
										onSubmit={handleSubmit}
										initialValues={defaultFormValue}
										validationSchema={validationSchema}
									>
										{(form) => (
											<FormikForm>
												<Form.Row>
													<Form.Group as={Col}>
														<Form.Label>Executivo</Form.Label>
														<AsyncSelect
															name="idUser"
															fetchMethod={executiveService.getForSelect}
															disabled={disableTransfer}
															formatOptionLabel={(
																value: ExecutiveLightForSelect
															) => {
																return (
																	<>
																		<b>{value.id}</b> {value.name}
																	</>
																);
															}}
															optionValue="idPerson"
														/>
													</Form.Group>
												</Form.Row>

												<Form.Row>
													<Form.Group as={Col}>
														<Form.Label>Quantidade</Form.Label>
														<Input
															name="ammount"
															type="text"
															disabled={disableTransfer || !form.values.idUser}
															prepend={
																<InputGroup.Text>
																	<FAIcon fixedWidth icon={faUsdCircle} />
																</InputGroup.Text>
															}
															formatter={currencyFormatter}
														/>
													</Form.Group>
												</Form.Row>

												<Separator />

												<ButtonsContainer>
													<FormSubmitButton
														label="Transferir"
														disabled={
															disableTransfer ||
															!form.values.idUser ||
															!form.values.ammount
														}
														isSubmitting={form.isSubmitting}
														noIcon
													/>
												</ButtonsContainer>
											</FormikForm>
										)}
									</Formik>
								)}
							</>
						)}
					</Card.Body>
				</Card>
			)}
		</Container>
	);
};

export default Transfer;
