/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useInfiniteQuery } from 'react-query';

import {
	faCheckCircle,
	faFilter,
	faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Select, Empty } from 'components';
import Skeleton from 'components/Skeleton';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { ExecutiveList, HttpGetListResult } from 'types';
import { coloredStatusFormatter, dateFormatter } from 'utils/formatters';

import FormGroupTitle from 'packages/admin/components/FormGroupTitle';
import { personStatusById } from 'packages/admin/utils/coloredStatus';
import SimpleList from 'packages/escritorio/components/SimpleList';

import { ScrollReplaceContainer, Status } from './executiveLastRegister/styles';

type ElementScrollProps = {
	scrollHeight: number;
	scrollLeft: number;
	scrollTop: number;
	scrollWidth: number;
	clientHeight: number;
	clientWidth: number;
	clientLeft: number;
	clientTop: number;
	offsetHeight: number;
	offsetLeft: number;
	offsetTop: number;
	offsetWidth: number;
};

type FilterSearchProp = { name: string; value: number };

const filterSearch: FilterSearchProp[] = [
	{ name: 'Hoje', value: 0 },
	{ name: 'Últimos 3 dias', value: 3 },
	{ name: 'Últimos 5 dias', value: 5 },
];

const fetchLatestRegister = async (
	_: string,
	paginate: number,
	days = 0,
	page = 1
): Promise<HttpGetListResult<ExecutiveList>> =>
	executiveService.getLatestRegister({
		params: { paginate, page, days },
	});

const statusFormatter = coloredStatusFormatter({
	statusList: personStatusById,
});

const ExecutiveLastRegister: React.FC<{ className?: string }> = ({
	...rest
}) => {
	const [toast] = useToast();
	const [perPage] = useState<number>(10);
	const [dateSelected, setDateSelected] = useState<FilterSearchProp>(
		filterSearch[0]
	);

	const {
		data: dataResult,
		isFetchingMore,
		fetchMore,
		canFetchMore,
		isLoading,
		isError,
	} = useInfiniteQuery<HttpGetListResult<ExecutiveList>>(
		['executive_executiveLastRegister', perPage, dateSelected.value],
		fetchLatestRegister,
		{
			getFetchMore: ({ meta }) =>
				meta?.last_page === meta?.current_page
					? false
					: (meta?.current_page || 0) + 1,
			onError: (error) => {
				// eslint-disable-next-line no-console
				console.log({ error });
				const errorMsg =
					'Erro interno: Ocorreu um erro ao obter os Últimos Executivos Cadastrados.';
				toast(errorMsg, { type: 'error' });
			},
		}
	);
	const allItemsLength: number =
		dataResult?.map((r) => r.data).flat().length || 0;

	const onSelectChange = useCallback((selected) => {
		setDateSelected(selected);
	}, []);

	const handleScrollBottom = (e: React.ChangeEvent<ElementScrollProps>) => {
		if (!canFetchMore || isFetchingMore) return;

		const bottomPosition = Math.round(
			e.target.scrollHeight - e.target.scrollTop
		);

		const bottom =
			bottomPosition === e.target.clientHeight ||
			bottomPosition === e.target.clientHeight + 1;

		if (bottom) {
			fetchMore();
		}
	};

	useEffect(() => {
		fetchMore();
	}, [dateSelected, fetchMore]);

	return (
		<Card {...rest}>
			<Card.Body>
				<FormGroupTitle>Últimos cadastros</FormGroupTitle>

				<Form.Row>
					<Form.Group as={Col} md={12} className="d-flex align-items-center">
						<FAIcon icon={faFilter} className="mr-2" />
						<Select
							options={filterSearch}
							optionLabel="name"
							optionValue="value"
							defaultValue={dateSelected}
							onChange={onSelectChange}
							isDisabled={isLoading}
						/>
					</Form.Group>
				</Form.Row>

				<Row>
					<ScrollReplaceContainer fluid onScroll={handleScrollBottom}>
						{isLoading ? (
							<SimpleList>
								<SimpleList.Header>
									<SimpleList.Title>
										<Skeleton width="10ch" height="1.2rem" />
									</SimpleList.Title>
									<SimpleList.Title>
										<Skeleton width="10ch" height="1.2rem" />
									</SimpleList.Title>
								</SimpleList.Header>
								<SimpleList.Item>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</SimpleList.Item>
							</SimpleList>
						) : (
							<>
								{isError ? (
									<Empty
										description="Não foi possível carregar a lista dos Últimos executivos cadastrados"
										image={
											<FAIcon icon={faTimesCircle} className="text-danger" />
										}
									/>
								) : allItemsLength === 0 ? (
									<Empty
										description={
											<span>
												Nenhum executivo cadastrado para o filtro{' '}
												<strong>{dateSelected.name}</strong>
											</span>
										}
										image={
											<FAIcon icon={faCheckCircle} className="text-info" />
										}
									/>
								) : (
									dataResult?.map(({ data, meta }) => (
										<React.Fragment key={meta?.current_page ?? 0}>
											{data.map((item) => (
												<SimpleList key={item.idPerson}>
													<SimpleList.Header>
														<SimpleList.Title>
															<strong>{item.idPerson}</strong> - {item.name}
														</SimpleList.Title>
														<SimpleList.Title>
															<Status>
																{statusFormatter.format(item.idStatus)}
															</Status>
														</SimpleList.Title>
													</SimpleList.Header>
													<SimpleList.Item>
														<strong>Patrocinador:</strong> {item.sponsorName}
														<br />
														{item.email}
														<br />
														Cadastrado em{' '}
														<strong>
															{item.dateRegister &&
																dateFormatter.format(item.dateRegister)}
														</strong>
													</SimpleList.Item>
												</SimpleList>
											))}

											{isFetchingMore && (
												<Row className="justify-content-center mb-3">
													<Col xs="auto">
														<Spinner animation="border" role="status" />
													</Col>
												</Row>
											)}
										</React.Fragment>
									))
								)}
							</>
						)}
					</ScrollReplaceContainer>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ExecutiveLastRegister;
