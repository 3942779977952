import styled from 'styled-components';

import classNames from 'classnames';

const Title = styled.h1.attrs((props) => ({
	...props,
	className: classNames('h3 m-0 mr-2 d-inline-block', props.className),
}))``;

export default Title;
