import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'store/ducks/ecommerce';

const useSetShoppingCartVisible = () => {
	const dispatch = useDispatch();
	const fnc = useCallback(
		(visible = true) => {
			if (visible) {
				dispatch(actions.showShoppingCart());
			} else {
				dispatch(actions.hideShoppingCart());
			}
		},
		[dispatch]
	);

	return fnc;
};

export default useSetShoppingCartVisible;
