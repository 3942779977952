import React from 'react';

import Form from 'react-bootstrap/Form';

import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import financialService from 'services/financial.service';
import { currencyFormatter, dateFormatter } from 'utils/formatters';

import {
	FilterContainer,
	CompanyIDParam,
	DateFilter,
	FilterButton,
	ColumnSum,
	ExportButton,
} from 'packages/admin/components/datatableView';

const validationSchema = Yup.object({
	id: Yup.string(),
	date: Yup.date().required('Campo obrigatório'),
});

const defaultFitlerValues = {
	date: new Date().toISOString(),
};

const columsExport: string[] = [
	'Atendente',
	'Pedido',
	'Depósito',
	'PagSeguro',
	'Saldo',
	'Dinheiro',
	'Cartão',
	'Total',
	'Tipo',
];

const CashierCompanyFilters: React.FC = () => {
	const { tableParams } = useDatagridContext();
	const { data } = useDatagridDataContext();
	const { date: dateFilter } = tableParams.params;

	return (
		<>
			<FilterContainer
				initialValues={defaultFitlerValues}
				validationSchema={validationSchema}
			>
				<CompanyIDParam name="id" />
				<div className="d-flex align-items-center">
					<Form.Group className="mr-3">
						<Form.Label>Data</Form.Label>
						<DateFilter name="date" selectDate isQuery={false} />
					</Form.Group>

					<Form.Group className="mr-3">
						<br />
						<FilterButton />
					</Form.Group>

					<Form.Group>
						<br />
						<ExportButton
							title="Fechamento de Caixa"
							filterData={
								dateFilter ? `${dateFormatter.format(dateFilter)}` : ''
							}
							to="csv"
							action={financialService.getCashierByCompany}
							columns={columsExport}
							disabled={!data}
						>
							<FontAwesomeIcon icon={faFileCsv} className="mr-2" /> Exportar
						</ExportButton>
					</Form.Group>

					<Form.Group className="align-self-end ml-auto mr-2">
						<Form.Label>Total</Form.Label>
						<div>
							<ColumnSum
								name="total"
								prefix="R$ "
								formatter={currencyFormatter}
							/>
						</div>
					</Form.Group>
				</div>
			</FilterContainer>
		</>
	);
};

export default CashierCompanyFilters;
