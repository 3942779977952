import React, { useCallback } from 'react';

import { FormikProps } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import scoreService from 'services/score.service';

import {
	FilterContainer,
	FilterButton,
	ExportButton,
	CompanyIDParam,
	OrderFilter,
} from 'packages/admin/components/datatableView';

type ScoreByOrderFiltersFormType = {
	orderNumber: number | null;
};

const defaultFilterValues: ScoreByOrderFiltersFormType = {
	orderNumber: null,
};

const validationSchema = Yup.object<ScoreByOrderFiltersFormType>({
	orderNumber: Yup.number().nullable().required('Campo obrigatório'),
});

const columnsExport: string[] = [
	'Data',
	'ID',
	'Executivo',
	'Nível',
	'ID do Amigo',
	'Amigo',
	'Pontos',
];

const ScoreByOrderFilters: React.FC = () => {
	const { isLoading, pagination } = useDatagridDataContext();

	const genExportDataHeader = useCallback(
		(values: ScoreByOrderFiltersFormType) => {
			const { orderNumber } = values;
			let header = '';

			if (pagination) {
				header += `Exibindo ${pagination.per_page} de ${pagination.total} registros | Página ${pagination.current_page} de ${pagination.last_page}\n`;
			}
			header += `Pedido nº: ${orderNumber}\n`;

			return header;
		},
		[pagination]
	);

	return (
		<FilterContainer
			initialValues={defaultFilterValues}
			validationSchema={validationSchema}
		>
			{(props: FormikProps<ScoreByOrderFiltersFormType>) => (
				<Form.Row>
					<CompanyIDParam name="idCompany" />
					<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
						<Form.Label>Pedido</Form.Label>
						<OrderFilter name="orderNumber" />
					</FormGroup>
					<FormGroup as={Col}>
						<br />
						<FilterButton className="m-1 mr-3" disabled={isLoading} />
						<ExportButton
							title="Pontuação x Pedido"
							filterData={genExportDataHeader(props.values)}
							to="csv"
							action={scoreService.getScoreByOrder}
							columns={columnsExport}
							exportOnlyScreenData
						>
							<FontAwesomeIcon icon={faFileCsv} className="mr-2" /> Exportar
						</ExportButton>
					</FormGroup>
				</Form.Row>
			)}
		</FilterContainer>
	);
};

export default ScoreByOrderFilters;
