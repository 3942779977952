const isObject = (obj: unknown): boolean => {
	return obj != null && typeof obj === 'object';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepEqual = (obj: any, other: any): boolean => {
	if (obj == null || other == null) {
		return obj === other;
	}
	if (!isObject(obj) && !Array.isArray(obj)) {
		return obj === other;
	}

	const objKeys = Object.keys(obj);
	const othKeys = Object.keys(other);

	if (objKeys.length !== othKeys.length) {
		return false;
	}

	for (const key of objKeys) {
		if (!(key in other)) {
			return false;
		}
		const val1 = obj[key];
		const val2 = other[key];
		const areObj = isObject(val1) && isObject(val2);

		if (areObj && !deepEqual(val1, val2)) {
			return false;
		}
		if (!areObj && val1 !== val2) {
			return false;
		}
	}

	return true;
};

export default deepEqual;
