import Formatter from './Formatter';
import { ButtonLinkFormatterConfig } from './Formatter/ButtonLink';
import { ColoredStatusConfig } from './Formatter/ColoredStatus';
import { CustomFormatterConfig } from './Formatter/Custom';
import {
	dateOptions,
	dateTimeOptions,
	hourMinOptions,
	dateHourMinOptions,
	timeOptions,
} from './Formatter/Date';
import { EditLinkFormatterConfig } from './Formatter/EditLink';
import { numberFormat } from './Formatter/Number';
import { REGEX_CLEAR_NUMERIC } from './Formatter/Regex';

// Default
export const defaultFormatter = new Formatter.Default();

// Telefones
export const phoneFormatter = new Formatter.Phone();

// CEP
export const cepFormatter = new Formatter.CEP();

// CPF
export const cpfFormatter = new Formatter.CPF();
export const cnpjFormatter = new Formatter.CNPJ();
export const cpfCnpjFormatter = new Formatter.CPFCNPJ();

// CNAE
export const cnaeFormatter = new Formatter.CNAE();

// Numeros:
export const numberFormatter = new Formatter.Number();
export const currencyFormatter = new Formatter.Number(
	'pt-br',
	numberFormat.currency()
);
export const currencyWithSymbolFormatter = new Formatter.Number(
	'pt-br',
	numberFormat.currencyWithSymbol()
);
export const decimalFormatter = (places = 1) =>
	new Formatter.Number('pt-br', numberFormat.decimal(places));

// Datas
export const dateFormatter = new Formatter.Date('pt-br', dateOptions);
export const timeFormatter = new Formatter.Date('pt-br', timeOptions);
export const dateTimeFormatter = new Formatter.Date('pt-br', dateTimeOptions);
export const dateHourMinFormatter = new Formatter.Date(
	'pt-br',
	dateHourMinOptions
);
export const hourMinFormatter = new Formatter.Date('pt-br', hourMinOptions);

// Regex
export const regexNumbersOnlyFormatter = new Formatter.Regex(
	REGEX_CLEAR_NUMERIC,
	''
);

// Links e buttons
export const editLinkFormatter = <T, P, R = void>(
	options: EditLinkFormatterConfig<T, P, R>
) => new Formatter.EditLink(options);

export const buttonLinkFormatter = <T, P, R = void>(
	options: ButtonLinkFormatterConfig<T, P, R>
) => new Formatter.ButtonLink(options);

export const customFormatter = <T = void>(options: CustomFormatterConfig<T>) =>
	new Formatter.Custom(options);

// Status
export const coloredStatusFormatter = (config: ColoredStatusConfig) =>
	new Formatter.ColoredStatus(config);
