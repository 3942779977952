import styled from 'styled-components';

// FIXME:
// Fazer o body da table ficar 100% na vertical com flex quebra o cálculo automático
// do espaçamento entre colunas feita pelo navegador

const DatagridStyle = styled.div`
	--datatable-border-color: rgba(0, 0, 0, 0.08);

	table {
		font-size: 0.923rem;
	}

	table thead th {
		border-top: 0;
		border-bottom: 0;
	}

	table td,
	table th {
		border-top: 1px solid var(--datatable-border-color);
		padding: 0.45rem;
	}
`;

/*
const DatatableWrapper = styled.div`
	--scroll-width: 10px;
	--primary: var(--primary);
	--accent: var(--accent);
	--warn: var(--warning);
	--success: var(--success);
	--danger: var(--danger);
	--info: var(--info);
	--background: var(--base-bg);
	--foreground: var(--base-color);

	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;

	table {
		display: flex;
		flex-flow: column;

		background-color: transparent;
		border-spacing: 0;
		border-collapse: collapse;
		word-wrap: break-word;

		thead {
			flex: 0 0 auto;
			width: calc(100% - var(--scroll-width));
			display: table;
			table-layout: fixed;

			tr {
				min-height: 42px;
				height: 42px;
				font-weight: 500;
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);

				th {
					padding: 5px;
					vertical-align: middle;
					border-top: 0;
				}
			}
		}

		tbody {
			flex: 1 1 auto;
			display: block;
			overflow-y: scroll;
			position: relative;
			border-left: 1px solid rgba(0, 0, 0, 0.12);
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);

			&::-webkit-scrollbar-track {
				border: 1px solid rgba(0, 0, 0, 0.1);
			}

			&::-webkit-scrollbar {
				width: $datagrid-scroll-width;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--primary);
			}

			tr {
				width: 100%;
				display: table;
				table-layout: fixed;
				min-height: 42px;
				height: 42px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.06);

				td {
					padding: 8px;
					vertical-align: middle;
				}
			}
		}

		.search-header {
			background-color: rgba(0, 0, 0, 0.01);
		}

		.search-group {
			display: flex;
			padding: 0px 8px;
			border: 1px solid rgba(0, 0, 0, 0.12);
			background-color: white;
		}
		.search-group .search-group-inner {
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: baseline;
		}
		.search-group-inner .search-icon {
			flex: 0;
		}
		input.search-input {
			border: 0;
			padding-left: 10px;
			padding-top: 4px;
			padding-bottom: 4px;
			width: calc(100% - 10px);
		}
		input.search-input:focus {
			outline: 0;
		}
	}
`;
*/

DatagridStyle.displayName = 'DatagridStyle';
(DatagridStyle.whyDidYouRender as boolean) = true;

export default DatagridStyle;
