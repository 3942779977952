/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import { Button, Spinner } from 'react-bootstrap';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import { useToast } from 'hooks';
import careerService from 'services/career.service';
import { HttpBaseResult, PersonStatusActiveInative, Career } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

const ChangeActiveButton: React.FC<ButtonLinkComponentProps<Career>> = ({
	row,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeStatus, setActiveStatus] = useState<boolean>(
		row.isActive === true
	);
	const [toast] = useToast();
	const { forceUpdate } = useDatagridContext();

	const handleActiveChange = useCallback(async () => {
		setIsLoading(true);
		const dataActive = {
			isActive: !activeStatus,
		};

		try {
			await careerService.patch(row.idCareer!, dataActive);
			setActiveStatus(!activeStatus);
			forceUpdate();
		} catch (e) {
			const { code, message } = e as HttpBaseResult;
			// eslint-disable-next-line no-console
			console.log('Error message: ', message);
			toast(`Não foi possível alterar o status. Cód: ${code}`, {
				type: 'error',
			});
		}

		setIsLoading(false);
	}, [activeStatus, forceUpdate, row, toast]);

	return (
		<Button
			variant={row.isActive === true ? 'success' : 'danger'}
			disabled={isLoading}
			onClick={handleActiveChange}
		>
			{isLoading ? (
				<Spinner size="sm" animation="border" role="status" />
			) : row.isActive === true ? (
				PersonStatusActiveInative.Active
			) : (
				PersonStatusActiveInative.Blocked
			)}
		</Button>
	);
};

export default ChangeActiveButton;
