export class ApiCodes {
	static Success = {
		// 200	Ok
		SessionCreated: 10, // Login efetuado com sucesso.
		PasswordChanged: 11, // Senha alterada e Login efeuado com sucesso.
		SessionClosed: 20, // Sessão Finalizada com Sucesso.
		EmailSent: 30, // E-mail enviado com sucesso.
		DataUpdated: 60, // Dados atualizados com sucesso.
		DataRemoved: 61, // Dados removidos com sucesso.
		// 201	Created
		DataCreated: 50, // Dados inseridos com sucesso.
	};

	static Error = {
		// 400	Bad Request
		InvalidFields: 100, // Falha na validação de campos
		SaveError: 101, // Erro ao salvar.
		// 401	Unauthorized
		InvalidToken: 110, // Token inválido.
		InvalidCredentials: 111, // Credenciais inválidas.
		PasswordExpired: 112, // Senha expirada.
		AccountNotValidated: 113, // Conta criada mas e-mail ainda não foi confirmado
		OutsideWorkHour: 114, // Acesso fora do horário permitido de trabalho
		BlockedAccount: 115, // Conta bloqueada, entre em contato com a central de atendimento
		TokenExpired: 116, // Sessão expirada
		// 403	Forbidden
		NotAutorized: 120, // Sem direito de acesso.
		// 404	Not Found
		IDNotFound: 130, // ID não encontrado.
		FileNotFound: 131, // Arquivo não encontrado.
		// 405	Method Not Allowed
		MethodNotAllowed: 140, // Método não permitido
		// 406	Not Acceptable
		// 500	Internal Server Error
		InternalError: 160, // Falha ao salvar.
		// 501	Not Implemented
		NotImplemented: 170, // Rota não implementada.
	};

	private static codeStrings = {
		[ApiCodes.Success.DataCreated]: 'Dados inseridos com sucesso',
		[ApiCodes.Success.DataRemoved]: 'Dados removidos com sucesso',
		[ApiCodes.Success.DataUpdated]: 'Dados atualizados com sucesso',
		[ApiCodes.Success.EmailSent]: 'E-mail enviado com sucesso',
		[ApiCodes.Success.PasswordChanged]: 'Senha alterada com sucesso',
		[ApiCodes.Success.SessionClosed]: 'Sessão finalizada',
		[ApiCodes.Success.SessionCreated]: 'Sessão criada',

		[ApiCodes.Error.AccountNotValidated]: 'Confirmação de conta pendente',
		[ApiCodes.Error.FileNotFound]: 'Arquivo não encontrado',
		[ApiCodes.Error.IDNotFound]: 'ID não encontrado',
		[ApiCodes.Error.InternalError]: 'Erro interno',
		[ApiCodes.Error.InvalidCredentials]: 'Credenciais inválidas',
		[ApiCodes.Error.InvalidFields]: 'Campos não passaram pela validação',
		[ApiCodes.Error.InvalidToken]: 'Token de acesso inválido',
		[ApiCodes.Error.BlockedAccount]:
			'Conta bloqueada, entre em contato com a central de atendimento',
		[ApiCodes.Error.TokenExpired]: 'Sessão expirada',
		[ApiCodes.Error.MethodNotAllowed]: 'Método não disponível',
		[ApiCodes.Error.NotAutorized]: 'Acesso não autorizado',
		[ApiCodes.Error.NotImplemented]: 'Não implementado',
		[ApiCodes.Error.PasswordExpired]: 'Senha expirada',
		[ApiCodes.Error.OutsideWorkHour]: 'Acesso fora do horário permitido',
		[ApiCodes.Error.SaveError]: 'Não foi possível efetuar a requisição',
	};

	static success(code: number): boolean {
		return code > 10 && code < 100;
	}

	static error(code: number): boolean {
		return !code || code >= 100;
	}

	static removeSuccess(code: number): boolean {
		return code === ApiCodes.Success.DataRemoved;
	}

	static createOrUpdateSuccess(code: number): boolean {
		return (
			code === ApiCodes.Success.DataCreated ||
			code === ApiCodes.Success.DataUpdated
		);
	}

	static getCodeString(code: number): string {
		let ret = `Erro desconhecido: ${code}`;

		if (ApiCodes.codeStrings[code]) {
			ret = ApiCodes.codeStrings[code];
		}

		return ret;
	}
}
