/* eslint-disable no-nested-ternary */
import {
	faPaperclip,
	IconDefinition,
	faFileImage,
	faFileAudio,
	faFileVideo,
	faFileAlt,
	faFileCode,
	faFile,
	faFilePowerpoint,
	faFilePdf,
	faFileCsv,
	faFileWord,
	faFileExcel,
	faFileArchive,
} from '@fortawesome/pro-light-svg-icons';

import { Attach, Options, UploadFile } from './types';

const mimeToIconMap = new Map<string, IconDefinition>([
	['image/jpeg', faFileImage],
	['image/png', faFileImage],
	['image/gif', faFileImage],
	['image/x-icon', faFileImage],
	['image/svg+xml', faFileImage],
	['audio/ogg', faFileAudio],
	['audio/wav', faFileAudio],
	['audio/mpeg', faFileAudio],
	['video/mp4', faFileVideo],
	['video/ogg', faFileVideo],
	['video/webm', faFileVideo],
	['video/x-msvideo', faFileVideo],
	['video/vnd.youtube.yt', faFileVideo],
	['text/plain', faFileAlt],
	['text/html', faFileCode],
	['text/css', faFileCode],
	['application/xhtml+xml', faFileCode],
	['application/xml', faFileCode],
	['application/octet-stream', faFile],
	['application/pkcs12', faFile],
	['application/vnd.ms-powerpoint', faFilePowerpoint],
	[
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		faFilePowerpoint,
	],
	['application/pdf', faFilePdf],
	['application/csv', faFileCsv],
	['application/msword', faFileWord],
	['application/rtf', faFileWord],
	[
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		faFileWord,
	],
	['application/vnd.ms-excel', faFileExcel],
	[
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		faFileExcel,
	],
	['application/x-rar-compressed', faFileArchive],
	['application/x-tar', faFileArchive],
	['application/zip', faFileArchive],
	['application/x-7z-compressed', faFileArchive],
]);

const attrAccept = (file: unknown, acceptedFiles: string): boolean => {
	if (file && acceptedFiles) {
		const acceptedFilesArray = Array.isArray(acceptedFiles)
			? acceptedFiles
			: acceptedFiles.split(',');
		const fileName = (file as File).name || '';
		const mimeType = (file as File).type || '';
		const baseMimeType = mimeType.replace(/\/.*$/, '');

		return acceptedFilesArray.some((type) => {
			const validType = type.trim();
			if (validType.charAt(0) === '.') {
				return fileName.toLowerCase().endsWith(validType.toLowerCase());
			}
			if (/\/\*$/.test(validType)) {
				return baseMimeType === validType.replace(/\/.*$/, '');
			}
			return mimeType === validType;
		});
	}
	return true;
};

const convertBytesInMB = (size: number) => {
	const sizeInMB = (size / (1024 * 1024)).toFixed(2);
	return Number(sizeInMB);
};

const maxFileAccept = (file: File, maxSize: number) => {
	if (file && maxSize) {
		return !(convertBytesInMB(file.size) > maxSize);
	}
	return true;
};

const getIconForFile = (file: File): IconDefinition => {
	const mimeType = file.type;
	return mimeToIconMap.get(mimeType) || faPaperclip;
};

const uid = (index: number) => {
	const now = +new Date();
	return `ap-upload-${now}-${index}`;
};

const isNewFile = (url?: string | null): url is string => {
	return /^data:/.test(String(url));
};

const isAttach = (obj: unknown): obj is Attach => 'file' in (obj as Attach);

const isUploadFile = (obj: unknown): obj is UploadFile =>
	typeof (obj as UploadFile).uid === 'string' && 'url' in (obj as UploadFile);

const isUploadFileArray = (arr: unknown): arr is UploadFile[] =>
	Array.isArray(arr) && arr.every((a) => isUploadFile(a));

const isValidStrImage = (img?: string | null): img is string =>
	/(?:\.(?:jpe?g|png)|image\/[0-9]+\/file)(?:\?.+?=.+)?$/.test(String(img));

// Curry function para mapear de string | Attach para UploadFile
const mapToUploadFile = (options: Options<Attach> = {}) => (
	file: string | Attach,
	idx = 0
): UploadFile => {
	const {
		itemIdProp,
		itemLastModifiedProp,
		itemNameProp,
		itemUrlProp,
		icon = 'paperclip',
	} = options;

	return {
		id: itemIdProp && isAttach(file) ? file[itemIdProp] : undefined,
		lastModified:
			itemLastModifiedProp && isAttach(file)
				? !Number.isNaN(file[itemLastModifiedProp])
					? Number(file[itemLastModifiedProp])
					: new Date(file[itemLastModifiedProp]!).getTime() || 0
				: 0,
		uid: uid(idx),
		name:
			typeof file === 'object'
				? itemNameProp
					? file[itemNameProp]
					: String(file.file.substring(file.file.lastIndexOf('/') + 1))
				: '',
		size: 0,
		preview: attrAccept(file, 'image/*')
			? typeof file === 'object'
				? String(file.file)
				: String(file)
			: null,
		url:
			typeof file === 'object'
				? itemUrlProp
					? file[itemUrlProp]
					: file.file
				: file,
		type: '',
		icon,
		error: null,
	};
};

// Mapeia somente imagens para UploadFile[]
const mapImages = (urls: string | string[]): UploadFile[] => {
	let images: string[] = [];
	if (Array.isArray(urls)) {
		images = urls;
	} else {
		images = [urls];
	}

	const validImages = images.filter((v) => isValidStrImage(v));
	return validImages.map(mapToUploadFile());
};

// Processa os anexos que foram modificados com base em um objeto de tipo diferente
// separando os adicionados e os deletados com base no id
const processUploadFiles = <T extends { [K in keyof T]: unknown }>(
	values: T[] | UploadFile[] | undefined,
	initialValues: T[],
	idProp: keyof T
) => {
	let uAttaches: UploadFile[] = [];
	let tAttaches: T[] = [];

	if (isUploadFileArray(values)) {
		uAttaches = values;
	} else {
		tAttaches = values ?? [];
	}

	const isModified = !tAttaches.length;

	const added: UploadFile[] = isModified
		? uAttaches.filter(
				(u) => !initialValues.find((a) => a[idProp] === u.id) && u.url?.trim()
		  )
		: [];

	const deleted: T[] = isModified
		? initialValues.filter((a) => !uAttaches?.find((u) => u.id === a[idProp]))
		: [];

	return { added, deleted, isModified };
};

export default {
	attrAccept,
	convertBytesInMB,
	maxFileAccept,
	getIconForFile,
	uid,
	isNewFile,
	isAttach,
	isUploadFile,
	isUploadFileArray,
	isValidStrImage,
	mapToUploadFile,
	mapImages,
	processUploadFiles,
};
