import React, {
	useState,
	useEffect,
	useCallback,
	useReducer,
	PropsWithChildren,
} from 'react';

import Datagrid from 'components/Datagrid';
import { DatagridConfig, DatagridState } from 'components/datagrid/types';
import {
	HttpGetListParams,
	HttpGetListResult,
	Pagination,
	QueryOperator,
} from 'types';
import deepEqual from 'utils/deepEqual';
import history from 'utils/history';

import datatableViewActions from './datatableView/actions';
import datatableViewReducer, {
	defaultReducerState,
} from './datatableView/reducer';

type DatatableViewProps = PropsWithChildren<{
	config: DatagridConfig;
	serviceMethod: (
		params: HttpGetListParams,
		...rest: any[]
	) => Promise<HttpGetListResult<unknown>>;
}>;

const DatatableView: React.FC<DatatableViewProps> = React.memo(
	({ config, serviceMethod, children }) => {
		// Ponte de dados com a API
		const [{ tableParams, fetchData }, dispatch] = useReducer(
			datatableViewReducer,
			defaultReducerState
		);
		// Todo parâmetro que influencia na busca de dados atualizados:
		const [httpParams, setHttpParams] = useState<HttpGetListParams>({});

		const onTableChanged = useCallback(
			(
				parms: DatagridState,
				prevHttpParams: HttpGetListParams,
				force: boolean
			) => {
				const prependQuery = (op?: QueryOperator) => (op !== '~' ? '' : '*');
				const appendQuery = prependQuery;

				const newParams: HttpGetListParams = {
					params: parms.params,
					pagination: {
						page: parms.page,
						paginate: parms.rowsPerPage,
						sort: parms.sortByKey
							? `${parms.sortByDesc ? '-' : ''}${parms.sortByKey}`
							: undefined,
					},
					query: parms.filters
						.map(
							(f) =>
								`"${f.operator ?? '~'}${f.key}": "${prependQuery(f.operator)}${
									f.query
								}${appendQuery(f.operator)}"`
						)
						.concat(
							parms.searchColumns
								.map((col) => {
									if (!!col.key && !!col.text) {
										const column = config.columns.find(
											(c) => c.key === col.key
										);
										const key = column ? column.sortBy || column.key : col.key;
										const searchType: QueryOperator = '~';
										const searchLike = '*';

										return `"${searchType}${key}": "${searchLike}${col.text}${searchLike}"`;
									}
									return '';
								})
								.filter((col) => col !== '')
						),
				};

				if (!newParams.query?.length) {
					newParams.query = undefined;
				}
				if (deepEqual(prevHttpParams, newParams) && !force) {
					return;
				}
				// eslint-disable-next-line no-console
				console.log('onTableChanged', newParams);
				setHttpParams(newParams);
			},
			[setHttpParams, config.columns]
		);

		useEffect(() => {
			if (!tableParams) {
				return;
			}

			// eslint-disable-next-line no-console
			console.log('useEffect() -> updateHistory()');
			const queryMap: Record<string, string> = {};
			if (tableParams.sortByKey) {
				queryMap.sortBy = tableParams.sortByKey;
			}
			if (tableParams.sortByDesc) {
				queryMap.sortByDesc = '';
			}
			if (tableParams.rowsPerPage) {
				queryMap.rowsPerPage = String(tableParams.rowsPerPage);
			}
			if (tableParams.page) {
				queryMap.page = String(tableParams.page);
			}

			if (tableParams.searchColumns !== undefined) {
				for (const col of tableParams.searchColumns) {
					if (col && col.key && col.text) {
						queryMap[col.key] = col.text;
					}
				}
			}

			history.push(
				`${history.location.pathname}?${new URLSearchParams(
					queryMap
				).toString()}`
			);
		}, [tableParams]);

		useEffect(() => {
			// eslint-disable-next-line no-console
			console.log('useEffect() -> dispatch(datatableUpdate)', httpParams);
			// Se não carregou os parâmetros ainda, não dispara ação:
			if (!httpParams.query && !httpParams.params && !httpParams.pagination) {
				// eslint-disable-next-line no-console
				console.log('dispatch(false)');
				return;
			}
			const asyncCallService = async () => {
				try {
					const result = await serviceMethod(httpParams);
					dispatch(
						datatableViewActions.setFetchData(
							result.data,
							result.meta as Pagination
						)
					);
				} catch (e) {
					dispatch(datatableViewActions.setFetchError(e));
				}
			};

			dispatch(datatableViewActions.setLoading(true));

			asyncCallService();
		}, [httpParams, serviceMethod]);

		return (
			<Datagrid
				data={fetchData}
				{...config}
				onChange={(val, force) => onTableChanged(val, httpParams, force)}
			>
				{children}
			</Datagrid>
		);
	}
);

DatatableView.displayName = 'DatatableView';
DatatableView.whyDidYouRender = true;

export default DatatableView;
