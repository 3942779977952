import { Reducer } from 'react';

import { PrefsState, PrefsActionTypes, PrefsActionEnum } from './types';

const initialState: PrefsState = {
	datatable: {
		rowsPerPage: 50,
	},
	admin: {
		idCompany: 0,
	},
};

const PrefsReducer: Reducer<PrefsState, PrefsActionTypes> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case PrefsActionEnum.Update:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default PrefsReducer;
