import React, { ReactNode } from 'react';

import { Button } from 'react-bootstrap';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';

type BasicModalProps = {
	title?: ReactNode;
	content: ReactNode;
	showFooterButtons: boolean;
};

const defaultProps = {
	showFooterButtons: false,
};

const BasicModal: React.FC<ModalForwardProps<BasicModalProps, boolean>> = ({
	title,
	content,
	showFooterButtons,
	modalRef,
	...rest
}) => {
	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			{title && (
				<Modal.Header closeButton>
					<Modal.Title className="m-0">{title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{content}</Modal.Body>
			{showFooterButtons && (
				<Modal.Footer align="end">
					<Button
						variant="outline-primary"
						onClick={() => modalRef.dismiss(false)}
					>
						Cancelar
					</Button>
					<Button variant="primary" onClick={() => modalRef.dismiss(true)}>
						Confirmar
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

BasicModal.defaultProps = defaultProps;

export default BasicModal;
