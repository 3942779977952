/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Form as FormikForm, FormikProps } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { faUser, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card } from 'components';
import {
	Input,
	Upload,
	AsyncSelect,
	ToggleButtonGroup,
	ToggleButton,
	FormikEffect,
} from 'components/Formik';
import Checkbox from 'components/Formik/Checkbox';
import uploadHelpers from 'components/upload/Helpers';
import { useQueryParams, useToast } from 'hooks';
import ecommerceService from 'services/ecommerce.service';
import personService from 'services/person.service';
import userService from 'services/system-user.service';
import timetableService from 'services/timetable.service';
import { actions } from 'store/ducks/session';
import { SystemUser, AuthACLRole } from 'types';
import history from 'utils/history';
import Sort from 'utils/Sorters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import SkeletonForm from './user/SkeletonForm';
import { UserFormType } from './user/types';

export const defaultFormValue: UserFormType = {
	id: null,
	idUser: null,
	idType: null,
	idPerson: null,
	name: '',
	email: '',
	imageUrl: [],
	changePass: false, // Para forçar ou não trocar a senha no próximo acesso
	sendmail: false,
	password: '',
	department: null,
	timetable: null,
	company: null,
	roles: [],
	idStatus: 3,
};

export const formSchema = Yup.object<UserFormType>().shape({
	idPerson: Yup.number().nullable(),
	name: Yup.string().required('Campo obrigatório'),
	email: Yup.string().email().required('Campo Obrigatório'),
	department: Yup.object().nullable().required('Campo obrigatório'),
	timetable: Yup.object().nullable(),
	company: Yup.object().nullable().required('Campo obrigatório'),
	changePass: Yup.bool().nullable(),
	password: Yup.string().when('idPerson', {
		is: (idPerson?: number) => idPerson == null,
		then: Yup.string().required('Campo obrigatório'),
		otherwise: Yup.string(),
	}),
	idStatus: Yup.string().required('Campo obrigatório'),
	roles: Yup.array().test(
		'not_empty',
		'O usuário precisa de pelo menos um perfil de acesso selecionado',
		(value) =>
			value?.reduce<boolean>(
				(acc, next) => acc || next === 0 || !!next,
				false
			) ?? false
	),
});

const UserEdit: React.FC = () => {
	const [toast] = useToast();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [modifyPassword, setModifyPassword] = useState<boolean>(false);
	const dispatch = useDispatch();
	const query = useQueryParams();
	const profile = query.get('p') || false;

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			setModifyPassword(isCreating);

			const roles = await userService
				.getProfiles({})
				.then((result) => result.data.sort(Sort.Alphabetically('name')));

			let user: UserFormType = defaultFormValue;

			if (!isCreating) {
				const { imageUrl, ...response } = await userService
					.getById(Number(id))
					.then((result) => {
						return {
							...result,
							roles: Array(roles?.length)
								.fill(false)
								.map((_, idx) => {
									return result.roles?.find(
										(val) => roles && roles[idx].idRole === Number(val) && val
									);
								}),
						} as SystemUser;
					});

				user = {
					...response,
					// Transformar prop image em UploadFile[]
					...(imageUrl && { imageUrl: uploadHelpers.mapImages(imageUrl) }),
				};
			}

			return { roles, user };
		},
		[]
	);

	const handleSubmit = useCallback(
		async (
			id: string,
			{ imageUrl, ...values }: UserFormType,
			{ setSubmitting }
		) => {
			const submit: SystemUser = {
				...values,
				idDepartment: Number(values.department?.idDepartment),
				idTimetable: values.timetable?.idTimetable,
				roles: values.roles?.filter((role) => typeof role === 'number'),
				// Só envia a prop image ao alterar a imagem ou excluir
				...(Array.isArray(imageUrl) &&
					(uploadHelpers.isNewFile(imageUrl[0]?.url)
						? { imageUrl: imageUrl[0]?.url }
						: imageUrl.length === 0 && { imageUrl: '' })),
			};

			try {
				await userService.createOrUpdate(submit.idPerson, submit);
				toast(`Usuário ${id ? 'alterado' : 'criado'} com sucesso`, {
					type: 'success',
				});

				if (profile) {
					dispatch(actions.loadLoggedRequest());
				} else {
					history.push('/admin/access/users');
				}
			} catch (e) {
				toast(`Não foi possível editar o usuário. Cód: ${e.code}`, {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[dispatch, profile, toast]
	);

	return (
		<PageLayoutFormWrapper
			title={profile ? 'Meu Perfil' : 'Usuário'}
			breadcrumb={[
				{ label: 'Acesso', path: '/admin/access/users' },
				{ label: 'Usuários', path: '/admin/access/users' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.user}
					validationSchema={formSchema}
				>
					{(form: FormikProps<UserFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator justifyContent="space-between">
									<Card.Title>
										<span>Dados Cadastrais</span>
									</Card.Title>
									<Form.Group className="d-inline mb-0">
										<ToggleButtonGroup name="idStatus">
											<ToggleButton
												variant="primary"
												size="sm"
												value={4}
												disabled={form.isSubmitting}
												onChange={() => {
													form.setFieldValue('status', 'Bloqueado');
												}}
											>
												Bloqueado
											</ToggleButton>
											<ToggleButton
												variant="primary"
												size="sm"
												value={3}
												disabled={form.isSubmitting}
												onChange={() => form.setFieldValue('status', 'Ativo')}
											>
												Ativo
											</ToggleButton>
										</ToggleButtonGroup>
									</Form.Group>
								</Card.Header>
								<Card.Content>
									<Row>
										<Col lg={8}>
											<Input name="idPerson" className="d-none" />

											<Form.Row className="w-100">
												<Form.Group as={Col} lg={5}>
													<Form.Label className="d-block">
														Foto do Perfil
													</Form.Label>
													<Upload
														name="imageUrl"
														listType="picture-card"
														showUploadList={false}
														width={180}
														height={180}
														accept=".jpg, .png"
														maxSize={2}
														disabled={form.isSubmitting}
														displayInline
													>
														<FAIcon icon={faUser} />
														<div>Adicionar Foto</div>
													</Upload>
												</Form.Group>
												<Col lg={7}>
													<Form.Row>
														<Form.Group as={Col} md={12}>
															<Form.Label>Nome</Form.Label>
															<Input
																name="name"
																type="text"
																disabled={form.isSubmitting}
															/>
														</Form.Group>

														<Form.Group as={Col} md={12}>
															<Form.Label>Email</Form.Label>
															<Input
																name="email"
																type="email"
																disabled={form.isSubmitting}
															/>
														</Form.Group>

														<Form.Group as={Col} md={12}>
															<Form.Label>Empresa</Form.Label>
															<AsyncSelect
																name="company"
																fetchMethod={ecommerceService.getCompanies}
																optionValue="idPerson"
																disabled={form.isSubmitting}
															/>
														</Form.Group>

														<Form.Group as={Col} md={12}>
															<Form.Label>Departamento</Form.Label>
															<AsyncSelect
																name="department"
																fetchMethod={
																	personService.getDepartmentsForSelect
																}
																optionValue="idDepartment"
																disabled={form.isSubmitting}
															/>
														</Form.Group>

														<Form.Group as={Col} md={12}>
															<Form.Label>Horário</Form.Label>
															<AsyncSelect
																name="timetable"
																isClearable
																fetchMethod={timetableService.getSchedules}
																optionValue="idTimetable"
																disabled={form.isSubmitting}
															/>
														</Form.Group>

														<Col lg={12}>
															{!modifyPassword ? (
																<div className="w-100 text-center bg-light p-4">
																	<Button
																		variant="link"
																		type="button"
																		onClick={() => setModifyPassword(true)}
																	>
																		{`${
																			id !== 'create' ? 'Trocar' : 'Nova'
																		} Senha`}
																	</Button>
																</div>
															) : (
																<>
																	<Form.Row>
																		<Form.Group as={Col} lg={12}>
																			<Form.Label>Nova Senha</Form.Label>
																			<Input
																				disabled={form.isSubmitting}
																				name="password"
																				type={
																					showPassword ? 'text' : 'password'
																				}
																				placeholder="Senha"
																				append={
																					<Button
																						variant="outline-primary"
																						onClick={() => {
																							setShowPassword(!showPassword);
																						}}
																					>
																						<FAIcon
																							icon={
																								showPassword
																									? faEye
																									: faEyeSlash
																							}
																						/>
																					</Button>
																				}
																			/>
																		</Form.Group>
																	</Form.Row>

																	<Form.Row>
																		<Form.Group as={Col} className="mb-0">
																			<Checkbox
																				name="changePass"
																				label="Invalidar Senha (força trocar a senha no próximo acesso)"
																			/>
																		</Form.Group>
																	</Form.Row>
																</>
															)}
														</Col>
													</Form.Row>
												</Col>
											</Form.Row>
										</Col>

										<Col lg={4} className="border-left">
											<Form.Row>
												<Form.Group as={Col} md={12}>
													<FormGroupTitle>Perfis de Acesso</FormGroupTitle>
													{data!.roles.map((role: AuthACLRole, idx: number) => (
														<Checkbox
															key={role.idRole}
															name={`roles[${idx}]`}
															value={String(role.idRole)}
															label={role.name}
															className="mb-1"
															disabled={form.isSubmitting}
															isValueNumber
														/>
													))}
													{!!form.errors.roles && form.touched.roles && (
														<span className="invalid-feedback d-block">
															{form.errors.roles}
														</span>
													)}
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="border-top">
									<div className="text-right">
										<FormCancelButton
											isSubmitting={form.isSubmitting}
											to="/admin/access/users"
										/>
										<FormSubmitButton isSubmitting={form.isSubmitting} />
									</div>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default UserEdit;
