import React, { useState, useEffect } from 'react';

import InputSlider from 'react-input-slider';
import styled from 'styled-components';

export type TimePickerProps = {
	defaultDate?: string;
	value?: string;
	onTimeChange?: (newDate: string) => void;
};

const BigNumber = styled.span`
	font-size: 2rem;
	font-weight: 600;
	border: solid 1px rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem;
`;

const BigNumberSeparator = styled.span`
	font-size: 1.8rem;
`;

const SliderContainer = styled.div`
	max-width: 80%;
	margin: auto;
`;

const SliderStyle = {
	track: {
		backgroundColor: '#ddd',
	},
	active: {
		backgroundColor: 'var(--secondary)',
	},
};

const TimePicker: React.FC<TimePickerProps> = ({
	defaultDate,
	value,
	onTimeChange,
}) => {
	const defDate = new Date(value || defaultDate || new Date());
	const defHour = defDate.getHours();
	const defMin = defDate.getMinutes();

	const [hour, setHour] = useState<number>(defHour);
	const [min, setMin] = useState<number>(defMin);
	const [date, setDate] = useState<Date>(defDate);

	useEffect(() => {
		const newDate = new Date(date);
		newDate.setHours(hour, min);

		if (date.toISOString() !== newDate.toISOString()) {
			setDate(newDate);
			if (onTimeChange !== undefined) {
				onTimeChange(newDate.toISOString());
			}
		}
	}, [date, hour, min, onTimeChange]);

	return (
		<>
			<div className="d-flex justify-content-center py-3">
				<BigNumber className="px-2">{`0${hour}`.slice(-2)}</BigNumber>
				<BigNumberSeparator className="mx-1">:</BigNumberSeparator>
				<BigNumber className="px-2">{`0${min}`.slice(-2)}</BigNumber>
			</div>
			<SliderContainer>
				<div className="">Hora:</div>
				<div className="mx-2">
					<InputSlider
						styles={SliderStyle}
						axis="x"
						xmin={0}
						xmax={23}
						x={hour}
						onChange={({ x }) => setHour(x)}
					/>
				</div>
				<div className="pt-3">Minuto:</div>
				<div className="mx-2">
					<InputSlider
						styles={SliderStyle}
						axis="x"
						xmin={0}
						xmax={59}
						x={min}
						onChange={({ x }) => setMin(x)}
					/>
				</div>
			</SliderContainer>
		</>
	);
};

export default TimePicker;
