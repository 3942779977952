import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import * as Yup from 'yup';

import { Input, Select } from 'components/Formik';
import correiosService from 'services/correios.service';
import countryStateService from 'services/country-state.service';
import { RegionState, PersonAddress, RegionCity } from 'types';
import { cepFormatter, numberFormatter } from 'utils/formatters';

export const defaultFormValue: PersonAddress = {
	idAddress: null,
	name: 'Endereço Padrão',
	number: '',
	active: true,
	cep: '',
	district: '',
	complement: '',
	streetName: '',
	isBilling: true,
	state: null,
	city: null,
};

export const formSchema = Yup.object<PersonAddress>().shape({
	idAddress: Yup.mixed()
		.transform((val) => (!val ? null : Number(val)))
		.nullable()
		.notRequired(),
	idPerson: Yup.number().nullable().notRequired(),
	name: Yup.string().required('O nome é obrigatório'),
	number: Yup.string().required('O número é obrigatório'),
	active: Yup.boolean(),
	district: Yup.string().required('O bairro é obrigatório'),
	complement: Yup.string().nullable().notRequired(),
	streetName: Yup.string().required('Endereço é obrigatório'),
	cep: Yup.string().length(8, 'CEP inválido').required('CEP é obrigatório'),
	isBilling: Yup.boolean(),
	state: Yup.object().nullable().required('UF obrigatório'),
	city: Yup.object().nullable().required('Cidade é obrigatória'),
});

type AddressFormProps = {
	path: string;
	editName?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({ path, editName }) => {
	const [states, setStates] = useState<RegionState[]>([]);
	const [cities, setCities] = useState<RegionCity[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const formikCtx = useFormikContext();

	const onStateChange = async (
		item: RegionState | undefined,
		isFirstLoad: boolean,
		cityCep?: string
	) => {
		if (!isFirstLoad) {
			const state = item;
			const { value: oldState } = formikCtx.getFieldMeta(`${path}.state`);
			if (oldState === state) {
				return;
			}

			formikCtx.setFieldValue(`${path}.city`, undefined);
		}

		if (!item) {
			return;
		}
		try {
			const citiesByState = await countryStateService.getCitiesForState(
				item.idState
			);
			if (cityCep) {
				const city = citiesByState.find((c) => c.name === cityCep);
				formikCtx.setFieldValue(`${path}.city`, city);
			}
			setCities(citiesByState);
		} catch (e) {
			setCities([]);
		}
	};

	const onCEPChange = async (cep: string, event: unknown) => {
		if (!event) return;
		formikCtx.setFieldTouched(`${path}.cep`, true);
		if (cep && cep.length === 8) {
			setIsLoading(true);
			try {
				const addressInfo = await correiosService.getAddressForCEP(cep);

				// TODO:
				// Api não deveria retornar array vazio quando não acha o CEP
				if (Array.isArray(addressInfo)) {
					formikCtx.setFieldError(`${path}.cep`, 'CEP não encontrado');
				} else {
					const { value: addrValues } = formikCtx.getFieldMeta<PersonAddress>(
						path
					);

					const state = states.find((s) => {
						return s.uf === addressInfo.uf.trim();
					});

					// eslint-disable-next-line no-console
					console.log('ADDRESS', addrValues);

					formikCtx.setFieldValue(path, {
						...addrValues,
						streetName: addressInfo.logradouro,
						district: addressInfo.bairro,
						state,
						cep,
					});

					onStateChange(state, false, addressInfo.cidade);
				}
			} catch (e) {
				formikCtx.setFieldError(`${path}.cep`, 'CEP não encontrado');
			} finally {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		const value = formikCtx.getFieldMeta(`${path}.state`).value as RegionState;
		if (value) {
			onStateChange(value, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		countryStateService
			.getStates()
			.then((s) => setStates(s))
			.catch(() => setStates([]));
	}, []);

	return (
		<>
			<Input className="d-none" name={`${path}.idAddress`} />
			{!editName ? (
				<Input className="d-none" name={`${path}.name`} />
			) : (
				<Form.Row>
					<Form.Group as={Col} lg={6}>
						<Form.Label>Identificação</Form.Label>
						<Input name={`${path}.name`} disabled={isLoading} />
						<Form.Text>Ex.: Casa, Apartamento, etc...</Form.Text>
					</Form.Group>
				</Form.Row>
			)}

			<Form.Row>
				<Form.Group as={Col} md={4}>
					<Form.Label>CEP</Form.Label>
					<Input
						name={`${path}.cep`}
						formatter={cepFormatter}
						onChange={onCEPChange}
						disabled={isLoading}
						append={
							isLoading && (
								<InputGroup.Text>
									<Spinner animation="border" size="sm" />
								</InputGroup.Text>
							)
						}
					/>
				</Form.Group>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col} lg={6}>
					<Form.Label>Endereço</Form.Label>
					<Input name={`${path}.streetName`} disabled={isLoading} />
				</Form.Group>

				<Form.Group as={Col} md={6} lg={2}>
					<Form.Label>Número</Form.Label>
					<Input
						name={`${path}.number`}
						formatter={numberFormatter}
						disabled={isLoading}
					/>
				</Form.Group>

				<Form.Group as={Col} md={6} lg={4}>
					<Form.Label>Complemento</Form.Label>
					<Input name={`${path}.complement`} disabled={isLoading} />
				</Form.Group>
			</Form.Row>

			<Form.Row>
				<Form.Group as={Col} md={4} lg={2}>
					<Form.Label>Estado</Form.Label>
					<Select
						name={`${path}.state`}
						options={states}
						optionLabel="uf"
						optionValue="idState"
						onChange={(value) => onStateChange(value, false)}
						disabled={isLoading}
					/>
				</Form.Group>

				<Form.Group as={Col} md={8} lg={4}>
					<Form.Label>Cidade</Form.Label>
					<Select
						name={`${path}.city`}
						isLoading={!cities}
						options={cities}
						optionLabel="name"
						optionValue="idCity"
						disabled={isLoading}
					/>
				</Form.Group>

				<Form.Group as={Col} lg={6}>
					<Form.Label>Bairro</Form.Label>
					<Input name={`${path}.district`} disabled={isLoading} />
				</Form.Group>
			</Form.Row>
		</>
	);
};

AddressForm.displayName = 'AddressForm';
AddressForm.whyDidYouRender = true;
AddressForm.defaultProps = {
	editName: false,
};

export default AddressForm;
