import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FormikProps,
	FormikHelpers,
} from 'formik';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import * as Yup from 'yup';

import AddressForm, {
	formSchema as addressFormSchema,
	defaultFormValue as defaultAddressFormValue,
} from 'components/AddressForm';
import {
	Input,
	AsyncSelect,
	Select,
	ToggleButtonGroup,
	ToggleButton,
	FormikEffect,
} from 'components/Formik';
import { useToast } from 'hooks';
import companyService from 'services/company.service';
import ecommerceService from 'services/ecommerce.service';
import { UrlParams } from 'types';
import {
	cnpjFormatter,
	cnaeFormatter,
	regexNumbersOnlyFormatter,
	phoneFormatter,
} from 'utils/formatters';
import history from 'utils/history';
import ValidateCNPJ from 'utils/Validator/CNPJ';

import {
	FormSubmitButton,
	PageHeader,
	FormCancelButton,
	FormGroupTitle,
} from 'packages/admin/components';

import CompanyIESTForm, {
	formSchema as iestFormSchema,
	defaultFormValue as defaultIESTFormValue,
} from './company/CompanyIESTForm';
import SkeletonForm from './company/SkeletonForm';
import { CompanyFormType } from './company/types';

export const defaultFormValue: CompanyFormType = {
	idPerson: null,
	name: '',
	socialName: '',
	linkType: 1,
	link: null,
	nRegister: '',
	municipalRegister: '',
	cnae: '',
	cpfCnpj: '',
	address: defaultAddressFormValue,
	phone: {
		number: '',
	},
	taxRegime: null,
	modules: [],
	st: defaultIESTFormValue,
};

export const formSchema = Yup.object<CompanyFormType>().shape({
	idPerson: Yup.mixed()
		.transform((val) => (!val ? null : Number(val)))
		.nullable()
		.notRequired(),
	name: Yup.string().required('Campo obrigatório'),
	socialName: Yup.string().required('Campo obrigatório'),
	linkType: Yup.number(),
	link: Yup.object()
		.when('linkType', {
			is: 2,
			then: Yup.object()
				.shape({
					idPerson: Yup.number(),
					name: Yup.string(),
				})
				.nullable()
				.required('Campo obrigatório'),
		})
		.nullable(),
	nRegister: Yup.string().required('Campo obrigatório'),
	municipalRegister: Yup.string().required('Campo obrigatório'),
	cnae: Yup.string().required('Campo obrigatório'),
	cpfCnpj: Yup.string()
		.transform((value: string): string => {
			if (!ValidateCNPJ(value)) {
				return '';
			}
			return value;
		})
		.required('CNPJ Inválido'),
	address: addressFormSchema,
	phone: Yup.object()
		.shape({
			idPhone: Yup.number().nullable().default(null),
			number: Yup.string()
				.min(10, 'Campo inválido')
				.max(11, 'Campo inválido')
				.notRequired(),
		})
		.nullable(),
	taxRegime: Yup.object().nullable().required('Campo obrigatório'),
	modules: Yup.array(),
	st: iestFormSchema,
});

const taxRegimeOptions = [
	{ idTaxRegime: 1, name: 'Simples Nacional' },
	{
		idTaxRegime: 2,
		name: 'Simples Nacional - excesso de sublime de receita bruta',
	},
	{ idTaxRegime: 3, name: 'Regime Normal' },
] as { idTaxRegime: number; name: string }[];

const CompanyEdit: React.FC = () => {
	const { id } = useParams<UrlParams>();
	const [toast] = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [company, setCompany] = useState<CompanyFormType>(defaultFormValue);

	useEffect(() => {
		if (id !== 'create') {
			companyService
				.getForId(Number(id))
				.then((c) => {
					// eslint-disable-next-line no-param-reassign
					c.linkType = c.link ? 2 : 1;
					setCompany(c as CompanyFormType);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
					toast('Não foi possível carregar os dados da Empresa', {
						type: 'error',
					});
				});
		} else {
			setIsLoading(false);
		}
	}, [id, toast]);

	const handleSubmit = useCallback(
		async (
			values: FormikValues,
			{ setSubmitting }: FormikHelpers<CompanyFormType>
		) => {
			const submit = { ...values } as CompanyFormType;

			if (!submit.phone?.idPhone && !submit.phone?.number.length) {
				submit.phone = null;
			}

			if (submit.linkType === 1) {
				submit.link = null;
			}

			setSubmitting(true);

			companyService
				.save(submit.idPerson, submit)
				.then(() => {
					toast(`Empresa ${id ? 'alterada' : 'criada'} com sucesso`, {
						type: 'success',
					});
					history.push('/admin/system/companies');
				})
				.catch((e) => {
					toast(`Não foi possível editar empresa. Cód: ${e.code}`, {
						type: 'error',
					});
				})
				.finally(() => setSubmitting(false));
		},
		[id, toast]
	);

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>
					{`${id !== 'create' ? 'Editar' : 'Nova'} Empresa`}
				</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Sistema', path: '/admin/system/companies', active: true },
						{
							label: 'Empresa',
							path: '/admin/system/companies',
							active: true,
						},
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<SkeletonForm />
			) : (
				<Formik
					onSubmit={handleSubmit}
					initialValues={company}
					validationSchema={formSchema}
					render={(values: FormikProps<CompanyFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<div className="d-none">
								<Input name="idPerson" />
							</div>

							<Card>
								<Card.Body>
									<FormGroupTitle className="d-flex justify-content-between">
										Dados Cadastrais
										<ToggleButtonGroup name="linkType">
											<ToggleButton variant="primary" value={1}>
												Matriz
											</ToggleButton>
											<ToggleButton variant="primary" value={2}>
												Filial
											</ToggleButton>
										</ToggleButtonGroup>
									</FormGroupTitle>
									<Form.Row>
										<Form.Group
											as={Col}
											md={values.values.linkType === 2 ? 6 : 12}
											lg={values.values.linkType === 2 ? 4 : 6}
										>
											<Form.Label>Nome</Form.Label>
											<Input name="name" type="text" />
										</Form.Group>

										<Form.Group
											as={Col}
											md={values.values.linkType === 2 ? 6 : 12}
											lg={values.values.linkType === 2 ? 4 : 6}
										>
											<Form.Label>Razão Social</Form.Label>
											<Input name="socialName" type="text" />
										</Form.Group>

										{values.values.linkType === 2 && (
											<Form.Group as={Col} md={6} lg={4}>
												<Form.Label>Matriz</Form.Label>
												<AsyncSelect
													name="link"
													fetchMethod={ecommerceService.getCompanies}
													optionValue="idPerson"
												/>
											</Form.Group>
										)}
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>CNPJ</Form.Label>
											<Input
												name="cpfCnpj"
												formatter={cnpjFormatter}
												type="text"
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>Inscrição Estadual</Form.Label>
											<Input
												name="nRegister"
												type="text"
												formatter={regexNumbersOnlyFormatter}
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>Inscrição Municipal</Form.Label>
											<Input
												name="municipalRegister"
												type="text"
												formatter={regexNumbersOnlyFormatter}
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>CNAE</Form.Label>
											<Input
												name="cnae"
												type="text"
												formatter={cnaeFormatter}
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>Regime Tributário</Form.Label>
											<Select
												name="taxRegime"
												optionLabel="name"
												options={taxRegimeOptions}
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Input
												name="phone.idPhone"
												className="d-none"
												value={company.phone?.idPhone}
											/>
											<Form.Label>Telefone</Form.Label>
											<Input
												name="phone.number"
												type="text"
												formatter={phoneFormatter}
											/>
										</Form.Group>
									</Form.Row>

									<FormGroupTitle marginTop>Endereço</FormGroupTitle>
									<AddressForm path="address" />

									<FormGroupTitle marginTop>
										Inscrição Estadual ST
									</FormGroupTitle>
									<CompanyIESTForm path="st" />
								</Card.Body>

								<Card.Footer className="text-right">
									<FormCancelButton to="/admin/system/companies" />
									<FormSubmitButton />
								</Card.Footer>
							</Card>
						</FormikForm>
					)}
				/>
			)}
		</Container>
	);
};

export default CompanyEdit;
