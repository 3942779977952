import { useContext } from 'react';

import context, { defaultDatagridDataContext } from './DatagridDataContext';

const useDatagridDataContext = () => {
	const ctx = useContext(context);
	// eslint-disable-next-line no-console
	console.assert(
		ctx !== defaultDatagridDataContext,
		'Chamado useDatagridDataContext() fora de contexto válido!'
	);
	return ctx;
};

export default useDatagridDataContext;
