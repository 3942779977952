import { useRouteMatch } from 'react-router-dom';

const useEcommerceBasePath = () => {
	const { path } = useRouteMatch();
	const index = path.indexOf('/', 1);

	if (index < 0) {
		return path;
	}
	return path.substring(0, index);
};

export default useEcommerceBasePath;
