import styled from 'styled-components';

import { darken, cssVar } from 'polished';

export const Container = styled.div``;

export const AddActions = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.875rem;

	.select__control,
	input {
		flex: 1;
		margin-right: 0.875rem;
	}
`;

export const DroppableContent = styled.div.attrs(
	(props: { isDraggingOver: boolean }) => ({
		isDraggingOver: props.isDraggingOver,
	})
)`
	border: ${(props) =>
		props.isDraggingOver
			? `2px dotted ${cssVar('--gray-20')}`
			: '1px dotted transparent'};
	padding: ${(props) =>
		props.isDraggingOver ? '0.5rem 0.5rem 0 0.5rem' : 'none'};
	border-radius: var(--base-border-radius);
	transition: all 0.2s;
`;

export const AddListItem = styled.div.attrs(
	(props: { isDragging: boolean }) => ({
		isDragging: props.isDragging,
	})
)`
	userselect: 'none';
	display: flex;
	align-items: center;
	border: 1px solid ${() => darken(0.03, cssVar('--light'))};
	box-shadow: var(--base-box-shadow);
	border-radius: var(--base-border-radius);
	padding: 0.575rem 0.875rem;
	margin-bottom: 0.5rem;
	background-color: var(--light);
	color: ${(props) =>
		props.isDragging
			? `${cssVar('--always-white')}`
			: `${cssVar('--base-color')}`};
	background: ${(props) =>
		props.isDragging ? `${cssVar('--primary')}` : `${cssVar('--light')}`};
	opacity: ${(props) => (props.isDragging ? 0.7 : 1)};

	span {
		margin-right: 0.875rem;

		svg {
			color: ${(props) =>
				props.isDragging
					? `${cssVar('--always-white')}`
					: `${cssVar('--gray-40')}`};
		}
	}
`;
