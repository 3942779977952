import styled from 'styled-components';

import { cssVar } from 'polished';

export const Container = styled.div.attrs((props: { hasError: boolean }) => ({
	hasError: props.hasError,
}))`
	.sun-editor {
		border-radius: var(--base-border-radius);
		border-color: ${(props) =>
			props.hasError ? cssVar('--danger') : cssVar('--gray-30')};
		z-index: 1;

		.se-container {
			.se-toolbar {
				outline: none;
				z-index: 2;
				border-bottom: ${(props) =>
					props.hasError
						? `1px solid ${cssVar('--danger')}`
						: `1px solid ${cssVar('--gray-30')}`};
			}

			.se-wrapper {
				border-radius: var(--base-border-radius);

				.se-wrapper-inner {
					&:focus {
						border: ${(props) =>
							props.hasError ? 'none' : `1px solid ${cssVar('--primary')}`};
						box-shadow: ${(props) =>
							props.hasError
								? 'inset 0 0 0 0.15rem rgba(240, 85, 96, 0.25)'
								: 'none'};
					}
				}
			}
		}

		.se-btn {
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}
`;
