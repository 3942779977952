import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Formik,
	Form as FormikForm,
	FormikProps,
	FieldArray,
	FormikHelpers,
} from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import * as Yup from 'yup';

import Card from 'components/Card';
import { FormikEffect } from 'components/Formik';
import Checkbox from 'components/Formik/Checkbox';
import Input from 'components/Formik/Input';
import { useThrottle, useToast } from 'hooks';
import timetableService from 'services/timetable.service';
import { Timetable, TimetablePermission } from 'types';
import { hourMinFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './schedule/SkeletonForm';
import {
	TimetableFormType,
	TimetablePermissionFormType,
} from './schedule/types';

const dayOfWeek = [
	'Domingo',
	'Segunda',
	'Terça',
	'Quarta',
	'Quinta',
	'Sexta',
	'Sábado',
];

export const defaultFormValue: TimetableFormType = {
	idTimetable: null,
	name: '',
	useSamePeriod: true,
	sameStart: '00:00',
	sameEnd: '23:59',
	permission: dayOfWeek.map((_, id) => ({
		idPermission: null,
		idDay: `${id + 1}`,
		start: '00:00',
		end: '23:59',
		isWorkDay: false,
	})),
};

export const formSchema = Yup.object<TimetableFormType>().shape({
	idTimetable: Yup.number().nullable(),
	name: Yup.string().required('Campo obrigatório'),
	useSamePeriod: Yup.bool(),
	sameStart: Yup.string().when('useSamePeriod', {
		is: true,
		then: Yup.string().required('Campo obrigatório'),
	}),
	sameEnd: Yup.string().when('useSamePeriod', {
		is: true,
		then: Yup.string().required('Campo obrigatório'),
	}),
	permission: Yup.array().of(
		Yup.object<TimetablePermissionFormType>()
			.shape({
				idPermission: Yup.string().nullable().notRequired(),
				idTimetable: Yup.number().notRequired(),
				idDay: Yup.string(),
				isWorkDay: Yup.bool().nullable().notRequired(),
				start: Yup.string().when('isWorkDay', {
					is: true,
					then: Yup.string().required('Campo obrigatório'),
				}),
				end: Yup.string().when('isWorkDay', {
					is: true,
					then: Yup.string().required('Campo obrigatório'),
				}),
			})
			.required()
	),
});

const ScheduleEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			let schedule: TimetableFormType = defaultFormValue;

			if (!isCreating) {
				const { permission, ...rest } = await timetableService.getById(
					Number(id)
				);

				const { sameStart, sameEnd } = defaultFormValue;
				const { start = sameStart, end = sameEnd } = permission[0] ?? {};

				const useSamePeriod = permission.reduce(
					(prev, next) => prev && next.start === start && next.end === end,
					true
				);
				const adjustedPermission = permission.map((p) => ({
					...p,
					start: p.start.substring(0, 5),
					end: p.end.substring(0, 5),
				}));

				schedule = {
					...rest,
					useSamePeriod,
					sameStart: start.substring(0, 5),
					sameEnd: end.substring(0, 5),
					permission: defaultFormValue.permission.map(
						(p): TimetablePermissionFormType => ({
							isWorkDay: true,
							...(adjustedPermission.find((a) => a.idDay === p.idDay) ?? {
								idDay: p.idDay,
								idPermission: null,
								start: p.start,
								end: p.end,
								isWorkDay: false,
							}),
						})
					),
				};
			}

			return { schedule };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends TimetableFormType>(
			id: string,
			{ permission, ...values }: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const submit: Timetable = {
				...values,
				permission: permission
					.filter((p) => p.isWorkDay)
					// eslint-disable-next-line no-unused-vars
					.map(({ isWorkDay, ...rest }): TimetablePermission => rest),
			};

			try {
				await timetableService.save(submit.idTimetable, submit);
				toast(`Horário ${id ? 'alterado' : 'criado'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/access/schedules');
			} catch (e) {
				toast(`Não foi possível editar o horário. Cód: ${e.code}`, {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[toast, history]
	);

	const changeAllPeriods = useCallback(
		(
			{ values, setFieldValue }: FormikProps<TimetableFormType>,
			start: string | null,
			end: string | null
		) => {
			values.permission.forEach((permission, index) => {
				if (permission.isWorkDay) {
					if (start) {
						setFieldValue(`permission[${index}].start`, start);
					}
					if (end) {
						setFieldValue(`permission[${index}].end`, end);
					}
				}
			});
		},
		[]
	);

	const onIsWorkDayChange = useCallback(
		(event, props: FormikProps<TimetableFormType>, index: number) => {
			const isChecked = event.target.checked;
			const { permission, sameStart, sameEnd, useSamePeriod } = props.values;

			let start = sameStart;
			let end = sameEnd;

			if (isChecked && !useSamePeriod) {
				start = permission[index].start || defaultFormValue.sameStart;
				end = permission[index].end || defaultFormValue.sameEnd;
			}

			props.setFieldValue(`permission.${index}.start`, start);
			props.setFieldValue(`permission.${index}.end`, end);
		},
		[]
	);

	const onUseSamePeriodChange = useCallback(
		(
			event: React.ChangeEvent<HTMLInputElement>,
			props: FormikProps<TimetableFormType>
		) => {
			const isChecked = event.target.checked;
			const { sameStart, sameEnd } = props.values;

			if (isChecked && sameStart && sameEnd) {
				changeAllPeriods(props, sameStart, sameEnd);
			}
		},
		[changeAllPeriods]
	);

	const onChangeSameInputs = useCallback(
		(value: string, props: FormikProps<TimetableFormType>, input: string) => {
			if (value.length < 5) return;

			if (props.values.useSamePeriod) {
				if (input === 'start') {
					changeAllPeriods(props, value, null);
				} else {
					changeAllPeriods(props, null, value);
				}
			}
		},
		[changeAllPeriods]
	);

	const onChangeInput = useThrottle(
		(
			value: string,
			fieldName: keyof TimetablePermissionFormType,
			index: number,
			props: FormikProps<TimetableFormType>
		) => {
			if (value.length < 5) return;

			const permissions = props.values.permission
				.map((p, i) => (i === index ? { ...p, [fieldName]: value } : p))
				.filter((p) => p.isWorkDay);

			const { sameStart, sameEnd } = defaultFormValue;
			const { start = sameStart, end = sameEnd } = permissions[0] ?? {};

			const anyDifferent = permissions.reduce(
				(prev, next) => prev || next.start !== start || next.end !== end,
				false
			);

			if (anyDifferent === props.values.useSamePeriod) {
				props.setFieldValue('useSamePeriod', !anyDifferent);
			}

			if (!anyDifferent) {
				props.setFieldValue('sameStart', start);
				props.setFieldValue('sameEnd', end);
			}
		},
		100
	);

	return (
		<PageLayoutFormWrapper
			title="Horário"
			breadcrumb={[
				{ label: 'Acesso', path: '/admin/access/schedules' },
				{ label: 'Horários', path: '/admin/access/schedules' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.schedule}
					validationSchema={formSchema}
				>
					{(form: FormikProps<TimetableFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content>
									<Form.Row>
										<Col lg={{ span: 6, offset: 3 }}>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" type="text" />
												</Form.Group>
											</Form.Row>

											<fieldset className="border rounded px-4 py-3 mb-3">
												<Form.Row>
													<Form.Group as={Col}>
														Usar o mesmo período para todos os dias selecionados
														abaixo
													</Form.Group>
												</Form.Row>

												<Form.Row>
													<Form.Group
														as={Col}
														md={{ span: 2, offset: 3 }}
														lg={{ span: 1, offset: 3 }}
														className="text-center"
													>
														<Form.Label>Usar</Form.Label>
														<Checkbox
															name="useSamePeriod"
															label=""
															style={{ position: 'relative', top: '8px' }}
															onChange={(event) => {
																onUseSamePeriodChange(event, form);
															}}
														/>
													</Form.Group>
													<Form.Group as={Col} md={4} lg={4}>
														<Form.Label>Entrada</Form.Label>
														<Input
															name="sameStart"
															formatter={hourMinFormatter}
															useFormattedValue
															onChange={(value) => {
																onChangeSameInputs(value, form, 'start');
															}}
															disabled={!form.values.useSamePeriod}
														/>
													</Form.Group>
													<Form.Group as={Col} md={4} lg={4}>
														<Form.Label>Saída</Form.Label>
														<Input
															name="sameEnd"
															formatter={hourMinFormatter}
															useFormattedValue
															onChange={(value) => {
																onChangeSameInputs(value, form, 'end');
															}}
															disabled={!form.values.useSamePeriod}
														/>
													</Form.Group>
												</Form.Row>
											</fieldset>

											<fieldset className="border rounded px-4 py-3 mb-3">
												<Form.Row>
													<Form.Group
														as={Col}
														md={{ span: 4, offset: 4 }}
														lg={{ span: 4, offset: 4 }}
													>
														<Form.Label>Entrada</Form.Label>
													</Form.Group>
													<Form.Group as={Col} md={4} lg={4}>
														<Form.Label>Saída</Form.Label>
													</Form.Group>
												</Form.Row>
												<FieldArray name="permission">
													{() =>
														form.values.permission.map((day, idx) => (
															<div key={day.idDay}>
																<Form.Row className="d-flex align-items-end">
																	<Form.Group as={Col} md={3} sm={3}>
																		<Form.Label>{dayOfWeek[idx]}</Form.Label>
																	</Form.Group>
																	<Form.Group
																		as={Col}
																		sm={2}
																		md={2}
																		lg={1}
																		className="text-center"
																	>
																		<Checkbox
																			name={`permission.${idx}.isWorkDay`}
																			label=""
																			style={{
																				position: 'relative',
																				top: '-8px',
																			}}
																			onChange={(event) => {
																				onIsWorkDayChange(event, form, idx);
																			}}
																		/>
																	</Form.Group>
																	<Form.Group as={Col} md={4} lg={4}>
																		<Input
																			name={`permission.${idx}.start`}
																			formatter={hourMinFormatter}
																			useFormattedValue
																			onChange={(value) => {
																				onChangeInput(
																					value,
																					'start',
																					idx,
																					form
																				);
																			}}
																			disabled={
																				!form.values.permission[idx].isWorkDay
																			}
																		/>
																	</Form.Group>
																	<Form.Group as={Col} md={4} lg={4}>
																		<Input
																			name={`permission.${idx}.end`}
																			formatter={hourMinFormatter}
																			useFormattedValue
																			onChange={(value) => {
																				onChangeInput(value, 'end', idx, form);
																			}}
																			disabled={
																				!form.values.permission[idx].isWorkDay
																			}
																		/>
																	</Form.Group>
																</Form.Row>
															</div>
														))
													}
												</FieldArray>
											</fieldset>
										</Col>
									</Form.Row>

									<Form.Row className="text-right">
										<Col
											lg={{ span: 6, offset: 3 }}
											md={{ span: 8, offset: 2 }}
										>
											<FormCancelButton to="/admin/access/schedules" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default ScheduleEdit;
