import styled from 'styled-components';

import classNames from 'classnames';

export const Title = styled.div.attrs((props) => ({
	className: 'font-weight-light',
	...props,
}))`
	min-height: 6rem;
`;

export const OldPrice = styled.div`
	min-height: 1.2rem;
`;

export const Price = styled.div.attrs((props) => ({
	className: classNames(props.className, 'h2 font-weight-bold'),
}))``;
