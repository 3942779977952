import {
	HttpGetListParams,
	HttpGetListResult,
	NetworkNumbers,
	NumbersByStateDash,
	NumbersActivationMonth,
} from 'types';

import Crud from './crud.service';

const getNetworkNumbers = (): Promise<NetworkNumbers> =>
	Crud.getOne<NetworkNumbers>('allExecutive/numbers', 0);

const getNumbersByStateDash = (
	params: HttpGetListParams
): Promise<HttpGetListResult<NumbersByStateDash>> =>
	Crud.getList<NumbersByStateDash>('allExecutive/numbersByState', params);

const getNumbersActivationMonth = (
	params: HttpGetListParams
): Promise<HttpGetListResult<NumbersActivationMonth>> =>
	Crud.getList<NumbersActivationMonth>(
		'allExecutive/numbersActivation',
		params
	);

export default {
	getNetworkNumbers,
	getNumbersByStateDash,
	getNumbersActivationMonth,
};
