import React from 'react';

import { Row, Col, Form, FormGroup } from 'react-bootstrap';

import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import useDatagridContext from 'components/datagrid/useDatagridContext';
import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import financialService from 'services/financial.service';
import { dateFormatter } from 'utils/formatters';

import {
	FilterContainer,
	DateFilter,
	FilterButton,
	ExportButton,
} from 'packages/admin/components/datatableView';

type PayoutAlertFilterType = {
	date?: string | null;
	endDate?: string | null;
};

const defaultFilterValues: PayoutAlertFilterType = {
	date: '',
	endDate: '',
};

const validationSchema = Yup.object().shape<PayoutAlertFilterType>({
	date: Yup.string().nullable().notRequired(),
	endDate: Yup.string()
		.nullable()
		.when('date', (initialDate: string, schema: Yup.DateSchema) =>
			schema.test(
				'is_valid_date_range',
				'A Data final precisa ser posterior à Data inicial',
				(endDate) =>
					!endDate ||
					new Date(endDate).getTime() - new Date(initialDate || 0).getTime() >=
						0
			)
		)
		.notRequired(),
});

const columnsExport: string[] = [
	'ID',
	'Data do Pedido',
	'Executivo',
	'Pedido',
	'Valor do Pedido',
	'Responsável',
	'Status',
	'Motivo',
];

const PayoutAlertFilters: React.FC = () => {
	const { tableParams } = useDatagridContext();
	const { isLoading } = useDatagridDataContext();
	const date =
		tableParams.filters.length > 0 ? tableParams.filters[0].query : '';
	const endDate =
		tableParams.filters.length > 1 ? tableParams.filters[1].query : '';

	return (
		<>
			<FilterContainer
				initialValues={defaultFilterValues}
				validationSchema={validationSchema}
			>
				<Form.Row>
					<Col xl={4} lg={5} md={6} xs={7}>
						<Row>
							<FormGroup as={Col} sm={6} xs={12}>
								<Form.Label>Data inicial</Form.Label>
								<DateFilter
									selectDate
									name="date"
									isQuery={true}
									queryOperator=">>"
								/>
							</FormGroup>
							<FormGroup as={Col} sm={6} xs={12}>
								<Form.Label>Data final</Form.Label>
								<DateFilter
									selectDate
									name="endDate"
									isQuery={true}
									queryParam="date"
									queryOperator="<<"
								/>
							</FormGroup>
						</Row>
					</Col>
					<FormGroup as={Col}>
						<br />
						<FilterButton className="m-1 mr-4" disabled={isLoading} />
						<ExportButton
							title="Alerta de Pagamentos"
							filterData={`${dateFormatter.format(date)}${
								endDate ? ` - ${dateFormatter.format(endDate)}` : ''
							}`}
							to="csv"
							action={financialService.getPayoutAlerts}
							columns={columnsExport}
						>
							<FontAwesomeIcon icon={faFileCsv} className="mr-2" /> Exportar
						</ExportButton>
					</FormGroup>
				</Form.Row>
			</FilterContainer>
		</>
	);
};

export default PayoutAlertFilters;
