import { HttpGetListParams, HttpGetListResult, Activation } from 'types';

import Crud from './crud.service';

const getList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Activation>> =>
	Crud.getList<Activation>('mmn/activation', params);

export default {
	getList,
};
