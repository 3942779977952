import React from 'react';

import Container from 'react-bootstrap/Container';

import { Timeline } from 'components';
import Skeleton from 'components/Skeleton';

const NotificationsSkeleton: React.FC = () => (
	<Container>
		<Timeline>
			{[...Array(4).keys()].map((key) => (
				<Timeline.Item key={key}>
					<Timeline.Date>
						<Skeleton height="100%" />
					</Timeline.Date>
					<Timeline.Badge />
					<Timeline.Panel>
						<Timeline.Header>
							<Timeline.HeaderIcon>
								<Skeleton height="100%" />
							</Timeline.HeaderIcon>
							<Timeline.Content>
								<Skeleton height="1.5rem" width="15ch" />
							</Timeline.Content>
						</Timeline.Header>
						<Timeline.Content>
							<span className="mb-2 ml-3 mt-3">
								<Skeleton width="15ch" />
							</span>
						</Timeline.Content>
						<Timeline.Body>
							<Skeleton width="20ch" height="1.2rem" />
							{'\n\n'}
							<Skeleton height="1.2rem" />
							<Skeleton height="1.2rem" />
							<Skeleton height="1.2rem" />
							{'\n\n'}
							<Skeleton width="10ch" height="1.2rem" />
						</Timeline.Body>
					</Timeline.Panel>
				</Timeline.Item>
			))}
		</Timeline>
	</Container>
);

export default NotificationsSkeleton;
