import React, { useMemo } from 'react';

import { useQuery } from 'react-query';

import { Card, Skeleton } from 'components';
import { useToast } from 'hooks';
import orderService from 'services/order.service';
import { OrderMonthSummary } from 'types';

import { SummaryTable } from './monthSummary/styles';
import { StyledHeader, StyledTitle } from './styles';

type MonthSummaryData = {
	total: number;
	amakha: number;
	paSells: number;
	executiveOrders: number;
	inventory: number;
	superBonus: number;
	activationKits: number;
};

const dataMap: Record<OrderMonthSummary['name'], keyof MonthSummaryData> = {
	TOTAL: 'total',
	AMAKHA: 'amakha',
	PAVENDA: 'paSells',
	EXECUTIVO: 'executiveOrders',
	ESTOQUE: 'inventory',
	SUPERBONUS: 'superBonus',
	ATIVACAO: 'activationKits',
};

const MonthSummary: React.FC = () => {
	const [toast] = useToast();

	const { data, isLoading } = useQuery<OrderMonthSummary[]>(
		['month_summary'],
		() => orderService.getOrderMonthSummary().then((r) => r.data),
		{
			onError: () => {
				toast('Não foi possível carregar os dados de resumo do mês', {
					type: 'error',
				});
			},
			retry: true,
			retryDelay: 10000,
		}
	);

	const summaries = useMemo(() => {
		return data?.reduce((prev, next) => {
			return next.name
				? { ...prev, [dataMap[next.name]]: Number(next.value) }
				: prev;
		}, {} as MonthSummaryData);
	}, [data]);

	return (
		<Card>
			<StyledHeader>
				<StyledTitle>Resumo Geral do Mês</StyledTitle>
			</StyledHeader>
			{/* TODO: Fazer tabela com css grid? */}
			<SummaryTable bordered hover>
				<tbody>
					<tr>
						<td>Total de Pedidos</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.total ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>Pedidos Comprados na Amakha</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.amakha ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>Pedidos Comprados no PA</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.paSells ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>Pedidos de Executivos</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.executiveOrders ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>Pedidos de Estoque</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.inventory ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>SuperBônus</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.superBonus ?? 0
							)}
						</td>
					</tr>
					<tr>
						<td>Kits</td>
						<td className="text-center w-25">
							{isLoading ? (
								<Skeleton height="1.2rem" display="block" />
							) : (
								summaries?.activationKits ?? 0
							)}
						</td>
					</tr>
				</tbody>
			</SummaryTable>
		</Card>
	);
};

export default MonthSummary;
