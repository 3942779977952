import { Reducer } from 'react';

import { SessionState, SessionActionEnum, SessionActionTypes } from './types';

const initialState: SessionState = {
	user: null,
	permissions: [],
	lastError: null,
};

const AuthReducer: Reducer<SessionState, SessionActionTypes> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case SessionActionEnum.LoginRequest:
		case SessionActionEnum.ForgotRequest:
		case SessionActionEnum.RedefineRequest:
		case SessionActionEnum.LogoutRequest:
			return {
				...initialState,
			};
		case SessionActionEnum.LoginSuccess:
			return {
				...state,
				...action.payload,
			};
		case SessionActionEnum.LoginFailed:
			return {
				...state,
				lastError: action.payload,
			};
		case SessionActionEnum.ForgotSuccess:
			return {
				...state,
				...action.payload,
			};
		case SessionActionEnum.ForgotFailed:
			return {
				...state,
				lastError: action.payload,
			};
		case SessionActionEnum.RedefineSuccess:
			return {
				...state,
				...action.payload,
			};
		case SessionActionEnum.RedefineFailed:
			return {
				...state,
				lastError: action.payload,
			};
		case SessionActionEnum.LoadLoggedSuccess:
			return {
				...state,
				...action.payload,
			};
		case SessionActionEnum.LoadLoggedFailed:
			return {
				...state,
				lastError: action.payload,
			};
		default:
			return state;
	}
};

export default AuthReducer;
