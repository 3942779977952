import styled from 'styled-components';

export const LinkContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const Link = styled.a`
	margin-top: 3rem;
`;

export const ImageBeAPa = styled.img`
	max-width: 900px;
	min-width: 400px;
	width: 100%;
`;
