import {
	HttpGetListParams,
	HttpGetListResult,
	OrderStatement,
	ExecutiveStatement,
	ID,
	HttpPostResultAny,
	Balance,
	StatementManualEntry,
	StatementPayout,
	StatementPayoutRequest,
	TransferAmk,
	TransferEntry,
	CashierCompany,
	Bank,
	StatementApprovalEntry,
	StatementSetApprovalEntry,
	InsertCreditDebitEntry,
	StatementPayoutAlert,
} from 'types';

import Crud from './crud.service';

/**
 * Service de extratos financeiros
 */
const getStatementByOrderId = (
	params: HttpGetListParams
): Promise<HttpGetListResult<OrderStatement>> => {
	return Crud.getList<OrderStatement>(
		'financial/statementByOrder/{id}/company/{company}',
		params
	);
};

const getStatementByExecutiveId = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveStatement>> => {
	return Crud.getList<ExecutiveStatement>(
		'financial/statementByPerson/{id}?month={month}&year={year}',
		params
	);
};

const getBalanceByExecutiveId = (id: ID): Promise<number> =>
	Crud.getOne<Balance>('financial/balance/{id}', id).then(
		(result) => Number(result.total) // Converte para número pq O backend envia uma string
	);

const getApprovalEntries = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StatementApprovalEntry>> =>
	Crud.getList<StatementApprovalEntry>('financial/approval', params);

const setApprovalEntries = (data: StatementSetApprovalEntry) =>
	Crud.create('financial/approval', data);

const getManualEntries = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StatementManualEntry>> =>
	Crud.getList<StatementManualEntry>(
		'financial/manualBonus?month={month}&year={year}',
		params
	);

const setManualEntries = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StatementManualEntry>> =>
	Crud.getList<StatementManualEntry>(
		'financial/manualBonus?month={month}&year={year}',
		params
	);

const createCreditDebitRequest = (data: InsertCreditDebitEntry) =>
	Crud.create('financial', data);

// TODO: Alterar para getPayout?
const getPayoutRequests = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StatementPayoutRequest>> =>
	Crud.getList<StatementPayoutRequest>('financial/payout', params);

// TODO: Alterar para getPayoutRequests?
const getPayoutAlerts = (
	params: HttpGetListParams
): Promise<HttpGetListResult<StatementPayoutAlert>> =>
	Crud.getList<StatementPayoutAlert>('financial', params);

const getPayoutRequest = (id: string | number): Promise<StatementPayout> =>
	Crud.getOne<StatementPayout>('financial/payout/{id}', id as number);

const setPayoutRequest = (
	id: string | number,
	data: { paid: number }
): Promise<HttpPostResultAny> =>
	Crud.patch<{ paid: number }>('financial/payout/{id}', id as number, data);

const createTransfer = (data: TransferEntry | TransferAmk) =>
	Crud.create('statement', data);

const getCashierByCompany = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CashierCompany>> =>
	Crud.getList<CashierCompany>(
		'financial/cashierByCompany/{id}?date={date}',
		params
	);

const getBankById = (id: ID): Promise<Bank> =>
	Crud.getOne<Bank>('financial/bank/{id}', id);

const getBankList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Bank>> => {
	return Crud.getList<Bank>('financial/bank', params);
};

export default {
	getStatementByExecutiveId,
	getStatementByOrderId,
	getBalanceByExecutiveId,
	getApprovalEntries,
	setApprovalEntries,
	getManualEntries,
	setManualEntries,
	createCreditDebitRequest,
	getPayoutAlerts,
	getPayoutRequests,
	getPayoutRequest,
	setPayoutRequest,
	createTransfer,
	getCashierByCompany,
	getBankById,
	getBankList,
};
