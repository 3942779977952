import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Skel from 'react-loading-skeleton';
import styled from 'styled-components';

import { Panel, Separator, ButtonsContainer } from './styles';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props}>
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const TransferSkeletonForm: React.FC = () => (
	<Card>
		<Card.Body className="offset-lg-3 col-lg-6">
			<Panel>
				<Skeleton height="3rem" width="260px" />
			</Panel>

			<Separator />

			<Form.Row>
				<SkelGroup as={Col} md={12} />
			</Form.Row>

			<Form.Row>
				<SkelGroup as={Col} md={12} />
			</Form.Row>

			<Separator />

			<ButtonsContainer>
				<Skeleton width="100px" height="2.2rem" />
			</ButtonsContainer>
		</Card.Body>
	</Card>
);

export default TransferSkeletonForm;
