import styled from 'styled-components';

import { cssVar, darken } from 'polished';

export const Container = styled.div``;

export const ProductList = styled.div`
	height: 640px;
	overflow-x: auto;
`;

export const ProductListItem = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid ${() => darken(0.03, cssVar('--light'))};
	box-shadow: var(--base-box-shadow);
	border-radius: var(--base-border-radius);
	padding: 0.575rem 0.875rem;
	margin-bottom: 0.5rem;
	background-color: var(--light);
`;
