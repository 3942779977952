import React from 'react';

import { Col, Form, Row } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

const CareerEditSkeleton: React.FC = () => (
	<Card>
		<Card.Header separator justifyContent="space-between">
			<div />
			<Form.Group className="mb-0">
				<Skeleton width="10ch" height="1.8rem" display="block" />
			</Form.Group>
		</Card.Header>
		<Card.Content>
			<Row>
				<Col md={8}>
					<Form.Row>
						<Form.Group as={Col}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>

						<Col sm="auto" className="flex-grow-1" />
					</Form.Row>
				</Col>
				<Col md={4}>
					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton
								width="100%"
								// height="20rem"
								padding={`0 0 ${(1.0 / (4 / 3)) * 100}% 0`}
							/>
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
		</Card.Content>
		<Card.Content className="text-right border-top">
			<Form.Row>
				<Col>
					<Skeleton width="10ch" height="2.5rem" margin="0 1rem 0 0" />
					<Skeleton width="10ch" height="2.5rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default CareerEditSkeleton;
