import {
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResult,
	ID,
} from 'types';
import {
	TicketForList,
	Ticket,
	TicketCategory,
	TicketSubject,
	TicketStatus,
	TicketAttach,
	TicketTimeline,
	TicketComment,
	TicketHistory,
	TicketDetails,
} from 'types/ticket.types';

import Crud from './crud.service';

/**
 * Service de requisição de dados de suporte
 */

const getTickets = (
	params: HttpGetListParams
): Promise<HttpGetListResult<TicketForList>> =>
	Crud.getList<TicketForList>('ticket', params);

const getTicketsForPerson = (
	params: HttpGetListParams
): Promise<HttpGetListResult<TicketForList>> =>
	Crud.getList<TicketForList>('person/{id}/ticket', params);

const getTicket = (id: ID): Promise<Ticket> =>
	Crud.getOne<Ticket>('ticket/{id}', id as number);

const getCategories = (
	params: HttpGetListParams
): Promise<HttpGetListResult<TicketCategory>> =>
	Crud.getList<TicketCategory>('ticket/category', params);

const getSubjects = (
	params: HttpGetListParams
): Promise<HttpGetListResult<TicketSubject>> =>
	Crud.getList<TicketSubject>('ticket/category/{id}/subject', params);

const getAllStatus = (
	params: HttpGetListParams
): Promise<HttpGetListResult<TicketStatus>> =>
	Crud.getList<TicketStatus>('ticket/status', params);

const getTicketStatus = (id?: ID): Promise<TicketStatus> =>
	Crud.getOne<TicketStatus>('ticket/status/{id}', id as number);

const getAttachs = (
	id: ID,
	params: HttpGetListParams
): Promise<TicketAttach[]> =>
	Crud.getListData<TicketAttach>(`ticket/${id}/attach`, params);

const getTimeline = (
	id: ID,
	params: HttpGetListParams
): Promise<TicketTimeline[]> =>
	Crud.getListData<TicketTimeline>(`ticket/${id}/timeline`, params);

const getComments = (
	id: ID,
	params: HttpGetListParams
): Promise<TicketComment[]> =>
	Crud.getListData<TicketComment>(`ticket/${id}/comments`, params);

const getHistories = (
	id: ID,
	params: HttpGetListParams
): Promise<TicketHistory[]> =>
	Crud.getListData<TicketHistory>(`ticket/${id}/history`, params);

const getTicketsDetails = (): Promise<TicketDetails> =>
	Crud.getOne<TicketDetails>('ticket/detail', null);

const getPersonTicketDetails = (id: ID): Promise<TicketDetails> =>
	Crud.getOne<TicketDetails>('person/{id}/ticketDetail', id as number);

const createTicket = (ticket: Ticket): Promise<HttpPostResult<unknown>> =>
	Crud.create<Ticket>('ticket', ticket);

const createComment = (
	comment: TicketComment
): Promise<HttpPostResult<unknown>> =>
	Crud.create<TicketComment>(`ticket/${comment.idTicket}/comment`, comment);

const createHistory = (
	idTicket: ID,
	history: TicketHistory
): Promise<HttpPostResult<unknown>> =>
	Crud.create<TicketHistory>(`ticket/${idTicket}/history`, history);

const createAttach = (
	idTicket: ID,
	attach: TicketAttach[]
): Promise<HttpPostResult<unknown>> =>
	Crud.create<TicketAttach[]>(`ticket/${idTicket}/attach`, attach);

export default {
	getTickets,
	getTicket,
	getTicketsForPerson,
	getTicketStatus,
	getTicketsDetails,
	getTimeline,
	getAttachs,
	getComments,
	getCategories,
	getSubjects,
	getAllStatus,
	getHistories,
	getPersonTicketDetails,
	createAttach,
	createHistory,
	createComment,
	createTicket,
};
