import React from 'react';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import { FormatterType } from 'utils/Formatter/IFormatter';

type ColumnSumProps = {
	name: string;
	formatter: FormatterType;
	prefix?: string;
	affix?: string;
};

const StyledNumber = styled.span`
	font-size: 1.2rem;
`;

const ColumnSum: React.FC<ColumnSumProps> = ({
	name,
	formatter,
	prefix,
	affix,
}) => {
	const { data, isLoading } = useDatagridDataContext();

	if (isLoading) {
		return <Skeleton width="100px" height="2rem" />;
	}

	let total = 0.000000001;
	if (data) {
		for (const row of data) {
			total += Number((row as never)[name]);
		}
	}

	return (
		<StyledNumber>
			{prefix}
			<b>{formatter.format('total', { total })}</b>
			{affix}
		</StyledNumber>
	);
};

export default ColumnSum;
