import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { OrderForList } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

const PayoutPaymentButton: React.FC<ButtonLinkComponentProps<OrderForList>> = ({
	row,
}) => {
	if (row.idStatus !== 1) return <>{row.status}</>;

	return (
		<Button
			as={Link}
			to={`/app/order/checkout/${row.idOrder}`}
			variant="success"
			size="sm"
		>
			Pagar
		</Button>
	);
};

export default PayoutPaymentButton;
