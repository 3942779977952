import { createContext } from 'react';

import { DatagridData } from './types';

export const defaultDatagridDataContext = {
	data: [],
	error: null,
	isLoading: false,
	pagination: null,
};

const DatagridDataContext = createContext<DatagridData>(
	defaultDatagridDataContext
);

export default DatagridDataContext;
