import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { cover } from 'polished';

export type BackdropImageProps = {
	src: string;
	glass?: boolean;
	fixed?: boolean;
	align?: string;
	zIndex?: number;
	size?: 'cover' | 'contain' | 'inherit' | 'unset' | 'initial' | 'auto';
};

const BackdropImage = styled.div`
	${cover()}
	z-index: ${({ zIndex }) => zIndex || 0};
	${({
		src,
		fixed,
		align,
	}: BackdropImageProps): FlattenSimpleInterpolation => css`
		background: url(${src}) no-repeat ${align || 'center center'}
			${fixed ? 'fixed' : ''};
	`}
	${({ glass }) =>
		glass
			? css`
					filter: blur(3px);
			  `
			: css``}
			background-size: ${({ size }) => size || 'cover'};
`;

export default BackdropImage;
