import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
	StyledWidget,
	WidgetColumn,
	WidgetIcon,
	WidgetInfo,
	WidgetTitle,
} from './widget/styles';

type WidgetType = {
	icon: IconProp;
	color: string;
};

const Widget: React.FC<WidgetType> = ({ icon, color, children, ...rest }) => {
	return (
		<StyledWidget {...rest}>
			{icon && <WidgetIcon icon={icon} color={color} />}
			{children}
		</StyledWidget>
	);
};

export default Object.assign(Widget, {
	Title: WidgetTitle,
	Info: WidgetInfo,
	Column: WidgetColumn,
});
