import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Skel from 'react-loading-skeleton';
import styled from 'styled-components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props} className="py-2">
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const AccessUsersFormSkeleton: React.FC = () => (
	<Card>
		<Card.Body>
			<Row>
				<Col lg={8}>
					<div className="text-right">
						<Skeleton width={70} />
					</div>

					<Row>
						<Col lg={5}>
							<Form.Label>
								<Label />
							</Form.Label>
							<Skeleton height="9rem" width="180px" />
						</Col>

						<Col lg={7}>
							<Row>
								<SkelGroup as={Col} lg={12} />
								<SkelGroup as={Col} lg={12} />
							</Row>
							<Row>
								<SkelGroup as={Col} lg={12} />
								<SkelGroup as={Col} lg={12} />
							</Row>

							<Row>
								<Col className="text-center">
									<Skeleton width={80} height="1.5rem" />
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col lg={4}>
					<Form.Label>
						<Label />
					</Form.Label>
					<Skeleton height="1.5rem" count={10} />
				</Col>
			</Row>
		</Card.Body>

		<Card.Footer className="text-right">
			<span className="mr-2">
				<Skel width={100} height="2rem" />
			</span>
			<Skel width={100} height="2rem" />
		</Card.Footer>
	</Card>
);

export default AccessUsersFormSkeleton;
