import React, { useCallback, useState } from 'react';

import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'react-query';

import {
	faEdit,
	faTrash,
	faPlusCircle,
	faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationModal } from 'modals';

import { Card, Radio } from 'components';
import { useModal, useSessionContext, useToast } from 'hooks';
import myService from 'services/my.service';
import { ApiCodes, HttpBaseResult, PersonAddress, PersonType } from 'types';

import { PageLayout } from 'packages/admin/components';

import AddressFormModal from './address/modals/AddressFormModal';
import SkeletonForm from './address/SkeletonForm';
import { AddressItem, AddressItemNew } from './address/styles';

const Address: React.FC = () => {
	const [toast] = useToast();
	const [isFetchingBilling, setIsFetchingBilling] = useState<boolean>(false);
	const open = useModal();
	const {
		auth: { person },
	} = useSessionContext();
	const canEdit = person?.idType !== PersonType.Distributor;

	const { data, isLoading, refetch } = useQuery(
		['get_addresses'],
		() => myService.getAddresses(0).then((res) => res.reverse()),
		{
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	const handleEdit = useCallback(
		(address: PersonAddress | null) => {
			if (!canEdit) return;
			open(AddressFormModal, { data: address }, (value?: unknown) => {
				if (value) {
					refetch();
				}
			});
		},
		[canEdit, open, refetch]
	);

	const handleRemove = useCallback(
		({ idAddress, name }) => {
			if (!canEdit) return;
			open(
				ConfirmationModal,
				{
					ButtonConfirmText: 'Remover',
					message: (
						<p className="mb-0">
							Tem certeza que deseja remover o endereço <strong>{name}</strong>?
						</p>
					),
				},
				async (value?: unknown) => {
					if (!value) {
						return;
					}

					try {
						const { message } = await myService.deleteAddress(idAddress);
						// eslint-disable-next-line no-console
						console.log('Success: ', message);
						toast(`Endereço ${name} removido com sucesso`, {
							type: 'success',
						});
						await refetch();
					} catch (e) {
						const { code, message } = e as HttpBaseResult;
						// eslint-disable-next-line no-console
						console.log('Error message: ', message);
						toast(ApiCodes.getCodeString(code), {
							type: 'error',
						});
					}
				}
			);
		},
		[canEdit, open, refetch, toast]
	);

	const changeIsBilling = useCallback(
		async (address: PersonAddress) => {
			if (!canEdit) return;
			const newAddress = { ...address, isBilling: true };
			setIsFetchingBilling(true);

			try {
				const { message } = await myService.createOrUpdateAddress(
					address.idAddress,
					newAddress
				);
				// eslint-disable-next-line no-console
				console.log('Success: ', message);
				toast(`Endereço ${address.name} selecionado como principal`, {
					type: 'success',
				});
				await refetch();
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			} finally {
				setIsFetchingBilling(false);
			}
		},
		[canEdit, refetch, toast]
	);

	return (
		<PageLayout
			title="Endereços"
			breadcrumb={[
				{ label: 'Cadastro', path: '/app/my/details' },
				{ label: 'Endereços', active: true },
			]}
		>
			{isLoading ? (
				<SkeletonForm />
			) : (
				<>
					{!canEdit && (
						<Alert variant="warning">
							<FAIcon icon={faExclamationTriangle} className="mr-2" />
							Para alterações de endereço entre em contato com nosso
							atendimento.
						</Alert>
					)}
					<div className="d-flex flex-wrap">
						{data &&
							data.map((item) => (
								<AddressItem key={item.idAddress}>
									<Card.Header
										separator
										justifyContent="space-between"
										alignItems="center"
									>
										<Card.Title>{item.name}</Card.Title>
										{canEdit && (
											<div>
												<OverlayTrigger
													overlay={
														<Tooltip id={`tooltip-edit-${item.idAddress}`}>
															Editar
														</Tooltip>
													}
												>
													<Button
														variant="link"
														size="sm"
														className="text-info"
														onClick={() => handleEdit(item)}
													>
														<FAIcon icon={faEdit} size="lg" />
													</Button>
												</OverlayTrigger>
												{!item.isBilling && (
													<OverlayTrigger
														overlay={
															<Tooltip id={`tooltip-remove-${item.idAddress}`}>
																Remover
															</Tooltip>
														}
													>
														<Button
															variant="link"
															size="sm"
															className="text-danger"
															onClick={() =>
																handleRemove({
																	idAddress: item.idAddress,
																	name: item.name,
																})
															}
														>
															<FAIcon icon={faTrash} size="lg" />
														</Button>
													</OverlayTrigger>
												)}
											</div>
										)}
									</Card.Header>
									<Card.Content>
										<p>
											{item.streetName}, {item.number}{' '}
											{item.complement ? `- ${item.complement}` : ''}
											<br />
											{item.cep} - {item.district}
											<br />
											{item.city?.name}/{item.state?.uf}
										</p>
									</Card.Content>
									<Card.Content className="d-flex justify-content-between">
										<Radio
											label="Selecionar como principal"
											checked={item.isBilling}
											onChange={() => changeIsBilling(item)}
											disabled={!canEdit || isFetchingBilling}
										/>
									</Card.Content>
								</AddressItem>
							))}

						{canEdit && (
							<AddressItemNew>
								<Button
									variant="link"
									className="d-flex flex-column align-items-center text-success"
									onClick={() => handleEdit(null)}
								>
									<FAIcon icon={faPlusCircle} size="3x" className="mb-2" />
									Novo Endereço
								</Button>
							</AddressItemNew>
						)}
					</div>
				</>
			)}
		</PageLayout>
	);
};

export default Address;
