import React, { useEffect, useState } from 'react';

import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Input, Select } from 'components/Formik';
import countryStateService from 'services/country-state.service';
import { RegionState } from 'types';
import RegexFormatter, { REGEX_CLEAR_NUMERIC } from 'utils/Formatter/Regex';

import { CompanyIESTType } from './types';

export const defaultFormValue: CompanyIESTType[] = [];

export const formSchema = Yup.array().of(
	Yup.object().shape({
		idState: Yup.string().min(1, '').required(''),
		state: Yup.object().required('Campo obrigatório'),
		ieST: Yup.string().trim().default('').required('Campo obrigatório'),
	})
);

type CompanyIESTProps = {
	path: string;
};
const numberFormatter = new RegexFormatter(REGEX_CLEAR_NUMERIC, '');

const CompanyIESTForm: React.FC<CompanyIESTProps> = ({ path }) => {
	const [states, setStates] = useState<RegionState[]>([]);
	const formikCtx = useFormikContext();
	const { value: values } = formikCtx.getFieldMeta<CompanyIESTType[]>(path);

	const setupStates = (sts: RegionState[]): void => {
		const { value } = formikCtx.getFieldMeta<CompanyIESTType[]>(path);
		const iest = [...value];

		for (const st of iest) {
			st.state = sts.find((s) => s.idState === st.idState) as RegionState;
		}
		formikCtx.setFieldValue(path, iest);
		setStates(sts);
	};

	useEffect(() => {
		countryStateService
			.getStates()
			.then((s) => setupStates(s))
			.catch(() => setStates([]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addNewST = (arrayHelper: ArrayHelpers) => {
		const { value } = formikCtx.getFieldMeta<CompanyIESTType[]>(path);
		const notUsedState = states.find(
			(s) => !value.find((st) => st.state === s)
		) as RegionState;
		arrayHelper.push({
			idState: notUsedState?.idState,
			state: notUsedState,
			ieST: '',
		});
	};

	return (
		<FieldArray
			name={path}
			render={(arrayHelper: ArrayHelpers) => (
				<>
					{values.map((st: CompanyIESTType, i: number) => (
						<Form.Row key={st.idState}>
							<Input
								className="d-none"
								type="text"
								name={`${path}[${i}].idState`}
								value={st.idState || ''}
							/>
							<Form.Group as={Col} xs={3} sm={4} md={3} lg={2}>
								<Select
									name={`${path}[${i}].state`}
									options={states}
									optionLabel="uf"
								/>
							</Form.Group>
							<Form.Group as={Col} xs={9} sm={8} md={6} lg={4}>
								<Input
									type="text"
									name={`${path}[${i}].ieST`}
									append={
										<Button
											variant="outline-danger"
											onClick={() => arrayHelper.remove(i)}
										>
											<FAIcon icon="trash" />
										</Button>
									}
									formatter={numberFormatter}
								/>
							</Form.Group>
						</Form.Row>
					))}
					{values.length < 27 && (
						<Button
							type="button"
							variant="outline-info"
							onClick={() => addNewST(arrayHelper)}
						>
							Adicionar ST
						</Button>
					)}
				</>
			)}
		/>
	);
};

export default CompanyIESTForm;
