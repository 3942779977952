import React, { useState, useCallback } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Input, Backdrop } from 'components';

export type SearchInputProps = {};

const ZIndexDiv = styled.div`
	position: relative;
	width: 70%;
	transition: var(--base-transition-all);
	&.focused {
		z-index: calc(var(--dropdown-zindex) - 1);
	}
`;

const SearchContainer: React.FC<SearchInputProps> = () => {
	const [focused, setFocused] = useState<boolean>(false);

	const onBlur = useCallback(() => {
		setFocused(false);
	}, [setFocused]);

	const onFocus = useCallback(() => {
		setFocused(true);
	}, [setFocused]);

	return (
		<>
			<Backdrop.Fade show={focused} />
			<ZIndexDiv className={focused ? 'focused w-100' : ''}>
				<Input
					size="lg"
					placeholder="O que você procura?"
					className={classNames(
						'border-right-0',
						focused ? 'bg-white' : 'bg-light'
					)}
					onFocus={onFocus}
					onBlur={onBlur}
					append={
						<InputGroup.Text
							className={classNames(
								'border border-left-0',
								focused && 'border-primary',
								focused ? 'bg-white' : 'bg-light'
							)}
						>
							<FAIcon icon={faSearch} />
						</InputGroup.Text>
					}
				/>
			</ZIndexDiv>
		</>
	);
};

export default SearchContainer;
