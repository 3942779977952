import { Popover, PopoverProps } from 'react-bootstrap';
import styled, { css } from 'styled-components';

type StoreContainerProps = {
	isInvalid?: boolean;
};

export const ListPopoverWrapper = styled(Popover)<PopoverProps>`
	width: 400px;
	max-width: 90vw;
	padding: 1rem;

	background: var(--base-bg);
	box-shadow: var(--base-box-shadow);

	/* Ajuste para popup com drag and drop */
	/* transform: unset !important;
	top: 2.5rem !important;
	left: -4.8rem !important; */
`;

export const StoreContainer = styled.div<StoreContainerProps>`
	${({ isInvalid }) =>
		isInvalid
			? css`
					border: solid 1px var(--danger);
					border-radius: var(--border-radius);
					padding: 0.25rem;
			  `
			: ''}
`;

export const StoreItemContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.25rem;
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 50%;
	margin-left: auto;

	> span {
		position: absolute;
		margin-top: 0.15rem;
	}
`;
