import React, { useEffect } from 'react';

import Background from './Background';
import ModalContext from './modal/ModalContext';
import ModalHeader from './modal/ModalHeader';
import {
	Backdrop,
	ModalDialog,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from './modal/styles';
import { ModalProps } from './modal/types';

const Modal: React.FC<ModalProps> = ({
	show,
	onShow: _,
	onHide,
	onExited,
	size,
	exitOnBackdropClick,
	children,
	variant,
	...rest
}) => {
	//
	// moralRef.dismiss() chama o onHide() internamente, isso faz com que o show seja igual a false
	// Quando isso acontece, o effect aguarda 300ms para concluir a animação do modal e chama o onExited()
	useEffect(() => {
		if (!show) {
			setTimeout(() => onExited?.(), 300);
		}
	}, [show, onExited]);

	return (
		<ModalContext.Provider value={{ handleOnClose: onHide }}>
			<Backdrop show={show} onClick={exitOnBackdropClick ? onHide : undefined}>
				<ModalDialog
					show={show}
					size={size}
					variant={variant}
					{...rest}
					onClick={(e) => e.stopPropagation()}
				>
					<Background variant={variant} />
					{children}
				</ModalDialog>
			</Backdrop>
		</ModalContext.Provider>
	);
};

Modal.defaultProps = {
	exitOnBackdropClick: true,
};

export default Object.assign(Modal, {
	Header: ModalHeader,
	Title: ModalTitle,
	Body: ModalBody,
	Footer: ModalFooter,
});
