/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Button, Col } from 'react-bootstrap';
import { ArrayQueryKey, useQuery } from 'react-query';

import { Skeleton } from 'components';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { ExecutiveDetails, ID } from 'types';
import { ElementOf } from 'utils';
import {
	cepFormatter,
	cnpjFormatter,
	cpfFormatter,
	phoneFormatter,
} from 'utils/formatters';

import { UserDetailsDialogConfig } from '../types';

export type UserDetailsModalProps = {
	row: { id: ID };
	config?: Partial<UserDetailsDialogConfig>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeRedundancy = <T extends ReadonlyArray<any>>(
	data: T
): ElementOf<T>[] => data.filter((v, i) => data.indexOf(v) === i);

const UserDetailsModal: React.FC<
	ModalForwardProps<UserDetailsModalProps, boolean>
> = ({ row, config, modalRef, ...rest }) => {
	const { showCpfCnpj = false, showContactOnly = true } = config || {};
	const [toast] = useToast();

	const { data: executive, isLoading: isLoadingExecutive } = useQuery<
		ExecutiveDetails,
		ArrayQueryKey
	>(
		['user_details_executive', row.id],
		() => executiveService.getAllExecutiveById(row.id),
		{
			enabled: !!row.id,
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar os dados do executivo.', {
					type: 'error',
				});
				modalRef.dismiss();
			},
		}
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			{isLoadingExecutive ? (
				<>
					<Modal.Header closeButton>
						<Skeleton width="150px" height="2rem" />
					</Modal.Header>
					<Modal.Body>
						<Col>
							<p className="mb-3" style={{ width: '70%' }}>
								<Skeleton width="8ch" />
							</p>
							<p className="mb-3" style={{ width: '70%' }}>
								<Skeleton width="20ch" display="block" margin="0.3rem 0" />
								<Skeleton width="20ch" display="block" margin="0.3rem 0" />
								<Skeleton width="30ch" display="block" margin="0.3rem 0" />
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
							</p>
							<p className="mb-3" style={{ width: '70%' }}>
								<Skeleton width="30ch" display="block" margin="0.3rem 0" />
								<Skeleton width="30ch" display="block" margin="0.3rem 0" />
							</p>
							<p className="mb-3" style={{ width: '70%' }}>
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
								<Skeleton width="4ch" display="block" margin="0.3rem 0" />
								<Skeleton width="15ch" display="block" margin="0.3rem 0" />
							</p>
						</Col>
					</Modal.Body>
					<Modal.Footer align="end">
						<Skeleton width="100px" height="2rem" />
					</Modal.Footer>
				</>
			) : (
				executive && (
					<>
						<Modal.Header closeButton>
							<Modal.Title>{executive.name}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Col>
								<p>
									<strong>ID: </strong>
									{`${executive.id}`}
									<br />
								</p>
								{!showContactOnly && (
									<p>
										{/* // TODO: Verificar se a descrição do status está correta */}
										<strong>Status: </strong>
										{executive.idStatus.toString() === '3'
											? 'Ativo'
											: 'Inativo'}
										<br />
										{executive.subscription && (
											<>
												<strong>Assinatura: </strong>
												{executive.subscription.name}
												<br />
											</>
										)}
										{executive.sponsor && (
											<>
												<strong>Patrocinador: </strong>
												{`${executive.sponsor.name} (ID: ${executive.sponsor.idSponsor})`}
												<br />
											</>
										)}
										{showCpfCnpj && executive.cpfCnpj.length <= 11 && (
											<>
												<strong>CPF: </strong>
												{cpfFormatter.format(executive.cpfCnpj)}
												<br />
												<strong>RG: </strong>
												{executive.nRegister}
												<br />
											</>
										)}
										{showCpfCnpj && executive.cpfCnpj.length > 11 && (
											<>
												<strong>CNPJ: </strong>
												{cnpjFormatter.format(executive.cpfCnpj)}
												<br />
												<strong>IE: </strong>
												{executive.nRegister}
												<br />
											</>
										)}
									</p>
								)}
								<p>
									{executive.email && (
										<>
											<strong>E-mail: </strong>
											{executive.email}
											<br />
										</>
									)}
									{executive.phone &&
										removeRedundancy(executive.phone).map((phone) => (
											<React.Fragment key={phone.idPhone}>
												<strong>Tel.:</strong>{' '}
												{phoneFormatter.format(phone.number)}
												<br />
											</React.Fragment>
										))}
								</p>
								{!showContactOnly &&
									executive.address &&
									// TODO: Corrigir na API para quando não existir endereço receber null ao invés de array vazia
									(Array.isArray(executive.address)
										? executive.address.length > 0
										: true) && (
										<p>
											<strong>Endereço: </strong>
											{`${executive.address.streetName}, ${
												executive.address.number
													? `${executive.address.number}`
													: 'S/N'
											}`}
											<br />
											<strong>Bairro: </strong>
											{executive.address.district}
											<br />
											<strong>Cidade: </strong>
											{executive.address.city.name}
											<br />
											<strong>Estado: </strong>
											{executive.address.state.uf}
											<br />
											<strong>CEP: </strong>
											{cepFormatter.format(executive.address.cep)}
										</p>
									)}
							</Col>
						</Modal.Body>
						<Modal.Footer align="end">
							<Button variant="primary" onClick={() => modalRef.dismiss()}>
								Ok
							</Button>
						</Modal.Footer>
					</>
				)
			)}
		</Modal>
	);
};

export default UserDetailsModal;
