import { CorreiosAddress, CorreiosTracking, CorreiosShipping } from 'types';

import Crud from './crud.service';

const getAddressForCEP = (cep: string): Promise<CorreiosAddress> =>
	Crud.getOne<CorreiosAddress>(`correios/cep/${cep}`, null);

const getTracking = (id: string): Promise<CorreiosTracking> =>
	Crud.getOne<CorreiosTracking>(`correios/tracking/${id}`, null);

const getShippingPrice = (
	cep: string,
	type: 'PAC' | 'sedex',
	weight: number
): Promise<CorreiosShipping> =>
	Crud.getOne<CorreiosShipping>(
		`correios/shipping/${cep}?weight=${weight}&type=${type}`,
		null
	);

export default {
	getAddressForCEP,
	getTracking,
	getShippingPrice,
};
