import { Reducer } from 'react';

import { ID } from 'types';

import {
	ShoppingCartState,
	ShoppingCartActionTypes,
	ShoppingCartActionEnum,
	ShoppingCart,
	SHOPPING_CART_VERSION,
	MAX_PRODUCT_PER_CART,
} from './types';

type PayloadForItems = {
	cartName: string;
	id: ID;
	quantity: number;
};

type PayloadForRemoveItems = {
	cartName: string;
	id: ID;
};

const initialState: ShoppingCartState = {
	version: SHOPPING_CART_VERSION,
	cart: {},
};

const clearCarts = (state: ShoppingCartState, carts: string[]) => {
	const newState = {
		...state,
	};
	carts.forEach((cartName) => {
		delete newState.cart[cartName];
	});

	return newState;
};

const keepCarts = (state: ShoppingCartState, carts: string[]) => {
	const newState: ShoppingCartState = {
		version: state.version,
		cart: {},
	};
	carts.forEach((cartName) => {
		newState.cart[cartName] = state.cart[cartName];
	});
	return newState;
};

const getCart = (
	state: ShoppingCartState,
	cartName: string
): [ShoppingCartState, ShoppingCart] => {
	const newState = { ...state, cart: { ...state.cart } };

	if (!newState.cart[cartName]) {
		newState.cart[cartName] = { items: [] };
	} else {
		newState.cart[cartName] = { ...newState.cart[cartName] };
	}

	return [newState, newState.cart[cartName]];
};

const getItem = (itemCart: ShoppingCart, id: ID) => {
	const cart = itemCart;
	const itemIndex = cart.items.findIndex((it) => it.id === id);
	const item = { id, quantity: 1 };
	if (itemIndex >= 0) {
		item.quantity = cart.items[itemIndex].quantity;
		cart.items = [...cart.items];
		cart.items.splice(itemIndex, 1, item);
	} else {
		cart.items = [...cart.items, item];
	}
	return item;
};

const getItemIfExists = (cart: ShoppingCart, id: ID) => {
	const itemIndex = cart.items.findIndex((it) => it.id === id);
	if (itemIndex >= 0) {
		return cart.items[itemIndex];
	}
	return null;
};

const updateItemQuantity = (
	state: ShoppingCartState,
	payload: PayloadForItems
) => {
	const { cartName, id, quantity } = payload;
	const [newState, cart] = getCart(state, cartName);
	const itemExists = !!getItemIfExists(cart, id);

	if (itemExists) {
		const item = getItem(cart, id);
		item.quantity = Math.min(
			MAX_PRODUCT_PER_CART,
			Math.max(1, item.quantity + quantity)
		);
		return newState;
	}
	return state;
};

const setItemQuantity = (
	state: ShoppingCartState,
	payload: PayloadForItems
) => {
	const { cartName, id, quantity } = payload;
	const [newState, cart] = getCart(state, cartName);
	const itemExists = !!getItemIfExists(cart, id);

	if (itemExists) {
		const item = getItem(cart, id);
		item.quantity = Math.min(MAX_PRODUCT_PER_CART, Math.max(1, quantity));
		return newState;
	}

	return state;
};

const addItem = (state: ShoppingCartState, payload: PayloadForItems) => {
	const { cartName, id, quantity } = payload;
	const [newState, cart] = getCart(state, cartName);

	if (process.env.NODE_ENV !== 'production') {
		if (getItemIfExists(cart, id)) {
			// eslint-disable-next-line no-console
			console.warn('Tentando adicionar item ja existente ao carrinho', payload);
			return state;
		}
	}

	const item = getItem(cart, id);
	item.quantity = Math.min(MAX_PRODUCT_PER_CART, Math.max(1, quantity));

	return newState;
};

const removeItem = (
	state: ShoppingCartState,
	payload: PayloadForRemoveItems
) => {
	const { cartName, id } = payload;
	const [newState, cart] = getCart(state, cartName);

	if (process.env.NODE_ENV !== 'production') {
		const item = getItemIfExists(cart, id);
		if (!item) {
			// eslint-disable-next-line no-console
			console.warn('Tentando remover item que não existe no carrinho', payload);
			return state;
		}
	}

	cart.items = cart.items.filter((it) => it.id !== id);
	if (!cart.items.length) {
		return clearCarts(state, [cartName]);
	}

	return newState;
};

const ShoppingCartReducer: Reducer<
	ShoppingCartState,
	ShoppingCartActionTypes
> = (state = initialState, action) => {
	switch (action.type) {
		case ShoppingCartActionEnum.Clear:
			return clearCarts(state, action.payload.cartNames);
		case ShoppingCartActionEnum.ClearAll:
			return keepCarts(state, action.payload.skipCartNames);
		case ShoppingCartActionEnum.SetQuantity:
			return setItemQuantity(state, action.payload);
		case ShoppingCartActionEnum.UpdateQuantity:
			return updateItemQuantity(state, action.payload);
		case ShoppingCartActionEnum.AddItem:
			return addItem(state, action.payload);
		case ShoppingCartActionEnum.RemoveItem:
			return removeItem(state, action.payload);
		default:
			return state;
	}
};

export default ShoppingCartReducer;
