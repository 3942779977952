import styled, { keyframes } from 'styled-components';

import RcDrawer from 'rc-drawer';

const drawerFadeIn = keyframes`
  0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

export const RcDrawerStyle = styled(RcDrawer).attrs(
	(props: { zIndex: number | string | undefined }) => ({
		zIndex: props.zIndex,
	})
)`
	position: fixed;
	z-index: ${({ zIndex }) => zIndex || 'var(--modal-zindex)'};
	width: 0%;
	height: 100%;
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s,
		width 0s ease 0.3s;

	> * {
		transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
			box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	}

	.drawer-content-wrapper {
		position: absolute;

		.drawer-content {
			width: 100%;
			height: 100%;
		}
	}

	&.drawer-left,
	&.drawer-right {
		top: 0;
		width: 0%;
		height: 100%;

		.drawer-content-wrapper {
			height: 100%;
		}

		&.drawer-open {
			width: 100%;
			transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
		}

		&.drawer-open.no-mask {
			width: 0%;
		}
	}

	&.drawer-left {
		left: 0;

		.drawer-content-wrapper {
			left: 0;
		}

		&.drawer-open {
			.drawer-content-wrapper {
				box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
					9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
			}
		}
	}

	&.drawer-right {
		right: 0;

		.drawer-content-wrapper {
			right: 0;
		}

		&.drawer-open {
			.drawer-content-wrapper {
				box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
					-9px 0 28px 0 rgba(0, 0, 0, 0.05),
					-12px 0 48px 16px rgba(0, 0, 0, 0.03);
			}

			&.no-mask {
				right: 1px;
				transform: translateX(1px);
			}
		}
	}

	&.drawer-top,
	&.drawer-bottom {
		left: 0;
		width: 100%;
		height: 0%;

		.drawer-content-wrapper {
			width: 100%;
		}

		&.drawer-open {
			height: 100%;
			transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
		}

		&.drawer-open.no-mask {
			height: 0%;
		}
	}

	&.drawer-top {
		top: 0;

		&.drawer-open {
			.drawer-content-wrapper {
				box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
					0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
			}
		}
	}

	&.drawer-bottom {
		bottom: 0;

		.drawer-content-wrapper {
			bottom: 0;
		}

		&.drawer-open {
			.drawer-content-wrapper {
				box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
					0 -9px 28px 0 rgba(0, 0, 0, 0.05),
					0 -12px 48px 16px rgba(0, 0, 0, 0.03);
			}

			&.no-mask {
				bottom: 1px;
				transform: translateY(1px);
			}
		}
	}

	&.drawer-open {
		.drawer-mask {
			height: 100%;
			opacity: 1;
			transition: none;
			animation: ${drawerFadeIn} 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
		}
	}

	.drawer-content {
		position: relative;
		z-index: 1;
		overflow: auto;
		background-color: var(--always-white);
		background-clip: padding-box;
		border: 0;
	}

	.drawer-mask {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: rgba(0, 0, 0, 0.45);
		opacity: 0;
		transition: opacity 0.3s linear, height 0s ease 0.3s;
	}

	&.drawer-open {
		.drawer-content {
			box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
				0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
		}
	}
`;

export const DrawerWrapperBodyStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
`;

export const DrawerBodyStyle = styled.div`
	flex-grow: 1;
	overflow: auto;
	font-size: 14px;
	line-height: 1.5;
	word-wrap: break-word;
`;

export const DrawerHeaderStyle = styled.header`
	position: relative;
	color: var(--base-color);
	background: var(--always-white);
	border-bottom: 1px solid #e2e7f1;
	border-radius: 2px 2px 0 0;

	div {
		margin: 0;
		color: var(--base-color);
		font-weight: 500;
		font-size: calc(0.875rem * 1.25);
		line-height: 22px;
	}

	.drawer-header-no-title {
		color: var(--base-color);
		background: var(--always-white);
	}
`;

export const DrawerFooterStyle = styled.footer`
	flex-shrink: 0;
	border-top: 1px solid #e2e7f1;
`;
