import React, { useCallback } from 'react';

import {
	Formik,
	Form as FormikForm,
	FormikProps,
	FormikValues,
	FieldArray,
} from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as Yup from 'yup';

import { Button, Card, List } from 'components';
import { FormikEffect, Input } from 'components/Formik';
import { useToast } from 'hooks';
import commissionService from 'services/commission.service';
import { Commission, CommissionTable } from 'types';
import { history } from 'utils';
import { currencyFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './comission/SkeletonForm';

export const defaultFormValue: Commission = {
	idComission: null,
	name: '',
	commissionTable: [{ level: 1, value: '' }],
};

export const formSchema = Yup.object<Commission>().shape({
	name: Yup.string().required('Campo obrigatório'),
	levels: Yup.array().of(
		Yup.object<CommissionTable>().shape({
			level: Yup.string(),
			value: Yup.string().trim().default('').required('Campo obrigatório'),
		})
	),
});

const CommissionEdit: React.FC = () => {
	const [toast] = useToast();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return { commission: { ...defaultFormValue } };
			}

			const commission = await commissionService.getById(Number(id));
			if (commission.commissionTable.length === 0) {
				commission.commissionTable = [{ level: 1, value: '' }];
			}

			return { commission };
		},
		[]
	);

	const handleSubmit = useCallback(
		async (id: string, values: FormikValues, { setSubmitting }) => {
			const submit = { ...values } as Commission;
			const isCreating = id === 'create';

			submit.commissionTable = submit.commissionTable.map((ct) => ({
				...ct,
				level: String(ct.level),
			}));

			try {
				await commissionService.createOrUpdate(submit.idComission, submit);
				toast(`Comissão ${isCreating ? 'criada' : 'alterada'} com sucesso`, {
					type: 'success',
				});

				history.push('/admin/mmn/commissions');
			} catch (e) {
				toast(
					`Não foi possível ${
						isCreating ? 'criar' : 'editar'
					} a comissão. Cód: ${e.code}`,
					{
						type: 'error',
					}
				);
			}

			setSubmitting(false);
		},
		[toast]
	);

	const onRemoveTable = useCallback(
		(item: CommissionTable, form: FormikProps<Commission>) => {
			const { commissionTable } = form.values;
			const tableRemoved = commissionTable.filter(
				(ct) => ct.level !== item.level
			);
			const sortedValues = tableRemoved.map((ct, idx) => ({
				...ct,
				level: idx + 1,
			}));
			form.setFieldValue('commissionTable', sortedValues);
		},
		[]
	);

	return (
		<PageLayoutFormWrapper
			title="Comissão"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/summary' },
				{
					label: 'Comissões',
					path: '/admin/mmn/commissions',
				},
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.commission}
					validationSchema={formSchema}
				>
					{(form: FormikProps<Commission>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content>
									<Row>
										<Col
											sm={12}
											md={{ span: 8, offset: 2 }}
											xl={{ span: 6, offset: 3 }}
										>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Nome</Form.Label>
													<Input
														name="name"
														type="text"
														disabled={form.isSubmitting}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Níveis</Form.Label>
													<FieldArray name="commissionTable">
														{(arrayHelper) => (
															<div className="d-flex">
																<List
																	data="commissionTable"
																	className="flex-grow-1 mr-2"
																	sortField="level"
																	onChangeItems={(tables) =>
																		form.setFieldValue(
																			'commissionTable',
																			tables
																		)
																	}
																	onRemoveItem={(item) =>
																		onRemoveTable(item, form)
																	}
																	hasRemoveButton={
																		form.values.commissionTable.length !== 1
																	}
																	dragInDrop
																>
																	{(item, index) => (
																		<div className="d-flex align-items-center w-100">
																			<h4 className="ml-1 mr-3 mb-0">
																				{item.level}
																			</h4>
																			<Input
																				name={`commissionTable[${index}].value`}
																				type="text"
																				formatter={currencyFormatter}
																				disabled={form.isSubmitting}
																			/>
																		</div>
																	)}
																</List>
																<Button
																	variant="link"
																	className="align-self-end"
																	style={{ marginBottom: '15px' }}
																	disabled={form.isSubmitting}
																	onClick={() =>
																		arrayHelper.push({
																			level:
																				form.values.commissionTable.length + 1,
																			value: '',
																		})
																	}
																>
																	Adicionar
																</Button>
															</div>
														)}
													</FieldArray>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="border-top">
									<Row>
										<Col
											sm={12}
											md={{ span: 8, offset: 2 }}
											xl={{ span: 6, offset: 3 }}
										>
											<div className="text-right">
												<FormCancelButton
													isSubmitting={form.isSubmitting}
													to="/admin/mmn/commissions"
												/>
												<FormSubmitButton isSubmitting={form.isSubmitting} />
											</div>
										</Col>
									</Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default CommissionEdit;
