import React from 'react';

import { Col, Form, Row } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

const ConfigEditSkeleton: React.FC = () => (
	<Card>
		<Card.Content className="offset-lg-2 col-lg-8">
			<Row>
				<Col sm={12}>
					<Form.Row>
						<Form.Group as={Col} sm={12}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} md={4}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
						<Form.Group as={Col} sm={6}>
							<Skeleton width="15ch" height="1rem" />
							<Skeleton height="2.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} sm={6} className="d-inline mb-0 mt-2">
							<Skeleton width="35ch" height="1.8rem" />
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
		</Card.Content>
		<Card.Content className="text-right border-top">
			<Form.Row>
				<Col>
					<Skeleton width="10ch" height="2.5rem" margin="0 1rem 0 0" />
					<Skeleton width="10ch" height="2.5rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default ConfigEditSkeleton;
