/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { useField } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Skeleton from 'react-loading-skeleton';
import { Props, OptionsType, OptionTypeBase } from 'react-select';

import classNames from 'classnames';

import { HttpGetListParams, HttpGetListResult } from 'types';

import useFirstRender from '../../hooks/useFirstRender';
import { StyledAsyncSelect } from '../select/styles';

export type AsyncSelectFetchMethod<T> = (
	prams: HttpGetListParams
) => Promise<HttpGetListResult<T>>;

export type AsyncSelectFilterQuery = (input: string) => string[];

export interface SelectProps extends Props<any> {
	name: string;
	fetchMethod: AsyncSelectFetchMethod<unknown>;
	fetchQuery?: AsyncSelectFilterQuery;
	fetchSortByKey?: string;
	fetchSortByDesc?: boolean;
	fetchParams?: Record<string, any>;
	onChange?: (value: any) => void;
	optionLabel?: string;
	optionValue?: string;
	defaultValue?: OptionTypeBase;
	disabled?: boolean;
	loadMessage?: React.ReactNode;
	noOptionMessage?: React.ReactNode;
	placeholder?: string | React.ReactNode;
}

const LoadingIndicator: React.FC = () => <Skeleton height="2rem" count={5} />;

const NoOptionsMessage: React.FC = () => <>Sem dados</>;

const defaultProps = {
	placeholder: 'Selecione',
	loadMessage: <LoadingIndicator />,
	noOptionMessage: <NoOptionsMessage />,
};

const defaultFetchQuery: AsyncSelectFilterQuery = (input: string) => {
	if (input.trim()) {
		return [`"~name": "*${input.trim()}*"`];
	}
	return [];
};

const SelectField: React.FC<SelectProps> = (props) => {
	const isFirstRender = useFirstRender();
	const {
		name,
		optionLabel,
		optionValue,
		defaultValue,
		fetchMethod,
		fetchQuery = defaultFetchQuery,
		fetchSortByKey = undefined,
		fetchSortByDesc = false,
		fetchParams = {},
		onChange,
		...rest
	} = props;

	const [field, { error, touched }, helpers] = useField(name);

	useEffect(() => {
		if (defaultValue) {
			helpers.setValue(defaultValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadOptions = async (
		inputValue: string,
		_: OptionsType<any>,
		{ page }: { page: number }
	) => {
		const query = fetchQuery(inputValue);

		const newParams: HttpGetListParams = {
			query: !query.length ? undefined : query,
			pagination: {
				page,
				paginate: 100,
			},
			params: {
				...fetchParams,
			},
		};

		if (fetchSortByKey) {
			newParams.pagination!.sort = !fetchSortByDesc
				? fetchSortByKey
				: `-${fetchSortByKey}`;
		}

		const result = await fetchMethod(newParams);
		return {
			options: result.data,
			hasMore: result.meta?.current_page !== result.meta?.last_page,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<>
			<InputGroup>
				<StyledAsyncSelect
					className={classNames(error && touched && 'is-invalid')}
					name={field.name}
					value={(isFirstRender && defaultValue) || field.value || ''}
					loadOptionsOnMenuOpen
					cacheUniqIds={false}
					defaultOptions
					loadOptions={loadOptions}
					classNamePrefix="select"
					getOptionLabel={(option: any) => {
						return optionLabel ? option[optionLabel] : option.name;
					}}
					getOptionValue={(option: any) => {
						return optionValue ? option[optionValue] : option;
					}}
					onChange={(value: any) => {
						helpers.setValue(value);
						onChange?.(value);
					}}
					onBlur={field.onBlur}
					additional={{ page: 1 }}
					debounceTimeout={1000}
					{...rest}
				/>
				{error && touched && (
					<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
				)}
			</InputGroup>
		</>
	);
};

SelectField.displayName = 'Formik/AsyncSelect';
SelectField.defaultProps = defaultProps;
SelectField.whyDidYouRender = true;

export default SelectField;
