import { ID } from 'types';

export const SHOPPING_CART_VERSION = '1.0.0';
export const MAX_PRODUCT_PER_CART = 10000;

export enum ShoppingCartActionEnum {
	Clear = '@shoppingcart/CLEAR',
	ClearAll = '@shoppingcart/CLEAR_ALL',
	AddItem = '@shoppingcart/ADD_ITEM',
	RemoveItem = '@shoppingcart/REMOVE_ITEM',
	UpdateQuantity = '@shoppingcart/UPDATE_QUANTITY',
	SetQuantity = '@shoppingcart/SET_QUANTITY',
}

export type ShoppingCartItem = {
	id: ID;
	quantity: number;
};

export type ShoppingCart = {
	items: ShoppingCartItem[];
};

export type ShoppingCartState = {
	version: string;
	cart: { [cartName: string]: ShoppingCart };
};

export type ShoppingCartActionTypes =
	| {
			type: ShoppingCartActionEnum.Clear;
			payload: { cartNames: string[] };
	  }
	| {
			type: ShoppingCartActionEnum.ClearAll;
			payload: { skipCartNames: string[] };
	  }
	| {
			type: ShoppingCartActionEnum.AddItem;
			payload: { cartName: string; id: ID; quantity: number };
	  }
	| {
			type: ShoppingCartActionEnum.RemoveItem;
			payload: { cartName: string; id: ID };
	  }
	| {
			type: ShoppingCartActionEnum.UpdateQuantity;
			payload: { cartName: string; id: ID; quantity: number };
	  }
	| {
			type: ShoppingCartActionEnum.SetQuantity;
			payload: { cartName: string; id: ID; quantity: number };
	  };
