import {
	PersonTypeModel,
	HttpGetListParams,
	HttpGetListResult,
	PersonAddress,
	HttpPostResult,
} from 'types';

import Crud from './crud.service';

const getTypes = (
	params: HttpGetListParams,
	route = 'person/type'
): Promise<HttpGetListResult<PersonTypeModel>> =>
	Crud.getList<PersonTypeModel>(route, params);

const getDepartments = (
	params: HttpGetListParams,
	route = 'user/department'
): Promise<HttpGetListResult<PersonTypeModel>> =>
	Crud.getList<PersonTypeModel>(route, params);

const getDepartmentsForSelect = (
	params: HttpGetListParams,
	route = 'user/department'
): Promise<HttpGetListResult<PersonTypeModel>> =>
	Crud.getList<PersonTypeModel>(route, params);

const createAddress = (
	data: PersonAddress,
	route = 'person/address'
): Promise<HttpPostResult<{ idAddress: number }>> => Crud.create(route, data);

export default {
	getTypes,
	getDepartments,
	getDepartmentsForSelect,
	createAddress,
};
