import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div``;

export const Timeline = styled.ul`
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;

	.label {
		display: inline;
		padding: 0.2em 0.6em 0.3em;
		font-size: 0.9em;
		font-weight: 700;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25em;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 4px;
		background: var(--gray-20);
		left: 31px;
		margin: 0;
		border-radius: 2px;
	}
`;

export const TimelineListItem = styled.li`
	position: relative;
	margin-right: 10px;
	margin-bottom: 15px;
`;

export const TimelineIcon = styled.span`
	width: 30px;
	height: 30px;
	line-height: 32px;
	position: absolute;
	background: var(--gray-30);
	border-radius: 50%;
	text-align: center;
	left: 18px;
	top: 0;
	color: var(--light);
`;

export const TimelineItem = styled(Card)`
	border-radius: 2px;
	margin-top: 0;
	background: var(--always-white);
	color: var(--base-color);
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin-left: 60px;
	margin-right: 15px;
	padding: 0;
	position: relative;
`;

export const TimelineHeader = styled(Card.Header)`
	color: #555;
	background: var(--gray-10);

	> a {
		font-weight: 600;
	}
`;

export const TimelineBody = styled(Card.Body)`
	padding: 10px;
	white-space: pre-line;
`;

export const TimelineHeaderTime = styled.span`
	color: #aaaaaa;
	font-size: 12px;
	font-weight: 400;
`;

export const TimeLabel = styled.li`
	position: relative;
	margin-right: 10px;
	margin-bottom: 15px;

	> span {
		font-weight: 600;
		padding: 5px;
		display: inline-block;
		border-radius: 4px;
		background-color: var(--secondary);
		color: var(--light);
	}
`;
