import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import { Card, Skeleton as Skel } from 'components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props}>
		<Form.Label>
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const PasswordSkeletonForm: React.FC = () => (
	<Card>
		<Card.Header separator>
			<Skel width="200px" height="2rem" />
		</Card.Header>

		<Card.Content className="offset-lg-3 col-lg-6">
			<Form.Row className="mb-3">
				<SkelGroup as={Col} md={12} />
			</Form.Row>

			<Form.Row>
				<SkelGroup as={Col} md={12} />
			</Form.Row>

			<Form.Row>
				<SkelGroup as={Col} md={12} />
			</Form.Row>
		</Card.Content>

		<Card.Content className="border-top">
			<div className="text-right">
				<Skel width="100px" height="2rem" />
			</div>
		</Card.Content>
	</Card>
);

export default PasswordSkeletonForm;
