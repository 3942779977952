import { ColoredStatus } from 'utils/Formatter/ColoredStatus';

export const accountType: ColoredStatus[] = [
	{
		status: '0',
		badgeText: 'Conta Poupança',
	},
	{
		status: '1',
		badgeText: 'Conta Corrente',
	},
];
