import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { QueryResult, TypedQueryFunction, useQuery } from 'react-query';

import useToast from 'hooks/useToast';
import { UrlParams } from 'types';

import PageLayout, { PageLayoutProps } from './PageLayout';

export type PageLayoutFormWrapperProps<T extends {}> = Omit<
	PageLayoutProps,
	'title'
> & {
	title: string | ((id: string) => string);
	withPrependTitle?: boolean;
	load: (id: string) => TypedQueryFunction<T>;
	redirectOnError?: boolean;
	skeleton: React.FC;
	children: (
		id: string,
		queryResult: QueryResult<T>
	) => React.ReactNode | React.ReactNodeArray;
};

const PageLayoutFormWrapper = <T extends {}>({
	title,
	withPrependTitle = true,
	breadcrumb,
	load,
	redirectOnError = true,
	skeleton: Component,
	children,
}: PageLayoutFormWrapperProps<T>) => {
	const { id } = useParams<UrlParams>();
	const { pathname } = useLocation();
	const history = useHistory();
	const [toast] = useToast();
	const queryResult = useQuery(['form_wrapper', id, title], load(id || ''), {
		cacheTime: 0,
		onError: () => {
			if (redirectOnError) {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
				history.push(pathname.slice(0, pathname.lastIndexOf('/')));
			}
		},
	});

	const isCreating = !id || id === 'create';

	let prependTitle = isCreating ? 'Novo' : 'Editar';
	const strTitle = typeof title === 'string' ? title : title(id || '');

	if (isCreating) {
		if (strTitle.charAt(strTitle.length - 1) === 'a') {
			prependTitle = 'Nova';
		} else {
			prependTitle = 'Novo';
		}
	}

	return (
		<PageLayout
			title={
				<>
					{withPrependTitle && prependTitle} {strTitle}
				</>
			}
			breadcrumb={[
				...breadcrumb,
				{
					label: `${withPrependTitle ? prependTitle : ''} ${strTitle}`,
					active: true,
				},
			]}
		>
			{queryResult.isLoading || (queryResult.isError && redirectOnError) ? (
				<Component />
			) : (
				children(id || '', queryResult)
			)}
		</PageLayout>
	);
};

export default PageLayoutFormWrapper;
