import React, { useEffect, useCallback, useState } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { DatagridUpdateAction, DatagridState } from 'components/datagrid/types';
import useDatagridContext from 'components/datagrid/useDatagridContext';
import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import { useToast } from 'hooks';

import { FilterContainer } from 'packages/admin/components/datatableView';

import useCheckedContext from '../useCheckedContext';

const initialFilterValues = {};

const WaitingDatagridFilters: React.FC = () => {
	const {
		tableParams: { disabled },
		listenForUpdates,
	} = useDatagridContext();
	const { data } = useDatagridDataContext();
	const { checked, emitChecked, clearChecked } = useCheckedContext();
	const [isPrinting, setPrinting] = useState<boolean>(false);
	const [postMessageData, setPostMessageData] = useState<{
		action: string;
		message?: string;
	}>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [iframes, setIframes] = useState<any[]>([]);
	const [toast] = useToast();

	const updateListener = useCallback(
		(state: DatagridState, action: DatagridUpdateAction) => {
			if (action === DatagridUpdateAction.NewData) {
				clearChecked();
			}
		},
		[clearChecked]
	);

	useEffect(() => {
		listenForUpdates(updateListener);
	}, [listenForUpdates, updateListener]);

	const keys = Object.keys(checked);
	const hasSelectedItems = keys.some(
		(value: string) => checked[value] ?? false
	);
	const isAllChecked =
		keys.length === data?.length &&
		keys.every((value: string) => checked[value] === true);

	const handleMessage = useCallback((event: MessageEvent) => {
		setPostMessageData(event.data);
	}, []);

	const onSelectAll = () => {
		if (isAllChecked) {
			clearChecked();
		} else {
			emitChecked(
				data?.map((value) => Number((value as { idOrder: string }).idOrder)) ||
					[],
				true
			);
		}
	};

	const printIframe = useCallback((orderIds) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const win = window as any;
		const iframe = win.frames
			? win.frames['print-orders']
			: document.getElementById('print-orders');
		const iframeWindow = iframe.contentWindow || iframe;

		iframe.src = `/admin/expedition/print-orders?orderIds=${orderIds}`;

		setIframes([iframe, iframeWindow]);
	}, []);

	const handleSendSelectedToPrint = async () => {
		// eslint-disable-next-line no-console
		console.assert(
			keys.length > 0,
			'Evento disparado sem nenhum item selecionado?!'
		);
		if (!data || !keys.length) {
			return;
		}

		const orderIds = keys.filter((item) => checked[item]);

		setPrinting(true);
		printIframe(orderIds);
	};

	useEffect(() => {
		if (postMessageData && iframes.length > 0) {
			const { action, message } = postMessageData;
			const [iframe, iframeWindow] = iframes;

			if (action === 'print-orders-loaded') {
				setPrinting(false);

				iframe.focus();
				iframeWindow.print();
			}
			if (action === 'print-orders-error') {
				setPrinting(false);
				toast(message, { type: 'error' });
			}
		}
	}, [postMessageData, iframes, toast]);

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [handleMessage]);

	return (
		<FilterContainer
			className="border-bottom d-flex flex-row align-items-center"
			initialValues={initialFilterValues}
		>
			<h5 className="my-2 d-inline mr-auto">Em Separação</h5>
			<div className="d-flex flex-row align-items-stretch">
				<button
					className="border mr-1 btn btn-sm btn-white"
					type="button"
					onClick={onSelectAll}
					disabled={disabled}
				>
					{isAllChecked ? 'Limpar Seleção' : 'Selecionar Tudo'}
				</button>
				<button
					className="border mr-1 btn btn-sm btn-white"
					disabled={!hasSelectedItems || disabled || isPrinting}
					type="button"
					onClick={handleSendSelectedToPrint}
				>
					{isPrinting ? (
						<>
							<Spinner size="sm" animation="border" role="status" />
							<span className="ml-2">Buscando Dados de Impressão</span>
						</>
					) : (
						<>
							<FAIcon icon={faPrint} className="mr-2" /> Imprimir Selecionados
						</>
					)}
				</button>
			</div>
		</FilterContainer>
	);
};

export default WaitingDatagridFilters;
