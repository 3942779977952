import {
	ID,
	Expedition,
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	ExpeditionOrder,
} from 'types';

import Crud from './crud.service';

const getExpeditions = (
	params: HttpGetListParams,
	route = 'expedition'
): Promise<HttpGetListResult<Expedition>> =>
	Crud.getList<Expedition>(route, params);

const getById = (id: ID): Promise<Expedition> =>
	Crud.getOne('expedition/{id}', id as number);

const createOrUpdate = (
	id: ID | null,
	data: Expedition
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('expedition/{id}', id as number, data);
	}
	return Crud.create('expedition', data);
};

const getWaitingSeparationOrders = (params: HttpGetListParams) =>
	Crud.getList<ExpeditionOrder>('expedition/{id}/waiting', params);

const getInSeparationOrders = (params: HttpGetListParams) =>
	Crud.getList<ExpeditionOrder>('expedition/{id}/inseparation', params);

export default {
	getExpeditions,
	getById,
	getWaitingSeparationOrders,
	getInSeparationOrders,
	createOrUpdate,
};
