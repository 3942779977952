import styled from 'styled-components';

export const GridWidgetsStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1rem;
`;

export const GridWidgetItemStyled = styled.div.attrs(
	(props: { size: number }) => ({
		size: props.size,
		className: `grid-${props.size}`,
	})
)`
	a {
		display: block;
		position: relative;

		.item-image {
			overflow: hidden;
			position: relative;
			background-color: var(--always-white);
			border-radius: var(--base-border-radius);
			border: 1px solid rgba(0, 0, 0, 0.1);

			img {
				width: 100%;
				opacity: 0.85;
				transition: opacity 0.1s ease;
			}
		}

		span {
			position: absolute;
			left: 1rem;
			bottom: 1rem;
			padding: 0.2rem 0.5rem 0;
			background-color: $white;
			font-weight: bold;
			font-size: 14px;
			color: $body-color;
			box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
		}
	}

	&:hover {
		.item-image img {
			opacity: 1;
		}
	}

	&.grid-1 {
		grid-column: span 1;
		grid-row: span 1;
	}

	&.grid-2 {
		grid-column: span 2;
		grid-row: span 2;
	}
`;
