import React, { PropsWithChildren } from 'react';

import { Formik, Form } from 'formik';

import { Schema } from 'yup';

import useDatagridContext from 'components/datagrid/useDatagridContext';

export type FilterContainerProps<T> = PropsWithChildren<{
	className?: string;
	initialValues: T;
	validationSchema?: Schema<unknown>;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterContainer: React.FC<FilterContainerProps<any>> = ({
	initialValues,
	validationSchema,
	className,
	children,
}) => {
	const { forceUpdate } = useDatagridContext();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={forceUpdate}
		>
			{typeof children === 'function' ? (
				(...params) => (
					<Form className={className} role="search">
						{children(...params)}
					</Form>
				)
			) : (
				<Form className={className} role="search">
					{children}
				</Form>
			)}
		</Formik>
	);
};

export default FilterContainer;
