import React, { useEffect } from 'react';

import useDatagridContext from 'components/datagrid/useDatagridContext';

import useSelectCompany from 'packages/admin/hooks/useSelectCompany';

type CompanyIDParamProps = {
	name?: string;
};

const CompanyIDParam: React.FC<CompanyIDParamProps> = ({ name = 'id' }) => {
	const { onParamChange } = useDatagridContext();
	const [company] = useSelectCompany();

	useEffect(() => {
		onParamChange(name, company.idPerson);
	}, [onParamChange, name, company]);

	return <></>;
};

export default CompanyIDParam;
