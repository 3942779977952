import { SessionState } from 'store/ducks/session';
import { PersonType } from 'types';

import { RouteValidator, ValidationFn } from './types';

const personTypeValidation: RouteValidator = (
	personType: PersonType
): ValidationFn => {
	return (session: SessionState) => session.user?.idType === personType;
};

export default personTypeValidation;
