import { SessionState } from 'store/ducks/session';
import { PersonPermissionSlug } from 'types';

import { RouteValidator, ValidationFn } from './types';

const permissionValidation: RouteValidator = (
	slug: PersonPermissionSlug
): ValidationFn => {
	return (session: SessionState) => session.permissions.includes(slug);
};

export default permissionValidation;
