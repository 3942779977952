import carrierService from 'services/carrier.service';
import expeditionService from 'services/expedition.service';
import storeService from 'services/store.service';
import {
	editLinkFormatter,
	numberFormatter,
	coloredStatusFormatter,
	buttonLinkFormatter,
	cnpjFormatter,
	dateTimeFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';
import {
	activeInactiveStatusByName,
	activeInactiveStatusByBoolean,
} from 'packages/admin/utils/coloredStatus';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';
import ChangeStatusCarrier from './store/ChangeStatusCarrier';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Lojas',
		path: '/admin/stores',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	Stores: {
		title: 'Lojas',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/stores/create`,
		action: storeService.getStores,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idStore',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/stores/{id}`,
					}),
				},
				{
					key: 'idStore',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'name', searchable: true, title: 'Nome' },
				{
					key: 'typeName',
					searchable: true,
					className: 'text-center',
					title: 'Tipo',
				},
				{
					key: 'companyName',
					searchable: true,
					className: 'text-center',
					title: 'Empresa',
				},
				{
					key: 'langSlug',
					searchable: true,
					className: 'text-center',
					title: 'Idioma',
				},
				{
					key: 'isActive',
					title: 'Status',
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: activeInactiveStatusByBoolean,
					}),
				},
			],
		},
	},

	StoreBanners: {
		title: 'Banners',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/banners/create`,
		action: storeService.getBanners,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idBanner',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/banners/{id}`,
					}),
				},
				{
					key: 'idBanner',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'name', searchable: true, title: 'Nome' },
				{
					key: 'initialDate',
					searchable: true,
					title: 'Data Inicial',
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'endDate',
					searchable: true,
					title: 'Data Final',
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'status',
					searchable: true,
					className: 'text-center',
					title: 'Status',
					formatter: coloredStatusFormatter({
						statusList: activeInactiveStatusByName,
					}),
				},
			],
		},
	},

	StoreFeatures: {
		title: 'Destaques',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/features/create`,
		action: storeService.getFeatures,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idFeature',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/features/{id}`,
					}),
				},
				{
					key: 'idFeature',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'name',
					title: 'Nome',
					searchable: true,
				},
				{
					key: 'description',
					title: 'Descrição',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: true,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: activeInactiveStatusByName,
					}),
				},
			],
		},
	},

	StoreExpeditions: {
		title: 'Expedição',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/expeditions/create`,
		action: expeditionService.getExpeditions,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idExpedition',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/expeditions/{id}`,
					}),
				},
				{
					key: 'idExpedition',
					title: 'ID',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{
					key: 'name',
					title: 'Expedição',
					searchable: true,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: true,
					className: 'text-center',
					formatter: coloredStatusFormatter({
						statusList: activeInactiveStatusByName,
					}),
				},
			],
		},
	},

	StoreCarriers: {
		title: 'Transportadoras',
		breadcrumbItems: adminBreadcrumb,
		newItemLink: `${adminBreadcrumb[0].path}/carriers/create`,
		action: carrierService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					scope: 'row',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/carriers/{id}`,
					}),
				},
				{
					key: 'name',
					title: 'Transportadora',
					searchable: true,
					sortable: true,
				},
				{
					key: 'cpfCnpj',
					title: 'CNPJ',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: cnpjFormatter,
				},
				{
					key: 'nRegister',
					title: 'Inscrição Estadual',
					className: 'text-center',
					searchable: true,
					sortable: true,
					formatter: numberFormatter,
				},
				{
					key: 'streetName',
					title: 'Endereço',
					searchable: true,
					sortable: true,
				},
				{
					key: 'number',
					title: 'Número',
					searchable: true,
					sortable: true,
					formatter: numberFormatter,
				},
				{
					key: 'complement',
					title: 'Complemento',
					searchable: true,
					sortable: true,
				},
				{
					key: 'cep',
					title: 'CEP',
					searchable: true,
					sortable: true,
					className: 'text-center',
				},
				{
					key: 'idPerson',
					title: 'Status',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter({
						component: ChangeStatusCarrier,
					}),
				},
			],
		},
	},
};

export default ScenesConfig;
