import React from 'react';

import styled from 'styled-components';

import { cover } from 'polished';

import BackdropFade from './backdrop/BackdropFade';
import BackdropGradient from './backdrop/BackdropGradient';
import BackdropImage from './backdrop/BackdropImage';

type BackdropComponent = React.FC & {
	Fade: typeof BackdropFade;
	Image: typeof BackdropImage;
	Gradient: typeof BackdropGradient;
};

const AbsoluteFullSize = styled.div.attrs((props: { zIndex: number }) => ({
	zIndex: props.zIndex || 0,
}))`
	${cover()}
	z-index: ${({ zIndex }) => zIndex};
`;

const Backdrop: BackdropComponent = (props) => <AbsoluteFullSize {...props} />;

Backdrop.Fade = BackdropFade;
Backdrop.Image = BackdropImage;
Backdrop.Gradient = BackdropGradient;

Backdrop.displayName = 'Backdrop';
Backdrop.whyDidYouRender = true;

export default Backdrop;
