import { faCircle, faStar } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

const VcDigitalRoutes: RouteDef = {
	path: '/admin/vc-digital',
	menu: {
		name: 'Você Digital',
		icon: faStar,
	},
	children: [
		{
			path: '/summary',
			menu: {
				name: 'Resumo',
				icon: faCircle,
			},
		},
		{
			path: '/subscriptions/by-status',
			menu: {
				name: 'Assinaturas Ativas',
				icon: faCircle,
			},
		},
		{
			path: '/subscriptions/by-month',
			menu: {
				name: 'Assinaturas x Mês',
				icon: faCircle,
			},
		},
		{
			path: '/leads/summary',
			menu: {
				name: 'Leads Coletados',
				icon: faCircle,
			},
		},
		{
			path: '/leads/by-executive',
			menu: {
				name: 'Leads x Executivo',
				icon: faCircle,
			},
		},
		{
			path: '/leads/by-subscription',
			menu: {
				name: 'Leads x Assinatura',
				icon: faCircle,
			},
		},
	],
};

export default VcDigitalRoutes;
