import { SessionPerson, HttpBaseResult } from 'types';
import { action, Action } from 'typesafe-actions';

import { SessionActionEnum } from './types';

const loginSuccess = (
	user: SessionPerson | null,
	permissions: string[]
): Action => action(SessionActionEnum.LoginSuccess, { user, permissions });

const loginFailed = (error: HttpBaseResult): Action =>
	action(SessionActionEnum.LoginFailed, error);

const forgotSuccess = (message: string | null): Action =>
	action(SessionActionEnum.ForgotSuccess, { message });

const forgotFailed = (error: HttpBaseResult): Action =>
	action(SessionActionEnum.ForgotFailed, error);

const redefineSuccess = (message: string | null): Action =>
	action(SessionActionEnum.RedefineSuccess, { message });

const redefineFailed = (error: HttpBaseResult): Action =>
	action(SessionActionEnum.RedefineFailed, error);

const loadLoggedSuccess = (user: SessionPerson): Action =>
	action(SessionActionEnum.LoadLoggedSuccess, { user });

const loadLoggedFailed = (error: HttpBaseResult): Action =>
	action(SessionActionEnum.LoadLoggedFailed, error);

export default {
	loginSuccess,
	loginFailed,
	forgotSuccess,
	forgotFailed,
	redefineSuccess,
	redefineFailed,
	loadLoggedSuccess,
	loadLoggedFailed,
};
