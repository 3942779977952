import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Empty, InputQty, Skeleton } from 'components';
import myService from 'services/my.service';
import { actions } from 'store/ducks/shoppingCart';
import { EcProductDetails, MyStoreProducts } from 'types';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import ProductCard from '../components/ProductCard';
import {
	Breadcrumb,
	Title,
	Subtitle,
	ProductPrice,
	FixedWidthText,
	Text,
	Small,
} from '../components/Typography';
import {
	useShoppingCartView,
	useEcommerceBasePath,
	useShoppingCartName,
} from '../hooks';

import {
	AddProductToCart,
	AddProductToCartItem,
	OptionItem,
	AsyncSelectStyled,
	ListImage,
} from './myShoopingCart/styles';

const SelectOptionItem: React.FC<{ item: MyStoreProducts }> = ({ item }) => (
	<OptionItem>
		<ListImage className="mr-2" src={item.image} alt={item.name} />

		<div>
			<Small className="d-block">{item.name}</Small>
			<Small className="text-muted d-xs-none">{item.code}</Small>
		</div>
	</OptionItem>
);

const MyShoppingCart: React.FC = () => {
	const baseUrl = useEcommerceBasePath();
	const cartView = useShoppingCartView();
	const cartName = useShoppingCartName();
	const dispatch = useDispatch();
	const [
		selectedProduct,
		setSelectedProduct,
	] = useState<MyStoreProducts | null>(null);
	const [product, setProduct] = useState<EcProductDetails | null>(null);
	const [quantity, setQuantity] = useState<number>(1);
	const [isProductLoading, setIsProductLoading] = useState<boolean>(false);

	const onEmptyCart = useCallback(() => {
		dispatch(actions.clear([cartName]));
	}, [dispatch, cartName]);

	const onQtyChange = useCallback((qty: number) => {
		setQuantity(qty);
	}, []);

	const getProductDetail = useCallback(async (prod: MyStoreProducts) => {
		try {
			const response = await myService.getMyStoreProduct(prod.idProduct);
			setProduct(response);
			setIsProductLoading(false);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log('ERRO ==> ', e);
			setIsProductLoading(false);
		}
	}, []);

	const addToCart = useCallback(() => {
		if (product) {
			dispatch(actions.addItem(cartName, product.idProduct, quantity));
			setQuantity(1);
			setProduct(null);
			setSelectedProduct(null);
		}
	}, [cartName, dispatch, product, quantity]);

	useEffect(() => {
		if (selectedProduct) {
			setIsProductLoading(true);
			getProductDetail(selectedProduct);
		} else {
			setProduct(null);
		}
	}, [getProductDetail, selectedProduct]);

	return (
		<Container fluid className="mb-5">
			<Breadcrumb>
				<Breadcrumb.Item as={Link} to={baseUrl} linkAs="span">
					<FAIcon icon={faHome} />
				</Breadcrumb.Item>
				<Breadcrumb.Item>Meu Carrinho</Breadcrumb.Item>
			</Breadcrumb>
			<header className="pb-4 border-bottom">
				<AddProductToCart>
					<Subtitle>
						Adicionar
						<br />
						ao carrinho:
					</Subtitle>
					<AddProductToCartItem>
						<AsyncSelectStyled
							value={selectedProduct}
							fetchMethod={myService.getMyStoreProducts}
							formatOptionLabel={(option: MyStoreProducts) => (
								<SelectOptionItem item={option} />
							)}
							placeholder="Selecione um produto"
							fetchSortByKey="name"
							onChange={(value: MyStoreProducts) => setSelectedProduct(value)}
							isClearable
						/>
					</AddProductToCartItem>
					{isProductLoading ? (
						<>
							<Skeleton width="8rem" height="2rem" className="ml-3" />
							<Skeleton width="6rem" height="2rem" className="ml-3" />
							<Skeleton width="6rem" height="2rem" className="ml-3" />
							<Skeleton width="4rem" height="2rem" className="ml-3" />
						</>
					) : (
						product && (
							<>
								<AddProductToCartItem>
									<InputQty quantity={quantity} onChange={onQtyChange} />
								</AddProductToCartItem>
								<AddProductToCartItem>
									<Text>
										<span className="text-muted">x</span>{' '}
										{currencyWithSymbolFormatter.format(product.price || 0)}
									</Text>
								</AddProductToCartItem>
								<AddProductToCartItem>
									<Text>
										<big>
											{currencyWithSymbolFormatter.format(
												product.price * quantity
											)}
										</big>
									</Text>
								</AddProductToCartItem>
								<AddProductToCartItem>
									<Button variant="outline-info" onClick={addToCart}>
										Adicionar
									</Button>
								</AddProductToCartItem>
							</>
						)
					)}
				</AddProductToCart>

				<Row className="align-items-center">
					<Col>
						<Title>Meu Carrinho</Title>
					</Col>
					{cartView.items.length > 0 && (
						<Col className="text-right col-auto d-none d-sm-block">
							<Button variant="dark" as={Link} to={`${baseUrl}/checkout`}>
								Finalizar Compra
							</Button>
						</Col>
					)}
				</Row>
			</header>
			{cartView.items.length === 0 ? (
				<>
					<Empty
						description="Seu Carrinho está vazio"
						image={<FAIcon icon={faShoppingCart} />}
					/>
				</>
			) : (
				<>
					<section>
						{cartView.items.map((item) => (
							<Row key={item.id}>
								<Col>
									{cartView.isLoading ? (
										<ProductCard.ListItem.Skeleton />
									) : (
										<ProductCard.ListItem
											className="px-1 py-2 border-bottom"
											item={item}
											product={cartView.productMap[item.id]}
										/>
									)}
								</Col>
							</Row>
						))}
					</section>
					<section className="py-3 mb-3 border-bottom">
						<Row noGutters className="align-items-end mb-1">
							<Col className="text-right">
								<Text>Frete:</Text>
							</Col>
							<Col className="col-auto text-right pl-1">
								<FixedWidthText width="12rem">A Calcular</FixedWidthText>
							</Col>
						</Row>
						<Row noGutters className="align-items-end">
							<Col className="text-right">
								<Text>Subtotal:</Text>
							</Col>
							<Col className="col-auto text-right pl-1">
								<FixedWidthText width="12rem">
									<ProductPrice>
										{cartView.isLoading
											? '-,--'
											: currencyWithSymbolFormatter.format(cartView.subtotal)}
									</ProductPrice>
								</FixedWidthText>
							</Col>
						</Row>
					</section>
					<Row noGutters>
						<Col className="col-6">
							<Button
								variant="primary"
								className="mr-2"
								as={Link}
								to={`${baseUrl}`}
							>
								Voltar a Loja
							</Button>
							<Button variant="outline-primary" onClick={onEmptyCart}>
								Esvaziar Carrinho
							</Button>
						</Col>
						<Col className="col-6 text-right">
							<Button variant="dark" as={Link} to={`${baseUrl}/checkout`}>
								Finalizar Compra
							</Button>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};

export default MyShoppingCart;
