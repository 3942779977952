import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

import {
	CategoryBanner,
	Paragraph,
	ProductCard,
} from 'packages/ecommerce/components';

const ProductListSkeleton: React.FC = () => (
	<>
		<CategoryBanner />
		<Container fluid className="py-4 bg-white">
			<Row>
				<Col lg={2}>
					<div className="mr-2">
						<Skeleton height="2rem" />
					</div>
				</Col>
				<Col lg={10}>
					<Paragraph>
						<Skeleton width="60%" />
					</Paragraph>
					<Row>
						{Array(12)
							.fill(0)
							.map((val, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<Col key={`${val}_${index}`} lg={3} md={4} sm={6} xs={12}>
									<ProductCard.Card.Skeleton />
								</Col>
							))}
					</Row>
				</Col>
			</Row>
		</Container>
	</>
);

export default ProductListSkeleton;
