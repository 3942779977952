import { createContext } from 'react';

import { DatagridContextType, DatagridUpdateAction } from './types';

export const defaultDatagridContext = {
	config: {
		columns: [],
		sortByKey: '',
		sortByDesc: true,
		updateOnFilterChange: true,
		paginate: {
			text: '',
			rowsOptions: [],
		},
	},
	tableParams: {
		params: {},
		searchColumns: [],
		filters: [],
		page: 1,
		rowsPerPage: 0,
		disabled: false,
		lastAction: DatagridUpdateAction.Setup,
	},
	onPageChange: () => {},
	onParamChange: () => {},
	onSearchChange: () => {},
	onOrderByChange: () => {},
	onRowsPerPageChange: () => {},
	onQueryChange: () => {},
	forceUpdate: () => {},
	listenForUpdates: () => {},
	setDisabled: () => {},
};

const DatagridContext = createContext<DatagridContextType>(
	defaultDatagridContext
);

export default DatagridContext;
