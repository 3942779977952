import {
	HttpGetListParams,
	HttpPostResultAny,
	ID,
	HttpGetListResult,
	SubscriptionList,
	Subscription,
	Activation,
} from 'types';

import Crud from './crud.service';

/**
 * Service de requisição de dados de Assinaturas do MMN
 */
const getList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<SubscriptionList>> =>
	Crud.getList<SubscriptionList>('mmn/subscription', params);

const getById = (id: ID): Promise<Subscription> =>
	Crud.getOne<Subscription>('mmn/subscription/{id}', id as number);

const createOrUpdate = (
	id: ID | null,
	data: Subscription
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('mmn/subscription/{id}', id as number, data);
	}
	return Crud.create('mmn/subscription', data);
};

const getActivationList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Activation>> =>
	Crud.getList<Activation>('mmn/activation', params);

export default {
	getList,
	getById,
	createOrUpdate,
	getActivationList,
};
