import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Form as FormikForm, Formik, FormikProps, FormikValues } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Backdrop, LoadingProgressBar } from 'components';
import { Input, Select } from 'components/Formik';
import executiveService from 'services/executive.service';
import { ExecutiveList, HttpGetListParams } from 'types';
import { FormatterType } from 'utils/Formatter/IFormatter';
import {
	coloredStatusFormatter,
	defaultFormatter,
	cpfCnpjFormatter,
	phoneFormatter,
	numberFormatter,
} from 'utils/formatters';
import ValidateCNPJ from 'utils/Validator/CNPJ';
import ValidateCPF from 'utils/Validator/CPF';

import FormGroupTitle from 'packages/admin/components/FormGroupTitle';
import { personStatusById } from 'packages/admin/utils/coloredStatus';

import PaginationExecutiveList from './executiveSearchList/PaginationExecutiveList';
import { TableStyled } from './executiveSearchList/styles';

type FilterByProp = { name: string; value: string };

const itemsByFilter: FilterByProp[] = [
	{ name: 'ID', value: 'id' },
	{ name: 'Nome', value: 'name' },
	{ name: 'CPF/CNPJ', value: 'cpfCnpj' },
	{ name: 'Telefone', value: 'phone' },
	{ name: 'Email', value: 'email' },
];

const pageLimit = 25;

const statusFormatter = coloredStatusFormatter({
	statusList: personStatusById,
});

interface ExecutiveSearchListModel {
	filterBy: FilterByProp;
	valueBy: string;
}

const defaultFitlerValues: ExecutiveSearchListModel = {
	filterBy: itemsByFilter[0],
	valueBy: '',
};

const validationSchema = Yup.object({
	filterBy: Yup.object<FilterByProp>().required('Campo obrigatório'),
	valueBy: Yup.string()
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'name',
			then: Yup.string().required('Campo obrigatório'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'email',
			then: Yup.string()
				.trim()
				.required('Campo obrigatório')
				.matches(
					/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
					'E-mail inválido'
				)
				.max(128, 'Máximo 128 caracteres'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'phone',
			then: Yup.string()
				.trim()
				.required('Campo obrigatório')
				.min(10, 'Digite seu telefone com DDD')
				.max(11, 'Digite seu telefone com DDD'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'cpfCnpj',
			then: Yup.string()
				.transform((value: string): string => {
					if (!ValidateCPF(value) && !ValidateCNPJ(value)) {
						return '';
					}
					return value;
				})
				.required('CPF/CNPJ Inválido'),
		})
		.when('filterBy', {
			is: (filter: FilterByProp) => filter.value === 'id',
			then: Yup.string().required('Campo obrigatório'),
		}),
});

const formatterMap: { [x: string]: FormatterType } = {
	name: defaultFormatter,
	cpfCnpj: cpfCnpjFormatter,
	phone: phoneFormatter,
	email: defaultFormatter,
	id: numberFormatter,
};

const byFilterNameMap: {
	[x: string]: 'Name' | 'Cpf' | 'Phone' | 'Email' | 'Id';
} = {
	name: 'Name',
	cpfCnpj: 'Cpf',
	phone: 'Phone',
	email: 'Email',
	id: 'Id',
};

const ExecutiveSearchList: React.FC<{ className: string }> = ({ ...rest }) => {
	const [executives, setExecutives] = useState<ExecutiveList[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [offset, setOffset] = useState(0);
	const [totalRecords, setTotalRecords] = useState<number>(0);

	const paginatedExecutives = [...executives].splice(offset, pageLimit);

	const handleSubmit = useCallback(async (values: FormikValues) => {
		const submit = { ...values } as ExecutiveSearchListModel;
		setIsLoading(true);
		setTotalRecords(0);
		setExecutives([]);

		const by = byFilterNameMap[submit.filterBy.value];
		const paramGet: { [key: string]: string | number | null } = {};
		if (by === 'Name') {
			paramGet.name = submit.valueBy;
		} else if (by === 'Email') {
			paramGet.email = submit.valueBy;
		} else {
			paramGet.param = submit.valueBy;
		}
		const params: HttpGetListParams = {
			params: paramGet,
		};

		executiveService
			.getFindBy(by, params)
			.then((result) => {
				const { data } = result;
				if (data.length === 0) {
					setError('Nenhum Executivo encontrado para esta pesquisa.');
					setExecutives([]);
					setTotalRecords(0);
					setIsLoading(false);
					return;
				}
				setExecutives(data);
				setTotalRecords(data.length);
				setIsLoading(false);
				setError('');
			})
			.catch((e) => {
				// eslint-disable-next-line no-console
				console.log('ERRO ==> ', e.code, e.message);
				setError(e.message);
				setIsLoading(false);
			});
	}, []);

	const onPageChanged = useCallback((page: number) => {
		const offsetNumber = (page - 1) * pageLimit;
		setOffset(offsetNumber);
	}, []);

	return (
		<Card {...rest}>
			<Card.Body>
				<FormGroupTitle>Buscar Executivos</FormGroupTitle>

				<Formik
					onSubmit={handleSubmit}
					initialValues={defaultFitlerValues}
					validationSchema={validationSchema}
				>
					{(formProps: FormikProps<ExecutiveSearchListModel>) => (
						<FormikForm>
							<Form.Row>
								<Form.Group as={Col} md={3}>
									<Form.Label> </Form.Label>
									<Select
										name="filterBy"
										options={itemsByFilter}
										optionLabel="name"
										optionValue="value"
										isDisabled={isLoading}
										onChange={() => formProps.setFieldValue('valueBy', '')}
									/>
								</Form.Group>

								<Form.Group as={Col} md={6}>
									<Form.Label>{formProps.values.filterBy.name}</Form.Label>
									<Input
										name="valueBy"
										type="text"
										formatter={formatterMap[formProps.values.filterBy.value]}
										disabled={isLoading}
									/>
								</Form.Group>

								<Form.Group
									as={Col}
									className="d-flex align-items-end justify-content-end"
								>
									<Button type="submit" variant="primary" disabled={isLoading}>
										{isLoading ? (
											<>
												<Spinner size="sm" animation="border" role="status" />
												<span className="ml-2">Aguarde...</span>
											</>
										) : (
											'Pesquisar'
										)}
									</Button>
								</Form.Group>
							</Form.Row>
						</FormikForm>
					)}
				</Formik>

				<Row>
					<Col
						md={12}
						style={{
							overflow: 'auto',
							height: '446px',
						}}
					>
						{isLoading && (
							<Backdrop>
								<Backdrop.Gradient
									filter={{
										iniColor: '#000000',
										iniOpacity: 0.05,
										endOpacity: 0.05,
									}}
								/>
								<LoadingProgressBar />
							</Backdrop>
						)}
						<TableStyled striped bordered hover>
							<thead>
								<tr>
									<th className="text-center">#</th>
									<th className="text-center">ID</th>
									<th>Nome</th>
									<th className="text-center">CPF/CNPJ</th>
									<th>E-mail</th>
									<th>Patrocinador</th>
									<th className="text-center">Status</th>
								</tr>
							</thead>
							<tbody>
								{!error &&
									paginatedExecutives.length > 0 &&
									paginatedExecutives.map((item) => (
										<tr key={item.id}>
											<td className="text-center">
												<Link
													to={`/admin/executive/executives/${item.idPerson}`}
												>
													<FAIcon icon={faEdit} />
												</Link>
											</td>
											<td className="text-center">
												{formatterMap.id.format(String(item.id))}
											</td>
											<td>{item.name}</td>
											<td className="text-center">
												{formatterMap.cpfCnpj.format(item.cpfCnpj)}
											</td>
											<td>{item.email}</td>
											<td>{item.sponsorName}</td>
											<td className="text-center">
												{statusFormatter.format(item.idStatus)}
											</td>
										</tr>
									))}
								{!!error && (
									<tr>
										<td colSpan={7} className="text-danger">
											{error}
										</td>
									</tr>
								)}
								{!error && paginatedExecutives.length === 0 && !isLoading && (
									<tr>
										<td colSpan={7} className="text-muted">
											Busque Executivos nos filtros acima.
										</td>
									</tr>
								)}
								{!error && paginatedExecutives.length === 0 && isLoading && (
									<tr>
										<td colSpan={7} className="text-muted">
											Buscando executivos...
										</td>
									</tr>
								)}
							</tbody>
						</TableStyled>
					</Col>
				</Row>

				<Row className="mt-3">
					<Col className="d-flex" style={{ height: '31px' }}>
						<PaginationExecutiveList
							totalRecords={totalRecords}
							pageLimit={pageLimit}
							pageRangeDisplayed={1}
							onChangePage={onPageChanged}
							isLoading={isLoading}
							className="mb-0 ml-auto"
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ExecutiveSearchList;
