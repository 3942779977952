import RegexFormatter, { REGEX_CLEAR_NUMERIC } from './Regex';

class CNPJFormatter extends RegexFormatter {
	constructor() {
		super(
			/^(\d{1,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/g,
			(
				_: string,
				$1: string,
				$2: string,
				$3: string,
				$4: string,
				$5: string
			) => {
				let result: string = $1;
				result += $2 ? `.${$2}` : '';
				result += $3 ? `.${$3}` : '';
				result += $4 ? `/${$4}` : '';
				result += $5 ? `-${$5}` : '';
				return result;
			},
			{
				clear: REGEX_CLEAR_NUMERIC,
				maxLength: 14,
			}
		);
	}

	formatForSearch = (value: string) => value.trim().replace(/[.\-/]/g, '');
}

export default CNPJFormatter;
