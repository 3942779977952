import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import {
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResult,
	HttpGetIdResult,
	ID,
} from 'types';

import { ObjectKeyMap } from '../types/core.types';

import { getApi } from './api';

const extractData = <T>(response: AxiosResponse<T>): T => response.data;

const extractExceptionData = (reason: AxiosError) => {
	// Server respondeu com erro?
	if (reason.response) {
		throw reason.response.data;
	}
	// Mensagem enviada mas sem resposta do servidor?
	if (reason.request) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw Object.assign(reason, { message: 'Sem resposta do servidor' });
	}
	// Erro na criação do request? (nunca deveria cair aqui)
	throw reason;
};

const makeCompleteUrl = (url: string, getParams: HttpGetListParams): string => {
	let finalUrl = url;

	if (getParams.params) {
		// eslint-disable-next-line no-restricted-syntax, guard-for-in
		for (const key in getParams.params) {
			finalUrl = finalUrl.replace(`{${key}}`, String(getParams.params[key]));
		}
	}

	let params: string = url.indexOf('?') >= 0 ? '&' : '?';

	if (getParams.pagination) {
		if (getParams.pagination.sort) {
			params += `sort=${getParams.pagination.sort}&`;
		}
		if (getParams.pagination.paginate && getParams.pagination.page) {
			params += `paginate=${getParams.pagination.paginate}&page=${getParams.pagination.page}&`;
		}
	}

	if (getParams.query) {
		params += `q={${getParams.query.join(',')}}`;
	}

	if (params.length > 1) {
		finalUrl += params;
	}

	return finalUrl;
};

const get = async <T>(
	path: string,
	queryParams?: ObjectKeyMap<string | number>,
	bodyData?: ObjectKeyMap<string | number>,
	config?: AxiosRequestConfig
): Promise<T> =>
	getApi()
		.get(path, {
			...config,
			...(queryParams && { params: queryParams }),
			...(bodyData && { data: bodyData }),
		})
		.then(extractData)
		.catch(extractExceptionData);

const getList = async <T>(
	route: string,
	params: HttpGetListParams
): Promise<HttpGetListResult<T>> =>
	getApi()
		.get<HttpGetListResult<T>>(makeCompleteUrl(route, params))
		.then(extractData)
		.catch(extractExceptionData);

const getListData = async <T>(
	route: string,
	params: HttpGetListParams
): Promise<T[]> =>
	getApi()
		.get<HttpGetListResult<T>>(makeCompleteUrl(route, params))
		.then(extractData)
		.then((res) => res.data)
		.catch(extractExceptionData);

const getOne = async <T>(route: string, id: number | null): Promise<T> =>
	getApi()
		.get<HttpGetIdResult<T>>(makeCompleteUrl(route, { params: { id } }))
		.then(extractData)
		.then((res) => res.data)
		.catch(extractExceptionData);

const create = <T, R = unknown>(
	route: string,
	data: T
): Promise<HttpPostResult<R>> =>
	getApi()
		.post<HttpPostResult<R>>(makeCompleteUrl(route, {}), data)
		.then(extractData)
		.catch(extractExceptionData);

const update = <T, R = unknown>(
	route: string,
	id: number,
	data: T
): Promise<HttpPostResult<R>> =>
	getApi()
		.put<HttpPostResult<R>>(makeCompleteUrl(route, { params: { id } }), data)
		.then(extractData)
		.catch(extractExceptionData);

const patch = <T, R = unknown>(
	route: string,
	id: number,
	data: T
): Promise<HttpPostResult<R>> =>
	getApi()
		.patch<HttpPostResult<R>>(makeCompleteUrl(route, { params: { id } }), data)
		.then(extractData)
		.catch(extractExceptionData);

// eslint-disable-next-line @typescript-eslint/naming-convention
const _delete = <R = unknown>(
	route: string,
	id: ID
): Promise<HttpPostResult<R>> =>
	getApi()
		.delete<HttpPostResult<R>>(makeCompleteUrl(route, { params: { id } }))
		.then(extractData)
		.catch(extractExceptionData);

/**
 * Implementação base de comunicação com backend
 *
 * @class
 */
export default {
	create,
	update,
	delete: _delete,
	patch,
	get,
	getOne,
	getList,
	getListData,
};
