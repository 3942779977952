import { faShoppingCart } from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import OrdersPage from './Orders';

const OrderRoutes: RouteDef = {
	path: '/admin/orders',
	component: OrdersPage,
	menu: {
		name: 'Pedidos',
		icon: faShoppingCart,
	},
};

export default OrderRoutes;
