import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';

import { Card } from 'components';
import Skeleton from 'components/Skeleton';

const StoreBannerFormSkeleton: React.FC = () => (
	<Card>
		<Card.Header separator>
			<Skeleton width="100px" height="2.4rem" />
		</Card.Header>
		<Card.Content>
			<Row>
				<Col md={12} lg={6}>
					<Form.Group>
						<Skeleton width="6ch" height="1.2rem" />
						<Skeleton height="2.6rem" />
					</Form.Group>

					<Form.Row>
						<Form.Group as={Col} md={6} lg={6}>
							<Skeleton width="8ch" height="1.2rem" />
							<Skeleton height="2.6rem" />
						</Form.Group>
						<Form.Group as={Col} md={6} lg={6}>
							<Skeleton width="8ch" height="1.2rem" />
							<Skeleton height="2.6rem" />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md={12}>
							<Skeleton width="8ch" height="1.2rem" />
							<Skeleton height="12rem" />
						</Form.Group>
					</Form.Row>
				</Col>
				<Col md={12} lg={6} className="border-left">
					<Skeleton width="15ch" height="1.6rem" />
					<Skeleton height="1.8rem" />
					<Skeleton height="1.8rem" />
					<Skeleton height="1.8rem" />
				</Col>
			</Row>
		</Card.Content>

		<Card.Content className="text-right border-top">
			<Form.Row>
				<Col>
					<Skeleton width="75px" height="2.4rem" />
					<Skeleton width="75px" height="2.4rem" margin="0 0 0 1rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default StoreBannerFormSkeleton;
