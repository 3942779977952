import { ReactFragment } from 'react';

import FindObjectProperty from 'utils/FindObjectProperty';

import CNPJFormatter from './CNPJ';
import CPFFormatter from './CPF';
import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from './IFormatter';
import { REGEX_CLEAR_NUMERIC } from './Regex';

class CPFCNPJFormatter implements FormatterType {
	private cpfFormatter = new CPFFormatter();

	private cnpjFormatter = new CNPJFormatter();

	format = (
		field: string,
		row?: FormatterParamRowType
	): string | ReactFragment => {
		const data = !row ? field : FindObjectProperty(row, field) || '';
		if (typeof data !== 'string') {
			return '';
		}
		let value = String(data);
		value = value.replace(REGEX_CLEAR_NUMERIC, '');
		if (value.length > 11) {
			return this.cnpjFormatter.format('value', { value });
		}
		return this.cpfFormatter.format('value', { value });
	};

	formatForInput = (data: FormatterParamType): [string, string] => {
		if (typeof data !== 'string') {
			return ['', ''];
		}
		let value = String(data);
		value = value.replace(REGEX_CLEAR_NUMERIC, '');
		if (value.length > 11) {
			return [value, this.cnpjFormatter.format('value', { value }) as string];
		}
		return [value, this.cpfFormatter.format('value', { value }) as string];
	};

	formatForSearch = (value: string) => value.trim().replace(/[.\-/]/g, '');
}

export default CPFCNPJFormatter;
