import styled from 'styled-components';

import { Card as CardComponent } from 'components';

export const Card = styled(CardComponent)`
	width: 100%;
	min-width: 200px;
	max-width: 300px;
`;

export const CardImgContent = styled(CardComponent.Content)`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 176px;
`;
