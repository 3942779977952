import * as React from 'react';

function SvgAmk(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			height="1em"
			width="1em"
			xmlSpace="preserve"
			viewBox="0 0 140 144"
			y={0}
			x={0}
			id="Amk_svg__Camada_1"
			{...props}
		>
			<style id="style2" type="text/css" />
			<path
				id="Amk_svg__path8"
				d="M75.242 111.638v-9.819c1.603-.2 3.106-.6 4.509-1.102a24.779 24.779 0 007.213-3.807c2.104-1.703 3.707-3.707 4.81-5.911 1.202-2.304 1.803-4.91 1.803-7.615 0-2.404-.4-4.508-1.102-6.311-.702-1.804-1.804-3.407-3.006-4.71-1.202-1.302-2.705-2.404-4.408-3.306-1.603-.901-3.307-1.603-5.01-2.204a46.163 46.163 0 00-5.31-1.503c-1.803-.4-3.506-.801-5.11-1.102-2.404-.5-4.308-1.002-5.91-1.503-1.403-.4-2.606-.901-3.407-1.503-.601-.4-1.102-.901-1.303-1.302-.3-.501-.4-1.202-.4-2.104 0-1.904.801-3.306 2.404-4.509 1.904-1.302 4.71-1.903 8.316-1.903 9.618 0 11.321 4.709 11.722 8.215l.2 1.904h11.222l-.1-2.204c-.1-2.906-.702-5.51-2.004-7.915a17.748 17.748 0 00-5.01-5.911c-2.003-1.603-4.508-2.806-7.314-3.607-.901-.3-1.803-.501-2.705-.702v-11.02h-10.72v10.72c-1.503.2-2.906.5-4.308.902-2.605.801-5.01 2.003-6.913 3.506-2.004 1.503-3.507 3.407-4.61 5.61-1.101 2.205-1.702 4.61-1.702 7.114 0 2.305.4 4.409 1.202 6.312.801 1.904 2.104 3.507 3.807 5.01 1.603 1.403 3.707 2.505 6.112 3.507 2.304.901 5.21 1.803 8.416 2.504a93.575 93.575 0 017.915 1.904c2.004.601 3.707 1.202 4.91 1.903 1.001.602 1.803 1.303 2.203 2.004.401.702.601 1.704.601 2.806s-.2 2.104-.7 2.905c-.502.902-1.203 1.603-2.105 2.305-1.002.7-2.204 1.202-3.707 1.603-1.503.4-3.206.6-5.11.6-1.903 0-3.807-.2-5.51-.7a14.075 14.075 0 01-4.308-1.904c-1.203-.802-2.204-1.904-3.006-3.206-.801-1.303-1.202-2.806-1.403-4.61l-.2-1.903H45.085l.1 2.204c.1 3.006.701 5.812 1.803 8.416 1.103 2.605 2.806 4.81 5.01 6.713 2.204 1.804 4.91 3.306 8.015 4.308 1.403.401 2.906.802 4.509 1.002v10.02h10.72z"
				fill="fill"
				fillOpacity={1}
				strokeWidth={1.002}
			/>
			<path
				id="Amk_svg__path831"
				d="M13.125 89.195v.102H0a68.539 68.539 0 003.606 10.819c3.707 8.616 8.818 16.232 15.43 22.844 6.613 6.613 14.326 11.823 22.842 15.43 8.817 3.707 18.235 5.61 27.954 5.61 9.718 0 19.136-1.903 27.953-5.61 8.516-3.607 16.23-8.817 22.842-15.43 6.613-6.612 11.723-14.228 15.43-22.844a68.539 68.539 0 003.606-10.82H126.54v-.101c-7.314 24.346-29.956 42.08-56.707 42.08s-49.394-17.734-56.707-42.08z"
				fill="fill"
				fillOpacity={1}
				strokeWidth={1.002}
			/>
			<path
				d="M13.125 54.805v-.102H0a68.539 68.539 0 013.606-10.819c3.707-8.616 8.818-16.232 15.43-22.844C25.65 14.427 33.362 9.217 41.878 5.61 50.695 1.903 60.113 0 69.832 0c9.718 0 19.136 1.903 27.953 5.61 8.516 3.607 16.23 8.817 22.842 15.43 6.613 6.612 11.723 14.228 15.43 22.844a68.539 68.539 0 013.606 10.82H126.54v.101c-7.314-24.346-29.956-42.08-56.707-42.08S20.438 30.46 13.125 54.805z"
				id="Amk_svg__path836"
				fill="fill"
				fillOpacity={1}
				strokeWidth={1.002}
			/>
		</svg>
	);
}

export default SvgAmk;
