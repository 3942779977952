import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const SummaryTable = styled(Table)`
	/* width: 100%; */
	margin-bottom: 0;
	font-size: 1.1rem;

	td {
		padding: 0.6rem 0.8rem;
		vertical-align: middle;
	}
`;
