import styled, { keyframes } from 'styled-components';

type SkeletonType = {
	width?: string;
	height?: string;
	margin?: string;
	padding?: string;
	inverted?: boolean;
	display?: 'inline-block' | 'block';
};

const skelAnimation = keyframes`
	0% {
			background-position: -200px 0;
	}

	100% {
			background-position: calc(200px + 100%) 0;
	}
`;

const Skeleton = styled.div`
	--first-color: ${(props: SkeletonType) =>
		props.inverted ? '#f5f5f5' : '#eee'};
	--second-color: ${(props: SkeletonType) =>
		props.inverted ? '#eee' : '#f5f5f5'};

	width: ${(props: SkeletonType) => props.width || '100%'};
	height: ${(props: SkeletonType) => props.height || '1rem'};
	margin: ${(props: SkeletonType) => props.margin || 'unset'};
	padding: ${(props: SkeletonType) => props.padding || 'unset'};

	display: ${(props: SkeletonType) => props.display || 'inline-block'};
	background-color: var(--first-color);
	background-image: linear-gradient(
		90deg,
		var(--first-color),
		var(--second-color),
		var(--first-color)
	);
	background-size: 200px 100%;
	background-repeat: no-repeat;
	border-radius: 4px;

	-webkit-animation: ${skelAnimation} 1.2s ease-in-out infinite;
	animation: ${skelAnimation} 1.2s ease-in-out infinite;
`;

export default Skeleton;
