import styled, { css } from 'styled-components';

import { darken, cssVar, adjustHue } from 'polished';

export type InfoCardProps = {
	colored?: boolean;
	gradient?: boolean;
	color: string;
};

const getColorBg = (color: string, gradient: boolean) => {
	const darkerColor = adjustHue('10%', darken(0.1, cssVar(`--${color}`)));

	return `
		background-color: var(--${color});
		background-image: ${
			gradient
				? `linear-gradient(
				45deg,
				${darkerColor} 0%,
				var(--${color}) 60%
			)
		`
				: 'none'
		};
	`;
};

export const InfoCardIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-radius: var(--base-border-radius);
	color: var(--always-white);
	width: 5rem;
	height: 5rem;
	font-size: 2.5rem;
`;

export const InfoCardTitle = styled.h1`
	font-size: 2.35rem;
	color: inherit;
	margin-bottom: 0.25rem;
`;

export const InfoCardSubtitle = styled.div`
	opacity: 0.8;
`;

export const InfoCard = styled.div.attrs((props: InfoCardProps) => props)`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	justify-content: center;
	position: relative;
	padding: 1rem;
	margin-bottom: var(--margin-bottom);
	border-radius: var(--base-border-radius);
	text-align: right;
	box-shadow: var(--base-box-shadow);
	background: var(--always-white);
	overflow: ${({ colored }) => (colored ? 'hidden' : 'inherit')};
	color: ${({ colored }) => (colored ? 'white' : 'var(--base-color)')};

	${InfoCardIcon} {
		${({ colored, gradient, color = 'primary' }) =>
			colored
				? css`
						background: transparent;
						opacity: 0.125;
						font-size: 12rem;
						justify-content: start;
				  `
				: css`
				margin-top: -3rem;
				${getColorBg(color, !!gradient)};
			);
			background-repeat: repeat-x;`}
	}

	${({ colored, color, gradient }: InfoCardProps) =>
		colored &&
		css`
			${getColorBg(color, !!gradient)};
			background-repeat: repeat-x;
		`}
`;
