import { ReactFragment } from 'react';

import FindObjectProperty from 'utils/FindObjectProperty';

import {
	FormatterType,
	FormatterParamType,
	FormatterParamRowType,
} from './IFormatter';

export const REGEX_CLEAR_ALPHANUM = /[^a-zA-Z0-9]/g;
export const REGEX_CLEAR_ALPHA = /[^a-zA-Z]/g;
export const REGEX_CLEAR_NUMERIC = /[^0-9]/g;
// eslint-disable-next-line max-len
export const REGEX_MASKED_DATE = /^(?<date>(?<day>[0-2]\d|[3][0-1])(?:\/(?<month>[0]\d|[1][0-2]))?(?:\/(?<year>\d{4}))?)(?<time>\s(?<hour>[0-1]\d|[2][0-3]):(?<minute>[0-5]\d)(?::(?<second>[0-5]\d))?)?$/;

class RegexFormatter implements FormatterType {
	constructor(
		private search: string | RegExp,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private replace: any,
		private options: { clear?: string | RegExp; maxLength?: number } = {
			clear: REGEX_CLEAR_ALPHANUM,
		}
	) {}

	format = (
		field: string,
		row?: FormatterParamRowType
	): string | ReactFragment => {
		const value = this.clearInput(
			!row ? field : FindObjectProperty(row, field) || ''
		);
		return value.replace(this.search, this.replace);
	};

	formatForInput = (data: FormatterParamType): [string, string] => {
		const value = this.clearInput(data);
		return [value, value.replace(this.search, this.replace)];
	};

	clearInput = (data: FormatterParamType): string => {
		if (typeof data !== 'string') {
			return '';
		}
		let value = String(data);
		if (this.options.clear) {
			value = value.replace(this.options.clear, '');
		}
		if (this.options.maxLength && this.options.maxLength > 0) {
			value = value.substr(0, this.options.maxLength);
		}
		return value;
	};
}

export default RegexFormatter;
