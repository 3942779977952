import {
	HttpGetListParams,
	HttpGetListResult,
	ID,
	HttpPostResultAny,
	ExecutiveClubOrder,
	ExecutiveClubDetails,
	ExecutiveClubStatement,
	ExecutiveClubAdvance,
	PersonAddress,
	PersonPhone,
	Balance,
	MyStoreProducts,
	EcProductDetails,
	ExecutiveUpdateRegister,
} from 'types';

import Crud from './crud.service';

const getBalance = (): Promise<number> =>
	Crud.getOne<Balance>('my/balance', 0).then(
		(result) => Number(result.total) // Converte para número pq o backend envia uma string
	);

const getAddresses = (_id: ID): Promise<PersonAddress[]> =>
	Crud.getListData('my/address', {});
// Crud.getListData('my/address', {});

const createOrUpdateAddress = (
	id: ID | null,
	data: PersonAddress
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('my/address/{id}', id as number, data);
	}
	return Crud.create('my/address', data);
};

const deleteAddress = (id: ID): Promise<HttpPostResultAny> =>
	Crud.delete('my/address/{id}', id);

const getPhones = (): Promise<PersonPhone[]> =>
	Crud.getListData('my/phone', {});

const updatePhone = (phone: PersonPhone): Promise<HttpPostResultAny> =>
	Crud.update('my/phone/{id}', phone.idPhone as number, phone);

const createPhone = (phone: PersonPhone): Promise<HttpPostResultAny> =>
	Crud.create('my/phone', phone);

const deletePhone = (id: ID): Promise<HttpPostResultAny> =>
	Crud.delete('my/phone/{id}', id);

const getClubOrders = (id: ID) => {
	let path = 'my/club/order';
	if (id !== null) {
		path = 'club/{id}/order';
	}

	return Crud.getListData<ExecutiveClubOrder>(path, { params: { id } });
};

const getClubDetails = (id: ID) => {
	let path = 'my/club/detail';
	if (id !== null) {
		path = 'club/{id}/detail';
	}

	return Crud.getOne<ExecutiveClubDetails>(path, id as number);
};

const getClubStatement = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveClubStatement>> => {
	let path = 'my/club/statement';
	if (params?.params?.id) {
		path = 'club/{id}/statement';
	}

	return Crud.getList<ExecutiveClubStatement>(path, params);
};

const getClubAdvance = (
	params: HttpGetListParams
): Promise<HttpGetListResult<ExecutiveClubAdvance>> => {
	let path = 'my/club/advance';
	if (params?.params?.id) {
		path = 'club/{id}/advance';
	}

	return Crud.getList<ExecutiveClubAdvance>(path, params);
};

const updateClubAdvance = (
	data: ExecutiveClubAdvance
): Promise<HttpPostResultAny> =>
	Crud.create<ExecutiveClubAdvance>('my/club/advance', data);

const getMyStoreProducts = (
	params: HttpGetListParams
): Promise<HttpGetListResult<MyStoreProducts>> =>
	Crud.getList<MyStoreProducts>('my/storeMMN/product/select', params);

const getMyStoreProduct = (id: ID) => {
	return Crud.getOne<EcProductDetails>(
		'my/storeMMN/product/{id}',
		id as number
	);
};

const updateRegister = (data: ExecutiveUpdateRegister) =>
	Crud.update('my/executive', 0, data);

export default {
	getAddresses,
	createOrUpdateAddress,
	deleteAddress,
	getPhones,
	createPhone,
	updatePhone,
	deletePhone,
	getBalance,
	getClubOrders,
	getClubDetails,
	getClubStatement,
	getClubAdvance,
	updateClubAdvance,
	getMyStoreProducts,
	getMyStoreProduct,
	updateRegister,
};
