import React from 'react';

import { faArrowLeft, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';

import { Button } from 'components';
import { NewsNotification, NewsNotificationAttach } from 'types';
import { history } from 'utils';
import { dateHourMinFormatter } from 'utils/formatters';

import { PageHeader } from 'packages/admin/components';

import {
	Container,
	ItemBody,
	ImageItem,
	AuthorDate,
	ContentText,
	AttachList,
	AttachListItem,
} from './newsDetails/styles';

interface NewsDetailsProps {
	location: Location<NewsNotification>;
}

const createMarkup = (description: string) => {
	return { __html: description };
};

const NewsDetails: React.FC<NewsDetailsProps> = ({ location }) => {
	const { state: item } = location;

	return (
		<Container>
			<PageHeader alignItems="center">
				<PageHeader.Title>
					<Button
						variant="outline-primary"
						size="sm"
						className="mb-2"
						onClick={() => history.push('/app/utils/news')}
					>
						<FAIcon icon={faArrowLeft} className="mr-1" /> Voltar
					</Button>
					<br />
					{item.title}
				</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils' },
						{
							label: 'Notícias',
							path: '/app/utils/news',
						},
						{
							label: `${item.title}`,
							active: true,
						},
					]}
				/>
			</PageHeader>

			<ItemBody>
				<ImageItem
					src={String(item?.imgUrl) || '/assets/images/image_news_default.jpg'}
					alt={item.title}
					className="img-fluid"
				/>
				<AuthorDate className="text-muted">
					<i>Por {item.createdBy?.name}</i> |{' '}
					{dateHourMinFormatter.format(item.releaseDate)}
				</AuthorDate>

				<ContentText dangerouslySetInnerHTML={createMarkup(item.description)} />

				{Array.isArray(item.attach) && item.attach?.length > 0 && (
					<AttachList>
						<div>Anexos:</div>
						<AttachListItem>
							{(item.attach as NewsNotificationAttach[]).map((attach) => (
								<span key={attach.idAttach ?? 0}>
									<a
										href={attach.file}
										target="_blank"
										rel="noopener noreferrer"
									>
										<FAIcon icon={faPaperclip} className="mr-2" />
										{attach.file.substring(attach.file.lastIndexOf('/') + 1)}
									</a>
								</span>
							))}
						</AttachListItem>
					</AttachList>
				)}
			</ItemBody>
		</Container>
	);
};

export default NewsDetails;
