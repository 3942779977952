/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import reduxThunk from 'redux-thunk';

import rootReducer from './ducks/rootReducer';
import persistReducers from './persistReducers';

// Extensão Redux dev-tools no navegador:
const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	persistReducers(rootReducer),
	composeEnhancers(applyMiddleware(reduxThunk))
);
const persistor = persistStore(store);

export { store, persistor };
