import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Decimal from 'decimal.js';

import {
	actions as cartActions,
	ShoppingCartItem,
} from 'store/ducks/shoppingCart';
import { EcProductDetails } from 'types';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import useShoppingCartName from 'packages/ecommerce/hooks/useShoppingCartName';

import { Text, Small } from '../Typography';

import { SmallImage, SmallInputQty } from './Shared';
import ProductItemSkeleton from './small/Skeleton';

type ProductItemComponent = React.FC<ProductItemProps> & {
	Skeleton: typeof ProductItemSkeleton;
};

export type ProductItemProps = {
	item: ShoppingCartItem;
	product: EcProductDetails | undefined;
	showInput?: boolean;
	showRemove?: boolean;
	showTotalPrice?: boolean;
	className?: string;
};

const ProductItem: ProductItemComponent = ({
	item,
	product,
	showInput,
	showRemove,
	showTotalPrice,
	className,
}) => {
	const dispatch = useDispatch();
	const cartName = useShoppingCartName();

	const onQtyChange = useCallback(
		(qty: number) => {
			dispatch(cartActions.setQuantity(cartName, item.id, qty));
		},
		[dispatch, item.id, cartName]
	);

	const onRemove = useCallback(() => {
		dispatch(cartActions.removeItem(cartName, item.id));
	}, [dispatch, item.id, cartName]);

	if (!product) {
		return <></>;
	}

	const totalPrice: number = new Decimal(product.price)
		.mul(item.quantity)
		.toDecimalPlaces(2)
		.toNumber();

	return (
		<div className={classNames('align-items-center', className)}>
			<Row noGutters>
				<Col className="col-auto">
					<SmallImage
						className="mr-2"
						src={product?.image[0].idImage}
						alt={product?.name}
					/>
				</Col>
				<Col>
					<Row noGutters>
						<Col>
							<Small className="d-block mb-2">{product?.name}</Small>
						</Col>
						{showRemove && (
							<Col className="col-auto">
								<Button
									size="sm"
									variant="link"
									className="text-danger"
									type="button"
									onClick={onRemove}
								>
									<FontAwesomeIcon icon={faTimesCircle} />
								</Button>
							</Col>
						)}
					</Row>
					<Row noGutters className="align-items-center">
						<Col className="col-auto mr-2">
							{showInput ? (
								<SmallInputQty
									size="sm"
									hideButtons
									quantity={item.quantity}
									onChange={onQtyChange}
								/>
							) : (
								<>{item.quantity}</>
							)}
						</Col>
						<Col>
							<Text>
								<span className="text-muted">x</span>{' '}
								{currencyWithSymbolFormatter.format(product?.price || 0)}
							</Text>
						</Col>
						{showTotalPrice && (
							<Col className="text-right">
								{currencyWithSymbolFormatter.format(totalPrice)}
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

ProductItem.Skeleton = ProductItemSkeleton;

ProductItem.displayName = 'ProductCard.Small';
ProductItem.whyDidYouRender = true;
ProductItem.defaultProps = {
	showInput: true,
	showRemove: true,
};

export default ProductItem;
