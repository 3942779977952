import { MutableRefObject, useEffect } from 'react';

const useIntersectionObserver = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref: MutableRefObject<any>,
	threshold: number,
	onIntersect: (
		entries: IntersectionObserverEntry[],
		observer: IntersectionObserver
	) => void
) => {
	useEffect(() => {
		const observer = new IntersectionObserver(onIntersect, {
			rootMargin: '0px',
			threshold,
		});

		const { current } = ref;
		observer.observe(current);

		return () => {
			observer.unobserve(current);
		};
	});
};

export default useIntersectionObserver;
