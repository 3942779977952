/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';

import { FormikHelpers, Formik, Form as FormikForm, FormikProps } from 'formik';
import { Col, Form, InputGroup } from 'react-bootstrap';

import { faKey } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import {
	FontAwesomeIcon as FAIcon,
	FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Empty, Panel } from 'components';
import Card from 'components/Card';
import faAmk from 'components/FontAwesomeIcons/faAmk';
import { Input, Select } from 'components/Formik';
import { useToast } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import accountService from 'services/account.service';
import payoutService from 'services/payout.service';
import { ApiCodes } from 'types';
import { HttpBaseResult } from 'types/api.types';
import { Account } from 'types/financial.types';
import { currencyFormatter } from 'utils/formatters';
import history from 'utils/history';

import { FormSubmitButton, FormCancelButton } from 'packages/admin/components';
import PageLayoutFormWrapper from 'packages/admin/components/PageLayoutFormWrapper';

import SkeletonForm from './payout/SkeletonForm';
import { Credit } from './payout/styles';
import { PayoutFormType } from './payout/types';

const defaultFormValue: PayoutFormType = {
	idAccount: 0,
	idUser: '',
	value: 0,
	signature: '',
};

// TODO: Obter configuração de api?
const payoutConfig = {
	minValue: 300,
};

const Payout: React.FC = () => {
	const [toast] = useToast();
	const {
		executive: { balance: credit },
		reloadExecutiveBalance,
	} = useSessionContext();

	// Validações
	const formSchema = useMemo(
		() =>
			Yup.object<PayoutFormType>().shape({
				account: Yup.object<Account>()
					.transform((value: Account | null) =>
						Number((value as Account)?.idAccount).toString() !== 'NaN'
							? value
							: null
					)
					.nullable()
					.required('Campo obrigatório'),
				value: Yup.number()
					.required('Campo obrigatório')
					.test(
						'minValue',
						`O valor mínimo para resgate é de ${currencyFormatter.format(
							payoutConfig.minValue
						)}`,
						(value) => (value ?? 0) >= payoutConfig.minValue
					)
					.test(
						'creditLimit',
						'O valor excede a quantidade de crédito disponível',
						(value) => !!credit && credit > 0.005 && (value ?? 0) <= credit
					),
				signature: Yup.string().required('Campo obrigatório'),
			}),
		[credit]
	);

	const loadData = useCallback(
		(_id: string) => async () => {
			const accounts = await accountService.getForList({});

			return {
				payout: defaultFormValue,
				accounts: accounts.data,
			};
		},
		[]
	);

	// Handlers
	const handleSubmit = useCallback(
		async (
			_id,
			{ account, ...values }: PayoutFormType,
			{ setFieldValue }: FormikHelpers<PayoutFormType>
		) => {
			const submit: PayoutFormType = {
				...values,
				idAccount: account?.idAccount ?? 0,
				idUser: account!.idUser,
				account: undefined,
			};

			try {
				const { code } = await payoutService.requestPayout(submit);
				// eslint-disable-next-line no-console
				console.assert(
					ApiCodes.success(code),
					'Api não retornou status diferente de 2xx?!'
				);

				reloadExecutiveBalance();

				toast('Solicitação de resgate efetuada com sucesso!', {
					type: 'success',
				});
				history.push('/app/financial/payout');
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});

				if (code === ApiCodes.Error.InvalidCredentials) {
					setFieldValue('signature', '');
				}
			}
		},
		[toast, reloadExecutiveBalance]
	);

	return (
		<PageLayoutFormWrapper
			title="Saque"
			breadcrumb={[
				{ label: 'Financeiro', path: '/app/financial' },
				{ label: 'Saques', path: '/app/financial/payout' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data, isError, refetch }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.payout}
					validationSchema={formSchema}
				>
					{(formProps: FormikProps<PayoutFormType>) => (
						<FormikForm>
							{isError ? (
								<Card>
									<Card.Content className="offset-lg-4 col-lg-4">
										<Empty
											description="Não foi possível ler dados do servidor, clique aqui para tentar novamente."
											image={
												<FAIcon icon={faTimesCircle} className="text-danger" />
											}
											onClick={() => refetch()}
										/>
									</Card.Content>
								</Card>
							) : data?.accounts.length === 0 ? (
								<Card>
									<Card.Content className="offset-lg-4 col-lg-4">
										<Empty
											description={
												'Você não tem uma conta cadastrada para saque,\nclique aqui para cadastrar uma.'
											}
											image={
												<FAIcon icon={faTimesCircle} className="text-info" />
											}
											onClick={() =>
												history.push(
													'/app/financial/account/create?redirect=/app/financial/payout/request'
												)
											}
										/>
									</Card.Content>
								</Card>
							) : (
								<Card>
									<Card.Content className="offset-lg-4 col-lg-4">
										{credit !== null && (
											<Form.Row>
												<Panel width="full">
													<Panel.Title>
														{credit === null ? (
															<span>Carregando...</span>
														) : (
															<>
																{'Você tem '}
																<FAIcon icon={faAmk} />
																<Credit>
																	{` ${currencyFormatter.format(credit)} `}
																</Credit>
																disponíveis
															</>
														)}
													</Panel.Title>
													<Panel.Text>
														{'O valor mínimo para resgate é de '}
														<FAIcon icon={faAmk} />
														<span>
															{` ${currencyFormatter.format(
																payoutConfig.minValue
															)}`}
														</span>
														{
															'\nOs pagamentos são efetuados em até 10 dias úteis.'
														}
													</Panel.Text>
												</Panel>
											</Form.Row>
										)}

										{credit !== null &&
											credit >= payoutConfig.minValue &&
											data!.accounts.length > 0 && (
												<>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>Conta para Depósito</Form.Label>
															{/* TODO: Ao selecionar a conta, exibir dados do banco? */}
															<Select
																name="account"
																options={data!.accounts}
																optionLabel="identifier"
															/>
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>Valor</Form.Label>
															<Input
																name="value"
																formatter={currencyFormatter}
																type="text"
																prepend={
																	<InputGroup.Text>
																		<FontAwesomeIcon icon={faAmk} />
																	</InputGroup.Text>
																}
															/>
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>
																Assinatura Eletrônica Atual
															</Form.Label>
															<Input
																name="signature"
																type="password"
																placeholder="Senha"
																prepend={
																	<InputGroup.Text>
																		<FAIcon fixedWidth icon={faKey} />
																	</InputGroup.Text>
																}
															/>
														</Form.Group>
													</Form.Row>
												</>
											)}

										<div className="text-right my-2">
											<FormCancelButton
												to="/app/financial/payout"
												hasEditPermission={false}
												className="mr-3"
											/>
											{credit !== null && payoutConfig.minValue <= credit && (
												<FormSubmitButton
													isSubmitting={formProps.isSubmitting}
												/>
											)}
										</div>
									</Card.Content>
								</Card>
							)}
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default Payout;
