import styled from 'styled-components';

import { cssVar } from 'polished';

export const AttachList = styled.div`
	display: flex;
	align-items: center;
`;

export const AttachItem = styled.div`
	width: 155px;
	height: 12.5rem;
	background-color: var(--gray-20);
	border-radius: var(--base-border-radius);
	box-shadow: var(--base-box-shadow);

	div {
		display: flex;
		align-items: center;
		padding-top: 5px;
	}

	button {
		border-right: 1px solid var(--gray-30);
	}

	span {
		font-size: 0.9rem;
		padding: 0 5px;
	}

	&:not(:first-child) {
		margin-left: 5px;
	}
`;

type IconProps = {
	bg: 'red' | 'blue' | 'yellow';
};

export const AttachIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10rem;
	border-radius: var(--base-border-radius);
	background-color: ${(props: IconProps) => cssVar(`--${props.bg}`)};
	color: var(--light);
	cursor: pointer;
`;
