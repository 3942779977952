import styled from 'styled-components';

export const AlertTitle = styled.div`
	font-size: 1.2rem;
	font-weight: 500;
`;

export const Separator = styled.div`
	position: relative;
	height: 16px;

	&:before {
		position: absolute;
		width: 100%;
		top: calc(16px / 2);
		content: '';
		display: block;
		border-top: 1px solid var(--border-secondary);
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: flex-end;
`;

export const FormTitle = styled.div`
	font-size: 1.2rem;
	padding: 15px 0px;
`;
