import React from 'react';

import { SessionPerson, HttpBaseResult } from 'types';

export type SessionContextState = {
	auth: {
		person: SessionPerson | null;
		permissions: string[];
		lastError: HttpBaseResult | null;
	};
	executive: {
		balance: number | null;
	};
};

export type SessionContextType = SessionContextState & {
	update: (state: Partial<SessionContextState>) => void;
	reloadExecutiveBalance: () => void;
};

export const defaultSessionContextValue: SessionContextType = {
	auth: {
		person: null,
		permissions: [],
		lastError: null,
	},
	executive: {
		balance: null,
	},
	update: () => {},
	reloadExecutiveBalance: () => {},
};

const SessionContext = React.createContext<SessionContextType>(
	defaultSessionContextValue
);

export default SessionContext;
