import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FormikProps,
	FormikHelpers,
} from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { faEye, faEyeSlash, faKey } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import ElectronicSignModal from 'modals/ElectronicSignModal';
import * as Yup from 'yup';

import { ScoreBar } from 'components';
import { FormikEffect, Input, Switch } from 'components/Formik';
import { useModal, useToast } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import userService from 'services/system-user.service';
import { ApiCodes, HttpBaseResult, UserPaType } from 'types';
import history from 'utils/history';
import PasswordStrength from 'utils/PasswordStrength';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';

import SkeletonForm from './user/SkeletonForm';

export const defaultFormValue: UserPaType = {
	idPerson: null,
	name: '',
	login: '',
	dateRegister: new Date(),
	active: true,
	idStatus: 3,
	signature: '',
	password: '',
	confirmPassword: '',
};

const User: React.FC = () => {
	const [toast] = useToast();
	const [showFields, setShowFields] = useState<{
		password: boolean;
		confirmPassword: boolean;
	}>({
		password: false,
		confirmPassword: false,
	});
	const [newPasswordStrength, setNewPasswordStrength] = useState<number>(0);
	const open = useModal();
	const {
		auth: { person },
	} = useSessionContext();

	// Validações
	const validationSchema = useMemo(() => {
		return Yup.object<UserPaType>({
			name: Yup.string().required('Campo obrigatório'),
			login: Yup.string().required('Campo Obrigatório'),
			password: Yup.lazy((value) => {
				if (value) {
					return Yup.string()
						.min(8, 'A senha deve possuir no mínimo 8 caracteres')
						.test(
							'newPasswordStrength',
							'Aumente a segurança de sua senha letras maiúsculas, minúsculas, números e símbolos',
							async () => newPasswordStrength > 45
						);
				}
				return Yup.string().when('idPerson', {
					is: (idPerson) => idPerson,
					then: Yup.string(),
					otherwise: Yup.string().required('Campo obrigatório'),
				});
			}),
			confirmPassword: Yup.lazy((value) => {
				if (value) {
					return Yup.string().oneOf(
						[Yup.ref('password'), ''],
						'A confirmação da senha não é igual a senha'
					);
				}
				return Yup.string().when('idPerson', {
					is: (idPerson) => idPerson,
					then: Yup.string().when('password', {
						is: (password) => password,
						then: Yup.string().required('Confirme a senha'),
					}),
					otherwise: Yup.string().required('Confirme a senha'),
				});
			}),
			active: Yup.mixed(),
			dateRegister: Yup.mixed(),
			idPerson: Yup.mixed(),
			idStatus: Yup.mixed(),
		});
	}, [newPasswordStrength]);

	const clearStringToLogin = (name: string) => {
		return name
			.trim()
			.toLowerCase()
			.replace(/[^0-9,a-z]/g, '');
	};

	const onNameChange = useCallback(
		(
			value: string,
			event: ChangeEvent<HTMLInputElement> | undefined,
			form: FormikProps<UserPaType>
		) => {
			if (!event) return;
			const nameToLogin = clearStringToLogin(value);
			form.setFieldValue('login', nameToLogin);
		},
		[]
	);

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return Promise.resolve({ user: { ...defaultFormValue } });
			}

			const user = await userService.getUserPaById(Number(id));
			user.active = user.idStatus === 3;
			user.dateRegister = null;
			return Promise.resolve({ user });
		},
		[]
	);

	const handleSubmit = useCallback(
		async (
			id: string,
			values: FormikValues,
			helpers: FormikHelpers<UserPaType>
		) => {
			const submit = { ...values } as UserPaType;

			await new Promise<void>((resolve) =>
				open(
					ElectronicSignModal,
					{
						message: (
							<p>
								Digite sua Assinatura Eletrônica para confirmar a{' '}
								{id === 'create' ? 'criação ' : 'edição '}
								do usuário <strong>{submit.name}</strong>
							</p>
						),
					},
					async (signature?: unknown) => {
						if (!signature) {
							helpers.setSubmitting(false);
							resolve();
							return;
						}

						submit.signature = String(signature);
						submit.active = submit.active ? 1 : 0;
						submit.idStatus = submit.active ? 3 : 4;
						submit.login = `${person!.id}-${clearStringToLogin(submit.login)}`;

						if (id === 'create') {
							submit.dateRegister = submit.dateRegister!.toLocaleString();
						} else {
							submit.dateRegister = null;
						}

						try {
							await userService.createOrUpdateUserPa(Number(id), submit);
							toast(`Usuário ${id ? 'alterado' : 'criado'} com sucesso`, {
								type: 'success',
							});
							setNewPasswordStrength(0);
							helpers.setSubmitting(false);
							history.push('/app/distributor/user');
						} catch (e) {
							const { code, message } = e as HttpBaseResult;
							// eslint-disable-next-line no-console
							console.log('Error message: ', message);
							toast(ApiCodes.getCodeString(code), {
								type: 'error',
							});
						} finally {
							helpers.setSubmitting(false);
							resolve();
						}
					}
				)
			);
		},
		[open, person, toast]
	);

	const onChangeNewPassword = (login: string, value: string) => {
		setNewPasswordStrength(PasswordStrength(login || '', value || ''));
	};

	return (
		<PageLayoutFormWrapper
			title="Usuário"
			breadcrumb={[
				{ label: 'Ponto de Apoio', path: '/app/distributor/order' },
				{ label: 'Usuários', path: '/app/distributor/user' },
			]}
			skeleton={SkeletonForm}
			load={loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(id, value, helpers)}
					initialValues={data!.user}
					validationSchema={validationSchema}
				>
					{(form: FormikProps<UserPaType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Body>
									<Row>
										<Col lg={{ offset: 3, span: 6 }}>
											<FormGroupTitle>
												Dados Cadastrais
												<Form.Group className="float-right d-inline mb-0">
													<Switch
														name="active"
														defaultChecked
														disabled={form.isSubmitting}
													/>
													<Form.Label className="text-muted pl-1">
														{' '}
														Ativo
													</Form.Label>
												</Form.Group>
											</FormGroupTitle>

											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Nome</Form.Label>
													<Input
														name="name"
														type="text"
														disabled={form.isSubmitting}
														onChange={(value, event) =>
															onNameChange(value, event, form)
														}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Login</Form.Label>
													<Input
														name="login"
														type="text"
														disabled={form.isSubmitting}
														prepend={
															<InputGroup.Text>{person?.id}-</InputGroup.Text>
														}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Senha</Form.Label>
													<Input
														name="password"
														type={showFields.password ? 'text' : 'password'}
														disabled={form.isSubmitting}
														placeholder="Senha"
														onChange={(value) => {
															onChangeNewPassword(form.values.login, value);
														}}
														prepend={
															<InputGroup.Text>
																<FAIcon fixedWidth icon={faKey} />
															</InputGroup.Text>
														}
														append={
															<Button
																variant="outline-primary"
																onClick={() => {
																	setShowFields({
																		...showFields,
																		password: !showFields.password,
																	});
																}}
															>
																<FAIcon
																	icon={
																		showFields.password ? faEye : faEyeSlash
																	}
																/>
															</Button>
														}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Confirmar Senha</Form.Label>
													<Input
														name="confirmPassword"
														type={
															showFields.confirmPassword ? 'text' : 'password'
														}
														disabled={form.isSubmitting}
														placeholder="Senha"
														prepend={
															<InputGroup.Text>
																<FAIcon fixedWidth icon={faKey} />
															</InputGroup.Text>
														}
														append={
															<Button
																variant="outline-primary"
																onClick={() => {
																	setShowFields({
																		...showFields,
																		confirmPassword: !showFields.confirmPassword,
																	});
																}}
															>
																<FAIcon
																	icon={
																		showFields.confirmPassword
																			? faEye
																			: faEyeSlash
																	}
																/>
															</Button>
														}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Row>
												<Form.Group as={Col}>
													<Form.Label>Força da Senha:</Form.Label>
													<ScoreBar score={newPasswordStrength} />
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Body>

								<Card.Footer className="text-right">
									<FormCancelButton
										isSubmitting={form.isSubmitting}
										to="/app/distributor/user"
									/>
									<FormSubmitButton isSubmitting={form.isSubmitting} />
								</Card.Footer>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default User;
