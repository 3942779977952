import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Skel from 'react-loading-skeleton';
import styled from 'styled-components';

const Skeleton = styled(Skel)`
	display: block;
`;
const Label = styled.div`
	height: 1.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SkelGroup = (props: any) => (
	<Form.Group {...props}>
		<Form.Label>
			{' '}
			<Label />
		</Form.Label>
		<Skeleton height="1.5rem" />
	</Form.Group>
);

const StoreBannerFormSkeleton: React.FC = () => (
	<Card>
		<Card.Header className="text-right">
			<Skeleton width={50} />
		</Card.Header>

		<Card.Body>
			<Form.Row>
				<SkelGroup as={Col} md={12} lg={6} />
				<SkelGroup as={Col} md={12} lg={6} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>

			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
			<Form.Row>
				<SkelGroup as={Col} md={6} lg={4} />
			</Form.Row>
		</Card.Body>

		<Card.Footer className="text-right">
			<span className="mr-2">
				<Skel width={100} height="2rem" />
			</span>
			<Skel width={100} height="2rem" />
		</Card.Footer>
	</Card>
);

export default StoreBannerFormSkeleton;
