import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, cssVar } from 'polished';

type ColorType = {
	info?: boolean;
	success?: boolean;
	warning?: boolean;
	danger?: boolean;
};

type PanelType = ColorType;

export const Panel = styled.div`
	width: 100%;
	min-width: min-content;
	max-width: 390px;
	padding: 18px 15px;
	margin: 0px auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	border: 1px solid var(--border-color);

	cursor: ${(props) => (props.onClick ? 'pointer' : 'unset')};

	color: ${(props: PanelType) =>
		(props.info && 'var(--info)') ||
		(props.success && 'var(--success)') ||
		(props.warning && 'var(--warning)') ||
		(props.danger && 'var(--danger)') ||
		'unset'};

	&:not(:first-child) {
		margin-top: 8px;
	}
`;

export const InfoMessage = styled.div`
	white-space: pre-line;
	text-align: center;
	min-width: 100%;
`;

export const AlertMessage = styled(InfoMessage)`
	font-weight: bold;
`;

export const Separator = styled.div`
	position: relative;
	height: 16px;

	&:before {
		position: absolute;
		width: 100%;
		top: calc(16px / 2);
		content: '';
		display: block;
		border-top: 1px solid var(--border-secondary);
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: flex-end;
`;

export const ButtonLink = styled(Button)`
	padding: 0 5px;
	background-color: ${darken(0.05, cssVar('--warning'))};
	color: var(--light);

	&:hover {
		background-color: ${darken(0.03, cssVar('--warning'))};
		color: var(--light);
	}
`;

export const AlertIcon = styled(FontAwesomeIcon)`
	margin-bottom: 16px;
`;

export const MessageAmmount = styled.div`
	font-size: 16px;
	color: ${(props: { textColor?: boolean }) =>
		props.textColor ? `${cssVar('--gray-60')}` : 'inherity'};
	display: flex;
	align-items: flex-end;

	svg {
		margin: 2px 0 0 5px;
		align-self: center;
	}
`;

export const NumberAmmount = styled.div`
	font-size: 28px;
	line-height: 30px;
	font-weight: bold;
	padding: 0 5px;
	color: var(--gray-70);
`;

export const SkeletonAmmount = styled.div`
	margin-left: 5px;
`;
