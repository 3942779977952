import React, {
	useEffect,
	FunctionComponent,
	useState,
	useCallback,
	ChangeEvent,
} from 'react';

import { useField } from 'formik';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { FormatterType } from 'utils/Formatter/IFormatter';

/**
 *	Definição de props necessárias para o input
 */
export interface InputProps extends Omit<FormControlProps, 'onChange'> {
	name: string;
	placeholder?: string;
	formatter?: FormatterType;
	useFormattedValue?: boolean;
	className?: string;
	prepend?: React.ReactNode | React.ReactNodeArray;
	append?: React.ReactNode | React.ReactNodeArray;
	onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Input genérico já ligado ao unform
 */
const Input: FunctionComponent<InputProps> = (props) => {
	const {
		name,
		formatter,
		useFormattedValue,
		onChange,
		prepend,
		append,
		...rest
	} = props;

	const [{ onBlur, value }, meta, helpers] = useField(name);

	const { initialValue, error, touched } = meta;
	const { setValue } = helpers;

	// Texto formatado exibido no input:
	const [formattedValue, setFormattedValue] = useState<string>(
		formatter
			? formatter.formatForInput(initialValue || value || '')[1]
			: initialValue || value || ''
	);

	const updateInputAndRef = useCallback(
		(
			newVal: string,
			updateRef = false,
			event?: ChangeEvent<HTMLInputElement>
		) => {
			const val = newVal || '';
			if (formatter) {
				const [rawValue, formatValue] = formatter.formatForInput(val);
				if (updateRef) {
					setValue(useFormattedValue ? formatValue : rawValue);
					onChange?.(useFormattedValue ? formatValue : rawValue, event);
				}
				setFormattedValue(formatValue);
			} else {
				if (updateRef) {
					setValue(val);
					onChange?.(val, event);
				}
				setFormattedValue(val);
			}
		},
		[onChange, formatter, useFormattedValue, setValue]
	);

	// Chama o onChange se o field vier com campo inicial definido:
	useEffect(() => {
		if (initialValue) {
			const initValue = formatter
				? formatter.formatForInput(initialValue)[useFormattedValue ? 1 : 0]
				: initialValue;

			onChange?.(initValue);
		}
	}, [initialValue]);

	// Atualiza o input quando o value for alterado externamente:
	useEffect(() => {
		updateInputAndRef(value || '', false);
	}, [updateInputAndRef, value]);

	const onInputChange = useCallback(
		(input: ChangeEvent<HTMLInputElement>) => {
			const val = input.currentTarget.value;
			updateInputAndRef(val, true, input);
		},
		[updateInputAndRef]
	);

	return (
		<>
			<InputGroup>
				{prepend && <InputGroup.Prepend>{prepend}</InputGroup.Prepend>}
				<FormControl
					as="input"
					name={name}
					value={formattedValue}
					onChange={onInputChange}
					onBlur={onBlur}
					isInvalid={!!error && touched}
					{...rest}
				/>
				{append && <InputGroup.Append>{append}</InputGroup.Append>}
				{error && touched && (
					<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
				)}
			</InputGroup>
		</>
	);
};

export default Input;
