/* eslint-disable max-classes-per-file */
import React, { ReactFragment } from 'react';
import { Link } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useModal, { ModalOpenType } from 'hooks/useModal';
import { ID } from 'types';
import { FindObjectProperty } from 'utils';

import DefaultFormatter from './Default';
import { FormatterType, FormatterParamRowType } from './IFormatter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ButtonLinkOnClickFn<T, P, R> = (
	id: ID,
	row: T,
	modalOpen: ModalOpenType<P, R>
) => void;

export type ButtonLinkComponentProps<T> = React.HTMLProps<HTMLButtonElement> & {
	// row é injetado no componente para cada linha
	row: T;
};

export type ButtonLinkFormatterConfig<T, P, R> = {
	link?: string;
	onClick?: ButtonLinkOnClickFn<T, P, R>;
	className?: string;
	label?: string | React.ReactNode | ((row: T) => string | React.ReactNode);
	icon?: IconProp;
	component?: React.FC<ButtonLinkComponentProps<T>>;
	componentProps?: ButtonLinkComponentProps<T>;
};

//
// Componente de botão genérico apenas para chamar a hook useModal incondicionalmente
//
const ButtonOrLinkWithModal = <T, P, R>(
	props: ButtonLinkFormatterConfig<T, P, R> & {
		row: T;
		field: string;
		isEnabled: boolean;
	}
) => {
	const {
		row,
		field,
		isEnabled,
		label,
		icon,
		link = '',
		onClick,
		className = 'btn btn-link btn-sm',
		component: Component,
		componentProps,
	} = props;
	const open = useModal<P, R>();

	if (Component) {
		return (
			<Component
				{...((componentProps || {}) as ButtonLinkComponentProps<T>)}
				row={row}
			/>
		);
	}

	if (process.env.NODE_ENV === 'development') {
		if (!label && !icon) {
			throw Error(
				'[ButtonLink] label ou icon deve ser declarado para usar o EditLink/ButtonLink'
			);
		}
	}

	const data = !row ? field : FindObjectProperty(row, field) || '';

	if (!isEnabled || onClick !== undefined) {
		return (
			<button
				type="button"
				className={className}
				disabled={!isEnabled}
				onClick={() => onClick?.(Number(data), row, open)}
			>
				{icon && (
					<FontAwesomeIcon icon={icon} className={label ? 'mr-2' : ''} />
				)}
				{typeof label === 'function' ? label(row) : label}
			</button>
		);
	}

	const mappedLink = link.replace('{id}', data as string);

	return (
		<Link to={mappedLink} className={className}>
			{icon && <FontAwesomeIcon icon={icon} className={label ? 'mr-2' : ''} />}
			{label}
		</Link>
	);
};

class ButtonLinkFormatter<T, P, R>
	extends DefaultFormatter
	implements FormatterType {
	protected isEnabled = true;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(protected config: ButtonLinkFormatterConfig<T, P, R>) {
		super();
	}

	setEnabled = (isEnabled = true) => {
		this.isEnabled = isEnabled;
	};

	format = (
		field: string,
		row?: FormatterParamRowType
	): string | ReactFragment => (
		<ButtonOrLinkWithModal
			{...this.config}
			row={row}
			field={field}
			isEnabled={this.isEnabled}
		/>
	);
}

export default ButtonLinkFormatter;
