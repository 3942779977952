import ticketService from 'services/ticket.service';
import {
	editLinkFormatter,
	numberFormatter,
	dateTimeFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Sistema',
		path: '/admin/support/tickets',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	SupportTickets: {
		title: 'Chamados de TI',
		breadcrumbItems: [
			...adminBreadcrumb,
			{ label: 'Chamados de TI', path: '/admin/support/tickets/all' },
		],
		action: ticketService.getTickets,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idTicket',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: '/admin/support/tickets/{id}',
					}),
				},
				{
					key: 'idTicket',
					title: 'Chamado',
					searchable: true,
					className: 'text-center',
					formatter: numberFormatter,
				},
				{ key: 'personName', title: 'Aberto Por', searchable: true },
				{
					key: 'date',
					title: 'Data',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{ key: 'category', title: 'Category', searchable: true },
				{ key: 'subject', title: 'Assunto', searchable: true },
				{
					key: 'description',
					title: 'Descrição',
					searchable: true,
					className: 'w-25',
				},
				{ key: 'attendant', title: 'Atendente', searchable: true },
				{
					key: 'statusDate',
					title: 'Data do Status',
					searchable: true,
					className: 'text-center',
					formatter: dateTimeFormatter,
				},
				{
					key: 'status',
					title: 'Status',
					searchable: true,
					className: 'text-center',
				},
			],
		},
	},
};

export default ScenesConfig;
