import React from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';

import { faShippingFast } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { useActor } from '@xstate/react';

import { Select } from 'components';
import Skeleton from 'components/Skeleton';
import { OrderShipment } from 'types';

import { Paragraph } from 'packages/ecommerce/components';

import { ShipmentState, ShipmentActor } from './shipmentSelect/ShipmentMachine';

type ShipmentSelectProps = {
	orderWeight: number;
	machineRef: ShipmentActor;
};

const ShipmentSelect: React.FC<ShipmentSelectProps> = ({ machineRef }) => {
	const [context, send] = useActor(machineRef);
	const current = (context as unknown) as ShipmentState;

	const isIdle = current.matches('idle');
	const isLoading = current.matches('fetching');
	const { selected: shipmentSelected, options } = current.context;

	const handleWithdrawSelect = (option: OrderShipment) =>
		send({
			type: 'SELECT',
			option,
		} as never);

	return (
		<Card className="mb-2">
			<Card.Body className="pt-0 border">
				<Paragraph className="py-2 border-bottom d-flex flex-row align-items-center">
					<FAIcon icon={faShippingFast} className="mr-2" />
					Forma de Entrega
				</Paragraph>
				{isLoading && (
					<>
						<Skeleton height="1.5rem" margin="0 0 0.5rem 0" />
						<Skeleton height="1.5rem" margin="0 0 0.5rem 0" />
						<Skeleton height="1.5rem" margin="0 0 0.5rem 0" />
					</>
				)}
				{isIdle && (
					<Row>
						<Col>Retirar no Local</Col>
						<Col className="ml-auto">
							<FormGroup>
								<Select
									options={options}
									optionLabel="name"
									value={shipmentSelected}
									onChange={handleWithdrawSelect}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
			</Card.Body>
		</Card>
	);
};

export default ShipmentSelect;
