import React, { useState, useCallback, useMemo } from 'react';

import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Card, Button, ScoreBar } from 'components';
import { FormikEffect, Input } from 'components/Formik';
import { useToast } from 'hooks';
import useSessionContext from 'hooks/useSessionContext';
import sessionService from 'services/session.service';
import { ApiCodes, ChangePassword, HttpBaseResult } from 'types';
import PasswordStrength from 'utils/PasswordStrength';

import {
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import PasswordSkeleton from './password/PasswordSkeleton';

const defaultFormValue: ChangePassword = {
	email: '',
	password: '',
	newPassword: '',
	confirmPassword: '',
};

const Password: React.FC = () => {
	const [toast] = useToast();
	const [newPasswordStrength, setNewPasswordStrength] = useState<number>(0);
	const [showFields, setShowFields] = useState<{
		password: boolean;
		newPassword: boolean;
		confirmPassword: boolean;
	}>({
		password: false,
		newPassword: false,
		confirmPassword: false,
	});
	const {
		auth: { person },
	} = useSessionContext();

	const validationSchema = useMemo(() => {
		return Yup.object<ChangePassword>({
			email: Yup.string(),
			password: Yup.string().required('Digite sua senha atual'),
			newPassword: Yup.string()
				.required('Campo obrigatório')
				.min(8, 'A senha deve possuir no mínimo 8 caracteres')
				.test(
					'password',
					'A nova senha precisa ser diferente da senha atual',
					// eslint-disable-next-line func-names
					function (v) {
						const ref = Yup.ref('password');
						// eslint-disable-next-line react/no-this-in-sfc
						return v !== this.resolve(ref);
					}
				)
				.test(
					'newPasswordStrength',
					'Aumente a segurança de sua assinatura inserindo letras maiúsculas, minúsculas, números e símbolos',
					async () => newPasswordStrength > 45
				),
			confirmPassword: Yup.string()
				.oneOf(
					[Yup.ref('newPassword'), ''],
					'A confirmação da senha não é igual a senha'
				)
				.required('Confirme sua senha'),
		});
	}, [newPasswordStrength]);

	const loadData = useCallback(() => {
		return { passwords: defaultFormValue };
	}, []);

	const handleSubmit = useCallback(
		async (
			values: ChangePassword,
			{ resetForm }: FormikHelpers<ChangePassword>
		) => {
			const submit = {
				...values,
				email: person?.email || '',
			};

			delete submit.confirmPassword;

			try {
				await sessionService.resetPassword(submit);
				resetForm();
				setNewPasswordStrength(0);
				toast('Sua Senha foi alterada com sucesso!', { type: 'success' });
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			}
		},
		[person?.email, toast]
	);

	const onChangeNewPassword = (value: string) => {
		setNewPasswordStrength(PasswordStrength('', value || ''));
	};

	return (
		<PageLayoutFormWrapper
			title="Senha de Acesso"
			breadcrumb={[{ label: 'Cadastro', path: '/app/my/details' }]}
			withPrependTitle={false}
			skeleton={PasswordSkeleton}
			load={() => loadData}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(value, helpers) => handleSubmit(value, helpers)}
					initialValues={data!.passwords}
					validationSchema={validationSchema}
				>
					{(formProps: FormikProps<ChangePassword>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator>
									<Card.Title>Alterar Senha</Card.Title>
								</Card.Header>
								<Card.Content className="offset-lg-3 col-lg-6">
									<Form.Row className="mb-3">
										<Form.Group as={Col}>
											<Form.Label>Minha Senha Atual</Form.Label>
											<Input
												name="password"
												type={showFields.password ? 'text' : 'password'}
												disabled={formProps.isSubmitting}
												append={
													<Button
														variant="outline-primary"
														disabled={formProps.isSubmitting}
														onClick={() =>
															setShowFields({
																...showFields,
																password: !showFields.password,
															})
														}
													>
														<FAIcon
															icon={showFields.password ? faEye : faEyeSlash}
														/>
													</Button>
												}
											/>
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>Minha Nova Senha</Form.Label>
											<Input
												name="newPassword"
												type={showFields.newPassword ? 'text' : 'password'}
												disabled={formProps.isSubmitting}
												onChange={onChangeNewPassword}
												append={
													<Button
														variant="outline-primary"
														disabled={formProps.isSubmitting}
														onClick={() =>
															setShowFields({
																...showFields,
																newPassword: !showFields.newPassword,
															})
														}
													>
														<FAIcon
															icon={showFields.newPassword ? faEye : faEyeSlash}
														/>
													</Button>
												}
											/>
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>Confirmar Nova Senha</Form.Label>
											<Input
												name="confirmPassword"
												type={showFields.confirmPassword ? 'text' : 'password'}
												disabled={formProps.isSubmitting}
												append={
													<Button
														variant="outline-primary"
														disabled={formProps.isSubmitting}
														onClick={() =>
															setShowFields({
																...showFields,
																confirmPassword: !showFields.confirmPassword,
															})
														}
													>
														<FAIcon
															icon={
																showFields.confirmPassword ? faEye : faEyeSlash
															}
														/>
													</Button>
												}
											/>
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>Força da Nova Senha:</Form.Label>
											<ScoreBar score={newPasswordStrength} />
										</Form.Group>
									</Form.Row>
								</Card.Content>

								<Card.Content className="border-top">
									<div className="text-right">
										<FormSubmitButton isSubmitting={formProps.isSubmitting} />
									</div>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default Password;
