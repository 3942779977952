import React from 'react';

import BsNavbar from 'react-bootstrap/Navbar';

import { ImageSvg } from 'components';

const NavbarSkeleton: React.FC = () => (
	<BsNavbar
		bg="white"
		variant="light"
		className="py-3 justify-content-center border-bottom"
	>
		<ImageSvg height={60} image="amakha" />
	</BsNavbar>
);

export default NavbarSkeleton;
