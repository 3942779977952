import styled from 'styled-components';

import { adjustHue, darken, cssVar } from 'polished';

// TODO:
// adicionar ao arquivo de types padrão
export type ColorVariant =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'danger'
	| 'info'
	| 'warning'
	| 'light'
	| 'blue'
	| 'indigo'
	| 'purple'
	| 'pink'
	| 'red'
	| 'orange'
	| 'yellow'
	| 'green'
	| 'teal'
	| 'cyan'
	| 'white'
	| 'gray'
	| 'gray-dark';

type BackgroundProps = {
	variant?: ColorVariant;
};

const getColorBg = (color: string, gradient: boolean) => {
	const darkerColor = adjustHue('10%', darken(0.1, cssVar(`--${color}`)));

	return `
		background-color: var(--${color});
		background-image: ${
			gradient
				? `linear-gradient(
				45deg,
				${darkerColor} 0%,
				var(--${color}) 60%
			)
		`
				: 'none'
		};
	`;
};

const Background = styled.div`
	position: absolute;
	z-index: -1;
	left: 0;
	width: 100%;
	top: 0;
	height: 100%;
	background-color: var(--base-bg);

	${(props: BackgroundProps) =>
		!!props.variant && getColorBg(props.variant, true)}
`;

export default Background;
