import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { faEye, faEyeSlash, faKey } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Input } from 'components/Formik';
import Panel from 'components/Panel';
import ScoreBar from 'components/ScoreBar';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { ApiCodes, ElectronicSignEntry, HttpBaseResult } from 'types';
import PasswordStrength from 'utils/PasswordStrength';

import { PageHeader, FormSubmitButton } from 'packages/admin/components';
import SkeletonForm from 'packages/escritorio/pages/financial/electronicSign/SkeletonForm';

import {
	AlertTitle,
	Separator,
	ButtonsContainer,
	FormTitle,
} from './electronicSign/styles';

const defaultFormValue: ElectronicSignEntry = {
	password: '',
	signature: '',
	newSignature: '',
	confirmNewSignature: '',
};

const ElectronicSign: React.FC = () => {
	const [toast] = useToast();
	const [havePrevSign, setHavePrevSign] = useState<boolean>(false);
	const [fatalError, setFatalError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [mailSent, setMailSent] = useState<boolean>(false);
	const [newPasswordStrength, setNewPasswordStrength] = useState<number>(0);
	const [showFields, setShowFields] = useState({
		password: false,
		signature: false,
		newSignature: false,
		confirmNewSignature: false,
	});

	// Validações
	const validationSchema = useMemo(() => {
		return Yup.object<ElectronicSignEntry>({
			password: Yup.string().required('Digite sua senha'),
			signature: Yup.string().required('Digite sua assinatura eletrônica'),
			newSignature: Yup.string()
				.required('Campo obrigatório')
				.oneOf(
					[Yup.ref('newSignature')],
					"Senha diferente do campo 'Nova Assinatura Eletrônica'"
				)
				.min(8, 'A senha deve possuir no mínimo 8 caracteres')
				.test(
					'newPasswordStrength',
					'Aumente a segurança de sua assinatura inserindo letras maiúsculas, minúsculas, números e símbolos',
					async () => newPasswordStrength > 45
				),
			confirmNewSignature: Yup.string().required('Campo obrigatório'),
		});
	}, [newPasswordStrength]);

	// Verifica a existência da assinatura atual
	const getSignatureStatus = useCallback(async () => {
		try {
			const { hasSignature } = await executiveService.getSignatureStatus();
			setHavePrevSign(hasSignature);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log({ e });
			const errorMsg =
				'Erro interno: Ocorreu um erro ao obter o status da assinatura eletrônica.';
			// setFatalError(errorMsg);
			toast(errorMsg, { type: 'error' });
		}

		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSignatureStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handlers
	const handleSubmit = async (
		form: ElectronicSignEntry, // InsertCreditDebitFormType
		helpers: FormikHelpers<ElectronicSignEntry> // InsertCreditDebitFormType
	) => {
		helpers.setSubmitting(true);

		const submit: ElectronicSignEntry = {
			password: form.password,
			signature: form.signature,
			newSignature: form.newSignature,
		};

		try {
			const { message } = await executiveService.updateSignature(submit);
			// eslint-disable-next-line no-console
			console.log('Success: ', message);
			helpers.resetForm();
			setNewPasswordStrength(0);
			toast('Sua assinatura Eletrônica foi atualizada com sucesso!', {
				type: 'success',
			});
		} catch (e) {
			const { code, message } = e as HttpBaseResult;
			// eslint-disable-next-line no-console
			console.log('Error message: ', message);
			toast(ApiCodes.getCodeString(code), {
				type: 'error',
			});
		}
		helpers.setSubmitting(false);
	};

	const onSendNewSignature = async () => {
		try {
			const { code, message } = await executiveService.sendNewSignature();

			if (ApiCodes.success(code)) {
				setHavePrevSign(true);
				setMailSent(true);
			} else {
				toast(`Não foi possível enviar o e-mail:\n${message}`);
			}
		} catch (e) {
			const { message } = e as HttpBaseResult;
			// eslint-disable-next-line no-console
			console.log('Error message: ', message);
			const errorMsg =
				'Erro interno: Ocorreu um erro ao obter o status da assinatura eletrônica.';
			setFatalError(errorMsg);
			toast(errorMsg, { type: 'error' });
		}
	};

	const onChangeNewSignature = (value: string) => {
		setNewPasswordStrength(PasswordStrength('', value || ''));
	};

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>Assinatura Eletrônica</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Financeiro', path: '/app/financial/summary' },
						{
							label: 'Assinatura Eletrônica',
							path: '/app/financial/electronicsign',
							active: true,
						},
					]}
				/>
			</PageHeader>
			{loading ? (
				<SkeletonForm />
			) : (
				<Card>
					<Card.Body className="offset-lg-3 col-lg-6">
						{fatalError ? (
							<Panel align="center">
								<Panel.Title>{fatalError}</Panel.Title>
							</Panel>
						) : (
							<>
								{!havePrevSign ? (
									<Panel
										align="center"
										variant="info"
										onClick={onSendNewSignature}
									>
										<Panel.Text>
											{`Você ainda não tem uma Assinatura Eletrônica.
											Clique aqui para receber uma por e-mail`}
										</Panel.Text>
									</Panel>
								) : (
									<>
										{mailSent ? (
											<Panel align="center" variant="success">
												<Panel.Text>
													<AlertTitle>Atenção</AlertTitle>
													<div>
														{`Uma Assinatura Eletrônica foi enviada para o seu e-mail.
														Se preferir alterá-la, utilize o formulário abaixo.`}
													</div>
												</Panel.Text>
											</Panel>
										) : (
											<Panel align="center">
												<Panel.Text>
													Se você esqueceu sua Assinatura Eletrônica, clique no
													botão abaixo para receber uma nova.
												</Panel.Text>
												<Button
													variant="outline-primary"
													onClick={onSendNewSignature}
												>
													Receber Nova Assinatura Eletrônica
												</Button>
											</Panel>
										)}

										<Formik<ElectronicSignEntry>
											onSubmit={handleSubmit}
											initialValues={defaultFormValue}
											validationSchema={validationSchema}
										>
											{(form) => (
												<FormikForm>
													<FormTitle>Alterar Assinatura Eletrônica</FormTitle>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>
																Senha de Acesso ao Escritório
															</Form.Label>
															<Input
																name="password"
																type={showFields.password ? 'text' : 'password'}
																placeholder="Senha"
																prepend={
																	<InputGroup.Text>
																		<FAIcon fixedWidth icon={faKey} />
																	</InputGroup.Text>
																}
																append={
																	<Button
																		variant="outline-primary"
																		onClick={() =>
																			setShowFields({
																				...showFields,
																				password: !showFields.password,
																			})
																		}
																	>
																		<FAIcon
																			icon={
																				showFields.password ? faEye : faEyeSlash
																			}
																		/>
																	</Button>
																}
															/>
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>
																Assinatura Eletrônica Atual
															</Form.Label>
															<Input
																name="signature"
																type={
																	showFields.signature ? 'text' : 'password'
																}
																placeholder="Senha"
																prepend={
																	<InputGroup.Text>
																		<FAIcon fixedWidth icon={faKey} />
																	</InputGroup.Text>
																}
																append={
																	<Button
																		variant="outline-primary"
																		onClick={() =>
																			setShowFields({
																				...showFields,
																				signature: !showFields.signature,
																			})
																		}
																	>
																		<FAIcon
																			icon={
																				showFields.signature
																					? faEye
																					: faEyeSlash
																			}
																		/>
																	</Button>
																}
															/>
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>Força da Nova Senha:</Form.Label>
															<ScoreBar score={newPasswordStrength} />
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>
																Nova Assinatura Eletrônica
															</Form.Label>
															<Input
																name="newSignature"
																type={
																	showFields.newSignature ? 'text' : 'password'
																}
																placeholder="Senha"
																onChange={onChangeNewSignature}
																prepend={
																	<InputGroup.Text>
																		<FAIcon fixedWidth icon={faKey} />
																	</InputGroup.Text>
																}
																append={
																	<Button
																		variant="outline-primary"
																		onClick={() =>
																			setShowFields({
																				...showFields,
																				newSignature: !showFields.newSignature,
																			})
																		}
																	>
																		<FAIcon
																			icon={
																				showFields.newSignature
																					? faEye
																					: faEyeSlash
																			}
																		/>
																	</Button>
																}
															/>
														</Form.Group>
													</Form.Row>
													<Form.Row>
														<Form.Group as={Col}>
															<Form.Label>
																Confirme a Nova Assinatura Eletrônica
															</Form.Label>
															<Input
																name="confirmNewSignature"
																type={
																	showFields.confirmNewSignature
																		? 'text'
																		: 'password'
																}
																placeholder="Senha"
																prepend={
																	<InputGroup.Text>
																		<FAIcon fixedWidth icon={faKey} />
																	</InputGroup.Text>
																}
																append={
																	<Button
																		variant="outline-primary"
																		onClick={() =>
																			setShowFields({
																				...showFields,
																				// eslint-disable-next-line max-len
																				confirmNewSignature: !showFields.confirmNewSignature,
																			})
																		}
																	>
																		<FAIcon
																			icon={
																				showFields.confirmNewSignature
																					? faEye
																					: faEyeSlash
																			}
																		/>
																	</Button>
																}
															/>
														</Form.Group>
													</Form.Row>
													<Separator />
													<ButtonsContainer>
														<FormSubmitButton
															isSubmitting={form.isSubmitting}
														/>
													</ButtonsContainer>
												</FormikForm>
											)}
										</Formik>
									</>
								)}
							</>
						)}
					</Card.Body>
				</Card>
			)}
		</Container>
	);
};

export default ElectronicSign;
