export enum PersonType {
	Admin = 1,
	Executive = 2,
	Distributor = 3,
	DistributorUser = 6,
	ExclusiveDistributor = 7,
}

export enum PersonStatus {
	Registered = 1, // pending
	Validated = 2,
	Active = 3, // Activate
	Blocked = 4,
	Canceled = 5,
}

export enum PersonStatusActiveInative {
	Active = 'Ativo',
	Blocked = 'Inativo',
}

export enum PersonPermissionSlug {
	AccessAdmin = 'access-adm',
	AccessExecutive = 'access-executive',
	AccessDistributor = 'access-distrib',
	ListUser = 'list-user',
	EditUser = 'edit-user',
	EditSponsor = 'edit-sponsor',
	ListAcl = 'list-acl',
	EditAcl = 'edit-acl',
	DeleteAcl = 'delete-acl',
	ListLog = 'list-log',
	ListExecutive = 'list-executive',
	EditExecutive = 'edit-executive',
	ListFollowUp = 'list-followup',
	EditFollowUp = 'edit-followup',
	ListProduct = 'list-product',
	EditProduct = 'edit-product',
	ListCategory = 'list-category',
	EditCategory = 'edit-category',
	ListShipment = 'list-shipment',
	EditShipment = 'edit-shipment',
	ListPayment = 'list-payment',
	EditPayment = 'edit-payment',
	ListCareer = 'list-career',
	EditCareer = 'edit-career',
	ListSubscription = 'list-subscription',
	EditSubscription = 'edit-subscription',
	ListStatement = 'list-statement',
	InsertStatement = 'insert-statement',
	ListManualBonus = 'list-manualBonus',
	ListPayout = 'list-payout',
	InsertPayout = 'insert-payout',
	EditPayout = 'edit-payout',
	ListOrder = 'list-order',
	InsertOrder = 'insert-order',
	EditOrder = 'edit-order',
	ReactivateOrder = 'reativate-order',
	CancelOrder = 'cancel-order',
	OrderPay = 'order-pay',
	OrderSendToExpedition = 'order-sendExpedition',
	OrderSeparateIni = 'order-separateIni',
	OrderSeparateEnd = 'order-separateEnd',
	OrderDelivered = 'order-delivered',
	OrderCancelAll = 'order-cancelAll',
	OrderCancelPA = 'order-cancelPA',
	OrderInvoice = 'order-invoice',
	OrderSend = 'order-send',
	ViewNetworkSale = 'view-networkSale',
	ViewNetworkScore = 'view-networkScore',
	ListCarrier = 'list-carrier',
	EditCarrier = 'edit-carrier',
	ListInvoice = 'list-invoice',
	EditInvoice = 'edit-invoice',
	ListTaxConf = 'list-taxConf',
	EditTaxConf = 'edit-taxConf',
	ListTaxProduct = 'list-taxProduct',
	EditTaxProduct = 'edit-taxProduct',
	ListSchedule = 'list-schedule',
	EditSchedule = 'edit-schedule',
	ListCompany = 'list-company',
	EditCompany = 'edit-company',
	ViewDesk = 'view-desk',
	ViewCashier = 'view-cashier',
	ViewCashierAll = 'view-cashierAll',
	ListSale = 'list-sale',
	EditSale = 'edit-sale',
	ListStoreStock = 'list-productStock',
	EditStoreStock = 'edit-productStock',
	ListSupport = 'list-support',
	EditSupport = 'edit-support',
	TicketCreate = 'ticket-ti-create',
	TicketStatusChange = 'ticket-ti-edit',
	TicketMetrics = 'ticket-ti-metrics',
	ClubShow = 'club-show',
	MMNPLView = 'mmn-pl-view',
	MMNPLCreate = 'mmn-pl-create',
	ListNews = 'list-news',
	EditNews = 'edit-news',
	ListNotification = 'list-notification',
	EditNotification = 'edit-notification',
	ListCampaign = 'list-campaign',
	EditCampaign = 'edit-campaign',
	ListExpedition = 'expedition-list',
	EditExpedition = 'expedition-edit',
}
