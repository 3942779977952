/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { Col, Row, Spinner } from 'react-bootstrap';
import { ArrayQueryKey, useInfiniteQuery } from 'react-query';

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import Empty from 'components/Empty';
import { useToast } from 'hooks';
import campaignService from 'services/campaign.service';
import { HttpGetListResult, Campaign } from 'types';
import Formatter from 'utils/Formatter';
import { timeOptions } from 'utils/Formatter/Date';

import { PageHeader } from 'packages/admin/components';
import { ScrollReplaceContainer } from 'packages/admin/pages/executive/executives/executiveLastRegister/styles';
import { SimpleList } from 'packages/escritorio/components';

import CampaignSkeleton from './campaigns/CampaignSkeleton';

type ElementScrollProps = {
	scrollHeight: number;
	scrollLeft: number;
	scrollTop: number;
	scrollWidth: number;
	clientHeight: number;
	clientWidth: number;
	clientLeft: number;
	clientTop: number;
	offsetHeight: number;
	offsetLeft: number;
	offsetTop: number;
	offsetWidth: number;
};

const fetchCampaigns = async (_: string, paginate: number, page = 1) =>
	campaignService.getMyCampaigns({
		pagination: { page, paginate, sort: '-initialDate' },
	});

const dateFormatter = new Formatter.Date();
const hourFormatter = new Formatter.Date('pt-BR', timeOptions);

const Campaigns: React.FC = () => {
	const [toast] = useToast();
	const [perPage] = useState<number>(10);
	const {
		data: searchData,
		isFetchingMore,
		fetchMore,
		isLoading,
		isError,
		canFetchMore,
	} = useInfiniteQuery<HttpGetListResult<Campaign>, ArrayQueryKey>(
		['utils_campaigns', perPage],
		fetchCampaigns,
		{
			getFetchMore: ({ meta }) =>
				meta?.last_page === meta?.current_page
					? false
					: (meta?.current_page || 0) + 1,
			onError: (error) => {
				// eslint-disable-next-line no-console
				console.log({ error });
				const errorMsg =
					'Erro interno: Ocorreu um erro ao obter a listagem de campanhas.';
				toast(errorMsg, { type: 'error' });
			},
		}
	);

	const allItemsLength: number =
		searchData?.map((r) => r.data).flat().length || 0;

	const handleScrollBottom = (e: React.ChangeEvent<ElementScrollProps>) => {
		if (!canFetchMore || isFetchingMore) return;

		const bottomPosition = Math.round(
			e.target.scrollHeight - e.target.scrollTop
		);

		const bottom =
			bottomPosition === e.target.clientHeight ||
			bottomPosition === e.target.clientHeight + 1;

		if (bottom) {
			fetchMore();
		}
	};

	return (
		<ScrollReplaceContainer fluid onScroll={handleScrollBottom}>
			<PageHeader alignItems="center">
				<PageHeader.Title>Campanhas</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils' },
						{ label: 'Campanhas', path: '/app/utils/campaigns', active: true },
					]}
				/>
			</PageHeader>
			{isLoading ? (
				<CampaignSkeleton />
			) : (
				<>
					{isError ? (
						<Empty
							description="Não foi possível carregar a lista de campanhas"
							image={<FAIcon icon={faTimesCircle} className="text-danger" />}
						/>
					) : allItemsLength === 0 ? (
						<Empty
							description="Nenhuma campanha encontrada"
							image={<FAIcon icon="check-circle" className="text-info" />}
						/>
					) : (
						searchData?.map(({ data, meta }) => (
							<React.Fragment key={meta?.current_page ?? 0}>
								{data.map((camp) => (
									<SimpleList key={camp.idCampaign ?? 0}>
										<SimpleList.Header withSeparator>
											<SimpleList.Column>
												<SimpleList.Title>{camp.name}</SimpleList.Title>
												<SimpleList.Info>
													{`Termina em ${dateFormatter.format(
														camp.endDate as string
													)} às ${hourFormatter.format(
														camp.endDate as string
													)}`}
												</SimpleList.Info>
											</SimpleList.Column>
											<SimpleList.Column>
												<strong
													className={
														camp.status === 'Ativa'
															? 'text-success'
															: 'text-danger'
													}
												>
													{camp.status}
												</strong>
											</SimpleList.Column>
										</SimpleList.Header>
										<SimpleList.Item>
											{!camp.coupon || camp.coupon.length === 0 ? (
												<p>Você não tem cupons para esta campanha</p>
											) : (
												<div>
													<p>Cupons:</p>
													<p>
														{camp.coupon.map((item, index) => (
															<span key={item.idCoupon}>
																{item.number +
																	(index < (camp.coupon?.length ?? 0) - 1
																		? ', '
																		: '')}
															</span>
														))}
													</p>
												</div>
											)}
										</SimpleList.Item>
									</SimpleList>
								))}
								{isFetchingMore && (
									<Row className="justify-content-center mb-3">
										<Col xs="auto">
											<Spinner animation="border" role="status" />
										</Col>
									</Row>
								)}
							</React.Fragment>
						))
					)}
				</>
			)}
		</ScrollReplaceContainer>
	);
};

export default Campaigns;
