import styled from 'styled-components';

export const CarouselStyle = styled.div`
	position: relative;
	margin: 20px 0;

	h3 {
		margin-bottom: 20px;
	}

	.btn-arrow {
		position: absolute;
		top: 50%;
		height: 35px;
		z-index: 1;

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}

	.rec-carousel-wrapper {
		height: 520px;

		@media (min-width: 576px) and (max-width: 767px) {
			height: 680px;
		}

		@media only screen and (max-width: 575px) {
			max-height: 780px;
		}
	}
`;
