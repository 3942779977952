import React from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import { Card, Skeleton } from 'components';

const Label = styled.div`
	height: 1.5rem;
`;

const CommissionFormSkeleton: React.FC = () => (
	<Card>
		<Card.Content>
			<Row>
				<Col sm={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<Form.Row>
						<Form.Group as={Col}>
							<Label />
							<Skeleton height="1.5rem" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Label />
							<Skeleton width="75%" height="2rem" />
							<Skeleton width="75%" height="2rem" />
							<Skeleton width="75%" height="2rem" className="mr-2" />
							<Skeleton width="23%" height="2rem" />
						</Form.Group>
					</Form.Row>
				</Col>
			</Row>
		</Card.Content>
		<Card.Content className="border-top">
			<Row>
				<Col sm={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<div className="text-right">
						<Skeleton width="100px" height="2rem" className="mr-2" />
						<Skeleton width="100px" height="2rem" />
					</div>
				</Col>
			</Row>
		</Card.Content>
	</Card>
);

export default CommissionFormSkeleton;
