import { HttpPostResult, ID, PersonJoinRegister, SessionPerson } from 'types';

import Crud from './crud.service';

/**
 * Service de comunicação e autenticação com backend
 */

const login = (
	credentials: { email: string; password: string; remember: boolean },
	route = 'auth/login'
): Promise<HttpPostResult<{ token: string }>> =>
	Crud.create(route, credentials);

const register = (
	data: PersonJoinRegister,
	route = 'auth/register'
): Promise<HttpPostResult<{ id?: ID; idUser: ID }>> => Crud.create(route, data);

const logout = (route = 'auth/logout'): Promise<HttpPostResult<{}>> =>
	Crud.delete<{}>(route, 0);

const forgotPassword = (
	email: string,
	route = 'auth/forgotPassword'
): Promise<HttpPostResult<{}>> => Crud.update(route, 0, { email });

const resetPassword = (
	data: { email: string; password: string; newPassword: string },
	route = 'auth/resetPassword'
): Promise<HttpPostResult<{}>> => Crud.update(route, 0, data);

const refresh = (
	route = 'auth/refresh'
): Promise<HttpPostResult<{ token: string }>> => Crud.create(route, 0);

const getLogged = (): Promise<SessionPerson> =>
	Crud.getOne<SessionPerson>('auth/logged', 0);

export default {
	login,
	register,
	logout,
	forgotPassword,
	resetPassword,
	refresh,
	getLogged,
};
