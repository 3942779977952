import React from 'react';

import {
	faFileAlt,
	faNewspaper,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';

import { PersonStatus, PersonType, RouteDef } from 'types';
import personStatusValidation from 'utils/RouteValidator/personStatusValidation';
import personTypeValidation from 'utils/RouteValidator/personTypeValidation';
import { Not } from 'utils/RouteValidator/validationCombiners';

import List from 'packages/admin/pages/List';
import listConfigs from 'packages/escritorio/pages/list/list.routes';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const OrderRoutes: RouteDef = {
	path: '/app/order',
	menu: {
		name: 'Pedidos',
		icon: faFileAlt,
	},
	validation: [
		personStatusValidation(PersonStatus.Active),
		Not(personTypeValidation(PersonType.DistributorUser)),
	],
	children: [
		/*
			// TODO: Implementar rota /summary
			{
				path: '/summary',
				name: 'Resumo Mensal',
				icon: 'chart-line',
			},
			*/
		{
			path: '',
			exact: true,
			menu: {
				name: 'Novo Pedido',
				icon: faPlus,
			},
		},
		{
			path: '/history',
			render: getListSceneRenderFn(listConfigs.Orders),
			menu: {
				name: 'Meus Pedidos',
				icon: faNewspaper,
			},
		},
	],
};

export default OrderRoutes;
