import styled from 'styled-components';

import { Button } from 'components';

export const Container = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	width: 100%;
	overflow: hidden;
	flex: 0 0 auto;
`;

export const TabGroupButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
	border: 1px solid var(--gray-20);
	padding: 5px 10px;
	min-width: 15px;
	max-width: 30px;
`;

export const TabGroupContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflow: hidden;
	flex: 1 1 auto;
	justify-content: center;
	box-shadow: inset 0px -1px 2px var(--gray-30);
`;
