import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import { FormCheckProps } from 'react-bootstrap/FormCheck';

export type CheckInputProps = FormCheckProps & {
	name?: string;
	defaultChecked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value?: unknown;
	checked?: boolean;
};

let checkIdNum = 0;

const CheckInput: React.FC<CheckInputProps> = (props) => {
	const [checkId, setCheckId] = useState<number>(0);

	useEffect(() => {
		// eslint-disable-next-line no-plusplus
		setCheckId(++checkIdNum);
	}, []);

	return (
		<Form.Check
			{...props}
			id={`${props.type}${checkId}-${props.id || props.name}`}
			custom
		/>
	);
};

// Componente base,
// Não adiciona whyDidYouRender e displayName pois
// é estendido pelo Checkbox e Radio

export default CheckInput;
