/* eslint-disable react/jsx-curly-newline */
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Formik,
	Form as FormikForm,
	FieldArray,
	ArrayHelpers,
	FormikValues,
	FormikProps,
} from 'formik';
import {
	Col,
	Button,
	Form,
	Row,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import { faStream } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { Input, List, Card } from 'components';
import {
	Input as FormikInput,
	Switch,
	AsyncSelect,
	FormikEffect,
} from 'components/Formik';
import { AddActions } from 'components/list/styles';
import { useToast } from 'hooks';
import ecommerceService from 'services/ecommerce.service';
import expeditionService from 'services/expedition.service';
import personService from 'services/person.service';
import StoreService from 'services/store.service';
import { MenuStore, ExpeditionStore, PersonStore } from 'types';
import Formatter from 'utils/Formatter';
import { numberFormat } from 'utils/Formatter/Number';
import { REGEX_CLEAR_NUMERIC } from 'utils/Formatter/Regex';
import Sort from 'utils/Sorters';

import {
	ProductSearchList,
	FormSubmitButton,
	FormCancelButton,
} from 'packages/admin/components';
import FormGroupTitle from 'packages/admin/components/FormGroupTitle';
import PageLayoutFormWrapper from 'packages/admin/components/PageLayoutFormWrapper';

import SkeletonForm from './store/FormSkeleton';
import { StoreFormType } from './store/types';

export const defaultFormValue: StoreFormType = {
	idStore: null,
	name: '',
	type: null,
	company: null,
	lang: null,
	isActive: true,
	personType: [],
	menu: [],
	expedition: [],
};

export const formSchema = Yup.object<StoreFormType>().shape({
	idStore: Yup.number().nullable().notRequired(),
	company: Yup.object().nullable().required('Empresa obrigatória'),
	expedition: Yup.array(),
	lang: Yup.object().nullable().required('Idioma obrigatório'),
	menu: Yup.array(),
	name: Yup.string().required('Campo obrigatório'),
	personType: Yup.array(),
	type: Yup.object().nullable().required('Tipo obrigatório'),
});

const decimalFormatter = new Formatter.Number('pt-br', numberFormat.decimal(4));

const StoreEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();
	const [menuSelected, setMenuSelected] = useState<MenuStore | null>(null);
	const [
		expeditionSelected,
		setExpeditionSelected,
	] = useState<ExpeditionStore | null>(null);
	const [personSelected, setPersonSelected] = useState<PersonStore | null>(
		null
	);
	const [personDiscount, setPersonDiscount] = useState<string>('');

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			if (isCreating) {
				return { store: { ...defaultFormValue } };
			}

			const store = isCreating
				? defaultFormValue
				: await StoreService.getStore(Number(id));

			return { store };
		},
		[]
	);

	// Handlers

	const handleSubmit = useCallback(
		async (id: string, values: FormikValues, { setSubmitting }) => {
			const submit = { ...values } as StoreFormType;
			setSubmitting(true);

			try {
				await StoreService.saveStore(submit, Number(id));
				toast(`Loja ${id ? 'alterada' : 'criada'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/stores/stores');
			} catch (e) {
				toast(`Não foi possível editar a loja. Cód: ${e.code}`, {
					type: 'error',
				});
			}

			setSubmitting(false);
		},
		[toast, history]
	);

	const handleAddMenu = (
		menuStore: MenuStore[],
		arrayHelper: ArrayHelpers
	) => () => {
		const alreadyAdd = menuStore.some(
			(menu) => menu?.idCategory === Number(menuSelected?.idCategory)
		);
		if (!alreadyAdd && menuSelected) {
			const nextSortId =
				([...menuStore].sort(Sort.Numerically('sort')).pop()?.sort || 0) + 1;

			const newMenu: MenuStore = { ...menuSelected, sort: nextSortId };

			arrayHelper.push(newMenu);
		}
		setMenuSelected(null);
	};

	const handleAddExpedition = (
		expeditionStore: ExpeditionStore[],
		arrayHelper: ArrayHelpers
	) => () => {
		const alreadyAdd = expeditionStore.some(
			(expedition) =>
				expedition?.idExpedition === Number(expeditionSelected?.idExpedition)
		);
		if (!alreadyAdd && expeditionSelected) {
			arrayHelper.push(expeditionSelected);
		}
		setExpeditionSelected(null);
	};

	const handleAddPersonType = (
		personStore: PersonStore[],
		arrayHelper: ArrayHelpers
	) => () => {
		const alreadyAdd = personStore.some(
			(person) => person.idType === Number(personSelected?.idType)
		);
		if (!alreadyAdd && personSelected) {
			arrayHelper.push({
				idType: Number(personSelected.idType),
				name: personSelected.name,
				discount:
					Number(personDiscount.replace(REGEX_CLEAR_NUMERIC, '')) / 10000,
			});
		}
		setPersonDiscount('');
		setPersonSelected(null);
	};

	return (
		<PageLayoutFormWrapper
			title="Loja"
			breadcrumb={[{ label: 'Lojas', path: '/admin/stores/stores' }]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.store}
					validationSchema={formSchema}
				>
					{(form: FormikProps<StoreFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Header separator justifyContent="space-between">
									{/* TODO: inserir botão Ver Loja */}
									{/* <Button variant="link" size="sm">
                    Ver Loja
                  </Button> */}
									<div />

									<Form.Group className="mb-0">
										<Switch name="isActive" defaultChecked />
										<Form.Label className="text-muted pl-1">Ativo</Form.Label>
									</Form.Group>
								</Card.Header>

								<Card.Content>
									<Form.Row>
										<Form.Group as={Col} md={7}>
											<Form.Label>Nome:</Form.Label>
											<FormikInput name="name" />
										</Form.Group>
										<Form.Group as={Col} md={5}>
											<Form.Label>Tipo:</Form.Label>
											<AsyncSelect
												name="type"
												fetchMethod={ecommerceService.getTypes}
												optionValue="idType"
											/>
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col} md={7}>
											<Form.Label>Empresa:</Form.Label>
											<AsyncSelect
												name="company"
												fetchMethod={ecommerceService.getCompanies}
												optionValue="idPerson"
											/>
										</Form.Group>

										<Form.Group as={Col} md={5}>
											<Form.Label>Idioma:</Form.Label>
											<AsyncSelect
												name="lang"
												fetchMethod={ecommerceService.getLangs}
												optionValue="idLang"
											/>
										</Form.Group>
									</Form.Row>
								</Card.Content>

								<Form.Row>
									<Col md={6} lg={8}>
										<Card.Content>
											<FormGroupTitle>Menu</FormGroupTitle>

											<FieldArray name="menu">
												{(arrayHelper: ArrayHelpers) => (
													<>
														<AddActions>
															<Row noGutters className="w-100">
																<Col>
																	<AsyncSelect
																		name="menu_select"
																		fetchMethod={ecommerceService.getMenus}
																		optionValue="idCategory"
																		optionLabel="shortName"
																		onChange={(option: MenuStore) =>
																			setMenuSelected(option)
																		}
																		value={menuSelected}
																	/>
																</Col>
																<Col
																	sm="auto"
																	className="d-flex flex-column-reverse"
																>
																	<Button
																		type="button"
																		variant="outline-info"
																		className="ml-2"
																		onClick={handleAddMenu(
																			form.values.menu,
																			arrayHelper
																		)}
																		disabled={!menuSelected}
																	>
																		<FAIcon icon="plus" className="mr-2" />{' '}
																		Adicionar
																	</Button>
																</Col>
															</Row>
														</AddActions>

														<List
															data="menu"
															emptyImage={faStream}
															emptyDescription="Não há menu adicionado"
															className="mb-3"
															onChangeItems={(options) => {
																form.setFieldValue('menu', options);
															}}
															onRemoveItem={(_item, index) =>
																arrayHelper.remove(index)
															}
															dragInDrop
														>
															{(item) => item.shortName}
														</List>
													</>
												)}
											</FieldArray>

											<hr />

											<FormGroupTitle>Expedição</FormGroupTitle>

											<FieldArray name="expedition">
												{(arrayHelper: ArrayHelpers) => (
													<>
														<AddActions>
															<Row noGutters className="w-100">
																<Col>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id="people_select_info">
																				Quais filiais em que o cliente poderá
																				retirar o pedido
																			</Tooltip>
																		}
																	>
																		<Form.Label>Filial</Form.Label>
																	</OverlayTrigger>
																	<AsyncSelect
																		name="expeditions_select"
																		fetchMethod={
																			expeditionService.getExpeditions
																		}
																		optionValue="id"
																		onChange={(option: ExpeditionStore) =>
																			setExpeditionSelected(option)
																		}
																		value={expeditionSelected}
																	/>
																</Col>
																<Col
																	sm="auto"
																	className="d-flex flex-column-reverse"
																>
																	<Button
																		type="button"
																		variant="outline-info"
																		className="ml-2"
																		onClick={handleAddExpedition(
																			form.values.expedition,
																			arrayHelper
																		)}
																		disabled={!expeditionSelected}
																	>
																		<FAIcon icon="plus" className="mr-2" />{' '}
																		Adicionar
																	</Button>
																</Col>
															</Row>
														</AddActions>

														<List
															data="expedition"
															emptyDescription="Não há expedição adicionado"
															className="mb-3"
															onRemoveItem={(item, index) =>
																arrayHelper.remove(index)
															}
														>
															{(item) => item.name}
														</List>
													</>
												)}
											</FieldArray>

											<hr />

											<FormGroupTitle>Clientes</FormGroupTitle>

											<FieldArray name="personType">
												{(arrayHelper: ArrayHelpers) => (
													<>
														<AddActions>
															<Row noGutters className="w-100">
																<Col>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id="people_select_info">
																				Tipos de clientes que podem acessar a
																				loja
																			</Tooltip>
																		}
																	>
																		<Form.Label>Tipo</Form.Label>
																	</OverlayTrigger>
																	<AsyncSelect
																		name="people_select"
																		className="mr-1"
																		fetchMethod={personService.getTypes}
																		optionValue="idType"
																		onChange={(option: PersonStore) =>
																			setPersonSelected(option)
																		}
																		value={personSelected}
																	/>
																</Col>
																<Col sm="4" md="2">
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id="people_discount_info">
																				Porcentagem do valor do produto que será
																				descontada do cliente
																			</Tooltip>
																		}
																	>
																		<Form.Label className="no-wrap">
																			% descontar
																		</Form.Label>
																	</OverlayTrigger>
																	<Input
																		name="people_discount"
																		formatter={decimalFormatter}
																		placeholder="Desconto"
																		value={personDiscount}
																		onChange={setPersonDiscount}
																		outputFormatted
																	/>
																</Col>
																<Col
																	sm="auto"
																	className="d-flex flex-column-reverse"
																>
																	<Button
																		type="button"
																		variant="outline-info"
																		onClick={handleAddPersonType(
																			form.values.personType,
																			arrayHelper
																		)}
																		disabled={!personSelected}
																	>
																		<FAIcon icon="plus" className="mr-2" />{' '}
																		Adicionar
																	</Button>
																</Col>
															</Row>
														</AddActions>

														<List
															data="personType"
															emptyDescription="Não há clientes adicionados"
															className="mb-3"
															// loading={loading}
															onRemoveItem={(item, index) =>
																arrayHelper.remove(index)
															}
														>
															{(item) => (
																<Row className="w-100">
																	<Col>
																		<span>{item.name}</span>
																	</Col>
																	<Col lg="auto" md="auto" sm="auto">
																		<span>{item.discount}%</span>
																	</Col>
																</Row>
															)}
														</List>
													</>
												)}
											</FieldArray>
										</Card.Content>
									</Col>
									<Col md={6} lg={4} className="d-flex border-left w-100">
										{/* TODO: Ativar lista de produtos */}
										<Card.Content width={100} className="d-none">
											<ProductSearchList />
										</Card.Content>
									</Col>
								</Form.Row>

								<br />

								<Card.Content className="text-right border-top">
									<FormCancelButton
										isSubmitting={form.isSubmitting}
										to="/admin/stores/stores"
									/>
									<FormSubmitButton isSubmitting={form.isSubmitting} />
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default StoreEdit;
