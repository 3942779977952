import React, { useCallback, useRef, useState } from 'react';

import { FastField, useFormikContext } from 'formik';
import { Button, Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Checkbox, Empty } from 'components';
import { Select } from 'components/Formik';
import useThrottle from 'hooks/useThrottle';
import {
	Expedition,
	ExpeditionStation,
	ExpeditionStationSelect,
	ProductSelect,
} from 'types';

import { FormGroupTitle } from 'packages/admin/components';

import {
	Container,
	ProductList,
	ProductListItem,
} from './productsListAddStation/styles';

export interface ProductsListAddStationProps {
	data: string;
	style?: React.CSSProperties;
	className?: string;
}

const ProductsListAddStation: React.FC<ProductsListAddStationProps> = (
	props
) => {
	const { data, ...rest } = props;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [canAddProduct, setCanAddProduct] = useState<boolean>(false);
	const [checkedAllVisible, setCheckedAllVisible] = useState<boolean>(false);
	const formikCtx = useFormikContext<Expedition>();
	const { value: products } = formikCtx.getFieldMeta<ProductSelect[]>(data);
	const inputRef = useRef<HTMLInputElement>(null);

	const clearAllChecked = useCallback(() => {
		const { setFieldValue } = formikCtx;

		setFieldValue('products', [
			...products.map((p) => ({ ...p, checked: false })),
		]);
		setCheckedAllVisible(false);
		setCanAddProduct(false);
	}, [formikCtx, products]);

	const updateProductList = useCallback(
		(prodsChecked) => {
			formikCtx.setFieldValue(
				'products',
				products.filter((item) => {
					// eslint-disable-next-line no-bitwise
					return !~prodsChecked.indexOf(item);
				})
			);
		},
		[formikCtx, products]
	);

	const setCanAddProductsInStation = useCallback(
		(productsToChecked?: ProductSelect[]) => {
			const {
				values: { products: prods },
				setFieldValue,
			} = formikCtx;

			if (!prods) return;

			if (productsToChecked) {
				setFieldValue('products', [
					...products.map((p) => {
						const result = productsToChecked.filter(
							(pc) => p.idProduct === pc.idProduct && pc.checked
						);
						return {
							...p,
							checked: result.length > 0 ? result[0].checked : false,
						};
					}),
				]);
			}

			const checkedProducts = productsToChecked || prods;

			const hasSomeChecked = checkedProducts.some((p) => p.checked);
			const allChecked = checkedProducts.every((p) => p.checked);
			if (checkedAllVisible !== allChecked) {
				setCheckedAllVisible(allChecked);
			}
			if (canAddProduct !== hasSomeChecked) {
				setCanAddProduct(hasSomeChecked);
			}
		},
		[canAddProduct, checkedAllVisible, formikCtx, products]
	);

	const searchProductList = useCallback(() => {
		const str = inputRef.current
			? inputRef.current.value.toLocaleLowerCase()
			: '';

		products.filter(
			// eslint-disable-next-line no-return-assign
			(p) =>
				// eslint-disable-next-line no-param-reassign
				(p.visible =
					`${p.idProduct} - ${p.name}`.toLocaleLowerCase().indexOf(str) >= 0)
		);

		clearAllChecked();
		setTimeout(() => setIsLoading(false), 200);
	}, [clearAllChecked, products]);

	const onSearch = useThrottle(
		useCallback(() => {
			setIsLoading(true);
			searchProductList();
		}, [searchProductList]),
		1000
	);

	const addProductsInStation = useCallback(() => {
		const { values, setFieldValue } = formikCtx;

		const prodsChecked = products.filter((p) => p.checked);

		if (values.station_select && prodsChecked.length > 0) {
			const indexStation = values.station.findIndex(
				(sta: ExpeditionStation) => sta.name === values.station_select?.name
			);
			const prodsStation = [
				...values.station[indexStation].products,
				...prodsChecked,
			];

			setFieldValue(`station[${indexStation}].products`, prodsStation);
			setFieldValue('station_select', null);
			clearAllChecked();
			updateProductList(prodsChecked);
			setCanAddProduct(false);
		}
	}, [clearAllChecked, formikCtx, products, updateProductList]);

	const checkAll = useCallback(
		(event, prods) => {
			const { checked } = event.target;

			setCheckedAllVisible(checked);
			const prodsVisible = [...products.filter((p) => p.visible)];
			// eslint-disable-next-line no-param-reassign
			prods = prodsVisible.map((p: ProductSelect) => ({ ...p, checked }));
			setCanAddProductsInStation(prods);
		},
		[products, setCanAddProductsInStation]
	);

	const addProductCheck = useCallback(
		(event, prod) => {
			const { checked } = event.target;

			// eslint-disable-next-line no-param-reassign
			prod.checked = checked;
			setCanAddProductsInStation();
		},
		[setCanAddProductsInStation]
	);

	return (
		<Container {...rest}>
			<FormGroupTitle>Produtos</FormGroupTitle>

			{canAddProduct && (
				<Form.Group className="bg-info p-2 pb-3">
					<Form.Label className="text-light">Adicionar na Estação</Form.Label>
					<div className="d-flex align-items-center">
						<Select
							name="station_select"
							options={formikCtx.values.station.map(
								(station: ExpeditionStation): ExpeditionStationSelect => ({
									idStation: station.idStation,
									name: station.name,
								})
							)}
							optionValue="idStation"
							className="mr-2"
						/>
						<Button
							type="button"
							variant="outline-light"
							onClick={addProductsInStation}
							disabled={!formikCtx.values.station_select}
						>
							<FAIcon icon="plus" className="mr-2" /> Adicionar
						</Button>
					</div>
				</Form.Group>
			)}

			<Form.Group>
				<input
					ref={inputRef}
					placeholder="Pesquisar"
					className="form-control"
					onChange={onSearch}
				/>
			</Form.Group>

			{products.length > 0 && (
				<Form.Group>
					<FastField
						as={Checkbox}
						label="Selecionar todos"
						className="ml-3"
						onChange={(event: React.ChangeEvent) => checkAll(event, products)}
						checked={checkedAllVisible}
					/>
				</Form.Group>
			)}

			<ProductList>
				{isLoading ? (
					<Skeleton count={5} height="1.5rem" />
				) : (
					<>
						{products.length > 0 &&
							products.map(
								(prod) =>
									prod.visible && (
										<ProductListItem key={prod.idProduct}>
											<FastField
												as={Checkbox}
												label={`${prod.idProduct} - ${prod.name}`}
												onClick={(event: React.ChangeEvent) =>
													addProductCheck(event, prod)
												}
												checked={prod.checked}
											/>
										</ProductListItem>
									)
							)}
						{products.length === 0 && (
							<Empty description="Digite acima para pesquisar" />
						)}
					</>
				)}
			</ProductList>
		</Container>
	);
};

ProductsListAddStation.displayName = 'ProductsListAddStation';
ProductsListAddStation.whyDidYouRender = true;

export default ProductsListAddStation;
