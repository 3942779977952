import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { ScoreLineTree } from 'types';

import PanelTab from './panelTabGroup/PanelTab';
import * as S from './panelTabGroup/styles';

export type PanelTabGroupProps = {
	lines: ScoreLineTree[];
	scoreLimit: number;
	tabSelected: ScoreLineTree | null;
	onTabSelected: (user: ScoreLineTree) => void;
};

let tabWidth = 120;
let totalWidth = 0;
let itemsPerPage = 0;
let maxPages = 0;
const baseTabWidth = 120;

const PanelTabGroup: React.FC<PanelTabGroupProps> = ({
	lines,
	scoreLimit,
	tabSelected,
	onTabSelected,
}) => {
	const [linePaged, setLinePaged] = useState<ScoreLineTree[]>([]);
	const [actualPage, setActualPage] = useState<number>(0);
	const containerRef = useRef<HTMLDivElement>(null);

	const getDirectPage = useCallback(() => {
		const offset = actualPage * itemsPerPage;
		setLinePaged(lines.slice(offset, offset + itemsPerPage));
	}, [actualPage, lines]);

	const onDirectClickLeft = useCallback(() => {
		setActualPage(Math.max(0, actualPage - 1));
	}, [actualPage]);

	const onDirectClickRight = useCallback(() => {
		setActualPage(Math.min(actualPage + 1, maxPages));
	}, [actualPage]);

	const handleResize = useCallback(() => {
		const container = containerRef.current ? containerRef.current : null;
		totalWidth = container?.clientWidth || 0;
		itemsPerPage = Math.floor(totalWidth / baseTabWidth);

		tabWidth = totalWidth / itemsPerPage;
		maxPages = Math.ceil((tabWidth * lines.length) / totalWidth - 1);

		getDirectPage();
	}, [getDirectPage, lines.length]);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize, actualPage]);

	return (
		<S.Container>
			<S.TabGroupButton
				variant="light"
				onClick={onDirectClickLeft}
				disabled={actualPage === 0}
			>
				<FAIcon icon="chevron-left" />
			</S.TabGroupButton>
			<S.TabGroupContainer ref={containerRef}>
				{linePaged.map((user) => (
					<PanelTab
						key={user.idPerson}
						user={user}
						scoreLimit={scoreLimit}
						active={user.idPerson === tabSelected?.idPerson}
						width={tabWidth}
						onClickTab={(u) => onTabSelected(u)}
					/>
				))}
			</S.TabGroupContainer>
			<S.TabGroupButton
				variant="light"
				onClick={onDirectClickRight}
				disabled={actualPage === maxPages}
			>
				<FAIcon icon="chevron-right" />
			</S.TabGroupButton>
		</S.Container>
	);
};

export default PanelTabGroup;
