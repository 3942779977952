import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import {
	faFacebookF,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import './footer/styles.scss';

import useEcommerceBasePath from '../hooks/useEcommerceBasePath';
import useEcommerceContext from '../hooks/useEcommerceContext';

type FooterProps = {
	fluid?: boolean;
};

const Footer: FunctionComponent<FooterProps> = ({ fluid = false }) => {
	const url = useEcommerceBasePath();
	const {
		config: { dropdown },
	} = useEcommerceContext();
	const departments = dropdown.filter((drop) => !drop.idParent);

	return (
		<footer className="bg-dark ecommerce-footer py-4">
			<Container fluid={fluid}>
				<Row>
					<Col md={6} lg={3}>
						<p className="lead font-weight-bold">Departamentos</p>
						<ul className="list-unstyled">
							{departments.map((dep) => (
								<li key={dep.idCategory}>
									<Link
										to={`${url}/categoria/${dep.idCategory}`}
										className="text-capitalize"
									>
										{dep.name}
									</Link>
								</li>
							))}
						</ul>
					</Col>
					<Col md={6} lg={3}>
						<p className="lead font-weight-bold">Atendimento Amakha</p>
						<ul className="list-unstyled">
							<li>
								<Link to="/">Fale Conosco</Link>
							</li>
							<li>
								<Link to="/">Troca e Devolução</Link>
							</li>
							<li>
								<Link to="/">Dúvidas Frequentes</Link>
							</li>
							<li>
								<Link to="/">Nossas Lojas</Link>
							</li>
						</ul>
					</Col>
					<Col md={6} lg={3}>
						<p className="lead font-weight-bold">Amakha Paris</p>
						<ul className="list-unstyled">
							<li>Segunda à Sexta das 09:00 às 18:00</li>
							<li>
								<FAIcon
									fixedWidth
									className="text-primary"
									icon={['fal', 'headphones']}
								/>{' '}
								(11) 2388-8203
							</li>
							<li>
								<FAIcon
									fixedWidth
									className="text-primary"
									icon={['fal', 'envelope']}
								/>{' '}
								sac@amakhaparis.com.br
							</li>
							<li>
								<FAIcon
									fixedWidth
									className="text-primary"
									icon={['fal', 'at']}
								/>{' '}
								Rua do Sacramento, 522 <br /> Rudge Ramos, São Bernardo do Campo
								- SP
							</li>
						</ul>
					</Col>
					<Col md={6} lg={3}>
						<p className="lead font-weight-bold">Redes Sociais</p>
						<ul className="list-inline">
							<li className="list-inline-item">
								<a
									href="https://www.facebook.com/AmakhaCosmeticosOficial"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FAIcon icon={faFacebookF} />
								</a>
							</li>
							<li className="list-inline-item">
								<a
									href="https://www.instagram.com/amakhaoficial"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FAIcon icon={faInstagram} />
								</a>
							</li>
							<li className="list-inline-item">
								<a
									href="https://www.youtube.com/channel/UCQ3KYXyvSwiJfWsX9_KDelQ"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FAIcon icon={faYoutube} />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

Footer.displayName = 'Footer';
Footer.whyDidYouRender = true;

export default Footer;
