import {
	ID,
	HttpGetListParams,
	HttpGetListResult,
	Carrier,
	HttpPostResultAny,
	CarrierList,
} from 'types';

import Crud from './crud.service';

const getForList = (
	params: HttpGetListParams
): Promise<HttpGetListResult<CarrierList>> =>
	Crud.getList<CarrierList>('carrier', params);

const getForId = (id: ID): Promise<Carrier> =>
	Crud.getOne<Carrier>('carrier/{id}', id as number);

const patch = (
	id: ID,
	data: { idStatus: number }
): Promise<HttpPostResultAny> => Crud.patch('carrier/{id}', id, data);

const createOrUpdate = (
	id: ID | null,
	data: Carrier
): Promise<HttpPostResultAny> => {
	if (id) {
		return Crud.update('carrier/{id}', id as number, data);
	}
	return Crud.create('carrier', data);
};

export default {
	getForList,
	getForId,
	patch,
	createOrUpdate,
};
