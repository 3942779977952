import React, { useContext } from 'react';

import ModalContext from 'components/modal/ModalContext';

import { StyledModalHeader, CloseButton } from './modalHeader/styles';
import { ModalHeaderProps } from './types';

const ModalHeader: React.FC<ModalHeaderProps> = ({
	children,
	closeButton,
	...rest
}) => {
	const { handleOnClose } = useContext(ModalContext);

	return (
		<StyledModalHeader {...rest}>
			{children}
			{closeButton && <CloseButton onClick={handleOnClose}>×</CloseButton>}
		</StyledModalHeader>
	);
};

export default ModalHeader;
