import React from 'react';

import Container from 'react-bootstrap/Container';

import Header from './PageHeader';
import { BreadcrumbItem } from './pageHeader/Breadcrumb';

export type PageLayoutProps = {
	title: string | React.ReactNode;
	breadcrumb: BreadcrumbItem[];
};

const PageLayout: React.FC<PageLayoutProps> = ({
	title,
	breadcrumb,
	children,
}) => (
	<Container fluid>
		<Header>
			<Header.Title>{title}</Header.Title>
			<Header.Breadcrumb items={breadcrumb} />
		</Header>
		{children}
	</Container>
);

export default PageLayout;
