import { ModalForwardProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventOnCallback = (wrapper?: ModalForwardProps<any, any>) => void;

export enum ModalEvent {
	New,
	Show,
	Close,
	Exited,
	ClearAll, // Usado para limpar a lista de Modal a cada caso de teste
}

const eventMap = new Map<ModalEvent, EventOnCallback[]>();

const on = (event: ModalEvent, callback: EventOnCallback) => {
	const list = eventMap.get(event) || [];
	list.push(callback);
	eventMap.set(event, list);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emit = (event: ModalEvent, ref?: ModalForwardProps<any, any>) => {
	const callbacks = eventMap.get(event);
	if (!callbacks) {
		return;
	}
	for (const callback of callbacks) {
		callback(ref);
	}
};

export default {
	on,
	emit,
};
