import { useState, useCallback, useRef } from 'react';

const useHover = <T extends HTMLElement | Node>(
	allowChildren = false
): [(instance: T) => void, boolean] => {
	const [value, setValue] = useState<boolean>(false);
	const ref = useRef<T>();

	const handleMouseOverDoc = useCallback(
		(node) => {
			if (ref.current != null) {
				setValue(ref.current.contains(node.target));
			} else {
				setValue(false);
			}
		},
		[ref]
	);

	const handleMouseOver = useCallback(() => setValue(true), []);
	const handleMouseOut = useCallback(() => setValue(false), []);

	const callbackRef = useCallback(
		(node) => {
			if (allowChildren) {
				document.removeEventListener('mouseover', handleMouseOverDoc);
			} else if (ref.current) {
				ref.current.removeEventListener('mouseover', handleMouseOver);
				ref.current.removeEventListener('mouseout', handleMouseOut);
			}

			ref.current = node;

			if (allowChildren) {
				document.addEventListener('mouseover', handleMouseOverDoc);
			} else if (ref.current) {
				ref.current.addEventListener('mouseover', handleMouseOver);
				ref.current.addEventListener('mouseout', handleMouseOut);
			}
		},
		[allowChildren, handleMouseOverDoc, handleMouseOver, handleMouseOut]
	);

	return [callbackRef, value];
};

export default useHover;
