import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	InfoCard as StyledInfoCard,
	InfoCardIcon,
	InfoCardTitle,
	InfoCardSubtitle,
	InfoCardProps as StyledInfoCardProps,
} from './infoCard/styles';

export type InfoCardProps = StyledInfoCardProps & {
	icon?: IconProp;
	className?: string;
	style?: React.CSSProperties;
};

const defaultProps: InfoCardProps = {
	gradient: true,
	colored: false,
	color: 'gray-dark',
};

const InfoCard: React.FC<InfoCardProps> = ({ children, icon, ...rest }) => {
	return (
		<StyledInfoCard {...rest}>
			{icon && (
				<InfoCardIcon>
					<FontAwesomeIcon icon={icon} />
				</InfoCardIcon>
			)}
			{children}
		</StyledInfoCard>
	);
};

InfoCard.displayName = 'InfoCard';
InfoCard.defaultProps = defaultProps;
InfoCard.whyDidYouRender = true;

export default Object.assign(InfoCard, {
	Title: InfoCardTitle,
	Subtitle: InfoCardSubtitle,
});
