// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FlatArrayToTree = <T>(
	array: T[],
	key: keyof T,
	parent: keyof T,
	childArray: keyof T
): T[] => {
	const mappedDrop = new Map<string, T>();
	const dropTree: T[] = [];

	for (const drop of array) {
		mappedDrop.set(String(drop[key]), { ...drop, [childArray]: [] });
	}

	for (const id of mappedDrop.keys()) {
		const drop = mappedDrop.get(id)!;

		const item = drop[parent] != null && mappedDrop.get(String(drop[parent]));
		if (item) {
			((item[childArray] as unknown) as Array<T>)?.push({ ...drop });
		} else {
			dropTree.push(drop);
		}
	}
	return dropTree;
};

export default FlatArrayToTree;
