/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { Button, FormGroup } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { ArrayQueryKey, useInfiniteQuery } from 'react-query';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { VideoModal } from 'modals';

import { Input, LoadWhenVisible } from 'components';
import Card from 'components/Card';
import Empty from 'components/Empty';
import Grid from 'components/Grid';
import Image from 'components/Image';
import { useToast, useModal, useThrottle } from 'hooks';

import { PageHeader } from 'packages/admin/components';
import {
	YoutubeSearchResult,
	YoutubeVideo,
} from 'packages/escritorio/pages/utils/videos/types';

import SkeletonVideosGrid from './videos/SkeletonVideosGrid';

// TODO: Enviar para o arquivo de configuração?
const youtubeAccessToken = 'AIzaSyDlEZs4XqkO5jaiAxAXg5jANA5gbsxrIBw';
const youtubeChannelId = 'UCQ3KYXyvSwiJfWsX9_KDelQ';

const fetchVideos = async (
	_: string,
	perPage: number,
	searchQuery: string,
	pageToken?: string
): Promise<YoutubeSearchResult> =>
	axios
		.get<YoutubeSearchResult>('https://www.googleapis.com/youtube/v3/search', {
			params: {
				key: youtubeAccessToken,
				channelId: youtubeChannelId,
				maxResults: perPage,
				...(pageToken && { pageToken }),
				...(searchQuery && { q: searchQuery }),
				type: 'video',
				order: 'date',
				part: 'snippet',
			},
			withCredentials: false,
			headers: {
				'Accept-Language': 'pt-BR,pt;q=0.9',
				Accept: 'application/json, text/plain, */*',
			},
		})
		.then((response) => response.data);

const Videos: React.FC = () => {
	const open = useModal();
	const [toast] = useToast();
	const [perPage] = useState<number>(20);
	const [searchQueryInput, setSearchQueryInput] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const delayedSetSearchQuery = useThrottle(setSearchQuery, 500);
	const { data, isFetchingMore, fetchMore, isLoading } = useInfiniteQuery<
		YoutubeSearchResult,
		ArrayQueryKey
	>(['utils_videos', perPage, searchQuery], fetchVideos, {
		getFetchMore: (lastGroup) => lastGroup.nextPageToken ?? false,
		onError: (error) => {
			// eslint-disable-next-line no-console
			console.log({ error });
			const errorMsg =
				'Erro interno: Ocorreu um erro ao obter a listagem de vídeos.';
			toast(errorMsg, { type: 'error' });
		},
	});
	const allItemsLength: number = data?.map((r) => r.items).flat().length || 0;

	useEffect(() => {
		delayedSetSearchQuery(searchQueryInput);
	}, [searchQueryInput, delayedSetSearchQuery]);

	// Handlers
	const handleVideoClick = (video: YoutubeVideo): void => {
		open(VideoModal, {
			link: `https://www.youtube.com/embed/${video.id.videoId}`,
			title: video.snippet.title,
		});
	};

	return (
		<Container fluid>
			<PageHeader alignItems="center">
				<PageHeader.Title>Vídeos</PageHeader.Title>
				<FormGroup className="m-0 w-25">
					<Input
						name="searchQuery"
						type="text"
						placeholder="Pesquisar"
						value={searchQueryInput}
						onChange={(value) => setSearchQueryInput(value)}
						disabled={isLoading}
					/>
				</FormGroup>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils' },
						{ label: 'Vídeos', path: '/app/utils/videos', active: true },
					]}
				/>
			</PageHeader>
			{isLoading ? (
				<SkeletonVideosGrid />
			) : (
				<>
					{allItemsLength === 0 && searchQueryInput.trim().length > 0 ? (
						<Empty
							description="Nenhum vídeo encontrado. Tente usar palavras-chave diferentes."
							image={<FAIcon icon={faTimesCircle} className="text-info" />}
						/>
					) : allItemsLength === 0 ? (
						<Empty
							description="Não foi possível carregar a lista de vídeos"
							image={<FAIcon icon={faTimesCircle} className="text-danger" />}
						/>
					) : (
						<Grid minWidth={300}>
							{data?.map((searchResult, index) => {
								const isLastPage = data.length === index + 1;
								const thereAreMoreResults =
									searchResult.items.length === perPage &&
									allItemsLength < searchResult.pageInfo.totalResults;

								return (
									<React.Fragment key={searchResult.etag}>
										{searchResult.items.map((video) => (
											<Card key={video.etag}>
												<LoadWhenVisible>
													<Card.ImageWrapper
														onClick={() => handleVideoClick(video)}
													>
														<Card.CenteredIcon icon={faYoutube} />
														<Image
															src={video.snippet.thumbnails.medium.url}
															alt={video.snippet.title}
														/>
													</Card.ImageWrapper>
												</LoadWhenVisible>
												<Card.Header>
													<Card.Title>{video.snippet.title}</Card.Title>
												</Card.Header>
												<Card.Content clipText>
													{video.snippet.description}
												</Card.Content>
											</Card>
										))}
										{isLastPage && thereAreMoreResults && (
											<Grid.FullWidthContainer>
												<Button
													disabled={!!isFetchingMore}
													variant="primary"
													type="button"
													onClick={() => fetchMore()}
												>
													+ Mais Vídeos
												</Button>
											</Grid.FullWidthContainer>
										)}
									</React.Fragment>
								);
							})}
						</Grid>
					)}
				</>
			)}
		</Container>
	);
};

export default Videos;
