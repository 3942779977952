import React from 'react';

import { Button } from 'react-bootstrap';

import { faPaperclip, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { useModal } from 'hooks';
import { OrderForList, OrderStatusConsts } from 'types';
import { ButtonLinkComponentProps } from 'utils/Formatter/ButtonLink';

import AttachModal from './modal/AttachModal';

const AttachCreditOrder: React.FC<ButtonLinkComponentProps<OrderForList>> = ({
	row,
}) => {
	const open = useModal();

	if (!row.hasAttach && row.idStatus !== OrderStatusConsts.Open) return null;

	return (
		<Button
			variant="link"
			onClick={() => {
				open(
					AttachModal,
					{
						idOrder: row.idOrder,
						number: row.number,
						enableUpload: row.idStatus === OrderStatusConsts.Waiting,
						enableRemove: row.idStatus === OrderStatusConsts.Waiting,
					},
					(value?: unknown) => value
				);
			}}
		>
			{row.hasAttach && <FAIcon icon={faPaperclip} />}
			{!row.hasAttach && row.idStatus === OrderStatusConsts.Open && (
				<FAIcon icon={faUpload} />
			)}
		</Button>
	);
};

export default AttachCreditOrder;
