import React from 'react';

import CheckInput, { CheckInputProps } from './CheckInput';

export type CheckboxProps = CheckInputProps;

const Checkbox: React.FC<CheckboxProps> = (props) => (
	<CheckInput {...props} type="checkbox" />
);

Checkbox.displayName = 'Checkbox';
// Checkbox.whyDidYouRender = true;

export default React.memo(Checkbox);
