import styled from 'styled-components';

export const Title = styled.div`
	font-size: 1.2rem;
	font-weight: 300;
`;

export const Credit = styled.span`
	font-size: 1.5rem;
	font-weight: 400;
`;
