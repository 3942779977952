import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import LatestOrders from './orders/LatestOrders';
import MonthSummary from './orders/MonthSummary';
import OrderSearchList from './orders/OrderSearchList';
import OrderWidgets from './orders/OrderWidgets';
import { SidebarCollapse } from './orders/styles';

const OrdersPage: React.FC = () => {
	return (
		<Container fluid className="d-flex flex-column flex-grow-1">
			<Row className="mt-3 flex-grow-1">
				<Col xl={9} className="d-flex flex-column flex-grow-1">
					<OrderWidgets />
					<OrderSearchList className="mt-1" />
				</Col>
				<SidebarCollapse xl={3}>
					<MonthSummary />
					<LatestOrders className="mt-3 flex-grow-1" />
				</SidebarCollapse>
			</Row>
		</Container>
	);
};

export default OrdersPage;
