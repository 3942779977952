import RegexFormatter, { REGEX_CLEAR_NUMERIC } from './Regex';

class CEPFormatter extends RegexFormatter {
	constructor() {
		super(
			/^(\d{1,5})(\d{0,3})/g,
			(_: string, $1: string, $2: string) => {
				if ($2) {
					return `${$1}-${$2}`;
				}
				return $1;
			},
			{
				clear: REGEX_CLEAR_NUMERIC,
				maxLength: 8,
			}
		);
	}

	formatForSearch = (value: string) => value.trim().replace(/-/g, '');
}

export default CEPFormatter;
