import React from 'react';

import { Button } from 'react-bootstrap';

import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';

type ConfirmationModalProps = {
	title?: string | React.ReactElement;
	message: string | React.ReactElement;
	ButtonCancelText?: string | React.ReactElement;
	ButtonConfirmText?: string | React.ReactElement;
	style?: React.CSSProperties;
	className?: string;
};

const defaultProps = {
	title: 'Atenção',
	ButtonCancelText: 'Voltar',
	ButtonConfirmText: 'Confirmar',
};

const ConfirmationModal: React.FC<
	ModalForwardProps<ConfirmationModalProps, boolean>
> = ({
	title,
	message,
	ButtonCancelText,
	ButtonConfirmText,
	modalRef,
	...rest
}) => {
	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="m-0">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer align="end">
				<Button
					variant="outline-primary"
					onClick={() => modalRef.dismiss(false)}
				>
					{ButtonCancelText}
				</Button>
				<Button variant="primary" onClick={() => modalRef.dismiss(true)}>
					{ButtonConfirmText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
