/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { Spinner, Row, Col, Container } from 'react-bootstrap';
import { ArrayQueryKey, useInfiniteQuery } from 'react-query';

import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Empty, Timeline } from 'components';
import { useToast } from 'hooks';
import newsNotificationService from 'services/newsNotification.service';
import { NewsNotification, NewsNotificationAttach } from 'types';
import { HttpGetListResult } from 'types/api.types';
import { dateFormatter, hourMinFormatter } from 'utils/formatters';

import PageHeader from 'packages/admin/components/PageHeader';

import NotificationsSkeleton from './notifications/NotificationsSkeleton';
import {
	ScrollReplaceContainer,
	AnexosList,
	AnexosListItem,
} from './notifications/styles';

type ElementScrollProps = {
	scrollHeight: number;
	scrollLeft: number;
	scrollTop: number;
	scrollWidth: number;
	clientHeight: number;
	clientWidth: number;
	clientLeft: number;
	clientTop: number;
	offsetHeight: number;
	offsetLeft: number;
	offsetTop: number;
	offsetWidth: number;
};

const fetchNotifications = async (_: string, paginate: number, page = 1) =>
	newsNotificationService.getForPerson('notification', {
		pagination: { page, paginate },
	});

const Notifications: React.FC = () => {
	const [toast] = useToast();
	const [perPage] = useState<number>(10);
	const {
		data: searchData,
		isFetchingMore,
		fetchMore,
		isLoading,
		isError,
		canFetchMore,
	} = useInfiniteQuery<HttpGetListResult<NewsNotification>, ArrayQueryKey>(
		['utils_notifications', perPage],
		fetchNotifications,
		{
			getFetchMore: ({ meta }) =>
				meta?.last_page === meta?.current_page
					? false
					: (meta?.current_page || 0) + 1,
			onError: (error) => {
				// eslint-disable-next-line no-console
				console.log({ error });
				const errorMsg =
					'Erro interno: Ocorreu um erro ao obter a listagem de notificações.';
				toast(errorMsg, { type: 'error' });
			},
		}
	);

	const allItemsLength: number =
		searchData?.map((r) => r.data).flat().length || 0;

	const handleScrollBottom = (e: React.ChangeEvent<ElementScrollProps>) => {
		if (!canFetchMore || isFetchingMore) return;

		const bottomPosition = Math.round(
			e.target.scrollHeight - e.target.scrollTop
		);

		const bottom =
			bottomPosition === e.target.clientHeight ||
			bottomPosition === e.target.clientHeight + 1;

		if (bottom) {
			fetchMore();
		}
	};

	return (
		<ScrollReplaceContainer fluid onScroll={handleScrollBottom}>
			<PageHeader alignItems="center">
				<PageHeader.Title>Notificações</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Utilidades', path: '/app/utils' },
						{
							label: 'Notificações',
							path: '/app/utils/notifications',
							active: true,
						},
					]}
				/>
			</PageHeader>
			{isLoading ? (
				<NotificationsSkeleton />
			) : (
				<Container>
					{isError ? (
						<Empty
							description="Não foi possível carregar a lista de notificações"
							image={<FAIcon icon={faTimesCircle} className="text-danger" />}
						/>
					) : allItemsLength === 0 ? (
						<Empty
							description="Nenhuma notificação"
							image={<FAIcon icon="check-circle" className="text-info" />}
						/>
					) : (
						<Timeline>
							{searchData?.map(({ data, meta }) => (
								<React.Fragment key={meta?.current_page ?? 0}>
									{data.map((notification) => (
										<Timeline.Item
											key={notification.idNews ?? 0}
											color={
												notification.level
													? `#${notification.level.color}`
													: undefined
											}
										>
											<Timeline.Date className="bg-secondary">
												{`${dateFormatter.format(notification.releaseDate)}`}
											</Timeline.Date>
											<Timeline.Badge>
												<FAIcon icon={faBell} />
											</Timeline.Badge>
											<Timeline.Panel>
												<Timeline.Header fontContrast="dark">
													<Timeline.HeaderIcon>
														<FAIcon icon={faExclamationTriangle} />
													</Timeline.HeaderIcon>
													<Timeline.Content>
														<h4 className="mb-0">{notification.title}</h4>
													</Timeline.Content>
												</Timeline.Header>
												<Timeline.Content>
													<span className="mb-2 ml-3 mt-3">
														{notification.createdBy && (
															<>
																<strong>
																	Criado por {notification.createdBy}
																</strong>
																{' às '}
															</>
														)}
														{`${hourMinFormatter.format(
															notification.releaseDate
														)}`}
													</span>
												</Timeline.Content>
												<Timeline.Body>
													<div
														className={
															notification?.attach &&
															notification.attach.length > 0
																? 'has-attach'
																: ''
														}
													>
														<span>{notification.description}</span>
													</div>

													{Array.isArray(notification?.attach) &&
														notification.attach?.length > 0 && (
															<AnexosList>
																<div>Anexos:</div>
																<AnexosListItem>
																	{(notification.attach as NewsNotificationAttach[]).map(
																		(attach) => (
																			<span key={attach.idAttach ?? 0}>
																				<a
																					href={attach.file}
																					target="_blank"
																					rel="noopener noreferrer"
																				>
																					<FAIcon
																						icon="paperclip"
																						className="mr-2"
																					/>
																					{attach.file}
																				</a>
																			</span>
																		)
																	)}
																</AnexosListItem>
															</AnexosList>
														)}
												</Timeline.Body>
											</Timeline.Panel>
										</Timeline.Item>
									))}
									{isFetchingMore && (
										<Row className="justify-content-center mb-3">
											<Col xs="auto">
												<Spinner animation="border" role="status" />
											</Col>
										</Row>
									)}
								</React.Fragment>
							))}
						</Timeline>
					)}
				</Container>
			)}
		</ScrollReplaceContainer>
	);
};

export default Notifications;
