import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Props } from 'react-select';

import { StyledSelect } from './select/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectProps extends Props<any> {
	isInvalid?: boolean;
	optionLabel?: string;
	optionValue?: string;
}

const LoadingIndicator: React.FC = () => <Skeleton height="2rem" count={5} />;

const NoOptionsMessage: React.FC = () => <>Sem dados</>;

const SelectField: React.FC<SelectProps> = ({
	optionLabel,
	optionValue,
	placeholder = 'Selecione',
	loadMessage = <LoadingIndicator />,
	noOptionMessage = <NoOptionsMessage />,
	menuPlacement = 'auto',
	...rest
}) => (
	<StyledSelect
		classNamePrefix="select"
		placeholder={placeholder}
		loadMessage={loadMessage}
		noOptionMessage={noOptionMessage}
		menuPlacement={menuPlacement}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getOptionLabel={(option: any) => {
			return optionLabel ? option[optionLabel] : option.name || option;
		}}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getOptionValue={(option: any) => {
			return optionValue ? option[optionValue] : option;
		}}
		{...rest}
	/>
);

SelectField.displayName = 'Select';
SelectField.whyDidYouRender = true;

export default SelectField;
