import { HttpGetListParams, HttpGetListResult, Graduated } from 'types';

import Crud from './crud.service';

const getGraduatedByIdCareer = (
	params: HttpGetListParams
): Promise<HttpGetListResult<Graduated>> =>
	Crud.getList<Graduated>(
		'mmn/graduate/byMonth/{id}?month={month}&year={year}',
		params
	);

export default {
	getGraduatedByIdCareer,
};
