import companyService from 'services/company.service';
import {
	editLinkFormatter,
	cpfCnpjFormatter,
	cnaeFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Sistema',
		path: '/admin/system/companies',
		active: true,
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	SystemCompanies: {
		title: 'Empresas',
		breadcrumbItems: [
			...adminBreadcrumb,
			{ label: 'Empresas', path: '/admin/system/companies' },
		],
		newItemLink: `${adminBreadcrumb[0].path}/create`,
		action: companyService.getForList,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: '/admin/system/companies/{id}',
					}),
				},
				{ key: 'name', title: 'Nome', searchable: true },
				{ key: 'socialName', title: 'Razão Social', searchable: true },
				{
					key: 'cpfCnpj',
					title: 'CNPJ',
					searchable: true,
					className: 'text-center',
					formatter: cpfCnpjFormatter,
				},
				{
					key: 'nRegister',
					title: 'Inscrição Estadual',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'municipalRegister',
					title: 'Inscrição Municipal',
					className: 'text-center',
					searchable: true,
				},
				{
					key: 'cnae',
					title: 'CNAE',
					searchable: true,
					className: 'text-center',
					formatter: cnaeFormatter,
				},
				{
					key: 'type',
					title: 'Tipo',
					className: 'text-center',
					searchable: true,
				},
			],
		},
	},
};

export default ScenesConfig;
