/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ArrayQueryKey, useQuery } from 'react-query';

import {
	faUsers,
	faStar,
	faUserPlus,
	faUserCheck,
} from '@fortawesome/pro-light-svg-icons';
import Axios from 'axios';
import echarts, { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';

import { InfoCard, Card } from 'components';
import Skeleton from 'components/Skeleton';
import { useToast } from 'hooks';
import dashboardService from 'services/dashboard.service';
import {
	NetworkNumbers,
	NumbersActivationMonth,
	NumbersByStateDash,
} from 'types';
import { numberFormatter } from 'utils/formatters';

import PageHeader from 'packages/admin/components/PageHeader';

const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const getChartOption = (data: NumbersActivationMonth[] | null) => {
	if (data) {
		const dataLength = data.length;

		data.forEach((d) => {
			d.idOperation = 0;
			d.operation = 'Cadastros';
			d.total = d.register;
		});
		data = data.concat(data.map((d) => ({ ...d })));
		for (let i = dataLength; i < data.length; ++i) {
			data[i].idOperation = 1;
			data[i].operation = 'Ativações';
			data[i].total = data[i].activated;
		}
	}

	const operations: NumbersActivationMonth[] = data
		? data
				.reduce(
					(array: NumbersActivationMonth[], item: NumbersActivationMonth) => {
						const entry = array.find(
							(a: NumbersActivationMonth) => a.idOperation === item.idOperation
						);
						if (!entry) {
							array.push(item);
						}
						return array;
					},
					[]
				)
				.sort((a: NumbersActivationMonth, b: NumbersActivationMonth) =>
					a.idOperation < b.idOperation ? -1 : 1
				)
		: [];

	const periods: Array<number> = [];
	const date = new Date();
	for (let d = 2017; d <= date.getFullYear(); d++) {
		periods.push(d);
	}

	const dataChart: any = {
		baseOption: {
			title: {
				text: '',
			},
			timeline: {
				y: 470,
				axisType: 'category',
				autoPlay: false,
				currentIndex: periods.length - 1,
				playInterval: 4000,
				data: periods,
				label: {
					formatter: (s: any) => {
						return s;
					},
				},
				tooltip: {
					formatter: (param: any) => {
						return param.name;
					},
				},
			},
			legend: {
				align: 'auto',
				data: operations.map((op) => op.operation),
			},
			calculable: true,
			grid: {
				top: 40,
				bottom: 150,
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
						label: {
							show: true,
							formatter: (params: { value: string }) => {
								return params.value.replace('\n', '');
							},
						},
					},
				},
			},
			tooltip: {
				formatter: (params: any) => {
					let ret = params[0].name.replace('\n', '');
					params.forEach(
						// eslint-disable-next-line no-return-assign
						(param: any) =>
							(ret += `<br />${param.marker} ${
								param.seriesName
							}: ${numberFormatter.format(param.value)}`)
					);
					return ret;
				},
			},
			xAxis: [
				{
					type: 'category',
					axisLabel: { interval: 0 },
					data: months,
					splitLine: { show: false },
				},
			],
			yAxis: [
				{
					type: 'value',
					axisLabel: {
						formatter: (param: any) => {
							return numberFormatter.format(param);
						},
					},
				},
			],
			series: operations.map((op) => {
				return { name: op.operation, type: 'bar', data: [0] };
			}),
		},
		options: periods.map((year) => {
			return {
				series: operations.map((op) => {
					return {
						name: op.operation,
						type: 'bar',
						data: months.map((month) => {
							const item =
								data &&
								data.find(
									(d) =>
										d.year === year &&
										d.month === months.indexOf(month) + 1 &&
										d.idOperation === op.idOperation
								);
							return item ? item.total : 0;
						}),
					};
				}),
			};
		}),
	};
	return dataChart;
};

const getChartMapOption = (dataMap: NumbersByStateDash[] | null) => {
	const states = [
		'AC',
		'AM',
		'AL',
		'AP',
		'BA',
		'CE',
		'DF',
		'ES',
		'GO',
		'MA',
		'MS',
		'MG',
		'MT',
		'PA',
		'PB',
		'PI',
		'PE',
		'PR',
		'RJ',
		'RN',
		'RO',
		'RR',
		'RS',
		'SC',
		'SE',
		'SP',
		'TO',
	];

	let maxScale = 0;
	let minScale = 999999;

	dataMap?.forEach((d) => {
		maxScale = Math.max(
			maxScale,
			d.totalActive + d.totalActivationMonth + d.totalRegister
		);
		minScale = Math.min(
			minScale,
			d.totalActive + d.totalActivationMonth + d.totalRegister
		);
	});

	const option: EChartOption = {
		tooltip: {
			formatter: (params: any) => {
				let ret = '';
				// if (dataMap) {
				const stat = dataMap && dataMap.find((c) => c.uf === params.data.name);
				if (!stat) {
					return `${params.marker} ${params.data.name}: ${params.data.value}`;
				}

				ret = `<strong>${stat.uf}</strong>`;
				ret += `<br />Total de Ativos: ${numberFormatter.format(
					stat.totalActive
				)}`;
				ret += `<br />Pendentes: ${numberFormatter.format(stat.totalRegister)}`;
				ret += `<br />Ativações no Mês: ${numberFormatter.format(
					stat.totalActivationMonth
				)}`;
				// }
				return ret;
			},
		},
		legend: {
			orient: 'vertical',
			left: 'left',
			data: ['Total de Ativos', 'Pendentes', 'Ativações no Mês'],
		},
		visualMap: [
			{
				min: minScale,
				max: maxScale,
				text: ['Máx', 'Min'],
				realtime: false,
				calculable: false,
				inRange: {
					color: ['#f6eea5', '#c3658f'],
				},
			},
		],
		// color: ['#c23531', '#2f4554', '#1ce1ac'],
		series: [
			{
				name: 'Total de Ativos',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.totalActive,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
			{
				name: 'Pendentes',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.totalRegister,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
			{
				name: 'Ativações no Mês',
				type: 'map',
				map: 'brazil',
				label: { show: true },
				emphasis: { label: { show: true } },
				data: dataMap
					? dataMap.map((state) => ({
							name: state.uf,
							value: state.totalActivationMonth,
					  }))
					: states.map((state) => ({ name: state, value: 0 })),
			},
		],
	};

	return option;
};

const Dashboard = (): React.ReactElement => {
	const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
	const [toast] = useToast();

	const { data: networkNumbers, isLoading: isNetworkNumbersLoading } = useQuery<
		NetworkNumbers,
		ArrayQueryKey
	>(['network_numbers'], () => dashboardService.getNetworkNumbers(), {
		onError: () => {
			toast('Não foi possível carregar dados', {
				type: 'error',
			});
		},
	});

	const {
		data: numbersActivationMonth,
		isLoading: isNumbersActivationMonthLoading,
	} = useQuery<NumbersActivationMonth[], ArrayQueryKey>(
		['registerForMonth_list'],
		() =>
			dashboardService.getNumbersActivationMonth({}).then((result) =>
				result.data.map((register) => {
					return {
						...register,
						idOperation: 0,
					};
				})
			),
		{
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	const {
		data: numbersByStateDash,
		isLoading: isNumbersByStateDashLoading,
	} = useQuery<NumbersByStateDash[], ArrayQueryKey>(
		['userStatusByState_list'],
		() =>
			dashboardService.getNumbersByStateDash({}).then((result) => result.data),
		{
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	React.useEffect(() => {
		Axios.get('/assets/geo/brazil.json').then((json) => {
			setMapLoaded(true);

			const br = { ...json.data };
			br.features.forEach((feat: { geometry: { coordinates: any[] } }) => {
				feat.geometry.coordinates.forEach((poly: any[]) => {
					poly.forEach((coord) => {
						// eslint-disable-next-line no-param-reassign
						coord[1] *= 0.8;
					});
				});
			});

			echarts.registerMap('brazil', br);
		});
	}, []);

	return (
		<>
			<Container fluid>
				<PageHeader>
					<PageHeader.Title>Início</PageHeader.Title>
					<PageHeader.Breadcrumb
						className="ml-auto"
						items={[{ label: 'Início', active: true }]}
					/>
				</PageHeader>

				<Row>
					<Col md={3}>
						<InfoCard color="purple" colored icon={faUsers}>
							<InfoCard.Title>
								{isNetworkNumbersLoading
									? '---'
									: numberFormatter.format(
											networkNumbers?.totalRegister || '0'
									  )}
							</InfoCard.Title>
							<InfoCard.Subtitle>Total de Cadastros</InfoCard.Subtitle>
						</InfoCard>
					</Col>
					<Col md={3}>
						<InfoCard color="blue" colored icon={faStar}>
							<InfoCard.Title>
								{isNetworkNumbersLoading
									? '---'
									: numberFormatter.format(networkNumbers?.totalActive || '0')}
							</InfoCard.Title>
							<InfoCard.Subtitle>Total de Ativos</InfoCard.Subtitle>
						</InfoCard>
					</Col>
					<Col md={3}>
						<InfoCard color="pink" colored icon={faUserPlus}>
							<InfoCard.Title>
								{isNetworkNumbersLoading
									? '---'
									: numberFormatter.format(
											networkNumbers?.todayRegister || '0'
									  )}
							</InfoCard.Title>
							<InfoCard.Subtitle>Cadastros no Dia</InfoCard.Subtitle>
						</InfoCard>
					</Col>
					<Col md={3}>
						<InfoCard color="green" colored icon={faUserCheck}>
							<InfoCard.Title>
								{isNetworkNumbersLoading
									? '---'
									: numberFormatter.format(
											networkNumbers?.todayActivation || '0'
									  )}
							</InfoCard.Title>
							<InfoCard.Subtitle>Ativações no Dia</InfoCard.Subtitle>
						</InfoCard>
					</Col>
				</Row>

				<Row>
					<Col lg={6}>
						<Card>
							<Card.Header separator>
								<Card.Title>Cadastros/Ativações x Mês</Card.Title>
							</Card.Header>
							<Card.Content>
								{isNumbersActivationMonthLoading ? (
									<div style={{ height: '550px' }}>
										<Skeleton width="98%" height="40rem" />
									</div>
								) : (
									<ReactEcharts
										option={getChartOption(numbersActivationMonth || null)}
										style={{ height: '550px' }}
										theme="amakha"
									/>
								)}
							</Card.Content>
						</Card>
					</Col>
					<Col lg={6}>
						<Card>
							<Card.Header separator>
								<Card.Title>Cadastros/Ativações x Estado</Card.Title>
							</Card.Header>
							<Card.Content>
								{isNumbersByStateDashLoading && mapLoaded ? (
									<div style={{ height: '550px' }}>
										<Skeleton width="98%" height="40rem" />
									</div>
								) : (
									<ReactEcharts
										option={getChartMapOption(numbersByStateDash || null)}
										style={{ height: '550px' }}
										theme="amakha"
									/>
								)}
							</Card.Content>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
