import React, { FunctionComponent, useState } from 'react';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';

import { faEye, faEyeSlash, faKey } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import { ScoreBar } from 'components';
import { Input } from 'components/Formik';
import PasswordStrength from 'utils/PasswordStrength';

/**
 * Dados retornada no onSubmit
 */
export interface ExpiredPassFormModel {
	email: string;
	password: string;
	newPassword: string;
	newPasswordConfirmation: string;
}

type ExpiredPassFormProps = {
	isLoading?: boolean;
	initialData: ExpiredPassFormModel;
	onSubmit: (
		form: ExpiredPassFormModel,
		actions?: FormikHelpers<ExpiredPassFormModel>
	) => void;
};

const formSchema = Yup.object({
	newPassword: Yup.string().required('Digite sua senha'),
	newPasswordConfirmation: Yup.string().oneOf(
		[Yup.ref('newPassword'), ''],
		'A confirmação da senha não é igual a senha'
	),
});

/**
 * Formulário com a senha e confirmação para setar nova senha.
 * O onSubmit somente é chamado quando os dados passam pela validação
 */
const ExpiredPassForm: FunctionComponent<ExpiredPassFormProps> = ({
	isLoading,
	initialData,
	onSubmit,
}) => {
	const [showPassword, setShowPassword] = useState<boolean[]>([false, false]);

	return (
		<Formik
			onSubmit={onSubmit}
			validationSchema={formSchema}
			initialValues={initialData}
		>
			{(form: FormikProps<ExpiredPassFormModel>) => (
				<FormikForm>
					<Form.Group>
						<p>Digite uma nova senha para continuar</p>
					</Form.Group>

					<Form.Group>
						<Input
							disabled={isLoading}
							name="newPassword"
							type={showPassword[0] ? 'text' : 'password'}
							placeholder="Senha"
							prepend={
								<InputGroup.Text>
									<FAIcon fixedWidth icon={faKey} />
								</InputGroup.Text>
							}
							append={
								<Button
									variant="outline-primary"
									onClick={() => {
										setShowPassword([!showPassword[0], showPassword[1]]);
									}}
								>
									<FAIcon icon={showPassword[0] ? faEye : faEyeSlash} />
								</Button>
							}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							disabled={isLoading}
							name="newPasswordConfirmation"
							type={showPassword[1] ? 'text' : 'password'}
							placeholder="Confirme sua Senha"
							prepend={
								<InputGroup.Text>
									<FAIcon fixedWidth icon={faKey} />
								</InputGroup.Text>
							}
							append={
								<Button
									variant="outline-primary"
									onClick={() => {
										setShowPassword([showPassword[0], !showPassword[1]]);
									}}
								>
									<FAIcon icon={showPassword[1] ? faEye : faEyeSlash} />
								</Button>
							}
						/>
					</Form.Group>

					<Form.Group>
						<ScoreBar
							score={PasswordStrength(
								initialData.email,
								form.values.newPassword
							)}
						/>
					</Form.Group>

					<div className="text-right">
						<Button
							variant="primary"
							className="mt-2"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<Spinner size="sm" animation="border" role="status" />
									<span className="ml-2">AGUARDE...</span>
								</>
							) : (
								'ENTRAR'
							)}
						</Button>
					</div>
				</FormikForm>
			)}
		</Formik>
	);
};

export default ExpiredPassForm;
