import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMounted } from '@restart/hooks';

import { useQueryParams } from 'hooks';
import { actions, selectors } from 'store/ducks/session';
import { history } from 'utils';

const Logout: React.FC = () => {
	const query = useQueryParams();
	const dispatch = useDispatch();
	const isMounted = useMounted();
	const isLogged = useSelector(selectors.isLogged);
	const redirect = query?.get('redirect') || '';

	useEffect(() => {
		if (isMounted()) {
			if (isLogged) {
				dispatch(actions.logoutRequest(redirect));
			} else {
				history.push(`/login?redirect=${redirect}`);
			}
		}
	}, [isMounted, isLogged, dispatch, redirect]);

	return <></>;
};

export default Logout;
