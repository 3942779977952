import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	Card,
	Button,
	Form,
	Col,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import { faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputQty from 'components/InputQty';
import LazyImage from 'components/LazyImage';
import { actions, MAX_PRODUCT_PER_CART } from 'store/ducks/shoppingCart';
import { EcProductDetails } from 'types';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import {
	useEcommerceBasePath,
	useSetShoppingCartVisible,
} from 'packages/ecommerce/hooks';

import { Title, ProductPrice } from '../Typography';

import ProductCardSkeleton from './card/Skeleton';
import { stubImageSrc, useCartNameAndItem } from './Shared';
import { OldPrice } from './styles';

export interface ProductCardProps {
	product: EcProductDetails;
	showQuantity?: boolean;
	showAddToCartButton?: boolean;
	showRemoveButton?: boolean;
}

type ProductCardComponent = React.FC<ProductCardProps> & {
	Skeleton: typeof ProductCardSkeleton;
};

const ProductCard: ProductCardComponent = ({
	product,
	showQuantity,
	showAddToCartButton,
}) => {
	const [quantity, setQuantity] = useState<number>(1);
	const baseUrl = useEcommerceBasePath();
	const setCartSidebarVisible = useSetShoppingCartVisible();
	const [cartName, cartItem] = useCartNameAndItem(product.idProduct);
	const dispatch = useDispatch();

	const onAddToCart = () => {
		if (!cartItem) {
			dispatch(actions.addItem(cartName, Number(product.idProduct), quantity));
		} else {
			dispatch(
				actions.updateQuantity(cartName, Number(product.idProduct), quantity)
			);
		}
		setCartSidebarVisible(true);
	};

	return (
		<Card className="border">
			<Card.Img
				variant="top"
				as={Link}
				to={`${baseUrl}/produto/${product.idProduct}`}
			>
				<LazyImage
					loadingSrc={stubImageSrc}
					src={product.image[0].idImage}
					alt={product.name}
				/>
			</Card.Img>
			<Card.Body>
				<Link
					className="text-dark"
					to={`${baseUrl}/produto/${product.idProduct}`}
				>
					<Title>{product.name}</Title>
					<OldPrice className="text-muted">
						{product.priceBase !== product.price && (
							<del>{currencyWithSymbolFormatter.format(product.priceBase)}</del>
						)}
					</OldPrice>
					<ProductPrice className="text-primary">
						{currencyWithSymbolFormatter.format(product.price)}
					</ProductPrice>
				</Link>

				<Form.Row className="mt-2">
					<Col>
						{showQuantity && (
							<InputQty
								quantity={quantity}
								maxLength={MAX_PRODUCT_PER_CART}
								onChange={setQuantity}
							/>
						)}
					</Col>
					<Col className="col-auto">
						{showAddToCartButton && (
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip id="add-cart">Adicionar ao Carrinho</Tooltip>}
							>
								<Button
									variant="dark"
									type="button"
									size="lg"
									onClick={onAddToCart}
								>
									<FontAwesomeIcon icon={faShoppingCart} />
								</Button>
							</OverlayTrigger>
						)}
					</Col>
				</Form.Row>
			</Card.Body>
		</Card>
	);
};

ProductCard.Skeleton = ProductCardSkeleton;

ProductCard.displayName = 'ProductCard.Card';
ProductCard.whyDidYouRender = true;

export default ProductCard;
