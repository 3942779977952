import styled from 'styled-components';

export const AvatarStyled = styled.span`
	--avatar-color: var(--gray-20);
	--avatar-bg: var(--gray-50);
	--avatar-size-base: 32px;
	--avatar-size-lg: 40px;
	--avatar-size-sm: 24px;
	--avatar-font-size-base: 18px;
	--avatar-font-size-lg: 24px;
	--avatar-font-size-sm: 14px;
	--avatar-border-radius-base: 50%;
	--avatar-border-radius-square: var(--base-border-radius);

	position: relative;
	display: inline-block;
	overflow: hidden;
	color: var(--avatar-color);
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	background: var(--avatar-bg);

	width: var(--avatar-size-base);
	height: var(--avatar-size-base);
	line-height: var(--avatar-size-base);
	border-radius: var(--avatar-border-radius-base);

	.string {
		position: absolute;
		left: 50%;
		transform-origin: 0 center;
	}

	&.has-icon {
		font-size: var(--avatar-font-size-base);
	}

	&.has-image {
		background: transparent;
	}

	&.square {
		border-radius: var(--avatar-border-radius-square) !important;
	}

	& > img {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.is-large {
		width: var(--avatar-size-lg);
		height: var(--avatar-size-lg);
		line-height: var(--avatar-size-lg);

		&.has-icon {
			font-size: var(--avatar-font-size-lg);
		}
	}

	&.is-small {
		width: var(--avatar-size-sm);
		height: var(--avatar-size-sm);
		line-height: var(--avatar-size-sm);

		&.has-icon {
			font-size: var(--avatar-font-size-sm);
		}
	}
`;
