import React from 'react';

import { useField } from 'formik';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

import classNames from 'classnames';

type ResizeType =
	| 'both'
	| 'none'
	| 'horizontal'
	| 'vertical'
	| 'initial'
	| 'inherit';

export type TextAreaProps = FormControlProps &
	Partial<Pick<HTMLTextAreaElement, 'rows' | 'cols'>> & {
		name: string;
		className?: string;
		resize: ResizeType;
	};

const ResizeableFormControl = styled(FormControl).attrs(
	(props: TextAreaProps) => ({
		...props,
		as: 'textarea',
	})
)`
	resize: ${(props: TextAreaProps) => props.resize};
`;

const TextArea: React.FC<TextAreaProps> = ({
	name,
	className,
	resize,
	...rest
}) => {
	const [field, { error, touched }] = useField(name);

	return (
		<InputGroup>
			<ResizeableFormControl
				{...rest}
				{...field}
				className={classNames(
					className,
					'form-control',
					error && touched && 'is-invalid'
				)}
				resize={resize}
			/>
			{error && touched && (
				<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
			)}
		</InputGroup>
	);
};

TextArea.displayName = 'FormikTextArea';
TextArea.whyDidYouRender = true;
TextArea.defaultProps = {
	resize: 'both',
};

export default TextArea;
