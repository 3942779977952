import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import styled from 'styled-components';

const style = `
	flex: 1;
	&.is-invalid > .select__control,
	&:hover.is-invalid > .select__control {
		border-color: var(--danger) !important;
	}
	.select__control {
		box-shadow: none;

		border-color: var(--input-border-color);
		border-radius: var(--input-border-radius);

		padding: var(--input-padding-y) var(--input-padding-x);
		font-family: var(--base-font-family);
		font-size: var(--input-font-size);
		font-weight: var(--input-font-weight);
		line-height: var(--input-line-height);
		min-height: var(--input-height);
		height: var(--input-height);

		&--is-focused {
			border-color: var(--input-focus-border-color) !important;

			&:hover {
				border-color: var(--input-focus-border-color) !important;
			}
		}

		&:hover {
			border-color: var(--input-border-color);
		}

		.select__indicator {
			padding: 0;
			padding-left: 8px;

			svg {
				color: var(--gray-50);
			}
		}

		.select__indicator-separator {
			background-color: var(--border-color);
		}
	}

	.select__value-container {
		padding: 0;
		font-size: var(--input-font-size);
		font-weight: var(--input-font-weight);
		line-height: var(--input-line-height);
		height: 100%;

		.select__single-value {
			color: var(--input-color);
		}
	}

	.select__menu {
		background-color: var(--always-white);
		border: 1px solid var(--input-border-color);
		box-shadow: var(--select-box-shadow);
		z-index: var(--dropdown-zindex);

		.select__menu-list {
			.select__option {
				border-bottom: 1px solid var(--gray-10);

				&:last-child {
					border: none;
				}

				&.select__option--is-focused {
					background-color: var(--gray-10);
				}

				&.select__option--is-selected {
					font-weight: bold;
					color: inherit;
					background-color: var(--gray-20);
				}
			}
		}
	}
`;

export const StyledSelect = styled(Select)`
	${style}
`;

export const StyledAsyncSelect = styled(AsyncPaginate)`
	${style}
`;
