import React from 'react';

import BsButton, { ButtonProps as BsButtonProps } from 'react-bootstrap/Button';

import { Omit } from 'utils/type';

type BsButtonVariant = keyof BsButtonProps['variant'];

type ButtonVariant =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'danger'
	| 'warning'
	| 'info'
	| 'dark'
	| 'light'
	| 'link'
	| 'outline-primary'
	| 'outline-secondary'
	| 'outline-success'
	| 'outline-danger'
	| 'outline-warning'
	| 'outline-info'
	| 'outline-dark'
	| 'outline-light'
	| 'blue'
	| 'indigo'
	| 'purple'
	| 'pink'
	| 'red'
	| 'orange'
	| 'yellow'
	| 'green'
	| 'teal'
	| 'cyan'
	| 'white'
	| 'outline-blue'
	| 'outline-indigo'
	| 'outline-purple'
	| 'outline-pink'
	| 'outline-red'
	| 'outline-orange'
	| 'outline-yellow'
	| 'outline-green'
	| 'outline-teal'
	| 'outline-cyan'
	| 'outline-white';

type ButtonProps = Omit<BsButtonProps, 'variant'> & {
	variant?: ButtonVariant;
};

const Button: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ variant, ...props }) => (
	<BsButton {...props} variant={variant as BsButtonVariant} />
);

export default Button;
