import React from 'react';
import { Link } from 'react-router-dom';

import BsBreadcrumb from 'react-bootstrap/Breadcrumb';
import styled from 'styled-components';

import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export interface BreadcrumbItem {
	label: string;
	path?: string;
	active?: boolean;
}

export type BreadcrumbProps = {
	className?: string;
	items: BreadcrumbItem[];
	pathHome?: string;
};

const StyledBreadcrumb = styled(BsBreadcrumb)`
	.breadcrumb {
		margin: 0;
		padding: 0;
		font-size: 0.875rem;
	}
`;

const Breadcrumb: React.FC<BreadcrumbProps> = ({
	className,
	items,
	pathHome = '/admin',
}) => (
	<StyledBreadcrumb className={classNames('p-0 m-0 d-inline-block', className)}>
		<BsBreadcrumb.Item linkAs="span">
			<Link to={pathHome} className="text-muted">
				<FontAwesomeIcon icon={faHome} />
			</Link>
		</BsBreadcrumb.Item>
		{items &&
			items.map((item) =>
				item.active ? (
					<BsBreadcrumb.Item active key={item.label} className="text-muted">
						{item.label}
					</BsBreadcrumb.Item>
				) : (
					<BsBreadcrumb.Item linkAs="span" key={item.label}>
						<Link to={`${item.path}`} className="text-muted">
							{item.label}
						</Link>
					</BsBreadcrumb.Item>
				)
			)}
	</StyledBreadcrumb>
);

export default Breadcrumb;
