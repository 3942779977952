import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
	Formik,
	Form as FormikForm,
	FormikValues,
	FormikHelpers,
} from 'formik';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import * as Yup from 'yup';

import AddressForm, {
	formSchema as addressFormSchema,
	defaultFormValue as defaultAddressFormValue,
} from 'components/AddressForm';
import { FormikEffect, Input, Switch } from 'components/Formik';
import { useToast } from 'hooks';
import carrierService from 'services/carrier.service';
import { ApiCodes, HttpBaseResult, PersonStatus, UrlParams } from 'types';
import { cnpjFormatter, regexNumbersOnlyFormatter } from 'utils/formatters';
import history from 'utils/history';
import ValidateCNPJ from 'utils/Validator/CNPJ';

import {
	FormSubmitButton,
	PageHeader,
	FormCancelButton,
	FormGroupTitle,
} from 'packages/admin/components';

import SkeletonForm from './carrier/FormSkeleton';
import { CarrierFormType } from './carrier/types';

export const defaultFormValue: CarrierFormType = {
	idPerson: null,
	name: '',
	socialName: '',
	cpfCnpj: '',
	nRegister: '',
	idStatus: null,
	status: '',
	active: true,
	address: defaultAddressFormValue,
};

export const formSchema = Yup.object<CarrierFormType>().shape({
	name: Yup.string().required('Campo obrigatório'),
	nRegister: Yup.string().required('Campo obrigatório'),
	cpfCnpj: Yup.string()
		.transform((value: string): string => {
			if (!ValidateCNPJ(value)) {
				return '';
			}
			return value;
		})
		.required('CNPJ Inválido'),
	address: addressFormSchema,
});

const CarrierEditPage: React.FC = () => {
	const { id } = useParams<UrlParams>();
	const [toast] = useToast();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [carrier, setCarrier] = useState<CarrierFormType>(defaultFormValue);

	useEffect(() => {
		if (id !== 'create') {
			carrierService
				.getForId(Number(id))
				.then((res) => {
					res.active = res.idStatus === PersonStatus.Active;
					setCarrier(res);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
					toast('Não foi possível carregar os dados da Transportadora', {
						type: 'error',
					});
				});
		} else {
			setIsLoading(false);
		}
	}, [id, toast]);

	const handleSubmit = useCallback(
		async (values: FormikValues, helpers: FormikHelpers<CarrierFormType>) => {
			const submit = { ...values } as CarrierFormType;
			helpers.setSubmitting(true);

			submit.idStatus = submit.active
				? PersonStatus.Active
				: PersonStatus.Blocked;

			try {
				const { message } = await carrierService.createOrUpdate(
					submit.idPerson,
					submit
				);
				// eslint-disable-next-line no-console
				console.log('Success: ', message);
				helpers.resetForm();
				toast(
					`Transportadora ${
						id !== 'create' ? 'alterada' : 'criada'
					} com sucesso`,
					{
						type: 'success',
					}
				);
				history.push('/admin/stores/carriers');
			} catch (e) {
				const { code, message } = e as HttpBaseResult;
				// eslint-disable-next-line no-console
				console.log('Error message: ', message);
				toast(ApiCodes.getCodeString(code), {
					type: 'error',
				});
			}
			helpers.setSubmitting(false);
		},
		[id, toast]
	);

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>
					{`${id !== 'create' ? 'Editar' : 'Nova'} Transportadora`}
				</PageHeader.Title>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'Loja', path: '/admin/stores/carriers', active: true },
						{
							label: 'Transportadora',
							path: '/admin/stores/carriers',
							active: true,
						},
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<SkeletonForm />
			) : (
				<Formik<CarrierFormType>
					onSubmit={handleSubmit}
					initialValues={carrier}
					validationSchema={formSchema}
				>
					{(formProps) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Body>
									<FormGroupTitle className="d-flex justify-content-between">
										Dados Cadastrais
										<Form.Group className="float-right d-inline mb-0">
											<Form.Label className="text-muted mr-2">Ativo</Form.Label>
											<Switch name="active" defaultChecked />
										</Form.Group>
									</FormGroupTitle>
									<Form.Row>
										<Form.Group as={Col} md={12} lg={8}>
											<Form.Label>Nome</Form.Label>
											<Input name="name" type="text" />
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>CNPJ</Form.Label>
											<Input
												name="cpfCnpj"
												formatter={cnpjFormatter}
												type="text"
											/>
										</Form.Group>

										<Form.Group as={Col} md={6} lg={4}>
											<Form.Label>Inscrição Estadual</Form.Label>
											<Input
												name="nRegister"
												type="text"
												formatter={regexNumbersOnlyFormatter}
											/>
										</Form.Group>
									</Form.Row>

									<FormGroupTitle marginTop>Endereço</FormGroupTitle>
									<AddressForm path="address" />
								</Card.Body>

								<Card.Footer className="text-right">
									<FormCancelButton
										isSubmitting={formProps.isSubmitting}
										to="/admin/stores/carriers"
									/>
									<FormSubmitButton isSubmitting={formProps.isSubmitting} />
								</Card.Footer>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</Container>
	);
};

export default CarrierEditPage;
