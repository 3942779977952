import React, { useCallback } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useQuery } from 'react-query';

import { faEdit, faFile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { Card } from 'components';
import { useModal, useToast } from 'hooks';
import recognitionService from 'services/recognition.service';
import { Recognition } from 'types';

import { PageHeader } from 'packages/admin/components';

import RecognitionFormModal from './recognition/modals/RecognitionFormModal';
import SkeletonForm from './recognition/SkeletonForm';
import * as S from './recognition/styles';

const RecognitionEdit: React.FC = () => {
	const [toast] = useToast();
	const open = useModal();

	const { data, isLoading, refetch } = useQuery(
		['get_recognitions'],
		() => recognitionService.getList({}),
		{
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados', {
					type: 'error',
				});
			},
		}
	);

	const handleEdit = useCallback(
		(badge?: Recognition) => {
			open(RecognitionFormModal, { data: badge }, (value?: unknown) => {
				if (value) {
					refetch();
				}
			});
		},
		[open, refetch]
	);

	return (
		<Container fluid>
			<PageHeader>
				<PageHeader.Title>Reconhecimentos</PageHeader.Title>
				<Button variant="primary" size="sm" onClick={() => handleEdit()}>
					<FAIcon icon={faFile} className="mr-1" /> Novo
				</Button>
				<PageHeader.Breadcrumb
					className="ml-auto"
					items={[
						{ label: 'MMN', path: '/admin/mmn/summary' },
						{ label: 'Reconhecimentos', active: true },
					]}
				/>
			</PageHeader>

			{isLoading ? (
				<SkeletonForm />
			) : (
				<div className="d-flex flex-wrap">
					{data &&
						data.map((item) => (
							<S.Card key={item.idBadge} className="mr-3">
								<Card.Header
									separator
									alignItems="center"
									justifyContent="space-between"
								>
									<Card.Title>{item.name}</Card.Title>
									<OverlayTrigger
										overlay={
											<Tooltip id={`tooltip-edit-${item.idBadge}`}>
												Editar
											</Tooltip>
										}
									>
										<Button
											variant="link"
											size="sm"
											className="text-info"
											onClick={() => handleEdit(item)}
										>
											<FAIcon icon={faEdit} size="lg" />
										</Button>
									</OverlayTrigger>
								</Card.Header>
								<S.CardImgContent>
									{item.image && (
										<img
											src={`${String(
												item.image
											)}?d=${new Date().toISOString()}`}
											alt={item.name}
											width="150px"
										/>
									)}
								</S.CardImgContent>
							</S.Card>
						))}
				</div>
			)}
		</Container>
	);
};

export default RecognitionEdit;
