import React, {
	useCallback,
	useState,
	useRef,
	ChangeEvent,
	useEffect,
} from 'react';

import { useField } from 'formik';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask, { InputState } from 'react-input-mask';

import { faCalendar, faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import Calendar from 'components/Calendar';
import { useOnClickOutside } from 'hooks';
import { REGEX_CLEAR_NUMERIC } from 'utils/Formatter/Regex';
import {
	dateFormatter,
	dateHourMinFormatter,
	hourMinFormatter,
} from 'utils/formatters';

import StyledOverlay from '../dateTimeInput/StyledOverlay';

import { InputProps } from './Input';

/**
 * Componente de seleção de data e hora
 * A string usada internamente é o format ISO, já disponível no
 * Date do JS
 *
 * TODO:
 * Se o campo de data for inválido, o retorno será null,
 * para facilitar a validação do campo e diferenciar entre
 * campo vazio ('') e inválido (null)
 */

export type DateTimePickerProps = Omit<InputProps, 'onChange'> & {
	calendarSide?: 'left' | 'right';
	selectDate?: boolean;
	selectTime?: boolean;
	showPicker?: boolean;
	className?: string;
	onChange?: (isoDate: string) => void;
};

const defaultProps: DateTimePickerProps = {
	calendarSide: 'left',
	selectDate: false,
	selectTime: false,
	showPicker: true,
	name: 'datetimepicker',
};

const useBeforeMaskedStateChange = (
	inputMask: string,
	selectDate?: boolean,
	selectTime?: boolean
) => {
	// Intercepta o input e ajusta os dígitos para não ficarem fora dos limites
	// de data e hora
	return useCallback(
		(newState: InputState, _: InputState, userInput: string): InputState => {
			if (userInput === null) {
				return newState;
			}

			const { value } = newState;

			let formatter = dateHourMinFormatter;
			if (!selectDate) {
				formatter = hourMinFormatter;
			} else if (!selectTime) {
				formatter = dateFormatter;
			}

			const numericValue = value.replace(REGEX_CLEAR_NUMERIC, '');

			// TODO:
			// Ainda é possível por data inválida pois o limite de dias é 31 para qualquer mês
			let formattedDate = formatter.formatForInput(numericValue)[1];
			let dateOffset = formattedDate.length - 1;

			while (dateOffset < inputMask.length && inputMask[dateOffset] !== '9') {
				formattedDate += inputMask[dateOffset];
				// eslint-disable-next-line no-plusplus
				dateOffset++;
			}

			const newSelection = {
				start: formattedDate.length,
				end: formattedDate.length,
			};

			return {
				selection: newSelection,
				value: formattedDate,
			};
		},
		[inputMask, selectDate, selectTime]
	);
};

const getFormattedDate = (
	date: string,
	selectDate: boolean,
	selectTime: boolean
): string => {
	let formattedDate = '';
	if (date.length > 0) {
		let formatter = dateHourMinFormatter;
		if (!selectDate) {
			formatter = hourMinFormatter;
		} else if (!selectTime) {
			formatter = dateFormatter;
		}
		formattedDate = formatter.format(date) as string;
	}
	return formattedDate;
};

const DateTimeInput: React.FC<DateTimePickerProps> = ({
	calendarSide,
	selectDate,
	selectTime,
	showPicker,
	name,
	className,
	onChange,
	...rest
}) => {
	const inputGroupRef = useRef(null);
	const [
		{ onBlur },
		{ initialValue, value, error, touched },
		{ setValue },
	] = useField(name);
	const popupRef = useRef<HTMLDivElement>(null);

	// Exibe/esconde popup
	const [isPickerVisible, setPickerVisible] = useState<boolean>(false);
	const togglePicker = useCallback(() => {
		setPickerVisible(!isPickerVisible);
	}, [isPickerVisible]);

	useOnClickOutside(inputGroupRef, () => setPickerVisible(false));

	// Cache da máscara do input
	const [inputMask, setInputMask] = useState<string>('');

	// Valor do input com máscara
	const [inputValue, setInputValue] = useState<string>(
		getFormattedDate((initialValue as string) || '', !!selectDate, !!selectTime)
	);

	// Atualiza máscara do input baseado nos parâmetros passados:
	useEffect(() => {
		let mask = '';
		if (selectDate) {
			mask = '99/99/9999';
		}
		if (selectTime) {
			if (mask.length > 0) {
				mask += ' ';
			}
			mask += '99:99';
		}
		setInputMask(mask);
	}, [selectDate, selectTime]);

	// Formata data e atualiza o input
	const updateInputFromDate = useCallback(
		(date: string) => {
			const formattedDate = getFormattedDate(date, !!selectDate, !!selectTime);
			setInputValue(formattedDate);
		},
		[selectTime, selectDate]
	);

	// Traz valor inicial para o input:
	useEffect(() => {
		updateInputFromDate(initialValue || '');
		onChange?.(initialValue || '');
	}, [updateInputFromDate, initialValue, onChange]);

	// Intercepta o input e ajusta os dígitos para não ficarem fora dos limites
	// de data e hora
	const beforeMaskedStateChange = useBeforeMaskedStateChange(
		inputMask,
		selectDate,
		selectTime
	);

	const onInputChange = (input: ChangeEvent<HTMLInputElement>) => {
		setInputValue(input.currentTarget.value);
	};

	// Verifica se o campo de data é válido e atualiza o dateValue
	const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (isPickerVisible) {
			return;
		}
		onBlur(event);
		const isoDate = dateFormatter.localeStringToIso(inputValue);
		setValue(isoDate);
		onChange?.(isoDate || '');
	};

	const onDateChange = (date: string) => {
		const isoDate = dateFormatter.localeStringToIso(
			new Date(date).toLocaleString()
		);
		setValue(isoDate);
		updateInputFromDate(isoDate || '');
		onChange?.(isoDate || '');
	};

	const onTimeChange = (date: string) => {
		const isoDate = dateFormatter.localeStringToIso(
			new Date(date).toLocaleString()
		);
		setValue(isoDate);
		updateInputFromDate(isoDate || '');
		onChange?.(isoDate || '');
	};

	return (
		<>
			<InputGroup className={className} ref={inputGroupRef}>
				{showPicker && calendarSide === 'left' && (
					<InputGroup.Prepend>
						<Button variant="outline-primary" onClick={togglePicker}>
							<FAIcon icon={selectDate ? faCalendar : faClock} />
						</Button>
					</InputGroup.Prepend>
				)}
				<Form.Control
					as={InputMask}
					value={inputValue}
					onChange={onInputChange}
					onBlur={onInputBlur}
					beforeMaskedValueChange={beforeMaskedStateChange}
					mask={inputMask}
					maskChar="_"
					readOnly={isPickerVisible}
					isInvalid={!!error && touched}
					className="rounded-right"
					{...rest}
				/>
				{error && touched && (
					<FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
				)}
				{showPicker && (
					<StyledOverlay
						overlayProps={{
							show: isPickerVisible,
							container: inputGroupRef,
							target: inputGroupRef,
							placement:
								calendarSide === 'left' ? 'bottom-start' : 'bottom-end',
						}}
					>
						<div ref={popupRef} className="pt-1 shadow-lg bg-white">
							<Calendar
								showDate={selectDate}
								showTime={selectTime}
								value={value}
								onDateChange={onDateChange}
								onTimeChange={onTimeChange}
							/>
						</div>
					</StyledOverlay>
				)}
			</InputGroup>
		</>
	);
};

DateTimeInput.displayName = 'DateTimeInput';
DateTimeInput.defaultProps = defaultProps;
DateTimeInput.whyDidYouRender = true;

export default DateTimeInput;
