export const MonthLocalePt = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
];

export const WeeksLocalePt = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

const getDaysInMonth = (year: number, month: number) =>
	new Date(year, month + 1, 0).getDate();

const splitArray = <T>(list: T[], chunk: number) => {
	const result = [];
	for (let i = 0, j = list.length; i < j; i += chunk) {
		result.push(list.slice(i, i + chunk));
	}
	return result;
};

export default {
	getDaysInMonth,
	splitArray,
};
