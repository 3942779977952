import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { queryCache } from 'react-query';

import { useMergeState } from '@restart/hooks';
import SessionContext, {
	defaultSessionContextValue,
	SessionContextState,
} from 'contexts/SessionContext';

import useDeepEffect from 'hooks/useDeepEffect';
import { RootState } from 'store/ducks/rootReducer';
import { SessionState } from 'store/ducks/session/types';
import { PersonType } from 'types';

import ExecutiveBalanceUpdater from './ExecutiveBalanceUpdater';

const SessionContainer: React.FC = ({ children }) => {
	const [values, update] = useMergeState<SessionContextState>(
		defaultSessionContextValue
	);

	const reloadExecutiveBalance = useCallback(() => {
		queryCache.invalidateQueries('executive_balance', {
			exact: true,
			stale: true,
			refetchActive: true,
			refetchInactive: true,
		});
	}, []);

	// FIXME: Remover redux?
	const authSel = useSelector<RootState, SessionState>(
		(state) => state.session
	);

	const idPersonType = authSel.user?.idType;
	const isExecutive =
		idPersonType === PersonType.Executive ||
		idPersonType === PersonType.Distributor ||
		idPersonType === PersonType.ExclusiveDistributor;

	useDeepEffect(() => {
		update({
			auth: {
				person: authSel.user,
				lastError: authSel.lastError,
				permissions: authSel.permissions,
			},
		});
	}, [authSel, update]);

	const contextValue = {
		...values,
		update,
		reloadExecutiveBalance,
	};

	return (
		<SessionContext.Provider value={contextValue}>
			{isExecutive && <ExecutiveBalanceUpdater />}
			{children}
		</SessionContext.Provider>
	);
};

export default SessionContainer;
