import React from 'react';

import { Col, Form } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

const AccessScheduleFormSkeleton: React.FC = () => (
	<Card>
		<Card.Content>
			<Form.Row>
				<Col lg={{ span: 6, offset: 3 }}>
					<Form.Row>
						<Form.Group as={Col}>
							<Skeleton width="5ch" height="1rem" />
							<Skeleton display="block" height="2.5rem" />
						</Form.Group>
					</Form.Row>

					<fieldset className="border rounded px-4 py-3 mb-3">
						<Form.Row>
							<Form.Group as={Col}>
								<Skeleton height="1rem" />
							</Form.Group>
						</Form.Row>

						<Form.Row>
							<Form.Group
								as={Col}
								md={{ span: 2, offset: 3 }}
								lg={{ span: 1, offset: 3 }}
								className="text-center"
							>
								<Skeleton height="1rem" />
								<Skeleton display="block" height="2.5rem" />
							</Form.Group>
							<Form.Group as={Col} md={4} lg={4}>
								<Skeleton height="1rem" />
								<Skeleton display="block" height="2.5rem" />
							</Form.Group>
							<Form.Group as={Col} md={4} lg={4}>
								<Skeleton height="1rem" />
								<Skeleton display="block" height="2.5rem" />
							</Form.Group>
						</Form.Row>
					</fieldset>

					<fieldset className="border rounded px-4 py-3 mb-3">
						<Form.Row>
							<Form.Group
								as={Col}
								md={{ span: 4, offset: 4 }}
								lg={{ span: 4, offset: 4 }}
							>
								<Skeleton height="1rem" />
							</Form.Group>
							<Form.Group as={Col} md={4} lg={4}>
								<Skeleton height="1rem" />
							</Form.Group>
						</Form.Row>
						{[...Array(7).keys()].map((key) => (
							<div key={key}>
								<Form.Row className="d-flex align-items-end">
									<Form.Group as={Col} md={3} sm={3}>
										<Skeleton display="block" height="1rem" />
									</Form.Group>
									<Form.Group
										as={Col}
										sm={2}
										md={2}
										lg={1}
										className="text-center"
									>
										<Skeleton display="block" height="2.5rem" />
									</Form.Group>
									<Form.Group as={Col} md={4} lg={4}>
										<Skeleton display="block" height="2.5rem" />
									</Form.Group>
									<Form.Group as={Col} md={4} lg={4}>
										<Skeleton display="block" height="2.5rem" />
									</Form.Group>
								</Form.Row>
							</div>
						))}
					</fieldset>
				</Col>
			</Form.Row>

			<Form.Row className="text-right">
				<Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }}>
					<Skeleton
						display="inline-block"
						width="10ch"
						height="2.5rem"
						margin="0 1rem 0 0"
					/>
					<Skeleton width="10ch" height="2.5rem" />
				</Col>
			</Form.Row>
		</Card.Content>
	</Card>
);

export default AccessScheduleFormSkeleton;
