import { Pagination, HttpBaseResult, QueryOperator } from 'types';
import { FormatterType } from 'utils/Formatter/IFormatter';

export enum DatagridUpdateAction {
	Setup = 'setup',
	ColumnSearch = 'colsearch',
	ColumnSort = 'colsort',
	QueryChange = 'query',
	ParamChange = 'param',
	RowsPerPage = 'rowsperpage',
	PageChange = 'pagechange',
	FilterChange = 'filterchange',
	ForceUpdate = 'forceupdate',
	NewData = 'data',
}

export type ColumnConfig = {
	key: string;
	sortBy: string;
	title: string;
	scope: string;
	className: string;
	searchable: boolean;
	sortable: boolean;
	searchFormatter: (value: string) => string;
	formatter: FormatterType;
};

/**
 * Texto será formatado usando os parâmetros:
 * {posIni} posição inicial da exibição da tabela dentro da lista
 * {posEnd} posição final da exibição da tabela dentro da lista
 * {total} total de itens na lista
 *
 * Exemplo de texto: "Exibindo {posIni} a {posEnd} de {total} itens"
 */
export type PaginationConfig = {
	text: string;
	rowsOptions: number[];
};

export type DatagridConfig = {
	sortByKey?: string;
	sortByDesc?: boolean;
	updateOnFilterChange?: boolean;
	columns: Array<Partial<ColumnConfig>>;
	paginate?: PaginationConfig;
};

export type DatagridState = {
	searchColumns: { key: string; text: string }[];
	filters: { key: string; query: string; operator?: QueryOperator }[];
	params: { [key: string]: string | number | null };
	page: number;
	rowsPerPage: number;
	sortByKey?: string;
	sortByDesc?: boolean;
	disabled: boolean;
	lastAction: DatagridUpdateAction;
};

export type DatagridUpdateListenerFn = (
	state: DatagridState,
	action: DatagridUpdateAction
) => void;

export type DatagridContextType = {
	config: Required<DatagridConfig>;
	tableParams: DatagridState;
	onSearchChange: (key: string, search: string) => void;
	onQueryChange: (key: string, query: string, operator?: QueryOperator) => void;
	onParamChange: (key: string, value: string | number | null) => void;
	onOrderByChange: (key: string, orderByDesc: boolean) => void;
	onPageChange: (page: number) => void;
	onRowsPerPageChange: (rowsPerPage: number) => void;
	forceUpdate: () => void;
	listenForUpdates: (fnc: DatagridUpdateListenerFn) => void;
	setDisabled: (disable?: boolean) => void;
};

export type DatagridData = {
	isLoading: boolean;
	data: unknown[] | null;
	error: HttpBaseResult | null;
	pagination: Pagination | null;
};
