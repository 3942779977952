import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';

export type FormCancelButtonProps = LinkProps & {
	hasEditPermission?: boolean;
	isSubmitting?: boolean;
};

const FormCancelButton: React.FC<FormCancelButtonProps> = ({
	hasEditPermission = true,
	isSubmitting,
	className,
	...rest
}) => {
	const label = hasEditPermission ? 'Cancelar' : 'Voltar';
	const classes = classNames(
		'btn btn-outline-primary',
		hasEditPermission && 'mr-3',
		isSubmitting && 'disabled',
		className
	);

	return (
		<>
			{isSubmitting ? (
				<div className={classes}>{label}</div>
			) : (
				<Link className={classes} {...rest}>
					{label}
				</Link>
			)}
		</>
	);
};

export default FormCancelButton;
