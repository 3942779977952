/* eslint-disable no-nested-ternary */
import React, {
	useCallback,
	ChangeEvent,
	useState,
	useRef,
	useMemo,
} from 'react';

import Button from 'react-bootstrap/Button';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import AspectRatio, { AspectRatioProps } from './AspectRatio';
import uploadHelpers from './upload/Helpers';
import {
	Container,
	ActionPictureButtons,
	UploadItem,
	UploadItems,
	PictureBox,
} from './upload/styles';
import {
	UploadFile,
	UploadType,
	UploadListType,
	PictureBoxProps,
	NewContainerProps,
} from './upload/types';

export interface UploadProps {
	name: string;
	error?: string;
	id?: string;
	value?: UploadFile[];
	type?: UploadType;
	listType?: UploadListType;
	disabled?: boolean;
	accept?: string;
	maxSize?: number;
	multiple?: boolean;
	showUploadList?: boolean;
	width?: string | number;
	height?: string | number;
	aspect?: AspectRatioProps['aspect'];
	minHeight?: AspectRatioProps['minHeight'];
	objectFit?: PictureBoxProps['objectFit'];
	displayInline?: NewContainerProps['displayInline'];
	onClick?: (event: React.MouseEvent) => void;
	style?: React.CSSProperties;
	className?: string;
	children?: React.ReactNode & { type?: { displayName?: string } };
	onChange?: (value: UploadFile[]) => void;
}

const defaultProps: Partial<UploadProps> = {
	type: 'select',
	listType: 'text',
	accept: '',
	showUploadList: true,
	aspect: 'fill',
	minHeight: '10rem',
};

const createBase64 = (file: Blob) => {
	return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (_) => resolve(reader.result);
		reader.onerror = (e) => reject(e);
	});
};

const Upload: React.FC<UploadProps> = (props) => {
	const {
		error,
		multiple,
		type,
		children,
		onClick,
		disabled,
		accept,
		maxSize,
		listType,
		displayInline,
		width,
		height,
		aspect,
		minHeight,
		objectFit,
		showUploadList,
		style,
		className,
		value,
		onChange,
		...rest
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState<UploadFile[]>(value || []);
	const [dragState, setDragState] = useState<string>('drop');

	const filesToUploadFiles = useCallback(
		async (fileItems: FileList) => {
			const filesArray = Array.prototype.slice.call(fileItems);

			const filesMapped = filesArray.map(
				async (file: File, idx: number): Promise<UploadFile> => {
					if (accept) {
						const fileAccept = uploadHelpers.attrAccept(file, accept);
						if (!fileAccept) {
							return {
								uid: uploadHelpers.uid(idx),
								name: '',
								size: file.size,
								preview: null,
								url: '',
								type: file.type,
								icon: uploadHelpers.getIconForFile(file),
								originFileObj: file,
								error: `Tipo de arquivo não aceito, adicione arquivos do tipo: ${accept}`,
							};
						}
					}

					if (maxSize) {
						const fileMaxSizeAccept = uploadHelpers.maxFileAccept(
							file,
							maxSize
						);
						if (!fileMaxSizeAccept) {
							return {
								uid: uploadHelpers.uid(idx),
								name: '',
								size: file.size,
								preview: null,
								url: '',
								type: file.type,
								icon: uploadHelpers.getIconForFile(file),
								originFileObj: file,
								error: `Tamanho do arquivo inválido, adicione arquivos de no máximo: ${maxSize}MB`,
							};
						}
					}

					return {
						uid: uploadHelpers.uid(idx),
						name: file.name,
						fileName: file.name,
						size: file.size,
						preview: uploadHelpers.attrAccept(file, 'image/*')
							? URL.createObjectURL(file)
							: null,
						url: (await createBase64(file)) as string | null,
						icon: uploadHelpers.getIconForFile(file),
						type: file.type,
						lastModified: file.lastModified,
						lastModifiedDate: new Date(file.lastModified),
						originFileObj: file,
						error: null,
					};
				}
			);

			const result = await Promise.all(filesMapped);

			return result;
		},
		[accept, maxSize]
	);

	// Proteção contra cache no navegador
	const isoDate = useMemo(() => new Date().toISOString(), []);

	const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const newFiles = e.target.files;
		if (!newFiles) {
			setFiles([]);
			onChange?.([]);
			return;
		}

		const filesChanged = await filesToUploadFiles(newFiles);

		if (multiple === false) {
			setFiles(filesChanged);
			onChange?.(filesChanged);
			return;
		}

		const concatFiles = filesChanged.concat(files || []);
		setFiles(concatFiles);
		onChange?.(concatFiles);
	};

	const onFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragState(e.type);

		if (e.type === 'dragover' || e.type === 'dragleave') return;

		const filesChanged = await filesToUploadFiles(e.dataTransfer.files);

		if (multiple === false) {
			setFiles(filesChanged);
			setDragState('drop');
			return;
		}

		const concatFiles = filesChanged.concat(files || []);
		setFiles(concatFiles);
		onChange?.(concatFiles);
		setDragState('drop');
	};

	const handleOnClick = (e: React.MouseEvent) => {
		const el = inputRef.current;

		if (!el) {
			return;
		}

		if (children && children?.type?.displayName === 'Button') {
			el.focus();
		}
		el.click();
		if (onClick) {
			onClick(e);
		}
	};

	const removeFile = useCallback(
		(index: number) => {
			const itemsRemoved = [...files];
			itemsRemoved.splice(index, 1);
			setFiles(itemsRemoved);
			onChange?.(itemsRemoved);

			if (inputRef && inputRef.current) {
				inputRef.current.value = '';
			}
		},
		[files, onChange]
	);

	return (
		<Container
			displayInline={displayInline}
			disabled={disabled}
			style={style}
			className={className}
			width={width}
			height={height}
		>
			<input
				type="file"
				ref={inputRef}
				onClick={(e: React.MouseEvent) => e.stopPropagation()}
				style={{ display: 'none' }}
				multiple={multiple}
				accept={accept}
				onChange={onChangeFile}
				{...rest}
			/>

			{type === 'drag' && (
				<AspectRatio
					aspect={aspect}
					minHeight={minHeight}
					onDrop={onFileDrop}
					onDragOver={onFileDrop}
					onDragLeave={onFileDrop}
				>
					<PictureBox
						dragHover={dragState === 'dragover'}
						onClick={handleOnClick}
						objectFit={objectFit}
					>
						{children}
					</PictureBox>
				</AspectRatio>
			)}

			{type === 'select' && (listType === 'text' || listType === 'picture') && (
				<span
					role="button"
					tabIndex={0}
					aria-hidden="true"
					onClick={handleOnClick}
				>
					<div>{children}</div>
				</span>
			)}

			{type === 'select' &&
				listType === 'picture-card' &&
				(files && files[0] ? (
					<>
						<AspectRatio aspect={aspect} minHeight={minHeight}>
							<PictureBox
								hasError={!!files[0].error || !!error}
								objectFit={objectFit}
							>
								{files[0].error || error ? (
									<p>{files[0].error || error}</p>
								) : files[0].preview ? (
									<img src={files[0].preview} alt={files[0].name} />
								) : (
									files[0].url && (
										<img
											src={`${files[0].url}?d=${isoDate}`}
											alt={files[0].name}
										/>
									)
								)}
							</PictureBox>
						</AspectRatio>

						<ActionPictureButtons>
							{(!files[0].error || !!error) && files[0].preview && (
								<Button
									href={files[0].preview}
									as="a"
									size="sm"
									variant="info"
									target="_blank"
									title="Visualizar"
									className="mr-2"
								>
									<FAIcon icon="eye" fixedWidth />
								</Button>
							)}

							<Button
								type="button"
								variant="danger"
								size="sm"
								onClick={() => removeFile(0)}
								title="Remover"
							>
								<FAIcon icon={faTrash} fixedWidth />
							</Button>
						</ActionPictureButtons>
					</>
				) : (
					<AspectRatio aspect={aspect} minHeight={minHeight}>
						<PictureBox onClick={handleOnClick} objectFit={objectFit}>
							{children}
						</PictureBox>
					</AspectRatio>
				))}

			{showUploadList && files && (
				<UploadItems>
					{files.map((file, index) => (
						<UploadItem
							key={file.uid}
							hasError={!!file.error || !!error}
							picture={listType === 'picture'}
						>
							{file.preview && listType === 'picture' ? (
								<a
									href={file.preview}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={file.preview} alt={file.name} />
								</a>
							) : (
								<FAIcon icon={file.icon as IconProp} />
							)}

							<span>{file.error || error || file.name}</span>

							<Button
								type="button"
								variant="light"
								onClick={() => removeFile(index)}
							>
								<FAIcon icon={faTrash} />
							</Button>
						</UploadItem>
					))}
				</UploadItems>
			)}
		</Container>
	);
};

Upload.displayName = 'Upload';
Upload.defaultProps = defaultProps;
Upload.whyDidYouRender = true;

export default Upload;
