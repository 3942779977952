import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card, { CardProps } from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Checkbox } from 'components';
import { currencyWithSymbolFormatter } from 'utils/formatters';

import {
	ProductCard,
	Text,
	Paragraph,
	ProductPrice,
	FixedWidthText,
} from 'packages/ecommerce/components';
import { useShoppingCartView } from 'packages/ecommerce/hooks';

type OrderSummaryProps = CardProps & {
	className?: string;
	onSubmitOrder: () => void;
	onTermsCheckboxChange: () => void;
	termsCheckboxValue: boolean;
	enableSubmit: boolean;
};

const OrderSummary: React.FC<OrderSummaryProps> = ({
	// TODO:
	// Finalizar fechamento de pedido
	onSubmitOrder: _,
	onTermsCheckboxChange,
	termsCheckboxValue,
	enableSubmit,
	...props
}) => {
	const cartView = useShoppingCartView();

	return (
		<>
			<Card {...props} className={classNames('mb-2', props.className)}>
				<Card.Body className="pt-0 pb-3 border">
					<Paragraph className="py-2 border-bottom">Resumo do Pedido</Paragraph>
					<Row noGutters className="align-items-end mt-2">
						<Col>
							<Text>Subtotal:</Text>
						</Col>
						<Col className="col-auto text-right pl-1">
							<FixedWidthText width="12rem">
								{cartView.isLoading
									? '-,--'
									: currencyWithSymbolFormatter.format(cartView.subtotal)}
							</FixedWidthText>
						</Col>
					</Row>
					<Row noGutters className="align-items-end mb-2">
						<Col>
							<Text>Frete:</Text>
						</Col>
						<Col className="col-auto text-right pl-1">
							<FixedWidthText width="12rem">A Calcular</FixedWidthText>
						</Col>
					</Row>
					<hr />
					<Row noGutters className="align-items-center pb-4">
						<Col>
							<Text>
								<b>Total:</b>
							</Text>
						</Col>
						<Col className="col-auto text-right pl-1">
							<FixedWidthText width="12rem">
								<ProductPrice>
									{cartView.isLoading
										? '-,--'
										: currencyWithSymbolFormatter.format(cartView.subtotal)}
								</ProductPrice>
							</FixedWidthText>
						</Col>
					</Row>
					<Checkbox
						checked={termsCheckboxValue}
						onChange={onTermsCheckboxChange}
						label="
						Declaro que li e concordo com os Termos e Condições"
					/>
					<Button
						variant="success"
						block
						size="lg"
						className="mt-2"
						disabled={!enableSubmit}
					>
						Fechar Pedido
					</Button>
				</Card.Body>
			</Card>

			<Card {...props} className={classNames('mb-2', props.className)}>
				<Card.Body className="pt-0 pb-2 border">
					<Paragraph className="py-2 border-bottom">Produtos</Paragraph>
					{cartView.items.map((item) => (
						<Row key={item.id}>
							<Col>
								{cartView.isLoading ? (
									<ProductCard.Small.Skeleton />
								) : (
									<ProductCard.Small
										className="py-2 border-bottom"
										showInput={false}
										showRemove={false}
										showTotalPrice
										item={item}
										product={cartView.productMap[item.id]}
									/>
								)}
							</Col>
						</Row>
					))}
					<Link to="/loja" className="btn btn-link btn-block mt-2 mb-1">
						<FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Continuar
						Comprando
					</Link>
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderSummary;
