import { RouteDef } from 'types';

import MainRouter from './pages/MainRouter';

const EcommerceRoutes: RouteDef[] = [
	{
		path: '/loja',
		component: MainRouter,
	},
];

export default EcommerceRoutes;
