import RegexFormatter, { REGEX_CLEAR_NUMERIC } from './Regex';

class CPFFormatter extends RegexFormatter {
	constructor() {
		super(
			/^(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/g,
			(_: string, $1: string, $2: string, $3: string, $4: string) => {
				let result: string = $1;
				result += $2 ? `.${$2}` : '';
				result += $3 ? `.${$3}` : '';
				result += $4 ? `-${$4}` : '';
				return result;
			},
			{
				clear: REGEX_CLEAR_NUMERIC,
				maxLength: 11,
			}
		);
	}

	formatForSearch = (value: string) => value.trim().replace(/[.\-/]/g, '');
}

export default CPFFormatter;
