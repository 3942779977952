import executiveService from 'services/executive.service';
import {
	numberFormatter,
	cpfCnpjFormatter,
	editLinkFormatter,
	buttonLinkFormatter,
} from 'utils/formatters';

import { BreadcrumbItem } from 'packages/admin/components/pageHeader/Breadcrumb';

import { ListSceneConfig } from '../types';

import paginateConfig from './paginate';

const adminBreadcrumb: BreadcrumbItem[] = [
	{
		label: 'Executivo',
		path: '/admin/executive',
	},
];

const ScenesConfig: { [name: string]: ListSceneConfig } = {
	Distributors: {
		title: 'PA Básico',
		breadcrumbItems: [
			...adminBreadcrumb,
			{
				label: 'PA Básico',
				path: `${adminBreadcrumb[0].path}/distributors`,
			},
		],
		action: executiveService.getExecutives,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/distributors`,
					}),
				},
				{
					key: 'id',
					title: 'ID',
					searchable: true,
					formatter: numberFormatter,
				},
				{ key: 'name', title: 'Nome', searchable: true },
				{ key: 'email', title: 'E-mail', searchable: true },
				{
					key: 'cpfCnpj',
					title: 'CPF/CNPJ',
					searchable: true,
					formatter: cpfCnpjFormatter,
				},
				{ key: 'sponsor', title: 'Patrocinador', searchable: true },
				{
					key: 'idStatus',
					title: 'Status',
					searchable: false,
					className: 'text-center',
				},
				{
					key: '',
					title: 'Estoque',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter({
						label: 'Ver Estoque',
						link: '',
					}),
				},
			],
		},
	},

	Exclusives: {
		title: 'PA Exclusivo',
		breadcrumbItems: [
			...adminBreadcrumb,
			{ label: 'PA Exclusivo', path: `${adminBreadcrumb[0].path}/exclusives` },
		],
		action: executiveService.getExecutives,
		datatableConfig: {
			paginate: paginateConfig,
			columns: [
				{
					key: 'idPerson',
					title: '',
					searchable: false,
					className: 'text-center',
					formatter: editLinkFormatter({
						link: `${adminBreadcrumb[0].path}/exclusives`,
					}),
				},
				{
					key: 'id',
					title: 'ID',
					searchable: true,
					formatter: numberFormatter,
				},
				{ key: 'name', title: 'Nome', searchable: true },
				{ key: 'email', title: 'E-mail', searchable: true },
				{
					key: 'cpfCnpj',
					title: 'CPF/CNPJ',
					searchable: true,
					formatter: cpfCnpjFormatter,
				},
				{ key: 'sponsor', title: 'Patrocinador', searchable: true },
				{
					key: 'idStatus',
					title: 'Status',
					searchable: false,
					className: 'text-center',
				},
				{
					key: '',
					title: 'Estoque',
					searchable: false,
					className: 'text-center',
					formatter: buttonLinkFormatter({
						label: 'Ver Estoque',
						link: '',
					}),
				},
			],
		},
	},
};

export default ScenesConfig;
