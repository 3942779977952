/* eslint-disable no-plusplus */
import React, {
	useState,
	useMemo,
	useRef,
	useEffect,
	useCallback,
} from 'react';

import { useField } from 'formik';
import {
	OverlayTrigger,
	InputGroup,
	FormControl,
	Form,
	Tooltip,
} from 'react-bootstrap';
import { useQuery, ArrayQueryKey } from 'react-query';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

import { LoadingSpinnerOverlay, Empty } from 'components';
import { useToast, useThrottleChange } from 'hooks';
import ecommerceService from 'services/ecommerce.service';
import productService from 'services/product.service';
import { EcImage, EcProductDetails, FeatureProduct } from 'types';
import { ProductSelect } from 'types/product.types';
import { deepEqual } from 'utils';
import Sort from 'utils/Sorters';

import { FormGroupTitle } from 'packages/admin/components';

import {
	Grid,
	Container,
	ListGroup,
	StyledPopover,
	StyledCol,
	StyledRow,
} from './featureGrid/styles';
import { FeatureGridProps, GridItem, GridLayout } from './featureGrid/types';
import {
	convertToMode1,
	gridDataObjProcessor,
	convertStrToLayoutMode1,
	convertToMode2,
	getInputEvents,
} from './featureGrid/utils';

const FeatureGrid: React.FC<FeatureGridProps> = ({ name, gridLayout }) => {
	const [toast] = useToast();
	const [activeFeatureItem, setActiveFeatureItem] = useState<GridItem | null>(
		null
	);
	const [selectedProduct, setSelectedProduct] = useState<ProductSelect | null>(
		null
	);
	const searchProductRef = useRef<HTMLInputElement | null>(null);
	const [searchProduct, setSearchProduct] = useState('');
	const delayedSearchProduct = useThrottleChange(searchProduct, 500);

	const [{ value: rawValue }, { error, touched }, { setValue }] = useField<
		FeatureProduct[]
	>(name);
	const value = useMemo(
		() => convertToMode1(rawValue, gridLayout as GridLayout),
		[rawValue, gridLayout]
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const initialGridLayout = useMemo(() => gridLayout, [rawValue]);

	const { data: searchList } = useQuery<ProductSelect[], ArrayQueryKey>(
		['feature_grid_products'],
		() => productService.getForSelect({}).then((r) => r.data),
		{
			onError: () => {
				toast('Não foi possível carregar a lista de produtos', {
					type: 'error',
				});
			},
		}
	);

	const {
		data: productDetails,
		isLoading: isLoadingProdDetail,
		isError: isErrorProdDetail,
	} = useQuery<EcProductDetails, ArrayQueryKey>(
		['feature_product_details', selectedProduct?.idProduct],
		() => ecommerceService.getProductDetails(+selectedProduct!.idProduct),
		{
			enabled: selectedProduct !== null,
			onError: () => {
				toast('Não foi possível carregar as imagens do produto', {
					type: 'error',
				});
			},
		}
	);

	// Dados processados para exibição do grid
	const data = useMemo(
		() => gridDataObjProcessor(value, convertStrToLayoutMode1(gridLayout)),
		[value, gridLayout]
	);

	const filteredProducts = useMemo(
		() =>
			searchList?.filter((p) =>
				delayedSearchProduct === ''
					? false
					: p.name.toLowerCase().includes(delayedSearchProduct.toLowerCase()) ||
					  // eslint-disable-next-line no-restricted-globals
					  (!isNaN(Number(delayedSearchProduct)) &&
							p.idProduct.toString().includes(delayedSearchProduct))
			),
		[searchList, delayedSearchProduct]
	);

	// Atualiza o valor do formulário ao alterar o formato do grid
	useEffect(() => {
		const featureProducts: FeatureProduct[] = [];
		data.forEach((column) =>
			column.gridItems.forEach(
				({ itemKey: _itemKey, ...product }) =>
					product.idProduct && featureProducts.push(product as FeatureProduct)
			)
		);

		const rawValueConverted = convertToMode1(
			rawValue,
			initialGridLayout as GridLayout
		);
		if (
			!deepEqual(
				rawValueConverted.sort(Sort.Numerically('sort')),
				featureProducts.sort(Sort.Numerically('sort'))
			)
		) {
			setValue(convertToMode2(featureProducts, gridLayout as GridLayout));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, gridLayout]);

	// Atualiza o valor do formulário ao selecionar imagem
	const changeItemImage = useCallback(
		(image: EcImage) => {
			if (image && activeFeatureItem && productDetails) {
				const alreadyExists = value.find(
					(p) => p.idProduct === String(productDetails.idProduct)
				);

				if (alreadyExists) {
					toast('Este produto já está no grid.', {
						type: 'info',
					});
					return;
				}

				const newProduct: FeatureProduct = {
					idProduct: String(productDetails.idProduct),
					idImage: image.idImage,
					sort: String(activeFeatureItem.itemKey + 1),
					size: activeFeatureItem.size!,
				};

				const newValue = value.find((p) => p.sort === newProduct.sort)
					? // Substitui um item da array
					  value.map(
							(product) =>
								[newProduct].find((o) => o?.sort === product.sort) || product
					  )
					: // Acrescenta um item na array
					  [...value, newProduct].sort(Sort.Numerically('sort'));

				// Ativa o novo item no lugar do antigo
				setActiveFeatureItem({
					itemKey: activeFeatureItem.itemKey,
					...newProduct,
				});
				setValue(convertToMode2(newValue, gridLayout));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeFeatureItem, gridLayout, productDetails, setValue, value]
	);

	// Remove item do grid
	const removeItem = useCallback(
		(gridItem: GridItem) => {
			const newValue = value.filter((p) => p.idProduct !== gridItem.idProduct);
			setValue(convertToMode2(newValue, gridLayout));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value]
	);

	// Seleção automática do produto atual na lista de produtos
	useEffect(() => {
		const activeItemInFilteredProducts = filteredProducts?.find(
			(p) => p.idProduct === activeFeatureItem?.idProduct
		);
		if (activeItemInFilteredProducts) {
			setSelectedProduct(activeItemInFilteredProducts);
		} else {
			setSelectedProduct(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredProducts]);

	// Handlers

	const handleEnteringGridItem = (gridItem: GridItem) => () => {
		setActiveFeatureItem(gridItem);
	};

	const handleEnteredGridItem = (_gridItem: GridItem) => () => {
		const searchField = getInputEvents(searchProductRef);
		searchField.focus();

		const product = searchList?.find(
			(p) => p.idProduct === activeFeatureItem?.idProduct ?? ''
		);

		searchField.setValue(product?.name ?? '');
		searchField.select();
	};

	const handleExitingGridItem = () => {
		// Limpa o state
		// TODO: Melhorar chamando a alteração do state uma só vez, agrupando propriedades ou usando um useReducer
		setActiveFeatureItem(null);
		setSelectedProduct(null);
		setSearchProduct('');
	};

	const handleChangeSearchProduct = (
		event: React.ChangeEvent<HTMLInputElement>
	) => setSearchProduct(event.target.value);

	const popover = (gridItem: GridItem) => (
		<StyledPopover id={`popover_${gridItem.itemKey}`}>
			<StyledRow>
				<StyledCol numRows={2} sm={8} className="pr-0">
					<Form.Group className="pr-2 mb-0 text-center">
						<FormGroupTitle>Produtos</FormGroupTitle>
						<InputGroup>
							{/* TODO: Criar um Input que funciona igual ao FormControl e aceita autocomplete="off" */}
							<FormControl
								name="searchProduct"
								placeholder="Pesquisar por nome ou código do produto"
								onChange={handleChangeSearchProduct}
								value={searchProduct}
								ref={(r: HTMLInputElement | null) => {
									searchProductRef.current = r;
								}}
							/>
							<InputGroup.Append>
								<InputGroup.Text>
									<FAIcon icon="search" />
								</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<Container>
						<ListGroup height="21rem">
							{filteredProducts?.map((product) => (
								<ListGroup.Item
									key={product.idProduct}
									selected={product.idProduct === selectedProduct?.idProduct}
									onClick={() => setSelectedProduct(product)}
								>
									{product.name}
								</ListGroup.Item>
							))}
						</ListGroup>
					</Container>
				</StyledCol>
				<StyledCol numRows={2} sm={4} className="pl-0">
					<Form.Group className="pr-2 mb-1 text-center">
						<FormGroupTitle>Imagens</FormGroupTitle>
					</Form.Group>
					<Container selected={!!selectedProduct}>
						<ListGroup align="center">
							{isLoadingProdDetail && <LoadingSpinnerOverlay />}
							{isErrorProdDetail ? (
								<Empty />
							) : (
								<>
									{productDetails?.image.map((item) => (
										<ListGroup.ImageWrapper
											key={item.idImage}
											selected={
												item.idImage === activeFeatureItem?.idImage ?? ''
											}
											onClick={() => changeItemImage(item)}
										>
											{item.idImage && <img src={item.idImage} alt="" />}
										</ListGroup.ImageWrapper>
									))}
								</>
							)}
						</ListGroup>
					</Container>
				</StyledCol>
			</StyledRow>
		</StyledPopover>
	);

	return (
		<>
			<Grid columns={data.length} isInvalid={!!(error && touched)}>
				<Grid.Backdrop show={activeFeatureItem !== null} />
				{data.map(({ columnKey, gridItems }) => (
					<Grid.Group
						key={columnKey}
						columns={Math.ceil(Number(gridItems.length) / 2)}
					>
						{gridItems.map((gridItem) => (
							<OverlayTrigger
								key={gridItem.itemKey}
								trigger="click"
								placement="auto"
								overlay={popover(gridItem)}
								onEntering={handleEnteringGridItem(gridItem)} // Faz a função do onClick
								onEntered={handleEnteredGridItem(gridItem)}
								onExiting={handleExitingGridItem}
								rootClose
							>
								<Grid.Item
									selected={gridItem.itemKey === activeFeatureItem?.itemKey}
									disabled={
										activeFeatureItem !== null &&
										activeFeatureItem.itemKey !== gridItem.itemKey
									}
								>
									{gridItem.idImage ? (
										<>
											<OverlayTrigger
												placement="top"
												overlay={
													<Tooltip id="tooltip-logout">
														{searchList?.find(
															(p) => p.idProduct === gridItem.idProduct
														)?.name ?? ''}
													</Tooltip>
												}
											>
												<img src={gridItem.idImage.toString()} alt="" />
											</OverlayTrigger>
											<Grid.RemoveButton
												onClick={(event) => {
													event.stopPropagation();
													removeItem(gridItem);
												}}
											/>
										</>
									) : (
										<span>Selecione</span>
									)}
								</Grid.Item>
							</OverlayTrigger>
						))}
					</Grid.Group>
				))}
			</Grid>
			{error && touched && (
				<span className="invalid-feedback d-block">{error}</span>
			)}
		</>
	);
};

FeatureGrid.displayName = 'FeatureGrid';
FeatureGrid.whyDidYouRender = true;

export default FeatureGrid;
