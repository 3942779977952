import React, { useCallback } from 'react';

import { FormikProps } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import useDatagridDataContext from 'components/datagrid/useDatagridDataContext';
import scoreService from 'services/score.service';
import { ExecutiveLightForSelect } from 'types';

import {
	FilterContainer,
	MonthSelectFilter,
	YearFilter,
	ExecutiveSelectFilter,
	FilterButton,
	ExportButton,
} from 'packages/admin/components/datatableView';
import {
	monthOptionsBR,
	MonthOption,
} from 'packages/admin/components/datatableView/MonthSelectFilter';

type ScoreByUserFiltersFormType = {
	id: ExecutiveLightForSelect | null;
	month: MonthOption;
	year: number;
};

const defaultFilterValues: ScoreByUserFiltersFormType = {
	id: null,
	month: monthOptionsBR[new Date().getMonth()],
	year: new Date().getFullYear(),
};

const validationSchema = Yup.object<ScoreByUserFiltersFormType>({
	id: Yup.object<ExecutiveLightForSelect>()
		.nullable()
		.required('Campo obrigatório'),
	month: Yup.object<MonthOption>().required('Campo obrigatório'),
	year: Yup.number().required('Campo obrigatório'),
});

const columnsExport: string[] = [
	'Data',
	'Pedido',
	'ID do Amigo',
	'Amigo',
	'Nível',
	'Pontos',
];

const ScoreByUserFilters: React.FC = () => {
	const { isLoading, pagination } = useDatagridDataContext();

	const genExportDataHeader = useCallback(
		(values: ScoreByUserFiltersFormType) => {
			const { id: executive, month, year } = values;
			let header = '';

			header += `Exibindo ${pagination?.per_page} de ${pagination?.total} registros | Página ${pagination?.current_page} de ${pagination?.last_page}\n`;
			header += `Executivo: ${executive?.id} - ${executive?.name} | ${month.name}/${year}\n`;

			return header;
		},
		[pagination]
	);

	return (
		<FilterContainer
			initialValues={defaultFilterValues}
			validationSchema={validationSchema}
		>
			{(props: FormikProps<ScoreByUserFiltersFormType>) => (
				<>
					<Form.Row>
						<FormGroup as={Col} lg={3} md={4} sm={4} xs={4}>
							<Form.Label>Executivo</Form.Label>
							<ExecutiveSelectFilter name="id" queryKey="idPerson" />
						</FormGroup>
						<FormGroup as={Col} lg={2} md={3} sm={4} xs={4}>
							<Form.Label>Mês</Form.Label>
							<MonthSelectFilter name="month" />
						</FormGroup>
						<FormGroup as={Col} lg={2} md={2} sm={3} xs={3}>
							<Form.Label>Ano</Form.Label>
							<YearFilter name="year" />
						</FormGroup>
						<FormGroup as={Col}>
							<br />
							<FilterButton className="m-1 mr-3" disabled={isLoading} />
							<ExportButton
								title="Pontuação x Executivo"
								filterData={genExportDataHeader(props.values)}
								to="csv"
								action={scoreService.getScoreByUser}
								columns={columnsExport}
								exportOnlyScreenData
							>
								<FontAwesomeIcon icon={faFileCsv} className="mr-2" /> Exportar
							</ExportButton>
						</FormGroup>
					</Form.Row>
				</>
			)}
		</FilterContainer>
	);
};

export default ScoreByUserFilters;
