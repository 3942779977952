import { SessionPerson, HttpBaseResult } from 'types';

export enum SessionActionEnum {
	LoginRequest = '@session/LOGIN_REQUEST',
	LoginFailed = '@session/LOGIN_FAILED',
	LoginSuccess = '@session/LOGIN_SUCCESS',
	ForgotRequest = '@session/FORGOT_REQUEST',
	ForgotFailed = '@session/FORGOT_SUCCESS',
	ForgotSuccess = '@session/FORGOT_FAILED',
	RedefineRequest = '@session/REDEFINE_REQUEST',
	RedefineFailed = '@session/REDEFINE_SUCCESS',
	RedefineSuccess = '@session/REDEFINE_FAILED',
	LoadLoggedRequest = '@session/LOADLOGGED_REQUEST',
	LoadLoggedSuccess = '@session/LOADLOGGED_SUCCESS',
	LoadLoggedFailed = '@session/LOADLOGGED_FAILED',
	LogoutRequest = '@session/LOGOUT_REQUEST',
}

export interface SessionState {
	user: SessionPerson | null;
	permissions: string[];
	lastError: HttpBaseResult | null;
}

export type SessionActionTypes =
	| {
			type: SessionActionEnum.LoginRequest;
			payload: {
				email: string;
				password: string;
				remember: boolean;
			};
	  }
	| {
			type: SessionActionEnum.LoginFailed;
			payload: HttpBaseResult;
	  }
	| {
			type: SessionActionEnum.LoginSuccess;
			payload: {
				user: SessionPerson | null;
				permissions: string[];
			};
	  }
	| {
			type: SessionActionEnum.ForgotRequest;
			payload: {
				email: string;
			};
	  }
	| {
			type: SessionActionEnum.ForgotFailed;
			payload: HttpBaseResult;
	  }
	| {
			type: SessionActionEnum.ForgotSuccess;
			payload: {
				message: string | null;
			};
	  }
	| {
			type: SessionActionEnum.RedefineRequest;
			payload: {
				email: string;
			};
	  }
	| {
			type: SessionActionEnum.RedefineFailed;
			payload: HttpBaseResult;
	  }
	| {
			type: SessionActionEnum.RedefineSuccess;
			payload: {
				message: string | null;
			};
	  }
	| {
			type: SessionActionEnum.LoadLoggedRequest;
			payload: {};
	  }
	| {
			type: SessionActionEnum.LoadLoggedFailed;
			payload: HttpBaseResult;
	  }
	| {
			type: SessionActionEnum.LoadLoggedSuccess;
			payload: {
				user: SessionPerson | null;
				permissions: string[];
			};
	  }
	| {
			type: SessionActionEnum.LogoutRequest;
			payload: { redirectTo: string };
	  };
