import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Col, Row } from 'react-bootstrap';

import * as Yup from 'yup';

import Card from 'components/Card';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import { Executive, HttpBaseResult } from 'types';

import { PageLayoutFormWrapper } from 'packages/admin/components';
import Followup from 'packages/admin/components/Followup';

import SkeletonForm from './executive/SkeletonForm';
import { ExecutiveFormType } from './executive/types';

export const defaultFormValue: ExecutiveFormType = {
	id: null,
	idUser: null,
	idStatus: 0,
	idSponsor: 0,
	idBillingAddress: 0,
	idSubscription: 0,
	idCountry: 31,
	idMmnCompany: 0,
	idMktCompany: 0,
	idClubCompany: 0,
	code: '',
	birthdate: '',
	cpfCnpj: '',
	nRegister: '',
	socialName: '',
	gender: '', // 'F' ou 'M'
	personType: '', // fisica ou juridica
	idType: null,
	name: '',
	email: '',
	imageUrl: '',
	changePass: false,
	sendmail: false,
	password: '',
};

export const formSchema = Yup.object<ExecutiveFormType>().shape({
	title: Yup.string().required('Campo obrigatório'),
	description: Yup.string().required('Campo obrigatório'),
	releaseDate: Yup.string()
		.transform((_, o) => (!o ? '' : o))
		.nullable(false)
		.required('Campo obrigatório'),
});

const ExecutiveEditPage = (): React.ReactElement => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			const executive: ExecutiveFormType = defaultFormValue;

			if (!isCreating) {
				// TODO: Reativar linha após o correto funcionamento da API
				// executive = await executiveService.getById(Number(id));
			}

			return { executive };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends ExecutiveFormType>(
			id: string,
			values: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const isCreating = id === 'create';

			const submit: Executive = {
				...values,
			};

			try {
				await executiveService.createOrUpdate(
					isCreating ? null : Number(id),
					submit
				);
				toast(`Executivo ${id ? 'alterado' : 'criado'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/executive/executives');
			} catch (e) {
				const { code } = e as HttpBaseResult;
				toast(`Não foi possível editar o Executivo. Cód: ${code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[toast, history]
	);

	return (
		<PageLayoutFormWrapper
			title="Executivo"
			breadcrumb={[
				{ label: 'Executivos', path: '/admin/executive/executives' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Card>
					<Card.Content>
						<Row>
							<Col md={12} lg={7}>
								<Formik
									onSubmit={(values, helpers) =>
										handleSubmit(id, values, helpers)
									}
									initialValues={data!.executive}
									validationSchema={formSchema}
								>
									<FormikForm />
								</Formik>
							</Col>
							<Col md={12} lg={5}>
								{id !== 'create' && <Followup idUser={Number(id)} />}
							</Col>
						</Row>
					</Card.Content>
				</Card>
			)}
		</PageLayoutFormWrapper>
	);
};

export default ExecutiveEditPage;
