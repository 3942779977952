import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { Card, Skeleton } from 'components';

import { ExpandScrollBox, Statement } from './styles';

const StatementApprovalSkeleton: React.FC = () => (
	<Row className="h-100">
		<Container fluid className="d-flex flex-column h-100">
			<ExpandScrollBox>
				<Container fluid className="d-flex flex-column">
					{[...Array(8).keys()].map((key) => (
						<Statement key={key}>
							<Statement.Body>
								<Row>
									<Col>
										<Row className="mb-0 pb-0">
											<Col xs={6} lg={2} className="my-1">
												<Skeleton width="10ch" height="1rem" />
											</Col>
											<Col xs={6} lg={5} className="my-1">
												<Skeleton width="10ch" height="1rem" />
											</Col>
											<Col xs={6} lg={3} className="my-1">
												<Skeleton width="10ch" height="1rem" />
											</Col>
											<Col xs={6} lg={2} className="my-1 text-right">
												<Skeleton width="10ch" height="1rem" />
											</Col>
										</Row>
										<Row className="border-top">
											<Col className="mt-3 d-flex justify-content-between">
												<Skeleton width="10ch" height="1rem" />
											</Col>
										</Row>
									</Col>
									<Col xs="auto">
										<Row className="h-100">
											<Col className="px-xs-0 px-md-3 px-xl-5 d-flex align-items-center justify-content-center">
												<Skeleton width="10ch" height="1.1rem" />
											</Col>
											<Col
												sm="auto"
												className="pr-3 pl-0 d-flex align-items-center justify-content-end"
											>
												<Skeleton width="2.1rem" height="2.1rem" />
												<Skeleton
													width="2.1rem"
													height="2.1rem"
													className="ml-2"
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Statement.Body>
						</Statement>
					))}
				</Container>
			</ExpandScrollBox>
			<Card className="mt-1">
				<Card.Header
					separator
					justifyContent="space-between"
					alignItems="center"
				>
					<Skeleton width="25ch" height="1.7rem" />
					<Skeleton width="10ch" height="2.4rem" />
				</Card.Header>
			</Card>
		</Container>
	</Row>
);

export default StatementApprovalSkeleton;
