import React from 'react';
import { Switch, Redirect, Router, Route } from 'react-router-dom';

import { ModalContainer } from 'components';
import { useRouteValidator } from 'hooks';
import history from 'utils/history';

import AdminRoutes from './admin/routes';
import EcommerceRoutes from './ecommerce/routes';
import EscritorioRoutes from './escritorio/routes';
import SessionRoutes from './session/routes';

const Routes = [
	...SessionRoutes,
	...AdminRoutes,
	...EscritorioRoutes,
	...EcommerceRoutes,
];

const RootRouter: React.FC = () => {
	const [routes] = useRouteValidator(Routes);

	return (
		<Router history={history}>
			<ModalContainer />
			<Switch>
				{routes.map(({ children: _, ...params }) => (
					<Route key={params.path} {...params} />
				))}
				<Redirect to="/login" />
			</Switch>
		</Router>
	);
};

export default RootRouter;
