import { ID } from 'types';

import { ShoppingCartState } from './types';

type Store = { shoppingCart: ShoppingCartState };

const getCart = (cartName: string) => (store: Store) =>
	store.shoppingCart.cart[cartName] || { items: [] };

const getCartItem = (cartName: string, id: ID) => (store: Store) =>
	store.shoppingCart.cart[cartName]?.items?.find((item) => item.id === id);

export default {
	getCart,
	getCartItem,
};
