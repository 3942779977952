import * as React from 'react';

function SvgAmakhaCrown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg height="1em" width="1em" viewBox="0 0 250 160" {...props}>
			<path d="M243.33 140.685c-3.286-.498-6.572-.996-9.857-1.394-26.982-3.584-64.317-5.575-107.925-5.575h-.797c-43.807 0-81.342 1.891-108.423 5.575-3.285.398-6.57.896-9.856 1.394l-2.19.398-.1.996 12.146 3.485H16.627c35.842-3.684 72.282-5.576 108.423-5.576h.697c35.842 0 72.083 1.892 107.626 5.576h.2l12.245-3.485-.1-.996zM188.47 50.88L125.549.7l-.598-.497-62.525 51.971L0 16.233v96.575c.498 3.186.996 5.675 1.394 8.064.498 2.39.896 4.78 1.394 7.965l.498 3.286h.995l3.385-22.402V84.333l54.66 32.358 62.126-50.578 63.023 49.283.598-.298c9.16-5.277 18.618-10.654 27.877-15.93 8.761-4.978 17.821-10.056 26.583-15.134v12.247c0 4.28-.1 9.06-.1 13.44l3.286 22.302h.995l.498-3.285c.498-3.186.996-5.576 1.394-7.965.498-2.39.996-4.879 1.394-8.164V16.233zm53.764-5.177l-54.66 31.262-62.624-50.179-.597-.498L62.027 78.16 7.567 46.798v-15.83l54.958 32.357 62.425-51.871 63.322 50.577 53.962-31.163zm-117.881-6.77l63.023 49.283.597-.299c8.96-5.177 45.798-26.583 54.36-31.561 0 1.792 0 4.879-.099 7.567 0 2.39-.1 4.978-.1 7.069l-54.56 33.253-62.624-50.179-.597-.498-62.326 51.872-54.46-31.362V57.053l54.66 32.358zM241.936 155.221c-3.286-.498-6.572-.996-9.857-1.394-26.981-3.584-64.317-5.575-107.925-5.575h-.797c-43.807 0-81.342 1.891-108.423 5.575-3.285.398-6.57.896-9.856 1.394l-2.29.299-.1.995L14.835 160H15.134c35.842-3.684 72.281-5.575 108.422-5.575h.697c35.843 0 72.083 1.891 107.627 5.575h.199l12.246-3.485-.1-.995z" />
			<path d="M53.664 26.189l7.168 11.051 7.169-11.051-7.169-8.563zM180.207 26.189l7.169 11.051 7.168-11.051-7.168-8.563z" />
		</svg>
	);
}

export default SvgAmakhaCrown;
