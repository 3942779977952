import React from 'react';

import { SideBar } from 'components';

import NavBar from 'packages/admin/components/NavBar';

const Skeleton: React.FC = () => (
	<>
		<NavBar.Skeleton />
		<SideBar.Skeleton menuOpened />
	</>
);

export default Skeleton;
