import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

const NavbarMMN: React.FC = () => (
	<Navbar variant="dark" bg="dark" className="p-0">
		<Container fluid>
			<Navbar.Text className="text-white mr-3">FALE CONOSCO</Navbar.Text>
			<Navbar.Text className="text-white">
				<FAIcon icon={faPhone} className="text-primary mr-1" /> (11) 2388-8203
			</Navbar.Text>
			<Navbar.Text className="mx-2">|</Navbar.Text>
			<Navbar.Text className="text-white">
				<FAIcon fixedWidth icon={faEnvelope} className="text-primary mr-1" />{' '}
				sac@amakhaparis.com.br
			</Navbar.Text>
			<Nav className="ml-auto">
				<Nav.Link as={Link} to="/app" className="btn btn-primary text-dark">
					Escritório Virtual
				</Nav.Link>
			</Nav>
		</Container>
	</Navbar>
);

NavbarMMN.displayName = 'NavbarMMN';
NavbarMMN.whyDidYouRender = true;

export default NavbarMMN;
