import { Link } from 'react-router-dom';

import BsBreadcrumb from 'react-bootstrap/Breadcrumb';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export const Title = styled.h1.attrs((props) => ({
	className: classNames(
		props.className,
		'h3 my-0 font-weight-bold text-uppercase'
	),
}))``;

export const Subtitle = styled.h2.attrs((props) => ({
	className: classNames(props.className, 'h5 my-0 text-uppercase'),
}))``;

export const Paragraph = styled.p.attrs((props) => ({
	className: classNames(props.className, 'mb-2'),
}))``;

export const Text = styled.span``;

export const FixedWidthText = styled(Text).attrs(
	(props: { width: string }) => ({
		width: props.width,
	})
)`
	display: block;
	min-width: ${(props) => props.width};
`;

export const Small = styled.small``;

export const ProductPrice = styled.div.attrs((props) => ({
	className: classNames(props.className, 'h2 my-0 font-weight-bold'),
}))``;

export const CartSubtotal = styled.h4.attrs((props) => ({
	className: classNames(props.className, 'h4 font-weight-bold text-uppercase'),
}))``;

export const Breadcrumb = styled(BsBreadcrumb).attrs(
	(props: { color: string; className?: string }) => ({
		className: classNames(props.className, 'd-inline-flex'),
	})
)`
	.breadcrumb {
		font-size: 0.925rem;
		background: transparent;
		padding: 0.75rem 0;
	}
	a {
		color: ${({ color }) => color || 'var(--primary)'};
	}
`;

export const FilterTitle = styled.div.attrs((props) => ({
	className: classNames(props.className, 'h5 font-weight-light mb-3'),
}))``;

export const CategorySidebarTitle = styled.div.attrs((props) => ({
	className: classNames(props.className, 'h5 font-weight-light mb-3'),
}))``;

export const CategorySidebarTitleLink = styled(Link).attrs((props) => ({
	className: classNames(props.className, 'h5 font-weight-light mb-3 d-block'),
}))``;

export const CategorySidebarLink = styled(Link).attrs((props) => ({
	className: classNames(
		props.className,
		'd-flex w-100 mb-1 align-items-center'
	),
}))`
	font-size: 1rem;
	color: var(--gray-70);
	margin-left: 0.2rem;
`;

export const CategorySidebarLinkIcon = styled(FontAwesomeIcon).attrs(
	(props) => ({
		className: classNames(props.className, 'ml-auto text-muted'),
	})
)`
	font-size: 1rem;
`;

export const AddressTitle = styled.div.attrs((props) => ({
	className: classNames(props.className, 'h5 font-weight-light mb-3'),
}))``;

export const AddressText = Text;
