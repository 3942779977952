import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import {
	Paragraph,
	Title,
	Text,
	ProductPrice,
} from 'packages/ecommerce/components/Typography';

const ImageContainer = styled.div`
	display: block;
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	height: 0;
`;

const ProductDetailsSkeleton: React.FC = () => (
	<>
		<Container fluid className="mb-5 pb-5">
			<Skeleton width="10rem" />
			<header className="py-2">
				<Title>
					<Skeleton width="40rem" height="2rem" />
				</Title>
			</header>
			<Row>
				<Col md={9} lg={9}>
					<Row>
						<Col md={5}>
							<ImageContainer>
								<div className="position-absolute w-100 h-100">
									<Skeleton width="100%" height="100%" />
								</div>
							</ImageContainer>
						</Col>
						<Col md={7}>
							<Paragraph className="mx-0 mx-md-4 mx-lg-4 mx-xl-4">
								<Skeleton width="80%" />
								<Skeleton />
								<Skeleton width="30%" />
							</Paragraph>
						</Col>
					</Row>
				</Col>
				<Col md={3} lg={3}>
					<Text>
						<Skeleton width="3rem" />
					</Text>
					<ProductPrice>
						<Skeleton width="6rem" />
					</ProductPrice>

					<hr className="my-3" />

					<Skeleton height="3rem" />
					<Skeleton height="3rem" />
					<Skeleton height="3rem" />
				</Col>
			</Row>
		</Container>
	</>
);

export default ProductDetailsSkeleton;
