import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as Yup from 'yup';

import { Card } from 'components';
import {
	AsyncSelect,
	Input,
	ToggleButtonGroup,
	ToggleButton,
	FormikEffect,
} from 'components/Formik';
import { useToast } from 'hooks';
import activationService from 'services/activation.service';
import commissionService from 'services/commission.service';
import configService from 'services/mmnConfig.service';
import { Activation, CommissionList, MMNConfig } from 'types';
import { decimalFormatter } from 'utils/formatters';

import {
	FormCancelButton,
	FormSubmitButton,
	PageLayoutFormWrapper,
} from 'packages/admin/components';

import SkeletonForm from './config/SkeletonForm';
import { MMNConfigFormType } from './config/types';

export const defaultFormValue: MMNConfigFormType = {
	idConfig: null,
	name: '',
	comissionFactor: 0,
	scoreFactor: 0,
	plFactor: 0,
	isVip: 0,
	comission: null,
	activation: null,
};

export const formSchema = Yup.object().shape<MMNConfigFormType>({
	idConfig: Yup.mixed(),
	name: Yup.string().required('Campo obrigatório'),
	comissionFactor: Yup.number()
		.min(0, 'O campo deve ter um valor entre 0 e 1')
		.max(1, 'O campo deve ter um valor entre 0 e 1')
		.required('Campo obrigatório'),
	scoreFactor: Yup.number()
		.min(0, 'O campo deve ter um valor entre 0 e 1')
		.max(1, 'O campo deve ter um valor entre 0 e 1')
		.required('Campo obrigatório'),
	plFactor: Yup.number()
		.min(0, 'O campo deve ter um valor entre 0 e 1')
		.max(1, 'O campo deve ter um valor entre 0 e 1')
		.required('Campo obrigatório'),
	isVip: Yup.number().required('Campo obrigatório'),
	comission: Yup.object()
		.shape<CommissionList>({
			idComission: Yup.number(),
			name: Yup.string(),
		})
		.nullable()
		.required('Campo obrigatório'),
	activation: Yup.object()
		.shape<Activation>({
			idActivation: Yup.number(),
			name: Yup.string(),
			message: Yup.string(),
		})
		.nullable()
		.required('Campo obrigatório'),
});

const ConfigEdit: React.FC = () => {
	const [toast] = useToast();
	const history = useHistory();

	const loadData = useCallback(
		(id: string) => async () => {
			const isCreating = id === 'create';

			let config: MMNConfigFormType = defaultFormValue;

			if (!isCreating) {
				const {
					isVip,
					comissionFactor,
					scoreFactor,
					plFactor,
					...rest
				} = await configService.getById(Number(id));

				config = {
					...rest,
					isVip: isVip ? 1 : 0,
					comissionFactor: Number(comissionFactor),
					scoreFactor: Number(scoreFactor),
					plFactor: Number(plFactor),
				};
			}

			return { config };
		},
		[]
	);

	const handleSubmit = useCallback(
		async <T extends MMNConfigFormType>(
			id: string,
			{
				isVip,
				comissionFactor,
				scoreFactor,
				plFactor,
				comission,
				activation,
				...values
			}: T,
			{ setSubmitting }: FormikHelpers<T>
		) => {
			const isCreating = id === 'create';

			const submit: MMNConfig = {
				...values,
				isVip: !!isVip,
				comissionFactor: String(comissionFactor),
				scoreFactor: String(scoreFactor),
				plFactor: String(plFactor),
				idComission: comission?.idComission ?? null,
				idActivation: activation?.idActivation ?? null,
			};

			try {
				await configService.createOrUpdate(
					isCreating ? null : Number(id),
					submit
				);
				toast(`Configuração ${id ? 'alterada' : 'criada'} com sucesso`, {
					type: 'success',
				});
				history.push('/admin/mmn/config');
			} catch (e) {
				toast(`Não foi possível editar a configuração. Cód: ${e.code}`, {
					type: 'error',
				});
			}
			setSubmitting(false);
		},
		[toast, history]
	);

	return (
		<PageLayoutFormWrapper
			title="Configuração MMN"
			breadcrumb={[
				{ label: 'MMN', path: '/admin/mmn/config' },
				{ label: 'Configuração MMN', path: '/admin/mmn/config' },
			]}
			load={loadData}
			skeleton={SkeletonForm}
		>
			{(id, { data }) => (
				<Formik
					onSubmit={(values, helpers) => handleSubmit(id, values, helpers)}
					initialValues={data!.config}
					validationSchema={formSchema}
				>
					{(form: FormikProps<MMNConfigFormType>) => (
						<FormikForm>
							<FormikEffect focusOnError promptOnExit />

							<Card>
								<Card.Content className="offset-lg-2 col-lg-8">
									<Row>
										<Col sm={12}>
											<Form.Row>
												<Form.Group as={Col} sm={12}>
													<Form.Label>Nome</Form.Label>
													<Input name="name" />
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} md={4}>
													<Form.Label>Fator de Comissão</Form.Label>
													<Input
														name="comissionFactor"
														formatter={decimalFormatter(2)}
													/>
												</Form.Group>
												<Form.Group as={Col} md={4}>
													<Form.Label>Fator de Pontuação</Form.Label>
													<Input
														name="scoreFactor"
														formatter={decimalFormatter(2)}
													/>
												</Form.Group>
												<Form.Group as={Col} md={4}>
													<Form.Label>Fator de PL</Form.Label>
													<Input
														name="plFactor"
														formatter={decimalFormatter(2)}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} sm={6}>
													<Form.Label>Tabela de Comissão</Form.Label>
													<AsyncSelect
														name="comission"
														fetchMethod={commissionService.getList}
														optionValue="idComission"
														isClearable
													/>
												</Form.Group>
												<Form.Group as={Col} sm={6}>
													<Form.Label>Programa de Ativação</Form.Label>
													<AsyncSelect
														name="activation"
														fetchMethod={activationService.getList}
														optionValue="idActivation"
														isClearable
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} sm={6} className="d-inline mt-2">
													<span className="d-inline mr-2">
														Entra no volume pessoal (VIP):{' '}
													</span>
													<ToggleButtonGroup name="isVip">
														<ToggleButton variant="primary" size="sm" value={1}>
															Sim
														</ToggleButton>
														<ToggleButton variant="primary" size="sm" value={0}>
															Não
														</ToggleButton>
													</ToggleButtonGroup>
												</Form.Group>
											</Form.Row>
										</Col>
									</Row>
								</Card.Content>
								<Card.Content className="text-right border-top">
									<Form.Row>
										<Col>
											<FormCancelButton to="/admin/mmn/config" />
											<FormSubmitButton isSubmitting={form.isSubmitting} />
										</Col>
									</Form.Row>
								</Card.Content>
							</Card>
						</FormikForm>
					)}
				</Formik>
			)}
		</PageLayoutFormWrapper>
	);
};

export default ConfigEdit;
