import styled, { css } from 'styled-components';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';

export type CardHeaderProps = {
	separator?: boolean;
	direction?: 'row' | 'column';
	justifyContent?:
		| 'start'
		| 'end'
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'left'
		| 'right'
		| 'normal'
		| 'baseline'
		| 'first baseline'
		| 'last baseline'
		| 'space-between'
		| 'space-around'
		| 'space-evenly'
		| 'stretch'
		| 'safe'
		| 'unsafe';
	alignItems?:
		| 'stretch'
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'baseline'
		| 'initial'
		| 'inherit';
};

export type CardContentProps = {
	clipText?: boolean;
	width?: number;
	height?: number;
};

export type CardProps = {
	width?: number;
	height?: number;
	grow?: boolean;
};

const Header = styled.div<CardHeaderProps>`
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justifyContent }) => justifyContent};
	align-items: ${({ alignItems }) => alignItems};
	padding: var(--card-padding-y) var(--card-padding-x);

	${({ onClick, role }) =>
		(onClick || role === 'button') &&
		css`
			cursor: pointer;
			background: var(--gray-10);

			&:hover {
				background: var(--gray-20);
			}
		`}

	${({ separator }) =>
		separator &&
		css`
			border-bottom: var(--border-width) solid var(--border-color);
		`}
`;

Header.defaultProps = {
	direction: 'row',
	justifyContent: 'start',
	alignItems: 'flex-start',
};

const Title = styled.h1`
	font-size: 1.12rem;
	font-family: var(--font-family-secondary);
	font-weight: var(--font-weight-bold);
	margin-bottom: 0;
`;

const Content = styled.div<CardContentProps>`
	padding: var(--card-padding-y) var(--card-padding-x);

	${({ width }) => (width ? `width: ${width}%;` : '')}

	${({ clipText }) =>
		clipText &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: break-word;

			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		`}
`;

const CenteredIcon = styled(FAIcon)`
	position: absolute;
	font-size: 50px;
	color: var(--always-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 100%;
	transition: 0.3s;
	margin-bottom: var(--card-padding-y);
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};

	> ${CenteredIcon} {
		opacity: 0.7;
		transition: 0.3s;
	}

	&:hover {
		filter: brightness(110%);

		> ${CenteredIcon} {
			opacity: 1;
		}
	}
`;

const Card = styled.div<CardProps>`
	--card-padding-x: 1rem;
	--card-padding-y: 1rem;

	${({ width }) =>
		width &&
		css`
			width: ${width}%;
		`}

	${({ height }) =>
		height &&
		css`
			height: ${height}%;
		`}

	${({ grow }) =>
		grow &&
		css`
			flex-grow: 1;
		`}

	display: block;
	padding: 0;
	margin-bottom: var(--margin-bottom);

	background: var(--base-bg);
	color: var(--input-color);

	border-radius: var(--border-radius);
	box-shadow: var(--base-box-shadow);

	> :first-child ${ImageWrapper}, > ${ImageWrapper}:first-child {
		div,
		img {
			border-radius: var(--border-radius) var(--border-radius) 0 0;
		}
	}
`;

export default Object.assign(Card, {
	Header,
	Title,
	Content,
	CenteredIcon,
	ImageWrapper,
});
