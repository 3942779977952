import React, { useCallback, useState } from 'react';

import { Formik, Form as FormikForm, FormikValues, FormikProps } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import { faEye, faEyeSlash, faKey } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import localforage from 'localforage';
import * as Yup from 'yup';

import { ScoreBar } from 'components';
import {
	Checkbox,
	DateTimeInput,
	Input,
	Radio,
	ToggleButton,
	ToggleButtonGroup,
} from 'components/Formik';
import { useToast } from 'hooks';
import executiveService from 'services/executive.service';
import sessionService from 'services/session.service';
import { PersonJoinRegister, PersonType } from 'types';
import {
	cpfFormatter,
	cnpjFormatter,
	phoneFormatter,
	regexNumbersOnlyFormatter,
} from 'utils/formatters';
import history from 'utils/history';
import PasswordStrength from 'utils/PasswordStrength';
import ValidateCNPJ from 'utils/Validator/CNPJ';
import ValidateCPF from 'utils/Validator/CPF';

import { FormGroupTitle } from 'packages/admin/components';

const birthdateMin = new Date('1900-01-01');
const birthdateMax = new Date();
birthdateMax.setFullYear(birthdateMax.getFullYear() - 18);

export const defaultFormValue: PersonJoinRegister = {
	idSponsor: null,
	sponsor: null,
	idCountry: '31',
	personType: 0,
	name: '',
	email: '',
	gender: 'F',
	birthdate: '',
	cpfCnpj: '',
	cpf: '',
	cnpj: '',
	nRegister: '',
	password: '',
	confirmPassword: '',
	phone: {
		number: '',
	},
	terms: false,
	roles: [String(PersonType.Executive)],
	stateRegister: '',
};

export const formSchema = Yup.object<PersonJoinRegister>().shape({
	name: Yup.string()
		.trim()
		.required('Campo obrigatório')
		.matches(/^\S+\s+\S+.*/, 'Digite seu nome e sobrenome')
		.min(5, 'Digite seu nome e sobrenome')
		.max(128, 'Máximo 128 caracteres'),
	email: Yup.string()
		.trim()
		.required('Campo obrigatório')
		.matches(
			/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
			'E-mail inválido'
		)
		.max(128, 'Máximo 128 caracteres'),
	password: Yup.string().required('Digite sua senha'),
	confirmPassword: Yup.string()
		.oneOf(
			[Yup.ref('password'), ''],
			'A confirmação da senha não é igual a senha'
		)
		.required('Confirme sua senha'),
	gender: Yup.string().when('personType', {
		is: 0,
		then: Yup.string().required('Campo obrigatório'),
	}),
	birthdate: Yup.date().when('personType', {
		is: 0,
		then: Yup.date()
			.required('Campo obrigatório')
			.min(birthdateMin, 'Data inválida')
			.max(birthdateMax, 'Você precisa ter 18 anos para se cadastrar'),
	}),
	cpf: Yup.string().when('personType', {
		is: 0,
		then: Yup.string()
			.transform((value: string): string => {
				if (!ValidateCPF(value)) {
					return '';
				}
				return value;
			})
			.required('CPF Inválido'),
	}),
	cnpj: Yup.string().when('personType', {
		is: 1,
		then: Yup.string()
			.transform((value: string): string => {
				if (!ValidateCNPJ(value)) {
					return '';
				}
				return value;
			})
			.required('CNPJ Inválido'),
	}),
	nRegister: Yup.string().required('Campo obrigatório'),
	phone: Yup.object({
		number: Yup.string()
			.trim()
			.required('Campo obrigatório')
			.min(10, 'Digite seu telefone com DDD')
			.max(11, 'Digite seu telefone com DDD'),
	}),
	terms: Yup.bool()
		.required('Você precisa aceitar os termos.')
		.oneOf([true], 'Você precisa aceitar os termos.'),
});

const JoinScene: React.FC = () => {
	const [register] = useState<PersonJoinRegister>(defaultFormValue);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSponsorLoading, setIsSponsorLoading] = useState<boolean>(false);
	const [isSponsorConfirmed, setIsSponsorConfirmed] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean[]>([false, false]);
	const [toast] = useToast();

	const handleSubmit = useCallback(
		async (values: FormikValues) => {
			const submit = { ...values } as PersonJoinRegister;
			setIsLoading(true);

			if (!submit.idSponsor) {
				toast('Atenção, Você deve escolher um patrocinador', {
					type: 'error',
				});
				// eslint-disable-next-line no-useless-return
				return;
			}

			if (submit.personType === 0) {
				submit.cpfCnpj = submit.cpf || '';
				submit.cnpj = null;
			} else {
				submit.cpfCnpj = submit.cnpj || '';
				submit.cpf = null;
			}

			delete submit.personType;
			delete submit.sponsor;
			delete submit.terms;

			sessionService
				.register(submit)
				.then(async (result) => {
					const { data } = result;
					await localforage.setItem('joinaddress', {
						id: data.id,
						idPerson: data.idUser,
						email: submit.email,
					});
					history.push('/joinaddress');
				})
				.catch((e) => {
					setIsLoading(false);
					const remoteErrors: string[] = [];

					if (e.error.subMessage) {
						// eslint-disable-next-line guard-for-in
						for (const key in e.error.subMessage) {
							e.error.subMessage[key].forEach((msg: string) => {
								remoteErrors.push(`* ${msg}`);
							});
						}
						toast(
							`Encontramos inconsistências nos dados informados:\n${remoteErrors.join(
								'\n'
							)}`,
							{
								type: 'error',
							}
						);
					} else {
						toast('Não foi possível efetuar o cadastro', {
							type: 'error',
						});
					}
				});
		},
		[toast]
	);

	const onSearchSponsor = useCallback(
		(form: FormikProps<PersonJoinRegister>) => {
			const { idSponsor } = form.values;

			if (idSponsor) {
				setIsSponsorLoading(true);

				executiveService
					.findById(idSponsor)
					.then((result) => {
						setIsSponsorLoading(false);
						form.setFieldValue('sponsor', result);
					})
					.catch((e) => {
						setIsSponsorLoading(false);
						toast(`Não foi possível encontrar o patrocinador. Cód: ${e.code}`, {
							type: 'error',
						});
					});
			}
		},
		[toast]
	);

	return (
		<Container
			fluid
			className="h-100 d-flex justify-content-center align-items-start"
		>
			<Card
				className="bg-white"
				style={{ width: '100%', minWidth: 320, maxWidth: 1140 }}
			>
				<Card.Header className="p-0">
					<img
						src="assets/images/portal_registro_baner.jpg"
						className="img-fluid"
						alt="Banner de registro"
					/>
				</Card.Header>
				<Card.Body>
					<Formik
						onSubmit={handleSubmit}
						initialValues={register}
						validationSchema={formSchema}
					>
						{(form) => (
							<FormikForm>
								<div className="w-100 bg-light p-4 mb-2">
									<Col
										sm={12}
										md={{ span: 10, offset: 1 }}
										lg={{ span: 8, offset: 2 }}
									>
										<FormGroupTitle
											className={isSponsorConfirmed ? 'text-center' : ''}
										>
											Patrocinador{' '}
											{isSponsorConfirmed && (
												<strong>{form.values.sponsor?.name}</strong>
											)}
										</FormGroupTitle>

										{!form.values.sponsor && (
											<>
												<Form.Group>
													<Form.Label>
														Digite o código do seu patrocinador
													</Form.Label>
													<Input name="idSponsor" />
												</Form.Group>

												<Form.Group className="text-right">
													<Button
														variant="primary"
														type="button"
														disabled={
															!form.values.idSponsor || isSponsorLoading
														}
														onClick={() => onSearchSponsor(form)}
													>
														{isSponsorLoading ? (
															<>
																<Spinner
																	size="sm"
																	animation="border"
																	role="status"
																/>
																<span className="ml-2">Pesquisando...</span>
															</>
														) : (
															'Procurar'
														)}
													</Button>
												</Form.Group>
											</>
										)}

										{form.values.sponsor && !isSponsorConfirmed && (
											<>
												<Form.Group>
													<p>
														Confirmar{' '}
														<strong>{form.values.sponsor.name}</strong> como seu
														patrocinador?
													</p>
												</Form.Group>

												<Form.Group className="text-right">
													<Button
														variant="outline-primary"
														type="button"
														className="mr-3"
														onClick={() => form.setFieldValue('sponsor', null)}
													>
														Não
													</Button>

													<Button
														variant="primary"
														type="button"
														onClick={() => {
															setIsSponsorConfirmed(true);
														}}
													>
														Confirmar
													</Button>
												</Form.Group>
											</>
										)}
									</Col>
								</div>

								<Col
									sm={12}
									md={{ span: 10, offset: 1 }}
									lg={{ span: 8, offset: 2 }}
								>
									<FormGroupTitle className="mt-5">
										Dados de Acesso
									</FormGroupTitle>

									<Form.Group>
										<Form.Label>Nome</Form.Label>
										<Input
											name="name"
											type="text"
											disabled={isLoading || !isSponsorConfirmed}
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label>Email</Form.Label>
										<Input
											name="email"
											type="email"
											disabled={isLoading || !isSponsorConfirmed}
										/>
									</Form.Group>

									<FormGroupTitle className="mt-5">
										Senha de Acesso ao Escritório
									</FormGroupTitle>

									<Form.Group>
										<Form.Label>Senha</Form.Label>
										<Input
											disabled={isLoading || !isSponsorConfirmed}
											name="password"
											type={showPassword[0] ? 'text' : 'password'}
											prepend={
												<InputGroup.Text>
													<FAIcon fixedWidth icon={faKey} />
												</InputGroup.Text>
											}
											append={
												<Button
													disabled={isLoading || !isSponsorConfirmed}
													variant="outline-primary"
													onClick={() => {
														setShowPassword([
															!showPassword[0],
															showPassword[1],
														]);
													}}
												>
													<FAIcon icon={showPassword[0] ? faEye : faEyeSlash} />
												</Button>
											}
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label>Confirme a Senha</Form.Label>
										<Input
											disabled={isLoading || !isSponsorConfirmed}
											name="confirmPassword"
											type={showPassword[1] ? 'text' : 'password'}
											prepend={
												<InputGroup.Text>
													<FAIcon fixedWidth icon={faKey} />
												</InputGroup.Text>
											}
											append={
												<Button
													disabled={isLoading || !isSponsorConfirmed}
													variant="outline-primary"
													onClick={() => {
														setShowPassword([
															showPassword[0],
															!showPassword[1],
														]);
													}}
												>
													<FAIcon icon={showPassword[1] ? faEye : faEyeSlash} />
												</Button>
											}
										/>
									</Form.Group>

									<Form.Group>
										<ScoreBar
											score={PasswordStrength(
												form.values.email,
												form.values.password
											)}
										/>
									</Form.Group>

									<FormGroupTitle className="mt-5">
										Dados Pessoais
									</FormGroupTitle>

									<Form.Group className="d-flex">
										<Radio
											name="personType"
											label="Pessoa Física"
											value="0"
											className="mr-3"
											disabled={isLoading || !isSponsorConfirmed}
											defaultChecked
											isValueNumber
										/>
										<Radio
											name="personType"
											label="Pessoa Jurídica"
											value="1"
											disabled={isLoading || !isSponsorConfirmed}
											isValueNumber
										/>
									</Form.Group>

									{form.values.personType === 0 && (
										<>
											<Form.Group>
												<Form.Label>Sexo</Form.Label>
												<br />
												<ToggleButtonGroup name="gender">
													<ToggleButton variant="primary" value="F">
														Feminino
													</ToggleButton>
													<ToggleButton variant="primary" value="M">
														Masculino
													</ToggleButton>
												</ToggleButtonGroup>
											</Form.Group>

											<Form.Group>
												<Form.Label>Data de Nascimento</Form.Label>
												<DateTimeInput
													name="birthdate"
													selectDate
													disabled={isLoading || !isSponsorConfirmed}
												/>
											</Form.Group>

											<Form.Group>
												<Form.Label>CPF</Form.Label>
												<Input
													name="cpf"
													type="text"
													formatter={cpfFormatter}
													disabled={isLoading || !isSponsorConfirmed}
												/>
											</Form.Group>

											<Form.Group>
												<Form.Label>RG</Form.Label>
												<Input
													name="nRegister"
													type="text"
													formatter={regexNumbersOnlyFormatter}
													disabled={isLoading || !isSponsorConfirmed}
												/>
											</Form.Group>
										</>
									)}

									{form.values.personType === 1 && (
										<>
											<Form.Group>
												<Form.Label>CNPJ</Form.Label>
												<Input
													name="cnpj"
													type="text"
													formatter={cnpjFormatter}
													disabled={isLoading || !isSponsorConfirmed}
												/>
											</Form.Group>

											<Form.Group>
												<Form.Label>Inscrição Estadual </Form.Label>
												<Input
													name="nRegister"
													type="text"
													formatter={regexNumbersOnlyFormatter}
													disabled={isLoading || !isSponsorConfirmed}
												/>
											</Form.Group>
										</>
									)}

									<FormGroupTitle className="mt-5">Telefone</FormGroupTitle>

									<Form.Group>
										<Form.Label>Número</Form.Label>
										<Input
											name="phone.number"
											type="text"
											formatter={phoneFormatter}
											disabled={isLoading || !isSponsorConfirmed}
										/>
									</Form.Group>

									<Form.Group>
										<Checkbox
											name="terms"
											label="Declaro que li o Contrato e concordo com os seus termos"
											disabled={isLoading || !isSponsorConfirmed}
										/>
										<a href="assets/docs/contrato.pdf" target="_blank">
											Visualizar termos
										</a>
									</Form.Group>

									<Form.Group className="text-right">
										<Button
											variant="primary"
											className="mt-2"
											type="submit"
											disabled={isLoading || !isSponsorConfirmed}
										>
											{isLoading ? (
												<>
													<Spinner size="sm" animation="border" role="status" />
													<span className="ml-2">AGUARDE...</span>
												</>
											) : (
												'CADASTRAR'
											)}
										</Button>
									</Form.Group>
								</Col>
							</FormikForm>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default JoinScene;
