import {
	HttpGetListParams,
	HttpGetListResult,
	HttpPostResultAny,
	ID,
	NewsBanner,
	NewsNotification,
	NewsNotificationAttach,
} from 'types';
import { HttpPostResult } from 'types/api.types';

import Crud from './crud.service';

/*
		getForList
	*/
const getForList = (
	page: string,
	params: HttpGetListParams
): Promise<HttpGetListResult<NewsNotification>> =>
	Crud.getList<NewsNotification>(page, params);

/*
		getPerson
	*/
const getForPerson = (
	page: string,
	params: HttpGetListParams
): Promise<HttpGetListResult<NewsNotification>> =>
	Crud.getList<NewsNotification>(`my/${page}`, params);

/*
		getById
	*/
const getById = (
	id: string | number,
	page: string
): Promise<NewsNotification> => Crud.getOne(`${page}/{id}`, id as number);

/*
		createBanner
	*/
const createBanner = (id: ID, data: NewsBanner): Promise<HttpPostResultAny> =>
	Crud.create(`news/${id}/banner`, data);

/*
		createAttaches
	*/
const createAttaches = (
	id: ID,
	page: string,
	data: { attachs: NewsNotificationAttach[] }
): Promise<HttpPostResultAny> => Crud.create(`${page}/${id}/attach`, data);

/*
		deleteAttaches
	*/
const deleteAttach = (id: ID, page: string): Promise<HttpPostResultAny> =>
	Crud.delete(`${page}/attach/{id}`, id);

/*
		createOrUpdate
	*/
const createOrUpdate = (
	id: ID | null,
	page: string,
	data: NewsNotification
): Promise<HttpPostResult<{ id: number }>> => {
	if (id) {
		return Crud.update(`${page}/{id}`, id as number, data);
	}
	return Crud.create(page, data);
};

export default {
	getById,
	getForList,
	getForPerson,
	createBanner,
	createAttaches,
	deleteAttach,
	createOrUpdate,
};
