import { SessionState } from 'store/ducks/session';

import { ValidationCombinerFn } from './types';

export const Some: ValidationCombinerFn = (...validations) => (
	session: SessionState
) => validations.some((v) => v(session));

export const Every: ValidationCombinerFn = (...validations) => (
	session: SessionState
) => validations.every((v) => v(session));

export const Not: ValidationCombinerFn = (...validations) => (
	session: SessionState
) => !validations.every((v) => v(session));
