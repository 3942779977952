import React from 'react';

import { Button } from 'react-bootstrap';
import { ArrayQueryKey, useQuery } from 'react-query';

import { Skeleton } from 'components';
import Modal from 'components/Modal';
import { ModalForwardProps } from 'components/ModalContainer';
import { useToast } from 'hooks';
import distributorService from 'services/distributor.service';
import { ID, DistributorList, Distributor } from 'types';
import { ElementOf } from 'utils';
import { cepFormatter, phoneFormatter } from 'utils/formatters';

type DistributorInfoModalProps = {
	id?: ID;
	row: DistributorList;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeRedundancy = <T extends ReadonlyArray<any>>(
	data: T
): ElementOf<T>[] => data.filter((v, i) => data.indexOf(v) === i);

const DistributorInfoModal: React.FC<
	ModalForwardProps<DistributorInfoModalProps, boolean>
> = ({ id, modalRef, row, ...rest }) => {
	const [toast] = useToast();

	const { data, isLoading } = useQuery<Distributor, ArrayQueryKey>(
		['payout_request', row.id],
		() => distributorService.getById(row.id),
		{
			enabled: id !== null,
			cacheTime: 0,
			onError: () => {
				toast('Não foi possível carregar dados.', {
					type: 'error',
				});
				modalRef.dismiss();
			},
		}
	);

	if (!modalRef) return null;

	return (
		<Modal {...rest}>
			{isLoading ? (
				<>
					<Modal.Header closeButton>
						<Skeleton width="150px" height="2rem" />
					</Modal.Header>
					<Modal.Body>
						<Skeleton width="50%" height="2rem" />
						<p className="mt-3" style={{ width: '70%' }}>
							{[...Array(6).keys()].map((key) => (
								<Skeleton key={key} />
							))}
						</p>
					</Modal.Body>
					<Modal.Footer align="end">
						<Skeleton width="100px" height="2rem" />
					</Modal.Footer>
				</>
			) : (
				data && (
					<>
						<Modal.Header closeButton>
							<Modal.Title>{`PA #${data.id}`}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<h4 className="font-weight-bold">{data.name}</h4>
							<p>
								{data.phone &&
									removeRedundancy(data.phone).map((phone) => (
										<React.Fragment key={phone.idPhone}>
											<strong>Tel.:</strong>{' '}
											{phoneFormatter.format(phone.number)}
											<br />
										</React.Fragment>
									))}
								{data.address && (
									<>
										<strong>Endereço:</strong>{' '}
										{`${data.address.streetName}, ${data.address.number}`}
										<br />
										<strong>Bairro:</strong> {data.address.district}
										<br />
										<strong>Cidade:</strong> {data.address.city.name}
										<br />
										<strong>Estado:</strong> {data.address.state.uf}
										<br />
										<strong>CEP:</strong>{' '}
										{cepFormatter.format(data.address.cep)}
									</>
								)}
							</p>
						</Modal.Body>
						<Modal.Footer align="end">
							<Button variant="primary" onClick={() => modalRef.dismiss(true)}>
								Ok
							</Button>
						</Modal.Footer>
					</>
				)
			)}
		</Modal>
	);
};

export default DistributorInfoModal;
