import React from 'react';

export type CheckedContextType = {
	checked: { [id: string]: boolean };
	emitChecked: (id: number[], state: boolean) => void;
	clearChecked: () => void;
};

const defaultCheckedContext = {
	checked: {},
	emitChecked: () => {},
	clearChecked: () => {},
};

export const CheckedContext = React.createContext<CheckedContextType>(
	defaultCheckedContext
);

const useCheckedContext = () => {
	const ctx = React.useContext(CheckedContext);
	// eslint-disable-next-line no-console
	console.assert(
		ctx !== defaultCheckedContext,
		'Chamado useCheckedContext() fora de contexto válido!'
	);
	return ctx;
};

export default useCheckedContext;
