import React from 'react';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBell, faTags } from '@fortawesome/pro-duotone-svg-icons';
import {
	faDownload,
	faGlobe,
	faHandshake,
	faNewspaper,
	faStar,
} from '@fortawesome/pro-light-svg-icons';

import { RouteDef } from 'types';

import List from 'packages/admin/pages/List';
import listConfigs from 'packages/escritorio/pages/list/list.routes';
import { ListSceneConfig } from 'packages/escritorio/pages/list/types';

import BeAPa from './BeAPa';
import Campaigns from './Campaigns';
import Contact from './Contact';
import News from './News';
import NewsDetails from './news/NewsDetails';
import Notifications from './Notifications';
import Videos from './Videos';

const getListSceneRenderFn = (config: ListSceneConfig) => {
	return () => <List config={config} />;
};

const UtilsRoutes: RouteDef = {
	path: '/app/utils',
	menu: {
		name: 'Utilidades',
		icon: faStar,
	},
	children: [
		{
			path: '/distributors',
			render: getListSceneRenderFn(listConfigs.UtilsDistributors),
			menu: {
				name: 'Encontre um PA',
				icon: faGlobe,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/videos',
			component: Videos,
			menu: {
				name: 'Vídeos',
				icon: faYoutube,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/news/:slug',
			component: NewsDetails,
		},
		{
			path: '/news',
			component: News,
			exact: true,
			menu: {
				name: 'Notícias',
				icon: faNewspaper,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/notifications',
			component: Notifications,
			menu: {
				name: 'Notificações',
				icon: faBell,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/campaigns',
			component: Campaigns,
			menu: {
				name: 'Campanhas',
				icon: faTags,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/marketing',
			render: getListSceneRenderFn(listConfigs.Utils),
			exact: false,
			menu: {
				name: 'Materiais',
				icon: faDownload,
			},
		},
		// (userPending || userInactive || isDefaulter) ? null :
		{
			path: '/pa',
			component: BeAPa,
			menu: {
				name: 'Ser PA',
				icon: faHandshake,
			},
		},
		{
			path: '/contact',
			component: Contact,
		},
	],
};

export default UtilsRoutes;
